import React from 'react'

import * as icons from '../assets/icons'

const SIZES = { small: 16, medium: 24, large: 48 }

export type IconTypes = keyof typeof icons

export const iconList = Object.keys(icons)

export interface IconProps {
  type: IconTypes
  color?: string
  size?: 'small' | 'medium' | 'large'
  customSize?: {
    width?: string | number
    height?: string | number
  }
  strokeColor?: string
  rotate?: string
  style?: React.CSSProperties
}

const Icon = ({
  type,
  color = 'black',
  size = 'medium',
  customSize,
  strokeColor,
  rotate,
  style = {},
}: IconProps): JSX.Element => {
  const SvgIcon = icons[type] || icons['settings']
  const svgSize = SIZES[size] || 'medium'

  if (type === 'entrepriseSelect') color = 'none'

  // for some reason, using the css prop here does not work
  return (
    <SvgIcon
      style={{
        display: 'inline-block',
        verticalAlign: 'middle',
        fill: color,
        ...(strokeColor ? { stroke: strokeColor } : {}),
        ...(customSize
          ? customSize
          : {
              width: svgSize,
              height: svgSize,
            }),
        ...(rotate ? { transform: `rotate(${rotate})` } : {}),
        ...style,
      }}
    />
  )
}

export default Icon
