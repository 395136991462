/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'

import {
  PartsConditionModalSettings,
  PartsConditionTableModal,
  PartsConditionTableRow,
} from '../../../types/AnimatorCompanyEmployees'
import Icon from '../../Icon'

interface PartsConditionActionCellProps {
  setModal: (obj: PartsConditionModalSettings) => void
  part: PartsConditionTableRow
  isIncluded?: boolean
}

const PartsConditionActionCell: React.FC<PartsConditionActionCellProps> = ({
  setModal,
  part,
  isIncluded = true,
}) => {
  const theme = useTheme()
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: PartsConditionTableModal,
    ) => {
      e.stopPropagation()
      if (!isIncluded) return
      setModal({ part, actionName })
    },
    [part, setModal, isIncluded],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <div
        css={{ cursor: 'pointer', opacity: isIncluded ? 1 : 0.2 }}
        onClick={e => handleClick(e, PartsConditionTableModal.CAMERA)}
      >
        <Icon
          type="camera"
          color={
            !part.attachments?.length
              ? theme.colors.gray2
              : theme.colors.primary
          }
        />
      </div>
      <div
        css={{ cursor: 'pointer', opacity: isIncluded ? 1 : 0.2 }}
        onClick={e => handleClick(e, PartsConditionTableModal.NOTE)}
      >
        <Icon
          type="note"
          color={part.note ? theme.colors.primary : theme.colors.gray2}
        />
      </div>
    </div>
  )
}

export default PartsConditionActionCell
