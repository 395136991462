import { gql, useQuery } from '@apollo/client'

export const QUERY_COMPANIES_SEARCH_COUNT = gql`
  query AnimatorGetCompaniesInActivityCount(
    $multiFilter: String
    $type: String
    $fleet: String
    $area_id: Int
    $programSteps: [Int]
    $programStep: Int
    $department: String
    $archived: Boolean
  ) {
    companiesSearchCount(
      multiFilter: $multiFilter
      type: $type
      fleet: $fleet
      area_id: $area_id
      programSteps: $programSteps
      programStep: $programStep
      department: $department
      archived: $archived
    )
  }
`

export interface UseCompaniesSearchCountParam {
  variables?: Record<string, unknown>
}

export const useCompaniesSearchCount = ({
  variables,
}: UseCompaniesSearchCountParam) => {
  return useQuery(QUERY_COMPANIES_SEARCH_COUNT, {
    variables,
  })
}
