import { gql, QueryResult, useQuery } from '@apollo/client'
import {
  GetDeploymentBikeModelQuery,
  GetDeploymentBikeModelQueryVariables,
} from '../../../../__generated__/graphql'

export const QUERY_DEPLOYMENT_BIKES = gql`
  query GetDeploymentBikeModel($id: String!) {
    deployment(id: $id) {
      id
      deploymentType
      name
      cargoBike
      foldingBike
      urbanBike
      deploymentStartDate
      deploymentEndDate
      bikeModelPrices {
        id
        remainingPrice
        childSeat
        bikemodel {
          id
          name
          brand
          size
          batteryPower
          bikeType
          publicPrice
          pictureUrl
          description
        }
      }
    }
  }
`

export const useDeploymentBikeModels = (
  deploymentId: string,
): QueryResult<GetDeploymentBikeModelQuery> => {
  return useQuery<
    GetDeploymentBikeModelQuery,
    GetDeploymentBikeModelQueryVariables
  >(QUERY_DEPLOYMENT_BIKES, {
    variables: { id: deploymentId },
  })
}
