export function shortDayDate(date: Date, reverse = false): string {
  if (!date) return date
  const day = date.getDate()
  const month = date.getMonth() + 1

  const monthText = month >= 10 ? month : '0' + month // Forgive me
  const dayText = day >= 10 ? day : '0' + day // Forgive me
  const year = date.getFullYear()

  if (reverse) {
    return `${year}/${monthText}/${dayText}`
  } else {
    return `${dayText}/${monthText}/${year}`
  }
}

export function formatMonth(date: Date): string {
  if (!date) return date

  const month = date?.toLocaleString('default', { month: 'long' })
  const year = date?.getFullYear()

  return `${month} ${year}`
}

export function getDatesInRange(startDate: Date, endDate: Date): Array<Date> {
  const date = new Date(startDate.getTime())

  const dates = []

  while (date <= endDate) {
    dates.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return dates
}
