/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNotifications } from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../../../../components/ConfirmationModal'
import { useUpdateEmployeeDeployment } from '../hooks/useUpdateEmployeeDeployment'
import { EmployeeDeploymentStatus } from '../../../../__generated__/graphql'
import { useSelectLoanEmployeeDeployment } from '../hooks/useSelectEmployeeDeployment'

export interface DeselectEmployeeModalProps
  extends Pick<ConfirmationModalProps, 'isOpen' | 'onClose'> {
  employeeDeploymentId: string
  employeeFullName: string
  onCompleted?: () => void
}

export const DeselectEmployeeModal: React.FC<DeselectEmployeeModalProps> = ({
  employeeDeploymentId,
  employeeFullName,
  onCompleted,
  ...rest
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [mutate, { loading }] = useUpdateEmployeeDeployment()
  const [selectLoan, { loading: loadingLoan }] =
    useSelectLoanEmployeeDeployment({
      onCompleted,
    })

  const onSubmit = async () => {
    try {
      await Promise.all([
        mutate({
          variables: {
            id: employeeDeploymentId,
            data: { status: EmployeeDeploymentStatus.Candidate },
          },
        }),
        selectLoan({
          variables: {
            employeeDeploymentId,
            selectBool: false,
          },
        }),
      ])
      rest.onClose()
    } catch (e) {
      apiErrorCatcher(notifications)
    }
  }

  return (
    <ConfirmationModal
      title={t(
        'animator.deployments.users.selectedTable.deselectEmployeeModal.title',
      )}
      description={t(
        'animator.deployments.users.selectedTable.deselectEmployeeModal.description',
        { employeeFullName: employeeFullName },
      )}
      loading={loading || loadingLoan}
      onSubmit={onSubmit}
      {...rest}
    />
  )
}

export default DeselectEmployeeModal
