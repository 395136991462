/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Row } from 'react-table'
import React from 'react'
import {
  PostTableModalActions,
  PostTagSettings,
  PostTagTableRow,
} from '../../../types/AnimatorPosts'
import Icon from '../../Icon'

interface PostTagActionCellProps {
  setModal: (
    obj: PostTagSettings<PostTagTableRow, PostTableModalActions>,
  ) => void
  row: Row<PostTagTableRow>
  actions: Array<PostTableModalActions>
}

const PostTagActionCell: React.FC<PostTagActionCellProps> = ({
  setModal,
  row,
  actions,
}) => {
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: PostTableModalActions,
    ) => {
      e.stopPropagation()
      setModal({ tag: row.original, actionName })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      {actions.map(actionName => (
        <div
          key={actionName}
          css={{ cursor: 'pointer' }}
          onClick={e => handleClick(e, actionName)}
        >
          <Icon type={actionName} />
        </div>
      ))}
    </div>
  )
}

export default PostTagActionCell
