/** @jsx jsx */
import { jsx } from '@emotion/core'
import { REF_STATE_OF_USE_RATING } from '@goodwatt/shared/dist/constants'
import { StateOfUseType } from '@goodwatt/shared/dist/types'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from './Icon'

interface StarRatingProps {
  id: string
  disabled?: boolean
  currentStateOfUse?: StateOfUseType
  updatedStateOfUse?: StateOfUseType
  onRatingClick: (rating: StateOfUseType) => void
  onMissedClick?: () => void
}

const StarRating: React.FC<StarRatingProps> = ({
  id,
  disabled,
  currentStateOfUse,
  updatedStateOfUse,
  onRatingClick,
  onMissedClick,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const handleStarClick = (rate: number) => {
    const rateStateOfUse =
      REF_STATE_OF_USE_RATING.stateOfUseFromRating[
        (rate + 1) as keyof typeof REF_STATE_OF_USE_RATING.stateOfUseFromRating
      ]
    onRatingClick(rateStateOfUse)
  }

  const handleStarColor = (starIndex: number): string => {
    if (disabled) {
      return theme.colors.gray5
    }
    if (updatedStateOfUse) {
      const updatedStateOfUseRate =
        REF_STATE_OF_USE_RATING.ratingFromStateOfUse[updatedStateOfUse]
      if (starIndex + 1 <= updatedStateOfUseRate) {
        return theme.colors.orangeLegend
      }
    }
    if (currentStateOfUse) {
      const currentStateOfUseRate =
        REF_STATE_OF_USE_RATING.ratingFromStateOfUse[currentStateOfUse]
      if (starIndex + 1 <= currentStateOfUseRate) {
        return theme.colors.yellowLegend
      }
    }
    return theme.colors.gray5
  }

  const handleTextColor = () => {
    if (
      (updatedStateOfUse &&
        (updatedStateOfUse === StateOfUseType.OUT_OF_USE ||
          updatedStateOfUse === StateOfUseType.MISSED)) ||
      (!updatedStateOfUse && currentStateOfUse === StateOfUseType.OUT_OF_USE)
    ) {
      return theme.colors.error
    }
    return theme.colors.gray1
  }
  const ratingLabel = React.useMemo(() => {
    if (disabled || (!updatedStateOfUse && !currentStateOfUse)) return '-'
    return t(`shared.stateOfUseLabel.${updatedStateOfUse || currentStateOfUse}`)
  }, [currentStateOfUse, disabled, t, updatedStateOfUse])

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minWidth: 260,
      }}
    >
      {onMissedClick && (
        <div
          css={{ marginLeft: 4.5, marginRight: 4.5, cursor: 'pointer' }}
          onClick={onMissedClick}
        >
          <Icon
            type="blockDoubleCrossed"
            color={
              updatedStateOfUse === StateOfUseType.MISSED
                ? theme.colors.error
                : theme.colors.gray5
            }
          />
        </div>
      )}
      {[0, 1, 2, 3].map(rate => (
        <div
          key={`${id}-${rate + 1}`}
          css={{ marginLeft: 4.5, marginRight: 4.5, cursor: 'pointer' }}
          onClick={() => handleStarClick(rate)}
        >
          <Icon type="star" color={handleStarColor(rate)} />
        </div>
      ))}
      <span
        css={{ marginLeft: 15, color: handleTextColor(), userSelect: 'none' }}
      >
        {ratingLabel}
      </span>
    </div>
  )
}

export default StarRating
