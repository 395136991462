/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { motion, AnimatePresence, MotionStyle } from 'framer-motion'
import usePortal from '../hooks/usePortal'
import { createPortal } from 'react-dom'
import { ANIMATOR_GUARD_Z_INDEX } from './AnimatorGuard'

export interface ModalProps {
  isOpen: boolean
  onBackgroundClick?: () => void
  childrenCentered?: boolean
  dimmedBackground?: boolean
  style?: MotionStyle
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onBackgroundClick,
  children,
  childrenCentered = true,
  dimmedBackground = true,
  style,
}) => {
  const target = usePortal()

  const theme = useTheme(),
    transition = {
      ease: 'easeInOut',
    },
    variants = {
      close: {
        opacity: 0,
        transition,
      },
      open: {
        opacity: 1,
        transition,
      },
    },
    childVariants = {
      close: { y: 200, opacity: 0 },
      open: {
        y: 0,
        opacity: 1,
        transition,
      },
    }

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          onClick={onBackgroundClick}
          style={style}
          css={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: dimmedBackground
              ? theme.colors.backgroundDimmed
              : 'transparent',
            zIndex: ANIMATOR_GUARD_Z_INDEX - 1,
          }}
          variants={variants}
          initial="close"
          animate="open"
        >
          <motion.div
            css={{
              display: 'flex',
              width: '100%',
              height: '100%',
              ...(childrenCentered
                ? {
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : {}),
            }}
            variants={childVariants}
            initial="close"
            animate="open"
            exit="close"
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    target,
  )
}

export default Modal
