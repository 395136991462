/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { HeaderGroup } from 'react-table'

import Icon from '../../Icon'
import { SortOrder } from '../../../__generated__/graphql'

interface HeaderCellProps {
  column: HeaderGroup
  sort: false | SortOrder
}

const HeaderCell: React.FC<HeaderCellProps> = ({ column, sort }) => {
  const theme = useTheme()

  if (column.canSort) {
    return (
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          userSelect: 'none',
        }}
      >
        {column.render('Header')}
        {sort ? (
          sort === SortOrder.Desc ? (
            <Icon
              type="arrowFilterDown"
              size="medium"
              color={theme.colors.white}
            />
          ) : (
            <Icon
              type="arrowFilterUp"
              size="medium"
              color={theme.colors.white}
            />
          )
        ) : (
          <Icon type="arrowDownUp" size="medium" color={theme.colors.white} />
        )}
      </div>
    )
  } else {
    return <span>{column.render('Header')}</span>
  }
}

export default HeaderCell
