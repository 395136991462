import {
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client'
import {
  DeleteUserMutation,
  DeleteUserMutationVariables,
} from '../../../../__generated__/graphql'

export const MUTATION_DELETE_USER = gql`
  mutation DeleteUser($data: DeleteUserInput!) {
    deleteUser(data: $data)
  }
`

export const useDeleteUser = (
  options?: MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
): MutationTuple<DeleteUserMutation, DeleteUserMutationVariables> => {
  return useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    MUTATION_DELETE_USER,
    {
      refetchQueries: ['GetAllCompanyEmployees'],
      ...options,
    },
  )
}
