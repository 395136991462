/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { Helmet } from 'react-helmet'
import { useQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import AcceptedList from './components/AcceptedList'
import PendingList from './components/PendingList'
import RefusedList from './components/RefusedList'
import ArchivedList from './components/ArchivedList'

import TableRefetchProvider from './components/TableRefetchContext'
import {
  GetAnimatorDepartmentsQuery,
  IntFilter,
} from '../../../__generated__/graphql'
import HeaderSummary from '../../../components/Dasboard/HeaderSummary'
import Button from '../../../components/Button'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import { UserRoles } from '@goodwatt/shared/dist/types'
import AdminTransferSiteToCompanyModal from './AdminTransferSiteToCompanyModal'
import AdminTransferDeploymentToSiteModal from './AdminTransferDeploymentToSite'

const tablePageSizes = {
  acceptedCompanies: 30,
  pendingCompanies: 20,
  refusedCompanies: 10,
}

const QUERY_DEPARTMENTS = gql`
  query GetAnimatorDepartments {
    departments {
      code
      name
    }
  }
`

const AnimatorCompanies: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useQuery<GetAnimatorDepartmentsQuery>(QUERY_DEPARTMENTS)

  const staffFilterOptions = React.useMemo(
    () => [
      {
        label: t('animator.companies.filter.options.all'),
        value: 'all',
      },
      {
        label: t('animator.companies.filter.options.ltHND'),
        value: { lt: 100 } as IntFilter,
      },
      {
        label: t('animator.companies.filter.options.gteHNDLt2HND'),
        value: { gte: 100, lt: 200 } as IntFilter,
      },
      {
        label: t('animator.companies.filter.options.gte2HNDLt3HND'),
        value: { gte: 200, lt: 300 } as IntFilter,
      },
      {
        label: t('animator.companies.filter.options.gte3HNDLt4HND'),
        value: { gte: 300, lt: 400 } as IntFilter,
      },
      {
        label: t('animator.companies.filter.options.gte4HNDLt5HND'),
        value: { gte: 400, lt: 500 } as IntFilter,
      },
      {
        label: t('animator.companies.filter.options.gte5HND'),
        value: { gte: 500 } as IntFilter,
      },
    ],
    [t],
  )

  const departmentsFilterOptions = React.useMemo(
    () => [
      {
        label: t('animator.companies.filter.options.all'),
        value: 'all',
      },
      ...(data && data.departments
        ? data.departments.map(d => ({
            label: `${d?.code || ''} ${d?.name || ''}`,
            value: d?.code || '',
          }))
        : []),
    ],
    [data, t],
  )

  const { role: userRole } = useSelector((state: StoreState) => state.user)

  const isAdmin = userRole === UserRoles.ADMIN

  const [transferSiteToCompanyPopupOpen, setTransferSiteToCompanyPopupOpen] =
    React.useState(false)

  const [
    transferDeploymentToSitePopupOpen,
    setTransferDeploymentToSitePopupOpen,
  ] = React.useState(false)

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goodwatt Animateur - Mes organisations</title>
        <link rel="canonical" href="https://goodwatt.fr/animator/companies" />
      </Helmet>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <HeaderSummary />

        {isAdmin && (
          <React.Fragment>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 23,
              }}
            >
              <Button onClick={() => setTransferSiteToCompanyPopupOpen(true)}>
                {t(
                  'animator.dashboard.adminActions.transferSiteToCompany.button',
                )}
              </Button>

              <Button
                onClick={() => setTransferDeploymentToSitePopupOpen(true)}
              >
                {t(
                  'animator.dashboard.adminActions.transferDeploymentToSite.button',
                )}
              </Button>
            </div>
          </React.Fragment>
        )}

        <TableRefetchProvider>
          <AcceptedList pageSize={tablePageSizes.acceptedCompanies} />
          <PendingList
            pageSize={tablePageSizes.pendingCompanies}
            departmentsFilterOptions={departmentsFilterOptions}
            staffFilterOptions={staffFilterOptions}
          />
          <RefusedList
            pageSize={tablePageSizes.refusedCompanies}
            departmentsFilterOptions={departmentsFilterOptions}
            staffFilterOptions={staffFilterOptions}
          />
          <ArchivedList pageSize={tablePageSizes.acceptedCompanies} />
        </TableRefetchProvider>

        {transferSiteToCompanyPopupOpen && (
          <AdminTransferSiteToCompanyModal
            isOpen={transferSiteToCompanyPopupOpen}
            onClose={() => setTransferSiteToCompanyPopupOpen(false)}
          />
        )}

        {transferDeploymentToSitePopupOpen && (
          <AdminTransferDeploymentToSiteModal
            isOpen={transferDeploymentToSitePopupOpen}
            onClose={() => setTransferDeploymentToSitePopupOpen(false)}
          />
        )}
      </div>
    </div>
  )
}
export default AnimatorCompanies
