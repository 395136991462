/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Row } from 'react-table'
import React from 'react'
import {
  AdviceTableModalActions,
  AdviceSettings,
  AdviceTableRow,
} from '../../../types/AnimatorAdvices'
import Icon from '../../Icon'

interface AdviceActionCellProps {
  setModal: (
    obj: AdviceSettings<AdviceTableRow, AdviceTableModalActions>,
  ) => void
  row: Row<AdviceTableRow>
  actions: Array<AdviceTableModalActions>
}

const AdviceActionCell: React.FC<AdviceActionCellProps> = ({
  setModal,
  row,
  actions,
}) => {
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: AdviceTableModalActions,
    ) => {
      e.stopPropagation()
      setModal({ advice: row.original, actionName })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      {actions.map(actionName => (
        <div
          key={actionName}
          css={{ cursor: 'pointer' }}
          onClick={e => handleClick(e, actionName)}
        >
          <Icon type={actionName} />
        </div>
      ))}
    </div>
  )
}

export default AdviceActionCell
