/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { createContext, useState, useContext } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import NotificationToast from '../components/NotificationToast'
import { ANIMATOR_GUARD_Z_INDEX } from '../components/AnimatorGuard'
import { useTranslation } from 'react-i18next'

export enum NotificationTypes {
  SUCCESS,
  ERROR,
  WARNING,
}

type NotificationParams = {
  type: NotificationTypes
  message: string
  infinite?: boolean
  ms?: number
  uid?: number
}

export type NotificationContextType = {
  newNotification: ({ type, message, infinite, ms }: NotificationParams) => void
  clearNotifications: () => void
  anErrorOccured: () => void
}

export const NotificationContext = createContext<
  NotificationContextType | undefined
>(undefined)

const NotificationProvider: React.FC = ({ children }) => {
  const [notificationList, setNotificationList] = useState<
    NotificationParams[]
  >([])
  const { t } = useTranslation()

  const onUnmount = (uid: number): void => {
    const array = notificationList.filter(item => item.uid !== uid)
    setNotificationList(array)
  }

  const newNotification = React.useCallback(
    ({
      type,
      message,
      infinite = false,
      ms = 5000,
    }: NotificationParams): void => {
      setNotificationList([
        {
          type,
          message,
          infinite,
          ms,
          uid: new Date().getTime(),
        },
        ...notificationList,
      ])
    },
    [notificationList],
  )

  const clearNotifications = (): void => setNotificationList([])

  const anErrorOccured = () =>
    newNotification({
      type: NotificationTypes.ERROR,
      message: t('forms.errors.anErrorOccured'),
    })

  const eventNewNotification = React.useCallback(
    (event: CustomEventInit): void => {
      newNotification(event.detail)
    },
    [newNotification],
  )

  React.useEffect(() => {
    window.addEventListener('newNotification', eventNewNotification)
    return (): void =>
      window.removeEventListener('newNotification', eventNewNotification)
  }, [eventNewNotification])

  return (
    <NotificationContext.Provider
      value={{ newNotification, clearNotifications, anErrorOccured }}
    >
      <div
        css={{
          width: '100%',
          position: 'fixed',
          pointerEvents: 'none',
          zIndex: ANIMATOR_GUARD_Z_INDEX + 5,
          bottom: 0,
        }}
      >
        <AnimatePresence>
          {notificationList.map(item => (
            <motion.li
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              key={item.uid}
              style={{
                listStyle: 'none',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <NotificationToast
                type={item.type}
                message={item.message}
                uid={item.uid as number}
                infinite={item.infinite}
                ms={item.ms}
                onUnmount={onUnmount}
              />
            </motion.li>
          ))}
        </AnimatePresence>
      </div>
      {children}
    </NotificationContext.Provider>
  )
}

const useNotifications = (): NotificationContextType => {
  const context = useContext(NotificationContext)
  if (context === undefined)
    throw new Error(
      'useNotifications must be used within a NotificationContext.Provider',
    )

  return context
}

export { NotificationProvider, useNotifications }
