import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useModal } from '../../../../hooks/useModal'
import { Employee } from '../../../../__generated__/graphql'
import EditEmployeeProfileModal from '../../../Shared/EditEmployeeModal'

export interface EditEmployeeProfileButtonProps {
  employee: Employee
}

export const EditEmployeeProfileButton: React.FC<
  EditEmployeeProfileButtonProps
> = ({ employee, children = <Icon type="anonymProfile" /> }) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      {modalProps.isOpen && (
        <EditEmployeeProfileModal
          employee={employee}
          submitCloseModal={modalProps.onClose}
          {...modalProps}
        />
      )}
      <button onClick={onOpen}>{children}</button>
    </Fragment>
  )
}
