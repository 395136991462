/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useTheme } from 'emotion-theming'

import i18n from '../i18n/config'
import AuthContainer from '../components/AuthContainer'
import Input from '../components/Input'
import Button from '../components/Button'

import {
  useNotifications,
  NotificationTypes,
} from '../contexts/NotificationContext'
import apiErrorCatcher from '../utils/apiErrorCatcher'
import AuthenticationCard from '../components/Card/AuthenticationCard'
import { linkCss } from '../styles/globalStyles'
import { ForgotPasswordMutation } from '../__generated__/graphql'

const MUTATION_FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`

const schema = yup.object().shape({
  email: yup.string().email(i18n.t('forms.errors.wrongFormat')),
})

interface InputForgotPassword {
  email: string
}

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { register, handleSubmit, errors } = useForm<InputForgotPassword>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const [forgotPassword, { loading }] = useMutation<ForgotPasswordMutation>(
    MUTATION_FORGOT_PASSWORD,
  )
  const notifications = useNotifications()

  const onSubmit = (data: InputForgotPassword) => {
    forgotPassword({
      variables: {
        email: data.email.toLowerCase(),
      },
    })
      .then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t('auth.forgotPassword.successNotification'),
        })
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <AuthContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('auth.forgotPassword.metaTitle')}</title>
        <link rel="canonical" href="https://goodwatt.fr/forgot-password" />
      </Helmet>
      <AuthenticationCard title={t('auth.forgotPassword.popupTitle')}>
        <p
          css={{
            fontSize: '1.6rem',
            textAlign: 'center',
            color: theme.colors.gray1,
            marginBottom: '40px',
          }}
        >
          {t('auth.forgotPassword.desc')}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="text"
            label={t('forms.label.email')}
            name="email"
            defaultValue=""
            register={register}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Button type="primary" submit loading={loading}>
              {t('auth.forgotPassword.buttonTitle')}
            </Button>
          </div>
        </form>
        <div
          css={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            css={{
              ...linkCss,
              marginTop: '40px',
              textDecoration: 'none',
              textAlign: 'center',
            }}
          >
            <a href="/" css={{ ...linkCss }}>
              {t('auth.forgotPassword.redirect')}
            </a>
          </p>
        </div>
      </AuthenticationCard>
    </AuthContainer>
  )
}

export default ForgotPassword
