/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'

import Input from '../../../components/Input'
import Button from '../../../components/Button'
import {
  useNotifications,
  NotificationTypes,
} from '../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import { changePasswordSchema } from '../../../utils/schemaValidation'
import { MUTATION_EDIT_PASSWORD } from '../../../utils/gql/mutations'
import {
  EditPasswordMutation,
  EditPasswordMutationVariables,
} from '../../../__generated__/graphql'

type Form = {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

const EditPasswordAnimator: React.FC = () => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors, reset } = useForm<Form>({
    resolver: yupResolver(changePasswordSchema),
    mode: 'onBlur',
  })
  const [editPassword, { loading }] = useMutation<
    EditPasswordMutation,
    EditPasswordMutationVariables
  >(MUTATION_EDIT_PASSWORD)
  const notifications = useNotifications()

  const onSubmit = (data: Form) => {
    editPassword({
      variables: {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      },
    })
      .then(result => {
        if (result?.data?.editPassword) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.notification.passwordSuccessUpdate'),
          })
          reset({ oldPassword: '', newPassword: '', confirmNewPassword: '' })
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 css={{ margin: '14px 0' }}>{t('shared.profile.editPassword')}</h3>
        <Input
          type="password"
          name="oldPassword"
          label={t('forms.label.oldPassword')}
          defaultValue=""
          error={!!errors.oldPassword}
          helperText={errors.oldPassword?.message}
          register={register}
        />
        <Input
          type="password"
          name="newPassword"
          label={t('forms.label.newPassword')}
          defaultValue=""
          error={!!errors.newPassword}
          helperText={errors.newPassword?.message}
          register={register}
        />
        <Input
          type="password"
          name="confirmNewPassword"
          label={t('forms.label.confirmNewPassword')}
          defaultValue=""
          error={!!errors.confirmNewPassword}
          helperText={errors.confirmNewPassword?.message}
          register={register}
        />
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button loading={loading} submit>
            {t('forms.button.editPassword')}
          </Button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default EditPasswordAnimator
