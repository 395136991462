import { Dispatch, SetStateAction, useState } from 'react'
import { PaginationProps } from '../components/Pagination'

export interface UsePaginationParam {
  defaultPage?: number
  take?: number
}

export interface UsePaginationResult {
  page: number
  setPage: Dispatch<SetStateAction<number>>
  variables: { skip: number; take: number }
  getPaginationProps: (
    props?: Omit<PaginationProps, 'currentPage' | 'pageSize' | 'onClick'>,
  ) => PaginationProps
}

export const usePagination = ({
  defaultPage = 0,
  take = 20,
}: UsePaginationParam = {}): UsePaginationResult => {
  const [page, setPage] = useState(defaultPage)

  return {
    page,
    setPage,
    variables: { skip: take * page, take },
    getPaginationProps: props => ({
      totalData: null,
      ...props,
      currentPage: page + 1,
      pageSize: take,
      onClick: page => setPage(page - 1),
    }),
  }
}
