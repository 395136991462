/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'

import { useDispatch } from 'react-redux'
import { NavItemActive } from '../../../hooks/useMenu'
import { useTheme } from 'emotion-theming'
import { NavLink } from 'react-router-dom'
import Icon from '../../Icon'
import { setMenuTitle } from '../../../redux/menu'

interface NavItemProps extends NavItemActive {
  onNavClick: (offsetTop: number) => void
  isActive: boolean
  closeMenu?: () => void
}

const NavItem: React.FC<NavItemProps> = ({
  url,
  icon,
  title,
  onNavClick,
  isActive,
  closeMenu,
}) => {
  const theme = useTheme()
  const navLinkEl = React.useRef<HTMLAnchorElement>(null)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (isActive && navLinkEl && navLinkEl.current) {
      dispatch(setMenuTitle(title))
      onNavClick(navLinkEl.current.offsetTop)
    }
  }, [onNavClick, isActive, dispatch, title])

  return (
    <li
      css={{
        fontFamily: theme.fontFamily,
        textTransform: 'uppercase',
        fontStyle: 'normal',
        fontSize: '1.6rem',
        lineHeight: '32px',
        [theme.media.tablet]: {
          fontSize: '2rem',
        },
      }}
    >
      <NavLink
        ref={navLinkEl}
        onClick={e => {
          onNavClick(e.currentTarget.offsetTop)
          dispatch(setMenuTitle(title))
          if (closeMenu) closeMenu()
        }}
        to={url}
        css={{
          position: 'relative',
          display: 'flex',
          padding: '5px 10px 5px 0',
          color: theme.colors.gray2,
        }}
      >
        <div
          css={{
            margin: '0 16px 0 10px',
            width: 48,
            height: 48,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon
            type={icon}
            customSize={{ width: '35px', height: '35px' }}
            color={theme.colors.gray2}
          />
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {title}
        </div>
      </NavLink>
    </li>
  )
}

export default NavItem
