/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../../redux/rootReducer'
import { setDeploymentForm } from '../../../../../redux/forms'
import theme from '../../../../../styles/theme'
import Input from '../../../../../components/Input'
import DateTime from '../../../../../components/DateTime'
import { DeploymentTableModal } from '../../../../../types/AnimatorDeployments'
import Switch from '../../../../../components/Switch'

interface Step3Props {
  type: DeploymentTableModal | undefined
  isOpen: boolean
}

const Step3: React.FC<Step3Props> = ({ type, isOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const form = useSelector((state: StoreState) => state.forms.deploymentForm)

  React.useEffect(() => {
    if (form.appointmentLoanReturn.location === '' && isOpen) {
      dispatch(
        setDeploymentForm({
          ...form,
          appointmentLoanReturn: {
            ...form.appointmentLoanReturn,
            location: form.appointmentLoanDelivery.location,
          },
        }),
      )
    }
  }, [form])

  const changeLocation = async (data: any) => {
    const currentDeploymentForm = form

    dispatch(
      setDeploymentForm({
        ...currentDeploymentForm,
        appointmentLoanReturn: {
          ...currentDeploymentForm.appointmentLoanReturn,
          location: data.target.value,
        },
      }),
    )
  }
  const onChangeDate = async (data: any) => {
    const currentDeploymentForm = form

    dispatch(
      setDeploymentForm({
        ...currentDeploymentForm,
        appointmentLoanReturn: {
          ...currentDeploymentForm.appointmentLoanReturn,
          date: data,
        },
      }),
    )
  }

  const onChangeDuration = async (data: any) => {
    const currentDeploymentForm = form

    dispatch(
      setDeploymentForm({
        ...currentDeploymentForm,
        appointmentLoanReturn: {
          ...currentDeploymentForm.appointmentLoanReturn,
          duration: Number(data.target.value),
        },
      }),
    )
  }

  const onChangeEmployees = async (data: any) => {
    const currentDeploymentForm = form

    dispatch(
      setDeploymentForm({
        ...currentDeploymentForm,
        appointmentLoanReturn: {
          ...currentDeploymentForm.appointmentLoanReturn,
          capacity: Number(data.target.value),
        },
      }),
    )
  }

  return (
    <div css={{ width: '100%', textAlign: 'initial', paddingInline: '9%' }}>
      <form>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            gap: 20,
          }}
        >
          <p css={styles.fieldLabel}>
            {t('animator.company.progress.modal.deliveryAppointment.location')}
          </p>
          <Input
            type="text"
            name="deliveryAppointmentLocation"
            value={form.appointmentLoanReturn.location || ''}
            placeholder={t(
              'animator.company.progress.modal.deliveryAppointment.locationPlaceholder',
            )}
            compact
            onChange={changeLocation}
            disabled={form.programStepNbr >= 5}
          />
        </div>

        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            gap: 20,
          }}
        >
          <p css={styles.fieldLabel}>
            {t(
              'animator.company.progress.modal.deliveryAppointment.startingDate',
            )}
          </p>
          <DateTime
            block={false}
            value={
              form.appointmentLoanReturn.date
                ? new Date(form.appointmentLoanReturn.date)
                : null
            }
            placeholder="01/03/2022 - 11h00"
            type="dateTime"
            onChange={onChangeDate}
            disabled={form.programStepNbr >= 5}
          />
        </div>

        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            gap: 20,
          }}
        >
          <p css={styles.fieldLabel}>
            {t('animator.company.progress.modal.deliveryAppointment.slots')}
          </p>

          <Input
            type="text"
            name="deliveryAppointmentDuration"
            value={form.appointmentLoanReturn.duration.toString() || ''}
            placeholder="5"
            ultraCompact
            subLabel={t(
              'animator.company.progress.modal.deliveryAppointment.durationSubLabel',
            )}
            onlyNumbers
            block={false}
            onChange={onChangeDuration}
            disabled={form.programStepNbr >= 5}
          />
          <Input
            type="text"
            name="deliveryAppointmentEmployees"
            value={form.appointmentLoanReturn.capacity.toString() || ''}
            placeholder="5"
            subLabel={t(
              'animator.company.progress.modal.deliveryAppointment.employeesSubLabel',
            )}
            ultraCompact
            onlyNumbers
            block={false}
            onChange={onChangeEmployees}
            disabled={form.programStepNbr >= 5}
          />
        </div>

        {type === DeploymentTableModal.EDIT && form.programStepNbr < 5 && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
              gap: 20,
            }}
          >
            <span css={styles.otherLabel}>
              {t('animator.company.progress.modal.applyToNew')}
            </span>
            <Switch
              isChecked={form.appointmentLoanReturn.resetAppointments}
              onClick={() =>
                dispatch(
                  setDeploymentForm({
                    ...form,
                    appointmentLoanReturn: {
                      ...form.appointmentLoanReturn,
                      resetAppointments:
                        !form.appointmentLoanReturn.resetAppointments,
                    },
                  }),
                )
              }
            />
            <span css={styles.fieldLabel}>
              {t('animator.company.progress.modal.applyToAll')}
            </span>
          </div>
        )}
      </form>
    </div>
  )
}

export default Step3

const styles = {
  otherLabel: {
    fontSize: '1.6rem',
    color: theme.colors.gray3,
  },
  fieldLabel: {
    width: 132,
    maxWidth: 132,
    minWidth: 132,
    fontSize: '1.6rem',
    color: theme.colors.gray3,
  },
}
