import { TFunction } from 'i18next'
import { TableCellsProps } from './TableCells'
import {
  GetBikeComponentHistoriesQuery,
  GetLoanPackageHistoriesQuery,
} from '../../../../../../__generated__/graphql'

export function computeRetentionAmount(
  bikeComponents?: {
    bikeComponentHistories:
      | ({
          estimatedRetention: number | null
        } | null)[]
      | null
      | undefined
  },
  loanPackages?: {
    loanPackageHistories:
      | ({
          estimatedRetention: number | null
        } | null)[]
      | null
      | undefined
  },
): number {
  let amount = 0

  if (!bikeComponents || !loanPackages) {
    return amount
  }

  bikeComponents?.bikeComponentHistories?.forEach(item => {
    amount += item?.estimatedRetention || 0
  })
  loanPackages?.loanPackageHistories?.forEach(item => {
    amount += item?.estimatedRetention || 0
  })

  return amount
}

export function computeTableInfos(
  t: TFunction,
  bikeComponents?: GetBikeComponentHistoriesQuery,
  loanPackages?: GetLoanPackageHistoriesQuery,
): {
  cells: TableCellsProps['data']
  reason: string
} {
  const cellsArr: TableCellsProps['data'] = []
  let reason = ''

  if (bikeComponents?.bikeComponentHistories.length) {
    bikeComponents?.bikeComponentHistories.forEach(item => {
      if (item.estimatedRetention) {
        reason = reason + t(`shared.bikeComponentPartLabel.${item.bikeComponent.bikeComponentPart.name}`) + ' : ' + (item.noteAfter || '') + '. '; // prettier-ignore
        cellsArr.push({
          element: t(
            `shared.bikeComponentPartLabel.${item.bikeComponent.bikeComponentPart.name}`,
          ),
          before: {
            stateOfUse: t(
              `shared.stateOfUseLabel.${item.stateOfUseBefore.name}`,
            ),
            comment: item.noteBefore || '-',
          },
          after: {
            stateOfUse: t(
              `shared.stateOfUseLabel.${item.stateOfUseAfter.name}`,
            ),
            comment: item.noteAfter || '-',
          },
          estimatedAmount: item.estimatedRetention || 0,
        })
      }
    })
  }
  if (loanPackages?.loanPackageHistories.length) {
    loanPackages?.loanPackageHistories.forEach(item => {
      if (item.estimatedRetention) {
        reason = reason + t(`shared.loanPackageAccessoryLabel.${item.loanPackage.packageAccessory.name}`) + ' : ' + (item.noteAfter || '') + '. '; // prettier-ignore
        cellsArr.push({
          element: t(
            `shared.loanPackageAccessoryLabel.${item.loanPackage.packageAccessory.name}`,
          ),
          before: {
            stateOfUse: t(
              `shared.stateOfUseLabel.${item.stateOfUseBefore.name}`,
            ),
            comment: item.noteBefore || '-',
          },
          after: {
            stateOfUse: t(
              `shared.stateOfUseLabel.${item.stateOfUseAfter.name}`,
            ),
            comment: item.noteAfter || '-',
          },
          estimatedAmount: item.estimatedRetention || 0,
        })
      }
    })
  }
  return { cells: cellsArr, reason }
}
