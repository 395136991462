import { gql, QueryResult, useQuery } from '@apollo/client'
import {
  GetCompanyAllSitesQuery,
  GetCompanyAllSitesQueryVariables,
} from '../../../../__generated__/graphql'

const QUERY_COMPANY_ALL_SITES = gql`
  query GetCompanyAllSites($where: SiteWhereInput!) {
    sites(where: $where) {
      id
      name
      street
      city
      postalCode
      employeesOnSite
      bikeSpaceCapacityInside
      bikeSpaceCapacityOutside
      bikeSpaceCapacityUnsafe
      carSpaceCapacity
      electricStationCapacity
      deployments {
        registeredCount
        beneficiaryCount
      }
    }
  }
`

export const useCompanySites = (
  companyId: string,
): QueryResult<GetCompanyAllSitesQuery, GetCompanyAllSitesQueryVariables> => {
  return useQuery(QUERY_COMPANY_ALL_SITES, {
    variables: {
      where: {
        companyId: { equals: companyId },
      },
    },
  })
}
