/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import Card from '.'

interface AuthenticationCardProps {
  title: string
}

const AuthenticationCard: React.FC<AuthenticationCardProps> = ({
  children,
  title,
}) => {
  const theme = useTheme()

  return (
    <Card
      css={{
        width: '100%',
        maxWidth: '480px',
        maxHeight: 'calc(100% - 64px)',
        [theme.media.mobile]: {
          boxShadow: 'none',
        },
      }}
      title={
        <p
          css={{
            textAlign: 'center',
            color: theme.colors.primary,
            fontFamily: 'Raleway',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            marginBottom: '40px',
          }}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      }
    >
      {children}
    </Card>
  )
}

export default AuthenticationCard
