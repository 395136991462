/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'

interface MunicipalityCellProps {
  value: { postalCode: string; city: string }
}

const MunicipalityCell: React.FC<MunicipalityCellProps> = ({
  value: { postalCode, city },
}) => (
  <span>
    {postalCode} {city}
  </span>
)

export default MunicipalityCell
