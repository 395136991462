/** @jsx jsx */
import { jsx } from '@emotion/core'
import Tooltip from '../../../../components/Tooltip'
import { NavLink } from 'react-router-dom'
import Icon from '../../../../components/Icon'
import React from 'react'
import { Flex } from '../../../../components/Flex'
import { useTranslation } from 'react-i18next'
import { ArchiveDeploymentButton } from './ArchiveDeploymentButton'
import { DeleteDeploymentButton } from './DeleteDeploymentButton'
import { EditDeploymentButton } from './EditDeploymentButton'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../redux/rootReducer'
import { UserRoles } from '../../../../redux/user'

export interface DeploymentActionsProps {
  deploymentId?: string
  stepNbr?: number
  archived: boolean
}

export const DeploymentActions: React.FC<DeploymentActionsProps> = ({
  deploymentId,
  stepNbr,
  archived,
}) => {
  const { t } = useTranslation()
  const role = useSelector((state: StoreState) => state.user.role)

  return (
    <Flex direction="row" justify="center" align="center" gap="8px">
      <Tooltip
        title={t('animator.dashboard.tooltips.view')}
        placement="top-end"
      >
        <NavLink
          to={
            role === UserRoles.ADMIN
              ? `/administrator/dashboard/${deploymentId}/progress`
              : `/animator/dashboard/${deploymentId}/progress`
          }
        >
          <Icon type="view" />
        </NavLink>
      </Tooltip>
      <Tooltip
        title={t('animator.dashboard.tooltips.edit')}
        placement="top-end"
        css={{ cursor: 'pointer' }}
      >
        <EditDeploymentButton id={deploymentId} />
      </Tooltip>
      <Tooltip
        title={
          archived
            ? t('animator.dashboard.tooltips.archived')
            : t('animator.dashboard.tooltips.archive')
        }
        placement="top-end"
        css={{ cursor: 'pointer' }}
      >
        <ArchiveDeploymentButton id={deploymentId} archived={archived} />
      </Tooltip>

      <Tooltip
        title={t('animator.dashboard.tooltips.delete')}
        placement="top-end"
        css={{ cursor: 'pointer' }}
      >
        <DeleteDeploymentButton id={deploymentId} />
      </Tooltip>
    </Flex>
  )
}
