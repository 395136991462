/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { downloadZip } from '../../../../components/Button/DownloadCSVButton'
import usePromiseLazyQuery from '../../../../hooks/usePromiseLazyQuery'

import {
  QUERY_ARCHIVED_DEPLOYMENT_DATA_EXPORT,
  formatArchivedDeploymentDataExport,
} from '../../../../utils/gql/archivedDeploymentDataExport'
import {
  QUERY_ARCHIVED_DEPLOYMENT_IDENTITY_EXPORT,
  formatArchivedDeploymentIdentityExport,
} from '../../../../utils/gql/archivedDeploymentIdentityExport'

import {
  GetArchivedDeploymentDataExportQuery,
  GetArchivedDeploymentDataExportQueryVariables,
  GetArchivedDeploymentIdentityExportQuery,
  GetArchivedDeploymentIdentityExportQueryVariables,
} from '../../../../__generated__/graphql'

import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../../../../components/ConfirmationModal'

export interface DownloadDeploymentModalProps
  extends Pick<ConfirmationModalProps, 'isOpen' | 'onClose'> {
  companyId: string
  id: string
  onCompleted?: () => void
}

export const DownloadDeploymentModal: React.FC<
  DownloadDeploymentModalProps
> = ({ companyId, id, onCompleted, ...rest }) => {
  const { t } = useTranslation()

  const loading = false
  const getArchivedDeploymentDataExport = usePromiseLazyQuery<
    GetArchivedDeploymentDataExportQuery,
    GetArchivedDeploymentDataExportQueryVariables
  >(QUERY_ARCHIVED_DEPLOYMENT_DATA_EXPORT, 'no-cache')

  const getArchivedDeploymentIdentityExport = usePromiseLazyQuery<
    GetArchivedDeploymentIdentityExportQuery,
    GetArchivedDeploymentIdentityExportQueryVariables
  >(QUERY_ARCHIVED_DEPLOYMENT_IDENTITY_EXPORT, 'no-cache')

  const getArchivedDeploymentDataExportData = React.useCallback(async () => {
    const { data } = await getArchivedDeploymentDataExport({
      companyId: companyId,
      deploymentId: id,
    })
    return formatArchivedDeploymentDataExport(data, id)
  }, [getArchivedDeploymentDataExport, id, companyId])

  const getArchivedDeploymentIdentityExportData =
    React.useCallback(async () => {
      const { data } = await getArchivedDeploymentIdentityExport({
        companyId: companyId,
        deploymentId: id,
      })
      return formatArchivedDeploymentIdentityExport(data)
    }, [getArchivedDeploymentIdentityExport, id, companyId])

  const onSubmit = async () => {
    await downloadZip(
      [
        {
          filename: 'archived-deployment-data.csv',
          data: await getArchivedDeploymentDataExportData(),
        },
        {
          filename: 'archived-deployment-identity.csv',
          data: await getArchivedDeploymentIdentityExportData(),
        },
      ],
      'archived-deployment.zip',
    ).then(() => {
      rest.onClose()
    })
  }

  return (
    <ConfirmationModal
      title={t('animator.deployments.downloadDeploymentArchiveModal.title')}
      description={t(
        'animator.deployments.downloadDeploymentArchiveModal.description',
      )}
      loading={loading}
      onSubmit={onSubmit}
      {...rest}
    />
  )
}

export default DownloadDeploymentModal
