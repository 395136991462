/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Modal from '@goodwatt/client/src/components/Modal'
import Popup from '@goodwatt/client/src/components/Popup'
import Button from '@goodwatt/client/src/components/Button'
import {
  useNotifications,
  NotificationTypes,
} from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import { QuizTableRow } from '../../../types/Quizzes'
import theme from '../../../styles/theme'
import {
  DeleteQuizMutation,
  DeleteQuizMutationVariables,
} from '../../../__generated__/graphql'

const MUTATION_DELETE_QUIZ = gql`
  mutation DeleteQuiz($id: String!) {
    deleteQuiz(id: $id) {
      id
    }
  }
`

interface DeleteModalProps {
  quiz: QuizTableRow
  isOpen: boolean
  closeModal: () => void
  updateList: () => void
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  quiz,
  isOpen,
  closeModal,
  updateList,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [deleteOneCompanyMonitoring, { loading }] = useMutation<
    DeleteQuizMutation,
    DeleteQuizMutationVariables
  >(MUTATION_DELETE_QUIZ)
  const onSubmit = () => {
    deleteOneCompanyMonitoring({
      variables: {
        id: quiz.id,
      },
    })
      .then(() => {
        updateList()
        closeModal()
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t(`quiz.modal.delete.successNotification`),
        })
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        closable
        separator={false}
        title={t('quiz.modal.delete.title')}
        titleColor={theme.colors.redLegend}
        onClose={closeModal}
        footer={
          <React.Fragment>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="tertiaryError"
                submit
                loading={loading}
                onClick={() => closeModal()}
              >
                {t('forms.button.cancel')}
              </Button>
            </div>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="primaryError"
                submit
                loading={loading}
                onClick={() => onSubmit()}
              >
                {t('forms.button.delete')}
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <p
          css={{ margin: '40px 0', textAlign: 'center' }}
          dangerouslySetInnerHTML={{
            __html: t('quiz.modal.delete.confirmationMessage', {
              content: quiz.title,
            }),
          }}
        ></p>
      </Popup>
    </Modal>
  )
}

export default DeleteModal
