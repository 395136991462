/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Link, LinkProps } from 'react-router-dom'
import theme from '../styles/theme'
import React from 'react'

export type NavigationLinkProps = Omit<LinkProps, 'css'>

export const NavigationLink: React.FC<NavigationLinkProps> = props => {
  return (
    <Link
      css={{
        color: theme.colors.primary,
        fontWeight: 500,
        textDecoration: 'underline',
      }}
      {...props}
    />
  )
}
