/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import {
  BikeComponentPartType,
  StateOfUseType,
} from '@goodwatt/shared/dist/types'

import Table from '../../../../../../components/Table'
import {
  PartsConditionModalSettings,
  PartsConditionTableRow,
  PartsConditionTableModal,
} from '../../../../../../types/AnimatorCompanyEmployees'
import PartsConditionActionCell from '../../../../../../components/Table/CustomCells/PartsConditionActionCell'
import PartModal from './PartModal'
import BikeComponentStarRatingCell from '../../../../../../components/Table/CustomCells/BikeComponentStarRatingCell'

import { StoreState } from '../../../../../../redux/rootReducer'
import { useDispatch, useSelector } from 'react-redux'
import {
  GetBikeComponentsQuery,
  GetBikeComponentsQueryVariables,
} from '../../../../../../__generated__/graphql'

const QUERY_BIKE_COMPONENTS = gql`
  query GetBikeComponents($bikeId: String!) {
    bike(where: { id: $bikeId }) {
      bikeComponents {
        id
        bikeComponentPart {
          name
        }
        note
        stateOfUse {
          name
        }
        bikeLoanAttachments {
          id
          size
          title
        }
      }
    }
  }
`

const initialModalSettings: PartsConditionModalSettings = {
  part: {
    id: '',
    name: '',
    stateOfUse: StateOfUseType.EXCELLENT,
    note: '',
    attachments: [],
  },
  actionName: PartsConditionTableModal.NONE,
}

interface BikeComponentsConditionProps {
  bikeId: string
  returningBike: boolean
  setFormAction: (
    payload: Partial<
      | StoreState['forms']['loanBikeForm']
      | StoreState['forms']['returnBikeForm']
    >,
  ) => void
}

const i18nBikeComponentConditionNamespace =
  'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeComponentsCondition'

const BikeComponentsCondition: React.FC<BikeComponentsConditionProps> = ({
  bikeId,
  returningBike,
  setFormAction,
}) => {
  const [currModal, setCurrModal] = React.useState(initialModalSettings)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { components, componentsFetched, bikeLoanId } = useSelector(
    (state: StoreState) => {
      return returningBike
        ? state.forms.returnBikeForm
        : state.forms.loanBikeForm
    },
  )
  const {
    data,
    loading,
    refetch: refetchBikeComponents,
  } = useQuery<GetBikeComponentsQuery, GetBikeComponentsQueryVariables>(
    QUERY_BIKE_COMPONENTS,
    {
      variables: { bikeId },
      fetchPolicy: 'network-only',
    },
  )
  React.useEffect(() => {
    if (data?.bike?.bikeComponents.length && !componentsFetched) {
      const freshComponents = data.bike.bikeComponents.reduce(
        (acc, component) => ({
          ...acc,
          [component.bikeComponentPart.name as BikeComponentPartType]: {
            touched:
              components[
                component.bikeComponentPart.name as BikeComponentPartType
              ].touched,
            id: component.id,
            stateOfUse: component.stateOfUse.name,
            note: component.note,
            attachments: component.bikeLoanAttachments,
          },
        }),
        {},
      )
      dispatch(
        setFormAction({
          componentsFetched: true,
          components: {
            ...components,
            ...freshComponents,
          },
        }),
      )
    }
  }, [components, componentsFetched, data, dispatch, setFormAction])

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<PartsConditionTableRow> }) => (
      <PartsConditionActionCell setModal={setCurrModal} part={row.original} />
    ),
    [],
  )

  const BikeConditionRatingCell = React.useCallback(
    ({ row }: { row: Row<PartsConditionTableRow & { touched: boolean }> }) => (
      <BikeComponentStarRatingCell
        returningBike={returningBike}
        bikeComponent={row.original}
        // @ts-ignore
        savedBikeComponents={data?.bike?.bikeComponents || []}
        onStarClickAction={setFormAction}
      />
    ),
    [returningBike, data, setFormAction],
  )

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: t(
          `${i18nBikeComponentConditionNamespace}.table.headers.bikeComponent`,
        ) as string,
        accessor: 'translatedName',
        disableSortBy: true,
        maxWidth: 200,
        cellStyle: () => ({ textAlign: 'left' }),
      },
      {
        Header: t(
          `${i18nBikeComponentConditionNamespace}.table.headers.conditionDescription`,
        ) as string,
        accessor: 'stateOfUse',
        disableSortBy: true,
        Cell: BikeConditionRatingCell,
      },
      {
        Header: t(
          `${i18nBikeComponentConditionNamespace}.table.headers.actions`,
        ) as string,
        accessor: 'action',
        maxWidth: 95,
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [ActionCell, BikeConditionRatingCell, t],
  )

  const formattedData = React.useMemo(() => {
    const componentsArray = Object.entries(components).map(([name, value]) => ({
      name,
      ...value,
    }))

    const itemsOrder = [
      BikeComponentPartType.BIKE_FRAME,
      BikeComponentPartType.BRAKES,
      BikeComponentPartType.BACK_WHEEL,
      BikeComponentPartType.FRONT_WHEEL,
      BikeComponentPartType.TRANSMISSIONS,
      BikeComponentPartType.HANDLEBAR,
      BikeComponentPartType.DEVICES,
      BikeComponentPartType.BATTERY,
      BikeComponentPartType.GLOBAL,
    ]

    return componentsArray
      .sort(
        (a, b) =>
          itemsOrder.indexOf(a.name as BikeComponentPartType) -
          itemsOrder.indexOf(b.name as BikeComponentPartType),
      )
      .map(component => ({
        ...component,
        translatedName: t(`shared.bikeComponentPartLabel.${component.name}`),
        touched: component.touched,
        action: '',
        note: component.note,
      }))
  }, [components, t])

  const onNoteModalSubmit = (textAreaValue: string, partName: string): void => {
    dispatch(
      setFormAction({
        components: {
          ...components,
          [partName]: {
            ...components[partName as BikeComponentPartType],
            touched: true,
            note: textAreaValue,
          },
        },
      }),
    )
    handleCloseNoteModal()
  }

  const handleCloseNoteModal = React.useCallback(async () => {
    await refetchBikeComponents()
    setCurrModal(initialModalSettings)
  }, [refetchBikeComponents])

  const partModalTitle = React.useMemo(() => {
    return `${t(
      `${i18nBikeComponentConditionNamespace}.bikeComponentPartModal.title`,
      { partName: t(`shared.bikeComponentPartLabel.${currModal.part.name}`) },
    )}`
  }, [currModal.part.name, t])

  return (
    <React.Fragment>
      <div css={{ minHeight: 380, padding: '0 25px' }}>
        <Table columns={columns} data={formattedData} loading={loading} />
      </div>
      <PartModal
        title={partModalTitle}
        label={t(
          `${i18nBikeComponentConditionNamespace}.bikeComponentPartModal.noteLabel`,
        )}
        componentType="bikeComponent"
        bikeLoanId={bikeLoanId}
        isOpen={
          currModal.actionName === PartsConditionTableModal.NOTE ||
          currModal.actionName === PartsConditionTableModal.CAMERA
        }
        shouldOpenCameraInput={
          currModal.actionName === PartsConditionTableModal.CAMERA
        }
        closeAutomaticCameraInput={() =>
          setCurrModal({
            ...currModal,
            actionName: PartsConditionTableModal.NOTE,
          })
        }
        closeModal={handleCloseNoteModal}
        part={currModal.part}
        setFormAction={setFormAction}
        onSubmit={onNoteModalSubmit}
      />
    </React.Fragment>
  )
}

export default BikeComponentsCondition
