/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import Button from '@goodwatt/client/src/components/Button'
import {
  AdviceTableAdd,
  AdviceTableModalActions,
  AdviceSettings,
  AdviceTableRow,
} from '@goodwatt/client/src/types/AnimatorAdvices'
import Table, { ColumnSortBy } from '@goodwatt/client/src/components/Table'
import AdviceActionCell from '@goodwatt/client/src/components/Table/CustomCells/AdviceActionCell'

import {
  QUERY_ANIMATOR_GET_ADVICE_TAGS,
  QUERY_ANIMATOR_GET_ADVICES,
  QUERY_COUNT_ADVICE,
} from '../../../../utils/gql/queries'
import AddOrEditModal from './AddOrEditModal'
import DeleteModal from './DeleteModal'

import { useTheme } from 'emotion-theming'
import Pagination from '../../../../components/Pagination'
import Icon from '../../../../components/Icon'
import DownloadCSVButton from '../../../../components/Button/DownloadCSVButton'
import usePromiseLazyQuery from '../../../../hooks/usePromiseLazyQuery'
import {
  AdminTableRefetchContext,
  AdminTableTypes,
} from '../../AdminTableRefetchContext'

import { initialColumnSortBy } from '../../Posts/PostList/PostList'
import {
  CountAdviceQuery,
  GetAdvicesGetPostsQuery,
  GetAdvicesGetPostsQueryVariables,
  GetAdviceTagsQuery,
} from '../../../../__generated__/graphql'

const initalModalSettings: AdviceSettings = {
  advice: {
    id: '',
    title: '',
    content: '',
    order: undefined,
    MIME: '',
    tags: [],
  },
  actionName: '',
}

interface AnimatorCompanyMonitoringProps {
  pageSize: number
}

const AnimatorCompanyMonitoringList: React.FC<
  AnimatorCompanyMonitoringProps
> = ({ pageSize }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [currModal, setCurrModal] =
    useState<AdviceSettings>(initalModalSettings)

  const [currPage, setCurrPage] = useState(1)

  const { data: adviceCount } = useQuery<CountAdviceQuery>(QUERY_COUNT_ADVICE)

  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)

  const {
    data: adviceData,
    refetch,
    loading,
  } = useQuery<GetAdvicesGetPostsQuery, GetAdvicesGetPostsQueryVariables>(
    QUERY_ANIMATOR_GET_ADVICES,
    {
      fetchPolicy: 'network-only',
      variables: {
        take: pageSize,
        skip: (currPage - 1) * pageSize,
        orderBy:
          orderBy.sort && orderBy.columnName
            ? [{ [orderBy.columnName]: orderBy.sort }]
            : undefined,
      },
    },
  )

  const getAllAdvice = usePromiseLazyQuery<
    GetAdvicesGetPostsQuery,
    GetAdvicesGetPostsQueryVariables
  >(QUERY_ANIMATOR_GET_ADVICES)

  const { data: tagsData } = useQuery<GetAdviceTagsQuery>(
    QUERY_ANIMATOR_GET_ADVICE_TAGS,
    {
      fetchPolicy: 'network-only',
    },
  )
  const ActionCell = React.useCallback(
    ({ row }: { row: Row<AdviceTableRow> }) => (
      <AdviceActionCell
        setModal={setCurrModal}
        row={row}
        actions={[AdviceTableModalActions.EDIT, AdviceTableModalActions.TRASH]}
      />
    ),
    [],
  )
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('animator.advice.table.headers.tags') as string,
        accessor: 'currentTag',
        cellStyle: () => ({ minWidth: '110px' }),
      },
      {
        Header: t('animator.advice.table.headers.order') as string,
        accessor: 'order',
      },
      {
        Header: t('animator.advice.table.headers.title') as string,
        accessor: 'title',
        maxWidth: 200,
      },
      {
        Header: t('animator.advice.table.headers.content') as string,
        accessor: 'contentCroped',
        sortField: 'content',
      },
      {
        Header: t(
          'animator.company.monitoring.table.headers.actions',
        ) as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [ActionCell, t],
  )
  const formattedData = useMemo(() => {
    if (!adviceData?.advicePosts?.length) return []

    return adviceData?.advicePosts?.map(advice => ({
      id: advice?.id,
      title: advice?.title,
      content: advice?.content,
      contentCroped: advice?.content.substr(0, 40),
      MIME: advice?.MIME,
      tags: advice?.tags,
      order: advice?.order,
      currentTag: advice?.tags[0]?.key,
    }))
  }, [adviceData])

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `conseils-${formatedDate}`

  const getExportData = async () => {
    const { data } = await getAllAdvice({
      take: 10_000,
      skip: 0,
    })

    if (!data?.advicePosts) return []

    return [
      ['Catégorie', 'Ordre', 'Titre du conseil', 'Text du conseil'],
      ...data.advicePosts.map(advice => ({
        categorie: advice?.tags.length ? advice.tags[0]?.key : 'N/a',
        ordre: advice?.order,
        title: advice?.title,
        content: advice?.content,
      })),
    ]
  }

  const { refetchTables } = React.useContext(AdminTableRefetchContext)

  const handleRefetch = () => {
    refetch()
    refetchTables([AdminTableTypes.ADVICE])
  }

  return (
    <React.Fragment>
      <div css={{ minHeight: 380 }}>
        <Table
          loading={loading}
          columns={columns}
          data={formattedData}
          onSortBy={setOrderBy}
        />
      </div>
      <div
        css={{
          position: 'relative',
          display: 'flex',
          marginTop: '41px',
          marginBottom: '31px',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={adviceCount?.adviceCount}
        />
        <div
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            paddingLeft: 77,
            [theme.media.mobile]: {
              paddingLeft: 0,
            },
          }}
        >
          <Button
            type="primary"
            submit
            onClick={() =>
              setCurrModal({
                ...initalModalSettings,
                actionName: AdviceTableAdd.ADD,
              })
            }
          >
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontSize: '1.6rem',
                fontWeight: 500,
                color: theme.colors.white,
              }}
            >
              <Icon
                strokeColor="white"
                type="plus"
                customSize={{ width: '16px', height: '16px' }}
              />
            </span>
          </Button>
          <DownloadCSVButton getData={getExportData} filename={filename} />
        </div>
        <AddOrEditModal
          advice={currModal.advice}
          dataList={tagsData}
          isOpen={
            currModal.actionName === AdviceTableAdd.ADD ||
            currModal.actionName === AdviceTableModalActions.EDIT
          }
          type={currModal.actionName}
          // companyId={companyId}
          closeModal={() => setCurrModal(initalModalSettings)}
          updateList={handleRefetch}
        />
        <DeleteModal
          advice={currModal.advice}
          isOpen={currModal.actionName === AdviceTableModalActions.TRASH}
          closeModal={() => setCurrModal(initalModalSettings)}
          updateList={handleRefetch}
        />
      </div>
    </React.Fragment>
  )
}

export default AnimatorCompanyMonitoringList
