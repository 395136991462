/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState } from 'react'
import { useTheme } from 'emotion-theming'
import { useSelector } from 'react-redux'

import Menu from './Menu'
import Disconnect from './Disconnect'
import AnimatorGuard, { ANIMATOR_GUARD_Z_INDEX } from '../AnimatorGuard'
import MobileHeader from './MobileHeader'

import useMenu from '../../hooks/useMenu'
import { StoreState } from '../../redux/rootReducer'
import useWindowSize from '../../hooks/useWindowSize'

const Layout: React.FC<{
  children: React.ReactNode
}> = ({ children }): JSX.Element => {
  const theme = useTheme()
  const user = useSelector((state: StoreState) => state.user)
  const menu = useMenu()
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
  const { width } = useWindowSize()

  if (!user.role || !menu[user.role]) {
    return <div>{children}</div>
  }

  return (
    <div
      css={{
        display: 'flex',
      }}
    >
      {user.animatorGuard && <AnimatorGuard />}
      {/* MENU */}
      <div
        css={{
          minWidth: '250px',
          backgroundColor: theme.colors.white,
          minHeight: '100vh',
          height: '100%',
          position: 'fixed',
          zIndex: ANIMATOR_GUARD_Z_INDEX - 3,
          [theme.media.tablet]: {
            transform: `translate(${menuIsOpen ? '0' : '-110vw'})`,
            transition: 'transform 0.3s',
            width: '100%',
            maxWidth: '100vw',
            height: '100vh',
            overflow: 'hidden',
          },
        }}
      >
        <Menu
          navList={menu[user.role]}
          closeMenu={() => setMenuIsOpen(false)}
        />
      </div>
      {/* LAYOUT */}
      <div
        css={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          padding: '50px',
          marginLeft: '250px',
          backgroundColor: theme.colors.gray7,
          overflow: 'hidden',
          [theme.media.tablet]: {
            padding: '0',
            marginLeft: 0,
          },
        }}
      >
        <div css={{ width: '100%', maxWidth: '1050px' }}>
          {width <= theme.breakpoints.tablet ? (
            <MobileHeader
              leftIcon="burgerMenu"
              onLeftIconClicked={() => setMenuIsOpen(true)}
            />
          ) : (
            <Disconnect closeMenu={() => setMenuIsOpen(false)} />
          )}
          <div
            css={{
              width: '100%',
              [theme.media.tablet]: {
                padding: '0 13px 13px 13px',
                maxWidth: '100vw',
                overflow: 'hidden',
              },
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
