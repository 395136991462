/** @jsx jsx */
import { useMutation, useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import { RestrictionType } from '@goodwatt/shared/dist/types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ActionCard from '../../../components/Card/ActionCard'
import DocumentCard from '../../../components/Card/DocumentCard'
import Loader from '../../../components/Loader'
import { StoreState } from '../../../redux/rootReducer'
import { QUERY_GET_COMPANY_DOCUMENTS } from '../../../utils/gql/queries'

import UpdateDocumentModal from '../../Shared/CompanyDocuments/UpdateDocumentsModal'
import UploadConvention from '../../Animator/Company/Documents/UploadConvention'
import UploadDocumentModal from '../../Shared/CompanyDocuments/UploadDocumentsModal'

import {
  MUTATION_UPLOAD_DOCUMENT_BY_COMPANY,
  accessibilitySelectOptions,
  MUTATION_UPDATE_DOCUMENT_BY_COMPANY,
} from './constants'
import {
  AccessibilityCompany,
  Document,
  GetCompanyDocumentsQuery,
  GetCompanyDocumentsQueryVariables,
  UpdateDocumentByCompanyMutation,
  UpdateDocumentByCompanyMutationVariables,
  UploadDocumentByCompanyMutation,
  UploadDocumentByCompanyMutationVariables,
} from '../../../__generated__/graphql'

const CompanyDocuments: React.FC = () => {
  const { t } = useTranslation()
  const userId = useSelector((state: StoreState) => state.user.id)
  const [editDocModal, setEditDocModal] = useState<Document | null>(null)
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false)
  // QUERY & MUTATIONS
  const [uploadDocument, { loading: uploadLoading }] = useMutation<
    UploadDocumentByCompanyMutation,
    UploadDocumentByCompanyMutationVariables
  >(MUTATION_UPLOAD_DOCUMENT_BY_COMPANY)
  const [updateDocument, { loading: updateLoading }] = useMutation<
    UpdateDocumentByCompanyMutation,
    UpdateDocumentByCompanyMutationVariables
  >(MUTATION_UPDATE_DOCUMENT_BY_COMPANY)
  const { data, refetch, loading } = useQuery<
    GetCompanyDocumentsQuery,
    GetCompanyDocumentsQueryVariables
  >(QUERY_GET_COMPANY_DOCUMENTS, {
    variables: {
      where: {
        userId,
      },
    },
  })

  if (loading && !data) return <Loader />

  return (
    <div>
      {data?.company?.conventionFilePath && (
        <UploadConvention
          deletable={false}
          hasConvention={!!data.company.conventionFilePath}
          companyId={data?.company?.id as string}
        />
      )}
      <div css={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {data?.company?.documentList?.map(doc => {
          if (!doc) return null
          return (
            <DocumentCard
              key={doc.id}
              id={doc.id}
              refetch={refetch}
              title={doc.title}
              filePath={doc.filePath}
              size={doc.size}
              date={doc.createdAt}
              documentRestrictions={doc.restrictions as string[]}
              deletable={doc.restrictions?.includes(RestrictionType.COMPANY_D)}
              downloadable={doc.restrictions?.includes(
                RestrictionType.COMPANY_R,
              )}
              onEdit={
                doc.restrictions?.includes(RestrictionType.COMPANY_W)
                  ? () => {
                      setEditDocModal(doc)
                    }
                  : undefined
              }
            />
          )
        })}
        <ActionCard
          variant="secondary"
          icon="uploadDoc"
          label={t('shared.documents.addDocument')}
          onCardClick={() => setAddModalOpen(true)}
        />
      </div>
      {editDocModal && (
        <UpdateDocumentModal
          loading={updateLoading}
          modalIsOpen={!!editDocModal}
          closeModal={() => setEditDocModal(null)}
          document={editDocModal}
          refetch={refetch}
          onUpdate={({ title, documentId, accessibility }) =>
            updateDocument({
              variables: {
                title,
                documentId,
                accessibility: accessibility as AccessibilityCompany,
              },
            })
          }
          accessibilitySelectOptions={accessibilitySelectOptions}
        />
      )}
      <UploadDocumentModal
        modalIsOpen={addModalOpen}
        closeModal={() => setAddModalOpen(false)}
        companyId={data?.company?.id as string}
        refetch={refetch}
        loading={uploadLoading}
        onUpload={({ title, file, accessibility }) =>
          uploadDocument({
            variables: {
              title,
              file,
              accessibility: accessibility as AccessibilityCompany,
            },
          })
        }
        accessibilitySelectOptions={accessibilitySelectOptions}
      />
    </div>
  )
}

export default CompanyDocuments
