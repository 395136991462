import React, { Fragment } from 'react'
import Icon from '../../../../components/Icon'
import { DeleteDeploymentEmployeeModale } from './DeleteDeploymentEmployeeModale'
import { useModal } from '../../../../hooks/useModal'

export interface DeleteDeploymentEmployeeButtonProps {
  deploymentEmployeeId: string
}

export const DeleteDeploymentEmployeeButton: React.FC<
  DeleteDeploymentEmployeeButtonProps
> = ({ deploymentEmployeeId }) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <DeleteDeploymentEmployeeModale
        {...modalProps}
        deploymentEmployeeId={deploymentEmployeeId}
      />
      <button onClick={onOpen}>
        <Icon type="trash" />
      </button>
    </Fragment>
  )
}
