/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Icon from '../../../components/Icon'
import Modal from '../../../components/Modal'
import Popup from '../../../components/Popup'

import {
  NotificationTypes,
  useNotifications,
} from '../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import { useTheme } from 'emotion-theming'
import Input from '../../../components/Input'
import { MUTATION_EDIT_PASSWORD } from '../../../utils/gql/mutations'
import { changePasswordSchema } from '../../../utils/schemaValidation'
import {
  EditPasswordMutation,
  EditPasswordMutationVariables,
} from '../../../__generated__/graphql'

type Form = {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

const ModifyPassword: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const notifications = useNotifications()
  const theme = useTheme()
  const { t } = useTranslation()
  const [editPassword, { loading }] = useMutation<
    EditPasswordMutation,
    EditPasswordMutationVariables
  >(MUTATION_EDIT_PASSWORD)

  const { register, handleSubmit, errors, reset } = useForm<Form>({
    resolver: yupResolver(changePasswordSchema),
    mode: 'onBlur',
  })

  const onSubmit = (data: Form) => {
    editPassword({
      variables: {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      },
    })
      .then(result => {
        if (result?.data?.editPassword) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.notification.passwordSuccessUpdate'),
          })
          reset({ oldPassword: '', newPassword: '', confirmNewPassword: '' })
          setModalOpen(false)
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Fragment>
      <div
        css={{
          display: 'flex',
          flex: 1,
          height: '100%',
          minHeight: '147px',
          // width: '230px',
          cursor: 'pointer',
        }}
      >
        <Card
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            height: '100%',
            backgroundColor: theme.colors.yellowLegend,
          }}
          onClick={() => setModalOpen(true)}
        >
          <Icon
            color={theme.colors.primary}
            type="key"
            customSize={{ width: 120, height: 120 }}
          />
          <div>
            <p
              css={{
                fontFamily: theme.fontFamily,
                fontWeight: 'bold',
                fontSize: '1.8rem',
                lineHeight: '32px',
                color: theme.colors.gray1,
                textAlign: 'center',
              }}
            >
              {t('shared.profile.editPassword')}
            </p>
          </div>
        </Card>
      </div>
      <Modal isOpen={modalOpen}>
        <Popup
          maxWidth={750}
          title={t('shared.profile.editPassword')}
          closable
          overflowY="visible"
          onClose={() => setModalOpen(false)}
          footer={
            <Fragment>
              <Button
                type="tertiary"
                disabled={loading}
                onClick={() => setModalOpen(false)}
              >
                {t('forms.button.cancel')}
              </Button>
              <div css={{ marginLeft: '14px' }}>
                <Button
                  submit
                  loading={loading}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t('forms.button.confirm')}
                </Button>
              </div>
            </Fragment>
          }
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontSize: '1.6rem',
              lineHeight: '24px',
              padding: '0 28px',
              [theme.media.tablet]: {
                padding: '0 0',
              },
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} css={{ width: '100%' }}>
              <div css={{ marginTop: '18px', width: '100%' }}>
                <Input
                  type="password"
                  name="oldPassword"
                  label={t('forms.label.oldPassword')}
                  defaultValue=""
                  error={!!errors.oldPassword}
                  helperText={errors.oldPassword?.message}
                  register={register}
                />
                <Input
                  type="password"
                  name="newPassword"
                  label={t('forms.label.newPassword')}
                  defaultValue=""
                  error={!!errors.newPassword}
                  helperText={errors.newPassword?.message}
                  register={register}
                />
                <Input
                  type="password"
                  name="confirmNewPassword"
                  label={t('forms.label.confirmNewPassword')}
                  defaultValue=""
                  error={!!errors.confirmNewPassword}
                  helperText={errors.confirmNewPassword?.message}
                  register={register}
                />
              </div>
            </form>
          </div>
        </Popup>
      </Modal>
    </Fragment>
  )
}

export default ModifyPassword
