/** @jsx jsx */
import { jsx } from '@emotion/core'
import { DeploymentType } from '../../../../__generated__/graphql'

const configs: Partial<
  Record<DeploymentType, { label: string; color: string }>
> = {
  [DeploymentType.Rental]: {
    label: 'L',
    color: '#d2d2d2',
  },
  [DeploymentType.Discovery]: {
    label: 'D',
    color: '#b9b9b9',
  },
}

export interface DeploymentTypeLabelProps {
  type: DeploymentType
}

export const DeploymentTypeLabel: React.FC<DeploymentTypeLabelProps> = ({
  type,
}) => {
  const config = configs[type]

  return config ? (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        borderRadius: '100%',
        fontSize: '14px',
        fontWeight: 700,
        width: '20px',
        height: '20px',
        background: config.color,
      }}
    >
      {config.label}
    </div>
  ) : null
}
