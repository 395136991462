/** @jsx jsx */
import { jsx } from '@emotion/core'
import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'
import theme from '../../../../styles/theme'
import React from 'react'
import Button from '../../../../components/Button'
import { useTranslation } from 'react-i18next'
import { useDeleteDeploymentEmployee } from '../hooks/useDeleteDeploymentEmployee'

export interface DeleteDeploymentEmployeeModale {
  deploymentEmployeeId: string
  isOpen: boolean
  onClose: () => void
}

export const DeleteDeploymentEmployeeModale: React.FC<
  DeleteDeploymentEmployeeModale
> = ({ deploymentEmployeeId, isOpen, onClose }) => {
  const { t } = useTranslation()
  const [mutate, { loading }] = useDeleteDeploymentEmployee({
    onCompleted: onClose,
  })

  return (
    <Modal isOpen={isOpen}>
      <Popup
        closable
        separator={false}
        titleColor={theme.colors.redLegend}
        title={t('animator.deployments.users.delete')}
        onClose={onClose}
        footer={
          <React.Fragment>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="tertiaryError"
                submit
                loading={loading}
                onClick={onClose}
              >
                {t('forms.button.cancel')}
              </Button>
            </div>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="primaryError"
                submit
                loading={loading}
                onClick={async () => {
                  await mutate({
                    variables: { id: deploymentEmployeeId },
                  })
                }}
              >
                {t('forms.button.delete')}
              </Button>
            </div>
          </React.Fragment>
        }
      ></Popup>
    </Modal>
  )
}
