/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'

import AdminTableRefetchContext from '../AdminTableRefetchContext'

import Card from '@goodwatt/client/src/components/Card'
import PostList from './PostList/PostList'
import TagList from './TagList/TagList'

const PAGESIZE = 7

const CompanyMonitoring: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goodwatt Admin - FAQ</title>
        <link rel="canonical" href="https://goodwatt.fr/animator/post" />
      </Helmet>

      <AdminTableRefetchContext>
        <Card
          css={{ marginBottom: '30px' }}
          title={
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontWeight: 'bold',
                fontSize: '1.6rem',
                paddingBottom: '1rem',
                marginBottom: 24,
              }}
            >
              {t('animator.post.dashboardTitleLabel')}
            </span>
          }
        >
          <PostList pageSize={PAGESIZE} />
        </Card>

        <Card
          css={{ marginBottom: '30px' }}
          title={
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontWeight: 'bold',
                fontSize: '1.6rem',
                paddingBottom: '1rem',
                marginBottom: 24,
              }}
            >
              {t('admin.post.tagTitleLabel')}
            </span>
          }
        >
          <TagList pageSize={PAGESIZE} />
        </Card>
      </AdminTableRefetchContext>
    </div>
  )
}

export default CompanyMonitoring
