/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import Button from '@goodwatt/client/src/components/Button'
import {
  PostTableAdd,
  PostTableModalActions,
  PostSettings,
  PostTableRow,
} from '@goodwatt/client/src/types/AnimatorPosts'
import Table, { ColumnSortBy } from '@goodwatt/client/src/components/Table'
import PostActionCell from '@goodwatt/client/src/components/Table/CustomCells/PostActionCell'

import AddOrEditModal from './AddOrEditModal'
import DeleteModal from './DeleteModal'
import {
  QUERY_ANIMATOR_GET_POSTS,
  QUERY_ANIMATOR_GET_TAGS,
  QUERY_COUNT_POST,
} from '../../../../utils/gql/queries'

import { useTheme } from 'emotion-theming'
import Icon from '../../../../components/Icon'
import Pagination from '../../../../components/Pagination'
import DownloadCSVButton from '../../../../components/Button/DownloadCSVButton'
import usePromiseLazyQuery from '../../../../hooks/usePromiseLazyQuery'
import {
  AdminTableRefetchContext,
  AdminTableTypes,
} from '../../AdminTableRefetchContext'
import {
  CountPostQuery,
  GetPostsQuery,
  GetPostsQueryVariables,
  GetTagsQuery,
  GetTagsQueryVariables,
} from '../../../../__generated__/graphql'

const initalModalSettings: PostSettings = {
  post: {
    id: '',
    title: '',
    order: undefined,
    content: '',
    MIME: '',
    tags: [],
  },
  actionName: '',
}

interface AnimatorCompanyMonitoringProps {
  pageSize: number
}

export const initialColumnSortBy: ColumnSortBy = {
  columnName: '',
  sort: false,
}

const AnimatorCompanyMonitoringList: React.FC<
  AnimatorCompanyMonitoringProps
> = ({ pageSize }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [currModal, setCurrModal] = useState<PostSettings>(initalModalSettings)
  const [currPage, setCurrPage] = useState(1)
  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)

  const { data: postCount } = useQuery<CountPostQuery>(QUERY_COUNT_POST)

  const {
    data: postData,
    refetch,
    loading,
  } = useQuery<GetPostsQuery, GetPostsQueryVariables>(
    QUERY_ANIMATOR_GET_POSTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        skip: (currPage - 1) * pageSize,
        take: pageSize,
        orderBy:
          orderBy.sort && orderBy.columnName
            ? [{ [orderBy.columnName]: orderBy.sort }]
            : undefined,
      },
    },
  )

  const getAllPost = usePromiseLazyQuery<GetPostsQuery, GetPostsQueryVariables>(
    QUERY_ANIMATOR_GET_POSTS,
  )

  const { data: tagsData } = useQuery<GetTagsQuery, GetTagsQueryVariables>(
    QUERY_ANIMATOR_GET_TAGS,
    {
      fetchPolicy: 'network-only',
      variables: {
        take: 10_000,
        skip: 0,
      },
    },
  )

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<PostTableRow> }) => (
      <PostActionCell
        setModal={setCurrModal}
        row={row}
        actions={[PostTableModalActions.EDIT, PostTableModalActions.TRASH]}
      />
    ),
    [],
  )
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('animator.post.table.headers.tags') as string,
        accessor: 'currentTag',
      },
      {
        Header: t('animator.post.table.headers.order') as string,
        accessor: 'order',
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: t('animator.post.table.headers.title') as string,
        accessor: 'titleCroped',
        sortField: 'title',
      },
      {
        Header: t('animator.post.table.headers.content') as string,
        accessor: 'contentCroped',
        sortField: 'content',
        cellStyle: () => ({ minWidth: '400px' }),
      },
      {
        Header: t(
          'animator.company.monitoring.table.headers.actions',
        ) as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [ActionCell, t],
  )
  const formattedData = useMemo(() => {
    if (!postData?.posts?.length) return []

    return postData?.posts?.map(post => ({
      id: post?.id,
      title: post?.title,
      titleCroped:
        (post?.title || '').length > 30
          ? post?.title.substring(0, 30) + '...'
          : post?.title,
      order: post?.order || undefined,
      content: post?.content,
      contentCroped:
        (post?.content || '').length > 30
          ? post?.content.substring(0, 40) + '...'
          : post?.content,
      MIME: post?.MIME,
      tags: post?.tags,
      currentTag: post?.tags[0]?.key,
    }))
  }, [postData])

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `questions-${formatedDate}`

  const getExportData = async () => {
    const { data } = await getAllPost({
      take: 10_000,
      skip: 0,
    })

    if (!data?.posts) return []

    return [
      ['Catégorie', 'Ordre', 'Question', 'Réponse'],
      ...data.posts.map(post => ({
        categorie: post?.tags.length ? post.tags[0]?.key : 'N/a',
        ordre: post?.order,
        question: post?.title,
        anwser: post?.content,
      })),
    ]
  }

  const { refetchTables } = React.useContext(AdminTableRefetchContext)

  const handleRefetch = () => {
    refetch()
    refetchTables([AdminTableTypes.FAQ])
  }

  return (
    <React.Fragment>
      <div css={{ minHeight: 380 }}>
        <Table
          loading={loading}
          columns={columns}
          data={formattedData}
          onSortBy={setOrderBy}
        />
      </div>
      <div
        css={{
          position: 'relative',
          display: 'flex',
          marginTop: '41px',
          marginBottom: '31px',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={postCount?.postCount}
        />
        <div
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            paddingLeft: 77,
            [theme.media.mobile]: {
              paddingLeft: 0,
            },
          }}
        >
          <Button
            type="primary"
            submit
            onClick={() =>
              setCurrModal({
                ...initalModalSettings,
                actionName: PostTableAdd.ADD,
              })
            }
          >
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontSize: '1.6rem',
                fontWeight: 500,
                color: theme.colors.white,
              }}
            >
              <Icon
                strokeColor="white"
                type="plus"
                customSize={{ width: '16px', height: '16px' }}
              />
            </span>
          </Button>
          <DownloadCSVButton getData={getExportData} filename={filename} />
        </div>
        <AddOrEditModal
          post={currModal.post}
          dataList={tagsData}
          isOpen={
            currModal.actionName === PostTableAdd.ADD ||
            currModal.actionName === PostTableModalActions.EDIT
          }
          type={currModal.actionName}
          // companyId={companyId}
          closeModal={() => setCurrModal(initalModalSettings)}
          updateList={handleRefetch}
        />
        <DeleteModal
          post={currModal.post}
          isOpen={currModal.actionName === PostTableModalActions.TRASH}
          closeModal={() => setCurrModal(initalModalSettings)}
          updateList={handleRefetch}
        />
      </div>
    </React.Fragment>
  )
}

export default AnimatorCompanyMonitoringList
