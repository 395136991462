/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useTheme } from 'emotion-theming'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'

import Modal from '../../../../../components/Modal'
import InputSelect from '../../../../../components/InputSelect'
import TextArea from '../../../../../components/TextArea'
import Button from '../../../../../components/Button'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../../contexts/NotificationContext'
import { TableTypes } from '../TableRefetchContext'

import { PendingCompaniesTableRow } from '../../../../../types/AnimatorCompanies'

import i18n from '../../../../../i18n/config'
import apiErrorCatcher from '../../../../../utils/apiErrorCatcher'
import { useTranslation } from 'react-i18next'
import Popup from '../../../../../components/Popup'
import {
  GetRejectionReaonsQuery,
  RefuseCompanyMutation,
  RefuseCompanyMutationVariables,
} from '../../../../../__generated__/graphql'

const QUERY_REJECTION_REASONS = gql`
  query GetRejectionReaons {
    rejectionReasons {
      label
    }
  }
`

const MUTATION_REFUSE_COMPANY = gql`
  mutation RefuseCompany(
    $companyId: String!
    $rejectionReason: String!
    $note: String
  ) {
    refuseCompany(
      companyId: $companyId
      rejectionReason: $rejectionReason
      note: $note
    )
  }
`

const schema = yup.object().shape({
  rejectionReason: yup
    .mixed()
    .test(
      'Rejection Reason',
      i18n.t('forms.errors.required'),
      value => !!value?.value,
    ),
})

interface InputRefuseCompany {
  rejectionReason: { value: string; label: string }
  note: string
}

interface RefuseModalProps {
  isOpen: boolean
  closeModal: (tablesToRefetch?: TableTypes[]) => void
  company: PendingCompaniesTableRow
}

const RefuseModal: React.FC<RefuseModalProps> = ({
  isOpen,
  closeModal,
  company,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { data } = useQuery<GetRejectionReaonsQuery>(QUERY_REJECTION_REASONS)
  const [refuseCompany, { loading }] = useMutation<
    RefuseCompanyMutation,
    RefuseCompanyMutationVariables
  >(MUTATION_REFUSE_COMPANY)
  const notifications = useNotifications()
  const { handleSubmit, errors, control, register } =
    useForm<InputRefuseCompany>({
      resolver: yupResolver(schema),
      mode: 'onBlur',
    })

  const selectData = React.useMemo(
    () =>
      data?.rejectionReasons.map(reason => ({
        value: reason.label,
        label: t(`animator.companies.refuseCompany.options.${reason.label}`),
      })) ?? [],
    [data, t],
  )

  const onSubmit = React.useCallback(
    (data: InputRefuseCompany) => {
      refuseCompany({
        variables: {
          companyId: company.id || '',
          rejectionReason: data.rejectionReason.value,
          note: data.note,
        },
      })
        .then(() => {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: i18n.t(
              'animator.companies.refuseCompany.successNotification',
              { companyName: company.name },
            ),
          })
          closeModal([TableTypes.PENDING, TableTypes.REFUSED])
        })
        .catch(apiErrorCatcher(notifications))
    },
    [refuseCompany, company, notifications, closeModal],
  )

  return (
    <Modal isOpen={isOpen}>
      <Popup
        title={i18n.t('animator.companies.refuseCompany.title')}
        titleColor={theme.colors.redLegend}
        closable
        maxWidth={750}
        onClose={closeModal as () => void}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button
                disabled={loading}
                onClick={() => closeModal()}
                type="tertiaryError"
              >
                {i18n.t('shared.button.cancel')}
              </Button>
            </div>
            <Button
              submit
              type="primaryError"
              loading={loading}
              onClick={handleSubmit(onSubmit)}
            >
              {i18n.t('shared.button.confirm')}
            </Button>
          </Fragment>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span
              css={{
                textAlign: 'center',
                paddingLeft: '75px',
                paddingRight: '75px',
                marginBottom: '40px',
              }}
            >
              {i18n.t('animator.companies.refuseCompany.warning')}
            </span>
            <span
              css={{ marginBottom: '15px' }}
              dangerouslySetInnerHTML={{
                __html: i18n.t('animator.companies.refuseCompany.mainReason'),
              }}
            />
            <div css={{ maxWidth: '500px', width: '100%', marginBottom: 12 }}>
              <Controller
                name="rejectionReason"
                control={control}
                defaultValue={null}
                render={({ onChange, onBlur, value, name }) => (
                  <InputSelect
                    block
                    name={name}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    placeholder={i18n.t('shared.placeholder.inputSelect')}
                    options={selectData}
                    error={!!errors.rejectionReason}
                    helperText={errors?.rejectionReason?.value?.message}
                  />
                )}
              />
            </div>
            <div css={{ width: '100%', marginBottom: 25 }}>
              <TextArea
                name="note"
                type="text"
                block
                withError={false}
                register={register}
                placeholder={i18n.t('shared.placeholder.optionalComment')}
                error={!!errors.note}
                helperText={errors?.note?.message}
              />
            </div>
            <span
              css={{ textAlign: 'center' }}
              dangerouslySetInnerHTML={{
                __html: i18n.t(
                  'animator.companies.refuseCompany.confirmation',
                  {
                    companyName: company.name,
                    companyCity: company.municipality.city,
                  },
                ),
              }}
            />
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default RefuseModal
