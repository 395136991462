/** @jsx jsx */
import { useCallback, useMemo, useState } from 'react'
import { TFunction } from 'i18next'
import { jsx } from '@emotion/core'
import { debounce } from 'lodash'
import { useHistory } from 'react-router-dom'
import {
  DeploymentType,
  GetSiteDeploymentsQuery,
  GetSiteDeploymentsQueryVariables,
  Site,
  DeploymentArchiveStatus,
} from '../../../__generated__/graphql'
import { ThemeType } from 'emotion-theming'
import { Column, Row } from 'react-table'
import { format } from 'date-fns'
import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '../../../components/Table'
import Pagination from '../../../components/Pagination'
import Icon from '../../../components/Icon'
import { programStepFilterOptions } from '../../Animator/Dashboard/DeploymentsTable'
import { QUERY_GET_SITE_DEPLOYMENTS } from '../../../utils/gql/queries'
import { useQuery } from '@apollo/client'
import InputSelect from '../../../components/InputSelect'
import { SelectVariant } from '../../../hooks/useVariant/variants/select'
import Input from '../../../components/Input'
import { DeploymentTypeIcon } from '../../Animator/Shared/DeploymentTypeIcon'

const PAGE_SIZE = 8

interface SiteDeploymentTableProps {
  siteId: Site['id']
  theme: ThemeType
  t: TFunction
}
interface Filters {
  deployment_type?: DeploymentType
  programStep?: string
  multiFilter?: string
}

export function SiteDeploymentTable({
  siteId,
  theme,
  t,
}: SiteDeploymentTableProps): JSX.Element {
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<Filters>({})
  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)
  const history = useHistory()
  const { data, loading } = useQuery<
    GetSiteDeploymentsQuery,
    GetSiteDeploymentsQueryVariables
  >(QUERY_GET_SITE_DEPLOYMENTS, {
    fetchPolicy: 'network-only',
    variables: {
      siteId: siteId,
      skip: (page - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
      orderBy:
        orderBy.sort && orderBy.columnName
          ? { [orderBy.columnName]: orderBy.sort }
          : undefined,

      deploymentType: filters.deployment_type,
      programStep: filters.programStep ? parseInt(filters.programStep, 10) : 0,
      multiFilter: filters.multiFilter,
    },
  })

  const formattedData = useMemo(
    () =>
      data?.siteDeployments?.map(d => ({
        id: d?.id,
        name: d?.name,
        deploymentStartDate: d?.deploymentStartDate
          ? format(new Date(d.deploymentStartDate), 'dd/MM/yyyy')
          : undefined,
        deploymentEndDate: d?.deploymentEndDate
          ? format(new Date(d.deploymentEndDate), 'dd/MM/yyyy')
          : undefined,
        programStep: d?.programStep?.stepNbr,
        employeesCount: d?.employeesCount,
        deploymentType: d?.deploymentType,
        selectedEmployeesCount: d?.selectedEmployeesCount,
        candidateCount: d?.candidateCount,
        beneficiaryCount: d?.beneficiaryCount,
        archiveStatus: d?.archiveStatus,
      })) ?? [],
    [data],
  )

  const onSelectChange = useCallback(({ value }, { name }) => {
    setFilters(prevState => ({
      ...prevState,
      [name]: value !== 'all' ? value : undefined,
    }))
  }, [])
  const updateMultiFilter = useMemo(
    () =>
      debounce(value => {
        setFilters(prevState => ({
          ...prevState,
          multiFilter: value || undefined,
        }))
      }, 300),
    [],
  )

  const onMultiFilterChange = useCallback(
    e => {
      /* signal to React not to nullify the event object */
      e.persist()

      updateMultiFilter(e.target.value)
    },
    [updateMultiFilter],
  )

  const ProgramStepCell = useCallback(
    ({ row }: { row: Row<typeof formattedData[0]> }) => (
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <DeploymentTypeIcon
          type={row.original?.deploymentType as DeploymentType}
        />
      </div>
    ),
    [formattedData],
  )
  const EmployeesCountCell = useCallback(
    ({ row }: { row: Row<typeof formattedData[0]> }) => (
      <div>
        {row.original.candidateCount}{' '}
        <span css={{ color: 'hsla(0, 0%, 51%, 1)', fontSize: 14 }}>
          ({row.original.beneficiaryCount || 0})
        </span>
      </div>
    ),
    [formattedData],
  )
  const ActionCell = useCallback(
    ({ row }: { row: Row<typeof formattedData[0]> }) => {
      const isArchived =
        row.original.archiveStatus === DeploymentArchiveStatus.Archived

      return (
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            cursor: !isArchived ? 'pointer' : 'cursor',
          }}
          onClick={() => {
            !isArchived &&
              history.push(`/company/dashboard/${row.original.id}/monitoring`)
          }}
        >
          <Icon
            type={isArchived ? 'archive' : 'view'}
            strokeColor={isArchived ? theme.colors.primary : ''}
            color={isArchived ? 'transparent' : theme.colors.primary}
          />
        </div>
      )
    },
    [theme, formattedData, history],
  )

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('company.tables.headers.startDate') as string,
        accessor: 'deploymentStartDate',
        cellStyle: () => ({ minWidth: '110px' }),
        sortType: (a, b) =>
          new Date(b.values.date).getTime() - new Date(a.values.date).getTime(),
      },
      {
        Header: t('company.tables.headers.endDate') as string,
        accessor: 'deploymentEndDate',
        maxWidth: 200,
        sortType: (a, b) =>
          new Date(b.values.date).getTime() - new Date(a.values.date).getTime(),
      },
      {
        Header: t('company.tables.headers.name') as string,
        accessor: 'name',
        maxWidth: 250,
        cellStyle: () => ({
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }),
      },
      {
        Header: () => (
          <div>
            Candidats <div css={{ fontSize: 12 }}>(Bénéficiaires)</div>
          </div>
        ),
        accessor: 'employeesCount',
        disableSortBy: true,
        Cell: EmployeesCountCell,
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: () => (
          <div css={{ width: '100%', paddingLeft: '14px' }}>
            {t('company.tables.headers.deployment_type')}
          </div>
        ),
        accessor: 'deploymentType',
        Cell: ProgramStepCell,
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [t, ActionCell, ProgramStepCell, EmployeesCountCell],
  )

  return (
    <div>
      <TableHeader
        t={t}
        theme={theme}
        onSelectChange={onSelectChange}
        onMultiFilterChange={onMultiFilterChange}
      />
      <Table
        columns={columns}
        data={formattedData}
        onSortBy={setOrderBy}
        loading={loading}
      />
      <div
        css={{
          marginTop: '41px',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Pagination
          currentPage={page}
          onClick={setPage}
          pageSize={PAGE_SIZE}
          totalData={data?.siteDeploymentsCount || 0}
        />
      </div>
    </div>
  )
}

interface TableHeaderProps {
  t: TFunction
  theme: ThemeType
  onSelectChange: ({ value }: any, { name }: any) => void
  onMultiFilterChange: (e: any) => void
}
function TableHeader({
  t,
  theme,
  onSelectChange,
  onMultiFilterChange,
}: TableHeaderProps) {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginTop: '2.1rem',
        marginBottom: '2.1rem',
        flexWrap: 'wrap',
        width: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          minWidth: '220px',
          paddingRight: '17px',
          width: '100%',
          gap: '18px',
          [theme.media.mobile]: {
            paddingRight: '0px',
          },
        }}
      >
        <div css={{ minWidth: '150px' }}>
          <InputSelect
            name="deployment_type"
            variant={SelectVariant.white}
            withError={false}
            label={t('animator.dashboard.filter.deployment_type')}
            defaultValue="all"
            options={[
              {
                value: 'all',
                label: t('animator.companies.filter.options.all_feminine'),
              },

              {
                value: DeploymentType.Discovery,
                label: t(
                  `animator.dashboard.filter.options.deployment_types.${DeploymentType.Discovery}`,
                ),
              },

              {
                value: DeploymentType.Rental,
                label: t(
                  `animator.dashboard.filter.options.deployment_types.${DeploymentType.Rental}`,
                ),
              },
            ]}
            onChange={onSelectChange}
          />
        </div>
        <div css={{ minWidth: '200px' }}>
          <InputSelect
            name="programStep"
            variant={SelectVariant.white}
            withError={false}
            label={t('company.tables.filters.status')}
            defaultValue="all"
            options={[
              {
                value: 'all',
                label: t('animator.companies.filter.options.all'),
              },
              ...programStepFilterOptions,
            ]}
            onChange={onSelectChange}
          />
        </div>
        <div
          css={{
            display: 'flex',
            flex: 2,
            alignItems: 'flex-end',
            minWidth: '220px',
            maxWidth: '322px',
            [theme.media.tablet]: {
              marginTop: '14px',
            },
          }}
        >
          <Input
            icon="magnifier"
            name="name"
            variant={undefined}
            withError={false}
            onChange={onMultiFilterChange}
          />
        </div>
      </div>
    </div>
  )
}
