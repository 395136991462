import { ThemeType } from 'emotion-theming'

export enum DateTimeVariant {
  GRAY = 'gray',
  WHITE = 'white',
}

export type ElementsConfig = {
  bckgColor: string
  border: string
}

export type DateTimeVariantsConfig = {
  [variant in DateTimeVariant]: ElementsConfig
}

export default (theme: ThemeType): DateTimeVariantsConfig => ({
  [DateTimeVariant.GRAY]: {
    bckgColor: theme.colors.gray6,
    border: `2px solid ${theme.colors.gray6}`,
  },
  [DateTimeVariant.WHITE]: {
    bckgColor: theme.colors.white,
    border: `1px solid ${theme.colors.gray4}`,
  },
})
