/** @jsx jsx */
import { jsx, Global } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'

import Icon, { IconTypes } from './Icon'
import useVariant from '../hooks/useVariant'
import {
  DateTimeVariant,
  DateTimeVariantsConfig,
} from '../hooks/useVariant/variants/dateTime'
import fr from 'date-fns/locale/fr'
import 'react-datepicker/dist/react-datepicker.min.css'

registerLocale('fr', fr)

export interface DateTimeProps {
  type?: 'date' | 'time' | 'dateTime'
  value?: Date | null | undefined
  label?: string
  variant?: DateTimeVariant
  icon?: IconTypes | null
  block?: boolean
  error?: boolean
  helperText?: string
  disabled?: boolean
  popperPlacement?: string
  placeholder?: string
  onChange(
    date: Date | [Date, Date] | null,
    event: React.SyntheticEvent<unknown, Event> | undefined,
  ): void
  withError?: boolean
  disableDatePicker?: boolean
}

const DateTime: React.FC<DateTimeProps> = ({
  type = 'date',
  value,
  label,
  icon = 'calendar',
  variant = DateTimeVariant.GRAY,
  popperPlacement = 'bottom-end',
  block = true,
  error,
  helperText,
  disabled,
  onChange,
  withError = true,
  disableDatePicker = false,
  placeholder,
}): JSX.Element => {
  const dateTimeVariantConfig = useVariant('dateTime')[
    variant
  ] as DateTimeVariantsConfig[typeof variant]
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <div
      css={{
        opacity: disabled ? 0.3 : 1,
        minWidth: type === 'dateTime' ? '290px' : 'initial',
        width: block ? '100%' : 'fit-content',
      }}
    >
      <div
        css={{
          paddingLeft: 24,
          marginBottom: 4,
        }}
      >
        <label
          css={{
            fontSize: '1.6rem',
            color: theme.colors.gray3,
          }}
        >
          {label}
        </label>
      </div>
      <div
        css={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 44,
          backgroundColor: dateTimeVariantConfig.bckgColor,
          borderRadius: 22,
          boxSizing: 'border-box',
          border: error
            ? `2px solid ${theme.colors.error}`
            : dateTimeVariantConfig.border,
          transition: 'border 0.2s ease-in',
        }}
      >
        {icon && type !== 'time' ? (
          <div
            css={{
              position: 'absolute',
              top: '10px',
              right: '13px',
            }}
          >
            <Icon
              type={icon}
              customSize={{ width: 19, height: 19 }}
              color={theme.colors.gray3}
            />
          </div>
        ) : null}
        <Global
          styles={{
            ...(disableDatePicker && {
              '.react-datepicker__tab-loop': {
                display: 'none',
              },
            }),
            '.react-datepicker__input-container input': {
              padding: '0 45px 0 19px',
              background: 'rgb(0 0 0 / 0%)',
            },
            '.react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker__input-container input':
              {
                borderRadius: 22,
                width: '100%',
                height: 'calc(100% - 1px)',
                boxSizing: 'border-box',
                fontSize: '1.6rem',
                fontFamily: 'Roboto',
                color: theme.colors.gray1,
              },
            '.react-datepicker': {
              fontSize: '1.3rem !important',
            },

            '.react-datepicker__current-month, .react-datepicker-time__header':
              {
                fontSize: '1.5rem !important',
              },

            '.react-datepicker__header': {
              paddingTop: '6px !important',
            },

            '.react-datepicker__navigation': {
              top: '13px !important',
            },

            '.react-datepicker__day-name, .react-datepicker__day': {
              margin: '0.5rem !important',
            },
            '.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)':
              {
                marginRight: 15,
              },
            '.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle':
              {
                left: 'auto!important',
                right: '18px',
              },
          }}
        />
        <DatePicker
          placeholderText={placeholder}
          css={{
            opacity: disabled ? 0.5 : 1,
          }}
          selected={value}
          showTimeSelectOnly={type === 'time'}
          showTimeSelect={type === 'time' || type === 'dateTime'}
          timeIntervals={15}
          timeCaption={t('forms.label.hour')}
          locale="fr"
          disabled={disabled}
          dateFormat={
            type === 'date'
              ? 'dd/MM/yyyy'
              : type === 'time'
              ? 'H:mm'
              : 'dd/MM/yyyy - H:mm'
          }
          onChange={onChange}
        />
      </div>
      {withError && (
        <div
          css={{
            marginLeft: 24,
            height: 20,
          }}
        >
          <span
            css={{
              fontFamily: 'Roboto',
              fontSize: '1.2rem',
              color: error ? theme.colors.error : theme.colors.gray3,
            }}
          >
            {helperText}
          </span>
        </div>
      )}
    </div>
  )
}

export default DateTime
