"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onlyNumber = exports.postalCodeRegex = exports.phoneNumberRegex = exports.siretRegex = exports.datetimeRegex = exports.passwordRegex = exports.emailRegex = void 0;
/* eslint-disable no-useless-escape */
exports.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 * At least one digit [0-9]
 * At least one lowercase character [a-z]
 * At least one uppercase character [A-Z]
 * No whitespace
 * At least 6 characters in length.
 */
exports.passwordRegex = /^(?=(.*[0-9]))(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{6,}$/;
exports.datetimeRegex = /^(\d{4})-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d).(\d\d\d)Z$/;
/**
 * Only numbers
 * Lenght should be equal to 14
 */
exports.siretRegex = /^[\d+]{14}$/;
/**
 * Only numbers
 * Lenght equal to 10
 */
exports.phoneNumberRegex = /^[0-9]{10}$/;
/**
 * Only numbers
 * Lenght equal to 5
 */
exports.postalCodeRegex = /^[0-9]{5}$/;
/**
 * Only numbers
 */
exports.onlyNumber = /^-?\d+\.?\d*$/;
