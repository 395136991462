import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react'
import { OptionTypeBase, ValueType } from 'react-select'
import debounce from 'lodash/debounce'

export interface RegisterFilterResult<T> {
  onChange: (
    value: ChangeEvent<HTMLInputElement> | ValueType<OptionTypeBase> | T,
  ) => void
}

export interface UseFiltersResult<FiltersT> {
  filters: Partial<FiltersT>
  setFilters: Dispatch<SetStateAction<Partial<FiltersT>>>
  register: <T>(
    name: keyof FiltersT,
    transform?: (value: T) => unknown,
  ) => RegisterFilterResult<T>
}

export const tryParseInt = (value: string): string | number => {
  const parsed = parseInt(value)
  if (isNaN(parsed)) return value
  return parsed
}

export const useFilters = <
  FiltersT = Record<string, unknown>,
>(): UseFiltersResult<FiltersT> => {
  const [filters, setFilters] = useState<Partial<FiltersT>>({})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFilters = useCallback(
    debounce((name: keyof FiltersT, newValue: unknown) => {
      setFilters(prev => {
        if (newValue === undefined || newValue === '') {
          const copy = { ...prev }
          delete copy[name]
          return copy
        }
        return {
          ...prev,
          [name]: newValue,
        }
      })
    }, 300),
    [],
  )

  const register = <T>(
    name: keyof FiltersT,
    transform?: (value: T) => unknown,
  ) => {
    return {
      onChange: (event: unknown | ChangeEvent<HTMLInputElement>) => {
        let newValue: unknown
        if (typeof event === 'object' && event != null) {
          if ('target' in event) {
            newValue = (event as ChangeEvent<HTMLInputElement>).target.value
          } else if ('value' in event) {
            newValue = (event as Record<'value', unknown>).value
            if (newValue === 'all') newValue = undefined
          } else {
            newValue = event
          }
        } else {
          newValue = event
        }
        if (newValue != null && transform != null) {
          newValue = transform(newValue as T)
        }
        debouncedSetFilters(name, newValue)
      },
    }
  }

  return {
    filters,
    setFilters,
    register,
  }
}
