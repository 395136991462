import { gql } from '@apollo/client'
import i18n from '../../i18n/config'
import { GetCompaniesExportQuery } from '../../__generated__/graphql'

export const QUERY_COMPANIES_EXPORT = gql`
  query GetCompaniesExport(
    $multiFilter: String
    $fleet: String
    $programStep: Int
    $archived: Boolean
    $department: String
    $nbrEmployees: IntFilter
    $loanDate: LoanDateFilter
  ) {
    companiesExport(
      multiFilter: $multiFilter
      fleet: $fleet
      programStep: $programStep
      archived: $archived
      department: $department
      nbrEmployees: $nbrEmployees
      loanDate: $loanDate
    ) {
      subscriptionCode
      name
      companyType
      nbrEmployees

      employeesCount
      employeesWithBikeCount
      employeesBeneficiaryCount
      employeeWithBikeAndAppCount

      companyReferrers {
        id
      }

      sites {
        id
        employeesOnSite
      }

      deploymentCount
      employeesTotalDistanceInKm
      employeesRidesCount
      employeesTotalMoneySaved
      companyTotalMoneySaved
      employeesTotalEnergySpent
      employeesTotalKgCO2Saved
      employeesBikeDayUsed
    }
  }
`
export const formatCompaniesExport = (
  data: GetCompaniesExportQuery | undefined,
): any => {
  if (!data?.companiesExport?.length) return []

  const header = [
    'ID',
    "Nom de l'organisation",
    "Type d'organisation",

    'Nombre de référents',
    'Nombre de sites',
    'Nombre de déploiements',

    'Nombre de collaborateurs',
    "Nombre d'inscrits",
    'Nombre de bénéficiaires',

    'Distance totale parcourue (km)',
    'Nombre total de trajets DT',
    "Nombre total de jour d'utilisation",
    "Economie totale pour l'organisation (€)",
    'Economie totale pour les bénéficiaires (€)',
    'Effort physique total des bénéficiaires (kCal)',
    'Emissions totales évitées (kgCO2)',
    "Nombre total de téléchargement de l'app",
  ]

  return [
    header,
    ...data?.companiesExport.map(company => [
      company?.subscriptionCode,
      company?.name,
      i18n.t(`shared.companyTypeLabel.${company?.companyType}`),

      company?.companyReferrers.length,
      company?.sites.length,
      company?.deploymentCount,

      company?.sites?.reduce((acc, site) => acc + site.employeesOnSite, 0),
      company?.employeesCount,
      company?.employeesBeneficiaryCount,

      company?.employeesTotalDistanceInKm?.toFixed(2),
      company?.employeesRidesCount,
      company?.employeesBikeDayUsed,
      company?.companyTotalMoneySaved?.toFixed(2),
      company?.employeesTotalMoneySaved?.toFixed(2),
      company?.employeesTotalEnergySpent?.toFixed(2),
      company?.employeesTotalKgCO2Saved?.toFixed(2),
      company?.employeeWithBikeAndAppCount,
    ]),
  ]
}
