/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { Flex } from '../../../components/Flex'
import { CardCollapsible } from '../../../components/Card/Collapsible'
import { useTranslation } from 'react-i18next'
import { useCompany } from './hooks/useCompany'
import { useCurrentCompanyId } from './hooks/useCurrentCompanyId'
import Card from '../../../components/Card'
import { LabeledIcon } from '../../../components/LabeledIcon'
import { NavigationLink } from '../../../components/NavigationLink'
import Button from '../../../components/Button'
import { CompaniesPageLayout } from './CompaniesPageLayout'
import { employeeNameFormatter, phoneFormatter } from '../../../utils/formatter'
import { CreateSiteModal } from '../../../components/Layout/Menu/CreateSiteModal'
import EditReferrerModal from '../../Shared/Referrer/EditReferrerModal'
import { CompanyReferrerTableModal } from '../../../types/CompanyReferrer'
import CompanyProfile from '../../Shared/CompanyProfile'
import ConfirmEmailModal from '../../Shared/CompanyProfile/ConfirmEmailModal'
import { CompanyReferrer } from '../../../__generated__/graphql'
import DeleteReferrerModal from '../../Shared/Referrer/DeleteReferrerModal'
import DeleteSiteModal from '../../Shared/Sites/DeleteSiteModal'

export const Profile: React.FC = () => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()
  const { data, refetch } = useCompany(companyId, true)
  const [selectedSite, setSelectedSite] = React.useState<string | undefined>()

  const [selectedReferrer, setSelectedReferrer] = React.useState<
    any | undefined
  >()
  const [createSite, setCreateSite] = React.useState<boolean>(false)
  const [deleteSite, setDeleteSite] = React.useState<any | null>(null)

  const [createReferrer, setCreateReferrer] = React.useState<boolean>(false)
  const [confirmEmailVisibility, setConfirmEmailVisibility] =
    React.useState<boolean>(false)
  const [deleteReferrer, setDeleteReferrer] = React.useState<any | null>(null)

  const onModalClose = () => {
    setSelectedSite(undefined)
    setSelectedReferrer(undefined)
    setCreateSite(false)
    setDeleteSite(null)
    setCreateReferrer(false)
    setDeleteReferrer(null)
  }

  const onConfirmEmailModalClose = () => {
    setConfirmEmailVisibility(false)
    setSelectedReferrer(undefined)
  }

  const [unpersistedForm, setUnpersistedForm] = React.useState<{
    password: string
    confirmPassword: string
  }>({
    password: '',
    confirmPassword: '',
  })

  const referrers = data?.company?.companyReferrers.filter(
    referrer => referrer.archived === false,
  )

  return (
    <CompaniesPageLayout>
      <Flex direction="column" gap="16px">
        <h3 css={{ color: '#565656', fontWeight: 400, fontSize: '16px' }}>
          {t('animator.companies.profile.organisation')}
        </h3>

        <CompanyProfile companyId={companyId} renderExtra={() => null} />
      </Flex>

      <Flex direction="column" gap="16px">
        <h3 css={{ color: '#565656', fontWeight: 400, fontSize: '16px' }}>
          {t('animator.companies.profile.sites')}
        </h3>

        {data?.company?.sites.map(site => (
          <CardCollapsible
            key={site.id}
            collapsibleContent={
              <Flex direction="column" gap="16px" css={{ color: '#434343' }}>
                <Fragment>
                  <Flex direction="row" gap="50px">
                    <Flex direction="column" gap="4px">
                      <p>{t('animator.companies.profile.bikeParking')}</p>
                      <Flex
                        direction="row"
                        align="center"
                        gap="10px"
                        css={{ fontSize: '14px', fontWeight: 400 }}
                      >
                        <LabeledIcon
                          icon="bikeUnsecure"
                          label={`${site.bikeSpaceCapacityUnsafe} ${t(
                            'animator.companies.profile.bikeSpaceCapacityUnsafe',
                          )}`}
                        />
                        <LabeledIcon
                          icon="bikeSecure"
                          label={`${site.bikeSpaceCapacityOutside} ${t(
                            'animator.companies.profile.bikeSpaceCapacityOutside',
                          )}`}
                        />
                        <LabeledIcon
                          icon="bikeInside"
                          label={`${site.bikeSpaceCapacityInside} ${t(
                            'animator.companies.profile.bikeSpaceCapacityInside',
                          )}`}
                        />
                      </Flex>
                    </Flex>
                  </Flex>

                  <hr css={{ margin: 0 }} />

                  <Flex direction="column" gap="4px">
                    <p>{t('animator.companies.profile.parking')}</p>
                    <Flex
                      direction="row"
                      align="center"
                      gap="50px"
                      css={{ fontSize: '14px', fontWeight: 400 }}
                    >
                      <LabeledIcon
                        icon="parking"
                        label={`${site.carSpaceCapacity} ${t(
                          'animator.companies.profile.privateSpots',
                        )}`}
                      />
                      <LabeledIcon
                        icon="electricCharge"
                        label={`${site.electricStationCapacity} ${t(
                          'animator.companies.profile.electricChargePoints',
                        )}`}
                      />
                      <Flex flex={1} justify="flex-end" gap="8px">
                        <NavigationLink
                          to="#"
                          onClick={event => {
                            event.stopPropagation()
                            setSelectedSite(site.id)
                          }}
                        >
                          {t('shared.edit')}
                        </NavigationLink>

                        <NavigationLink
                          to="#"
                          onClick={event => {
                            event.stopPropagation()
                            setDeleteSite(site)
                          }}
                        >
                          {t('shared.delete')}
                        </NavigationLink>
                      </Flex>
                    </Flex>
                  </Flex>
                </Fragment>
              </Flex>
            }
          >
            <Flex
              direction="column"
              gap="16px"
              css={{ fontSize: '14px', fontWeight: 400, color: '#434343' }}
            >
              <Flex direction="row" gap="50px">
                <LabeledIcon icon="home" label={site.name} />
                <LabeledIcon
                  icon="animators"
                  label={`${t('animator.companies.profile.employees')} : ${
                    site.employeesOnSite
                  }`}
                />
              </Flex>
              <LabeledIcon
                icon="locate"
                label={`${site.street}, ${site.postalCode} ${site.city}`}
              />
            </Flex>
          </CardCollapsible>
        ))}
      </Flex>

      <Flex direction="column" gap="16px">
        <h3 css={{ color: '#565656', fontWeight: 400, fontSize: '16px' }}>
          {t('animator.companies.profile.referrers')}
        </h3>

        {referrers?.map(referrer => (
          <Card key={referrer.id}>
            <Flex direction="row" gap="48px" css={{ color: '#434343' }}>
              <LabeledIcon
                icon="user"
                label={employeeNameFormatter(
                  referrer.firstName,
                  referrer.lastName,
                )}
              />
              <LabeledIcon icon="mail" label={referrer.email} />
              <LabeledIcon
                icon="phone"
                label={phoneFormatter(referrer.phoneNumber)}
              />

              <Flex flex={1} justify="flex-end" gap="8px">
                <NavigationLink
                  to="#"
                  onClick={event => {
                    event.stopPropagation()
                    setSelectedReferrer(referrer)
                  }}
                >
                  {t('shared.edit')}
                </NavigationLink>
                <NavigationLink
                  to="#"
                  onClick={event => {
                    event.stopPropagation()
                    setDeleteReferrer(referrer)
                  }}
                >
                  {t('shared.delete')}
                </NavigationLink>
              </Flex>
            </Flex>
          </Card>
        ))}
      </Flex>
      <Flex direction="row" justify="center" gap="16px">
        <Button onClick={() => setCreateReferrer(true)}>
          {t('animator.companies.profile.createReferrer')}
        </Button>
        <Button onClick={() => setCreateSite(true)}>
          {t('animator.companies.profile.createSite')}
        </Button>

        {!!selectedSite && (
          <CreateSiteModal
            siteId={selectedSite}
            type="update"
            isOpen={!!selectedSite}
            onModalClose={onModalClose}
          />
        )}

        {createSite && (
          <CreateSiteModal isOpen={createSite} onModalClose={onModalClose} />
        )}

        {createReferrer && (
          <EditReferrerModal
            isOpen={createReferrer}
            onModalClose={onModalClose}
            referrer={null}
            type={CompanyReferrerTableModal.ADD}
            refetch={refetch}
            submitCloseModal={onModalClose}
            unpersistedForm={unpersistedForm}
            setUnpersistedForm={setUnpersistedForm}
          />
        )}

        {!!selectedReferrer && (
          <Fragment>
            <EditReferrerModal
              isOpen={!!selectedReferrer}
              onModalClose={onModalClose}
              referrer={selectedReferrer}
              type={CompanyReferrerTableModal.EDIT}
              refetch={refetch}
              submitCloseModal={onModalClose}
              unpersistedForm={unpersistedForm}
              setUnpersistedForm={setUnpersistedForm}
              openConfirmEmailModal={() => setConfirmEmailVisibility(true)}
            />

            <ConfirmEmailModal
              referrer={selectedReferrer as CompanyReferrer}
              closeModal={onConfirmEmailModalClose}
              modalIsOpen={confirmEmailVisibility}
              refetch={() => {}}
            />
          </Fragment>
        )}

        {deleteReferrer != null && (
          <DeleteReferrerModal
            referrer={deleteReferrer as CompanyReferrer}
            referrers={data?.company?.companyReferrers}
            onModalClose={onModalClose}
            refetch={() => refetch()}
            submitCloseModal={onModalClose}
          />
        )}

        {deleteSite != null && (
          <DeleteSiteModal
            site={deleteSite}
            sites={data?.company?.sites}
            onModalClose={onModalClose}
            refetch={() => refetch()}
            submitCloseModal={onModalClose}
          />
        )}
      </Flex>
    </CompaniesPageLayout>
  )
}

export default Profile
