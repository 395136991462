import {
  BabySeatOptions,
  GetEmployeeQuery,
  SpecialBikesType,
} from '../__generated__/graphql'

export interface CompanySubscribedEmployeesTableRow {
  id: string
  name: string
  workTravelDistance: number
  bikeParkingType: string
  travelMode: string
  selectLoan: boolean
  hasCompletedProfile: boolean
  emailVerified: boolean

  specialBikeType: SpecialBikesType | null
  specialBikeAbsolutelyNeeded: boolean | null
  babySeatNeeded: BabySeatOptions | null
}

export enum CompanySubscribedEmployeesTableModalActions {
  HABITS = 'road',
  CHECKBOX = 'checkbox',
  EDIT_PROFILE = 'anonymProfile',
  CONFIRM_EMAIL = 'confirmEmail',
  CONFIRM_DELETE = 'confirmDelete',
}

export type CompanySubscribedEmployeesModalSettings<
  D = GetEmployeeQuery['employee'],
  T = CompanySubscribedEmployeesTableModalActions,
> = {
  employee: D | null
  actionName: '' | T
}
