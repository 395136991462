/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'react-table'

import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '../../../../../components/Table'
import Pagination from '../../../../../components/Pagination'
import ApproveModal from './ApproveModal'
import RefuseModal from './RefuseModal'
import PendingCompaniesActionCell from '../../../../../components/Table/CustomCells/PendingCompaniesActionCell'
import MunicipalityCell from '../../../../../components/Table/CustomCells/MunicipalityCell'

import { TableRefetchContext, TableTypes } from '../TableRefetchContext'

import {
  PendingTableModal,
  CompaniesModalSettings,
  PendingCompaniesTableRow,
} from '../../../../../types/AnimatorCompanies'
import { ProgramStepType } from '@goodwatt/shared/dist/types'
import CompanyInfoModal from './CompanyInfoModal'
import DownloadCSVButton from '../../../../../components/Button/DownloadCSVButton'
import {
  formatCompaniesExport,
  QUERY_COMPANIES_EXPORT,
} from '../../../../../utils/gql/companiesExport'
import usePromiseLazyQuery from '../../../../../hooks/usePromiseLazyQuery'
import {
  AnimatorGetCompaniesPendingCountQuery,
  AnimatorGetCompaniesPendingCountQueryVariables,
  AnimatorGetCompaniesPendingQuery,
  AnimatorGetCompaniesPendingQueryVariables,
  GetCompaniesExportQuery,
  GetCompaniesExportQueryVariables,
  IntFilter,
} from '../../../../../__generated__/graphql'

const QUERY_COMPANIES_PENDING_COUNT_FILTERED = gql`
  query AnimatorGetCompaniesPendingCount(
    $multiFilter: String
    $programStep: Int
    $department: String
    $nbrEmployees: IntFilter
  ) {
    companiesSearchCount(
      multiFilter: $multiFilter
      programStep: $programStep
      department: $department
      nbrEmployees: $nbrEmployees
    )
  }
`

const QUERY_COMPANIES_PENDING_PAGINATED_FILTERED = gql`
  query AnimatorGetCompaniesPending(
    $multiFilter: String
    $programStep: Int
    $department: String
    $nbrEmployees: IntFilter
    $take: Int
    $skip: Int
    $orderBy: CompanyOrderByInput
  ) {
    companiesSearch(
      multiFilter: $multiFilter
      programStep: $programStep
      department: $department
      nbrEmployees: $nbrEmployees

      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      name
      postalCode
      city
      nbrEmployees
      companyType
      areaId
    }
  }
`

const initialModalSettings: CompaniesModalSettings<
  PendingCompaniesTableRow,
  PendingTableModal
> = {
  company: {
    id: '',
    companyType: '',
    name: '',
    municipality: { city: '', postalCode: '' },
    nbrEmployees: 0,
    areaId: undefined,
  },
  name: '',
}

interface PendingListProps {
  filters: {
    [filterName: string]: string
  }
  pageSize: number
}

const PendingList: React.FC<PendingListProps> = ({ filters, pageSize }) => {
  const {
    removeTableToFetch,
    tableRefetchPlanner: { [TableTypes.PENDING]: needToRefetch },
    refetchTables,
  } = React.useContext(TableRefetchContext)

  const { t } = useTranslation()
  const [currPage, setCurrPage] = React.useState(1)
  const [orderBy, setOrderBy] =
    React.useState<ColumnSortBy>(initialColumnSortBy)
  const [currModal, setCurrModal] = React.useState(initialModalSettings)

  // Resetting to first page on filter change
  React.useEffect(() => {
    setCurrPage(1)
  }, [filters])

  const searchVariables: AnimatorGetCompaniesPendingCountQueryVariables = {
    multiFilter: filters.multiFilter,
    programStep: ProgramStepType.PENDING,
    department: filters.department,
    nbrEmployees: filters.staff as IntFilter,
  }

  const { data: countData } = useQuery<
    AnimatorGetCompaniesPendingCountQuery,
    AnimatorGetCompaniesPendingCountQueryVariables
  >(QUERY_COMPANIES_PENDING_COUNT_FILTERED, {
    variables: searchVariables,
  })

  const {
    data: companiesData,
    loading,
    refetch,
  } = useQuery<
    AnimatorGetCompaniesPendingQuery,
    AnimatorGetCompaniesPendingQueryVariables
  >(QUERY_COMPANIES_PENDING_PAGINATED_FILTERED, {
    fetchPolicy: needToRefetch ? 'network-only' : 'cache-first',
    variables: {
      ...searchVariables,

      take: pageSize,
      skip: (currPage - 1) * pageSize,
      orderBy:
        orderBy.sort && orderBy.columnName
          ? { [orderBy.columnName]: orderBy.sort }
          : undefined,
    },
  })

  React.useEffect(() => {
    if (needToRefetch) {
      refetch().then(() => removeTableToFetch(TableTypes.PENDING))
    }
  }, [refetch, needToRefetch, removeTableToFetch])

  const PendingActionCell = React.useCallback(
    ({ row }: { row: Row<PendingCompaniesTableRow> }) => (
      <PendingCompaniesActionCell setModal={setCurrModal} row={row} />
    ),
    [],
  )

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: t('animator.companies.table.headers.type') as string,
        accessor: 'companyType',
      },
      {
        Header: t('animator.companies.table.headers.companyName') as string,
        accessor: 'name',
        maxWidth: 200,
      },
      {
        Header: t(
          'animator.companies.table.headers.companyMunicipality',
        ) as string,
        accessor: 'municipality',
        disableSortBy: true,
        Cell: MunicipalityCell,
      },
      {
        Header: t(
          'animator.companies.table.headers.companyNbrEmployees',
        ) as string,
        accessor: 'nbrEmployees',
        cellStyle: (): { textAlign: string } => ({ textAlign: 'center' }),
      },
      {
        Header: t('animator.companies.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        maxWidth: 95,
        Cell: PendingActionCell,
        cellStyle: (): { overflow: string } => ({
          overflow: 'inherit',
        }),
      },
    ],
    [t, PendingActionCell],
  )

  const formattedData = React.useMemo((): PendingCompaniesTableRow[] => {
    if (!companiesData || !companiesData.companiesSearch?.length) {
      return []
    }

    return companiesData.companiesSearch.map(
      company =>
        ({
          id: company?.id,
          name: company?.name,
          municipality: {
            city: company?.city,
            postalCode: company?.postalCode,
          },
          nbrEmployees: company?.nbrEmployees,
          areaId: company?.areaId,
          companyType: t(`shared.companyTypeLabel.${company?.companyType}`),
        } as PendingCompaniesTableRow),
    )
  }, [companiesData, t])

  const onCloseModal = React.useCallback(
    (tablesToRefetch?: TableTypes[]) => {
      setCurrModal(initialModalSettings)
      if (Array.isArray(tablesToRefetch)) {
        refetchTables(tablesToRefetch)
      }
    },
    [refetchTables],
  )

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `organisations-en-attente-${formatedDate}`

  const getCompanies = usePromiseLazyQuery<
    GetCompaniesExportQuery,
    GetCompaniesExportQueryVariables
  >(QUERY_COMPANIES_EXPORT)

  const getExportData = async () => {
    const { data } = await getCompanies({
      ...searchVariables,
    })

    return formatCompaniesExport(data)
  }

  return (
    <React.Fragment>
      <div css={{ minHeight: 380 }}>
        <Table
          columns={columns}
          data={formattedData}
          onSortBy={setOrderBy}
          loading={loading}
        />
      </div>
      <div
        css={{ marginTop: '41px', display: 'flex', justifyContent: 'center' }}
      >
        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={countData?.companiesSearchCount}
        />
        <div
          css={{
            display: 'flex',
            justifySelf: 'flex-end',
            position: 'absolute',
            right: 21,
          }}
        >
          <DownloadCSVButton getData={getExportData} filename={filename} />
        </div>
      </div>
      <ApproveModal
        isOpen={currModal.name === PendingTableModal.APPROVE}
        closeModal={onCloseModal}
        company={currModal.company || initialModalSettings.company}
      />
      <RefuseModal
        isOpen={currModal.name === PendingTableModal.REFUSE}
        closeModal={onCloseModal}
        company={currModal.company || initialModalSettings.company}
      />
      {currModal?.company?.id && (
        <CompanyInfoModal
          isOpen={currModal.name === PendingTableModal.INFO}
          closeModal={onCloseModal}
          companyId={currModal?.company?.id}
        />
      )}
    </React.Fragment>
  )
}

export default PendingList
