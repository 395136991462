/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEmployeeEmployeeDeployments } from './hooks/useEmployeeEmployeeDeployments'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import React from 'react'
import { BikeModelCard } from '../Application/components/BikeModelCard'

export const EmployeeBikeCard: React.FC = () => {
  const userId = useSelector((state: StoreState) => state.user.id)
  const { data } = useEmployeeEmployeeDeployments(userId)

  const employeeDeployment = data?.employee?.employeeDeployments?.[0]

  return employeeDeployment && employeeDeployment.chosenBikeModel ? (
    <BikeModelCard
      choosenOption={employeeDeployment.option}
      deployment={employeeDeployment.deployment}
      pricing={employeeDeployment.chosenBikeModel}
      css={{ gridColumn: 'span 2 / span 2' }}
    />
  ) : null
}
