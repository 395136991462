import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import ConfirmEmailModal from '../../Company/Employees/SubscribedEmployees/ConfirmEmailModal'
import { useModal } from '../../../../hooks/useModal'
import { Employee } from '../../../../__generated__/graphql'

export interface VerifyEmployeeEmailButtonProps {
  employee: Employee
}

export const VerifyEmployeeEmailButton: React.FC<
  VerifyEmployeeEmailButtonProps
> = ({ employee }) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      {modalProps.isOpen && (
        <ConfirmEmailModal employee={employee} {...modalProps} />
      )}
      <button onClick={onOpen}>
        <Icon type="mailCheck" />
      </button>
    </Fragment>
  )
}
