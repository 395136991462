/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment, useEffect } from 'react'

import { useTheme } from 'emotion-theming'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSubSubMenuTitle } from '../../../redux/menu'
import { Flex } from '../../Flex'
import { SubNavItem } from '../../../hooks/useMenu'
import Icon from '../../Icon'

interface ConditionalSubNavLinkProps extends SubNavItem {
  closeMenu?: () => void
}

const ConditionalSubNavLink: React.FC<ConditionalSubNavLinkProps> = ({
  title,
  lastPathUrl,
  overideUrl,
  titleRedirectUrl,
  groupTitle,
  closeMenu,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const locationPathName = useLocation().pathname
  const isSubActive = locationPathName.includes(lastPathUrl)
  // This Regex pattern catch the last path in url
  // eg: "/animator/company/1276483764/status" will catch "/status"
  const subNavTo = overideUrl
    ? lastPathUrl
    : locationPathName.replace(/\/[^/]*$/, lastPathUrl)

  useEffect(() => {
    if (isSubActive) dispatch(setSubSubMenuTitle(title))
  }, [closeMenu, dispatch, isSubActive, title])

  if (titleRedirectUrl) {
    return (
      <Flex
        justify="space-between"
        align="center"
        css={{
          paddingRight: 17,
        }}
      >
        <div
          css={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            font: 'Quicksand',
            fontWeight: 700,
            color: theme.colors.primary,
            textTransform: 'initial',
          }}
        >
          {title}
        </div>

        <NavLink
          onClick={() => {
            if (closeMenu) closeMenu()
          }}
          to={titleRedirectUrl}
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.colors.gray7,
            borderRadius: '100%',
            color: theme.colors.gray2,
            padding: 5,
            width: 24,
            height: 24,
          }}
        >
          <Icon type="close" size="small" color={theme.colors.gray2} />
        </NavLink>
      </Flex>
    )
  }

  return (
    <Fragment>
      {groupTitle && (
        <li>
          <Flex direction="row" gap="2px" align="center">
            <p
              css={{
                color: theme.colors.primary,
                fontSize: '16px',
                fontWeight: 700,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {groupTitle}
            </p>
          </Flex>
        </li>
      )}
      <li>
        <NavLink
          onClick={() => {
            if (closeMenu) closeMenu()
          }}
          to={subNavTo}
          css={{
            position: 'relative',
            display: 'block',
            paddingLeft: 17,
            color: theme.colors.gray2,
          }}
        >
          <span
            css={{
              position: 'absolute',
              top: 'calc(50% - 5px)',
              left: '0',
              width: '5px',
              height: '10px',
              backgroundColor: isSubActive
                ? theme.colors.primary
                : theme.colors.gray5,
            }}
          />
          {title}
        </NavLink>
      </li>
    </Fragment>
  )
}

export default ConditionalSubNavLink
