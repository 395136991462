/** @jsx jsx */
import { jsx } from '@emotion/core'
import EmployeeRidesDiagram from './EmployeeRidesDiagram'
import EmployeeStats from './EmployeeStats'
import LastEmployeeRides from './LastEmployeeRides'
import { EmployeeOffer } from './EmployeeOffer'
import { EmployeeBikeCard } from './EmployeeBikeCard'

const EmployeeLoanMonitoring: React.FC = () => {
  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: 20,
      }}
    >
      <EmployeeOffer />
      <div css={{ gridColumn: 'span 3 / span 3' }}>
        <EmployeeStats />
      </div>
      <EmployeeBikeCard />
      <LastEmployeeRides />
      <EmployeeRidesDiagram />
    </div>
  )
}

export default EmployeeLoanMonitoring
