/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'react-table'

import {
  ProgramStepType,
  RejectionReasonType,
} from '@goodwatt/shared/dist/types'
import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '../../../../../components/Table'
import Pagination from '../../../../../components/Pagination'
import CommentModal from './CommentModal'
import UndoModal from './UndoModal'
import DeleteModal from './DeleteModal'
import MunicipalityCell from '../../../../../components/Table/CustomCells/MunicipalityCell'
import RefusedCompaniesActionCell from '../../../../../components/Table/CustomCells/RefusedCompaniesActionCell'

import { TableRefetchContext, TableTypes } from '../TableRefetchContext'

import {
  RefusedTableModal,
  CompaniesModalSettings,
  RefusedCompaniesTableRow,
} from '../../../../../types/AnimatorCompanies'
import { format } from 'date-fns'
import usePromiseLazyQuery from '../../../../../hooks/usePromiseLazyQuery'

import {
  formatCompaniesExport,
  QUERY_COMPANIES_EXPORT,
} from '../../../../../utils/gql/companiesExport'
import DownloadCSVButton from '../../../../../components/Button/DownloadCSVButton'
import {
  AnimatorGetCompaniesRefusedCountQuery,
  AnimatorGetCompaniesRefusedCountQueryVariables,
  AnimatorGetCompaniesRefusedQuery,
  AnimatorGetCompaniesRefusedQueryVariables,
  GetCompaniesExportQuery,
  GetCompaniesExportQueryVariables,
  IntFilter,
} from '../../../../../__generated__/graphql'

const QUERY_COMPANIES_REFUSED_COUNT_FILTERED = gql`
  query AnimatorGetCompaniesRefusedCount(
    $multiFilter: String
    $programStep: Int
    $department: String
    $nbrEmployees: IntFilter
  ) {
    companiesSearchCount(
      multiFilter: $multiFilter
      programStep: $programStep
      department: $department
      nbrEmployees: $nbrEmployees
    )
  }
`

const QUERY_COMPANIES_REFUSED_PAGINATED_FILTERED = gql`
  query AnimatorGetCompaniesRefused(
    $multiFilter: String
    $programStep: Int
    $department: String
    $nbrEmployees: IntFilter
    $take: Int
    $skip: Int
    $orderBy: CompanyOrderByInput
  ) {
    companiesSearch(
      multiFilter: $multiFilter
      programStep: $programStep
      department: $department
      nbrEmployees: $nbrEmployees

      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      companyType
      name
      postalCode
      city
      nbrEmployees
      companyRejection {
        createdAt
        note
        rejectionReason {
          label
        }
      }
    }
  }
`

const initialModalSettings: CompaniesModalSettings<
  RefusedCompaniesTableRow,
  RefusedTableModal
> = {
  company: {
    id: '',
    companyType: '',
    name: '',
    municipality: { city: '', postalCode: '' },
    nbrEmployees: 0,
    rejectionDate: '',
    rejectionReason: '',
    rejectionNote: '',
  },
  name: '',
}

interface RefusedListProps {
  filters: {
    [filterName: string]: string
  }
  pageSize: number
}

const RefusedList: React.FC<RefusedListProps> = ({ filters, pageSize }) => {
  const {
    removeTableToFetch,
    refetchTables,
    tableRefetchPlanner: { [TableTypes.REFUSED]: needToRefetch },
  } = React.useContext(TableRefetchContext)
  const { t } = useTranslation()

  const [currPage, setCurrPage] = React.useState(1)
  const [orderBy, setOrderBy] =
    React.useState<ColumnSortBy>(initialColumnSortBy)
  const [currModal, setCurrModal] = React.useState(initialModalSettings)

  // Resetting to first page on filter change
  React.useEffect(() => {
    setCurrPage(1)
  }, [filters])

  const searchVariables: AnimatorGetCompaniesRefusedCountQueryVariables = {
    multiFilter: filters.multiFilter,
    programStep: ProgramStepType.REFUSED,
    department: filters.department,
    nbrEmployees: filters.staff as IntFilter,
  }

  const { data: countData } = useQuery<
    AnimatorGetCompaniesRefusedCountQuery,
    AnimatorGetCompaniesRefusedCountQueryVariables
  >(QUERY_COMPANIES_REFUSED_COUNT_FILTERED, {
    variables: searchVariables,
  })

  const {
    data: companiesData,
    loading,
    refetch,
  } = useQuery<
    AnimatorGetCompaniesRefusedQuery,
    AnimatorGetCompaniesRefusedQueryVariables
  >(QUERY_COMPANIES_REFUSED_PAGINATED_FILTERED, {
    fetchPolicy: needToRefetch ? 'network-only' : 'cache-first',
    variables: {
      ...searchVariables,

      take: pageSize,
      skip: (currPage - 1) * pageSize,
      orderBy:
        orderBy.sort && orderBy.columnName
          ? { [orderBy.columnName]: orderBy.sort }
          : undefined,
    },
  })

  React.useEffect(() => {
    if (needToRefetch) {
      refetch().then(() => removeTableToFetch(TableTypes.REFUSED))
    }
  }, [refetch, needToRefetch, removeTableToFetch])

  const RefusedActionCell = React.useCallback(
    ({ row }: { row: Row<RefusedCompaniesTableRow> }) => (
      <RefusedCompaniesActionCell setModal={setCurrModal} row={row} />
    ),
    [],
  )

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: t('animator.companies.table.headers.type') as string,
        accessor: 'companyType',
      },
      {
        Header: t('animator.companies.table.headers.companyName') as string,
        accessor: 'name',
        maxWidth: 200,
      },
      {
        Header: t(
          'animator.companies.table.headers.companyMunicipality',
        ) as string,
        accessor: 'municipality',
        disableSortBy: true,
        Cell: MunicipalityCell,
        maxWidth: 150,
      },
      {
        Header: t(
          'animator.companies.table.headers.companyNbrEmployees',
        ) as string,
        accessor: 'nbrEmployees',
        cellStyle: (): { textAlign: string } => ({ textAlign: 'center' }),
      },
      {
        Header: t('animator.companies.table.headers.rejectionReason') as string,
        accessor: 'rejectionReason',
        disableSortBy: true,
        maxWidth: 150,
      },
      {
        Header: t('animator.companies.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        maxWidth: 115,
        Cell: RefusedActionCell,
        cellStyle: (): { overflow: string } => ({
          overflow: 'inherit',
        }),
      },
    ],
    [t, RefusedActionCell],
  )

  const formattedData = React.useMemo((): RefusedCompaniesTableRow[] => {
    if (!companiesData || !companiesData.companiesSearch?.length) {
      return []
    }

    return companiesData.companiesSearch.map(
      company =>
        ({
          id: company?.id,
          name: company?.name,
          municipality: {
            city: company?.city,
            postalCode: company?.postalCode,
          },
          nbrEmployees: company?.nbrEmployees,
          rejectionDate: format(
            new Date(company?.companyRejection?.createdAt || 0),
            'dd/MM/yyyy',
          ),
          rejectionReason: t(
            `animator.companies.refuseCompany.options.${
              company?.companyRejection?.rejectionReason?.label ||
              RejectionReasonType.OTHER
            }`,
          ),
          rejectionNote: company?.companyRejection?.note,
          companyType: t(`shared.companyTypeLabel.${company?.companyType}`),
        } as RefusedCompaniesTableRow),
    )
  }, [companiesData, t])

  const onCloseModal = React.useCallback(
    (tablesToRefetch?: TableTypes[]) => {
      setCurrModal(initialModalSettings)
      if (Array.isArray(tablesToRefetch)) {
        refetchTables(tablesToRefetch)
      }
    },
    [refetchTables],
  )

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `organisations-refusees-${formatedDate}`

  const getCompanies = usePromiseLazyQuery<
    GetCompaniesExportQuery,
    GetCompaniesExportQueryVariables
  >(QUERY_COMPANIES_EXPORT)

  const getExportData = async () => {
    const { data } = await getCompanies({
      ...searchVariables,
    })

    return formatCompaniesExport(data)
  }

  return (
    <React.Fragment>
      <div css={{ minHeight: 380 }}>
        <Table
          columns={columns}
          data={formattedData}
          onSortBy={setOrderBy}
          loading={loading}
        />
      </div>
      <div
        css={{ marginTop: '41px', display: 'flex', justifyContent: 'center' }}
      >
        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={countData?.companiesSearchCount}
        />
        <div
          css={{
            display: 'flex',
            justifySelf: 'flex-end',
            position: 'absolute',
            right: 21,
          }}
        >
          <DownloadCSVButton getData={getExportData} filename={filename} />
        </div>
      </div>
      <CommentModal
        isOpen={currModal.name === RefusedTableModal.EDIT}
        closeModal={() => setCurrModal(initialModalSettings)}
        company={currModal.company}
      />
      <UndoModal
        isOpen={currModal.name === RefusedTableModal.UNDO}
        closeModal={onCloseModal}
        company={currModal.company}
      />
      <DeleteModal
        isOpen={currModal.name === RefusedTableModal.DELETE}
        closeModal={onCloseModal}
        refetch={refetch}
        company={currModal.company}
      />
    </React.Fragment>
  )
}

export default RefusedList
