/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Flex } from '../../../components/Flex'
import { NavLink, useHistory } from 'react-router-dom'
import Icon from '../../../components/Icon'
import theme from '../../../styles/theme'
import Card from '../../../components/Card'
import CardTitle from '../../../components/Card/CardTitle'
import Table from '../../../components/Table'
import { useTranslation } from 'react-i18next'
import { useCurrentDeploymentId } from './hooks/useCurrentDeploymentId'
import { useQuery } from '@apollo/client'
import { QUERY_ANIMATOR_DEPLOYMENT } from '../../../utils/gql/queries'
import { CellProps } from 'react-table'
import { format } from 'date-fns'
import { DeleteDeploymentButton } from './components/DeleteDeploymentButton'
import { EditDeploymentButton } from './components/EditDeploymentButton'
import { NextDeploymentStepButton } from './components/NextDeploymentStepButton'
import { DeploymentStepLabel } from './components/DeploymentStepLabel'
import { Deployment } from '../../../__generated__/graphql'

export const DeploymentPageLayout: React.FC = ({ children }) => {
  const { t } = useTranslation()
  const id = useCurrentDeploymentId()
  const { data } = useQuery(QUERY_ANIMATOR_DEPLOYMENT, {
    variables: { id },
  })
  const history = useHistory()

  return (
    <Flex direction="column" gap="32px">
      <Flex direction="row" align="center" gap="16px">
        {data && (
          <p css={{ color: theme.colors.primary, fontWeight: 500 }}>
            {data.deployment.site.company.name} • {data.deployment.site.name} •{' '}
            {data.deployment.name}
          </p>
        )}
        <NavLink
          to="/"
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '24px',
            width: '24px',
            borderRadius: '100%',
            backgroundColor: theme.colors.primary,
          }}
        >
          <Icon type="close" size="small" color="white" />
        </NavLink>
      </Flex>

      <Card css={{ gap: '16px' }}>
        <CardTitle label={t('animator.deployments.table.title')} />
        <Table
          columns={[
            {
              Header: t('animator.deployments.table.phase') as string,
              Cell: ({ row: { original } }: CellProps<Deployment>) => (
                <DeploymentStepLabel deployment={original} />
              ),
              accessor: 'phase',
              disableSortBy: true,
            },
            {
              Header: t('animator.deployments.table.start') as string,
              Cell: ({ value }: CellProps<Deployment>) => (
                <div css={{ textAlign: 'center' }}>
                  <p>{format(new Date(value), 'dd/MM/yy')}</p>
                  <p>{format(new Date(value), "à HH'h'mm")}</p>
                </div>
              ),
              accessor: 'deploymentStartDate',
              disableSortBy: true,
            },
            {
              Header: t('animator.deployments.table.startLocation') as string,
              accessor: 'startLocation',
              disableSortBy: true,
            },
            {
              Header: t('animator.deployments.table.end') as string,
              Cell: ({ value }: CellProps<Deployment>) => (
                <div css={{ textAlign: 'center' }}>
                  <p>{format(new Date(value), 'dd/MM/yy')}</p>
                  <p>{format(new Date(value), "à HH'h'mm")}</p>
                </div>
              ),
              accessor: 'deploymentEndDate',
              disableSortBy: true,
            },
            {
              Header: t('animator.deployments.table.endLocation') as string,
              accessor: 'endLocation',
              disableSortBy: true,
            },
            {
              Header: t('animator.deployments.table.amount') as string,
              Cell: ({ value }) => (
                <Flex align="center" justify="center">
                  {value}
                </Flex>
              ),
              accessor: 'loanQuantityBikes',
              disableSortBy: true,
            },
            {
              Header: t('animator.deployments.table.hasSpecialBike') as string,
              Cell: ({ value }: CellProps<Deployment>) => (
                <div
                  css={{
                    width: '10px',
                    height: '10px',
                    margin: 'auto',
                    borderRadius: '100%',
                    backgroundColor: value
                      ? theme.colors.greenLegend
                      : theme.colors.redLegend,
                  }}
                />
              ),
              accessor: 'hasSpecialBike',
              disableSortBy: true,
            },
            {
              Header: t('animator.deployments.table.group') as string,
              Cell: ({ value }) => (
                <Flex align="center" justify="center">
                  {value}
                </Flex>
              ),
              accessor: 'fleet.name',
              disableSortBy: true,
            },
            {
              Header: t('animator.deployments.table.actions') as string,
              Cell: ({ row: { original } }: CellProps<Deployment>) => (
                <Flex
                  css={{ margin: 'auto' }}
                  direction="row"
                  align="items"
                  gap="4px"
                >
                  {original.programStep.stepNbr < 5 &&
                    original.programStep.stepNbr !== 3 && (
                      <NextDeploymentStepButton />
                    )}
                  <EditDeploymentButton />
                  {original.programStep.stepNbr < 3 && (
                    <DeleteDeploymentButton
                      onCompleted={() => {
                        history.push('/')
                      }}
                    />
                  )}
                </Flex>
              ),
              accessor: 'actions',
              disableSortBy: true,
            },
          ]}
          loading={data == null}
          data={data ? [data.deployment] : []}
        />
      </Card>
      {children}
    </Flex>
  )
}
