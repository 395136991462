/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import Icon from '../../Icon'

import {
  RefusedTableModal,
  CompaniesModalSettings,
  RefusedCompaniesTableRow,
} from '../../../types/AnimatorCompanies'
import Tooltip from '../../Tooltip'

const RefusedCompaniesActionCell: React.FC<{
  setModal: (
    obj: CompaniesModalSettings<RefusedCompaniesTableRow, RefusedTableModal>,
  ) => void
  row: Row<RefusedCompaniesTableRow>
}> = ({ setModal, row }) => {
  const { t } = useTranslation()
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      icon: RefusedTableModal,
    ) => {
      e.stopPropagation()
      setModal({ company: row.original, name: icon })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        css={{ cursor: 'pointer', marginRight: '7px' }}
        onClick={e => handleClick(e, RefusedTableModal.EDIT)}
      >
        <Tooltip
          title={t('animator.companies.tooltip.commentCompanyRefusal')}
          placement="top-end"
        >
          <Icon type="comment" />
        </Tooltip>
      </div>
      <div
        css={{ cursor: 'pointer', marginRight: '7px' }}
        onClick={e => handleClick(e, RefusedTableModal.UNDO)}
      >
        <Tooltip
          title={t('animator.companies.tooltip.cancelCompanyRefusal')}
          placement="top-end"
        >
          <Icon type="undo" />
        </Tooltip>
      </div>
      <div
        css={{ cursor: 'pointer', marginRight: '7px' }}
        onClick={e => handleClick(e, RefusedTableModal.DELETE)}
      >
        <Tooltip
          title={t('animator.companies.tooltip.deleteCompanyRefusal')}
          placement="top-end"
        >
          <Icon type="trash" />
        </Tooltip>
      </div>
    </div>
  )
}

export default RefusedCompaniesActionCell
