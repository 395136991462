/** @jsx jsx */
import React, { useState } from 'react'
import Input from '../../../../components/Input'
import {
  FleetModalLineBike,
  FleetModalLineProps,
} from '../../../../types/AnimatorFleets'
import InputSelect from '../../../../components/InputSelect'
import { jsx } from '@emotion/core'

interface ErrorsByField {
  stickerId: boolean
  morioId: boolean
  bicycode: boolean
  keysId: boolean
}

interface ColumnMaxLength {
  morioId: number
  keysId: number
  bicycode: number
}

type typeInputNames = ['stickerId', 'morioId', 'bicycode', 'keysId']

const inputNames: typeInputNames = [
  'stickerId',
  'morioId',
  'bicycode',
  'keysId',
]
const columnMaxLength = { morioId: 8, keysId: 255, bicycode: 14 }
const initialErrorsByField: ErrorsByField = {
  stickerId: false,
  morioId: false,
  bicycode: false,
  keysId: false,
}

const BikeLineInput: React.FC<FleetModalLineProps> = ({
  bike,
  bikeModels,
  onBikeChange,
  bikeErrors,
  bikes,
  onError,
}) => {
  const [localBike, setLocalBike] = useState(bike)
  const [errorsByField, setErrorsByField] = useState(initialErrorsByField)
  const bikeModelOptions = bikeModels.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  const handleSelectOnChange = ({ value }: any, { name }: any) => {
    const bike = { ...localBike, [name]: value }
    onBikeChange(bike)
    setLocalBike(bike)
  }

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const bike = { ...localBike, [e.target.name]: e.target.value }
    onBikeChange(bike)
    setLocalBike(bike)
  }

  React.useEffect(() => {
    const errors: ErrorsByField = { ...initialErrorsByField }
    if (
      !localBike.stickerId &&
      !localBike.morioId &&
      !localBike.bicycode &&
      !localBike.keysId
    ) {
      onError(false)
      setErrorsByField(errors)
      return
    }

    bikes
      .filter(bike => bike.id !== localBike.id)
      .forEach(bike => {
        inputNames.forEach(name => {
          if (errors[name as keyof ErrorsByField]) return

          const serverName = bike[name as keyof FleetModalLineBike]?.toString()
          const localName =
            localBike[name as keyof FleetModalLineBike]?.toString()

          if (['stickerId', 'morioId'].includes(name)) {
            return (errors[name as keyof ErrorsByField] = false)
          } else if (!localName || !serverName) {
            return (errors[name as keyof ErrorsByField] = false)
          }

          if (serverName === localName) {
            errors[name as keyof ErrorsByField] = true
          } else {
            errors[name as keyof ErrorsByField] = false
          }
        })
      })

    bikeErrors.forEach(bike => {
      inputNames.forEach(name => {
        if (errors[name as keyof ErrorsByField]) return

        const serverName = bike[name]?.toString()
        const localName =
          localBike[name as keyof FleetModalLineBike]?.toString()

        if (['stickerId', 'morioId'].includes(name)) {
          if (!localName) return (errors[name as keyof ErrorsByField] = true)
        } else if (!localName || !serverName) {
          return (errors[name as keyof ErrorsByField] = false)
        }

        if (serverName === localName) {
          errors[name as keyof ErrorsByField] = true
        } else {
          errors[name as keyof ErrorsByField] = false
        }
      })
    })

    onError(
      errors.stickerId || errors.morioId || errors.bicycode || errors.keysId,
    )

    setErrorsByField(errors)
    // eslint-disable-next-line
  }, [bikes, bikeErrors, localBike])

  const inputs = inputNames.map((name, index) => (
    <td key={index}>
      <Input
        defaultValue={localBike[name as keyof FleetModalLineBike]}
        name={name}
        onChange={handleInputOnChange}
        onlyNumbers={name === 'stickerId'}
        error={errorsByField[name as keyof ErrorsByField]}
        withError={false}
        // helperText={errorsByField[name as keyof ErrorsByField] ? 'Identifiants déjà attribué' : ''}
        maxLength={columnMaxLength[name as keyof ColumnMaxLength]}
      />
    </td>
  ))

  return (
    <tr>
      {inputs}
      <td css={{ minWidth: '105px' }}>
        <InputSelect
          name="modelId"
          options={bikeModelOptions}
          defaultValue={bike.modelId || bikeModels[0]?.id}
          onChange={handleSelectOnChange}
          withError={false}
        />
      </td>
    </tr>
  )
}

export default BikeLineInput
