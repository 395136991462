import { gql, QueryResult, useQuery } from '@apollo/client'
import {
  EmployeeEmployeeDeploymentsQuery,
  EmployeeEmployeeDeploymentsQueryVariables,
} from '../../../../__generated__/graphql'

export const QUERY_EMPLOYEE_EMPLOYEE_DEPLOYMENTS = gql`
  query EmployeeEmployeeDeployments($userId: String!) {
    employee(where: { userId: $userId }) {
      employeeDeployments {
        option
        deployment {
          id
          name
          deploymentType
          deploymentStartDate
          deploymentEndDate
          cargoBikeChildSeat
          urbanBikeChildSeat
          programStep {
            stepNbr
          }
        }
        chosenBikeModel {
          id
          remainingPrice
          bikemodel {
            brand
            bikeType
            name
            description
            publicPrice
            weight
            modelSizes
            pictureUrl
          }
        }
      }
    }
  }
`

export const useEmployeeEmployeeDeployments = (
  userId: string,
): QueryResult<
  EmployeeEmployeeDeploymentsQuery,
  EmployeeEmployeeDeploymentsQueryVariables
> => {
  return useQuery<
    EmployeeEmployeeDeploymentsQuery,
    EmployeeEmployeeDeploymentsQueryVariables
  >(QUERY_EMPLOYEE_EMPLOYEE_DEPLOYMENTS, {
    variables: { userId },
  })
}
