/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@goodwatt/client/src/components/Button'
import Modal from '@goodwatt/client/src/components/Modal'
import Popup from '@goodwatt/client/src/components/Popup'

import i18n from '@goodwatt/client/src/i18n/config'

interface ModalConfirmationProps {
  isOpen: boolean
  onSubmit: () => void
}

const WelcomeModal: React.FC<ModalConfirmationProps> = ({
  isOpen,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Modal isOpen={isOpen}>
      <Popup
        separator={false}
        closable={false}
        title={t('employee.onBoarding.welcomeModal.title')}
        titleColor={theme.colors.primary}
        maxWidth={640}
        footer={
          <Fragment>
            <Button submit type="primary" onClick={onSubmit}>
              {i18n.t('forms.button.next')}
            </Button>
          </Fragment>
        }
      >
        <p
          css={{
            textAlign: 'center',
            padding: '0 18px',
            lineHeight: '21px',
          }}
        >
          {t('employee.onBoarding.welcomeModal.description')}
        </p>
      </Popup>
    </Modal>
  )
}

export default WelcomeModal
