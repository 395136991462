/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Input from '../../../../../../components/Input'

import { setLoanBikeForm } from '../../../../../../redux/forms'
import { StoreState } from '../../../../../../redux/rootReducer'

import { format } from 'date-fns'
import { employeeNameFormatter } from '../../../../../../utils/formatter'
import { useQuery } from '@apollo/client'

import { QUERY_GET_EMPLOYEE_ABSENCES } from '../../../../../../utils/gql/queries'
import {
  AppointmentCategory,
  GetEmployeeAbsencesQuery,
  GetEmployeeAbsencesQueryVariables,
  GetEmployeeQuery,
} from '../../../../../../__generated__/graphql'

interface BikeAttributionProps {
  loanEventsFormatted: { loanDeliveryDate: string; loanReturnDate: string }
  employee: GetEmployeeQuery['employee'] | null | undefined
  hasBabySeat: boolean
  deploymentId: string
}

const BikeAttribution: React.FC<BikeAttributionProps> = ({
  deploymentId,
  employee,
  hasBabySeat,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { stickerId } = useSelector(
    (state: StoreState) => state.forms.loanBikeForm,
  )
  const [inputError, setInputError] = React.useState<string | undefined>()

  const employeeName = employee
    ? employeeNameFormatter(employee?.firstName, employee?.lastName)
    : null

  const { data: employeeAbsence } = useQuery<
    GetEmployeeAbsencesQuery,
    GetEmployeeAbsencesQueryVariables
  >(QUERY_GET_EMPLOYEE_ABSENCES, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: employee?.id,
      },
    },
  })

  const loanDeliveryAppointment = employee?.loanDeliveryAppointment?.find(
    appointment => appointment?.deploymentId === deploymentId,
  )

  const loanReturnAppointment = employee?.loanReturnAppointment?.find(
    appointment => appointment?.deploymentId === deploymentId,
  )

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLoanBikeForm({ stickerId: parseInt(e.target.value, 10) }))
  }

  const onInputBlur = () => {
    setInputError(!stickerId ? t('forms.errors.required') : undefined)
  }

  const loanDeliveryAbsence = employeeAbsence?.employee?.absences?.find(
    x =>
      x?.category === AppointmentCategory.LoanDelivery &&
      x?.deploymentId === deploymentId,
  )

  const loanReturnAbsence = employeeAbsence?.employee?.absences?.find(
    x =>
      x?.category === AppointmentCategory.LoanReturn &&
      x?.deploymentId === deploymentId,
  )

  const loanDeliveryDate = loanDeliveryAppointment?.date
    ? format(new Date(loanDeliveryAppointment.date), 'dd/MM/yyyy')
    : loanDeliveryAbsence
    ? format(new Date(loanDeliveryAbsence.date), 'dd/MM/yyyy')
    : null

  const loanReturnDate = loanReturnAppointment?.date
    ? format(new Date(loanReturnAppointment.date), 'dd/MM/yyyy')
    : loanReturnAbsence
    ? format(new Date(loanReturnAbsence.date), 'dd/MM/yyyy')
    : null

  let loanDateRange = t(
    'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeAttribution.loanDateRangeWithoutEnd',
    {
      loanDeliveryDate: loanDeliveryDate || 'n/a',
    },
  )

  if (loanDeliveryDate != null && loanReturnDate != null) {
    loanDateRange = t(
      'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeAttribution.loanDateRange',
      {
        loanDeliveryDate: loanDeliveryDate || 'n/a',
        loanReturnDate: loanReturnDate || 'n/a',
      },
    )
  }

  return (
    <div css={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <div css={{ maxWidth: 550 }}>
        <form>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeAttribution.description',
                  { employeeName: employeeName },
                ),
              }}
            />
            {hasBabySeat && (
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeAttribution.babySeat',
                  ),
                }}
              />
            )}
          </p>
          <div css={{ marginBottom: 35 }} />
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: loanDateRange,
              }}
            />
          </p>
          <div css={{ marginBottom: 35 }} />
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeAttribution.selectBike',
              ),
            }}
          />
          <div css={{ marginBottom: 20 }} />
          <Input
            name="stickerId"
            placeholder={t('shared.placeholder.userEntry')}
            value={`${stickerId || ''}`}
            onChange={onInputChange}
            onBlur={onInputBlur}
            error={!!inputError}
            helperText={inputError}
          />
        </form>
      </div>
    </div>
  )
}

export default BikeAttribution
