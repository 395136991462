import React from 'react'

export enum AdminTableTypes {
  ANIMATOR = 'animator',
  AREAS = 'areas',
  FAQ = 'faq',
  ADVICE = 'advice',
  QUIZ = 'quiz',
  TERRITORY = 'territory',
}

const initalContext: TableRefetchContextType = {
  refetchTables: () => null,
  removeTableToFetch: () => null,
  tableRefetchPlanner: {
    [AdminTableTypes.ANIMATOR]: false,
    [AdminTableTypes.AREAS]: false,
    [AdminTableTypes.TERRITORY]: false,
    [AdminTableTypes.FAQ]: false,
    [AdminTableTypes.ADVICE]: false,
    [AdminTableTypes.QUIZ]: false,
  },
}

export type TableRefetchContextType = {
  refetchTables: (tables: AdminTableTypes[]) => void
  removeTableToFetch: (table: AdminTableTypes) => void
  tableRefetchPlanner: { [tables in AdminTableTypes]: boolean }
}

export const AdminTableRefetchContext =
  React.createContext<TableRefetchContextType>(initalContext)

const AdminTableRefetchProvider: React.FC = ({ children }) => {
  const [needToRefetch, setNeedToRefetch] = React.useState<AdminTableTypes[]>(
    [],
  )

  const tableRefetchPlanner = React.useMemo(
    () => ({
      [AdminTableTypes.ANIMATOR]: needToRefetch.includes(
        AdminTableTypes.ANIMATOR,
      ),
      [AdminTableTypes.AREAS]: needToRefetch.includes(AdminTableTypes.AREAS),
      [AdminTableTypes.TERRITORY]: needToRefetch.includes(
        AdminTableTypes.TERRITORY,
      ),
      [AdminTableTypes.FAQ]: needToRefetch.includes(AdminTableTypes.FAQ),
      [AdminTableTypes.ADVICE]: needToRefetch.includes(AdminTableTypes.ADVICE),
      [AdminTableTypes.QUIZ]: needToRefetch.includes(AdminTableTypes.QUIZ),
    }),
    [needToRefetch],
  )

  const removeTableToFetch = React.useCallback((table: AdminTableTypes) => {
    setNeedToRefetch(prevState => prevState.filter(t => t !== table))
  }, [])

  const refetchTables = React.useCallback(
    (tablesToRefetch: AdminTableTypes[]) => {
      setNeedToRefetch(tablesToRefetch)
    },
    [],
  )

  return (
    <AdminTableRefetchContext.Provider
      value={{ refetchTables, tableRefetchPlanner, removeTableToFetch }}
    >
      {children}
    </AdminTableRefetchContext.Provider>
  )
}

export default AdminTableRefetchProvider
