import { GetBikeModelQuery, GetBikesQuery } from '../__generated__/graphql'

export enum FleetModalType {
  EDIT = 'edit',
  DELETE = 'delete',
  NONE = 'none',
}

export interface FleetModalSetting {
  fleet: FleetsTableRow | undefined
  modalState: FleetModalType
}

export interface FleetListProps {
  filters: {
    [filterName: string]: string
  }
  pageSize: number
  onFleetsChange: () => void
}

export interface FleetsTableRow {
  id: string | undefined
  name: string | undefined
  totalBike: number | undefined
  totalAvailableBike: number | undefined
  totalLoanBike: number | undefined
  totalOtherBike: number | undefined
  nextStepCategory: string | undefined
  nextStepDate: string | undefined
  area:
    | {
        id: number
        name: string
      }
    | undefined
}

export interface FleetModalLineBike {
  stickerId: number | undefined
  morioId: string
  bicycode: string
  keysId: string
  modelId: string
  id: string
}

export interface FleetModalListProps {
  numberOfRow: number
  bikeModels: GetBikeModelQuery['bikeModels']
  bikes: FleetModalLineBike[]
  onBikesChange: (bikes: FleetModalLineBike[]) => void
  bikeErrors: GetBikesQuery['bikes']
}

export interface FleetModalLineProps {
  bike: FleetModalLineBike
  bikeModels: GetBikeModelQuery['bikeModels']
  onBikeChange: (bike: FleetModalLineBike) => void
  bikeErrors: GetBikesQuery['bikes']
  bikes: FleetModalLineBike[]
  onError: (isError: boolean) => void
  isNew?: boolean
}
