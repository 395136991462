/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'

import Card from '@goodwatt/client/src/components/Card'
import List from './List'
import CardInfo from '../../../components/CardInfo'
import { useQuery } from '@apollo/client'

import { QUERY_GET_CONTACTS } from '../../../utils/gql/queries'
import Loader from '../../../components/Loader'
import { IconTypes } from '../../../components/Icon'
import { ContactInfo, GetContactsQuery } from '../../../__generated__/graphql'
const PAGESIZE = 100

function cardInfoData(contact: ContactInfo | null): {
  icon?: IconTypes
  description: string
  textColor?: string
}[] {
  const defaultData: Array<{
    icon?: IconTypes
    description: string
    textColor?: string
  }> = [
    {
      icon: 'profile',
      description: `${contact?.firstName} ${contact?.lastName?.toUpperCase()}`,
    },
    {
      icon: 'envelope',
      description: contact?.email?.toLowerCase() || '',
    },
  ]

  return defaultData
}

const CompanyMonitoring: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { data, loading } = useQuery<GetContactsQuery>(QUERY_GET_CONTACTS)

  if (loading) return <Loader />

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goodwatt - FAQ</title>
        <link rel="canonical" href="https://goodwatt.fr/employee/post" />
      </Helmet>

      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        {data?.getContacts?.map(contact => (
          <div
            key={contact?.userRole}
            css={{
              width: 'calc(50% - 15px)',
              minWidth: '450px',
              marginBottom: 24,
              [theme.media.mobile]: {
                width: '100%',
                minWidth: 'auto',
              },
            }}
          >
            <CardInfo
              title={t(`shared.contact.cardTitle.${contact?.userRole}`)}
              data={cardInfoData(contact)}
            />
          </div>
        ))}
      </div>

      <Card
        title={
          <span
            css={{
              fontFamily: theme.fontFamily,
              fontWeight: 'bold',
              fontSize: '1.6rem',
              paddingBottom: '1rem',
            }}
          >
            {t('animator.post.dashboardTitleLabel')}
          </span>
        }
      >
        <List pageSize={PAGESIZE} />
      </Card>
    </div>
  )
}

export default CompanyMonitoring
