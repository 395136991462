import i18n from '../../../../i18n/config'
import { RestrictionType } from '@goodwatt/shared/dist/types'
import { gql } from '@apollo/client'
import { AccessibilityAnimator } from '../../../../__generated__/graphql'

export const MUTATION_UPLOAD_DOCUMENT_BY_ANIMATOR = gql`
  mutation UploadDocumentByAnimator(
    $file: Upload!
    $title: String!
    $companyId: String!
    $accessibility: accessibilityAnimator
    $employeeId: String
  ) {
    uploadDocumentByAnimator(
      companyId: $companyId
      file: $file
      title: $title
      accessibility: $accessibility
      employeeId: $employeeId
    ) {
      id
    }
  }
`

export const MUTATION_UPDATE_DOCUMENT_BY_ANIMATOR = gql`
  mutation UpdateDocumentByAnimator(
    $title: String!
    $accessibility: accessibilityAnimator!
    $documentId: String!
  ) {
    updateDocumentByAnimator(
      title: $title
      accessibility: $accessibility
      documentId: $documentId
    ) {
      id
    }
  }
`

export const accessibilitySelectOptions = [
  {
    label: i18n.t('shared.documents.docModal.option5'),
    value: AccessibilityAnimator.Employee,
  },
  {
    label: i18n.t('shared.documents.docModal.option3'),
    value: AccessibilityAnimator.ReferrerEmployee,
  },
]

export const accessibilityDefaultValue = (
  restrictions: string[],
): {
  label: string
  value: string
} | null => {
  if (
    restrictions?.includes(RestrictionType.ANIMATOR_D) &&
    restrictions?.includes(RestrictionType.ANIMATOR_W) &&
    restrictions?.includes(RestrictionType.ANIMATOR_R) &&
    restrictions.length === 3
  )
    return {
      label: i18n.t('shared.documents.docModal.option1'),
      value: AccessibilityAnimator.Animator,
    }
  if (
    restrictions?.includes(RestrictionType.ANIMATOR_D) &&
    restrictions?.includes(RestrictionType.ANIMATOR_W) &&
    restrictions?.includes(RestrictionType.ANIMATOR_R) &&
    restrictions?.includes(RestrictionType.COMPANY_R) &&
    restrictions.length === 4
  )
    return {
      label: i18n.t('shared.documents.docModal.option2'),
      value: AccessibilityAnimator.Referrer,
    }
  if (
    restrictions?.includes(RestrictionType.ANIMATOR_D) &&
    restrictions?.includes(RestrictionType.ANIMATOR_W) &&
    restrictions?.includes(RestrictionType.ANIMATOR_R) &&
    restrictions?.includes(RestrictionType.COMPANY_R) &&
    restrictions?.includes(RestrictionType.EMPLOYEE_R) &&
    restrictions.length === 5
  )
    return {
      label: i18n.t('shared.documents.docModal.option3'),
      value: AccessibilityAnimator.ReferrerEmployee,
    }
  return null
}
