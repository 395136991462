/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useQuery } from '@apollo/client'

import {
  QUERY_ANIMATOR_GET_POSTS,
  QUERY_ANIMATOR_GET_TAGS,
} from '../../../utils/gql/queries'
import FAQList from '../../Shared/FAQList'
import {
  GetPostsQuery,
  GetPostsQueryVariables,
  GetTagsQuery,
  SortOrder,
} from '../../../__generated__/graphql'

interface AnimatorCompanyMonitoringProps {
  pageSize: number
}

const AnimatorCompanyMonitoringList: React.FC<
  AnimatorCompanyMonitoringProps
> = () => {
  const { data: postTags } = useQuery<GetTagsQuery>(QUERY_ANIMATOR_GET_TAGS, {
    fetchPolicy: 'network-only',
    variables: {
      orderBy: [{ order: SortOrder.Asc }],
    },
  })
  const { data: postData } = useQuery<GetPostsQuery, GetPostsQueryVariables>(
    QUERY_ANIMATOR_GET_POSTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        orderBy: [{ order: SortOrder.Asc }],
      },
    },
  )

  return <FAQList postData={postData} postTags={postTags} />
}

export default AnimatorCompanyMonitoringList
