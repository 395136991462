/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'emotion-theming'

const SuccessStep: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div css={{ width: '100%' }}>
      <p
        css={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontSize: '1.6rem',
          marginBottom: '40px',
          textAlign: 'center',
          color: theme.colors.gray2,
        }}
        dangerouslySetInnerHTML={{
          __html: t('auth.signupEmployee.successDescription'),
        }}
      />

      <div
        css={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            history.push('/')
          }}
        >
          {t('forms.button.backToWebsite')}
        </Button>
      </div>
    </div>
  )
}

export default SuccessStep
