/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'

import Icon from '../../../../../components/Icon'

import {
  BikeComponentTableModal,
  ComponentRow,
  EditBikePartModalSettings,
} from '../EditBikeComponentsCondition'

interface BikeComponentConditionActionCellProps {
  setPartModalSettings: (partModalSettings: EditBikePartModalSettings) => void
  component: ComponentRow
}

const BikeComponentConditionActionCell: React.FC<
  BikeComponentConditionActionCellProps
> = ({ setPartModalSettings, component }) => {
  const theme = useTheme()
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: BikeComponentTableModal,
    ) => {
      e.stopPropagation()
      setPartModalSettings({
        actionName,
        component,
      })
    },
    [component, setPartModalSettings],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <div
        css={{ cursor: 'pointer' }}
        onClick={e => handleClick(e, BikeComponentTableModal.CAMERA)}
      >
        <Icon
          type="camera"
          color={
            !component.attachments?.length
              ? theme.colors.gray2
              : theme.colors.primary
          }
        />
      </div>
      <div
        css={{ cursor: 'pointer' }}
        onClick={e => handleClick(e, BikeComponentTableModal.NOTE)}
      >
        <Icon
          type="note"
          color={component.note ? theme.colors.primary : theme.colors.gray2}
        />
      </div>
    </div>
  )
}

export default BikeComponentConditionActionCell
