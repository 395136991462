/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'

export type PlacementTypes =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'left-start'
  | 'left'
  | 'left-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-start'
  | 'bottom'
  | 'bottom-end'

export interface TooltipProps {
  title: string
  placement?: PlacementTypes
  block?: boolean
  children: React.ReactNode
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  placement = 'top',
  block = true,
  children,
}): JSX.Element => {
  const theme = useTheme()
  const isTop = placement.indexOf('top') > -1
  const isLeft = placement.indexOf('left') > -1
  const isSide = isLeft || placement.indexOf('right') > -1

  return (
    <div
      css={{
        position: 'relative',
        display: block ? 'block' : 'inline-block',
        // '&:hover span:first-child': {
        //   visibility: 'visible',
        // },
        '&:hover span:first-of-type': {
          visibility: 'visible',
        },
      }}
    >
      <span
        css={{
          position: 'absolute',
          display: 'flex',
          visibility: 'hidden',
          [isSide || isTop ? 'top' : 'bottom']: '0',
          [isLeft ? 'left' : 'right']: '0',
          flexDirection: isSide ? 'column-reverse' : 'row',
          width: isSide ? 'auto' : '100%',
          height: isSide ? '100%' : 'auto',
          alignItems: isLeft ? 'flex-end' : 'normal',
          justifyContent:
            placement.indexOf('end') > 0
              ? isSide
                ? 'flex-start'
                : 'flex-end'
              : placement.indexOf('start') > 0
              ? isSide
                ? 'flex-end'
                : 'flex-start'
              : 'center',
          fontSize: '1.3rem',
          transform: isSide
            ? `translateX(calc(${isLeft ? '-100% - .5rem' : '100% + .5rem'}))`
            : `translateY(calc(${isTop ? '-100% - .5rem' : '100% + .5rem'}))`,
          zIndex: 1,
        }}
      >
        <span
          css={{
            width: 'fit-content',
            padding: '1rem',
            borderRadius: 6,
            fontFamily: theme.fontFamily,
            color: '#fff',
            backgroundColor: theme.colors.gray3,
            textAlign: 'center',
          }}
        >
          {title}
        </span>
      </span>
      {children}
    </div>
  )
}

export default Tooltip
