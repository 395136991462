/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../../../../../components/Checkbox'
import { setReturnBikeForm } from '../../../../../../redux/forms'
import { StoreState } from '../../../../../../redux/rootReducer'
import { employeeNameFormatter } from '../../../../../../utils/formatter'
import { Employee } from '../../../../../../__generated__/graphql'

const currStepi18nNamespace =
  'animator.company.employees.selected.modals.employeeReturnBikeModal.animatorReturnValidation'

interface ReturnBikeAnimatorValidationProps {
  employee: Pick<Employee, 'firstName' | 'lastName'>
}

const ReturnBikeAnimatorValidation: React.FC<
  ReturnBikeAnimatorValidationProps
> = ({ employee }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const { animatorValidation } = useSelector(
    (state: StoreState) => state.forms.returnBikeForm,
  )

  const handleValidationClick = () => {
    dispatch(setReturnBikeForm({ animatorValidation: !animatorValidation }))
  }

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 550,
        }}
      >
        <div css={{ marginTop: 15 }}>
          <span>{t(`${currStepi18nNamespace}.description.bike`)} </span>
          <span
            dangerouslySetInnerHTML={{
              __html: t(`${currStepi18nNamespace}.description.employee`, {
                employeeName: employeeNameFormatter(
                  employee.firstName,
                  employee.lastName,
                ),
              }),
            }}
          />
          <span>
            {' '}
            {t(`${currStepi18nNamespace}.description.retentionOnDeposit`)}
          </span>
        </div>
        <div css={{ marginBottom: 35 }} />
        <div
          onClick={handleValidationClick}
          css={{
            padding: 20,
            display: 'flex',
            alignItems: 'center',
            border: `2px solid ${theme.colors.gray6}`,
            borderRadius: '22px',
            cursor: 'pointer',
            [theme.media.mobile]: {
              justifyContent: 'space-between',
            },
          }}
        >
          <div css={{ [theme.media.mobile]: { display: 'flex', flex: 1 } }}>
            <Checkbox isChecked={animatorValidation} />
          </div>
          <span
            css={{
              color: theme.colors.primary,
              fontSize: '1.6rem',
              fontWeight: 'bold',
              userSelect: 'none',
              [theme.media.mobile]: { flex: 5 },
            }}
          >
            {t('shared.termsOfContract')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ReturnBikeAnimatorValidation
