/** @jsx jsx */
import React, { useMemo, useState } from 'react'
import { jsx } from '@emotion/core'
import { useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import theme from '../../../styles/theme'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '@goodwatt/client/src/components/Table'
import { QUERY_ALL_BIKE_MODEL_NAMES } from '@goodwatt/client/src/utils/gql/queries'
import {
  GetAllBikeModelNamesQuery,
  GetAllBikeModelNamesQueryVariables,
} from '../../../__generated__/graphql'

import {
  BikeModelTableModal,
  BikeModelModalSettings,
  BikeModelTableRow,
} from '@goodwatt/client/src/types/AnimatorBikes'

import BikeModelsActionCell from '@goodwatt/client/src/components/Table/CustomCells/BikeModelsActionCell'
import { SpecialBikesType } from '@goodwatt/shared/dist/types'
import EditBikeModelModal from './BikeModelModal'
import DeleteBikeModelModal from './DeleteBikeModelModal'

const initalModalSettings: BikeModelModalSettings = {
  bikeModel: {
    id: '',
    name: '',
    brand: '',
    bikeType: '',
    childSeatCompatibility: false,
  },
  actionName: '',
}

const BikeModelList: React.FC = () => {
  const { t } = useTranslation()
  const [currModal, setCurrModal] =
    useState<BikeModelModalSettings>(initalModalSettings)
  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)
  const {
    data: bikeModelNames,
    loading,
    refetch,
  } = useQuery<GetAllBikeModelNamesQuery, GetAllBikeModelNamesQueryVariables>(
    QUERY_ALL_BIKE_MODEL_NAMES,
    {
      fetchPolicy: 'network-only',
      variables: {
        orderBy:
          orderBy.sort && orderBy.columnName
            ? { [orderBy.columnName]: orderBy.sort }
            : undefined,
      },
    },
  )

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<BikeModelTableRow> }) => (
      <BikeModelsActionCell setModal={setCurrModal} row={row} />
    ),
    [],
  )

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('animator.bikes.table.headers.bikeModelBrand') as string,
        accessor: 'brand',
      },
      {
        Header: t('animator.bikes.table.headers.bikeModelName') as string,
        accessor: 'name',
      },
      {
        Header: t('animator.bikes.table.headers.bikeModelType') as string,
        accessor: 'bikeType',
        Cell: ({ value }: { value: SpecialBikesType }) => {
          return (
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {value === SpecialBikesType.URBAN_BIKE && (
                <Icon type="bikeUrban" color={theme.colors.gray2} />
              )}

              {value === SpecialBikesType.FOLDING_BIKE && (
                <Icon type="bikeFolding" color={theme.colors.gray6} />
              )}

              {value === SpecialBikesType.CARGO_BIKE && (
                <Icon type="bikeCargo" color={theme.colors.gray2} />
              )}
            </div>
          )
        },
      },
      {
        Header: t('animator.bikes.table.headers.bikeModelChildSeat') as string,
        accessor: 'childSeatCompatibility',
        Cell: ({ value }: { value: boolean }) => {
          return (
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {value && <Icon type="kidOption" color={theme.colors.gray2} />}
            </div>
          )
        },
      },
      {
        Header: t('animator.bikes.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [t, ActionCell],
  )

  const formattedData = useMemo(() => {
    if (!bikeModelNames?.bikeModels?.length) return []

    return bikeModelNames.bikeModels.map(bikeModel => {
      return {
        id: bikeModel?.id,
        brand: bikeModel?.brand,
        name: bikeModel?.name,
        bikeType: bikeModel?.bikeType,
        childSeatCompatibility: bikeModel?.childSeatCompatibility,
      }
    })
  }, [bikeModelNames])

  const handleEditModalClose = (shouldRefetch = false) => {
    setCurrModal(initalModalSettings)
  }

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={formattedData}
        onSortBy={setOrderBy}
        loading={loading}
      />
      <div
        css={{
          marginTop: '41px',
          display: 'flex',
          justifyContent: 'flex-start',
          position: 'relative',
        }}
      >
        <Button
          submit
          onClick={() =>
            setCurrModal({
              ...initalModalSettings,
              actionName: BikeModelTableModal.ADD,
            })
          }
        >
          <span
            css={{
              fontFamily: theme.fontFamily,
              fontSize: '1.6rem',
              fontWeight: 500,
              color: theme.colors.white,
              textTransform: 'uppercase',
            }}
          >
            {t('shared.button.addBikeModel')}
          </span>
        </Button>
      </div>
      <EditBikeModelModal
        bikeModelId={currModal.bikeModel?.id}
        isOpen={
          currModal.actionName === BikeModelTableModal.EDIT ||
          currModal.actionName === BikeModelTableModal.ADD
        }
        type={currModal.actionName}
        closeModal={handleEditModalClose}
      />
      <DeleteBikeModelModal
        bikeModel={currModal.bikeModel}
        refetch={refetch}
        isOpen={currModal.actionName === BikeModelTableModal.DELETE}
        closeModal={handleEditModalClose}
      />
    </React.Fragment>
  )
}

export default BikeModelList
