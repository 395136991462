/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import { useSelector } from 'react-redux'

import CardFoldable from '../../../../../components/Card/Foldable'
import InputSelect from '../../../../../components/InputSelect'
import Input from '../../../../../components/Input'
import CardTitle from '../../../../../components/Card/CardTitle'
import List from './List'

import { TextVariant } from '../../../../../hooks/useVariant/variants/text'
import { SelectVariant } from '../../../../../hooks/useVariant/variants/select'
import {
  QUERY_ALL_FLEET_NAMES,
  QUERY_TERRITORY_DEPARTEMENT,
} from '../../../../../utils/gql/queries'
import { StoreState } from '../../../../../redux/rootReducer'
import { UserRoles } from '@goodwatt/shared/dist/types'
import {
  GetAllFleetNamesQuery,
  GetAllFleetNamesQueryVariables,
  GetTerritoryDepartmentQuery,
} from '../../../../../__generated__/graphql'

let debouncedFn: undefined | (() => void)

const CompaniesArchivedList: React.FC<{ pageSize: number }> = ({
  pageSize,
}) => {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({})

  const { id: userId, role: userRole } = useSelector(
    (state: StoreState) => state.user,
  )

  const isAdmin = userRole === UserRoles.ADMIN

  const { data } = useQuery<
    GetAllFleetNamesQuery,
    GetAllFleetNamesQueryVariables
  >(QUERY_ALL_FLEET_NAMES, {
    variables: {
      where: isAdmin
        ? {}
        : {
            area: {
              animator: { some: { userId: { equals: userId } } },
            },
          },
    },
  })

  const { data: departments } = useQuery<GetTerritoryDepartmentQuery>(
    QUERY_TERRITORY_DEPARTEMENT,
  )

  const formattedDepartments = useMemo(() => {
    return (
      departments?.territoryDepartments.map(t => ({
        label: `${t.code} ${t.name}`,
        value: t.code,
      })) || []
    )
  }, [departments])

  const theme = useTheme()

  const onSelectChange = React.useCallback(({ value }, { name }) => {
    setFilters(prevState => ({
      ...prevState,
      [name]: value !== 'all' ? value : undefined,
    }))
  }, [])

  const onInputNameChange = React.useCallback(e => {
    /* signal to React not to nullify the event object */
    e.persist()

    if (!debouncedFn) {
      debouncedFn = debounce(() => {
        setFilters(prevState => ({
          ...prevState,
          multiFilter: e.target.value || undefined,
        }))
      }, 300)
    }
    debouncedFn()
  }, [])

  React.useEffect(() => (debouncedFn = undefined), [])

  const fleetFilterOptions = React.useMemo(
    () =>
      data?.fleets
        ? data?.fleets.map(({ name }: { name: string }) => ({
            value: name,
            label: name,
          }))
        : [],
    [data],
  )

  return (
    <div css={{ marginBottom: '30px' }}>
      <CardFoldable
        title={
          <CardTitle
            statusColor={theme.colors.gray4}
            label={t('animator.companies.ArchivedTitleLabel')}
          />
        }
        isDefaultFolded
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            marginTop: '2.1rem',
            marginBottom: '2.1rem',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <div
            css={{
              display: 'flex',
              flex: 1,
              minWidth: '220px',
              paddingRight: '17px',
              width: '100%',
              [theme.media.mobile]: {
                paddingRight: '0px',
              },
            }}
          >
            <InputSelect
              name="fleet"
              variant={SelectVariant.white}
              label={t('animator.companies.filter.fleets')}
              withError={false}
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t('animator.companies.filter.options.all'),
                },
                ...fleetFilterOptions,
              ]}
              onChange={onSelectChange}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flex: 1,
              minWidth: '220px',
              paddingRight: '17px',
              width: '100%',
              [theme.media.mobile]: {
                paddingRight: '0px',
                marginTop: '14px',
              },
            }}
          >
            <InputSelect
              name="department"
              variant={SelectVariant.white}
              withError={false}
              label={t('animator.companies.filter.department')}
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t('animator.companies.filter.options.all'),
                },
                ...formattedDepartments,
              ]}
              onChange={onSelectChange}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flex: 2,
              minWidth: '220px',
              [theme.media.tablet]: {
                marginTop: '14px',
              },
            }}
          >
            <Input
              icon="magnifier"
              variant={TextVariant.white}
              name="accptedAutoComplete"
              withError={false}
              onChange={onInputNameChange}
            />
          </div>
        </div>
        <List filters={filters} pageSize={pageSize} />
      </CardFoldable>
    </div>
  )
}

export default CompaniesArchivedList
