/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  PackageAccessoryType,
  StateOfUseType,
} from '@goodwatt/shared/dist/types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EmployeeValidationState, setLoanBikeForm } from '../../../redux/forms'
import { StoreState } from '../../../redux/rootReducer'

import { PartsConditionTableRow } from '../../../types/AnimatorCompanyEmployees'
import StarRating from '../../StarRating'

interface PackageAccessoryStarRatingCellProps {
  accessory: PartsConditionTableRow
}

const PackageAccessoryStarRatingCell: React.FC<
  PackageAccessoryStarRatingCellProps
> = ({ accessory }) => {
  const dispatch = useDispatch()
  const { accessories } = useSelector(
    (state: StoreState) => state.forms.loanBikeForm,
  )

  const currentAccessory = React.useMemo(
    () => accessories[accessory.name as PackageAccessoryType],
    [accessories, accessory.name],
  )

  React.useEffect(() => {
    if (!currentAccessory.included && currentAccessory.stateOfUse) {
      dispatch(
        setLoanBikeForm({
          accessories: {
            ...accessories,
            [accessory.name as PackageAccessoryType]: {
              ...currentAccessory,
              stateOfUse: undefined,
            },
          },
        }),
      )
    }
  }, [accessories, accessory.name, currentAccessory, dispatch])

  const onClick = (newStateOfUse: StateOfUseType) => {
    dispatch(
      setLoanBikeForm({
        employeeValidation: EmployeeValidationState.TODO,
        accessories: {
          ...accessories,
          [accessory.name as PackageAccessoryType]: {
            ...currentAccessory,
            stateOfUse: newStateOfUse,
          },
        },
      }),
    )
  }

  return (
    <StarRating
      id={accessory.name || ''}
      disabled={!currentAccessory.included}
      updatedStateOfUse={currentAccessory.stateOfUse}
      onRatingClick={onClick}
    />
  )
}

export default PackageAccessoryStarRatingCell
