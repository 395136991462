/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  BikeComponentPartType,
  StateOfUseType,
} from '@goodwatt/shared/dist/types'
import { useTheme } from 'emotion-theming'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'react-table'

import Table from '../../../../../components/Table'
import PartsConditionActionCell from './ActionTableCell'
import StarRatingTableCell from './StarRatingTableCell'

import EditBikePartModal from './EditBikePartModal'
import { EditBikeComponentCondition } from '..'
import {
  BikeComponent,
  BikeLoanAttachment,
} from '../../../../../__generated__/graphql'

export enum BikeComponentTableModal {
  NONE = 'none',
  NOTE = 'note',
  CAMERA = 'camera',
}

export type ComponentRow = {
  id: string
  stateOfUse: string
  translatedName: string
  name: string
  attachments: BikeLoanAttachment[]
  action: string
  note: string | null | undefined
}

export type EditBikePartModalSettings = {
  actionName: BikeComponentTableModal
  component: ComponentRow
}

interface EditBikeComponentsConditionProps {
  components: BikeComponent[]
  editedComponents: EditBikeComponentCondition
  onStarClickAction: (
    component: ComponentRow,
    value: { stateOfUse: StateOfUseType },
  ) => void
  onNoteModalSubmit: (component: ComponentRow, value: { note: string }) => void
}

const EditBikeComponentsCondition: React.FC<
  EditBikeComponentsConditionProps
> = ({
  components,
  editedComponents,
  onStarClickAction,
  onNoteModalSubmit,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [partModalSettings, setPartModalSettings] =
    useState<EditBikePartModalSettings>({
      actionName: BikeComponentTableModal.NONE,
      component: {
        action: '',
        attachments: [],
        id: '',
        name: '',
        note: '',
        stateOfUse: '',
        translatedName: '',
      },
    })

  const ActionCell = useCallback(
    ({ row: { original } }: { row: { original: ComponentRow } }) => (
      <PartsConditionActionCell
        setPartModalSettings={setPartModalSettings}
        component={original}
      />
    ),
    [],
  )

  const BikeConditionRatingCell = useCallback(
    ({ row: { original } }: { row: { original: ComponentRow } }) => (
      <StarRatingTableCell
        component={original}
        editedStateOfUse={
          editedComponents[original.name as BikeComponentPartType].stateOfUse
        }
        onStarClickAction={stateOfUse =>
          onStarClickAction(original, { stateOfUse: stateOfUse })
        }
      />
    ),
    [editedComponents, onStarClickAction],
  )

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t(
          `animator.bikes.modal.bikeComponentsCondition.table.headers.bikeComponent`,
        ) as string,
        accessor: 'translatedName',
        disableSortBy: true,
        maxWidth: 200,
        cellStyle: () => ({ textAlign: 'left' }),
      },
      {
        Header: t(
          `animator.bikes.modal.bikeComponentsCondition.table.headers.conditionDescription`,
        ) as string,
        accessor: 'stateOfUse',
        disableSortBy: true,
        Cell: BikeConditionRatingCell,
      },
      {
        Header: t(
          `animator.bikes.modal.bikeComponentsCondition.table.headers.actions`,
        ) as string,
        accessor: 'action',
        maxWidth: 95,
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [t, ActionCell, BikeConditionRatingCell],
  )

  const formattedData: ComponentRow[] = useMemo(() => {
    const itemsOrder = [
      BikeComponentPartType.BIKE_FRAME,
      BikeComponentPartType.BRAKES,
      BikeComponentPartType.BACK_WHEEL,
      BikeComponentPartType.FRONT_WHEEL,
      BikeComponentPartType.TRANSMISSIONS,
      BikeComponentPartType.HANDLEBAR,
      BikeComponentPartType.DEVICES,
      BikeComponentPartType.BATTERY,
      BikeComponentPartType.GLOBAL,
    ]

    return [...components]
      .sort(
        (a, b) =>
          itemsOrder.indexOf(
            a.bikeComponentPart.name as BikeComponentPartType,
          ) -
          itemsOrder.indexOf(b.bikeComponentPart.name as BikeComponentPartType),
      )
      .map(component => ({
        id: component.id,
        stateOfUse: component.stateOfUse.name,
        translatedName: t(
          `shared.bikeComponentPartLabel.${component.bikeComponentPart.name}`,
        ),
        name: component.bikeComponentPart.name,
        attachments: component.bikeLoanAttachments,
        action: '',
        note:
          editedComponents[
            component.bikeComponentPart.name as BikeComponentPartType
          ].note ?? component.note,
      }))
  }, [editedComponents, components, t])

  return (
    <Fragment>
      <div css={{ minHeight: 380 }}>
        <div
          css={{
            marginLeft: 21,
            marginBottom: 4,
          }}
        >
          <label
            css={{
              fontSize: '1.6rem',
              color: theme.colors.gray3,
            }}
          >
            {t('animator.bikes.modal.fields.bikeComponents.default')}
          </label>
        </div>
        <Table columns={columns} data={formattedData} />
        <EditBikePartModal
          isOpen={
            partModalSettings.actionName === BikeComponentTableModal.NOTE ||
            partModalSettings.actionName === BikeComponentTableModal.CAMERA
          }
          shouldOpenCameraInput={
            partModalSettings.actionName === BikeComponentTableModal.CAMERA
          }
          closeAutomaticCameraInput={() =>
            setPartModalSettings({
              actionName: BikeComponentTableModal.NOTE,
              component: partModalSettings.component,
            })
          }
          closeModal={() =>
            setPartModalSettings({
              actionName: BikeComponentTableModal.NONE,
              component: partModalSettings.component,
            })
          }
          component={partModalSettings.component}
          setComponentNote={onNoteModalSubmit}
        />
      </div>
    </Fragment>
  )
}

export default EditBikeComponentsCondition
