import { MutationHookOptions, useMutation } from '@apollo/client'
import { MUTATION_ARCHIVE_DEPLOYMENT } from '../../../../utils/gql/mutations'

export const useArchiveDeployment = (options?: MutationHookOptions) => {
  return useMutation(MUTATION_ARCHIVE_DEPLOYMENT, {
    refetchQueries: [
      'GetDeployments',
      'GetDeploymentsCount',
      'GetCompanySitesWithDeployments',
    ],
    ...options,
  })
}
