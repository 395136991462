/** @jsx jsx */
import { useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../../../../components/Loader'
import Modal from '../../../../../components/Modal'
import Popup from '../../../../../components/Popup'
import { QUERY_GET_COMPANY } from '../../../../../utils/gql/queries'
import {
  GetCompanyProfileQuery,
  GetCompanyProfileQueryVariables,
} from '../../../../../__generated__/graphql'

interface CompanyInfoModalProps {
  isOpen: boolean
  closeModal: () => void
  companyId: string
}

const CompanyInfoModal: React.FC<CompanyInfoModalProps> = ({
  isOpen,
  closeModal,
  companyId,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { data, loading } = useQuery<
    GetCompanyProfileQuery,
    GetCompanyProfileQueryVariables
  >(QUERY_GET_COMPANY, { variables: { where: { id: companyId } } })

  const formattedData = React.useMemo(() => {
    const company = data?.company
    const mainReferrer = company?.companyReferrers.find(
      referrer => referrer.mainReferrer === true,
    )

    return [
      {
        label: t('company.profile.nameLabel'),
        values: [company?.name],
      },
      {
        label: t('company.profile.typeLabel'),
        values: [t(`shared.companyTypeLabel.${company?.companyType}`)],
      },
      {
        label: t('company.profile.siteLabel'),
        values: [
          company?.sites[0]?.name,
          company?.sites[0]?.street,
          `${
            company?.sites[0]?.postalCode
              ? `${company?.sites[0]?.postalCode} `
              : ''
          } ${company?.sites[0]?.city || ''}`,
        ],
      },
      {
        label: t('company.profile.employeesCountLabel'),
        values: [
          t('company.profile.employee', { count: company?.nbrEmployees }),
        ],
      },
      {
        label: t('company.profile.referee'),
        values: [
          `${mainReferrer?.firstName || ''} ${mainReferrer?.lastName || ''}`,
          mainReferrer?.phoneNumber,
          mainReferrer?.email,
        ],
      },
      // {
      //   label: t('company.profile.bikeParking'),
      //   values: [
      //     t('company.profile.bikeSpaceCapacityInside', {
      //       count: company?.bikeSpaceCapacityInside,
      //     }),
      //     t('company.profile.bikeSpaceCapacityOutside', {
      //       count: company?.bikeSpaceCapacityOutside,
      //     }),
      //     t('company.profile.bikeSpaceCapacityUnsafe', {
      //       count: company?.bikeSpaceCapacityUnsafe,
      //     }),
      //   ],
      // },
      // {
      //   label: t('company.profile.carParking'),
      //   values: [
      //     t('company.profile.carSpaceCapacity', {
      //       count: company?.carSpaceCapacity,
      //     }),
      //     t('company.profile.electricStationCapacity', {
      //       count: company?.electricStationCapacity,
      //     }),
      //   ],
      // },
    ]
  }, [t, data])

  return (
    <Modal isOpen={isOpen}>
      <Popup
        maxWidth={750}
        onClose={closeModal}
        title={t('animator.companies.companyInfoModalTitle')}
      >
        {loading ? (
          <Loader />
        ) : (
          <div
            css={{
              paddingLeft: '100px',
              [theme.media.mobile]: {
                paddingLeft: 0,
              },
            }}
          >
            {formattedData.map((item, index) => (
              <div
                key={index}
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 16,
                }}
              >
                <div css={{ flex: 1 }}>
                  <p css={{ color: theme.colors.gray3 }}>{item.label}</p>
                </div>
                <div css={{ flex: 2 }}>
                  {item.values.map(value => (
                    <p key={value}>{value}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </Popup>
    </Modal>
  )
}

export default CompanyInfoModal
