import { useState } from 'react'

export interface UseModalResult {
  onOpen: () => void
  modalProps: { isOpen: boolean; onClose: () => void }
}

export const useModal = (): UseModalResult => {
  const [isOpen, setIsOpen] = useState(false)

  return {
    onOpen: () => setIsOpen(true),
    modalProps: {
      isOpen,
      onClose: () => setIsOpen(false),
    },
  }
}
