/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import { Controller, Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Input from '../../../../components/Input'
import DateTime from '../../../../components/DateTime'
import TextArea from '../../../../components/TextArea'

import CurrencyInput from '../../../../components/CurrencyInput'
import { GetBikeQuery } from '../../../../__generated__/graphql'

type TextInputRefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined

type TextAreaRefReturn =
  | string
  | ((instance: HTMLTextAreaElement | null) => void)
  | React.RefObject<HTMLTextAreaElement>
  | null
  | undefined

interface EditBikeConditionalInputs {
  register: () => TextAreaRefReturn | TextInputRefReturn
  control: Control
  bikeInfos: GetBikeQuery['bike'] | null | undefined
  currentForm: 'OTHER' | 'START_REPAIR' | 'UPDATE_CURRENT_REPAIR' | 'END_REPAIR'
  children: React.ReactNode
}

const EditBikeConditionalInputs: React.FC<EditBikeConditionalInputs> = ({
  control,
  register,
  bikeInfos,
  currentForm,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      {currentForm === 'START_REPAIR' ||
      currentForm === 'UPDATE_CURRENT_REPAIR' ? (
        <Fragment>
          <Input
            type="text"
            placeholder={t('animator.bikes.modal.fields.repairerLocationPlaceholder')} // prettier-ignore
            name="beforeRepair.repairerLocation"
            defaultValue={bikeInfos?.repairs?.[0]?.repairerLocation}
            label={t('animator.bikes.modal.fields.repairerLocation')}
            register={register as () => TextInputRefReturn}
          />
          <Controller
            name="beforeRepair.resolvedDate"
            control={control}
            defaultValue={
              bikeInfos?.repairs?.[0]?.resolvedDate
                ? new Date(bikeInfos.repairs[0].resolvedDate)
                : new Date()
            }
            render={({ onChange, value }) => (
              <DateTime
                label={t('animator.bikes.modal.fields.repairResolvedDate')}
                block
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Fragment>
      ) : null}
      {currentForm === 'END_REPAIR' && (
        <Controller
          name="afterRepair.price"
          control={control}
          defaultValue={bikeInfos?.repairs?.[0].price || 0}
          render={({ onChange, value, name }) => (
            <CurrencyInput
              currency="EUR"
              // minimumFractionDigits={2}
              onChange={onChange}
              value={value}
              name={name}
              label={t('animator.bikes.modal.fields.repairCost')}
            />
          )}
        />
      )}
      {children}
      {currentForm === 'START_REPAIR' ||
      currentForm === 'UPDATE_CURRENT_REPAIR' ? (
        <div css={{ width: '100%', marginTop: 18 }}>
          <TextArea
            name="beforeRepair.note"
            type="text"
            defaultValue={bikeInfos?.repairs?.[0]?.beginningNote || ''}
            block
            register={register as () => TextAreaRefReturn}
            placeholder={t('shared.placeholder.optionalComment')}
            withError={false}
          />
        </div>
      ) : null}
      {currentForm === 'END_REPAIR' && (
        <div css={{ width: '100%', marginTop: 18 }}>
          <TextArea
            name="afterRepair.note"
            type="text"
            defaultValue={bikeInfos?.repairs?.[0]?.completionNote || ''}
            block
            register={register as () => TextAreaRefReturn}
            placeholder={t('shared.placeholder.optionalComment')}
            withError={false}
          />
        </div>
      )}
    </Fragment>
  )
}

export default EditBikeConditionalInputs
