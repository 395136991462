/** @jsx jsx */
import React, { Fragment } from 'react'
import Modal from './Modal'
import Popup from './Popup'
import Button from './Button'
import i18n from '../i18n/config'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'

export interface ConfirmationModalProps {
  title: string
  description?: React.ReactNode
  loading?: boolean
  onSubmit?: () => void
  isOpen: boolean
  onClose: () => void
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  description,
  loading,
  onSubmit,
  isOpen,
  onClose,
}: ConfirmationModalProps) => {
  const theme = useTheme()

  return (
    <Modal isOpen={isOpen}>
      <Popup
        closable={false}
        title={title}
        titleColor={theme.colors.primary}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button
                disabled={loading}
                onClick={() => onClose()}
                type="tertiary"
              >
                {i18n.t('shared.button.cancel')}
              </Button>
            </div>
            <Button submit type="primary" loading={loading} onClick={onSubmit}>
              {i18n.t('shared.button.confirm')}
            </Button>
          </Fragment>
        }
      >
        {description && (
          <p
            css={{
              textAlign: 'center',
              padding: '0 18px',
              color: theme.colors.redLegend,
              fontWeight: 700,
            }}
          >
            {description}
          </p>
        )}
      </Popup>
    </Modal>
  )
}
