/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'emotion-theming'

import Card from '../../../components/Card'
import EditProfileAnimator from './EditProfile'
import EditPasswordAnimator from './EditPassword'
import EditAvatarAnimator from './EditAvatar'
import { StoreState } from '../../../redux/rootReducer'

import { QUERY_GET_ADMIN } from '../../../utils/gql/queries'
import {
  GetAdminQuery,
  GetAdminQueryVariables,
} from '../../../__generated__/graphql'

const Profile: React.FC = () => {
  const { t } = useTranslation()
  const userId = useSelector((store: StoreState) => store.user.id)
  const { loading, data, refetch } = useQuery<
    GetAdminQuery,
    GetAdminQueryVariables
  >(QUERY_GET_ADMIN, {
    variables: { where: { userId: userId } },
    fetchPolicy: 'no-cache',
  })
  const location = useLocation()
  const theme = useTheme()

  if (loading) return null

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('animator.profile.metaTitle')}</title>
        <link
          rel="canonical"
          href={`https://goodwatt.fr/${location.pathname}`}
        />
      </Helmet>
      <Card
        css={{
          width: '100%',
          padding: '24px 48px',
          [theme.media.laptop]: {
            padding: '12px 24px 24px 24px',
          },
          [theme.media.laptop]: {
            padding: '12px 12px 24px 12px',
          },
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            [theme.media.laptop]: {
              flexDirection: 'column-reverse',
            },
          }}
        >
          <div
            css={{
              flex: 9,
              paddingRight: '6.4rem',
              [theme.media.laptop]: {
                paddingRight: 0,
              },
            }}
          >
            <EditProfileAnimator data={data} userId={userId} />
            <EditPasswordAnimator />
          </div>
          <div
            css={{
              flex: 1,
              paddingBottom: 24,
              [theme.media.laptop]: { alignSelf: 'center' },
            }}
          >
            <EditAvatarAnimator
              avatarUrl={data?.administrator?.avatarUrl}
              refetch={refetch}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Profile
