/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Input from '../../../../components/Input'
import InputSelect from '../../../../components/InputSelect'

interface EditFleetInputProps {
  control: Control
  defaultValue: {
    label: string | null | undefined
    value: string | null | undefined
  }
  fleetOptions: { label: string; value: string }[]
  disabled: boolean
}

const EditFleetInput: React.FC<EditFleetInputProps> = ({
  control,
  defaultValue,
  fleetOptions,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <div css={{ display: 'flex', flex: 5 }}>
      {disabled ? (
        <Input
          disabled
          value={defaultValue?.value as string}
          label={t('forms.label.fleet')}
        />
      ) : (
        <Controller
          name="fleet"
          control={control}
          defaultValue={defaultValue}
          render={({ onChange, value, name }) => (
            <InputSelect
              label={t('forms.label.fleet')}
              name={name}
              value={value}
              onChange={onChange}
              options={fleetOptions}
            />
          )}
        />
      )}
    </div>
  )
}

export default EditFleetInput
