/** @jsx jsx */
import React, { useMemo, useState } from 'react'
import { jsx } from '@emotion/core'
import { useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '@goodwatt/client/src/components/Table'
import Pagination from '@goodwatt/client/src/components/Pagination'
import Button from '../../../../components/Button'
import theme from '../../../../styles/theme'
import Icon from '../../../../components/Icon'
import {
  QUERY_AREA_COUNT,
  QUERY_AREA_SEARCH,
} from '../../../../utils/gql/queries'

import AreaEditModal from './AreaEditModal'
import AreaDeleteModal from './AreaDeleteModal'
import AreaAddModal from './AreaAddModal'

import AreaActionCell from '../../../../components/Table/CustomCells/AreaActionCell'
import {
  AreaModalSettings,
  AreaTableModal,
} from '../../../../types/admin/AdminArea'
import DownloadCSVButton from '../../../../components/Button/DownloadCSVButton'
import { formatZonesExport } from '../../../../utils/gql/zonesExport'
import usePromiseLazyQuery from '../../../../hooks/usePromiseLazyQuery'
import {
  AdminTableRefetchContext,
  AdminTableTypes,
} from '../../AdminTableRefetchContext'
import {
  AreaCountFilteredQuery,
  AreaCountFilteredQueryVariables,
  AreaSearch,
  GetAreaSearchQuery,
  GetAreaSearchQueryVariables,
} from '../../../../__generated__/graphql'

const initalModalSettings: AreaModalSettings = {
  area: null,
  actionName: '',
}

const AnimatorBikeTableList: React.FC<{ pageSize: number }> = ({
  pageSize,
}) => {
  const { t } = useTranslation()
  const [currModal, setCurrModal] =
    useState<AreaModalSettings>(initalModalSettings)
  const [currPage, setCurrPage] = useState(1)

  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)

  const { data: countData, refetch: refetchBikesCount } = useQuery<
    AreaCountFilteredQuery,
    AreaCountFilteredQueryVariables
  >(QUERY_AREA_COUNT)

  const {
    data: bikesData,
    loading,
    refetch: refetchBikesData,
  } = useQuery<GetAreaSearchQuery, GetAreaSearchQueryVariables>(
    QUERY_AREA_SEARCH,
    {
      fetchPolicy: 'network-only',
      variables: {
        skip: (currPage - 1) * pageSize,
        orderBy:
          orderBy.sort && orderBy.columnName
            ? { [orderBy.columnName]: orderBy.sort }
            : undefined,
        take: pageSize,
      },
    },
  )

  const getAllArea = usePromiseLazyQuery<
    GetAreaSearchQuery,
    GetAreaSearchQueryVariables
  >(QUERY_AREA_SEARCH)

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<AreaSearch> }) => (
      <AreaActionCell setModal={setCurrModal} row={row} />
    ),
    [],
  )
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('admin.area.table.headers.name') as string,
        accessor: 'name',
        sortField: 'name',
      },
      {
        Header: t('admin.area.table.headers.territoryCount') as string,
        accessor: 'territoryCount',
        sortField: 'territoryCount',
      },
      {
        Header: t('admin.area.table.headers.companyStep1Count') as string,
        accessor: 'companyStep1Count',
        sortField: 'companyStep1Count',
      },
      {
        Header: t('admin.area.table.headers.companyStep2Count') as string,
        accessor: 'companyStep2Count',
        sortField: 'companyStep2Count',
      },
      {
        Header: t('admin.area.table.headers.companyStep3Count') as string,
        accessor: 'companyStep3Count',
        sortField: 'companyStep3Count',
      },
      {
        Header: t('admin.area.table.headers.companyStep4Count') as string,
        accessor: 'companyStep4Count',
        sortField: 'companyStep4Count',
      },
      {
        Header: t('admin.area.table.headers.companyStep5Count') as string,
        accessor: 'companyStep5Count',
        sortField: 'companyStep5Count',
      },
      {
        Header: t('admin.area.table.headers.employeeSelectedCount') as string,
        accessor: 'employeeTotalCount',
        sortField: 'employeeTotalCount',
      },
      {
        Header: t('admin.area.table.headers.employeeBikeCount') as string,
        accessor: 'employeeBikeCount',
        sortField: 'employeeBikeCount',
      },
      {
        Header: t('admin.area.table.headers.bikeCount') as string,
        accessor: 'bikeCount',
        sortField: 'bikeCount',
      },
      {
        Header: t('admin.animator.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [t, ActionCell],
  )

  const formattedData = useMemo(() => {
    if (!bikesData?.areaSearch?.length) return []

    return bikesData.areaSearch.map(animator => {
      return {
        ...animator,
      }
    })
  }, [bikesData])

  const handleEditModalClose = (shouldRefetch = false) => {
    setCurrModal(initalModalSettings)
    if (shouldRefetch) {
      refetchBikesCount()
      refetchBikesData()
    }
  }

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `zones-${formatedDate}`

  const getExportData = async () => {
    const { data } = await getAllArea({})
    return formatZonesExport(data)
  }

  const {
    refetchTables,
    removeTableToFetch,
    tableRefetchPlanner: { [AdminTableTypes.AREAS]: needToRefetch },
  } = React.useContext(AdminTableRefetchContext)

  React.useEffect(() => {
    if (needToRefetch) {
      refetchBikesData()
      removeTableToFetch(AdminTableTypes.AREAS)
    }
  }, [needToRefetch, refetchBikesData, removeTableToFetch])

  const handleRefetch = () => {
    refetchBikesData()
    refetchTables([AdminTableTypes.TERRITORY])
  }

  return (
    <React.Fragment>
      <Table
        columns={columns}
        headerCategories={[
          {
            name: t('admin.area.table.headers.name') as string,
            colspan: 2,
            hidden: true,
          },
          {
            name: t('admin.area.table.headers.company') as string,
            colspan: 5,
          },
          {
            name: t('admin.area.table.headers.employee') as string,
            colspan: 2,
          },
        ]}
        data={formattedData}
        onSortBy={setOrderBy}
        loading={loading}
      />
      <div
        css={{
          marginTop: '41px',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={countData?.areaCount}
        />
        <div
          css={{
            display: 'flex',
            justifySelf: 'flex-end',
            position: 'absolute',
            right: 0,
          }}
        >
          <Button
            submit
            onClick={() =>
              setCurrModal({ area: null, actionName: AreaTableModal.ADD })
            }
          >
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontSize: '1.6rem',
                fontWeight: 500,
                color: theme.colors.white,
              }}
            >
              <Icon
                strokeColor="white"
                type="plus"
                customSize={{ width: '16px', height: '16px' }}
              />
            </span>
          </Button>
          <DownloadCSVButton getData={getExportData} filename={filename} />
        </div>
      </div>
      <AreaEditModal
        area={currModal.area}
        modalIsOpen={currModal.actionName === AreaTableModal.EDIT}
        closeModal={handleEditModalClose}
        refetch={handleRefetch}
      />
      <AreaDeleteModal
        area={currModal.area}
        modalIsOpen={currModal.actionName === AreaTableModal.DELETE}
        closeModal={handleEditModalClose}
        refetch={handleRefetch}
      />
      <AreaAddModal
        modalIsOpen={currModal.actionName === AreaTableModal.ADD}
        closeModal={handleEditModalClose}
        refetch={handleRefetch}
      />
    </React.Fragment>
  )
}

export default AnimatorBikeTableList
