/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Helmet } from 'react-helmet'
// import { gql, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce'
import { useParams } from 'react-router-dom'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'

import Card from '../../../../components/Card'
import InputSelect from '../../../../components/InputSelect'
import { SelectVariant } from '../../../../hooks/useVariant/variants/select'
import Input from '../../../../components/Input'
import { TextVariant } from '../../../../hooks/useVariant/variants/text'
import List from './List'
import {
  CompanyMonitoringStatusType,
  CompanyMonitoringType,
} from '@goodwatt/shared/dist/types'
let debouncedFn: undefined | (() => void)
const PAGESIZE = 30

const CompanyMonitoring: React.FC = () => {
  const theme = useTheme()
  const [filters, setFilters] = React.useState({})
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const onSelectChange = React.useCallback(({ value }, { name }) => {
    setFilters(prevState => ({
      ...prevState,
      [name]: value !== 'all' ? value : undefined,
    }))
  }, [])

  const onInputNameChange = React.useCallback(e => {
    /* signal to React not to nullify the event object */
    e.persist()

    if (!debouncedFn) {
      debouncedFn = debounce(() => {
        setFilters(prevState => ({
          ...prevState,
          multiFilter: e.target.value || undefined,
        }))
      }, 300)
    }
    debouncedFn()
  }, [])

  React.useEffect(() => (debouncedFn = undefined), [])

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goodwatt Animateur - Suivi</title>
        <link
          rel="canonical"
          href={`https://goodwatt.fr/animator/company/${id}/monitoring`}
        />
      </Helmet>
      <Card
        title={
          <span
            css={{
              fontFamily: theme.fontFamily,
              fontWeight: 'bold',
              fontSize: '1.6rem',
            }}
          >
            {t('animator.company.monitoring.title')}
          </span>
        }
      >
        {/* Inputs */}
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            marginTop: '2.1rem',
            marginBottom: '2.1rem',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <div
            css={{
              display: 'flex',
              flex: 1,
              minWidth: '220px',
              paddingRight: '17px',
              width: '100%',
              [theme.media.mobile]: {
                paddingRight: '0px',
              },
            }}
          >
            <InputSelect
              name="companyMonitoringType"
              variant={SelectVariant.white}
              withError={false}
              label="Type"
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: 'Tous',
                },
                {
                  value: CompanyMonitoringType.AUTO + '',
                  label: 'Auto',
                },
                {
                  value: CompanyMonitoringType.MANUAL + '',
                  label: 'Manuel',
                },
              ]}
              onChange={onSelectChange}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flex: 1,
              minWidth: '220px',
              paddingRight: '17px',
              width: '100%',
              [theme.media.mobile]: {
                paddingRight: '0px',
                marginTop: '14px',
              },
            }}
          >
            <InputSelect
              name="companyMonitoringStatus"
              variant={SelectVariant.white}
              withError={false}
              label="Statut"
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t(
                    'animator.company.monitoring.filter.companyMonitoringStatus.options.all',
                  ),
                },
                {
                  value: CompanyMonitoringStatusType.NOT_STARTED,
                  label: t(
                    `animator.company.monitoring.filter.companyMonitoringStatus.options.${CompanyMonitoringStatusType.NOT_STARTED}`,
                  ),
                },
                {
                  value: CompanyMonitoringStatusType.IN_PROGRESS,
                  label: t(
                    `animator.company.monitoring.filter.companyMonitoringStatus.options.${CompanyMonitoringStatusType.IN_PROGRESS}`,
                  ),
                },
                {
                  value: CompanyMonitoringStatusType.COMPLETED,
                  label: t(
                    `animator.company.monitoring.filter.companyMonitoringStatus.options.${CompanyMonitoringStatusType.COMPLETED}`,
                  ),
                },
              ]}
              onChange={onSelectChange}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flex: 2,
              minWidth: '220px',
              [theme.media.tablet]: {
                marginTop: '14px',
              },
            }}
          >
            <Input
              icon="magnifier"
              name="search"
              withError={false}
              variant={TextVariant.white}
              defaultValue=""
              onChange={onInputNameChange}
            />
          </div>
        </div>
        <List companyId={id} filters={filters} pageSize={PAGESIZE} />
      </Card>
    </div>
  )
}

export default CompanyMonitoring
