/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import { useSelector } from 'react-redux'

import CardFoldable from '@goodwatt/client/src/components/Card/Foldable'
import InputSelect from '@goodwatt/client/src/components/InputSelect'
import Input from '@goodwatt/client/src/components/Input'
import CardTitle from '../../../components/Card/CardTitle'
import ListBike from './List'
import ListFleet from '../Fleets/List'
import BikeModelList from './Models'

import { TextVariant } from '@goodwatt/client/src/hooks/useVariant/variants/text'
import { SelectVariant } from '@goodwatt/client/src/hooks/useVariant/variants/select'
import {
  QUERY_ALL_FLEET_NAMES,
  QUERY_ALL_BIKE_MODEL_NAMES,
  QUERY_AREAS,
} from '@goodwatt/client/src/utils/gql/queries'
import { StoreState } from '../../../redux/rootReducer'
import { useTheme } from 'emotion-theming'
import { UserRoles } from '@goodwatt/shared/dist/types'
import { FleetStockLevel } from '../../../components/Table/CustomCells/FleetStockConditionCell'
import {
  GetAllBikeModelNamesQuery,
  GetAllBikeModelNamesQueryVariables,
  GetAllFleetNamesQuery,
  GetAllFleetNamesQueryVariables,
  GetAreasQuery,
  GetAreasQueryVariables,
} from '../../../__generated__/graphql'
import HeaderSummary from '../../../components/Dasboard/HeaderSummary'

const PAGESIZE = 10
let bikeDebouncedFn: undefined | (() => void)
let fleetDebouncedFn: undefined | (() => void)

const CompanyBike: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [bikeFilters, setBikeFilters] = React.useState({})
  const [fleetFilters, setFleetFilters] = React.useState({})
  const { id: userId, role: userRole } = useSelector(
    (state: StoreState) => state.user,
  )
  const isAdmin = userRole === UserRoles.ADMIN

  const { data, refetch: refetchFleetNames } = useQuery<
    GetAllFleetNamesQuery,
    GetAllFleetNamesQueryVariables
  >(QUERY_ALL_FLEET_NAMES, {
    variables: {
      where: isAdmin
        ? {}
        : {
            area: {
              animator: { some: { userId: { equals: userId } } },
            },
          },
    },
  })

  const { data: bikeModelNames } = useQuery<
    GetAllBikeModelNamesQuery,
    GetAllBikeModelNamesQueryVariables
  >(QUERY_ALL_BIKE_MODEL_NAMES)

  const { data: areasData } = useQuery<GetAreasQuery, GetAreasQueryVariables>(
    QUERY_AREAS,
  )

  const onBikeSelectChange = React.useCallback(({ value }, { name }) => {
    setBikeFilters(prevState => ({
      ...prevState,
      [name]: value !== 'all' ? value : undefined,
    }))
  }, [])

  const onFleetSelectChange = React.useCallback(({ value }, { name }) => {
    setFleetFilters(prevState => ({
      ...prevState,
      [name]: value !== 'all' ? value : undefined,
    }))
  }, [])

  const onBikeInputNameChange = React.useCallback(e => {
    /* signal to React not to nullify the event object */
    e.persist()

    if (!bikeDebouncedFn) {
      bikeDebouncedFn = debounce(() => {
        setBikeFilters(prevState => ({
          ...prevState,
          multiFilter: e.target.value || undefined,
        }))
      }, 300)
    }
    bikeDebouncedFn()
  }, [])

  const onFleetInputNameChange = React.useCallback(e => {
    e.persist()

    if (!fleetDebouncedFn) {
      fleetDebouncedFn = debounce(() => {
        setFleetFilters(prevState => ({
          ...prevState,
          multiFilter: e.target.value || undefined,
        }))
      }, 300)
    }
    fleetDebouncedFn()
  }, [])

  React.useEffect(() => {
    bikeDebouncedFn = undefined
    fleetDebouncedFn = undefined
  }, [])

  const fleetFilterOptions = React.useMemo(
    () =>
      data?.fleets
        ? data?.fleets.map(({ name }: { name: string }) => ({
            value: name,
            label: name,
          }))
        : [],
    [data],
  )

  const modelFilterOptions = React.useMemo(
    () =>
      bikeModelNames?.bikeModels
        ? bikeModelNames?.bikeModels.map(({ name }: { name: string }) => ({
            value: name,
            label: name,
          }))
        : [],
    [bikeModelNames],
  )

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goodwatt {isAdmin ? 'Admin' : 'Animateur'} - Mes velos</title>
        <link rel="canonical" href="https://goodwatt.fr/animator/bikes" />
      </Helmet>

      <HeaderSummary />

      <div css={{ marginBottom: '30px' }}>
        <CardFoldable
          title={<CardTitle label={t('animator.bikes.dashboardTitleLabel')} />}
          isDefaultFolded={false}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              marginTop: '2.1rem',
              marginBottom: '2.1rem',
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            <div
              css={{
                display: 'flex',
                flex: 1,
                minWidth: '220px',
                paddingRight: '17px',
                width: '100%',
                [theme.media.mobile]: {
                  paddingRight: '0px',
                },
              }}
            >
              <InputSelect
                name="model"
                withError={false}
                variant={SelectVariant.white}
                label={t('animator.companies.filter.models')}
                defaultValue="all"
                options={[
                  {
                    value: 'all',
                    label: t('animator.companies.filter.options.all'),
                  },
                  ...modelFilterOptions,
                ]}
                onChange={onBikeSelectChange}
              />
            </div>
            <div
              css={{
                display: 'flex',
                flex: 1,
                minWidth: '220px',
                paddingRight: '17px',
                width: '100%',
                [theme.media.mobile]: {
                  paddingRight: '0px',
                },
              }}
            >
              <InputSelect
                name="fleet"
                withError={false}
                variant={SelectVariant.white}
                label={t('animator.companies.filter.fleet')}
                defaultValue="all"
                options={[
                  {
                    value: 'all',
                    label: t('animator.companies.filter.options.all_feminine'),
                  },
                  ...fleetFilterOptions,
                ]}
                onChange={onBikeSelectChange}
              />
            </div>
            <div
              css={{
                display: 'flex',
                flex: 1,
                minWidth: '220px',
                paddingRight: '17px',
                width: '100%',
                [theme.media.tablet]: {
                  paddingRight: '0px',
                  marginTop: '14px',
                },
              }}
            >
              <InputSelect
                name="bikeCondition"
                withError={false}
                variant={SelectVariant.white}
                label={t('animator.bikes.filter.bikeConditions.label')}
                defaultValue="all"
                options={[
                  {
                    value: 'all',
                    label: t(
                      'animator.bikes.filter.bikeConditions.options.all',
                    ),
                  },
                  {
                    value: 'onLoan',
                    label: t(
                      'animator.bikes.filter.bikeConditions.options.onLoan',
                    ),
                  },
                  {
                    value: 'reparation',
                    label: t(
                      'animator.bikes.filter.bikeConditions.options.reparation',
                    ),
                  },
                  {
                    value: 'stock',
                    label: t(
                      'animator.bikes.filter.bikeConditions.options.stock',
                    ),
                  },
                  {
                    value: 'outOfService',
                    label: t(
                      'animator.bikes.filter.bikeConditions.options.outOfService',
                    ),
                  },
                  {
                    value: 'stolen',
                    label: t(
                      'animator.bikes.filter.bikeConditions.options.stolen',
                    ),
                  },
                  {
                    value: 'sold',
                    label: t(
                      'animator.bikes.filter.bikeConditions.options.sold',
                    ),
                  },
                ]}
                onChange={onBikeSelectChange}
              />
            </div>
            <div
              css={{
                display: 'flex',
                flex: 1,
                minWidth: '220px',
                maxWidth: '275px',
                marginTop: '14px',
              }}
            >
              <Input
                icon="magnifier"
                name="name"
                variant={TextVariant.white}
                withError={false}
                onChange={onBikeInputNameChange}
              />
            </div>
          </div>
          <ListBike filters={bikeFilters} pageSize={PAGESIZE} />
        </CardFoldable>
      </div>

      {isAdmin && (
        <div css={{ marginBottom: '30px' }}>
          <CardFoldable
            title={
              <CardTitle label={t('animator.bikes.bikeModelsTitleLabel')} />
            }
            isDefaultFolded={false}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginTop: '2.1rem',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              <BikeModelList />
            </div>
          </CardFoldable>
        </div>
      )}

      <CardFoldable
        title={<CardTitle label={t('animator.fleets.dashboardTitleLabel')} />}
        isDefaultFolded={false}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            marginTop: '2.1rem',
            marginBottom: '2.1rem',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <div
            css={{
              display: 'flex',
              flex: 1,
              minWidth: '220px',
              paddingRight: '17px',
              width: '100%',
              [theme.media.mobile]: {
                paddingRight: '0px',
              },
            }}
          >
            <InputSelect
              name="stockLevel"
              withError={false}
              variant={SelectVariant.white}
              label={t('animator.fleets.filter.stock')}
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t(
                    'animator.fleets.filter.fleetConditions.stockLevel.all',
                  ),
                },
                {
                  value: FleetStockLevel.LOW,
                  label: t(
                    `animator.fleets.filter.fleetConditions.stockLevel.${FleetStockLevel.LOW}`,
                  ),
                },
                {
                  value: FleetStockLevel.MEDIUM,
                  label: t(
                    `animator.fleets.filter.fleetConditions.stockLevel.${FleetStockLevel.MEDIUM}`,
                  ),
                },
                {
                  value: FleetStockLevel.IMPORTANT,
                  label: t(
                    `animator.fleets.filter.fleetConditions.stockLevel.${FleetStockLevel.IMPORTANT}`,
                  ),
                },
              ]}
              onChange={onFleetSelectChange}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flex: 1,
              minWidth: '220px',
              paddingRight: '17px',
              width: '100%',
              [theme.media.tablet]: {
                paddingRight: '0px',
                marginTop: '14px',
              },
            }}
          >
            <InputSelect
              name="areaId"
              withError={false}
              variant={SelectVariant.white}
              label={t('animator.fleets.table.headers.area')}
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t('animator.companies.filter.options.all_feminine'),
                },
                ...(areasData?.areas || []).map(area => ({
                  value: area.id.toString(),
                  label: area.name,
                })),
              ]}
              onChange={onFleetSelectChange}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flex: 2,
              minWidth: '220px',
              [theme.media.tablet]: {
                marginTop: '14px',
              },
            }}
          >
            <Input
              icon="magnifier"
              name="name"
              variant={TextVariant.white}
              withError={false}
              onChange={onFleetInputNameChange}
            />
          </div>
        </div>
        <ListFleet
          filters={fleetFilters}
          pageSize={PAGESIZE}
          onFleetsChange={() => refetchFleetNames()}
        />
      </CardFoldable>
    </div>
  )
}

export default CompanyBike
