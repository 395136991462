import i18n from '../../i18n/config'
import {
  Area,
  Company,
  Deployment,
  Fleet,
  ProgramStep,
  Site,
} from '../../__generated__/graphql'
import { format } from 'date-fns'

export const formatDeploymentExport = (
  deploymentExportData: (Pick<
    Deployment,
    | 'id'
    | 'name'
    | 'deploymentType'
    | 'candidateCount'
    | 'beneficiaryCount'
    | 'beneficiaryWomenCount'
    | 'beneficiaryMenCount'
    | 'deploymentStartDate'
    | 'deploymentEndDate'
    | 'employeesTotalDistanceInKm'
    | 'employeesTotalMoneySaved'
    | 'employeesTotalEnergySpent'
    | 'employeesTotalKgCO2Saved'
    | 'hasSpecialBike'
    | 'averageEmployeeAge'
    | 'averageEmployeeWorkTravelDistance'
    | 'employeeWithAppCount'
  > & {
    site: Pick<Site, 'name' | 'street' | 'postalCode' | 'city'> & {
      company: Pick<Company, 'name'>
    }
  } & {
    fleet: Pick<Fleet, 'name'> & {
      area: Pick<Area, 'name'>
    }
  } & {
    programStep: Pick<ProgramStep, 'name'>
  })[],
): unknown[][] => {
  const header = [
    'ID',
    'Nom du déploiement',
    'Type de déploiement',
    "Nom de l'organisation",
    'Site',
    'Adresse',
    'Code postal',
    'Ville',
    'Zone',
    'Flotte de vélos',
    'Vélos spéciaux',
    'Phase actuelle',
    "Nombre d'usagers candidats",
    "Nombre d'usagers bénéficiaires",
    'Nombre de femmes bénéficiaires',
    "Nombre d'hommes bénéficiaires",
    'Âge moyen bénéficiaires',
    'Distance DT moyenne bénéficiaires',
    'Date de début',
    'Date de fin',
    'Distance totale parcourue',
    'Economie pour les salariés (€)',
    'Effort physique (kCal)',
    'Emissions (kgCO2)',
    "Nombre de téléchargement de l'app",
  ]

  return [
    header,
    ...deploymentExportData.map(deployment => [
      deployment.id,
      deployment.name,
      i18n.t(`shared.deploymentTypes.${deployment?.deploymentType}`),
      deployment.site?.company?.name,
      deployment.site?.name,
      deployment.site?.street,
      deployment.site?.postalCode,
      deployment.site?.city,
      deployment.fleet?.area?.name,
      deployment.fleet?.name,
      deployment.hasSpecialBike ? 'Oui' : 'Non',
      i18n.t(`shared.programStepLabel.${deployment.programStep?.name}`),
      deployment.candidateCount,
      deployment.beneficiaryCount,
      deployment.beneficiaryWomenCount,
      deployment.beneficiaryMenCount,
      deployment.averageEmployeeAge?.toString(),
      deployment.averageEmployeeWorkTravelDistance?.toString(),
      format(new Date(deployment.deploymentStartDate), 'dd/MM/yyyy à HH:mm'),
      format(new Date(deployment.deploymentEndDate), 'dd/MM/yyyy à HH:mm'),
      `${deployment.employeesTotalDistanceInKm} km`,
      `${deployment.employeesTotalMoneySaved} €`,
      `${deployment.employeesTotalEnergySpent} kCal`,
      `${deployment.employeesTotalKgCO2Saved} kgCO2`,
      deployment.employeeWithAppCount,
    ]),
  ]
}
