/** @jsx jsx */
import { useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Input from '../../components/Input'
import Button from '../../components/Button'
import InputSelect from '../../components/InputSelect'
import { StoreState } from '../../redux/rootReducer'
import { setEmployeeSignUpForm } from '../../redux/forms'
import { useTheme } from 'emotion-theming'

import {
  QUERY_LIST_COMPANY_SITES_BY_SUBSCRIPTION_CODE,
  QUERY_GET_SITE_ADDRESS,
} from '../../utils/gql/queries'
import {
  ListCompanySitesBySubscriptionCodeQuery,
  ListCompanySitesBySubscriptionCodeQueryVariables,
  GetSiteAddressQuery,
  GetSiteAddressQueryVariables,
} from '../../__generated__/graphql'

const schema = yup.object().shape({
  siteId: yup.object().required(),
})

type StepSiteEmployeeInput = {
  siteId: { value: string; label: string }
}

interface StepSiteProps {
  onNext: () => void
  onPrevious: () => void
}

const StepSite: React.FC<StepSiteProps> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const form = useSelector(
    (state: StoreState) => state.forms.employeeSignUpForm,
  )

  const { handleSubmit, errors, control } = useForm<StepSiteEmployeeInput>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const { data: sites } = useQuery<
    ListCompanySitesBySubscriptionCodeQuery,
    ListCompanySitesBySubscriptionCodeQueryVariables
  >(QUERY_LIST_COMPANY_SITES_BY_SUBSCRIPTION_CODE, {
    variables: {
      subscriptionCode: parseInt(form.subscriptionCode),
    },
  })

  const { refetch } = useQuery<
    GetSiteAddressQuery,
    GetSiteAddressQueryVariables
  >(QUERY_GET_SITE_ADDRESS)

  const onChangeSelect = async (data: any) => {
    const result = await refetch({
      id: data.value,
    })

    if (result.data?.site) {
      dispatch(
        setEmployeeSignUpForm({
          siteId: data.value,
          siteStreet: result.data.site.street,
          sitePostalCode: result.data.site.postalCode,
          siteCity: result.data.site.city,
        }),
      )
    }
  }

  const onSubmit = (data: StepSiteEmployeeInput) => {
    // To persist form if the user reload the page
    dispatch(
      setEmployeeSignUpForm({
        siteId: data.siteId.value,
      }),
    )

    if (data.siteId.value) onNext()
  }

  return (
    <div css={{ width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <Controller
              name="siteId"
              control={control}
              defaultValue={form.siteId}
              render={({ onChange, onBlur, value, name }) => (
                <InputSelect
                  placeholder={t('forms.label.selectSite')}
                  name={name}
                  label={t('forms.label.siteName')}
                  onBlur={onBlur}
                  value={value}
                  onChange={(...props) => {
                    onChangeSelect(props[0])
                    onChange(...props)
                  }}
                  options={
                    sites?.sitesBySubscriptionCode?.map(elem => ({
                      value: elem?.id,
                      label: elem?.name || '',
                    })) || []
                  }
                  error={!!errors.siteId}
                  helperText={
                    errors.siteId ? t('forms.errors.chooseAnOption') : ''
                  }
                />
              )}
            />

            <Input
              type="text"
              name="street"
              label={t('forms.label.companyAddress')}
              value={form.siteStreet}
              readOnly
            />
          </div>

          <div css={{ display: 'flex', flexDirection: 'row' }}>
            <div css={{ flex: 2, marginRight: 15 }}>
              <Input
                type="text"
                name="postalCode"
                label={t('forms.label.postalCode')}
                value={form.sitePostalCode}
                readOnly
              />
            </div>
            <div css={{ flex: 3 }}>
              <Input
                type="text"
                name="city"
                label={t('forms.label.city')}
                value={form.siteCity}
                readOnly
              />
            </div>
          </div>

          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <div css={{ marginRight: 40, marginLeft: '-84px' /* -40 + -44 */ }}>
              <Button
                type="tertiary"
                onClick={onPrevious}
                icon="back"
                iconColor={theme.colors.primary}
              />
            </div>
            <Button type="primary" submit>
              {t('forms.button.next')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default StepSite
