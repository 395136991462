/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

interface DetailsCellProps {
  stateOfUse?: string
  comment?: string
}

const DetailsCell: React.FC<DetailsCellProps> = ({ stateOfUse, comment }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        padding: '14px',
      }}
    >
      <p>
        <span css={{ color: theme.colors.gray3 }}>
          {t('animator.company.employees.selected.modals.deposit.stateOfUse')}
        </span>
        <span>{stateOfUse || ''}</span>
      </p>
      <p>
        <span css={{ color: theme.colors.gray3 }}>
          {t('animator.company.employees.selected.modals.deposit.comment')}
        </span>
        <span>{comment || ''}</span>
      </p>
    </div>
  )
}

export interface TableCellsProps {
  data: {
    element: string
    before: {
      stateOfUse?: string
      comment?: string
    }
    after: {
      stateOfUse?: string
      comment?: string
    }
    estimatedAmount: number
  }[]
}

const TableCells: React.FC<TableCellsProps> = ({ data }) => {
  const theme = useTheme()

  const tableCellStyles = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.gray6,
    borderTop: `2px solid ${theme.colors.white}`,
  }

  return (
    <Fragment>
      {data.map((item, index) => (
        <div
          key={item.element}
          css={{
            display: 'flex',
            width: '100%',
            minHeight: '50px',
          }}
        >
          {/* 1st column */}
          <div
            css={{
              flex: 1,
              borderRight: `2px solid ${theme.colors.white}`,
              ...(index === data.length - 1
                ? { borderBottomLeftRadius: '22px' }
                : {}),
              ...tableCellStyles,
            }}
          >
            <span css={{ marginLeft: '14px' }}>{item.element}</span>
          </div>
          {/* 2nd column */}
          <div
            css={{
              flex: 2,
              borderRight: `2px solid ${theme.colors.white}`,
              ...tableCellStyles,
            }}
          >
            <DetailsCell
              stateOfUse={item.before.stateOfUse}
              comment={item.before.comment}
            />
          </div>
          {/* 3rd column */}
          <div
            css={{
              flex: 2,
              borderRight: `2px solid ${theme.colors.white}`,
              ...tableCellStyles,
            }}
          >
            <DetailsCell
              stateOfUse={item.after.stateOfUse}
              comment={item.after.comment}
            />
          </div>
          {/* 4th column */}
          <div
            css={{
              flex: 1,
              ...tableCellStyles,
              ...(index === data.length - 1
                ? { borderBottomRightRadius: '22px' }
                : {}),
            }}
          >
            <span css={{ marginLeft: '14px' }}>
              {item.estimatedAmount || 0}€
            </span>
          </div>
        </div>
      ))}
    </Fragment>
  )
}

export default TableCells
