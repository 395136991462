/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'

import Icon from '../../Icon'
import { NavItemIdle } from '../../../hooks/useMenu'
import { useTheme } from 'emotion-theming'
import { AnimatePresence, motion } from 'framer-motion'
import ConditionalSubNavLink from './ConditionalSubNavLink'

interface ConditionalNavItemProps extends NavItemIdle {
  moveNavIndictator: (offsetTop: number) => void
  collapsible: boolean
  isActive: boolean
  closeMenu?: () => void
}

const ConditionalNavItem: React.FC<ConditionalNavItemProps> = ({
  icon,
  subNav,
  moveNavIndictator,
  collapsible,
  title,
  idleHidden,
  isActive,
  closeMenu,
}) => {
  const theme = useTheme()
  const currentConditionalEl = React.useRef<HTMLDivElement>(null)

  const [showList, setShowList] = React.useState<boolean>(
    !collapsible || isActive,
  )

  React.useEffect(() => {
    if (!isActive && collapsible) {
      setShowList(false)
    }

    // wait for the end of the animation to calculate the new position
    setTimeout(_ => {
      if (isActive && currentConditionalEl && currentConditionalEl.current) {
        moveNavIndictator(currentConditionalEl.current.offsetTop)
      }
    }, 300)
  }, [moveNavIndictator, isActive, collapsible])

  const showMenu = !idleHidden || isActive

  const handleMenuClick = () => {
    if (!collapsible) return
    setShowList(!showList)
  }

  const subNavList = (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ul css={{ paddingLeft: 75, marginTop: 10 }}>
        {subNav.map(
          (
            { title, lastPathUrl, overideUrl, titleRedirectUrl, groupTitle },
            index,
          ) => (
            <ConditionalSubNavLink
              title={title}
              titleRedirectUrl={titleRedirectUrl}
              lastPathUrl={lastPathUrl}
              closeMenu={closeMenu}
              overideUrl={overideUrl}
              groupTitle={groupTitle}
              key={`${lastPathUrl}-${index}`}
            />
          ),
        )}
      </ul>
    </motion.div>
  )

  return (
    <AnimatePresence>
      {showMenu && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <li
            css={{
              fontFamily: theme.fontFamily,
              textTransform: 'uppercase',
              fontStyle: 'normal',
              fontSize: '1.6rem',
              lineHeight: '32px',
              [theme.media.tablet]: {
                fontSize: '2rem',
              },
            }}
          >
            <div
              onClick={handleMenuClick}
              ref={currentConditionalEl}
              css={{
                cursor: 'pointer',
                userSelect: 'none',
                position: 'relative',
                display: 'flex',
                padding: '5px 10px 5px 0',
                color: theme.colors.gray2,
              }}
            >
              <div
                css={{
                  margin: '0 16px 0 10px',
                  minWidth: 48,
                  height: 48,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon
                  type={icon}
                  customSize={{ width: '35px', height: '35px' }}
                  color={theme.colors.gray2}
                />
              </div>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: '20px',
                  overflow: 'hidden',
                }}
              >
                <span
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {title}
                </span>
              </div>
              {collapsible && (
                <div
                  css={{
                    minWidth: 48,
                    height: 48,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    transition: '0.3s ease all',
                    transform: showList ? 'scaleY(-100%)' : 'scaleY(100%)',
                  }}
                >
                  <Icon
                    type="fullArrowDown"
                    customSize={{ width: '12px', height: '12px' }}
                    color={theme.colors.gray2}
                  />
                </div>
              )}
            </div>
            {showList && subNavList}
          </li>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ConditionalNavItem
