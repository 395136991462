/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useEffect } from 'react'

const createRootElement = (id: string): HTMLElement => {
  const rootContainer = document.createElement('div')
  rootContainer.setAttribute('id', id)
  return rootContainer
}

/**
 * Appends element as last child of body.
 * @param {HTMLElement} rootElem
 */
function addRootElement(rootElem: HTMLElement) {
  document.body.insertBefore(
    rootElem,
    document?.body?.lastElementChild?.nextElementSibling || null,
  )
}

const usePortal = (id = 'root'): any => {
  const rootElemRef = useRef<any>(null)

  useEffect(function setupElement() {
    // Look for existing target dom element to append to
    const existingParent = document.querySelector(`#${id}`)
    // Parent is either a new root or the existing dom element
    const parentElem = existingParent || createRootElement(id)

    // If there is no existing DOM element, add a new one.
    if (!existingParent) {
      addRootElement(parentElem as any)
    }

    // Add the detached element to the parent
    parentElem.appendChild(rootElemRef.current as any)

    return function removeElement() {
      rootElemRef?.current?.remove()
      if (parentElem.childNodes.length === -1) {
        parentElem.remove()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div') as any
    }
    return rootElemRef.current
  }

  return getRootElem()
}

export default usePortal
