import React from 'react'
import { useQuery } from '@apollo/client'

import Icon from '../../../components/Icon'
import { useTheme } from 'emotion-theming'

import ProfilePreview from './ProfilePreview'
import EditCompanyModal, {
  AnchorEditCompanyProfileType,
} from './EditCompanyModal'
import EditCompanyParkingModal, {
  AnchorEditCompanyParkingType,
} from './EditCompanyParkingModal'
import { QUERY_GET_COMPANY } from '../../../utils/gql/queries'

import ConfirmEmailModal from './ConfirmEmailModal'
import {
  CompanyReferrer,
  GetCompanyProfileQuery,
  GetCompanyProfileQueryVariables,
} from '../../../__generated__/graphql'

type AnchorEditModalType<A> = {
  isOpen: boolean
  anchor: A | undefined
}

const initialModalVisibilityState = { isOpen: false, anchor: undefined }
interface CompanyProfileWithUserId {
  userId: string
  companyId?: never
  renderExtra?: (data: GetCompanyProfileQuery) => React.ReactNode
}

interface CompanyProfileWithCompanyIdProps {
  userId?: never
  companyId: string
  renderExtra?: (data: GetCompanyProfileQuery) => React.ReactNode
}

const CompanyProfile: React.FC<
  CompanyProfileWithCompanyIdProps | CompanyProfileWithUserId
> = ({ companyId, userId, renderExtra }) => {
  const theme = useTheme()
  const [profileModalVisibility, setProfileModalVisibility] = React.useState<
    AnchorEditModalType<AnchorEditCompanyProfileType>
  >(initialModalVisibilityState)
  const [parkingModalVisibility, setParkingModalVisibility] = React.useState<
    AnchorEditModalType<AnchorEditCompanyParkingType>
  >(initialModalVisibilityState)
  const [confirmEmailVisibility, setConfirmEmailVisibility] =
    React.useState<boolean>(false)

  const {
    data,
    loading: loadingQuery,
    refetch,
  } = useQuery<GetCompanyProfileQuery, GetCompanyProfileQueryVariables>(
    QUERY_GET_COMPANY,
    {
      variables: {
        where: {
          ...(companyId ? { id: companyId } : {}),
          ...(userId ? { userId: userId } : {}),
        },
      },
    },
  )

  if (loadingQuery && !data)
    return (
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Icon type="loader" size="large" strokeColor={theme.colors.primary} />
      </div>
    )

  const mainReferrer = data?.company?.companyReferrers?.find(
    referrer => referrer?.mainReferrer === true,
  )

  return (
    <>
      <ProfilePreview
        data={data as GetCompanyProfileQuery}
        renderExtra={renderExtra}
        openProfileModal={(anchor: AnchorEditCompanyProfileType) =>
          setProfileModalVisibility({ isOpen: true, anchor })
        }
        openParkingModal={(anchor: AnchorEditCompanyParkingType) =>
          setParkingModalVisibility({ isOpen: true, anchor })
        }
        openConfirmEmailModal={() => setConfirmEmailVisibility(true)}
      />
      <EditCompanyModal
        data={data as GetCompanyProfileQuery}
        modalIsOpen={profileModalVisibility.isOpen}
        anchorOnOpen={profileModalVisibility.anchor}
        closeModal={() =>
          setProfileModalVisibility(initialModalVisibilityState)
        }
        companyId={data?.company?.id as string}
        refetch={refetch}
      />
      <EditCompanyParkingModal
        data={data as GetCompanyProfileQuery}
        modalIsOpen={parkingModalVisibility.isOpen}
        anchorOnOpen={parkingModalVisibility.anchor}
        closeModal={() =>
          setParkingModalVisibility(initialModalVisibilityState)
        }
        companyId={data?.company?.id as string}
        refetch={refetch}
      />
      <ConfirmEmailModal
        referrer={mainReferrer as CompanyReferrer}
        closeModal={() => setConfirmEmailVisibility(false)}
        modalIsOpen={confirmEmailVisibility}
        refetch={refetch}
      />
    </>
  )
}

export default CompanyProfile
