/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { StoreState } from '../../redux/rootReducer'
import { setCompanySignUpForm } from '../../redux/forms'
import Input from '../../components/Input'
import Button from '../../components/Button'
import i18n from '../../i18n/config'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'emotion-theming'
import { CompanyType } from '../../__generated__/graphql'
import InputSelect from '../../components/InputSelect'

const schema = yup.object().shape({
  companyName: yup.string().required(i18n.t('forms.errors.required')),
  companyType: yup.object().required(),
})

type Step1CompanyInputs = {
  companyName: string
  companyType: {
    value: CompanyType
    label: string
  }
}

interface Step1Props {
  onNext: () => void
}

const Step1: React.FC<Step1Props> = ({ onNext }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { register, handleSubmit, errors, control } =
    useForm<Step1CompanyInputs>({
      resolver: yupResolver(schema),
      mode: 'onBlur',
    })

  const { companyName, companyType } = useSelector(
    (state: StoreState) => state.forms.companySignUpForm,
  )

  const onSubmit = (data: Step1CompanyInputs) => {
    // To persist form if the user reload the page
    dispatch(
      setCompanySignUpForm({
        companyName: data.companyName,
        companyType: data.companyType.value,
      }),
    )

    if (data.companyType.value) onNext()
  }

  return (
    <div>
      <div
        css={{
          marginBottom: '40px',
        }}
      >
        <p
          css={{
            textAlign: 'center',
            fontSize: '1.6rem',
            color: theme.colors.gray1,
          }}
          dangerouslySetInnerHTML={{
            __html: t('auth.signupCompany.description'),
          }}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="text"
          name="companyName"
          defaultValue={companyName}
          label={t('forms.label.companyName')}
          register={register}
          error={!!errors.companyName}
          helperText={errors.companyName?.message}
        />

        <Controller
          name="companyType"
          control={control}
          defaultValue={companyType}
          render={({ onChange, onBlur, value, name }) => (
            <InputSelect
              name={name}
              label={t('forms.label.companyType')}
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              options={
                [
                  CompanyType.Company,
                  CompanyType.Association,
                  CompanyType.Colectivity,
                  CompanyType.Healthcare,
                  CompanyType.PublicAdministration,
                  CompanyType.ClubCompany,
                  CompanyType.Other,
                ].map((value: string) => ({
                  value,
                  label: t(`shared.companyTypeLabel.${value}`),
                })) as any
              }
              error={!!errors.companyType}
              helperText={
                errors.companyType ? t('forms.errors.chooseAnOption') : ''
              }
            />
          )}
        />

        <div
          css={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <Button type="primary" submit>
            {t('forms.button.next')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Step1
