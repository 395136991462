/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, gql } from '@apollo/client'
import { Column, Row } from 'react-table'
import { format, isValid, parse } from 'date-fns'
import {
  CompanyMonitoringStatusType,
  CompanyMonitoringType,
} from '@goodwatt/shared/dist/types'
import Button from '../../../../components/Button'
import {
  CompanyMonitoringTableAdd,
  CompanyMonitoringTableModalActions,
  CompanyMonitoringSettings,
  CompanyMonitoringTableRow,
} from '../../../../types/CompanyMonitoring'
import Pagination from '../../../../components/Pagination'
import Table, { ColumnSortBy } from '../../../../components/Table'
import CompanyMonitoringActionCell from '../../../../components/Table/CustomCells/CompanyMonitoringActionCell'
import AddOrEditCompanyMonitoringModal from './AddOrEditCompanyMonitoringModal'
import DeleteCompanyMonitoringModal from './DeleteCompanyMonitoringModal'

import { useTheme } from 'emotion-theming'
import {
  AnimatorCompanyMonitoringCountFilteredQuery,
  AnimatorCompanyMonitoringCountFilteredQueryVariables,
  AnimatorGetCompanyMonitoringQuery,
  AnimatorGetCompanyMonitoringQueryVariables,
  SortOrder,
} from '../../../../__generated__/graphql'

const QUERY_ANIMATOR_COMPANY_MONITORING_COUNT_FILTERED = gql`
  query AnimatorCompanyMonitoringCountFiltered(
    $where: CompanyMonitoringWhereInput
  ) {
    companyMonitoringCount(where: $where)
  }
`
const QUERY_ANIMATOR_COMPANY_MONITORING_PAGINATED_FILTERED = gql`
  query AnimatorGetCompanyMonitoring(
    $offset: Int
    $where: CompanyMonitoringWhereInput
    $orderBy: [CompanyMonitoringOrderByWithRelationInput!]
    $pageSize: Int!
  ) {
    companyMonitorings(
      take: $pageSize
      skip: $offset
      where: $where
      orderBy: $orderBy
    ) {
      id
      date
      deadline
      note
      type
      createdAt
      companyMonitoringStatus {
        id
        name
      }
    }
  }
`
const initalModalSettings: CompanyMonitoringSettings = {
  companyMonitoring: {
    id: '',
    note: '',
    statusId: '',
    statusName: '',
    date: undefined,
    deadline: undefined,
  },
  actionName: '',
}

interface AnimatorCompanyMonitoringProps {
  filters: {
    [filterName: string]: string
  }
  pageSize: number
  companyId: string
}

const AnimatorCompanyMonitoringList: React.FC<
  AnimatorCompanyMonitoringProps
> = ({ filters, companyId, pageSize }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [currPage, setCurrPage] = useState(1)
  const [currModal, setCurrModal] =
    useState<CompanyMonitoringSettings>(initalModalSettings)
  const [orderBy, setOrderBy] = useState<ColumnSortBy>({
    columnName: 'createdAt',
    sort: SortOrder.Desc,
  })
  const whereClause = useMemo(() => {
    const { multiFilter } = filters,
      parsedDate = parse(multiFilter, 'dd/MM/yyyy', new Date())

    return {
      companyId: { equals: companyId },
      AND: [
        filters.companyMonitoringType
          ? {
              type: { equals: !!+filters.companyMonitoringType },
            }
          : {},
        filters.companyMonitoringStatus
          ? {
              companyMonitoringStatus: {
                name: { equals: filters.companyMonitoringStatus },
              },
            }
          : {},
        multiFilter
          ? {
              OR: [
                {
                  note: { contains: multiFilter },
                },
                {
                  companyMonitoringStatus: {
                    name: { contains: multiFilter },
                  },
                },
                ...(isValid(parsedDate)
                  ? [
                      {
                        date: {
                          equals: parsedDate,
                        },
                      },
                    ]
                  : []),
                ...(isValid(parsedDate)
                  ? [
                      {
                        deadline: {
                          equals: parsedDate,
                        },
                      },
                    ]
                  : []),
              ],
            }
          : {},
      ],
    }
  }, [filters, companyId])
  const {
    data: companyMonitoringsData,
    loading,
    refetch,
  } = useQuery<
    AnimatorGetCompanyMonitoringQuery,
    AnimatorGetCompanyMonitoringQueryVariables
  >(QUERY_ANIMATOR_COMPANY_MONITORING_PAGINATED_FILTERED, {
    fetchPolicy: 'network-only',
    variables: {
      where: whereClause,
      offset: (currPage - 1) * pageSize,
      orderBy:
        orderBy.sort && orderBy.columnName
          ? [{ [orderBy.columnName]: orderBy.sort }]
          : undefined,
      pageSize,
    },
  })
  const { data: companyMonitoringCountTotal, refetch: refetchTotal } = useQuery<
    AnimatorCompanyMonitoringCountFilteredQuery,
    AnimatorCompanyMonitoringCountFilteredQueryVariables
  >(QUERY_ANIMATOR_COMPANY_MONITORING_COUNT_FILTERED, {
    fetchPolicy: 'network-only',
    variables: {
      where: whereClause,
    },
  })
  const ActionCell = React.useCallback(
    ({ row }: { row: Row<CompanyMonitoringTableRow> }) =>
      typeof row.original?.type === 'boolean' &&
      +row.original.type === CompanyMonitoringType.MANUAL ? (
        <CompanyMonitoringActionCell setModal={setCurrModal} row={row} />
      ) : (
        ''
      ),
    [],
  )
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('animator.company.monitoring.table.headers.date') as string,
        accessor: 'date',
        Cell: ({ row }: { row: Row<CompanyMonitoringTableRow> }) =>
          row.original?.date
            ? format(new Date(row.original?.date), 'dd/MM/yyyy')
            : '',
      },
      {
        Header: t('animator.company.monitoring.table.headers.type') as string,
        accessor: 'type',
        Cell: ({ row }: { row: Row<CompanyMonitoringTableRow> }) =>
          typeof row.original?.type === 'boolean'
            ? t(
                `animator.company.monitoring.filter.companyMonitoringType.options.${+row
                  .original?.type}`,
              )
            : '',
      },
      {
        Header: t('animator.company.monitoring.table.headers.note') as string,
        accessor: 'note',
        maxWidth: 300,
      },
      {
        Header: t(
          'animator.company.monitoring.table.headers.deadline',
        ) as string,
        accessor: 'deadline',
        Cell: ({ row }: { row: Row<CompanyMonitoringTableRow> }) =>
          row.original?.deadline
            ? format(new Date(row.original?.deadline), 'dd/MM/yyyy')
            : '',
      },
      {
        Header: t(
          'animator.company.monitoring.table.headers.companyMonitoringStatus',
        ) as string,
        accessor: 'statusId',
        Cell: ({ row }: { row: Row<CompanyMonitoringTableRow> }) =>
          row.original?.statusName &&
          row.original?.statusName !== CompanyMonitoringStatusType.NONE
            ? t(
                `animator.company.monitoring.filter.companyMonitoringStatus.options.${row.original?.statusName}`,
              )
            : '',
        cellStyle: (): { textAlign: string } => ({ textAlign: 'center' }),
      },
      {
        Header: t(
          'animator.company.monitoring.table.headers.actions',
        ) as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [ActionCell, t],
  )
  const formattedData = useMemo(() => {
    if (!companyMonitoringsData?.companyMonitorings?.length) return []

    return companyMonitoringsData.companyMonitorings.map(companyMonitory => ({
      id: companyMonitory.id,
      note: companyMonitory.note,
      type: companyMonitory.type,
      date: companyMonitory.date,
      deadline: companyMonitory.deadline,
      statusId: companyMonitory.companyMonitoringStatus?.id,
      statusName: companyMonitory.companyMonitoringStatus?.name,
    }))
  }, [companyMonitoringsData])

  // Resetting to first page on filter change
  React.useEffect(() => {
    setCurrPage(1)
  }, [filters])

  return (
    <React.Fragment>
      <div css={{ minHeight: 380 }}>
        <Table
          columns={columns}
          data={formattedData}
          onSortBy={setOrderBy}
          defaultSortValue={orderBy}
          loading={loading}
        />
      </div>
      <div
        css={{
          position: 'relative',
          display: 'flex',
          marginTop: '41px',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            paddingLeft: 77,
            [theme.media.mobile]: {
              paddingLeft: 0,
            },
          }}
        >
          <Button
            type="primary"
            submit
            onClick={() =>
              setCurrModal({
                ...initalModalSettings,
                actionName: CompanyMonitoringTableAdd.ADD,
              })
            }
          >
            {t('forms.button.add')}
          </Button>
        </div>
        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={companyMonitoringCountTotal?.companyMonitoringCount}
        />
        <AddOrEditCompanyMonitoringModal
          companyMonitoring={currModal.companyMonitoring}
          isOpen={
            currModal.actionName === CompanyMonitoringTableAdd.ADD ||
            currModal.actionName === CompanyMonitoringTableModalActions.EDIT
          }
          type={currModal.actionName}
          companyId={companyId}
          onClose={() => setCurrModal(initalModalSettings)}
          updateList={() => refetchTotal().then(() => refetch())}
        />
        <DeleteCompanyMonitoringModal
          id={currModal.companyMonitoring.id}
          isOpen={
            currModal.actionName === CompanyMonitoringTableModalActions.TRASH
          }
          onClose={() => setCurrModal(initalModalSettings)}
          updateList={() =>
            refetchTotal().then(() =>
              companyMonitoringCountTotal?.companyMonitoringCount &&
              currPage <=
                Math.ceil(
                  companyMonitoringCountTotal?.companyMonitoringCount /
                    pageSize,
                )
                ? refetch()
                : null,
            )
          }
        />
      </div>
    </React.Fragment>
  )
}

export default AnimatorCompanyMonitoringList
