/**
 * Restricted routes are when
 * user is not authenticated he can have
 * access to these routes
 * */
import React from 'react'

import SignIn from '../pages/SignIn'
import SignUpEmployee from '../pages/SignUpEmployee/index'
import SignUpCompany from '../pages/SignUpCompany/index'
import ForgotPassword from '../pages/ForgotPassword'

const restrictedRoutes = [
  {
    path: '/',
    exact: true,
    main: <SignIn />,
  },
  {
    path: '/signup-employee',
    exact: false,
    main: <SignUpEmployee />,
  },
  {
    path: '/signup-company',
    exact: false,
    main: <SignUpCompany />,
  },
  {
    path: '/forgot-password',
    exact: false,
    main: <ForgotPassword />,
  },
]

export default restrictedRoutes
