/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'

import { IconTypes } from './Icon'
import Card from './Card'
import Icon from './Icon'
import Avatar from './Avatar'

interface CardInfoProps {
  title?: string
  avatar?: boolean
  avatarUrl?: string
  data: {
    icon?: IconTypes
    iconColor?: string
    description: string
    textColor?: string
    right?: React.ReactElement
  }[]
  onEditClicked?: () => void
  editIconColor?: string
}

const CardInfo: React.FC<CardInfoProps> = ({
  title = '',
  data,
  avatar = false,
  avatarUrl,
  onEditClicked,
  editIconColor,
}) => {
  const theme = useTheme()

  return (
    <Card
      css={{
        padding: '25px',
        height: '100%',
        minWidth: '430px',
        [theme.media.mobile]: {
          minWidth: 'auto',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          [theme.media.mobile]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
        }}
      >
        {avatar && (
          <div
            css={{
              minWidth: '120px',
              height: '120px',
              margin: '0px 30px 0px 0px',
              [theme.media.mobile]: {
                margin: '0px 0px 24px 0px',
              },
            }}
          >
            <Avatar imgUrl={avatarUrl} editable={false} />
          </div>
        )}
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          {title && (
            <span
              css={{
                fontFamily: theme.fontFamily,
                color: theme.colors.gray1,
                fontSize: '1.8rem',
                fontWeight: 'bold',
                marginBottom: '16px',
              }}
            >
              {title}
            </span>
          )}

          {data.map((item, index) => (
            <div
              key={`${item.description}_${index}`}
              css={{
                display: 'flex',
                alignItems: 'center',
                marginTop: index ? '14px' : 0,
                paddingRight: '20px',
              }}
            >
              {item.icon && (
                <div css={{ marginRight: '14px' }}>
                  <Icon
                    type={item.icon}
                    customSize={{ width: '26px', height: '26px' }}
                    color={item.iconColor ? item.iconColor : theme.colors.gray1}
                  />
                </div>
              )}
              <span
                css={{
                  fontSize: '1.6rem',
                  color: item.textColor ? item.textColor : theme.colors.gray1,
                  wordBreak: 'break-word',
                }}
              >
                {item.description}

                {item.right ? item.right : null}
              </span>
            </div>
          ))}
        </div>
      </div>
      {onEditClicked && (
        <button
          onClick={onEditClicked}
          css={{
            position: 'absolute',
            width: 44,
            height: 44,
            borderRadius: '50%',
            right: '12px',
            bottom: '12px',
            backgroundColor:
              editIconColor === theme.colors.error ? '#F7BCBC' : 'transparent',
          }}
        >
          <Icon
            type="edit"
            size="medium"
            color={editIconColor ? editIconColor : theme.colors.gray1}
          />
        </button>
      )}
    </Card>
  )
}

export default CardInfo
