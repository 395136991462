import { Flex } from '../../../../components/Flex'
import {
  BabySeatOptions,
  EmployeeDeployment,
  SpecialBikesType,
} from '../../../../__generated__/graphql'
import React from 'react'
import theme from '../../../../styles/theme'
import Icon from '../../../../components/Icon'
import Tooltip from '../../../../components/Tooltip'
import { IconTypes } from '../../../../components/Icon'

export interface EmployeeOptionsIconsProps {
  employeeDeployment: Pick<EmployeeDeployment, 'option' | 'chosenBikeModel'>
}

export const EmployeeOptionsIcons: React.FC<EmployeeOptionsIconsProps> = ({
  employeeDeployment,
}) => {
  const bikeType = employeeDeployment.chosenBikeModel?.bikemodel.bikeType
  const brand = employeeDeployment.chosenBikeModel?.bikemodel.brand
  const model = employeeDeployment.chosenBikeModel?.bikemodel.name

  let icon = 'bike' as IconTypes
  let iconColor = theme.colors.gray2

  if (bikeType === SpecialBikesType.UrbanBike) {
    icon = 'bikeUrban'
  } else if (bikeType === SpecialBikesType.FoldingBike) {
    icon = 'bikeFolding'
    iconColor = theme.colors.gray6
  } else if (bikeType === SpecialBikesType.CargoBike) {
    icon = 'bikeCargo'
  }

  return (
    <Flex direction="row" justify="center" gap="8px">
      <Tooltip title={`${brand} ${model}`} placement="top">
        <Icon type={icon} color={iconColor} />
      </Tooltip>

      {employeeDeployment.option === BabySeatOptions.Needed && (
        <Icon type="kidOption" color={theme.colors.orangeLegend} />
      )}

      {employeeDeployment.option === BabySeatOptions.AbsolutelyNeeded && (
        <Icon type="kidOption" color={theme.colors.redLegend} />
      )}
    </Flex>
  )
}
