import React from 'react'

import ResetPassword from '../pages/ResetPassword'
import VerifyEmail from '../pages/VerifyEmail'

const publicRoutes = [
  {
    path: '/verify-email/:token',
    exact: true,
    main: <VerifyEmail />,
  },
  {
    path: '/reset-password/:token',
    exact: true,
    main: <ResetPassword />,
  },
]

export default publicRoutes
