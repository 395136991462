import React from 'react'
import theme from '../../styles/theme'
import { useQuery } from '@apollo/client'
import {
  GetBikesCountsDashboardQuery,
  GetBikesCountsDashboardQueryVariables,
  GetCompaniesCountsDashboardQuery,
  GetCompaniesCountsDashboardQueryVariables,
} from '../../__generated__/graphql'
import { QUERY_COMPANIES_COUNT_FILTERED } from '../../pages/Animator/Dashboard/MyCompanies'
import { useSelector } from 'react-redux'
import { StoreState } from '../../redux/rootReducer'
import { UserRoles } from '@goodwatt/shared/dist/types'
import { ProgramStepType } from '@goodwatt/shared/dist/types'
import { QUERY_BIKES_COUNT_FILTERED } from '../../pages/Animator/Dashboard/MyBikes'
import { useTranslation } from 'react-i18next'

type SummaryItem = {
  value: number
  title: string
  subtitle: string
}

const SummaryList = ({
  items,
  style,
}: {
  items: SummaryItem[]
  style?: React.CSSProperties
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        padding: '2.1rem',
        width: '100%',
        ...style,
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2px',
            width: '100%',
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
              color: theme.colors.primary,
            }}
          >
            {item.value}
          </div>
          <div
            style={{
              marginTop: 16,
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            {item.title}
          </div>
          <div
            style={{
              fontSize: '14px',
              textAlign: 'center',
            }}
          >
            {item.subtitle}
          </div>
        </div>
      ))}
    </div>
  )
}

export default function HeaderSummary() {
  const { id: userId, role: userRole } = useSelector(
    (state: StoreState) => state.user,
  )
  const isAdmin = userRole === UserRoles.ADMIN
  const { t } = useTranslation()

  const { data: companyData } = useQuery<
    GetCompaniesCountsDashboardQuery,
    GetCompaniesCountsDashboardQueryVariables
  >(QUERY_COMPANIES_COUNT_FILTERED, {
    variables: {
      inActivityFilter: {
        AND: [
          isAdmin
            ? {}
            : {
                area: {
                  animator: {
                    some: {
                      userId: { equals: userId },
                    },
                  },
                },
              },
          {
            programStep: {
              stepNbr: {
                gte: ProgramStepType.CONTRACTUALIZATION,
                lte: ProgramStepType.PROGRAM_REVIEW,
              },
            },
          },
        ],
      },
      pendingFilter: {
        AND: [
          isAdmin
            ? {}
            : {
                area: {
                  animator: {
                    some: {
                      userId: { equals: userId },
                    },
                  },
                },
              },
          { programStep: { stepNbr: { equals: ProgramStepType.PENDING } } },
        ],
      },
      employeesFilter: {
        AND: [
          isAdmin
            ? {}
            : {
                company: {
                  area: {
                    animator: {
                      some: {
                        userId: { equals: userId },
                      },
                    },
                  },
                },
              },
        ],
      },
      employeesWithLoanFilter: {
        AND: [
          isAdmin
            ? {}
            : {
                company: {
                  area: {
                    animator: {
                      some: {
                        userId: { equals: userId },
                      },
                    },
                  },
                },
              },
          {
            bikeLoan: {
              some: {
                OR: [
                  {
                    bikeReturned: { equals: false },
                  },
                  {
                    bikeReturned: { equals: true },
                  },
                ],
              },
            },
          },
        ],
      },
    },
  })

  const { data: bikeData } = useQuery<
    GetBikesCountsDashboardQuery,
    GetBikesCountsDashboardQueryVariables
  >(QUERY_BIKES_COUNT_FILTERED)

  const organizationSummary: SummaryItem[] = [
    {
      value: companyData?.companiesPending || 0,
      title: t('animator.dashboard.summary.companies'),
      subtitle: t('animator.dashboard.summary.waiting'),
    },
    {
      value: companyData?.companiesInActivity || 0,
      title: t('animator.dashboard.summary.companies'),
      subtitle: t('animator.dashboard.summary.validated'),
    },
    {
      value: companyData?.companyEmployeesCount || 0,
      title: t('animator.dashboard.summary.employees'),
      subtitle: t('animator.dashboard.summary.registered'),
    },
    {
      value: companyData?.companyEmployeesWithLoanCount || 0,
      title: t('animator.dashboard.summary.employees'),
      subtitle: t('animator.dashboard.summary.onLoan'),
    },
  ]

  const bikesSummary: SummaryItem[] = [
    {
      value: bikeData?.bikesOnLoan || 0,
      title: t('animator.dashboard.summary.bikes'),
      subtitle: t('animator.dashboard.myBikesCard.onLoan'),
    },
    {
      value: bikeData?.bikesInRepair || 0,
      title: t('animator.dashboard.summary.bikes'),
      subtitle: t('animator.dashboard.myBikesCard.inRepair'),
    },
    {
      value: bikeData?.bikesOutOfService || 0,
      title: t('animator.dashboard.summary.bikes'),
      subtitle: t('animator.dashboard.myBikesCard.outOfService'),
    },
    {
      value: bikeData?.bikesAvailable || 0,
      title: t('animator.dashboard.summary.bikes'),
      subtitle: t('animator.dashboard.myBikesCard.available'),
    },
  ]

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        borderRadius: '30px',
        boxShadow: '4px 8px 20px rgba(0, 0, 0, 0.15)',
        backgroundColor: theme.colors.white,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '30px',
        justifyContent: 'space-around',
      }}
    >
      <SummaryList
        items={organizationSummary}
        style={{
          borderRight: `2px solid ${theme.colors.border}`,
        }}
      />

      <SummaryList items={bikesSummary} />
    </div>
  )
}
