import { gql, MutationTuple, useMutation } from '@apollo/client'
import {
  DeleteEmployeeDeploymentMutation,
  DeleteEmployeeDeploymentMutationVariables,
} from '../../../../__generated__/graphql'

const DELETE_EMPLOYEE_DEPLOYMENT_MUTATION = gql`
  mutation DeleteEmployeeDeployment($id: String!) {
    deleteEmployeeDeployment(id: $id)
  }
`

export const useDeleteEmployeeDeployment = (): MutationTuple<
  DeleteEmployeeDeploymentMutation,
  DeleteEmployeeDeploymentMutationVariables
> => {
  return useMutation(DELETE_EMPLOYEE_DEPLOYMENT_MUTATION, {
    refetchQueries: ['GetDeploymentsAvailables'],
  })
}
