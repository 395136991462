/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'

export interface BadgeProps {
  fontColor?: string
  backgroundColor?: string
  label: string
}

const Badge: React.FC<BadgeProps> = ({
  fontColor = 'white',
  backgroundColor = 'black',
  label,
}) => {
  const theme = useTheme()

  return (
    <div
      css={{
        backgroundColor: backgroundColor,
        borderRadius: 3,
        fontFamily: theme.fontFamily,
        padding: '6px 14px',
      }}
    >
      <span
        css={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '1.4rem',
          color: fontColor,
        }}
      >
        {label}
      </span>
    </div>
  )
}

export default Badge
