/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'emotion-theming'
import Card from '../../../../components/Card'
import { Flex } from '../../../../components/Flex'
import { DeploymentTypeIcon } from '../../../Animator/Shared/DeploymentTypeIcon'
import { DeploymentType } from '../../../../__generated__/graphql'

import { ReactComponent as GoodwattLogoColor } from '../../../../assets/images/goodwatt_color.svg'

export const GoodwattOffers: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Card
      css={{ flex: 2 }}
      title={
        <span
          css={{
            fontWeight: 'bold',
            fontSize: '1.8rem',
            lineHeight: '20px',
            fontFamily: 'Raleway',
          }}
        >
          {t('employee.deployments.offers')}
        </span>
      }
    >
      <Flex
        direction="row"
        align="end"
        css={{
          marginTop: '16px',
          marginBottom: '16px',
          alignItems: 'flex-start',
        }}
      >
        <div css={{ flex: 1, textAlign: 'center', padding: '0 10px' }}>
          <DeploymentTypeIcon type={'DISCOVERY' as DeploymentType} />
          <GoodwattLogoColor css={{ marginTop: '20px', marginBottom: '5px' }} />
          <div
            css={{
              color: theme.colors.gray3,
              marginBottom: '20px',
            }}
          >
            {t(`shared.deploymentTypeLabel.${DeploymentType.Discovery}`)}
          </div>
          <div>
            <p
              css={{ fontSize: '14px' }}
              dangerouslySetInnerHTML={{
                __html: t(
                  `shared.deploymentTypeDesc.${DeploymentType.Discovery}`,
                ),
              }}
            />
          </div>
        </div>

        <div
          css={{
            flex: 1,
            textAlign: 'center',
            borderLeft: '1px solid gray',
            padding: '0 10px',
          }}
        >
          <DeploymentTypeIcon type={'RENTAL' as DeploymentType} />
          <GoodwattLogoColor css={{ marginTop: '20px', marginBottom: '5px' }} />
          <div
            css={{
              color: theme.colors.gray3,
              marginBottom: '20px',
            }}
          >
            {t(`shared.deploymentTypeLabel.${DeploymentType.Rental}`)}
          </div>
          <div>
            <p
              css={{ fontSize: '14px' }}
              dangerouslySetInnerHTML={{
                __html: t(`shared.deploymentTypeDesc.${DeploymentType.Rental}`),
              }}
            />
          </div>
        </div>
      </Flex>
    </Card>
  )
}
