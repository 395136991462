/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { ReactElement } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ANIMATOR_GUARD_Z_INDEX } from '../../../../../../../components/AnimatorGuard'

import { format } from 'date-fns'

import usePortal from '../../../../../../../hooks/usePortal'
import {
  EmployeeSignType,
  EmployeeValidationState,
  setLoanBikeForm,
} from '../../../../../../../redux/forms'
import { StoreState } from '../../../../../../../redux/rootReducer'

import EntityStatesOfUse from './EntityStatesOfUse'
import { StateOfUseType } from '@goodwatt/shared/dist/types'
import CredsForm from './CredsForm'
import AuthPassed from './WayoutScreens/AuthPassed'
import SignPaper from './WayoutScreens/SignPaper'
import SignOnApp from './WayoutScreens/SignOnApp'
import CorrectionsNeeded from './WayoutScreens/CorrectionsNeeded'

import {
  QUERY_GET_EMPLOYEE_ABSENCES,
  QUERY_GET_EMPLOYEE_SIGN_STATUS,
} from '../../../../../../../utils/gql/queries'
import { useQuery } from '@apollo/client'

import usePromiseLazyQuery from '../../../../../../../hooks/usePromiseLazyQuery'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../../../../contexts/NotificationContext'
import i18n from '../../../../../../../i18n/config'
import {
  AppointmentCategory,
  GetEmployeeAbsencesQuery,
  GetEmployeeAbsencesQueryVariables,
  GetEmployeeQuery,
  GetEmployeeSignStatusQuery,
  GetEmployeeSignStatusQueryVariables,
} from '../../../../../../../__generated__/graphql'

interface EmployeeLoanValidationProps {
  loanEventsFormatted: { loanDeliveryDate: string; loanReturnDate: string }
  onSuccess: () => void
  employeeEmail: string
  employee: GetEmployeeQuery['employee'] | null | undefined
  contractAccepted: boolean | null | undefined
  closeValidation: () => void
  deploymentId: string
}

const currStepi18nNamespace =
  'animator.company.employees.selected.modals.employeeLoanBikeModal.employeeLoanValidation'

const EmployeeLoanValidation: React.FC<EmployeeLoanValidationProps> = ({
  onSuccess,
  employeeEmail,
  employee,
  contractAccepted,
  closeValidation,
  deploymentId,
}) => {
  const target = usePortal()
  const { t } = useTranslation()
  const theme = useTheme()
  const { accessories, stickerId, employeeValidation, components } =
    useSelector((state: StoreState) => state.forms.loanBikeForm)
  const dispatch = useDispatch()

  const notifications = useNotifications()

  // React.useEffect(() => {
  //   dispatch(activateAnimatorGuard())
  // }, [dispatch])

  const getEmployeeSignStatus = usePromiseLazyQuery<
    GetEmployeeSignStatusQuery,
    GetEmployeeSignStatusQueryVariables
  >(QUERY_GET_EMPLOYEE_SIGN_STATUS, 'no-cache')

  const formattedComponents = React.useMemo(
    () =>
      Object.entries(components).map(([name, value]) => ({
        name,
        translatedName: t(`shared.bikeComponentPartLabel.${name}`),
        stateOfUse: value.stateOfUse as StateOfUseType,
      })),
    [components, t],
  )

  const hasAccessory = React.useMemo(
    () => Object.values(accessories).some(accessory => accessory.included),
    [accessories],
  )

  const formattedAccessories = React.useMemo(
    () =>
      hasAccessory
        ? Object.entries(accessories)
            .filter(entry => entry[1].included)
            .map(([name, value]) => ({
              name,
              translatedName: t(`shared.loanPackageAccessoryLabel.${name}`),
              stateOfUse: value.stateOfUse as StateOfUseType,
            }))
        : [],
    [hasAccessory, accessories, t],
  )

  const handleSuccessWayout = () => {
    onSuccess()
    closeValidation()
  }

  const handleCheckSignOnApp = async () => {
    if (!employee?.id) {
      notifications.anErrorOccured()
      return
    }

    const { data } = await getEmployeeSignStatus({
      where: {
        id: employee.id,
      },
    })

    if (!data?.employee?.contractAccepted) {
      notifications.newNotification({
        type: NotificationTypes.ERROR,
        message: i18n.t(
          'animator.company.employees.selected.modals.employeeLoanBikeModal.employeeLoanValidation.signOnApp.errors.notSign',
        ),
      })
      return
    }

    onSuccess()
    closeValidation()
  }

  const handleCorrectionsNeededWayout = () => {
    dispatch(
      setLoanBikeForm({
        employeeValidation: EmployeeValidationState.TODO,
      }),
    )
    closeValidation()
  }

  const handleBackToLoanSteps = () => {
    dispatch(
      setLoanBikeForm({
        employeeValidation: EmployeeValidationState.CORRECTIONS_NEEDED,
      }),
    )
  }

  const handleSignPaper = () => {
    dispatch(
      setLoanBikeForm({
        employeeValidation: EmployeeValidationState.SIGN_PAPER,
        employeeSignType: EmployeeSignType.SIGN_PAPER,
      }),
    )
  }

  let wayoutScreens: ReactElement | null = null

  if (employeeValidation === EmployeeValidationState.VALIDATED) {
    wayoutScreens = <AuthPassed onExitClick={handleSuccessWayout} />
  } else if (
    employeeValidation === EmployeeValidationState.CORRECTIONS_NEEDED
  ) {
    wayoutScreens = (
      <CorrectionsNeeded onExitClick={handleCorrectionsNeededWayout} />
    )
  } else if (employeeValidation === EmployeeValidationState.SIGN_ON_APP) {
    wayoutScreens = contractAccepted ? (
      <AuthPassed onExitClick={handleSuccessWayout} />
    ) : (
      <SignOnApp onExitClick={handleCheckSignOnApp} />
    )
  } else if (employeeValidation === EmployeeValidationState.SIGN_PAPER) {
    wayoutScreens = <SignPaper onExitClick={handleSuccessWayout} />
  }

  const { data: employeeAbsence } = useQuery<
    GetEmployeeAbsencesQuery,
    GetEmployeeAbsencesQueryVariables
  >(QUERY_GET_EMPLOYEE_ABSENCES, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: employee?.id,
      },
    },
  })

  const loanDeliveryAppointment = employee?.loanDeliveryAppointment?.find(
    appointment => appointment?.deploymentId === deploymentId,
  )

  const loanReturnAppointment = employee?.loanReturnAppointment?.find(
    appointment => appointment?.deploymentId === deploymentId,
  )

  const loanDeliveryAbsence = employeeAbsence?.employee?.absences?.find(
    x =>
      x?.category === AppointmentCategory.LoanDelivery &&
      x?.deploymentId === deploymentId,
  )

  const loanReturnAbsence = employeeAbsence?.employee?.absences?.find(
    x =>
      x?.category === AppointmentCategory.LoanReturn &&
      x?.deploymentId === deploymentId,
  )

  const employeeIsAbsent = !!loanDeliveryAbsence

  const employeeHasAlreadySign = employee?.contractAccepted === true

  const loanDeliveryDate = loanDeliveryAppointment?.date
    ? format(new Date(loanDeliveryAppointment.date), 'dd/MM/yyyy')
    : loanDeliveryAbsence
    ? format(new Date(loanDeliveryAbsence.date), 'dd/MM/yyyy')
    : null

  const loanReturnDate = loanReturnAppointment?.date
    ? format(new Date(loanReturnAppointment.date), 'dd/MM/yyyy')
    : loanReturnAbsence
    ? format(new Date(loanReturnAbsence.date), 'dd/MM/yyyy')
    : null

  let loanDateRange = t(
    `${currStepi18nNamespace}.description.loanRangesWithoutEnd`,
    {
      loanDeliveryDate: loanDeliveryDate || 'n/a',
    },
  )

  if (loanDeliveryDate && loanReturnDate) {
    loanDateRange = t(`${currStepi18nNamespace}.description.loanRanges`, {
      loanDeliveryDate: loanDeliveryDate || 'n/a',
      loanReturnDate: loanReturnDate || 'n/a',
    })
  }

  return createPortal(
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.colors.white,
        zIndex: ANIMATOR_GUARD_Z_INDEX + 1,
      }}
    >
      {wayoutScreens ? (
        wayoutScreens
      ) : (
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: 645,
                marginBottom: 35,
                [theme.media.tablet]: {
                  maxWidth: 350,
                },
              }}
            >
              <span
                css={{
                  textAlign: 'center',
                  color: theme.colors.primary,
                  fontFamily: 'Raleway',
                  fontSize: '1.8rem',
                  fontWeight: 'bold',
                  marginTop: '10vh',
                }}
              >
                {t(
                  'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeAttribution.title',
                )}
              </span>
              <div css={{ marginBottom: 35 }} />
              <div css={{ textAlign: 'center' }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(`${currStepi18nNamespace}.description.bike`, {
                      stickerId,
                    }),
                  }}
                />{' '}
                {hasAccessory && (
                  <span>
                    {t(`${currStepi18nNamespace}.description.accessories`)}{' '}
                  </span>
                )}
                <span
                  dangerouslySetInnerHTML={{
                    __html: loanDateRange,
                  }}
                />
              </div>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: 50,
                  [theme.media.tablet]: {
                    flexWrap: 'wrap',
                    marginBottom: 25,
                  },
                }}
              >
                <div css={{ width: '100%' }}>
                  <div
                    css={{
                      marginBottom: 35,
                      [theme.media.tablet]: {
                        marginBottom: 25,
                      },
                    }}
                  />
                  <EntityStatesOfUse
                    entityTitle={t('shared.resources.bike')}
                    entities={formattedComponents}
                  />
                </div>
                {hasAccessory && (
                  <div css={{ width: '100%' }}>
                    <div
                      css={{
                        marginBottom: 35,
                        [theme.media.tablet]: {
                          marginBottom: 25,
                        },
                      }}
                    />
                    <EntityStatesOfUse
                      entityTitle={t('shared.resources.accessory')}
                      entities={formattedAccessories}
                    />
                  </div>
                )}
              </div>
              <span
                css={{
                  textAlign: 'center',
                  color: theme.colors.primary,
                  fontWeight: 'bold',
                  lineHeight: '25px',
                }}
              >
                {employeeHasAlreadySign
                  ? employeeIsAbsent
                    ? t(`${currStepi18nNamespace}.verificationAbsent`)
                    : t(`${currStepi18nNamespace}.verificationAlreadySign`)
                  : t(`${currStepi18nNamespace}.verification`)}
              </span>
              <div css={{ marginBottom: 35 }} />
              <CredsForm
                email={employeeEmail}
                requirePassword={!employeeHasAlreadySign}
              />
            </div>
            <div css={{ paddingBottom: 35 }}>
              <span
                css={{
                  color: theme.colors.gray3,
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                  margin: '0 48px',
                }}
                onClick={handleBackToLoanSteps}
              >
                {t(
                  'animator.company.employees.selected.modals.employeeLoanBikeModal.employeeLoanValidation.backToPartsState',
                )}
              </span>

              <span
                css={{
                  color: theme.colors.blue1,
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                  margin: '0 48px',
                }}
                onClick={handleSignPaper}
              >
                {t(
                  'animator.company.employees.selected.modals.employeeLoanBikeModal.employeeLoanValidation.paperContract',
                )}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>,
    target,
  )
}

export default EmployeeLoanValidation
