import React, { Fragment } from 'react'
import EditEmployeeHabitsModal, {
  EditEmployeeHabitsModalProps,
} from '../../../Shared/EditEmployeeHabitsModal'
import { useModal } from '../../../../hooks/useModal'
import Icon from '../../../../components/Icon'

export type EditEmployeeHabitsButtonProps = Pick<
  EditEmployeeHabitsModalProps,
  'employee'
>

export const EditEmployeeHabitsButton: React.FC<
  EditEmployeeHabitsButtonProps
> = ({ employee }) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      {modalProps.isOpen && (
        <EditEmployeeHabitsModal employee={employee} {...modalProps} />
      )}
      <button onClick={onOpen}>
        <Icon type="road" />
      </button>
    </Fragment>
  )
}
