/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'
import { ProgramStepType } from '@goodwatt/shared/dist/types'

import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'

import StepStatusModal from './StepStatusModal'

interface StepStatusProps {
  currentStepNbr?: ProgramStepType
  description?: string
  onClick?: (e: React.MouseEvent) => void
  refetchCompany: () => void
}

const StepStatus: React.FC<StepStatusProps> = ({
  currentStepNbr,
  description,
  refetchCompany,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Fragment>
      <Card css={{ height: '100%' }}>
        <span
          css={{
            fontWeight: 'bold',
            fontSize: '1.8rem',
            color: theme.colors.gray2,
            marginBottom: 13,
          }}
        >
          {t('animator.company.progress.stepStatus.title')}
        </span>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: 60,
                width: '60px',
                height: '60px',
                border: `3px solid ${theme.colors.primary}`,
                borderRadius: '50%',
                marginBottom: 12,
                marginRight: 12,
              }}
            >
              <span
                css={{
                  fontSize: '4.0rem',
                  color: theme.colors.primary,
                }}
              >
                {currentStepNbr || '...'}
              </span>
            </div>
            <div css={{ paddingBottom: '10px' }}>
              <span
                css={{
                  textAlign: 'center',
                  fontFamily: theme.fontFamily,
                  fontWeight: 'bold',
                  fontSize: '1.4rem',
                  color: theme.colors.primary,
                }}
              >
                {description || '...'}
              </span>
            </div>
          </div>
        </div>
        {![
          ProgramStepType.PROGRAM_REVIEW,
          ProgramStepType.PREPARING_LOAN,
        ].includes(currentStepNbr as number) && (
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button onClick={() => setEditMode(true)}>
              <Icon
                type="stepArrowLeft"
                size="medium"
                strokeColor={theme.colors.primary}
                rotate="180deg"
              />
            </button>
          </div>
        )}
      </Card>
      <StepStatusModal
        refetchCompany={refetchCompany}
        modalIsOpen={editMode}
        currentStepNbr={currentStepNbr}
        closeModal={() => setEditMode(false)}
      />
    </Fragment>
  )
}

export default StepStatus
