/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useModal } from '../../../../hooks/useModal'
import ArchiveUserModal from './ArchiveUserModal'

export interface ArchiveUserButtonProps {
  id: string
  disabled?: boolean
}

export const ArchiveUserButton: React.FC<ArchiveUserButtonProps> = ({
  id,
  disabled,
}: ArchiveUserButtonProps) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      {modalProps.isOpen && <ArchiveUserModal id={id} {...modalProps} />}
      <button
        onClick={onOpen}
        disabled={disabled}
        css={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <Icon
          color="transparent"
          strokeColor={disabled ? 'grey' : 'black'}
          type="archive"
        />
      </button>
    </Fragment>
  )
}
