/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { BikeModelsList } from './components/BikeModelsList'

export const Application: React.FC = () => {
  return <BikeModelsList />
}

export default Application
