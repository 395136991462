import { GetAnimatorsQuery } from '../../__generated__/graphql'

export enum AnimatorTableModal {
  EDIT = 'edit',
  DELETE = 'delete',
  ADD = 'add',
  NONE = 'none',
}
export interface AnimatorModalSettings<
  D = GetAnimatorsQuery['animators'][0],
  T = AnimatorTableModal,
> {
  animateur: D | null
  actionName: T
}
