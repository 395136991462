/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useModal } from '../../../../hooks/useModal'
import DeleteCompanyModal from './DeleteCompanyModal'

export interface DeleteCompanyButtonProps {
  id: string
  disabled?: boolean
  companyName: string
}

export const DeleteCompanyButton: React.FC<DeleteCompanyButtonProps> = ({
  id,
  companyName,
  disabled,
}: DeleteCompanyButtonProps) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <DeleteCompanyModal id={id} companyName={companyName} {...modalProps} />
      <button
        onClick={onOpen}
        disabled={disabled}
        css={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <Icon color={disabled ? 'grey' : 'white'} type="trash" />
      </button>
    </Fragment>
  )
}
