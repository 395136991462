import { css } from '@emotion/core'

export const searchBoxCss = css`
  border: 1px solid #d4d4d4;
  border-radius: 30px;
  padding: 10px 10px 10px 15px;
  margin-top: 15px;
  margin-bottom: 10px;
`

export const tagsSharedCss = css`
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
`
export const tagsSelected = css`
  color: white;
  background-color: #3cd9f1;
`
export const tagsUnselected = css`
  color: #828282;
  transition: background-color 0.3s;

  :hover {
    background-color: #f1f1f1;
  }
`
export const questionShared = css`
  border-bottom: 1px solid #d4d4d4;
  color: #4f4f4f;
  user-select: none;
`

export const questionHidden = css`
  font-weight: 500;
`
export const plusIcon = css`
  transition: transform 0.1s ease-out;
  float: right;
  margin-top: -3px;
`
export const plusIconRotate = css`
  transform: rotate(45deg);
`

export const modalWrapper = css`
  position: relative;
  display: flex;
  margintop: 41px;
  marginbottom: 31px;
  justifycontent: center;
  width: 100%;
`
