/** @jsx jsx */
import React, { useState } from 'react'
import { jsx } from '@emotion/core'
import { gql, useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '../../../../../components/Table'
import Pagination from '../../../../../components/Pagination'

import { TableRefetchContext, TableTypes } from '../TableRefetchContext'
import { UserRoles } from '@goodwatt/shared/dist/types'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../redux/rootReducer'
import DownloadCSVButton from '../../../../../components/Button/DownloadCSVButton'
import usePromiseLazyQuery from '../../../../../hooks/usePromiseLazyQuery'

import {
  formatCompaniesExport,
  QUERY_COMPANIES_EXPORT,
} from '../../../../../utils/gql/companiesExport'
import {
  AccepetedCompaniesTableRow,
  ArchivedCompaniesTableRow,
  ArchiveTableModal,
  CompaniesModalSettings,
} from '../../../../../types/AnimatorCompanies'
import CompanyDeleteModal from '../RefusedList/DeleteModal'
import ArchivedCompaniesActionCell from '../../../../../components/Table/CustomCells/ArchivedCompaniesActionCell'
import CompanyUnArchiveModal from './UnArchiveModal'
import { formatMonth } from '../../../../../utils/Date'
import InputSelect from '../../../../../components/InputSelect'
import {
  AnimatorGetCompaniesInActivityCountQueryVariables,
  GetArchivedCompanyRangesQuery,
  GetArchivedCompanySearchQuery,
  GetArchivedCompanySearchQueryVariables,
  GetCompaniesExportQuery,
  GetCompaniesExportQueryVariables,
} from '../../../../../__generated__/graphql'
import { useCompaniesSearchCount } from '../CompanyDeploymentsList/useCompaniesSearchCount'

const QUERY_ARCHIVE_COMPANY_SEARCH = gql`
  query GetArchivedCompanySearch(
    $multiFilter: String
    $fleet: String
    $programStep: Int
    $department: String
    $archived: Boolean
    $take: Int
    $skip: Int
    $orderBy: CompanyOrderByInput
  ) {
    companiesSearch(
      multiFilter: $multiFilter
      fleet: $fleet
      programStep: $programStep
      department: $department
      archived: $archived

      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      subscriptionCode
      name
      fleetName
      areaName
      department
      nbrEmployees
      employeesSelectedForLoan
      loanEndDate
      bikeTotalDistance
      archivedAt
      archiveStatus
      allDeploymentCount
      beneficiaries
      registered
    }
  }
`

const QUERY_ARCHIVE_COMPANY_RANGES = gql`
  query GetArchivedCompanyRanges {
    companiesExportRanges {
      label
      startDate
      endDate
      count
    }
  }
`

interface InActivityListProps {
  filters: {
    [filterName: string]: string
  }
  pageSize: number
}

const initialModalSettings: CompaniesModalSettings<
  AccepetedCompaniesTableRow,
  ArchiveTableModal
> = {
  company: {
    id: '',
  },
  name: '',
}

const ArchivedList: React.FC<InActivityListProps> = ({ filters, pageSize }) => {
  const { role: userRole } = useSelector((state: StoreState) => state.user)

  const [currModal, setCurrModal] = React.useState(initialModalSettings)

  const { t } = useTranslation()
  const [currPage, setCurrPage] = React.useState(1)
  const [orderBy, setOrderBy] =
    React.useState<ColumnSortBy>(initialColumnSortBy)
  const {
    removeTableToFetch,
    tableRefetchPlanner: { [TableTypes.ARCHIVED]: needToRefetch },
  } = React.useContext(TableRefetchContext)

  const isAdmin = userRole === UserRoles.ADMIN

  // Resetting to first page on filter change
  React.useEffect(() => {
    setCurrPage(1)
  }, [filters])

  const searchVariables: AnimatorGetCompaniesInActivityCountQueryVariables = {
    multiFilter: filters.multiFilter,
    fleet: filters.fleet,
    programStep: parseInt(filters.programStep, 10),
    department: filters.department,
    archived: true,
  }

  const { data: countData } = useCompaniesSearchCount({
    variables: searchVariables,
  })

  const { data: rangesData, refetch: refetchRanges } =
    useQuery<GetArchivedCompanyRangesQuery>(QUERY_ARCHIVE_COMPANY_RANGES, {
      fetchPolicy: 'network-only',
    })

  const rangesOptions = React.useMemo(() => {
    if (rangesData?.companiesExportRanges == null) {
      return []
    }

    return rangesData.companiesExportRanges.map(range => ({
      label: range?.label as string,
      value: {
        startDate: range?.startDate as string,
        endDate: range?.endDate as string,
      },
    }))
  }, [rangesData])

  const {
    data: companiesData,
    loading,
    refetch,
  } = useQuery<
    GetArchivedCompanySearchQuery,
    GetArchivedCompanySearchQueryVariables
  >(QUERY_ARCHIVE_COMPANY_SEARCH, {
    fetchPolicy: 'network-only',
    variables: {
      ...searchVariables,

      take: pageSize,
      skip: (currPage - 1) * pageSize,
      orderBy:
        orderBy.sort && orderBy.columnName
          ? { [orderBy.columnName]: orderBy.sort }
          : undefined,
    },
  })

  React.useEffect(() => {
    if (needToRefetch) {
      Promise.all([refetch(), refetchRanges()]).then(() =>
        removeTableToFetch(TableTypes.ARCHIVED),
      )
    }
  }, [refetch, needToRefetch, removeTableToFetch, refetchRanges])

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<ArchivedCompaniesTableRow> }) => (
      <ArchivedCompaniesActionCell setModal={setCurrModal} row={row} />
    ),
    [],
  )

  const columns = React.useMemo<Column[]>(() => {
    const columnValues = [
      {
        Header: t('animator.companies.table.headers.companyCode') as string,
        accessor: 'subscriptionCode',
        cellStyle: (): { textAlign: string } => ({ textAlign: 'right' }),
      },
      {
        Header: t('animator.companies.table.headers.companyName') as string,
        accessor: 'name',
        maxWidth: 200,
      },
      // {
      //   Header: t('animator.companies.table.headers.companyFleet') as string,
      //   accessor: 'fleetName',
      //   maxWidth: isAdmin ? 200 : 274,
      // },
      {
        Header: t(
          'animator.companies.table.headers.companyNbrEmployees',
        ) as string,
        accessor: 'nbrEmployees',
        cellStyle: (): { textAlign: string } => ({ textAlign: 'center' }),
      },
      {
        Header: t(
          'animator.companies.acceptCompany.deployments.plural',
        ) as string,
        accessor: 'allDeploymentCount',
        cellStyle: (): { textAlign: string } => ({ textAlign: 'center' }),
      },
      {
        Header: t('animator.deployments.progress.stats.registered') as string,
        accessor: 'registered',
        cellStyle: (): { textAlign: string } => ({ textAlign: 'center' }),
      },
      {
        Header: t(
          'animator.deployments.progress.stats.beneficiaries',
        ) as string,
        accessor: 'beneficiaries',
        cellStyle: (): { textAlign: string } => ({ textAlign: 'center' }),
      },
      // {
      //   Header: t('animator.companies.table.headers.companyLoan') as string,
      //   accessor: 'employeesSelectedForLoan',
      //   disableSortBy: true,
      //   cellStyle: (): { textAlign: string } => ({ textAlign: 'center' }),
      // },
      // {
      //   Header: t('animator.companies.table.headers.endLoanDate') as string,
      //   accessor: 'loanEndDate',
      //   cellStyle: (): { textAlign: string } => ({ textAlign: 'center' }),
      // },
      // {
      //   Header: t('animator.companies.table.headers.bikeDistance') as string,
      //   accessor: 'bikeTotalDistance',
      //   cellStyle: (): { textAlign: string } => ({ textAlign: 'center' }),
      // },
      {
        Header: t('animator.companies.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        maxWidth: 115,
        Cell: ActionCell,
        cellStyle: (): { overflow: string } => ({
          overflow: 'inherit',
        }),
      },
    ]

    if (isAdmin) {
      columnValues.splice(3, 0, {
        Header: t('animator.companies.table.headers.areaName') as string,
        accessor: 'areaName',
        maxWidth: 200,
      })
    }

    return columnValues
  }, [t, isAdmin, ActionCell])

  const formattedData = React.useMemo(() => {
    if (!companiesData || !companiesData.companiesSearch?.length) {
      return []
    }

    return companiesData.companiesSearch.map((company: any) => ({
      ...company,
      loanEndDate: formatMonth(new Date(company.loanEndDate)),
    }))
  }, [companiesData])

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `organisations-archivees-${formatedDate}`

  const [downloadRange, setDownloadRange] = useState<unknown>(null)

  const getCompanies = usePromiseLazyQuery<
    GetCompaniesExportQuery,
    GetCompaniesExportQueryVariables
  >(QUERY_COMPANIES_EXPORT)

  const getExportData = async () => {
    if (downloadRange == null) {
      return
    }

    const { data } = await getCompanies({
      ...searchVariables,
      // @ts-ignore
      loanDate: downloadRange.value,
    })

    return formatCompaniesExport(data)
  }

  const onCloseModal = React.useCallback(() => {
    setCurrModal(initialModalSettings)
  }, [])

  return (
    <React.Fragment>
      <div css={{ minHeight: 380 }}>
        <Table
          columns={columns}
          data={formattedData}
          onSortBy={setOrderBy}
          loading={loading}
        />
      </div>
      <div
        css={{
          marginTop: '41px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={countData?.companiesSearchCount}
        />
        <div
          css={{
            display: 'flex',
            justifySelf: 'flex-end',
            position: 'absolute',
            right: 21,
          }}
        >
          <InputSelect<{ startDate: string; endDate: string }>
            options={rangesOptions}
            isSearchable={false}
            css={{ width: 120, marginRight: 10 }}
            onChange={setDownloadRange}
            maxMenuHeight={100}
          />

          <DownloadCSVButton
            getData={getExportData}
            filename={filename}
            disabled={downloadRange == null}
          />
        </div>
      </div>
      <CompanyDeleteModal
        isOpen={currModal.name === ArchiveTableModal.DELETE}
        refetch={refetch}
        closeModal={onCloseModal}
        company={currModal.company}
      />
      <CompanyUnArchiveModal
        isOpen={currModal.name === ArchiveTableModal.UNARCHIVE}
        refetch={refetch}
        closeModal={onCloseModal}
        company={currModal.company}
      />
    </React.Fragment>
  )
}

export default ArchivedList
