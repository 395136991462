/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ComponentRow } from '..'

import Button from '../../../../../../components/Button'
import Modal from '../../../../../../components/Modal'
import Popup from '../../../../../../components/Popup'
import TextArea from '../../../../../../components/TextArea'

import ReadOnlyAttachments from './ReadOnlyAttachments'

const i18nBikeComponentConditionNamespace =
  'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeComponentsCondition'

interface EditBikePartModalProps {
  isOpen: boolean
  bikeLoanId?: string
  component: ComponentRow
  shouldOpenCameraInput: boolean
  setComponentNote: (component: ComponentRow, value: { note: string }) => void
  closeModal: () => void
  closeAutomaticCameraInput?: () => void
}

const EditBikePartModal: React.FC<EditBikePartModalProps> = ({
  isOpen,
  closeModal,
  bikeLoanId,
  component,
  shouldOpenCameraInput,
  closeAutomaticCameraInput,
  setComponentNote,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [textAreaValue, setTextAreaValue] = React.useState('')

  React.useEffect(() => {
    if (component.note !== textAreaValue) {
      setTextAreaValue(component.note || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component.note])

  const handleModalSubmit = () => {
    if (component.note !== textAreaValue)
      setComponentNote(component, { note: textAreaValue })
    closeModal()
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        title={t(
          `${i18nBikeComponentConditionNamespace}.bikeComponentPartModal.title`,
          { partName: t(`shared.bikeComponentPartLabel.${component.name}`) },
        )}
        onClose={handleModalSubmit}
        closable={false}
        maxWidth={570}
        titleColor={theme.colors.gray2}
        footer={
          <React.Fragment>
            <Button submit type="primary" onClick={handleModalSubmit}>
              {t('forms.button.return')}
            </Button>
          </React.Fragment>
        }
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <TextArea
            label={t(
              `${i18nBikeComponentConditionNamespace}.bikeComponentPartModal.noteLabel`,
            )}
            block
            resizable={false}
            name="partComment"
            value={textAreaValue}
            withError={false}
            rows={5}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setTextAreaValue(e.target.value)
            }
          />
          <ReadOnlyAttachments
            shouldOpenCameraInput={shouldOpenCameraInput}
            closeAutomaticCameraInput={closeAutomaticCameraInput}
            component={component}
            bikeLoanId={bikeLoanId}
          />
        </div>
      </Popup>
    </Modal>
  )
}

export default EditBikePartModal
