import { gql } from '@apollo/client'
import { dateFormatterExport } from '../formatter'

import i18n from '../../i18n/config'
import {
  GetCompanyEmployeesExportQuery,
  SpecialBikesType,
} from '../../__generated__/graphql'

export const QUERY_COMPANY_EMPLOYEES_EXPORT = gql`
  query GetCompanyEmployeesExport($employeeWhereInput: EmployeeWhereInput!) {
    employees(where: $employeeWhereInput) {
      civility
      firstName
      lastName
      phoneNumber
      street
      postalCode
      city
      cityOfBirth
      birthdate
      age
      height

      interest
      order

      identityCardFrontFilePath
      identityCardBackFilePath
      identityCardVerified
      proofOfAddressFilePath
      proofOfAddressVerified
      depositId
      depositByCheque

      user {
        email
      }

      travelMode {
        name
      }
      workTravelDistance
      workTravelDuration

      employeeBikeHabit {
        name
      }

      bikeParkingType {
        name
      }

      specialBikeType
      specialBikeAbsolutelyNeeded
      babySeat

      trainingAppointment {
        date
      }
      loanDeliveryAppointment {
        date
      }
      loanReturnAppointment {
        date
      }

      company {
        name
      }

      bikeLoan {
        bikeReturnedDate
        bikeDeliveredDate
        bike {
          stickerId
        }
      }

      hasMobileApp

      ridesCount
      homeWorkRides
      bikeDaysUsed
      totalDistanceInKm
      totalMoneySaved
      totalEnergySpent
      totalKgCO2Saved

      currentLoanPoints
    }
  }
`
export const formatEmployeesExport = (
  employeeExportData:
    | Pick<GetCompanyEmployeesExportQuery, 'employees'>
    | undefined,
): any => {
  if (!employeeExportData?.employees?.length) return []

  const header = [
    'Civilité',
    'Prenom',
    'Nom',
    'E-mail',
    'Telephone portable',
    'Adresse',
    'Code postal',
    'Ville',
    'Date de naissance',
    'Age',
    'Ville de naissance',
    'Taille (cm)',

    'Mode de transport',
    'Distance DT (km)',
    'Duree DT (min)',
    'Pratique du velo',
    'Stationnement',

    'Type de vélo',
    'Type de demande',
    'Siège enfant',

    'Ordre',

    'Caution',
    'Pièces justificatives',

    "Nom de l'organisation",
    'Numero velo',
    'Date de remise',
    'Heure de remise',
    'Date de formation',
    'Heure de formation',
    'Date de reprise',
    'Heure de reprise',

    'Nombre de trajets DT',
    "Nombre de jour d'utilisation",
    'Distance totale (km)',
    'Distance quotidienne (km/j)',
    'Economie (€)',
    'Effort physique (kCal)',
    'Emissions (kgCO2)',
    'Nombre de points (appli)',
    'Application Telecharger',
  ]

  const formatedData = employeeExportData?.employees
    .sort((a, b) => b.interest - a.interest)
    .map((x, i) => ({
      ...x,
      interest: i + 1,
    }))

  return [
    header,
    ...formatedData.map(employee => ({
      civility: i18n.t(`shared.profile.civility.${employee.civility}`),
      firstName: employee.firstName,
      lastName: employee.lastName,
      email: employee.user.email,
      phoneNumber: employee.phoneNumber,
      street: employee.street,
      postalCode: employee.postalCode,
      city: employee.city,
      birthdate: dateFormatterExport(employee.birthdate, false),
      age: employee.age,
      cityOfBirth: employee.cityOfBirth,
      height: employee.height,

      travelMode: employee?.travelMode?.name
        ? i18n.t(
            `animator.company.employees.subscribed.filter.mode.options.${employee.travelMode.name}`,
          )
        : '',
      workTravelDistance: employee.workTravelDistance,
      workTravelDuration: employee.workTravelDuration,
      employeeBikeHabit: employee?.employeeBikeHabit?.name
        ? i18n.t(
            `shared.employeeBikeHabitLabel.${employee?.employeeBikeHabit?.name}`,
          )
        : '',
      bikeParkingType: employee?.bikeParkingType?.name
        ? i18n.t(
            `animator.company.employees.subscribed.filter.parkingType.options.${employee.bikeParkingType.name}`,
          )
        : '',

      specialBikeType: employee.specialBikeType
        ? {
            [SpecialBikesType.UrbanBike]: 'Urbain',
            [SpecialBikesType.FoldingBike]: 'Pliant',
            [SpecialBikesType.CargoBike]: 'Familial',
          }[employee.specialBikeType]
        : '',
      specialBikeAbsolutelyNeeded:
        employee.specialBikeType === SpecialBikesType.UrbanBike
          ? 'Essentiel'
          : employee.specialBikeAbsolutelyNeeded
          ? 'Essentiel'
          : 'Si possible',

      babySeat: employee.babySeat
        ? i18n.t(`shared.babySeat.short.${employee.babySeat}`)
        : '',

      order: employee.order,

      deposit: employee.depositId || employee.depositByCheque ? 'Oui' : 'Non',
      proofs:
        employee.identityCardVerified && employee.proofOfAddressVerified
          ? 'Oui'
          : employee.identityCardFrontFilePath &&
            employee.proofOfAddressFilePath
          ? 'En attente'
          : 'Non',

      companyName: employee.company?.name,
      bikeId: employee.bikeLoan[0]?.bike?.stickerId,

      // bikeLoanDate: employee.bikeLoan[0]?.bikeDeliveredDate
      //   ? dateFormatterExport(employee.bikeLoan[0]?.bikeDeliveredDate, false)
      //   : dateFormatterExport(employee.loanDeliveryAppointment?.date, false),
      // bikeLoanDateHour: employee.bikeLoan[0]?.bikeDeliveredDate
      //   ? dateFormatterExport(employee.bikeLoan[0]?.bikeDeliveredDate, true, true)
      //   : dateFormatterExport(
      //       employee.loanDeliveryAppointment?.date,
      //       true,
      //       true,
      //     ),

      bikeLoanDate: 'TODO',
      bikeLoanDateHour: 'TODO',

      trainingDate: dateFormatterExport(
        employee.trainingAppointment?.date,
        false,
      ),
      trainingDateHour: dateFormatterExport(
        employee.trainingAppointment?.date,
        true,
        true,
      ),

      // bikeLoanEndDate: employee.bikeLoan[0]?.bikeReturnedDate
      //   ? dateFormatterExport(employee.bikeLoan[0]?.bikeReturnedDate, false)
      //   : dateFormatterExport(employee.loanReturnAppointment?.date, false),
      // bikeLoanEndDateHour: employee.bikeLoan[0]?.bikeReturnedDate
      //   ? dateFormatterExport(employee.bikeLoan[0]?.bikeReturnedDate, true, true)
      //   : dateFormatterExport(employee.loanReturnAppointment?.date, true, true),

      bikeLoanEndDate: 'TODO',
      bikeLoanEndDateHour: 'TODO',

      ridesCount: employee.homeWorkRides,
      bikeDaysUsed: employee.bikeDaysUsed,
      totalDistanceInKm: employee.totalDistanceInKm,

      totalDailyDistance:
        (employee.totalDistanceInKm || 0) / (employee.bikeDaysUsed || 1),

      totalMoneySaved: employee.totalMoneySaved,
      totalEnergySpent: employee.totalEnergySpent,
      totalKgCO2Saved: Math.round(employee.totalKgCO2Saved || 0),
      currentLoanPoints: employee.currentLoanPoints,
      hasMobileApp: employee.hasMobileApp,
    })),
  ]
}
