/* eslint-disable no-console */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment, useMemo, useCallback } from 'react'

import CardInfo from '../../../components/CardInfo'
import Icon, { IconTypes } from '../../../components/Icon'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'emotion-theming'

import { displayAvatar } from '../../../utils/files'

import { AnchorEditCompanyProfileType } from './EditCompanyModal'
import { AnchorEditCompanyParkingType } from './EditCompanyParkingModal'
import { StoreState } from '../../../redux/rootReducer'
import { useSelector } from 'react-redux'
import {
  CompanyReferrer,
  GetCompanyProfileQuery,
} from '../../../__generated__/graphql'

interface ProfilePreviewProps {
  data: GetCompanyProfileQuery
  openProfileModal: (anchor: AnchorEditCompanyProfileType) => void
  openParkingModal: (anchor: AnchorEditCompanyParkingType) => void
  openConfirmEmailModal: () => void
  renderExtra?: (data: GetCompanyProfileQuery) => React.ReactNode
}

function ConfirmEmailButton({
  referrer,
  openConfirmEmailModal,
}: {
  referrer: CompanyReferrer
  openConfirmEmailModal: () => void
}) {
  const handleOnClick = useCallback(() => {
    openConfirmEmailModal()
  }, [openConfirmEmailModal])

  if (referrer.user?.emailVerified) {
    return null
  }

  return (
    <span style={{ marginLeft: 8, cursor: 'pointer' }} onClick={handleOnClick}>
      <Icon type="mailCheck" />
    </span>
  )
}

interface CardsInfo {
  avatar: boolean
  avatarUrl: string
  title: string
  data: {
    icon?: IconTypes
    description: string
    textColor?: string
  }[]
  onEditClicked?: () => void
}

const ProfilePreview: React.FC<ProfilePreviewProps> = ({
  data,
  openProfileModal,
  openParkingModal,
  openConfirmEmailModal,
  renderExtra,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const { id: userId } = useSelector((state: StoreState) => state.user)

  const companyReferrer = data.company?.companyReferrers?.find(
    ref => ref.user.id === userId,
  )

  const nbrEmployeesOnSites = data.company?.sites.reduce(
    (acc, site) => acc + site.employeesOnSite,
    0,
  )

  const extra = renderExtra?.(data) || null

  const cardsInfo: CardsInfo[] = useMemo(
    () =>
      [
        extra
          ? {
              avatar: false,
              avatarUrl: '',
              title: t('company.profile.referee'),
              data: [
                {
                  icon: 'user',
                  description: `${
                    companyReferrer?.firstName
                  } ${companyReferrer?.lastName.toUpperCase()}`,
                },
                {
                  icon: 'envelope',
                  description: companyReferrer?.email || '',
                  right:
                    companyReferrer != null ? (
                      <ConfirmEmailButton
                        referrer={companyReferrer as CompanyReferrer}
                        openConfirmEmailModal={openConfirmEmailModal}
                      />
                    ) : null,
                },
                {
                  icon: 'phone',
                  description: companyReferrer?.phoneNumber || '',
                },
              ],
              onEditClicked: () =>
                openProfileModal(
                  AnchorEditCompanyProfileType.COMPANY_REFEREE_INFO,
                ),
            }
          : {
              avatar: true,
              avatarUrl: displayAvatar(data.company?.avatarUrl),
              title: data?.company?.name || '',
              data: [
                {
                  icon: 'tag',
                  description: data?.company?.subscriptionCode.toString() || '',
                },
                {
                  icon: 'company',
                  description: t(
                    `shared.companyTypeLabel.${data?.company?.companyType}`,
                  ),
                },
                {
                  icon: 'user',
                  description: t('company.profile.employee', {
                    count: nbrEmployeesOnSites,
                  }),
                },
              ],
              onEditClicked: () =>
                openProfileModal(AnchorEditCompanyProfileType.COMPANY_INFO),
            },
      ].filter(c => c != null) as CardsInfo[],
    [
      data,
      t,
      companyReferrer,
      nbrEmployeesOnSites,
      openConfirmEmailModal,
      openProfileModal,
      extra,
    ],
  )

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      {cardsInfo.map((cardInfoData, index) => (
        <Fragment key={index}>
          <div
            css={{
              width: 'calc(50% - 15px)',
              minWidth: '450px',
              marginBottom: 24,
              [theme.media.mobile]: {
                width: '100%',
                minWidth: 'auto',
              },
            }}
          >
            <CardInfo
              avatar={cardInfoData.avatar}
              avatarUrl={cardInfoData.avatarUrl}
              title={cardInfoData.title}
              data={cardInfoData.data}
              onEditClicked={cardInfoData.onEditClicked}
            />
          </div>
          {index === 0 && extra != null ? (
            <div
              css={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridTemplateRows: '1fr',
                gridColumnGap: '15px',

                marginBottom: 24,

                width: 'calc(50% - 15px)',

                [theme.media.mobile]: {
                  width: '100%',
                  minWidth: 'auto',
                },
              }}
            >
              {extra}
            </div>
          ) : null}
        </Fragment>
      ))}
    </div>
  )
}

export default ProfilePreview
