import { useTheme } from 'emotion-theming'

import * as elementVariants from './variants'

export type ElementVariantTypes = keyof typeof elementVariants

export type ElementsConfig =
  | elementVariants.select.SelectVariantsConfig
  | elementVariants.text.TextVariantsConfig

export function useSelectVariant(element: ElementVariantTypes): ElementsConfig {
  const theme = useTheme()

  return elementVariants[element].default(theme)
}

export default useSelectVariant
