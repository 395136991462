import { gql, MutationTuple, useMutation } from '@apollo/client'
import {
  CreateEmployeeDeploymentMutation,
  CreateEmployeeDeploymentMutationVariables,
} from '../../../../__generated__/graphql'

const CREATE_EMPLOYEE_DEPLOYMENT_MUTATION = gql`
  mutation CreateEmployeeDeployment($data: EmployeeDeploymentCreationInput!) {
    createEmployeeDeployment(data: $data) {
      id
    }
  }
`

export const useCreateEmployeeDeployment = (): MutationTuple<
  CreateEmployeeDeploymentMutation,
  CreateEmployeeDeploymentMutationVariables
> => {
  return useMutation(CREATE_EMPLOYEE_DEPLOYMENT_MUTATION, {
    refetchQueries: ['GetDeploymentsAvailables'],
  })
}
