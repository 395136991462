/** @jsx jsx */
import { useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SummaryCard, { SummaryItem } from '../../../components/Card/Summary'
import { StoreState } from '../../../redux/rootReducer'
import { QUERY_GET_EMPLOYEE_SUMMARY } from '../../../utils/gql/queries'
import {
  GetEmployeeSummaryQuery,
  GetEmployeeSummaryQueryVariables,
  SummaryFilter,
} from '../../../__generated__/graphql'

const EmployeeStats: React.FC = () => {
  const { t } = useTranslation()
  const userId = useSelector((state: StoreState) => state.user.id)

  const [summaryFilter, setSummaryFilter] = React.useState<SummaryFilter>(
    SummaryFilter.All,
  )

  const { data, loading } = useQuery<
    GetEmployeeSummaryQuery,
    GetEmployeeSummaryQueryVariables
  >(QUERY_GET_EMPLOYEE_SUMMARY, {
    fetchPolicy: 'no-cache',
    variables: {
      summary: summaryFilter,
      where: { userId },
    },
  })

  const onSelectChange = React.useCallback(
    ({ value }) => {
      setSummaryFilter(value)
    },
    [setSummaryFilter],
  )

  const employeeSummary = useMemo<SummaryItem[]>(
    () => [
      {
        id: 'totalDistance',
        icon: 'road',
        label: t('employee.monitoring.stats.label0', {
          count: Math.round(data?.employee?.totalDistanceInKm || 0),
        }),
        description: t('employee.monitoring.stats.description0', {
          count: Math.round(data?.employee?.totalDistanceInKm || 0),
        }),
      },
      {
        id: 'co2Saved',
        icon: 'leaves',
        label: t('company.monitoring.stats.label1', {
          count: Math.round(data?.employee?.totalKgCO2Saved || 0),
        }),
        description: t('employee.monitoring.stats.description1'),
      },
      {
        id: 'routesDone',
        icon: 'roadPin',
        label: t('employee.monitoring.stats.label2', {
          count: data?.employee?.ridesCount || 0,
        }),
        description: t('employee.monitoring.stats.description2', {
          count: data?.employee?.ridesCount || 0,
        }),
      },
      {
        id: 'energySpent',
        icon: 'burger',
        label: t('employee.monitoring.stats.label3', {
          count: Math.round(data?.employee?.totalEnergySpent || 0),
        }),
        description: t('employee.monitoring.stats.description3', {
          count: Math.round(data?.employee?.totalEnergySpent || 0),
        }),
      },
      {
        id: 'employeeMoneySaved',
        icon: 'bankNotes',
        label: t('employee.monitoring.stats.label4', {
          count: Math.ceil(data?.employee?.totalMoneySaved || 0),
        }),
        description: t('employee.monitoring.stats.description4', {
          count: Math.ceil(data?.employee?.totalMoneySaved || 0),
        }),
      },
      {
        id: 'employeeDayUsed',
        icon: 'calendarAlt',
        label: t('employee.monitoring.stats.label5', {
          count: Math.ceil(data?.employee?.bikeDaysUsed || 0),
        }),
        description: t('employee.monitoring.stats.description5', {
          count: Math.ceil(data?.employee?.bikeDaysUsed || 0),
        }),
      },
    ],
    [t, data],
  )

  return (
    <SummaryCard
      title={t('employee.monitoring.stats.title')}
      itemList={employeeSummary}
      loading={loading}
      onSelectChange={onSelectChange}
      fullWidth
    ></SummaryCard>
  )
}

export default EmployeeStats
