/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Flex } from '../../../../../components/Flex'
import { useTranslation } from 'react-i18next'
import Card from '../../../../../components/Card'
import { LabeledIcon } from '../../../../../components/LabeledIcon'
import { NavigationLink } from '../../../../../components/NavigationLink'
import {
  employeeNameFormatter,
  phoneFormatter,
} from '../../../../../utils/formatter'
import { useCompanyAllReferrer } from '../../hooks/useCompanyReferrers'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../redux/rootReducer'
import EditReferrerModal from '../../../../Shared/Referrer/EditReferrerModal'
import { CompanyReferrerTableModal } from '../../../../../types/CompanyReferrer'

export const CompanyReferrers: React.FC = () => {
  const { t } = useTranslation()
  const companyId = useSelector(
    (state: StoreState) => state.user.companyId,
  ) as string
  const { data } = useCompanyAllReferrer(companyId)
  const [selectedReferrer, setSelectedReferrer] = React.useState<
    any | undefined
  >()

  const onModalClose = () => {
    setSelectedReferrer(undefined)
  }

  const [unpersistedForm, setUnpersistedForm] = React.useState<{
    password: string
    confirmPassword: string
  }>({
    password: '',
    confirmPassword: '',
  })

  return (
    <Flex direction="column" gap="16px">
      <h3 css={{ color: '#565656', fontWeight: 400, fontSize: '16px' }}>
        {t('animator.companies.profile.referrers')}
      </h3>
      {data?.companyAllReferrers?.map(referrer => (
        <Card key={referrer?.id}>
          <Flex direction="row" gap="48px" css={{ color: '#434343' }}>
            <LabeledIcon
              icon="user"
              label={employeeNameFormatter(
                referrer?.firstName,
                referrer?.lastName,
              )}
            />
            <LabeledIcon icon="mail" label={referrer?.email} />
            <LabeledIcon
              icon="phone"
              label={phoneFormatter(referrer?.phoneNumber ?? '')}
            />
            <NavigationLink
              css={{ marginLeft: 'auto' }}
              to="#"
              onClick={event => {
                event.stopPropagation()
                setSelectedReferrer(referrer)
              }}
            >
              {t('shared.edit')}
            </NavigationLink>
          </Flex>
        </Card>
      ))}

      {!!selectedReferrer && (
        <EditReferrerModal
          isOpen={!!selectedReferrer}
          onModalClose={onModalClose}
          referrer={selectedReferrer}
          type={CompanyReferrerTableModal.EDIT}
          refetch={() => {}}
          submitCloseModal={onModalClose}
          unpersistedForm={unpersistedForm}
          setUnpersistedForm={setUnpersistedForm}
        />
      )}
    </Flex>
  )
}
