/** @jsx jsx */
import { useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentDeploymentId } from '../../Animator/Deployment/hooks/useCurrentDeploymentId'
import SummaryCard, { SummaryItem } from '../../../components/Card/Summary'
import { QUERY_ANIMATOR_DEPLOYMENT } from '../../../utils/gql/queries'
import { SummaryFilter } from '../../../__generated__/graphql'

const EmployeeStats: React.FC = () => {
  const { t } = useTranslation()
  const id = useCurrentDeploymentId()
  const [summaryFilter, setSummaryFilter] = React.useState<SummaryFilter>(
    SummaryFilter.All,
  )

  const { data, loading } = useQuery(QUERY_ANIMATOR_DEPLOYMENT, {
    variables: { id: id, summary: summaryFilter },
  })

  const onSelectChange = React.useCallback(
    ({ value }) => {
      setSummaryFilter(value)
    },
    [setSummaryFilter],
  )

  const employeeSummary = useMemo<SummaryItem[]>(
    () => [
      {
        id: 'totalDistance',
        icon: 'road',
        label: t('company.monitoring.stats.label0', {
          count: Math.round(data?.deployment?.employeesTotalDistanceInKm || 0),
        }),
        description: t('company.monitoring.stats.description0', {
          count: Math.round(data?.deployment?.employeesTotalDistanceInKm || 0),
        }),
      },
      {
        id: 'companyMoneySaved',
        icon: 'bankNotes',
        label: t('company.monitoring.stats.label5', {
          count: Math.ceil(data?.deployment?.employeesTotalMoneySaved || 0),
        }),
        description: t('company.monitoring.stats.description5', {
          count: Math.ceil(data?.deployment?.employeesTotalMoneySaved || 0),
        }),
      },

      {
        id: 'routesDone',
        icon: 'roadPin',
        label: t('company.monitoring.stats.label2', {
          count: data?.deployment?.employeesRidesCount || 0,
        }),
        description: t('company.monitoring.stats.description2', {
          count: data?.deployment?.employeesRidesCount || 0,
        }),
      },
      {
        id: 'energySpent',
        icon: 'burger',
        label: t('company.monitoring.stats.label3', {
          count: Math.round(data?.deployment?.employeesTotalEnergySpent || 0),
        }),
        description: t('company.monitoring.stats.description3', {
          count: Math.round(data?.deployment?.employeesTotalEnergySpent || 0),
        }),
      },
      {
        id: 'employeeMoneySaved',
        icon: 'calendarV2',
        label: t('company.monitoring.stats.employeesBikeDayUsed', {
          count: Math.ceil(data?.deployment?.employeesBikeDayUsed || 0),
        }),
        description: t(
          'company.monitoring.stats.employeesBikeDayUsedDescription',
          {
            count: Math.ceil(data?.deployment?.employeesBikeDayUsed || 0),
          },
        ),
      },
      {
        id: 'co2Saved',
        icon: 'leaves',
        label: t('company.monitoring.stats.label1', {
          count: Math.round(data?.deployment?.employeesTotalKgCO2Saved || 0),
        }),
        description: t('company.monitoring.stats.description1', {
          count: Math.round(data?.deployment?.employeesTotalKgCO2Saved || 0),
        }),
      },
    ],
    [t, data],
  )

  return (
    <SummaryCard
      title={t('company.monitoring.stats.title')}
      itemList={employeeSummary}
      loading={loading}
      onSelectChange={onSelectChange}
    />
  )
}

export default EmployeeStats
