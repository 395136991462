/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'

import CardFoldable from '../../../../../components/Card/Foldable'
import InputSelect from '../../../../../components/InputSelect'
import Input from '../../../../../components/Input'
import CardTitle from '../../../../../components/Card/CardTitle'
import List from './List'

import { SelectVariant } from '../../../../../hooks/useVariant/variants/select'
import { TextVariant } from '../../../../../hooks/useVariant/variants/text'
import { IntFilter } from '../../../../../__generated__/graphql'

let debouncedFn: undefined | (() => void)

interface CompaniesRefusedListProps {
  pageSize: number
  departmentsFilterOptions: { value: string; label: string }[]
  staffFilterOptions: { value: string | IntFilter; label: string }[]
}

const CompaniesRefusedList: React.FC<CompaniesRefusedListProps> = ({
  pageSize,
  departmentsFilterOptions,
  staffFilterOptions,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [filters, setFilters] = React.useState({})

  const onSelectChange = React.useCallback(({ value }, { name }) => {
    setFilters(prevState => ({
      ...prevState,
      [name]: value !== 'all' ? value : undefined,
    }))
  }, [])

  const onInputNameChange = React.useCallback(e => {
    /* signal to React not to nullify the event object */
    e.persist()

    if (!debouncedFn) {
      debouncedFn = debounce(() => {
        setFilters(prevState => ({
          ...prevState,
          multiFilter: e.target.value || undefined,
        }))
      }, 300)
    }
    debouncedFn()
  }, [])

  React.useEffect(() => (debouncedFn = undefined), [])

  return (
    <div css={{ marginBottom: '30px' }}>
      <CardFoldable
        isDefaultFolded
        title={
          <CardTitle
            statusColor={theme.colors.redLegend}
            label={t('animator.companies.refusedTitleLabel')}
          />
        }
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            marginTop: '2.1rem',
            marginBottom: '2.1rem',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <div
            css={{
              display: 'flex',
              flex: 1,
              minWidth: '220px',
              paddingRight: '17px',
              width: '100%',
              [theme.media.mobile]: {
                paddingRight: '0px',
              },
            }}
          >
            <InputSelect
              name="department"
              withError={false}
              variant={SelectVariant.white}
              label={t('animator.companies.filter.departments')}
              defaultValue="all"
              onChange={onSelectChange}
              options={departmentsFilterOptions}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flex: 1,
              minWidth: '220px',
              paddingRight: '17px',
              width: '100%',
              [theme.media.mobile]: {
                paddingRight: '0px',
                marginTop: '14px',
              },
            }}
          >
            <InputSelect<string | IntFilter>
              name="staff"
              withError={false}
              variant={SelectVariant.white}
              label={t('animator.companies.filter.staff')}
              defaultValue="all"
              onChange={onSelectChange}
              options={staffFilterOptions}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flex: 2,
              minWidth: '220px',
              [theme.media.tablet]: {
                marginTop: '14px',
              },
            }}
          >
            <Input
              icon="magnifier"
              variant={TextVariant.white}
              name="refusedAutoComplete"
              withError={false}
              onChange={onInputNameChange}
            />
          </div>
        </div>
        <List filters={filters} pageSize={pageSize} />
      </CardFoldable>
    </div>
  )
}

export default CompaniesRefusedList
