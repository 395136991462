/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useCurrentDeploymentId } from '../hooks/useCurrentDeploymentId'
import DownloadDeploymentModal from './DownloadArchivedDeploymentModal'
import { useModal } from '../../../../hooks/useModal'

export interface DownloadArchivedDeploymentButtonProps {
  onCompleted?: () => void
  id?: string
  companyId: string
}

export const DownloadArchivedDeploymentButton: React.FC<
  DownloadArchivedDeploymentButtonProps
> = ({ onCompleted, id, companyId }) => {
  const defaultId = useCurrentDeploymentId()
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <DownloadDeploymentModal
        onCompleted={onCompleted}
        id={id ?? defaultId}
        companyId={companyId}
        {...modalProps}
      />
      <button onClick={onOpen}>
        <Icon type="download" />
      </button>
    </Fragment>
  )
}
