/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { Controller, useForm } from 'react-hook-form'

import Modal from '../../components/Modal'
import Popup from '../../components/Popup'
import EditAvatar from './EditAvatar'
import Button from '../../components/Button'
import Input from '../../components/Input'
import DateTime from '../../components/DateTime'

import {
  NotificationTypes,
  useNotifications,
} from '../../contexts/NotificationContext'

import i18n from '../../i18n/config'
import apiErrorCatcher from '../../utils/apiErrorCatcher'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserName } from '../../redux/user'
import { MUTATION_UPDATE_ONE_EMPLOYEE } from '../../utils/gql/mutations'

import { useTheme } from 'emotion-theming'
import { employeeNameFormatter } from '../../utils/formatter'
import Checkbox from '../../components/Checkbox'
import InputSelect from '../../components/InputSelect'
import { countiesList } from '../../utils/countries'
import { EmployeeCivility, UserRoles } from '@goodwatt/shared/dist/types'
import { StoreState } from '../../redux/rootReducer'
import {
  Civility,
  Employee,
  EmployeeUpdateInput,
  GetEmployeeQuery,
  Role,
  UpdateOneEmployeeMutation,
  UpdateOneEmployeeMutationVariables,
} from '../../__generated__/graphql'

// Schema used by admin/animators
const schemaForAdminsAndAnimators = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  street: yup.string(),
  civility: yup.string(),
  country: yup.object().shape({
    value: yup.string().nullable(),
  }),
  postalCode: yup
    .string()
    .matches(/^(\d{5})?$/, i18n.t('forms.errors.wrongFormat')),
  city: yup.string(),
  phoneNumber: yup
    .string()
    .matches(/^(\d{10})?$/, i18n.t('forms.errors.phoneFormat')),
  birthdate: yup
    .date()
    .nullable()
    .min(new Date(1930, 0, 1), i18n.t('forms.errors.birthdateTooOld'))
    .max(new Date(2010, 0, 1), i18n.t('forms.errors.birthdateTooRecent'))
    .typeError(i18n.t('forms.errors.required')),
  cityOfBirth: yup.string(),
  height: yup
    .string()
    .matches(/^(\d{3})?$/, i18n.t('forms.errors.wrongFormat')),
})

// Schema used by everyone else
const schema = yup.object().shape({
  firstName: yup.string().required(i18n.t('forms.errors.required')),
  lastName: yup.string().required(i18n.t('forms.errors.required')),
  street: yup.string().required(i18n.t('forms.errors.required')),
  civility: yup.string().required(i18n.t('forms.errors.required')),
  country: yup.object().shape({
    value: yup
      .string()
      .required(i18n.t('forms.errors.required'))
      .typeError(i18n.t('forms.errors.required')),
  }),
  postalCode: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .length(5, i18n.t('forms.errors.wrongFormat')),
  city: yup.string().required(i18n.t('forms.errors.required')),
  phoneNumber: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .length(10, i18n.t('forms.errors.phoneFormat')),
  birthdate: yup
    .date()
    .required(i18n.t('forms.errors.required'))
    .min(new Date(1930, 0, 1), i18n.t('forms.errors.birthdateTooOld'))
    .max(new Date(2010, 0, 1), i18n.t('forms.errors.birthdateTooRecent'))
    .typeError(i18n.t('forms.errors.required')),
  cityOfBirth: yup.string().required(i18n.t('forms.errors.required')),
  height: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .length(3, i18n.t('forms.errors.wrongFormat')),
})

interface EditEmployeeInfoForm {
  firstName: string
  lastName: string
  country: {
    label: string
    value: string
  }
  civility: Civility
  street: string
  postalCode: string
  city: string
  phoneNumber: string
  birthdate: Date
  cityOfBirth: string
  height: string
}

export enum AnchorEditEmployeeProfileType {
  USER_INFO = 'userInfo',
  USER_CONTACT = 'userContact',
  USER_ADDRESS = 'userAddress',
  USER_BIRTH = 'userBirth',
}

const scrollIntoViewSettings: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'start',
}

interface EditEmployeeProfileProps {
  employee: GetEmployeeQuery['employee'] | null
  onClose: () => void
  submitCloseModal: () => void
  refetch?: (newData?: Partial<Employee>) => void
  isOpen?: boolean
  ownEmployeeInfo?: boolean
  anchorOnOpen?: AnchorEditEmployeeProfileType
  onBoardingMode?: boolean
}

const EditEmployeeProfile: React.FC<EditEmployeeProfileProps> = ({
  employee,
  onClose,
  submitCloseModal,
  refetch,
  isOpen = true,
  ownEmployeeInfo = false,
  anchorOnOpen,
  onBoardingMode = false,
}) => {
  /* ANCHORS FOR EDIT MODAL */
  const userInfoRef = React.useRef<HTMLDivElement>(null)
  const userContactRef = React.useRef<HTMLDivElement>(null)
  const userAddressRef = React.useRef<HTMLDivElement>(null)
  const userBirthRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (isOpen) {
      if (anchorOnOpen === AnchorEditEmployeeProfileType.USER_INFO) {
        userInfoRef?.current?.scrollIntoView(scrollIntoViewSettings)
      } else if (anchorOnOpen === AnchorEditEmployeeProfileType.USER_CONTACT) {
        userContactRef?.current?.scrollIntoView(scrollIntoViewSettings)
      } else if (anchorOnOpen === AnchorEditEmployeeProfileType.USER_BIRTH) {
        userBirthRef.current?.scrollIntoView(scrollIntoViewSettings)
      } else if (anchorOnOpen === AnchorEditEmployeeProfileType.USER_ADDRESS) {
        userAddressRef.current?.scrollIntoView(scrollIntoViewSettings)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notifications = useNotifications()

  const { role: userRole } = useSelector((state: StoreState) => state.user)
  const isAdminOrAnimator =
    userRole && [UserRoles.ADMIN, UserRoles.ANIMATOR].includes(userRole)

  const { register, handleSubmit, errors, control, setError } = useForm({
    resolver: yupResolver(
      // Admins and animators are not required to fill all the form, others are
      isAdminOrAnimator ? schemaForAdminsAndAnimators : schema,
    ),
    mode: 'onBlur',
  })
  const [updateEmployee, { loading }] = useMutation<
    UpdateOneEmployeeMutation,
    UpdateOneEmployeeMutationVariables
  >(MUTATION_UPDATE_ONE_EMPLOYEE, {
    refetchQueries: ['GetDeploymentEmployees'],
  })

  const onSubmit = (inputData: EditEmployeeInfoForm) => {
    const data: EmployeeUpdateInput = {}

    // Since not all the inputs are required, only forward those that have been
    // correctly inputed.

    if (inputData.firstName && inputData.firstName !== '') {
      data.firstName = { set: inputData.firstName }
    }

    if (inputData.lastName && inputData.lastName !== '') {
      data.lastName = { set: inputData.lastName }
    }

    if (inputData.phoneNumber && inputData.phoneNumber !== '') {
      data.phoneNumber = { set: inputData.phoneNumber }
    }

    if (inputData.country && inputData.country.value) {
      data.country = { set: inputData.country.value }
    }

    if (inputData.civility) {
      data.civility = { set: inputData.civility }
    }

    if (inputData.street && inputData.street !== '') {
      data.street = { set: inputData.street }
    }

    if (inputData.postalCode && inputData.postalCode !== '') {
      data.postalCode = { set: inputData.postalCode }
    }

    if (inputData.city && inputData.city !== '') {
      data.city = { set: inputData.city }
    }

    if (inputData.birthdate) {
      inputData.birthdate.setHours(12)
      data.birthdate = { set: inputData.birthdate }
    }

    if (inputData.cityOfBirth && inputData.cityOfBirth !== '') {
      data.cityOfBirth = { set: inputData.cityOfBirth }
    }

    if (inputData.height && inputData.height !== '') {
      data.height = { set: parseInt(inputData.height) }
    }

    updateEmployee({
      variables: {
        where: { id: employee?.id },
        data,
      },
    })
      .then(result => {
        if (result.data?.updateOneEmployee?.id) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: i18n.t('shared.notification.profileSuccessUpdate'),
          })
          if (ownEmployeeInfo) {
            dispatch(
              updateUserName({
                name: `${inputData.firstName} ${inputData.lastName}`,
              }),
            )
          }
          refetch?.()
          submitCloseModal()
        }
      })
      .catch(_ => {
        setError('birthdate', {
          message: i18n.t('forms.errors.wrongFormat'),
        })
        apiErrorCatcher(notifications)
      })
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        maxWidth={750}
        title={t('employee.profile.editModalTitle', {
          name: employeeNameFormatter(employee?.firstName, employee?.lastName),
        })}
        onClose={onClose}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {onBoardingMode ? (
              <Button
                type="tertiary"
                onClick={onClose}
                icon="back"
                iconColor={theme.colors.blue1}
              />
            ) : (
              <Button type="tertiary" onClick={onClose}>
                {t('forms.button.cancel')}
              </Button>
            )}

            <div css={{ marginRight: 40 }} />
            <Button
              loading={loading}
              type="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {onBoardingMode ? t('forms.button.next') : t('forms.button.save')}
            </Button>
          </div>
        }
      >
        <div ref={userInfoRef} />
        <EditAvatar
          resourceId={employee?.id || ''}
          role={Role.Employee}
          refetch={refetch}
          avatarUrl={employee?.avatarUrl || undefined}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            css={{
              width: '100%',
              padding: '0 12px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              [theme.media.mobile]: {
                padding: '0',
              },
            }}
          >
            <Input
              type="text"
              name="firstName"
              defaultValue={employee?.firstName}
              label={t('forms.label.firstName')}
              register={register}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />

            <Input
              type="text"
              name="lastName"
              defaultValue={employee?.lastName}
              label={t('forms.label.lastName')}
              register={register}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />

            <span
              style={{
                marginLeft: 21,
                marginBottom: 12,
                fontSize: '1.6rem',
                color: '#828282',
                alignSelf: 'flex-start',
              }}
            >
              Civilité
            </span>

            <Controller
              name="civility"
              control={control}
              defaultValue={employee?.civility}
              render={({ onChange, value }) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    gap: 48,
                    marginLeft: 21,
                  }}
                >
                  <Checkbox
                    isChecked={value === EmployeeCivility.MISTER}
                    label="Monsieur"
                    size="medium"
                    onClick={() => onChange(EmployeeCivility.MISTER)}
                  ></Checkbox>
                  <Checkbox
                    isChecked={value === EmployeeCivility.MISTRESS}
                    label="Madame"
                    size="medium"
                    onClick={() => onChange(EmployeeCivility.MISTRESS)}
                  ></Checkbox>
                </div>
              )}
            />

            <div css={{ marginBottom: 48 }} />
            <div ref={userContactRef} />
            <Input
              type="text"
              name="email"
              defaultValue={employee?.user.email}
              disabled
              label={t('forms.label.email')}
            />
            <Input
              type="text"
              name="phoneNumber"
              label={t('forms.label.phoneNumber')}
              defaultValue={employee?.phoneNumber}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber?.message}
              register={register}
              onlyNumbers
              maxLength={10}
            />
            <div css={{ marginBottom: 24 }} />
            <div ref={userAddressRef} />
            <Input
              type="text"
              name="street"
              defaultValue={employee?.street || undefined}
              label={t('forms.label.address')}
              error={!!errors.street}
              helperText={errors.street?.message}
              register={register}
            />
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                [theme.media.mobile]: {
                  flexDirection: 'column',
                },
              }}
            >
              <div
                css={{
                  flex: 2,
                  marginRight: 15,
                  [theme.media.mobile]: {
                    marginRight: 0,
                  },
                }}
              >
                <Input
                  type="text"
                  name="postalCode"
                  label={t('forms.label.postalCode')}
                  defaultValue={employee?.postalCode || undefined}
                  error={!!errors.postalCode}
                  helperText={errors.postalCode?.message}
                  onlyNumbers
                  maxLength={5}
                  register={register}
                />
              </div>
              <div css={{ flex: 3 }}>
                <Input
                  type="text"
                  name="city"
                  label={t('forms.label.city')}
                  defaultValue={employee?.city || undefined}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  register={register}
                />
              </div>
            </div>
            <Controller
              name="country"
              control={control}
              defaultValue={{
                label: employee?.country,
                value: employee?.country,
              }}
              render={({ onChange, onBlur, value, name }) => (
                <InputSelect
                  options={countiesList.map(x => ({
                    label: x,
                    value: x,
                  }))}
                  type="text"
                  name={name}
                  onBlur={onBlur}
                  value={value}
                  defaultValue={employee?.country || undefined}
                  label={t('forms.label.country')}
                  error={!!errors.country?.value}
                  helperText={errors.country?.value?.message}
                  register={register}
                  onChange={onChange}
                />
              )}
            />

            <div css={{ marginBottom: '24px' }} />
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                flexWrap: 'wrap',
                [theme.media.mobile]: {
                  flexDirection: 'column',
                },
              }}
            >
              <div
                ref={userBirthRef}
                css={{
                  flex: 2,
                  marginRight: 15,
                  [theme.media.mobile]: {
                    marginRight: 0,
                  },
                }}
              >
                <Controller
                  name="birthdate"
                  control={control}
                  defaultValue={
                    employee?.birthdate ? new Date(employee?.birthdate) : null
                  }
                  render={({ onChange, value }) => {
                    return (
                      <DateTime
                        placeholder={t('forms.label.placeholderBirthdate')}
                        label={t('forms.label.birthdate')}
                        block={false}
                        value={value}
                        onChange={onChange}
                        type="date"
                        icon={null}
                        disableDatePicker
                        error={!!errors.birthdate}
                        helperText={errors.birthdate?.message}
                      />
                    )
                  }}
                />
              </div>
              <div css={{ flex: 3 }}>
                <Input
                  type="text"
                  name="cityOfBirth"
                  label={t('forms.label.cityOfBirth')}
                  defaultValue={employee?.cityOfBirth || undefined}
                  error={!!errors.cityOfBirth}
                  helperText={errors.cityOfBirth?.message}
                  register={register}
                />
              </div>
            </div>
            <Input
              type="text"
              name="height"
              label={t('forms.label.height')}
              placeholder={t('forms.label.heightPlaceholder')}
              description={t('forms.label.heightDescription')}
              defaultValue={(employee?.height || '').toString()}
              error={!!errors.height}
              helperText={errors.height?.message}
              register={register}
              onlyNumbers
              maxLength={3}
            />
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default EditEmployeeProfile
