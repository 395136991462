/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { Row } from 'react-table'
import React from 'react'

import Icon from '../../Icon'

import {
  AreaModalSettings,
  AreaTableModal,
} from '../../../types/admin/AdminArea'
import { AreaSearch } from '../../../__generated__/graphql'

interface BikesActionCellProps {
  setModal: (obj: AreaModalSettings<AreaSearch, AreaTableModal>) => void
  row: Row<AreaSearch>
}

const AreaActionCell: React.FC<BikesActionCellProps> = ({ setModal, row }) => {
  const theme = useTheme()
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: AreaTableModal,
    ) => {
      e.stopPropagation()
      setModal({ area: row.original, actionName })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <div
        css={{ cursor: 'pointer', marginLeft: 8 }}
        onClick={e => handleClick(e, AreaTableModal.EDIT)}
      >
        <Icon type="edit" color={theme.colors.gray2} />
      </div>
      <div
        css={{ cursor: 'pointer', marginLeft: 8 }}
        onClick={e => handleClick(e, AreaTableModal.DELETE)}
      >
        <Icon type="trash" color={theme.colors.gray2} />
      </div>
    </div>
  )
}

export default AreaActionCell
