/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import { Flex } from '../../../components/Flex'
import theme from '../../../styles/theme'
import Icon, { IconTypes } from '../../../components/Icon'
import Card from '../../../components/Card'
import { NavLink, useParams } from 'react-router-dom'

export interface EmployeeProps {
  candidates: number
  beneficiaries: number
}

const CompanyEmployees: React.FC<EmployeeProps> = ({
  candidates,
  beneficiaries,
}) => {
  const { t } = useTranslation()
  const { deploymentId } = useParams<{ deploymentId: string }>()

  return (
    <Card
      css={{
        marginBottom: 0,
        [theme.media.desktop]: {
          marginBottom: 0,
        },
        flex: '1',
      }}
      title={
        <span
          css={{
            fontSize: '1.6rem',
            color: theme.colors.gray2,
            fontWeight: 'bold',
            fontFamily: theme.fontFamily,
            marginBottom: '0.7rem',
          }}
        >
          {t('company.card.employees')}
        </span>
      }
    >
      <Flex gap="50px">
        <DataCell
          icon="people"
          value={`${candidates} ${t('shared.resources.employee_plural')}`}
          label={t('shared.resources.candidate_plural')}
        />
        <DataCell
          icon="bike"
          value={`${beneficiaries} ${t('shared.resources.employee_plural')}`}
          label={t('shared.resources.beneficiary_plural')}
        />
      </Flex>
      <p
        css={{
          marginTop: '1rem',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: '1.6rem',
        }}
      >
        <NavLink
          css={{
            color: theme.colors.primary,
            textDecoration: 'underline',
          }}
          to={`/company/dashboard/${deploymentId}/employees`}
        >
          {t('company.card.moreDetailsOnEmployees')}
        </NavLink>
      </p>
    </Card>
  )
}

interface DataCellProps {
  icon: IconTypes
  value: React.ReactNode
  label: React.ReactNode
}

const DataCell: React.FC<DataCellProps> = ({ icon, label, value }) => {
  return (
    <Flex direction="row" gap="16px" align="center">
      <Icon type={icon} size="large" color={theme.colors.primary} />
      <Flex direction="column">
        <p>{value}</p>
        <p css={{ fontSize: '14px', color: theme.colors.gray3 }}>{label}</p>
      </Flex>
    </Flex>
  )
}

export default CompanyEmployees
