import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import { MUTATION_DELETE_COMPANY } from '../../../../utils/gql/mutations'
import {
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables,
} from '../../../../__generated__/graphql'

export const useDeleteCompany = (
  options?: MutationHookOptions<
    DeleteCompanyMutation,
    DeleteCompanyMutationVariables
  >,
): MutationTuple<DeleteCompanyMutation, DeleteCompanyMutationVariables> => {
  return useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(
    MUTATION_DELETE_COMPANY,
    {
      refetchQueries: ['companiesWithDeployments'],
      ...options,
    },
  )
}
