/** @jsx jsx */
import { jsx } from '@emotion/core'

import EmployeeRidesDiagram from './EmployeeRidesDiagram'
import EmployeeStats from './EmployeeStats'
import LastEmployeeRides from './LastEmployeeRides'
import CompanyEmployees from './Employees'
import { Flex } from '../../../components/Flex'
import EmployeeInfoCard from './EmployeeAppointment'
import { gql, useQuery } from '@apollo/client'
import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
  DeploymentType,
  GetCompanyDeploymentQuery,
  GetCompanyDeploymentQueryVariables,
} from '../../../__generated__/graphql'
import { setSubMenuTitle } from '../../../redux/menu'

import Icon from '../../../components/Icon'
import { DeploymentTypeIcon } from '../../Animator/Shared/DeploymentTypeIcon'

const QUERY_COMPANY_DEPLOYMENT = gql`
  query GetCompanyDeployment($id: String!) {
    deployment(id: $id) {
      name
      deploymentType
      candidateCount
      beneficiaryCount
      site {
        name
      }
    }
  }
`

const CompanyLoanMonitoring: React.FC = () => {
  const { deploymentId } = useParams<{ deploymentId: string }>()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { data } = useQuery<
    GetCompanyDeploymentQuery,
    GetCompanyDeploymentQueryVariables
  >(QUERY_COMPANY_DEPLOYMENT, {
    variables: { id: deploymentId },
  })

  useEffect(() => {
    if (data?.deployment?.name) dispatch(setSubMenuTitle(data.deployment.name))
  }, [data, dispatch])

  return (
    <Fragment>
      <Flex
        align="center"
        gap="1.2rem"
        css={{
          marginBottom: '1.2rem',
        }}
      >
        <Flex align="center" gap=".5rem">
          <h1
            css={{
              fontFamily: 'Raleway',
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '20px',
              textAlign: 'left',
              color: '#3CD9F1',
            }}
          >
            {t('company.monitoring.title', {
              address: data?.deployment?.site.name,
              name: data?.deployment?.name,
            })}
          </h1>
          <DeploymentTypeIcon
            type={data?.deployment?.deploymentType as DeploymentType}
          />
        </Flex>
        <Link to="/company/dashboard">
          <Icon type="quit" />
        </Link>
      </Flex>
      <Flex direction="row" gap="26px" flexWrap="wrap" flex="1">
        <Flex css={{ width: '100%', gap: 26 }}>
          <Flex direction="column" gap="26px" css={{ width: '50%' }}>
            <EmployeeInfoCard />

            <CompanyEmployees
              candidates={data?.deployment?.candidateCount ?? 0}
              beneficiaries={data?.deployment?.beneficiaryCount ?? 0}
            />
          </Flex>

          <EmployeeStats />
        </Flex>

        <Flex css={{ width: '100%' }}>
          <EmployeeRidesDiagram />
          <LastEmployeeRides />
        </Flex>
      </Flex>
    </Fragment>
  )
}

export default CompanyLoanMonitoring
