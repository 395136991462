import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface MenuState {
  menuTitle: string
  subMenuTitle: string
  subSubMenuTitle: string
}

const initialState: MenuState = {
  menuTitle: '',
  subMenuTitle: '',
  subSubMenuTitle: '',
}

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuTitle(state: MenuState, action: PayloadAction<string>) {
      state.menuTitle = action.payload
      state.subSubMenuTitle = ''
    },
    setSubMenuTitle(state: MenuState, action: PayloadAction<string>) {
      state.subMenuTitle = action.payload
    },
    setSubSubMenuTitle(state: MenuState, action: PayloadAction<string>) {
      state.subSubMenuTitle = action.payload
    },
  },
})

export const { setMenuTitle, setSubMenuTitle, setSubSubMenuTitle } =
  menuSlice.actions

export default menuSlice.reducer
