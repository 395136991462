/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useModal } from '../../../../hooks/useModal'
import ArchiveCompanyModal from './ArchiveCompanyModal'

export interface ArchiveCompanyButtonProps {
  id: string
  disabled?: boolean
  companyName: string
}

export const ArchiveCompanyButton: React.FC<ArchiveCompanyButtonProps> = ({
  id,
  disabled,
  companyName,
}: ArchiveCompanyButtonProps) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <ArchiveCompanyModal id={id} companyName={companyName} {...modalProps} />
      <button
        onClick={onOpen}
        disabled={disabled}
        css={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <Icon
          color="transparent"
          strokeColor={disabled ? 'grey' : 'white'}
          type="archive"
        />
      </button>
    </Fragment>
  )
}
