/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'

export interface PaginationProps {
  currentPage: number
  totalData: number | undefined | null
  pageSize: number
  onClick: (pageClicked: number) => void
  alwaysShowLastPage?: boolean
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalData = 0,
  pageSize,
  onClick,
  alwaysShowLastPage = true,
}) => {
  const theme = useTheme()

  const totalPages = React.useMemo(() => {
    if (pageSize <= 0) {
      // eslint-disable-next-line no-console
      console.error(
        'PageSize should always be positive to prevent division by 0',
      )
      return 1
    }
    if (!totalData) {
      return 1
    }

    return Math.ceil(totalData / pageSize)
  }, [totalData, pageSize])

  const iterableFromTotalPages = React.useMemo(() => {
    const generateArray = Array.from(Array(totalPages).keys())

    if (totalPages < 10) {
      return generateArray.slice(0, 9)
    } else if (currentPage <= 5) {
      if (!alwaysShowLastPage) {
        return generateArray.slice(0, 9)
      }
      // -1 is for "..." display
      return [...generateArray.slice(0, 7), -1, totalPages - 1]
    } else if (currentPage < totalPages - 4) {
      if (!alwaysShowLastPage) {
        return generateArray.slice(currentPage - 5, currentPage + 4)
      }
      return [
        0,
        -1,
        ...generateArray.slice(currentPage - 3, currentPage + 2),
        -1,
        totalPages - 1,
      ]
    } else {
      if (!alwaysShowLastPage) {
        return generateArray.slice(totalPages - 9)
      }
      return [0, -1, ...generateArray.slice(totalPages - 7)]
    }
  }, [totalPages, currentPage, alwaysShowLastPage])

  React.useEffect(() => {
    if (currentPage > totalPages) onClick(totalPages)
  }, [totalPages, currentPage, onClick])

  return (
    <div
      css={{
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        background: theme.colors.gray6,
        borderRadius: '2.2rem',
      }}
    >
      {iterableFromTotalPages.map((n, index) => {
        const isCurrent = n + 1 === currentPage

        return (
          <div
            css={{
              zIndex: isCurrent ? 1 : 0,
              marginLeft: '-0.3rem',
              marginRight: '-0.3rem',
              cursor: n === -1 ? 'default' : 'pointer',
              width: '4.4rem',
              height: '4.4rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isCurrent
                ? theme.colors.primary
                : theme.colors.gray6,
              borderRadius: '50%',
            }}
            onClick={n === -1 ? () => null : () => onClick(n + 1)}
            key={`Value-${n}Index-${index}`}
          >
            <span
              css={{
                fontSize: '1.6rem',
                color: isCurrent ? theme.colors.white : theme.colors.gray3,
              }}
            >
              {n === -1 ? '...' : n + 1}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default Pagination
