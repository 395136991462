/** @jsx jsx */
import { useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StoreState } from '../../../redux/rootReducer'
import { QUERY_GET_COMPANY_SITES } from '../../../utils/gql/queries'

import { useTheme } from 'emotion-theming'

import {
  GetCompanySitesQuery,
  GetCompanySitesQueryVariables,
} from '../../../__generated__/graphql'
import CardFoldable from '../../../components/Card/Foldable'
import CardTitle from '../../../components/Card/CardTitle'
import { SiteDeploymentTable } from './SiteDeploymentTable'
import Icon from '../../../components/Icon'

import { CreateSiteModal } from '../../../components/Layout/Menu/CreateSiteModal'

const Dashboard: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  const companyId = useSelector(
    (state: StoreState) => state.user.companyId,
  ) as string

  const [selectedSite, setSelectedSite] = React.useState<string | undefined>()

  const { data, loading } = useQuery<
    GetCompanySitesQuery,
    GetCompanySitesQueryVariables
  >(QUERY_GET_COMPANY_SITES, {
    variables: {
      where: {
        companyId: { equals: companyId },
      },
    },
  })

  const onModalClose = () => setSelectedSite(undefined)

  if (loading)
    return (
      <div
        css={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icon type="loader" size="large" strokeColor={theme.colors.primary} />
      </div>
    )

  if (!data?.sites?.length)
    return (
      <p css={{ fontWeight: 'bold', color: theme.colors.redLegend }}>
        {t('forms.errors.companyHasNoSite')}
      </p>
    )

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: 26,
        width: '100%',
      }}
    >
      {data.sites.map((site, index) => (
        <CardFoldable
          key={site.id}
          title={
            <div
              css={{
                display: 'flex',
                justifyItems: 'center',
                gap: 20,
                width: '100%',
              }}
            >
              <CardTitle label={site.name} />
              <div
                css={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: theme.colors.blue1,
                }}
                onClick={e => {
                  e.stopPropagation()
                  setSelectedSite(site.id)
                }}
              >
                <div>{t('company.dashboard.updateSite')}</div>
              </div>
            </div>
          }
          isDefaultFolded={index !== 0}
        >
          <SiteDeploymentTable siteId={site.id} theme={theme} t={t} />
        </CardFoldable>
      ))}

      {!!selectedSite && (
        <CreateSiteModal
          siteId={selectedSite}
          type="update"
          isOpen={!!selectedSite}
          onModalClose={onModalClose}
        />
      )}
    </div>
  )
}

export default Dashboard
