import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useModal } from '../../../../hooks/useModal'
import { ReturnPackageModal } from './ReturnPackageModal'
import {
  Employee,
  EmployeeDeployment,
  EmployeeLoanActions,
} from '../../../../__generated__/graphql'
import { ReturnBikeModal } from './ReturnBikeModal'

export interface ReturnPackageButtonProps {
  employeeDeployment: Pick<EmployeeDeployment, 'id' | 'loanPossibleAction'> & {
    employee: Pick<Employee, 'id' | 'firstName' | 'lastName'>
  }
}

export const ReturnPackageButton: React.FC<ReturnPackageButtonProps> = ({
  employeeDeployment: { loanPossibleAction, ...employeeDeployment },
}) => {
  const { onOpen, modalProps } = useModal()

  let modal: React.ReactElement | undefined

  switch (loanPossibleAction) {
    case EmployeeLoanActions.CanReturnPackage:
      modal = (
        <ReturnPackageModal
          {...modalProps}
          employeeDeploymentId={employeeDeployment.id}
        />
      )
      break
    case EmployeeLoanActions.CanReturnBike:
      modal = (
        <ReturnBikeModal
          {...modalProps}
          employeeDeployment={employeeDeployment}
        />
      )
      break
  }

  return modal ? (
    <Fragment>
      {modal}
      <button onClick={onOpen}>
        {loanPossibleAction === EmployeeLoanActions.CanReturnPackage ? (
          <Icon color="transparent" type="returnPackage" />
        ) : (
          <Icon color="orange" type="returnOfABike" />
        )}
      </button>
    </Fragment>
  ) : null
}
