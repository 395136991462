/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Column, Row } from 'react-table'
import { ProgramStepType } from '@goodwatt/shared/dist/types'

import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '../../../../../components/Table'
import WithdrawalModal from './WithdrawalModal'
import SelectedEmployeeActionsCell from '../../../../../components/Table/CustomCells/CompanySelectedEmployeeActionsCell'
import EditEmployeeProfileModal from '../../../../Shared/EditEmployeeModal'

import {
  CompanyEmployeesModalSettings,
  CompanySelectedEmployeesTableModal,
  CompanySelectedEmployeesTableRow,
} from '../../../../../types/AnimatorCompanyEmployees'
import SendMailButton from '../../../../../components/Button/SendMail'
import DownloadCSVButton from '../../../../../components/Button/DownloadCSVButton'
import { useTheme } from 'emotion-theming'
import EmployeeDocumentCell from '../../../../../components/Table/CustomCells/EmployeeDocumentCell'
import EmployeeDocumentsModal from './DocumentsModal/'
import ReturnBikeModal from './ReturnBikeModal'
import ReturnPackageModal from './ReturnPackageModal'

import {
  EmployeeTableRefetchContext,
  EmployeeTableTypes,
} from '../EmployeeTableRefetchContext'
import DepositRetentionModal from './DepositRetentionModal'

import {
  employeeNameFormatter,
  dateFormatter,
} from '@goodwatt/client/src/utils/formatter'

import {
  QUERY_COMPANY_EMPLOYEES_EXPORT,
  formatEmployeesExport,
} from '@goodwatt/client/src/utils/gql/employeesExport'
import ReleaseDepositButton from './ReleaseDeposits/Button'
import ReleaseDepositsConfirmationModal from './ReleaseDeposits/Modal'
import { computeRetentionAmount } from './DepositRetentionModal/helpers'
import usePromiseLazyQuery from '../../../../../hooks/usePromiseLazyQuery'

import {
  AppointmentCategory,
  Employee,
  GetCompanyEmployeesExportQuery,
  GetCompanyEmployeesExportQueryVariables,
  GetSelectedCompanyEmployeesQuery,
  GetSelectedCompanyEmployeesQueryVariables,
  SelectedCompanyEmployee,
  SelectedCompanyEmployeesColumnOrdered,
} from '../../../../../__generated__/graphql'

const QUERY_COMPANY_SELECTED_EMPLOYEES = gql`
  query GetSelectedCompanyEmployees(
    $companyId: String!
    $dateFilter: DateTime
    $multiFilter: String
    $distanceFilter: DistanceFilterInput
    $orderBy: SelectedCompanyEmployeesOrderByInput
  ) {
    selectedCompanyEmployees(
      companyId: $companyId
      dateFilter: $dateFilter
      multiFilter: $multiFilter
      distanceFilter: $distanceFilter
      orderBy: $orderBy
      hasBike: true
    ) {
      id
      firstName
      lastName
      babySeat
      email
      points
      bikeStickerId
      ridesTotalDistance
      homeWorkRides
      daysWithRide
      loanPossibleAction
      identityCardFrontFilePath
      identityCardBackFilePath
      identityCardVerified
      proofOfAddressFilePath
      proofOfAddressVerified
      depositId
      depositExpirationDate
      depositByCheque
      depositReleasedAt
      bikeReturned
      retentionOnDeposit

      bikeLoan {
        bikeReturnedDate
        bikeDeliveredDate
      }

      absences {
        id
        category
      }

      bikeComponentHistories {
        estimatedRetention
      }

      loanPackageHistories {
        estimatedRetention
      }

      trainingAppointment {
        id
        date
        location
      }
      loanDeliveryAppointment {
        id
        date
        location
      }
      loanReturnAppointment {
        id
        date
        location
      }
    }
  }
`

const initialModalSettings: CompanyEmployeesModalSettings = {
  employee: null,
  actionName: '',
}

interface SelectedCompanyEmployeesListProps {
  filters: {
    temporality: string | null
    distance: any | null
    multiFilter: string | null
  }
  loanEventsFormatted: {
    loanReturnDate: string
    loanDeliveryDate: string
  }
  currentProgramStep: ProgramStepType
}

const SelectedCompanyEmployeesList: React.FC<
  SelectedCompanyEmployeesListProps
> = ({ filters, loanEventsFormatted, currentProgramStep }) => {
  const {
    refetchTables,
    removeTableToFetch,
    tableRefetchPlanner: { [EmployeeTableTypes.SELECTED]: needToRefetch },
  } = React.useContext(EmployeeTableRefetchContext)

  const { t } = useTranslation()
  const theme = useTheme()
  const { id: companyId } = useParams<{ id: string }>()
  const [orderBy, setOrderBy] =
    React.useState<ColumnSortBy>(initialColumnSortBy)
  const [currModal, setCurrModal] = React.useState(initialModalSettings)
  const [sendEmailDisabled, setSendEmailDisabled] = React.useState(false)
  const [documentsModal, setDocumentsModal] = React.useState<string | null>(
    null,
  )

  const resetModal = () => setCurrModal(initialModalSettings)

  const {
    data: employeesData,
    loading,
    refetch: refetchSelectedEmployees,
  } = useQuery<
    GetSelectedCompanyEmployeesQuery,
    GetSelectedCompanyEmployeesQueryVariables
  >(QUERY_COMPANY_SELECTED_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    variables: {
      companyId,
      dateFilter: filters.temporality,
      multiFilter: filters.multiFilter,
      distanceFilter: filters.distance,
      orderBy:
        orderBy.sort && orderBy.columnName
          ? {
              columnName:
                orderBy.columnName as SelectedCompanyEmployeesColumnOrdered,
              sort: orderBy.sort,
            }
          : null,
    },
  })

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<CompanySelectedEmployeesTableRow> }) => (
      <SelectedEmployeeActionsCell
        setModal={setCurrModal}
        row={row}
        loanEventsFormatted={loanEventsFormatted}
        currentProgramStep={currentProgramStep}
      />
    ),
    [loanEventsFormatted, currentProgramStep],
  )

  const DocumentCell = React.useCallback(
    ({ row }: { row: Row<CompanySelectedEmployeesTableRow> }) => (
      <EmployeeDocumentCell
        setModal={employeeId => setDocumentsModal(employeeId)}
        row={row}
        deploymentType={null}
      />
    ),
    [],
  )

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: t(
          'animator.company.employees.selected.table.headers.name',
        ) as string,
        accessor: 'name',
        maxWidth: 200,
      },
      ...(currentProgramStep >= ProgramStepType.EMPLOYEE_LOANS_SELECTION
        ? [
            {
              Header: t(
                'animator.company.employees.selected.table.headers.documents',
              ) as string,
              accessor: 'document',
              disableSortBy: true,
              Cell: DocumentCell,
            },
          ]
        : []),
      {
        Header: t(
          'animator.company.employees.selected.table.headers.bike',
        ) as string,
        accessor: 'bikeStickerId',
        disableSortBy: false,
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: t(
          'animator.company.employees.selected.table.headers.homeWorkRides',
        ) as string,
        accessor: 'homeWorkRides',
      },
      {
        Header: t(
          'animator.company.employees.selected.table.headers.totalDistance',
        ) as string,
        accessor: 'ridesTotalDistance',
      },
      {
        Header: t(
          'animator.company.employees.selected.table.headers.dailyDistance',
        ) as string,
        accessor: 'distancePerDay',
      },
      {
        Header: t(
          'animator.company.employees.selected.table.headers.return',
        ) as string,
        accessor: 'loanEndDate',
      },
      {
        Header: t('animator.companies.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [t, ActionCell, DocumentCell, currentProgramStep],
  )

  const formattedData = React.useMemo(() => {
    if (!employeesData?.selectedCompanyEmployees?.length) {
      return []
    }

    return employeesData.selectedCompanyEmployees.map(employee => ({
      id: employee?.id,
      email: employee?.email,
      name: employeeNameFormatter(employee?.firstName, employee?.lastName),
      bikeStickerId: employee?.bikeStickerId,
      homeWorkRides: employee?.homeWorkRides || 0,
      babySeat: employee?.babySeat
        ? t(`shared.babySeat.short.${employee.babySeat}`)
        : '',
      identityCardFrontDocument: employee?.identityCardFrontFilePath,
      identityCardBackDocument: employee?.identityCardBackFilePath,
      identityCardVerified: employee?.identityCardVerified,
      proofOfAddressDocument: employee?.proofOfAddressFilePath,
      proofOfAddressVerified: employee?.proofOfAddressVerified,
      depositId: employee?.depositId,
      depositByCheque: employee?.depositByCheque,
      depositReleasedAt: employee?.depositReleasedAt,
      depositExpirationDate: employee?.depositExpirationDate,
      ridesTotalDistance: employee?.ridesTotalDistance
        ? `${Math.floor(employee?.ridesTotalDistance)} km`
        : '0 km',
      distancePerDay:
        employee?.ridesTotalDistance && employee?.daysWithRide
          ? `${Math.floor(
              employee?.ridesTotalDistance / employee?.daysWithRide,
            )} km/j`
          : `0 km/j`,
      loanPossibleAction: employee?.loanPossibleAction,

      hasReturnBike: employee?.bikeReturned,

      estimatedTotal: computeRetentionAmount(
        {
          // @ts-ignore
          bikeComponentHistories: employee?.bikeComponentHistories,
        },
        {
          loanPackageHistories: employee?.loanPackageHistories,
        },
      ),

      loanDate: employee?.absences?.find(
        x => x?.category === AppointmentCategory.LoanDelivery,
      )
        ? t('forms.label.absent')
        : employee?.bikeLoan && employee?.bikeLoan[0]?.bikeDeliveredDate
        ? dateFormatter(employee?.bikeLoan[0]?.bikeDeliveredDate)
        : dateFormatter(employee?.loanDeliveryAppointment?.date),

      loanLocation: employee?.loanDeliveryAppointment?.location,

      loanEndDate: employee?.absences?.find(
        x => x?.category === AppointmentCategory.LoanReturn,
      )
        ? t('forms.label.absent')
        : employee?.bikeLoan && employee?.bikeLoan[0]?.bikeReturnedDate
        ? dateFormatter(employee?.bikeLoan[0]?.bikeReturnedDate)
        : dateFormatter(employee?.loanReturnAppointment?.date),
      loanEndLocation: employee?.loanReturnAppointment?.location,

      trainingDate: employee?.absences?.find(
        x => x?.category === AppointmentCategory.Training,
      )
        ? t('forms.label.absent')
        : dateFormatter(employee?.trainingAppointment?.date),
      trainingLocation: employee?.trainingAppointment?.location,
    }))
  }, [employeesData, t])

  const mailList = React.useMemo(() => {
    if (!employeesData?.selectedCompanyEmployees?.length) {
      setSendEmailDisabled(true)
      return []
    }
    setSendEmailDisabled(false)
    return employeesData.selectedCompanyEmployees.reduce<string[]>(
      (acc, employee) => {
        if (employee?.email) {
          return [...acc, employee?.email]
        }
        return acc
      },
      [],
    )
  }, [employeesData])

  const handleRefetch = (newData?: Partial<Employee>) => {
    if (newData) {
      setCurrModal(prevState => ({
        ...prevState,
        employee: {
          ...(prevState.employee as Employee),
          ...newData,
        },
      }))
    }
    refetchTables([EmployeeTableTypes.SELECTED, EmployeeTableTypes.ALL])
  }

  const getAllEmployeeExpot = usePromiseLazyQuery<
    GetCompanyEmployeesExportQuery,
    GetCompanyEmployeesExportQueryVariables
  >(QUERY_COMPANY_EMPLOYEES_EXPORT, 'no-cache')

  React.useEffect(() => {
    if (needToRefetch) {
      refetchSelectedEmployees().then(() =>
        removeTableToFetch(EmployeeTableTypes.SELECTED),
      )
    }
  }, [refetchSelectedEmployees, needToRefetch, removeTableToFetch])

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `selected-employees-${formatedDate}`

  const getExportData = async () => {
    const { data } = await getAllEmployeeExpot({
      employeeWhereInput: {
        companyId: { equals: companyId },
        selectLoan: { equals: true },
        NOT: [{ bikeLoan: null }],
      },
    })
    return formatEmployeesExport(data)
  }

  const employeeAvailableForRelease = employeesData?.selectedCompanyEmployees
    ?.filter(x => x?.depositId !== null)
    .filter(x => x?.bikeReturned)
    .filter(x => !x?.depositReleasedAt)

  const showReleaseDepositButton = employeeAvailableForRelease?.length

  return (
    <React.Fragment>
      <div
        css={{
          minHeight: 380,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Table
          columns={columns}
          data={formattedData}
          onSortBy={setOrderBy}
          loading={loading}
        />
        <div
          css={{
            display: 'flex',
            alignSelf: 'flex-end',
            marginTop: 17,
            [theme.media.mobile]: {
              display: 'none',
            },
            gap: 7,
          }}
        >
          {!!showReleaseDepositButton && (
            <ReleaseDepositButton
              companyId={companyId}
              onClick={() =>
                setCurrModal({
                  employee: null,
                  actionName:
                    CompanySelectedEmployeesTableModal.RELEASE_DEPOSITS,
                })
              }
            />
          )}
          <DownloadCSVButton getData={getExportData} filename={filename} />
          <SendMailButton mailTo={mailList} disabled={sendEmailDisabled} />
        </div>
      </div>
      <EditEmployeeProfileModal
        isOpen={
          currModal.actionName === CompanySelectedEmployeesTableModal.EDIT
        }
        refetch={handleRefetch}
        employee={currModal.employee}
        submitCloseModal={resetModal}
        onClose={resetModal}
      />
      <WithdrawalModal
        isOpen={
          currModal.actionName === CompanySelectedEmployeesTableModal.WITHDRAWAL
        }
        employee={currModal.employee}
        closeModal={resetModal}
        refetchTables={handleRefetch}
      />
      {/* <LoanBikeModal
        employee={currModal.employee}
        loanEventsFormatted={loanEventsFormatted}
        closeModal={resetModal}
        refetchTables={refetchSelectedEmployees}
        modalIsOpen={
          currModal.actionName === CompanySelectedEmployeesTableModal.LOAN_BIKE
        }
      /> */}
      {/* <AppointmentModal
        employee={
          employeesData?.selectedCompanyEmployees?.find(
            x => x?.id === currModal?.employee?.id,
          ) as SelectedCompanyEmployee
        }
        // Old component don't have deploymentId
        deploymentId=""
        onClose={resetModal}
        refetch={handleRefetch}
        isOpen={
          currModal.actionName ===
          CompanySelectedEmployeesTableModal.APPOINTMENT
        }
        submitCloseModal={resetModal}
      /> */}
      <ReturnPackageModal
        employee={currModal.employee}
        closeModal={resetModal}
        refetchTables={refetchSelectedEmployees}
        modalIsOpen={
          currModal.actionName ===
          CompanySelectedEmployeesTableModal.RETURN_PACKAGE
        }
      />
      <ReturnBikeModal
        employee={currModal.employee}
        closeModal={resetModal}
        refetchTables={refetchSelectedEmployees}
        modalIsOpen={
          currModal.actionName ===
          CompanySelectedEmployeesTableModal.RETURN_BIKE
        }
      />
      {documentsModal && (
        <EmployeeDocumentsModal
          isOpen={!!documentsModal}
          closeModal={() => setDocumentsModal(null)}
          employeeId={documentsModal}
          deploymentType={null}
          refetchTable={refetchSelectedEmployees}
        />
      )}
      {currModal.actionName ===
        CompanySelectedEmployeesTableModal.RETENTION && (
        <DepositRetentionModal
          employee={currModal.employee}
          employeeDeploymentId=""
          closeModal={resetModal}
          refetch={refetchSelectedEmployees}
        />
      )}
      {currModal.actionName ===
        CompanySelectedEmployeesTableModal.RELEASE_DEPOSITS &&
        employeesData?.selectedCompanyEmployees?.length && (
          <ReleaseDepositsConfirmationModal
            employees={
              employeesData?.selectedCompanyEmployees as SelectedCompanyEmployee[]
            }
            closeModal={resetModal}
            refetch={refetchSelectedEmployees}
          />
        )}
    </React.Fragment>
  )
}

export default SelectedCompanyEmployeesList
