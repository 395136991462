/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNotifications } from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import { useUnArchiveDeployment } from '../hooks/useUnArchiveDeployment'
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../../../../components/ConfirmationModal'

export interface UnArchiveDeploymentModalProps
  extends Pick<ConfirmationModalProps, 'isOpen' | 'onClose'> {
  id: string
  onCompleted?: () => void
}

export const UnArchiveDeploymentModal: React.FC<
  UnArchiveDeploymentModalProps
> = ({ id, onCompleted, ...rest }) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [mutate, { loading }] = useUnArchiveDeployment({
    onCompleted,
  })

  const onSubmit = async () => {
    try {
      await mutate({
        variables: { data: { id } },
      })
      rest.onClose()
    } catch (e) {
      apiErrorCatcher(notifications)
    }
  }

  return (
    <ConfirmationModal
      title={t('animator.deployments.unArchiveDeploymentModal.title')}
      description={t(
        'animator.deployments.unArchiveDeploymentModal.description',
      )}
      loading={loading}
      onSubmit={onSubmit}
      {...rest}
    />
  )
}

export default UnArchiveDeploymentModal
