import {
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client'
import {
  ArchiveUserMutation,
  ArchiveUserMutationVariables,
} from '../../../../__generated__/graphql'

export const MUTATION_ARCHIVE_USER = gql`
  mutation ArchiveUser($data: ArchiveUserInput!) {
    archiveUser(data: $data)
  }
`

export const useArchiveUser = (
  options?: MutationHookOptions<
    ArchiveUserMutation,
    ArchiveUserMutationVariables
  >,
): MutationTuple<ArchiveUserMutation, ArchiveUserMutationVariables> => {
  return useMutation<ArchiveUserMutation, ArchiveUserMutationVariables>(
    MUTATION_ARCHIVE_USER,
    {
      refetchQueries: ['GetAllCompanyEmployees'],
      ...options,
    },
  )
}
