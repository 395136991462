/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { Flex } from '../../../../../components/Flex'
import { CardCollapsible } from '../../../../../components/Card/Collapsible'
import { LabeledIcon } from '../../../../../components/LabeledIcon'
import { NavigationLink } from '../../../../../components/NavigationLink'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../redux/rootReducer'
import { useTranslation } from 'react-i18next'
import { useCompanySites } from '../../hooks/useCompanySites'
import { CreateSiteModal } from '../../../../../components/Layout/Menu/CreateSiteModal'

export const CompanySites: React.FC = () => {
  const { t } = useTranslation()
  const companyId = useSelector(
    (state: StoreState) => state.user.companyId,
  ) as string

  const { data } = useCompanySites(companyId)
  const [selectedSite, setSelectedSite] = React.useState<string | undefined>()
  const onModalClose = () => setSelectedSite(undefined)

  return (
    <Flex direction="column" gap="16px">
      <h3 css={{ color: '#565656', fontWeight: 400, fontSize: '16px' }}>
        {t('company.menu.allSites')}
      </h3>
      {data?.sites?.map(site => (
        <CardCollapsible
          key={site.id}
          collapsibleContent={
            <Flex direction="column" gap="16px" css={{ color: '#434343' }}>
              <Fragment>
                <Flex direction="row" gap="50px">
                  <Flex direction="column" gap="4px">
                    <p>{t('animator.companies.profile.bikeParking')}</p>
                    <Flex
                      direction="row"
                      align="center"
                      gap="10px"
                      css={{ fontSize: '14px', fontWeight: 400 }}
                    >
                      <LabeledIcon
                        icon="bikeUnsecure"
                        label={`${site.bikeSpaceCapacityUnsafe} ${t(
                          'animator.companies.profile.bikeSpaceCapacityUnsafe',
                        )}`}
                      />
                      <LabeledIcon
                        icon="bikeSecure"
                        label={`${site.bikeSpaceCapacityOutside} ${t(
                          'animator.companies.profile.bikeSpaceCapacityOutside',
                        )}`}
                      />
                      <LabeledIcon
                        icon="bikeInside"
                        label={`${site.bikeSpaceCapacityInside} ${t(
                          'animator.companies.profile.bikeSpaceCapacityInside',
                        )}`}
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <hr css={{ margin: 0 }} />
                <Flex direction="column" gap="4px">
                  <p>{t('animator.companies.profile.parking')}</p>
                  <Flex
                    direction="row"
                    align="center"
                    gap="50px"
                    css={{ fontSize: '14px', fontWeight: 400 }}
                  >
                    <LabeledIcon
                      icon="parking"
                      label={`${site.carSpaceCapacity} ${t(
                        'animator.companies.profile.privateSpots',
                      )}`}
                    />
                    <LabeledIcon
                      icon="electricCharge"
                      label={`${site.electricStationCapacity} ${t(
                        'animator.companies.profile.electricChargePoints',
                      )}`}
                    />
                    <NavigationLink
                      css={{ marginLeft: 'auto' }}
                      to="#"
                      onClick={event => {
                        event.stopPropagation()
                        setSelectedSite(site.id)
                      }}
                    >
                      {t('shared.edit')}
                    </NavigationLink>
                  </Flex>
                </Flex>
              </Fragment>
            </Flex>
          }
        >
          <Flex
            direction="column"
            gap="16px"
            css={{ fontSize: '14px', fontWeight: 400, color: '#434343' }}
          >
            <Flex direction="row" gap="50px">
              <LabeledIcon icon="home" label={site.name} />
              <LabeledIcon
                icon="animators"
                label={`${t('animator.companies.profile.employees')} : ${
                  site.employeesOnSite
                }`}
              />
            </Flex>
            <LabeledIcon
              icon="locate"
              label={`${site.street}, ${site.postalCode} ${site.city}`}
            />
          </Flex>
        </CardCollapsible>
      ))}

      {!!selectedSite && (
        <CreateSiteModal
          siteId={selectedSite}
          type="update"
          isOpen={!!selectedSite}
          onModalClose={onModalClose}
        />
      )}
    </Flex>
  )
}
