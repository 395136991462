/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useTheme } from 'emotion-theming'
import { ProgramStepType } from '@goodwatt/shared/dist/types'

import ConventionStatus from './ConventionStatus'
import BikeLoanDate from '../../../Shared/CompanyProgress/BikeLoanDate'

import StepStatus from './StepStatus'
import SummaryCard, { SummaryItem } from '../../../../components/Card/Summary'
import theme from '../../../../styles/theme'
import { useTranslation } from 'react-i18next'
import EditEventsModal from './EditEventsModal'
import Modal from '../../../../components/Modal'
import Icon from '../../../../components/Icon'
import { QUERY_COMPANY_PROGRESS_DETAILS } from '../../../../utils/gql/queries'

import TrainingDate from '../../../Shared/CompanyProgress/TrainingDate'
import SpecialBikesStatus from './SpecialBikesStatus'
import {
  AppointmentCategory,
  GetCompanyProgressDetailsQuery,
  GetCompanyProgressDetailsQueryVariables,
} from '../../../../__generated__/graphql'

const CompanyProgress: React.FC = () => {
  const theme = useTheme()
  const [editEventsMode, setEditEventsMode] = useState<
    AppointmentCategory | false
  >(false)
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { data, loading, refetch } = useQuery<
    GetCompanyProgressDetailsQuery,
    GetCompanyProgressDetailsQueryVariables
  >(QUERY_COMPANY_PROGRESS_DETAILS, {
    fetchPolicy: 'network-only',
    variables: {
      where: { id },
    },
  })

  if (loading)
    return (
      <div
        css={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icon type="loader" size="large" strokeColor={theme.colors.primary} />
      </div>
    )

  if (!data || !data.company)
    return (
      <p css={{ fontWeight: 'bold', color: theme.colors.redLegend }}>
        {t('forms.errors.contentNotFound')}
      </p>
    )

  const stepStatusDescription = data?.company?.programStep.name
    ? t(`shared.programStepLabel.${data?.company?.programStep.name}`)
    : undefined

  const myCompaniesSummary: SummaryItem[] = [
    {
      id: 'employeesCount',
      icon: 'person',
      label: t('animator.company.progress.employeesRecap.employees', {
        count: data?.company?.employeesCount || 0,
      }),
      description: t('animator.company.progress.employeesRecap.description0', {
        count: data?.company?.employeesCount || 0,
      }),
    },
    {
      id: 'employeesCompletedProfile',
      icon: 'docsPen',
      label: t('animator.company.progress.employeesRecap.employees', {
        count: data?.company?.employeesCompletedProfile || 0,
      }),
      description: t('animator.company.progress.employeesRecap.description1', {
        count: data?.company?.employeesCompletedProfile || 0,
      }),
    },
    {
      id: 'employeesValidated',
      icon: 'valid',
      label: t('animator.company.progress.employeesRecap.employees', {
        count: data?.company?.employeesValidated || 0,
      }),
      description:
        t('animator.company.progress.employeesRecap.description2', {
          count: data?.company?.employeesValidated || 0,
        }) +
        t('animator.company.progress.employeesRecap.place', {
          count: data?.company?.loanQuantityBikes || 0,
        }),
    },
    {
      id: 'employeesWithBikeCount',
      icon: 'bikeRiden',
      label: t('animator.company.progress.employeesRecap.employees', {
        count: data?.company?.employeesWithBikeCount || 0,
      }),
      description: t('animator.company.progress.employeesRecap.description3', {
        count: data?.company?.employeesWithBikeCount || 0,
      }),
    },
  ]

  const myBikesSummary: SummaryItem[] = [
    {
      id: 'totalDistance',
      icon: 'road',
      label: t('animator.company.progress.bikesRecap.label0', {
        count: Math.round(data?.company?.employeesTotalDistanceInKm || 0),
      }),
      description: t('animator.company.progress.bikesRecap.description0', {
        count: Math.round(data?.company?.employeesTotalDistanceInKm || 0),
      }),
    },
    {
      id: 'co2Saved',
      icon: 'leaves',
      label: t('animator.company.progress.bikesRecap.label1', {
        count: Math.round(data?.company?.employeesTotalKgCO2Saved || 0),
      }),
      description: t('animator.company.progress.bikesRecap.description1'),
    },
    {
      id: 'routesDone',
      icon: 'roadPin',
      label: t('animator.company.progress.bikesRecap.label2', {
        count: data.company?.employeesRidesCount || 0,
      }),
      description: t('animator.company.progress.bikesRecap.description2'),
    },
  ]

  return (
    <Fragment>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div css={styles.wrapper1}>
          <div css={{ ...styles.firstColumnBlock, ...styles.rightMargin }}>
            <StepStatus
              currentStepNbr={data?.company?.programStep.stepNbr}
              description={stepStatusDescription}
              refetchCompany={refetch}
            />
          </div>
          {data.company.programStep.stepNbr <
            ProgramStepType.EMPLOYEE_LOANS_SELECTION && (
            <div css={{ ...styles.firstColumnBlock, ...styles.rightMargin }}>
              <ConventionStatus
                signedConvention={!!data.company?.conventionFilePath}
              />
            </div>
          )}

          <div css={{ ...styles.firstColumnBlock, ...styles.rightMargin }}>
            <SpecialBikesStatus
              eligible={!!data.company?.eligibleToSpecialBikes}
            />
          </div>
        </div>
        <div css={styles.wrapper2}>
          {data.company.programStep.stepNbr >=
            ProgramStepType.EMPLOYEE_LOANS_SELECTION && (
            <div css={{ ...styles.secondColumn, ...styles.rightMargin }}>
              <SummaryCard
                title={t('animator.company.progress.employeesRecap.title')}
                linkTo="employees"
                loading={false}
                itemList={myCompaniesSummary}
              />
            </div>
          )}
          {data.company.programStep.stepNbr >=
            ProgramStepType.LOAN_BIKES_IN_PROGRESS && (
            <div css={{ ...styles.secondColumn, ...styles.rightMargin }}>
              <SummaryCard
                title={t('animator.company.progress.bikesRecap.title')}
                loading={false}
                itemList={myBikesSummary}
              />
            </div>
          )}
        </div>
        <div css={styles.wrapper3}>
          <div css={{ ...styles.thirdColumn, ...styles.rightMargin }}>
            <BikeLoanDate
              company={data.company}
              onEditClicked={() =>
                setEditEventsMode(AppointmentCategory.LoanDelivery)
              }
            />
          </div>
          <div css={{ ...styles.thirdColumn, ...styles.rightMargin }}>
            <TrainingDate
              company={data.company}
              onEditClicked={() =>
                setEditEventsMode(AppointmentCategory.Training)
              }
            />
          </div>
          <div css={styles.thirdColumn}>
            {/* <BikeReturnDate
              company={data.company}
              onEditClicked={() =>
                setEditEventsMode(AppointmentCategory.LoanReturn)
              }
            /> */}
          </div>
        </div>
      </div>
      <Modal isOpen={editEventsMode !== false}>
        <EditEventsModal
          totalEmployeesForLoan={data?.company?.employeesSelectedForLoan || 0}
          refetch={refetch}
          data={data}
          defaultAppointmentType={editEventsMode as AppointmentCategory}
          onClose={() => setEditEventsMode(false)}
          companyStepNumber={data.company.programStep.stepNbr}
        />
      </Modal>
    </Fragment>
  )
}

const styles = {
  wrapper1: {
    display: 'flex',
    'flex-direction': 'row',
    'flex-wrap': 'wrap',
    width: '100%',
  },
  firstColumnBlock: {
    width: '100%',
    marginBottom: 24,
    minWidth: 240,
    maxWidth: 300,
    [theme.media.mobile]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },
  wrapper2: {
    display: 'flex',
    'flex-direction': 'row',
    'flex-wrap': 'wrap',
    width: '100%',
  },
  secondColumn: {
    width: '100%',
    maxWidth: 462,
    marginBottom: 24,
    [theme.media.mobile]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },
  wrapper3: {
    display: 'flex',
    'flex-direction': 'row',
    'flex-wrap': 'wrap',
    width: '100%',
  },
  thirdColumn: {
    width: '100%',
    marginBottom: 24,
    minWidth: 240,
    maxWidth: 300,
    [theme.media.mobile]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },
  rightMargin: {
    marginRight: 24,
  },
}

export default CompanyProgress
