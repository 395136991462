/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'

interface SwitchProps {
  id?: string
  isChecked: boolean
  onClick?: ((id: string) => void) | undefined
  label?: string
  error?: boolean
  color?: string
}

const Switch: React.FC<SwitchProps> = ({
  id = '',
  label = '',
  error = false,
  ...props
}) => {
  const theme = useTheme()

  const color = props.color ?? theme.colors.primary

  return (
    <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <button
        onClick={e => {
          e.preventDefault()
          props.onClick && props.onClick(id)
        }}
        type="button"
        css={{
          position: 'relative',
        }}
      >
        <div
          css={{
            cursor: 'pointer',
            minWidth: 42,
            width: '100%',
            height: 18,
            transition: '.3s ease all',
            backgroundColor: props.isChecked ? color : theme.colors.gray4,
            opacity: 0.5,
            boxSizing: 'border-box',
            borderRadius: 32 / 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        ></div>
        <div
          css={{
            top: -3,
            transition: '.3s ease all',
            right: props.isChecked ? -6 : 22,
            position: 'absolute',
            borderRadius: '50%',
            background: props.isChecked ? color : theme.colors.gray4,
            width: 24,
            height: 24,
          }}
        />
      </button>
      {label && (
        <span
          css={{
            marginLeft: '1.2rem',
            fontSize: '1.4rem',
            color: error ? theme.colors.error : theme.colors.gray2,
          }}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      )}
    </div>
  )
}

export default Switch
