import i18n from '../../../i18n/config'
import { RestrictionType } from '@goodwatt/shared/dist/types'
import { gql } from '@apollo/client'
import { AccessibilityCompany } from '../../../__generated__/graphql'

export const MUTATION_UPLOAD_DOCUMENT_BY_COMPANY = gql`
  mutation UploadDocumentByCompany(
    $file: Upload!
    $title: String!
    $accessibility: accessibilityCompany!
  ) {
    uploadDocumentByCompany(
      file: $file
      title: $title
      accessibility: $accessibility
    ) {
      id
    }
  }
`

export const MUTATION_UPDATE_DOCUMENT_BY_COMPANY = gql`
  mutation UpdateDocumentByCompany(
    $title: String!
    $accessibility: accessibilityCompany!
    $documentId: String!
  ) {
    updateDocumentByCompany(
      title: $title
      accessibility: $accessibility
      documentId: $documentId
    ) {
      id
    }
  }
`

export const accessibilitySelectOptions = [
  {
    label: i18n.t('shared.documents.docModal.option1'),
    value: AccessibilityCompany.Animator,
  },
  {
    label: i18n.t('shared.documents.docModal.option4'),
    value: AccessibilityCompany.Employee,
  },
]

/**
 * On the document page, when company upload a document
 * there is a select input with restrictions options
 * with labels.
 * Be careful, if the restrictions change in the server you
 * can only add the changes here, do not modify the existing
 * conditions below, otherwise there is chance that it breaks
 * documents with outdated restrictions, and won't be able
 * to display the default value of the select input
 * @param restrictions ['company:write', 'company:read'...]
 */
export const accessibilityDefaultValue = (
  restrictions: string[],
): {
  label: string
  value: string
} | null => {
  if (
    restrictions?.includes(RestrictionType.COMPANY_W) &&
    restrictions?.includes(RestrictionType.COMPANY_R) &&
    restrictions?.includes(RestrictionType.COMPANY_D) &&
    restrictions?.includes(RestrictionType.ANIMATOR_R) &&
    restrictions?.includes(RestrictionType.ANIMATOR_D) &&
    restrictions.length === 5
  )
    return {
      label: i18n.t('shared.documents.docModal.option1'),
      value: AccessibilityCompany.Animator,
    }
  if (
    restrictions?.includes(RestrictionType.COMPANY_W) &&
    restrictions?.includes(RestrictionType.COMPANY_R) &&
    restrictions?.includes(RestrictionType.COMPANY_D) &&
    restrictions?.includes(RestrictionType.ANIMATOR_R) &&
    restrictions?.includes(RestrictionType.ANIMATOR_D) &&
    restrictions?.includes(RestrictionType.EMPLOYEE_R) &&
    restrictions.length === 6
  )
    return {
      label: i18n.t('shared.documents.docModal.option4'),
      value: AccessibilityCompany.Employee,
    }
  return null
}
