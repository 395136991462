/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Modal from '../../../../../../components/Modal'
import StepPopup from '../../../../../../components/Popup/StepPopup'
import BikeAttribution from './BikeAttribution'
import BikeComponentsCondition from '../Shared/BikeComponentsCondition'
import LoanAccessoriesCondition from './LoanAccessoriesCondition'

import { StoreState } from '../../../../../../redux/rootReducer'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../../../../utils/apiErrorCatcher'
import i18n from '../../../../../../i18n/config'
import AnimatorLoanValidation from './AnimatorLoanValidation'
import EmployeeLoanValidation from './EmployeeLoanValidation'
import { QUERY_GET_EMPLOYEE_SIGN_STATUS } from '../../../../../../utils/gql/queries'

import {
  EmployeeSignType,
  EmployeeValidationState,
  resetLoanBikeForm,
  setLoanBikeForm,
} from '../../../../../../redux/forms'
import { parse } from 'date-fns'
import {
  BabySeatOptions,
  CreateBikeLoanMutation,
  CreateBikeLoanMutationVariables,
  GetEmployeeQuery,
  VerifyBikeAssociationToDeploymentMutation,
  VerifyBikeAssociationToDeploymentMutationVariables,
  GetEmployeeSignStatusQuery,
  GetEmployeeSignStatusQueryVariables,
} from '../../../../../../__generated__/graphql'

const MUTATION_VERIFY_BIKE_ASSOCIATION_TO_DEPLOYMENT = gql`
  mutation verifyBikeAssociationToDeployment(
    $stickerId: String!
    $deploymentId: String!
  ) {
    verifyBikeAssociationToDeployment(
      stickerId: $stickerId
      deploymentId: $deploymentId
    ) {
      id
      stickerId
    }
  }
`

const MUTATION_CREATE_BIKE_LOAN = gql`
  mutation CreateBikeLoan(
    $bikeId: String!
    $employeeDeploymentId: String!
    $loanDate: DateTime!
    $loanEndDate: DateTime!
    $bikeComponents: [BikeComponentInputWithAttachments!]!
    $loanPackages: [LoanPackageInputWithAttachments!]!
    $signOnApp: Boolean!
  ) {
    createBikeLoan(
      bikeId: $bikeId
      employeeDeploymentId: $employeeDeploymentId
      loanDate: $loanDate
      loanEndDate: $loanEndDate

      bikeComponents: $bikeComponents
      loanPackages: $loanPackages

      signOnApp: $signOnApp
    ) {
      id
    }
  }
`

const QUERY_GET_EMPLOYEE_DEPLOYMENT = gql`
  query GetEmployeeDeployment($id: String!) {
    employeeDeployment(id: $id) {
      option
      bikeLoan {
        id
        loanDate
        loanEndDate
        bike {
          id
          morioId
        }
      }
    }
  }
`

interface LoanBikeModalProps {
  closeModal: () => void
  employee: GetEmployeeQuery['employee'] | null
  employeeDeploymentId: string
  loanEventsFormatted: { loanDeliveryDate: string; loanReturnDate: string }
  refetchTables: () => void
  modalIsOpen: boolean
}

const LoanBikeModal: React.FC<LoanBikeModalProps> = ({
  employee,
  employeeDeploymentId,
  loanEventsFormatted,
  closeModal,
  refetchTables,
  modalIsOpen,
}) => {
  const [loanProcessStep, setLoanProcessStep] = React.useState<number>(0)
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(true)
  const [employeeValidationIsOpen, setEmployeeValidationIsOpen] =
    React.useState<boolean>(false)
  const { loanBikeForm } = useSelector((state: StoreState) => state.forms)
  const dispatch = useDispatch()
  const notifications = useNotifications()
  const { deploymentId } = useParams<{ deploymentId: string }>()

  const [checkBikeToCompany, { loading: loadingCheckBike }] = useMutation<
    VerifyBikeAssociationToDeploymentMutation,
    VerifyBikeAssociationToDeploymentMutationVariables
  >(MUTATION_VERIFY_BIKE_ASSOCIATION_TO_DEPLOYMENT)

  const [createBikeLoan, { loading: loadingCreateBikeLoan }] = useMutation<
    CreateBikeLoanMutation,
    CreateBikeLoanMutationVariables
  >(MUTATION_CREATE_BIKE_LOAN, {
    refetchQueries: ['GetDeploymentEmployees'],
  })

  const { loading: loadingEmployeeDeployment, data: employeeDeploymentData } =
    useQuery(QUERY_GET_EMPLOYEE_DEPLOYMENT, {
      fetchPolicy: 'no-cache',
      variables: { id: employeeDeploymentId },
    })

  const { data: getEmployeeSignStatus } = useQuery<
    GetEmployeeSignStatusQuery,
    GetEmployeeSignStatusQueryVariables
  >(QUERY_GET_EMPLOYEE_SIGN_STATUS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: employee?.id,
      },
    },
  })

  React.useEffect(() => {
    let currNextDisabled = false
    if (loanProcessStep === 0) {
      currNextDisabled =
        loanBikeForm.stickerId === 0 || isNaN(loanBikeForm.stickerId)
    }
    if (loanProcessStep === 2) {
      currNextDisabled = Object.values(loanBikeForm.accessories).some(
        accessory => accessory.included && !accessory.stateOfUse,
      )
    }
    if (loanProcessStep === 3) {
      currNextDisabled = !loanBikeForm.animatorValidation
    }
    setIsNextDisabled(currNextDisabled)
  }, [loanProcessStep, loanBikeForm])

  const canGoNext = async (): Promise<boolean | void> => {
    // Handle step 0 onNext
    if (!loading && loanProcessStep === 0) {
      return checkBikeToCompany({
        variables: { stickerId: `${loanBikeForm.stickerId}`, deploymentId },
      })
        .then(result => {
          if (result.data?.verifyBikeAssociationToDeployment) {
            dispatch(
              setLoanBikeForm({
                bikeId: result.data?.verifyBikeAssociationToDeployment.id,
              }),
            )
            return true
          }
          return false
        })
        .catch(apiErrorCatcher(notifications))
    } else if (loanProcessStep === 1) {
      return true
    } else if (loanProcessStep === 2) {
      if (
        [
          EmployeeValidationState.TODO,
          EmployeeValidationState.SIGN_ON_APP,
        ].includes(loanBikeForm.employeeValidation)
      ) {
        setEmployeeValidationIsOpen(true)
        return false
      }
      return true
    } else if (loanProcessStep === 3) {
      try {
        if (employee == null) {
          return
        }

        // Create the bike loan
        await createBikeLoan({
          variables: {
            bikeId: loanBikeForm.bikeId,
            employeeDeploymentId: employeeDeploymentId,
            loanDate: parse(
              loanEventsFormatted.loanDeliveryDate,
              'dd/MM/yyyy',
              new Date(),
            ),
            loanEndDate: parse(
              loanEventsFormatted.loanReturnDate,
              'dd/MM/yyyy',
              new Date(),
            ),

            bikeComponents: Object.values(loanBikeForm.components).map(
              partValues => ({
                id: partValues.id!,

                note: partValues.touched ? partValues.note || '' : undefined,
                stateOfUse: partValues.touched
                  ? partValues.stateOfUse
                  : undefined,
                attachments: partValues.touched
                  ? partValues.attachments?.map(a => a.id) || []
                  : undefined,
              }),
            ),

            loanPackages: Object.values(loanBikeForm.accessories)
              .filter(a => a.included)
              .map(accessory => ({
                id: accessory.id!,

                note: accessory.note || '',
                stateOfUse: accessory.stateOfUse,
                attachments: accessory.attachments?.map(a => a.id) || [],
              })),

            signOnApp:
              loanBikeForm.employeeSignType === EmployeeSignType.SIGN_ON_APP,
          },
        })

        dispatch(resetLoanBikeForm())
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: i18n.t(
            'animator.company.employees.selected.modals.employeeLoanBikeModal.successLoanNotification',
          ),
        })
        setLoanProcessStep(0)
        await refetchTables()
      } catch (error) {
        apiErrorCatcher(notifications)(error)
        return false
      }
      return true
    }
  }

  const changeStep = (direction: 1 | -1) => {
    if (direction > 0) {
      setLoanProcessStep(prevState => prevState + 1)
    } else if (direction < 0) {
      setLoanProcessStep(prevState => prevState - 1)
    }
  }

  const steps = React.useMemo(
    () => [
      {
        height: 250,
        mobileHeight: 280,
        title: i18n.t(
          'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeAttribution.title',
        ),
        isFirst: true,
        isLast: false,
      },
      {
        height: 420,
        mobileHeight: 385,
        title: i18n.t(
          'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeComponentsCondition.title',
          { stickerId: loanBikeForm.stickerId },
        ),
        isFirst: false,
        isLast: false,
      },
      {
        height: 420,
        mobileHeight: 380,
        title: i18n.t(
          'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeLoanAccessoriesCondition.title',
        ),
        isFirst: false,
        isLast: false,
      },
      {
        height: 250,
        mobileHeight: 280,
        title: i18n.t(
          'animator.company.employees.selected.modals.employeeLoanBikeModal.animatorLoanValidation.title',
        ),
        isFirst: false,
        isLast: true,
      },
    ],
    [loanBikeForm.stickerId],
  )

  const loading =
    loadingCheckBike || loadingCreateBikeLoan || loadingEmployeeDeployment

  return (
    <Modal isOpen={modalIsOpen}>
      <StepPopup
        processStep={steps[loanProcessStep]}
        processStepNbr={loanProcessStep}
        changeStep={changeStep}
        canGoNext={canGoNext}
        disableNext={isNextDisabled}
        loading={loading}
        closeModal={closeModal}
      >
        {loanProcessStep === 0 && (
          <BikeAttribution
            hasBabySeat={
              employeeDeploymentData?.employeeDeployment.option !==
              BabySeatOptions.NotNeeded
            }
            employee={employee}
            deploymentId={deploymentId}
            loanEventsFormatted={loanEventsFormatted}
          />
        )}

        {loanProcessStep === 1 && (
          <BikeComponentsCondition
            returningBike={false}
            bikeId={loanBikeForm.bikeId}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setFormAction={setLoanBikeForm as any} // FIXME: any
          />
        )}

        {loanProcessStep === 2 && <LoanAccessoriesCondition />}

        {employeeValidationIsOpen && (
          <EmployeeLoanValidation
            loanEventsFormatted={loanEventsFormatted}
            onSuccess={() => changeStep(1)}
            employeeEmail={employee?.user.email || ''}
            employee={employee}
            deploymentId={deploymentId}
            contractAccepted={getEmployeeSignStatus?.employee?.contractAccepted}
            closeValidation={() => setEmployeeValidationIsOpen(false)}
          />
        )}

        {loanProcessStep === 3 && (
          <AnimatorLoanValidation
            loanEventsFormatted={loanEventsFormatted}
            employee={employee}
            deploymentId={deploymentId}
          />
        )}
      </StepPopup>
    </Modal>
  )
}

export default LoanBikeModal
