/** @jsx jsx */
import { jsx, Global, InterpolationWithTheme } from '@emotion/core'
import theme from './theme'

const ResetCss: React.FC = (): JSX.Element => <Global styles={resetCssStyle} />

export default ResetCss

const resetCssStyle: InterpolationWithTheme<unknown> = {
  /** 
  **  A solution for this problem is percentage.
  **  Usually default font-size of the browser is 16px.
  **  Setting font-size: 100% will make 1rem = 16px.
  **  But it will make calculations a little difficult.
  **  A better way is to set font-size: 62.5%. Because 62.5% of 16px is 10px.
  **  Which makes 1rem = 10px.

  * Source: https://medium.com/code-better/css-units-for-font-size-px-em-rem-79f7e592bb97
*/
  html: {
    fontSize: '62.5%',
    boxSizing: 'border-box',
    '@media only screen and (max-width : 576px)': {
      fontSize: '55%',
    },
  },
  '*, *:before, *:after': {
    boxSizing: 'inherit',
  },
  body: {
    fontSize: '1.6rem',
    margin: 0,
    padding: 0,
    backgroundColor: '#f9f7f7',
    fontFamily: 'Roboto,sans-serif',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  a: {
    textDecoration: 'none',
  },
  ul: {
    padding: 0,
    margin: 0,
  },
  li: {
    listStyle: 'none',
  },
  code: {
    fontFamily:
      'source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace',
  },
  '*/ div': {
    padding: '0',
    margin: '0',
    outline: 'none',
  },
  'a:active, a:focus, input, select': {
    outline: '0',
    border: 'none',
    outlineStyle: 'none',
    MozOutlineStyle: 'none',
  },
  button: {
    border: 'none',
    margin: '0',
    padding: '0',
    /* width: 'auto */
    overflow: 'visible',
    cursor: 'pointer',
    outline: '0',
    background: 'transparent',
    /* inherit font & color from ancestor */
    color: 'inherit',
    font: 'inherit',
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    lineHeight: 'normal',
    /* Corrects font smoothing for webkit */
    WebkitFontSmoothing: 'inherit',
    MozOsxFontSmoothing: 'inherit',
    /* Corrects inability to style clickable `input` types in iOS */
    WebkitAppearance: 'none',
  },
  'p, h1, h2, h3, h4, h5': {
    margin: '0',
  },
  /* Chrome, Safari, Edge, Opera */
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  /* Firefox */
  'input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '.bold': {
    fontWeight: 'bold',
  },
  '.color-primary': {
    color: theme.colors.primary,
  },
  '.color-secondary': {
    color: theme.colors.secondary,
  },
  '.underline': {
    textDecoration: 'underline',
  },
}
