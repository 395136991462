/** @jsx jsx */
import { jsx } from '@emotion/core'
import { StateOfUseType } from '@goodwatt/shared/dist/types'
import { useEffect, useState } from 'react'
import { ComponentRow } from '.'

import StarRating from '../../../../../components/StarRating'

interface BikeComponentStarRatingCellProps {
  component: ComponentRow
  editedStateOfUse?: StateOfUseType
  onStarClickAction: (newStateOfUse: StateOfUseType) => void
}

const BikeComponentStarRatingCell: React.FC<
  BikeComponentStarRatingCellProps
> = ({ component, editedStateOfUse, onStarClickAction }) => {
  const [defaultStateOfUse, setDefaultStateOfUse] = useState<StateOfUseType>()

  useEffect(() => {
    if (!defaultStateOfUse && component.stateOfUse)
      setDefaultStateOfUse(component.stateOfUse as StateOfUseType)
  }, [component.stateOfUse, defaultStateOfUse])

  return (
    <StarRating
      id={component.id || ''}
      currentStateOfUse={defaultStateOfUse}
      updatedStateOfUse={editedStateOfUse}
      onRatingClick={onStarClickAction}
    />
  )
}

export default BikeComponentStarRatingCell
