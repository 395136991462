/** @jsx jsx */
import { useMutation } from '@apollo/client'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'
import Modal from '../../../../../../components/Modal'
import Popup from '../../../../../../components/Popup'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../../../contexts/NotificationContext'
import { employeeNameFormatter } from '../../../../../../utils/formatter'
import { MUTATION_RELEASE_DEPOSITS } from '../../../../../../utils/gql/mutations'

interface ReleaseDepositsConfirmationModalProps {
  closeModal: () => void
  employees: any[]
  refetch?: () => void
}

const ReleaseDepositsConfirmationModal: React.FC<
  ReleaseDepositsConfirmationModalProps
> = ({ closeModal, employees, refetch }) => {
  const notifications = useNotifications()
  const { t } = useTranslation()
  const theme = useTheme()
  const [releaseDeposits, { loading }] = useMutation(
    MUTATION_RELEASE_DEPOSITS,
    {
      refetchQueries: ['GetDeploymentEmployees'],
      onCompleted: () => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t('forms.success.releaseDepositsSuccess'),
        })

        refetch?.()
        closeModal?.()
      },
      onError: () =>
        notifications.newNotification({
          type: NotificationTypes.ERROR,
          message: t('forms.errors.anErrorOccured'),
        }),
    },
  )

  const employeeAvailableForRelease = employees
    .filter(x => x?.bikeLoan?.bikeReturned)
    .filter(x => x?.depositId !== null)
    .filter(x => !x?.depositReleasedAt)

  const employeeWithCheque = employees
    .filter(x => x?.depositByCheque)
    .filter(x => x?.bikeLoan?.bikeReturned)

  return (
    <Modal isOpen>
      <Popup
        maxWidth={750}
        title={t(
          'animator.company.employees.selected.modals.releaseDeposits.title',
        )}
        titleColor={theme.colors.success}
        closable={false}
        footer={
          <Fragment>
            <Button
              type="tertiary"
              onClick={closeModal}
              textColor={theme.colors.success}
            >
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginLeft: '14px' }}>
              <Button
                submit
                type="primary"
                loading={loading}
                onClick={() => {
                  releaseDeposits({
                    variables: {
                      employeeDeploymentIds: employeeAvailableForRelease.map(
                        ed => ed?.id,
                      ),
                    },
                  })
                }}
              >
                {t('forms.button.confirm')}
              </Button>
            </div>
          </Fragment>
        }
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p css={{ textAlign: 'center' }}>
            {t(
              'animator.company.employees.selected.modals.releaseDeposits.body',
            )}
          </p>
          {employeeAvailableForRelease.length > 0 && (
            <p
              css={{ textAlign: 'center', marginTop: 12 }}
              dangerouslySetInnerHTML={{
                __html: t(
                  'animator.company.employees.selected.modals.releaseDeposits.people',
                  {
                    peopleNames: employeeAvailableForRelease
                      .map(
                        ed =>
                          `<strong>${employeeNameFormatter(
                            ed?.employee?.firstName,
                            ed?.employee?.lastName,
                          )}</strong>`,
                      )
                      .join(', '),
                  },
                ),
              }}
            />
          )}
          {employeeWithCheque.length > 0 && (
            <p
              css={{ textAlign: 'center', marginTop: 12 }}
              dangerouslySetInnerHTML={{
                __html: t(
                  'animator.company.employees.selected.modals.releaseDeposits.chequePeople',
                  {
                    peopleNames: employeeWithCheque
                      .map(
                        ed =>
                          `<strong>${employeeNameFormatter(
                            ed?.employee?.firstName,
                            ed?.employee?.lastName,
                          )}</strong>`,
                      )
                      .join(', '),
                  },
                ),
              }}
            />
          )}
          <p css={{ textAlign: 'center', marginTop: 12 }}>
            {t(
              'animator.company.employees.selected.modals.releaseDeposits.confirm',
            )}
          </p>
        </div>
      </Popup>
    </Modal>
  )
}

export default ReleaseDepositsConfirmationModal
