/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import ApolloClientProvider from './ApolloClientProvider'
import Routes from './routes'
import { NotificationProvider } from './contexts/NotificationContext'
import store, { persistor } from './redux/store'
import theme from './styles/theme'

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NotificationProvider>
          <ApolloClientProvider>
            <ThemeProvider theme={theme}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>GoodWatt</title>
                <link rel="canonical" href="https://goodwatt.fr/" />
              </Helmet>
              <Routes />
            </ThemeProvider>
          </ApolloClientProvider>
        </NotificationProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
