/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { useTheme } from 'emotion-theming'
import { useSelector } from 'react-redux'
import { regex } from '@goodwatt/shared'

import Input from '../../components/Input'
import Button from '../../components/Button'

import Checkbox from '../../components/Checkbox'
import { useNotifications } from '../../contexts/NotificationContext'
import i18n from '../../i18n/config'
import { gql, useMutation } from '@apollo/client'

import { StoreState } from '../../redux/rootReducer'
import apiErrorCatcher from '../../utils/apiErrorCatcher'
import { useTranslation } from 'react-i18next'
import {
  Civility,
  CreateEmployeeMutation,
  CreateEmployeeMutationVariables,
} from '../../__generated__/graphql'

const MUTATION_CREATE_EMPLOYEE = gql`
  mutation CreateEmployee($inputs: CreateEmployeeInputs!) {
    createEmployee(inputs: $inputs) {
      id
    }
  }
`

const schema = yup.object().shape({
  password: yup
    .string()
    .matches(regex.passwordRegex, i18n.t('forms.errors.passwordRegex'))
    .required(i18n.t('forms.errors.required')),
  confirmPassword: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .oneOf([yup.ref('password')], i18n.t('forms.errors.passwordsNotIdentical')),
  cgu: yup.boolean().equals([true]),
})

type Step3EmployeeInputs = {
  password: string
  confirmPassword: string
  cgu: boolean
}

interface Step3Props {
  onNext: () => void
  onPrevious: () => void
  unpersistedForm: {
    password: string
    confirmPassword: string
    cgu: boolean
  }
  setUnpersistedForm: React.Dispatch<
    React.SetStateAction<{
      password: string
      confirmPassword: string
      cgu: boolean
    }>
  >
}

const Step3: React.FC<Step3Props> = ({
  onNext,
  onPrevious,
  unpersistedForm,
  setUnpersistedForm,
}) => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors, control } =
    useForm<Step3EmployeeInputs>({
      resolver: yupResolver(schema),
    })
  const theme = useTheme()
  const notifications = useNotifications()
  const [createEmployee, { loading }] = useMutation<
    CreateEmployeeMutation,
    CreateEmployeeMutationVariables
  >(MUTATION_CREATE_EMPLOYEE)
  const form = useSelector(
    (state: StoreState) => state.forms.employeeSignUpForm,
  )

  const onSubmit = (data: Step3EmployeeInputs) => {
    setUnpersistedForm({
      cgu: data.cgu,
      confirmPassword: data.confirmPassword,
      password: data.password,
    })

    createEmployee({
      variables: {
        inputs: {
          email: form.email.toLowerCase(),
          password: data.password,
          firstName: form.firstName,
          lastName: form.lastName,
          civility: form.civility as Civility,
          phoneNumber: form.phoneNumber,
          cguAccepted: data.cgu,
          subscriptionCode: parseInt(form.subscriptionCode),
          siteId: form.siteId,
        },
      },
    })
      .then(result => {
        if (result.data?.createEmployee?.id) onNext()
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p
          css={{
            textAlign: 'center',
            fontStyle: 'italic',
            fontSize: '1.6rem',
            color: theme.colors.error,
            marginBottom: '40px',
          }}
        >
          {t('forms.errors.passwordRegexNotif')}
        </p>
        <Input
          type="password"
          label={t('forms.label.password')}
          name="password"
          defaultValue={unpersistedForm.password}
          error={!!errors.password}
          helperText={errors.password?.message}
          register={register}
        />
        <Input
          type="password"
          label={t('forms.label.confirmPassword')}
          name="confirmPassword"
          defaultValue={unpersistedForm.confirmPassword}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword?.message}
          register={register}
        />
        <div
          css={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}
        >
          <a
            css={{
              color: theme.colors.primary,
            }}
            href="https://goodwatt.fr/cgu"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('forms.label.showCGU')}
          </a>
        </div>
        <div css={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <Controller
            name="cgu"
            defaultValue={unpersistedForm.cgu}
            control={control}
            render={({ onChange, value }) => (
              <Checkbox
                isChecked={value}
                label={t('forms.label.cgu')}
                onClick={() => onChange(!value)}
                size="small"
                error={!!errors?.cgu}
              />
            )}
          />
        </div>
        <div
          css={{
            marginTop: '40px',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <div css={{ marginRight: 40, marginLeft: '-84px' /* -40 + -44 */ }}>
            <Button
              type="tertiary"
              onClick={onPrevious}
              icon="back"
              iconColor={theme.colors.primary}
            />
          </div>
          <Button loading={loading} type="primary" submit>
            {t('forms.button.valid')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Step3
