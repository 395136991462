import { constants } from '@goodwatt/shared'
import { IconTypes } from '../components/Icon'

export const displayAvatar = (avatarUrl?: string): string => {
  if (!avatarUrl) return ''
  if (avatarUrl.indexOf('http') + 1 || avatarUrl.indexOf('www') + 1)
    return avatarUrl
  return `${process.env.REACT_APP_MINIO}${constants.BUCKET_AVATAR}/${avatarUrl}`
}

export const displayContract = (contractUrl?: string): string => {
  if (!contractUrl) return ''
  return `${process.env.REACT_APP_MINIO}/${contractUrl}`
}

export const displayBikeModelImage = (image?: string): string => {
  if (!image) return ''
  return `${process.env.REACT_APP_MINIO}${constants.BUCKET_BIKE_MODEL}/${image}`
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const getFileExtension = (filename: string): string => {
  if (filename.lastIndexOf('.') === -1) return ''
  return filename.slice(filename.lastIndexOf('.') + 1, filename.length)
}

export const fileIconMapping: { [key: string]: IconTypes } = {
  pdf: 'pdf',
  docx: 'docx',
  doc: 'docx',
  xls: 'xls',
  xlsx: 'xls',
}

export const getFileIcon = (filename: string): IconTypes =>
  fileIconMapping[getFileExtension(filename)] || 'unknownDoc'
