/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { gql, useMutation, StoreObject, Reference } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'
import Button from '../../../../components/Button'
import {
  useNotifications,
  NotificationTypes,
} from '../../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import {
  DeleteOneCompanyMonitoringMutation,
  DeleteOneCompanyMonitoringMutationVariables,
} from '../../../../__generated__/graphql'

const MUTATION_DELETE_COMPANY_MONITORING = gql`
  mutation DeleteOneCompanyMonitoring(
    $where: CompanyMonitoringWhereUniqueInput!
  ) {
    deleteOneCompanyMonitoring(where: $where) {
      id
    }
  }
`

export interface DeleteModalProps {
  id: string
  isOpen: boolean
  onClose: () => void
  updateList?: () => void
}

export const DeleteCompanyMonitoringModal: React.FC<DeleteModalProps> = ({
  id,
  isOpen,
  onClose,
  updateList,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [deleteOneCompanyMonitoring, { loading }] = useMutation<
    DeleteOneCompanyMonitoringMutation,
    DeleteOneCompanyMonitoringMutationVariables
  >(MUTATION_DELETE_COMPANY_MONITORING, {
    refetchQueries: ['GetDeployment'],
  })
  const onSubmit = () => {
    deleteOneCompanyMonitoring({
      variables: {
        where: { id },
      },
      update(cache) {
        cache.modify({
          fields: {
            companyMonitorings: (existingCompanyMonitorings, { readField }) =>
              existingCompanyMonitorings.filter(
                (compnayMonitoringRef: Reference | StoreObject | undefined) =>
                  id !== readField('id', compnayMonitoringRef),
              ),
          },
        })
      },
    })
      .then(() => {
        updateList?.()
        onClose()
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t(
            `animator.company.monitoring.modal.delete.successNotification`,
          ),
        })
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        closable
        title={t('animator.company.monitoring.modal.delete.title')}
        onClose={onClose}
        footer={
          <React.Fragment>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="tertiary"
                submit
                loading={loading}
                onClick={() => onClose()}
              >
                {t('forms.button.cancel')}
              </Button>
            </div>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="primary"
                submit
                loading={loading}
                onClick={() => onSubmit()}
              >
                {t('forms.button.confirm')}
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <p css={{ margin: '40px 0', textAlign: 'center' }}>
          {t('animator.company.monitoring.modal.delete.confirmationMessage')}
        </p>
      </Popup>
    </Modal>
  )
}

export default DeleteCompanyMonitoringModal
