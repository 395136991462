import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import { UserRoles } from '../redux/user'

import { PrivateRoute } from './index'

import CompanyDashboard from '../pages/Company/Dashboard/index'
import Sites from '../pages/Company/Profile/sites'
import CompanyProfile from '../pages/Company/Profile/index'
import CompanyInformations from '../pages/Company/Profile/infos'
import CompanyDocuments from '../pages/Company/Documents/index'
import CompanyEmployees from '../pages/Company/Employees/companyEmployees'
import CompanyLoanMonitoring from '../pages/Company/LoanMonitoring'
import CompanyPosts from '../pages/Company/Posts'
import CompanyLoanEmployees from '../pages/Company/LoanEmployees'
import Referrers from '../pages/Company/Profile/referrers'

const routes = [
  // {
  //   path: '/dashboard',
  //   exact: false,
  //   main: <CompanyDashboard />,
  // },
  {
    path: '/dashboard/:deploymentId/monitoring',
    exact: false,
    main: <CompanyLoanMonitoring />,
  },
  {
    path: '/dashboard/:deploymentId/employees',
    exact: false,
    main: <CompanyLoanEmployees />,
  },
  {
    path: '/dashboard/:deploymentId/monitoring',
    exact: false,
    main: <CompanyLoanMonitoring />,
  },
  {
    path: '/dashboard/:deploymentId/employees',
    exact: false,
    main: <CompanyLoanMonitoring />,
  },
  {
    path: '/dashboard',
    exact: false,
    main: <CompanyDashboard />,
  },
  {
    path: '/profile/infos',
    exact: false,
    main: <CompanyInformations />,
  },
  // {
  //   path: '/profile',
  //   exact: false,
  //   main: <CompanyProfile />,
  // },
  {
    path: '/profile/referrers',
    exact: false,
    main: <Referrers />,
  },
  {
    path: '/profile/sites',
    exact: false,
    main: <Sites />,
  },
  {
    path: '/documents',
    exact: false,
    main: <CompanyDocuments />,
  },
  {
    path: '/employees',
    exact: false,
    main: <CompanyEmployees />,
  },
  {
    path: '/help',
    exact: false,
    main: <CompanyPosts />,
  },
  {
    path: '/monitoring',
    exact: false,
    main: <CompanyLoanMonitoring />,
  },
]

const CompanyRouting: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      {routes.map((route, index) => (
        <PrivateRoute
          protectedToRole={UserRoles.COMPANY}
          key={`${path}-${index}`}
          exact={route.exact}
          path={`${path}${route.path}`}
        >
          {route.main}
        </PrivateRoute>
      ))}
    </Switch>
  )
}

export default CompanyRouting
