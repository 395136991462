import { gql } from '@apollo/client'
import { dateFormatterExport } from '../formatter'
import { GetComunityExportQuery } from '../../__generated__/graphql'

export const QUERY_COMUNITY_EXPORT = gql`
  query GetComunityExport {
    exportMessages {
      id
      image
      date
      creator {
        firstName
        lastName
        user {
          email
        }
      }
    }
  }
`
export const formatComunityExport = (
  comunityExportData: GetComunityExportQuery | undefined,
): any => {
  if (!comunityExportData?.exportMessages?.length) return []

  const header = [
    'Image URL',
    'Date',
    'Createur - Email',
    'Createur - Nom',
    'Createur - Prenom',
  ]

  return [
    header,
    ...comunityExportData?.exportMessages.map(message => ({
      image: message.image,
      date: dateFormatterExport(message.date),
      email: message.creator?.user.email,
      firstName: message.creator?.firstName,
      lastName: message.creator?.lastName,
    })),
  ]
}
