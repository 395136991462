/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { Controller, useForm } from 'react-hook-form'
import { regex } from '@goodwatt/shared'
import { useTheme } from 'emotion-theming'
import { employeeNameFormatter } from '../../../utils/formatter'
import {
  NotificationTypes,
  useNotifications,
} from '../../../contexts/NotificationContext'
import i18n from '../../../i18n/config'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import Modal from '../../../components/Modal'
import Popup from '../../../components/Popup'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Checkbox from '../../../components/Checkbox'
import Icon from '../../../components/Icon'
import { EmployeeCivility } from '@goodwatt/shared/dist/types'
import {
  Civility,
  CompanyReferrer,
  GetCompanyReferrerQuery,
  GetCompanyReferrerQueryVariables,
  UpdateCompanyReferrerMutation,
  UpdateCompanyReferrerMutationVariables,
  CreateCompanyReferrerMutation,
  CreateCompanyReferrerMutationVariables,
  CheckEmailMutation,
  CheckEmailMutationVariables,
} from '../../../__generated__/graphql'

import {
  MUTATION_UDPDATE_COMPANY_REFERRER,
  MUTATION_CREATE_COMPANY_REFERRER,
  MUTATION_CHECK_EMAIL,
} from '../../../utils/gql/mutations'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import { CompanyReferrerTableModal } from '../../../types/CompanyReferrer'
import { UserRoles } from '../../../redux/user'
import { useCurrentCompanyId } from '../../../pages/Animator/Companies/hooks/useCurrentCompanyId'

function ConfirmEmailButton({
  referrer,
  openConfirmEmailModal,
}: {
  referrer: CompanyReferrer
  openConfirmEmailModal: () => void
}) {
  const handleOnClick = useCallback(() => {
    openConfirmEmailModal()
  }, [openConfirmEmailModal])

  if (referrer.user?.emailVerified) {
    return null
  }

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span
        style={{ marginLeft: 8, cursor: 'pointer' }}
        onClick={handleOnClick}
      >
        <Icon type="mailCheck" />
      </span>
    </div>
  )
}

const editSchema = yup.object().shape({
  civility: yup.string().required(i18n.t('forms.errors.required')),
  firstName: yup.string().required(i18n.t('forms.errors.required')),
  lastName: yup.string().required(i18n.t('forms.errors.required')),
  email: yup.string().required(i18n.t('forms.errors.required')),
  phoneNumber: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .length(10, i18n.t('forms.errors.phoneFormat')),
})

const createSchema = yup.object().shape({
  civility: yup.string().required(i18n.t('forms.errors.required')),
  firstName: yup.string().required(i18n.t('forms.errors.required')),
  lastName: yup.string().required(i18n.t('forms.errors.required')),
  email: yup
    .string()
    .email(i18n.t('forms.errors.emailRegex'))
    .required(i18n.t('forms.errors.required')),
  phoneNumber: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .length(10, i18n.t('forms.errors.phoneFormat')),
  password: yup
    .string()
    .matches(regex.passwordRegex, i18n.t('forms.errors.passwordRegex'))
    .required(i18n.t('forms.errors.required')),
  confirmPassword: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .oneOf([yup.ref('password')], i18n.t('forms.errors.passwordsNotIdentical')),
})

interface EditReferrerForm {
  firstName: string
  lastName: string
  civility: Civility
  email: string
  phoneNumber: string
  password: string
  confirmPassword: string
}

interface EditReferrerModalProps {
  referrer: GetCompanyReferrerQuery['companyReferrer'] | null
  refetch: (newData?: Partial<GetCompanyReferrerQueryVariables>) => void
  onModalClose: () => void
  submitCloseModal: () => void
  openConfirmEmailModal?: () => void
  isOpen?: boolean
  type: CompanyReferrerTableModal
  unpersistedForm: {
    password: string
    confirmPassword: string
  }
  setUnpersistedForm: React.Dispatch<
    React.SetStateAction<{
      password: string
      confirmPassword: string
    }>
  >
}

const EditReferrerModal: React.FC<EditReferrerModalProps> = ({
  referrer,
  refetch,
  onModalClose,
  submitCloseModal,
  openConfirmEmailModal = () => {},
  isOpen = true,
  type = CompanyReferrerTableModal.NONE,
  unpersistedForm,
  setUnpersistedForm,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const notifications = useNotifications()
  const { role: userRole } = useSelector((state: StoreState) => state.user)
  const isAdminOrAnimator =
    userRole && [UserRoles.ADMIN, UserRoles.ANIMATOR].includes(userRole)

  let companyId = ''
  const userCompanyId = useSelector(
    (state: StoreState) => state.user?.companyId,
  ) as string
  const animatorCompanyId = useCurrentCompanyId()

  if (isAdminOrAnimator) {
    companyId = animatorCompanyId
  } else {
    companyId = userCompanyId
  }

  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(
      type === CompanyReferrerTableModal.ADD ? createSchema : editSchema,
    ),
    mode: 'onBlur',
  })

  const [checkUserEmail, { loading }] = useMutation<
    CheckEmailMutation,
    CheckEmailMutationVariables
  >(MUTATION_CHECK_EMAIL)

  const [updateReferrer, { loading: updateLoading }] = useMutation<
    UpdateCompanyReferrerMutation,
    UpdateCompanyReferrerMutationVariables
  >(MUTATION_UDPDATE_COMPANY_REFERRER, {
    refetchQueries: ['GetCompanyAllReferrer'],
  })

  const [createReferrer, { loading: creationLoading }] = useMutation<
    CreateCompanyReferrerMutation,
    CreateCompanyReferrerMutationVariables
  >(MUTATION_CREATE_COMPANY_REFERRER, {
    refetchQueries: ['GetCompanyAllReferrer'],
  })

  const onSubmit = (inputData: EditReferrerForm) => {
    setUnpersistedForm({
      confirmPassword: inputData.confirmPassword,
      password: inputData.password,
    })

    if (type === CompanyReferrerTableModal.ADD) {
      checkUserEmail({
        variables: { email: inputData.email },
      })
        .then(result => {
          if (result.data?.verifyUserEmail) {
            createReferrer({
              variables: {
                data: {
                  companyId,
                  civility: inputData.civility,
                  email: inputData.email,
                  firstName: inputData.firstName,
                  lastName: inputData.lastName,
                  password: inputData.password,
                  phoneNumber: inputData.phoneNumber,
                  userId: '',
                },
              },
            })
              .then(() => {
                submitCloseModal()
                refetch()
                notifications.newNotification({
                  type: NotificationTypes.SUCCESS,
                  message: t('shared.notification.referrerCreated'),
                })
              })
              .catch(apiErrorCatcher(notifications))
          }
        })
        .catch(apiErrorCatcher(notifications))
    } else {
      updateReferrer({
        variables: {
          data: {
            id: referrer ? referrer.id : '',
            civility: inputData.civility,
            firstName: inputData.firstName,
            lastName: inputData.lastName,
            phoneNumber: inputData.phoneNumber,
          },
        },
      })
        .then(() => {
          submitCloseModal()
          refetch()
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.notification.successUpdate'),
          })
        })
        .catch(apiErrorCatcher(notifications))
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        maxWidth={750}
        title={
          type === CompanyReferrerTableModal.ADD
            ? t('animator.companies.profile.createReferrer')
            : t('employee.profile.editModalTitle', {
                name: employeeNameFormatter(
                  referrer?.firstName,
                  referrer?.lastName,
                ),
              })
        }
        onClose={onModalClose}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <div css={{ marginRight: 40 }} />
            <Button
              loading={creationLoading || updateLoading || loading}
              type="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {t('forms.button.save')}
            </Button>
          </div>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            css={{
              width: '100%',
              padding: '0 12px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              [theme.media.mobile]: {
                padding: '0',
              },
            }}
          >
            <span
              style={{
                marginLeft: 21,
                marginBottom: 12,
                fontSize: '1.6rem',
                color: '#828282',
                alignSelf: 'flex-start',
              }}
            >
              Civilité
            </span>

            <Controller
              name="civility"
              control={control}
              defaultValue={referrer?.civility}
              render={({ onChange, value }) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    gap: 48,
                    marginLeft: 21,
                  }}
                >
                  <Checkbox
                    isChecked={value === EmployeeCivility.MISTER}
                    label="Monsieur"
                    size="medium"
                    error={!!errors.civility}
                    onClick={() => onChange(EmployeeCivility.MISTER)}
                  ></Checkbox>
                  <Checkbox
                    isChecked={value === EmployeeCivility.MISTRESS}
                    label="Madame"
                    size="medium"
                    error={!!errors.civility}
                    onClick={() => onChange(EmployeeCivility.MISTRESS)}
                  ></Checkbox>
                </div>
              )}
            />

            <div css={{ marginBottom: 24 }} />

            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                gap: '15px',
                width: '100%',
              }}
            >
              <div css={{ flex: 1 }}>
                <Input
                  type="text"
                  name="firstName"
                  defaultValue={referrer?.firstName}
                  label={t('forms.label.firstName')}
                  register={register}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              </div>
              <div css={{ flex: 1 }}>
                <Input
                  type="text"
                  name="lastName"
                  defaultValue={referrer?.lastName}
                  label={t('forms.label.lastName')}
                  register={register}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              </div>
            </div>

            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                gap: '15px',
                width: '100%',
              }}
            >
              <Input
                type="text"
                name="email"
                register={register}
                error={!!errors.email}
                helperText={errors.email?.message}
                defaultValue={referrer?.email}
                label={t('forms.label.email')}
                readOnly={type === CompanyReferrerTableModal.EDIT}
              />

              {referrer?.email &&
                type === CompanyReferrerTableModal.EDIT &&
                isAdminOrAnimator && (
                  <ConfirmEmailButton
                    referrer={referrer as CompanyReferrer}
                    openConfirmEmailModal={openConfirmEmailModal}
                  />
                )}
            </div>

            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                gap: '15px',
                width: '100%',
              }}
            >
              <div css={{ flex: 1 }}>
                <Input
                  type="text"
                  name="phoneNumber"
                  placeholder="ex: 0601010101"
                  label={t('forms.label.phoneNumber')}
                  defaultValue={referrer?.phoneNumber}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                  register={register}
                  onlyNumbers
                  maxLength={10}
                />
              </div>
            </div>

            {type === CompanyReferrerTableModal.ADD && (
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '15px',
                  width: '100%',
                }}
              >
                <div css={{ flex: 1 }}>
                  <Input
                    type="password"
                    label={t('forms.label.password')}
                    name="password"
                    defaultValue={unpersistedForm.password}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    register={register}
                  />
                </div>
                <div css={{ flex: 1 }}>
                  <Input
                    type="password"
                    label={t('forms.label.confirmPassword')}
                    name="confirmPassword"
                    defaultValue={unpersistedForm.confirmPassword}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                    register={register}
                  />
                </div>
              </div>
            )}
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default EditReferrerModal
