/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useCompanySitesWithDeployments } from '../hooks/useCompanySitesWithDeployments'
import { Flex } from '../../../components/Flex'
import CardFoldable from '../../../components/Card/Foldable'
import { GetCompanySitesWithDeploymentsQuery } from '../../../__generated__/graphql'
import { useFilters } from '../../../hooks/useFilters'
import InputSelect from '../../../components/InputSelect'
import { SelectVariant } from '../../../hooks/useVariant/variants/select'
import { useTranslation } from 'react-i18next'
import Input from '../../../components/Input'
import { TextVariant } from '../../../hooks/useVariant/variants/text'
import { useMemo } from 'react'
import { distinctValues } from '../../../utils/distinctValues'
import Table from '../../../components/Table'
import { CellProps, Column } from 'react-table'
import { format } from 'date-fns'
import { NavigationLink } from '../../../components/NavigationLink'
import { useOrderBy } from '../../../hooks/useOrderBy'
import { DeploymentActions } from '../../Animator/Deployment/components/DeploymentActions'
import { DeploymentTypeLabel } from '../../Animator/Deployment/components/DeploymentTypeLabel'
import { CreateSiteModal } from '../../../components/Layout/Menu/CreateSiteModal'

export type CompanySitesListSite = NonNullable<
  GetCompanySitesWithDeploymentsQuery['sites']
>[number]
export type CompanySitesListDeployment =
  CompanySitesListSite['deployments'][number]

export interface CompanySitesListProps {
  companyId: string
  getDeploymentLink?: (deployment: CompanySitesListDeployment) => string
}

export const CompanySitesList: React.FC<CompanySitesListProps> = ({
  companyId,
  getDeploymentLink,
}) => {
  const { data } = useCompanySitesWithDeployments(companyId)

  return (
    <Flex direction="column" gap="32px">
      {data?.sites?.map(site => (
        <CompanySitesListRow
          key={site.id}
          site={site}
          getDeploymentLink={getDeploymentLink}
        />
      ))}
    </Flex>
  )
}

interface Filters {
  offer: string
  stepId: string
  search: string
}

export interface CompanySitesListRowProps
  extends Pick<CompanySitesListProps, 'getDeploymentLink'> {
  site: CompanySitesListSite
}

export const CompanySitesListRow: React.FC<CompanySitesListRowProps> = ({
  site,
  getDeploymentLink,
}) => {
  const { filters, register } = useFilters<Filters>()
  const { orderBy, getTableProps } = useOrderBy()
  const { t } = useTranslation()
  const [selectedSite, setSelectedSite] = React.useState<string | undefined>()
  const onModalClose = () => {
    setSelectedSite(undefined)
  }
  const { offerTypes, steps, stepNumbers } = useMemo(() => {
    return {
      steps: distinctValues(site.deployments, d => d.programStep.id),
      offerTypes: distinctValues(site.deployments, d => d.deploymentType),
      stepNumbers: site.deployments.reduce(
        (p, d) => ({
          ...p,
          [d.programStep.id]: d.programStep.stepNbr,
        }),
        {} as Record<string, number>,
      ),
    }
  }, [site])

  const filtered = useMemo(() => {
    const searchLower = filters.search?.toLowerCase()

    return site.deployments
      .filter(d => {
        if (filters.offer && d.deploymentType !== filters.offer) {
          return false
        }
        if (filters.stepId && d.programStep.id !== filters.stepId) {
          return false
        }
        if (searchLower && !d.name.toLowerCase().includes(searchLower)) {
          return false
        }
        return true
      })
      .map(d => ({
        ...d,
        stepNbr: d.programStep.stepNbr,
      }))
  }, [site, filters])

  const sorted = useMemo(() => {
    if (orderBy == null) return filtered
    const [key, direction] = Object.entries(orderBy)[0]
    const factor = direction === 'asc' ? -1 : 1
    return filtered
      .map(v => ({
        ...v,
        _sortKey: `${v[key as keyof typeof v]}`,
      }))
      .sort((a, b) => a._sortKey.localeCompare(b._sortKey) * factor)
  }, [orderBy, filtered])

  return (
    <CardFoldable
      title={
        <Flex direction="row" align="center" gap="32px">
          <h3 css={{ color: '#4F4F4F', fontSize: '18px' }}>{site.name}</h3>
          <NavigationLink
            to="#"
            onClick={event => {
              event.stopPropagation()
              setSelectedSite(site.id)
            }}
          >
            {t(`animator.companies.progress.edit`)}
          </NavigationLink>
        </Flex>
      }
    >
      <Flex
        direction="row"
        gap="16px"
        align="end"
        css={{ marginTop: '16px', marginBottom: '16px' }}
      >
        <div css={{ flex: 1 }}>
          <InputSelect
            variant={SelectVariant.white}
            label={t(`animator.companies.progress.table.offer`)}
            withError={false}
            defaultValue="all"
            options={[
              {
                value: 'all',
                label: t('animator.companies.filter.options.all_feminine'),
              },
              ...offerTypes.map(o => ({
                value: o,
                label: t(`animator.companies.progress.offerTypes.${o}`),
              })),
            ]}
            {...register('offer')}
          />
        </div>
        <div css={{ flex: 1 }}>
          <InputSelect
            variant={SelectVariant.white}
            label={t(`animator.companies.progress.table.phase`)}
            withError={false}
            defaultValue="all"
            options={[
              {
                value: 'all',
                label: t('animator.companies.filter.options.all_feminine'),
              },
              ...steps.map(step => ({
                value: step,
                label: stepNumbers[step].toFixed(0),
              })),
            ]}
            {...register('stepId')}
          />
        </div>
        <div css={{ flex: 2 }}>
          <Input
            icon="magnifier"
            variant={TextVariant.white}
            withError={false}
            {...register('search')}
          />
        </div>
      </Flex>
      <Table
        {...getTableProps()}
        columns={[
          {
            Header: t(`animator.companies.progress.table.start`) as string,
            Cell: ({ value }: CellProps<CompanySitesListDeployment>) => (
              <span css={{ textWrap: 'wrap' }}>
                {format(new Date(value), 'dd/MM/yyyy')}
              </span>
            ),
            accessor: 'deploymentStartDate',
          },
          {
            Header: t(`animator.companies.progress.table.end`) as string,
            Cell: ({ value }: CellProps<CompanySitesListDeployment>) => (
              <span css={{ textWrap: 'wrap' }}>
                {format(new Date(value), 'dd/MM/yyyy')}
              </span>
            ),
            accessor: 'deploymentEndDate',
          },
          {
            Header: t(`animator.companies.progress.table.name`) as string,
            Cell: ({ value, row }: CellProps<CompanySitesListDeployment>) =>
              getDeploymentLink ? (
                <NavigationLink to={getDeploymentLink(row.original)}>
                  {value}
                </NavigationLink>
              ) : (
                value
              ),
            accessor: 'name',
          },
          {
            Header: t(`animator.companies.progress.table.phase`) as string,
            Cell: ({ value }) => <p style={{ textAlign: 'center' }}>{value}</p>,
            accessor: 'stepNbr',
          },
          {
            Header: t(`animator.companies.progress.table.offer`) as string,
            Cell: ({ value }: CellProps<CompanySitesListDeployment>) => (
              <Flex align="center" justify="center">
                <DeploymentTypeLabel type={value} />
              </Flex>
            ),
            accessor: 'deploymentType',
          },
          {
            Header: t(`animator.companies.progress.table.actions`) as string,
            Cell: ({
              row: { original },
            }: CellProps<CompanySitesListDeployment>) => (
              <DeploymentActions
                deploymentId={original.id}
                stepNbr={original.programStep.stepNbr}
                archived={original.archiveStatus === 'ARCHIVED'}
              />
            ),
            id: 'actions',
            disableSortBy: true,
            cellStyle: () => ({ overflow: 'visible' }),
          } as Column,
        ]}
        data={sorted}
      />
      {!!selectedSite && (
        <CreateSiteModal
          siteId={selectedSite}
          type="update"
          isOpen={!!selectedSite}
          onModalClose={onModalClose}
        />
      )}
    </CardFoldable>
  )
}
