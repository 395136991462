/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { useForm, Controller } from 'react-hook-form'
import { FetchResult } from '@apollo/client'

import Button from '../../../components/Button'
import Input from '../../../components/Input'
import InputSelect from '../../../components/InputSelect'
import Modal from '../../../components/Modal'
import Popup from '../../../components/Popup'
import i18n from '../../../i18n/config'

import {
  NotificationTypes,
  useNotifications,
} from '../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import FileDisplay from '../../../components/FileDisplay'
import { accessibilityDefaultValue as accessibilityDefaultValueAnimator } from '../../Animator/Company/Documents/constants'
import { accessibilityDefaultValue as accessibilityDefaultValueCompany } from '../../Company/Documents/constants'
import {
  AccessibilityAnimator,
  AccessibilityCompany,
  Document,
  UpdateDocumentByAnimatorMutation,
  UpdateDocumentByCompanyMutation,
} from '../../../__generated__/graphql'

const schema = yup.object().shape({
  filename: yup.string().required(i18n.t('forms.errors.required')),
  accessibility: yup.object().shape({
    value: yup.string().required(i18n.t('forms.errors.required')),
  }),
})

interface UpdateDocumentModalProps {
  modalIsOpen: boolean
  closeModal: () => void
  refetch: () => void
  document: Document
  accessibilitySelectOptions: { label: string; value: string }[]
  onUpdate: ({
    title,
    documentId,
    accessibility,
  }: {
    title: string
    documentId: string
    accessibility: AccessibilityAnimator | AccessibilityCompany
  }) => Promise<
    FetchResult<
      UpdateDocumentByAnimatorMutation | UpdateDocumentByCompanyMutation,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>
    >
  >
  loading?: boolean
}

interface FormInput {
  filename: string
  accessibility: {
    value: AccessibilityAnimator | AccessibilityCompany
    label: string
  }
}

const UpdateDocumentModal: React.FC<UpdateDocumentModalProps> = ({
  modalIsOpen,
  closeModal,
  refetch,
  document,
  onUpdate,
  accessibilitySelectOptions,
  loading = false,
}) => {
  const notifications = useNotifications()
  const { t } = useTranslation()
  const { register, handleSubmit, errors, control, reset } = useForm<FormInput>(
    {
      resolver: yupResolver(schema),
    },
  )

  const onSubmit = (data: FormInput) => {
    onUpdate({
      title: data.filename,
      documentId: document?.id,
      accessibility: data.accessibility.value,
    })
      .then(() => {
        refetch()
        reset()
        closeModal()
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t('shared.documents.notifications.docUpdated'),
        })
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={modalIsOpen}>
      <form
        css={{ display: 'flex', flex: 1, justifyContent: 'center' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Popup
          overflowY="visible"
          title={t('shared.documents.updateDocModal.title')}
          closable
          maxWidth={750}
          onClose={closeModal}
          footer={
            <Fragment>
              <Button type="tertiary" onClick={closeModal} disabled={loading}>
                {t('forms.button.cancel')}
              </Button>
              <div css={{ marginLeft: '14px' }}>
                <Button submit loading={loading}>
                  {t('forms.button.confirm')}
                </Button>
              </div>
            </Fragment>
          }
        >
          <FileDisplay
            filename={
              document.filePath.slice(
                document.filePath.lastIndexOf('/') + 1
                  ? document.filePath.lastIndexOf('/') + 1
                  : 0,
                document.filePath.length,
              ) || ''
            }
            size={document?.size || 0}
          />
          <div css={{ marginBottom: 14 }} />
          <Input
            type="text"
            register={register}
            name="filename"
            label={t('shared.documents.docModal.filenameLabel')}
            defaultValue={document.title}
            error={!!errors.filename}
            helperText={errors?.filename?.message}
          />
          <Controller
            name="accessibility"
            control={control}
            defaultValue={
              accessibilityDefaultValueAnimator(
                (document?.restrictions || []) as string[],
              ) ||
              accessibilityDefaultValueCompany(
                (document?.restrictions || []) as string[],
              )
            }
            render={({ onChange, onBlur, value, name }) => {
              return (
                <InputSelect
                  value={value}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={!!errors.accessibility}
                  helperText={errors?.accessibility?.value?.message}
                  label={t('shared.documents.docModal.accessibility')}
                  placeholder={t('shared.placeholder.inputSelect')}
                  options={accessibilitySelectOptions}
                />
              )
            }}
          />
        </Popup>
      </form>
    </Modal>
  )
}

export default UpdateDocumentModal
