/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../../components/Modal'
import Button from '../../../../../components/Button'
import Popup from '../../../../../components/Popup'

interface LoanMaxQuantityReachModalProps {
  closeModal: () => void
  companyLoanQuantityBikes: number
}

const LoanMaxQuantityReachModal: React.FC<LoanMaxQuantityReachModalProps> = ({
  closeModal,
  companyLoanQuantityBikes,
}) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen>
      <Popup
        closable
        title={t('animator.company.employees.subscribed.modal.title')}
        onClose={closeModal}
        footer={
          <Button type="primary" submit onClick={closeModal}>
            {t('animator.company.employees.subscribed.modal.confirm')}
          </Button>
        }
      >
        <div css={{ textAlign: 'center' }}>
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                'animator.company.employees.subscribed.modal.companyLoanMax',
                {
                  companyLoanMax: companyLoanQuantityBikes,
                },
              ),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t(
                'animator.company.employees.subscribed.modal.updateCompanyLoanMax',
              ),
            }}
          />
        </div>
      </Popup>
    </Modal>
  )
}

export default LoanMaxQuantityReachModal
