/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../../../../components/Card'
import { Flex } from '../../../../components/Flex'
import cargoImage from '../../Application/assets/cargo.png'
import urbanImage from '../../Application/assets/urban.png'
import foldingImage from '../../Application/assets/folding.png'

enum SpecialBikesType {
  UrbanBike = 'URBAN_BIKE',
  FoldingBike = 'FOLDING_BIKE',
  CargoBike = 'CARGO_BIKE',
}

const images = {
  [SpecialBikesType.CargoBike]: cargoImage,
  [SpecialBikesType.UrbanBike]: urbanImage,
  [SpecialBikesType.FoldingBike]: foldingImage,
}

export const GoodwattBikes: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Card
      css={{ flex: 1 }}
      title={
        <span
          css={{
            fontWeight: 'bold',
            fontSize: '1.8rem',
            lineHeight: '20px',
            fontFamily: 'Raleway',
          }}
        >
          {t('employee.deployments.bikes')}
        </span>
      }
    >
      <Flex
        direction="column"
        align="start"
        css={{ marginTop: '16px', marginBottom: '6px' }}
      >
        {Object.values(SpecialBikesType).map((value, index) => {
          return (
            <Flex
              direction="row"
              gap="10px"
              key={index}
              css={{ marginBottom: '10px' }}
            >
              <div css={{ flex: 1, minWidth: '110px' }}>
                <img
                  src={images[value]}
                  alt={t(`shared.specialBikes.abbreviation.${value}`)}
                  css={{ height: '70px' }}
                />
              </div>
              <div css={{ flex: 2 }}>
                {t(`shared.specialBikes.abbreviation.${value}`)} :{' '}
                <span
                  css={{
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                    color: '#979797',
                  }}
                >
                  {t(`shared.specialBikes.description.${value}`)}
                </span>
              </div>
            </Flex>
          )
        })}
      </Flex>
    </Card>
  )
}
