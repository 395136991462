export enum QuizTableModalActions {
  EDIT = 'edit',
  TRASH = 'trash',
  VIEW = 'view',
}

export enum QuizTableAdd {
  ADD = 'add',
}

export interface TagTableRow {
  id: string
  key: string
}

export interface QuizSettings<
  D = QuizTableRow,
  T = QuizTableModalActions | QuizTableAdd,
> {
  quiz: D
  actionName: '' | T
}

export interface QuizTableRow {
  id: string
  title: string
  content: string
  MIME: string
  imageURL: string
}

export interface ModalQuizSubmit {
  title: string
  content: string
  MIME: string
  image: FileList | null
  imageURL: string
}
