/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'
import Button from '../../../../components/Button'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import theme from '../../../../styles/theme'
import InputSelect from '../../../../components/InputSelect'

import i18n from '../../../../i18n/config'
import { QUERY_AREAS } from '../../../../utils/gql/queries'
import { MUTATION_DELETE_AREA } from '../../../../utils/gql/mutations'

import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import {
  AreaSearch,
  DeleteAreaMutation,
  DeleteAreaMutationVariables,
  GetAreaSearchQueryVariables,
  GetAreasQuery,
} from '../../../../__generated__/graphql'

interface AreaEditModalProps {
  area: AreaSearch | null
  refetch: (newData?: Partial<GetAreaSearchQueryVariables>) => void

  closeModal: () => void
  modalIsOpen?: boolean
}

const AreaDeleteModal: React.FC<AreaEditModalProps> = ({
  area,
  refetch,

  closeModal,
  modalIsOpen = true,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()

  const [newArea, setNewArea] = useState<number | null>()

  const onUpdateArea = useCallback(
    async ({ value }) => {
      setNewArea(value)
    },
    [setNewArea],
  )

  const [deleteArea, { loading }] = useMutation<
    DeleteAreaMutation,
    DeleteAreaMutationVariables
  >(MUTATION_DELETE_AREA)

  const onSubmit = async () => {
    if (loading) return
    if (!newArea || !area?.areaId) return

    await deleteArea({
      variables: {
        areaId: area.areaId,
        newAreaId: newArea,
      },
    })
      .then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: i18n.t('admin.area.notification.successDelete'),
        })
        refetch()
        closeModal()
      })
      .catch(apiErrorCatcher(notifications))
  }

  const { data: areaData } = useQuery<GetAreasQuery>(QUERY_AREAS)

  const areaOptions = areaData?.areas.map(x => ({
    label: x.name || '',
    value: x.id || '',
  }))

  return (
    <Modal isOpen={modalIsOpen}>
      <Popup
        maxWidth={750}
        css={{ color: theme.colors.redLegend }}
        title={t('admin.area.modal.deleteTitle')}
        titleColor={theme.colors.redLegend}
        onClose={closeModal}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Button type="tertiaryError" onClick={closeModal}>
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginRight: 16 }} />
            <Button type="primaryError" loading={loading} onClick={onSubmit}>
              {t('forms.button.delete')}
            </Button>
          </div>
        }
      >
        <p
          css={{
            textAlign: 'center',
            marginBottom: 24,
            marginTop: 12,
            marginLeft: 48,
            marginRight: 48,
          }}
        >
          {t('admin.area.modal.deleteTextline1')}
          <span css={{ fontWeight: 'bold' }}> {area?.name} </span>?
        </p>
        <p
          css={{
            textAlign: 'center',
            marginBottom: 24,
            marginTop: 12,
            marginLeft: 48,
            marginRight: 48,
          }}
        >
          {t('admin.area.modal.deleteTextline2')}
        </p>
        <form onSubmit={onSubmit}>
          <InputSelect
            name="area"
            options={areaOptions || []}
            onChange={area => onUpdateArea(area)}
          />
        </form>
      </Popup>
    </Modal>
  )
}

export default AreaDeleteModal
