/** @jsx jsx */
import { useLazyQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import { ProgramStepType } from '@goodwatt/shared/dist/types'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'

import Icon, { IconTypes } from '../../Icon'

import {
  NotificationTypes,
  useNotifications,
} from '../../../contexts/NotificationContext'

import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import { QUERY_GET_EMPLOYEE } from '../../../utils/gql/queries'

import {
  CompanyEmployeesModalSettings,
  CompanySelectedEmployeesTableRow,
  CompanySelectedEmployeesTableModal,
  CompanySelectedEmployeesTableRowDetails,
} from '../../../types/AnimatorCompanyEmployees'
import {
  EmployeeLoanActions,
  GetEmployeeQuery,
  GetEmployeeQueryVariables,
} from '../../../__generated__/graphql'

type ActionIconProps = {
  type: IconTypes
  modal: CompanySelectedEmployeesTableModal
  color?: string
  strokeColor?: string
  customSize?: { width: number; height: number }
} | null

interface SelectecEmployeeActionsCellProps {
  setModal: (
    obj: CompanyEmployeesModalSettings<
      GetEmployeeQuery['employee'],
      CompanySelectedEmployeesTableModal
    >,
  ) => void
  row: Row<
    CompanySelectedEmployeesTableRow &
      Partial<CompanySelectedEmployeesTableRowDetails>
  >
  loanEventsFormatted: {
    loanReturnDate: string
    loanDeliveryDate: string
  }
  currentProgramStep: ProgramStepType
  hasBike?: boolean
}

let currActionName: CompanySelectedEmployeesTableModal | null = null

const CompanySelectedEmployeeActionsCell: React.FC<
  SelectecEmployeeActionsCellProps
> = ({
  setModal,
  row,
  loanEventsFormatted,
  currentProgramStep,
  hasBike = true,
}) => {
  const theme = useTheme()
  const notifications = useNotifications()
  const { t } = useTranslation()
  const [getEmployee] = useLazyQuery<
    GetEmployeeQuery,
    GetEmployeeQueryVariables
  >(QUERY_GET_EMPLOYEE, {
    onCompleted: data => {
      if (data?.employee) {
        setModal({
          employee: data.employee,
          actionName: currActionName as CompanySelectedEmployeesTableModal,
        })
      }
    },
    onError: error =>
      apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
    fetchPolicy: 'network-only',
  })

  const canLoanBike = React.useCallback(() => {
    if (
      loanEventsFormatted.loanReturnDate === '...' ||
      loanEventsFormatted.loanDeliveryDate === '...'
    ) {
      notifications.newNotification({
        type: NotificationTypes.WARNING,
        message: t(
          'animator.company.employees.selected.modals.employeeLoanBikeModal.warnNoDateSelected',
        ),
      })
      return false
    }
    /*
    Optionnal now
    if (
      !row.original.identityCardVerified ||
      !row.original.proofOfAddressVerified
    ) {
      notifications.newNotification({
        type: NotificationTypes.WARNING,
        message: t(
          'animator.company.employees.selected.modals.employeeLoanBikeModal.warnEmployeeNotVerified'
        ),
      });
      return false;
    }
    */
    /*
    Caution is optionnal now :
    if (!row.original.depositId || !row.original.depositExpirationDate) {
      notifications.newNotification({
        type: NotificationTypes.WARNING,
        message: t(
          'animator.company.employees.selected.modals.employeeLoanBikeModal.warnEmployeeDepositNotFound'
        ),
      });
      return false;
    }

    if (new Date() > new Date(row.original.depositExpirationDate)) {
      notifications.newNotification({
        type: NotificationTypes.WARNING,
        message: t(
          'animator.company.employees.selected.modals.employeeLoanBikeModal.warnEmployeeDepositNotValid'
        ),
      });
      return false;
    }
    */
    return true
  }, [
    loanEventsFormatted.loanDeliveryDate,
    loanEventsFormatted.loanReturnDate,
    t,
    notifications,
  ])

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    actionName: CompanySelectedEmployeesTableModal,
  ) => {
    e.stopPropagation()
    if (
      actionName !== CompanySelectedEmployeesTableModal.LOAN_BIKE ||
      canLoanBike()
    ) {
      currActionName = actionName
      getEmployee({ variables: { where: { id: row.original.id } } })
    }
  }

  const renderFirstAction = () => {
    let iconProps: ActionIconProps = null
    if (
      currentProgramStep === ProgramStepType.PREPARING_LOAN ||
      currentProgramStep === ProgramStepType.EMPLOYEE_LOANS_SELECTION ||
      !hasBike
    ) {
      iconProps = {
        modal: CompanySelectedEmployeesTableModal.WITHDRAWAL,
        type: 'circleWithCross',
        color: theme.colors.gray7,
        strokeColor: theme.colors.gray2,
      }
    }

    return iconProps ? (
      <div
        css={{ cursor: 'pointer', marginRight: '7px' }}
        onClick={e =>
          handleClick(e, (iconProps as NonNullable<ActionIconProps>).modal)
        }
      >
        <Icon {...iconProps} />
      </div>
    ) : null
  }

  const renderLoanAction = () => {
    let iconProps: ActionIconProps

    switch (row.original.loanPossibleAction) {
      case EmployeeLoanActions.CanReceiveBike:
        iconProps = {
          modal: CompanySelectedEmployeesTableModal.LOAN_BIKE,
          type: 'loanOfABike',
          color: theme.colors.success,
          customSize: { width: 30, height: 30 },
        }
        break
      case EmployeeLoanActions.CanReturnPackage:
        iconProps = {
          modal: CompanySelectedEmployeesTableModal.RETURN_PACKAGE,
          type: 'returnPackage',
          color: theme.colors.warning,
          customSize: { width: 30, height: 30 },
        }
        break
      case EmployeeLoanActions.CanReturnBike:
        iconProps = {
          modal: CompanySelectedEmployeesTableModal.RETURN_BIKE,
          type: 'returnOfABike',
          color: theme.colors.warning,
          customSize: { width: 30, height: 30 },
        }
        break
      case EmployeeLoanActions.RetentionOnDeposit:
        iconProps = {
          modal: CompanySelectedEmployeesTableModal.RETENTION,
          type: 'euro',
          color: row.original.estimatedTotal
            ? theme.colors.error
            : theme.colors.greenLegend,
        }
        break
      case EmployeeLoanActions.NullRetentionOnDeposit:
        iconProps = null
        break
      default:
        iconProps = null
        break
    }

    return iconProps ? (
      <div
        css={{ cursor: 'pointer', marginRight: '7px' }}
        onClick={e =>
          handleClick(e, (iconProps as NonNullable<ActionIconProps>).modal)
        }
      >
        <Icon {...iconProps} />
      </div>
    ) : null
  }

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      {renderFirstAction()}
      {renderLoanAction()}
      {!row.original.hasReturnBike && (
        <div
          css={{ cursor: 'pointer', marginRight: '7px' }}
          onClick={e =>
            handleClick(e, CompanySelectedEmployeesTableModal.APPOINTMENT)
          }
        >
          <Icon type="calendarAlt" />
        </div>
      )}
      <div
        css={{ cursor: 'pointer' }}
        onClick={e => handleClick(e, CompanySelectedEmployeesTableModal.EDIT)}
      >
        <Icon type="anonymProfile" />
      </div>
    </div>
  )
}

export default CompanySelectedEmployeeActionsCell
