/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useCurrentDeploymentId } from '../hooks/useCurrentDeploymentId'
import { useModal } from '../../../../hooks/useModal'
import UnArchiveDeploymentModal from './UnArchiveDeploymentModal'

export interface UnArchiveDeploymentButtonProps {
  id?: string
}

export const UnArchiveDeploymentButton: React.FC<
  UnArchiveDeploymentButtonProps
> = ({ id }: UnArchiveDeploymentButtonProps) => {
  const defaultId = useCurrentDeploymentId()
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <UnArchiveDeploymentModal id={id ?? defaultId} {...modalProps} />
      <button onClick={onOpen}>
        <Icon color="transparent" strokeColor="black" type="unArchive" />
      </button>
    </Fragment>
  )
}
