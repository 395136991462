/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useCurrentDeploymentId } from '../hooks/useCurrentDeploymentId'
import { useModal } from '../../../../hooks/useModal'
import ArchiveDeploymentModal from './ArchiveDeploymentModal'

export interface ArchiveDeploymentButtonProps {
  id?: string
  archived: boolean
}

export const ArchiveDeploymentButton: React.FC<
  ArchiveDeploymentButtonProps
> = ({ id, archived }) => {
  const defaultId = useCurrentDeploymentId()
  const { onOpen, modalProps } = useModal()

  return archived ? (
    <Icon color="transparent" strokeColor="grey" type="archive" />
  ) : (
    <Fragment>
      <ArchiveDeploymentModal id={id ?? defaultId} {...modalProps} />
      <button onClick={onOpen}>
        <Icon color="transparent" strokeColor="black" type="archive" />
      </button>
    </Fragment>
  )
}
