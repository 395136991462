/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useEmployeeEmployeeDeployments } from './hooks/useEmployeeEmployeeDeployments'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import Card from '../../../components/Card'
import theme from '../../../styles/theme'
import { useTranslation } from 'react-i18next'
import logo from '../../../assets/images/goodwatt_color.svg'
import { Flex } from '../../../components/Flex'
import { DeploymentTypeIcon } from '../../Animator/Shared/DeploymentTypeIcon'
import { format } from 'date-fns'

export const EmployeeOffer: React.FC = () => {
  const { t } = useTranslation()
  const userId = useSelector((state: StoreState) => state.user.id)
  const { data } = useEmployeeEmployeeDeployments(userId)

  const deployment = data?.employee?.employeeDeployments?.[0]?.deployment

  return (
    <Card
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          marginBottom: 8,
          alignSelf: 'start',
          fontSize: '1.6rem',
          color: theme.colors.gray2,
          fontWeight: 'bold',
        }}
      >
        {t('employee.offer.title')}
      </div>

      <Flex
        flex={1}
        direction="column"
        align="center"
        justify="space-between"
        gap="8px"
      >
        <Flex direction="column" align="center" gap="8px">
          {deployment && (
            <div
              css={{
                color: theme.colors.gray2,
                textAlign: 'center',
              }}
            >
              {t(`employee.offer.name`)} {deployment.name}
            </div>
          )}
          <img src={logo} alt="Logo" />
          {deployment && (
            <span
              css={{
                color: theme.colors.gray1,
              }}
            >
              {t(`employee.offer.type.${deployment.deploymentType}`)}
            </span>
          )}
        </Flex>
        {deployment && (
          <Flex direction="row" align="center" gap="8px">
            <DeploymentTypeIcon type={deployment.deploymentType} />
            <Flex
              direction="column"
              align="center"
              css={{
                color: theme.colors.gray2,
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              <p>
                {t('employee.offer.from')}
                {format(new Date(deployment.deploymentStartDate), 'dd/MM/yyyy')}
              </p>
              <p>
                {t('employee.offer.until')}
                {format(new Date(deployment.deploymentEndDate), 'dd/MM/yyyy')}
              </p>
            </Flex>
          </Flex>
        )}
        <a
          href="/employee/help"
          css={{
            color: theme.colors.primary,
            textDecoration: 'underline',
            fontSize: '14px',
            lineHeight: '18px',
          }}
        >
          {t('employee.offer.contactUs')}
        </a>
      </Flex>
    </Card>
  )
}
