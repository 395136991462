/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../redux/rootReducer'
import { useDeploymentsAvailables } from '../hooks/useDeployments'
import { usePagination } from '../../../../hooks/usePagination'
import { useOrderBy } from '../../../../hooks/useOrderBy'
import {
  Appointment,
  DeploymentType,
  GetEmployeeDashboardQuery,
  GetEmployeeDashboardQueryVariables,
  SortOrder,
} from '../../../../__generated__/graphql'
import { useTranslation } from 'react-i18next'
import Table from '../../../../components/Table'
import { DeploymentTypeIcon } from '../../../Animator/Shared/DeploymentTypeIcon'
import { differenceInDays, format } from 'date-fns'
import { QUERY_GET_EMPLOYEE_DASHBOARD } from '../../../../utils/gql/queries'
import Checkbox from '../../../../components/Checkbox'
import Button from '../../../../components/Button'
import { Flex } from '../../../../components/Flex'
import { useModal } from '../../../../hooks/useModal'
import DeleteEmployeeDeploymentModal from './DeleteEmployeeDeploymentModal'
import ModalAbsent from './ModalAbsent'
import { CellProps, Column } from 'react-table'
import { Link, useHistory } from 'react-router-dom'

type DeploymentOrderByField = 'name' | 'startDate' | 'endDate' | 'offer'

export const DeploymentsList: React.FC = () => {
  const { t } = useTranslation()
  const company = useSelector((state: StoreState) => state.user.companyId)
  const userId = useSelector((state: StoreState) => state.user.id)
  const pagination = usePagination({ take: 50 })
  const { orderBy, getTableProps } = useOrderBy()
  const [selectedDeploymentId, setselectedDeploymentId] = useState('')
  const [selectedEmployeeDeploymentId, setselectedEmployeeDeploymentId] =
    useState('')
  const [selectedDeploymentInfos, setselectedDeploymentInfos] = useState({
    name: '',
    type: '',
    startDate: '',
    endDate: '',
  })

  const [selectedAppointement, setSelectedAppointement] = useState(
    {} as Appointment,
  )

  const { onOpen: onOpenDelete, modalProps: modalPropsDelete } = useModal()
  const { onOpen: onOpenAbsent, modalProps: modalPropsAbsent } = useModal()

  const history = useHistory()

  const onSubmit = async () => {
    const url = `/employee/application/${selectedDeploymentId}`
    history.push(url)
  }
  const { data: employee } = useQuery<
    GetEmployeeDashboardQuery,
    GetEmployeeDashboardQueryVariables
  >(QUERY_GET_EMPLOYEE_DASHBOARD, {
    fetchPolicy: 'network-only',
    variables: {
      where: { userId: userId },
    },
  })

  const variables = {
    company: company,
    siteId: employee?.employee?.sites[0]?.id,
    archived: false,
    ...pagination.variables,
  }

  const { data: deployments, loading } = useDeploymentsAvailables({
    variables: {
      ...variables,
      orderBy: { deploymentStartDate: SortOrder.Asc },
    },
  })

  const onDeploymentSelect = (deploymentId: string) => {
    if (selectedDeploymentId === deploymentId) {
      setselectedDeploymentId('')
      return
    }
    setselectedDeploymentId(deploymentId)

    const deploymentInfos = findDeploymentInfos(deployments, deploymentId, t)

    deploymentId !== '' &&
      setselectedDeploymentInfos({
        ...deploymentInfos,
      })
  }

  const onDeleteEmployeeDeployment = (
    deploymentId: string,
    employeeDeploymentId: string,
  ) => {
    setselectedDeploymentId(deploymentId)
    setselectedEmployeeDeploymentId(employeeDeploymentId)

    const deploymentInfos = findDeploymentInfos(deployments, deploymentId, t)
    deploymentId !== '' &&
      setselectedDeploymentInfos({
        ...deploymentInfos,
      })

    onOpenDelete()
  }

  const onReportAbsent = (appointment: Appointment) => {
    setSelectedAppointement(appointment)
    onOpenAbsent()
  }

  const formattedData = useMemo(() => {
    if (!deployments || !deployments.deployments) {
      return []
    }

    let result = deployments.deployments.map(deployment => {
      const loanDeliveryAppointment = employee?.employee?.appointments.find(
        (appointment: any) =>
          appointment.deploymentId === deployment?.id &&
          appointment.category === 'LOAN_DELIVERY',
      )

      const LoanDeliveryAbsence = employee?.employee?.absences.find(
        (absence: any) =>
          absence.deploymentId === deployment?.id &&
          absence.category === 'LOAN_DELIVERY',
      )

      const loanReturnAppointment = employee?.employee?.appointments.find(
        (appointment: any) =>
          appointment.deploymentId === deployment?.id &&
          appointment.category === 'LOAN_RETURN',
      )

      const loanReturnAbsence = employee?.employee?.absences.find(
        (absence: any) =>
          absence.deploymentId === deployment?.id &&
          absence.category === 'LOAN_RETURN',
      )

      let startDate = null

      if (loanDeliveryAppointment?.date) {
        startDate = loanDeliveryAppointment?.date
      } else if (LoanDeliveryAbsence?.date) {
        startDate = LoanDeliveryAbsence?.date
      }

      let endDate = null

      if (loanReturnAppointment?.date) {
        endDate = loanReturnAppointment?.date
      } else if (loanReturnAbsence?.date) {
        endDate = loanReturnAbsence?.date
      }

      return {
        action: getAction(
          deployment,
          employee?.employee,
          selectedDeploymentId,
          onDeploymentSelect,
        ),
        offer: (
          <DeploymentTypeIcon
            type={deployment?.deploymentType as DeploymentType}
          />
        ),
        name: deployment?.name ? deployment?.name : '',

        startDate: startDate
          ? format(new Date(startDate), "dd/MM/yyyy à HH'h'mm")
          : format(new Date(deployment?.deploymentStartDate), 'dd/MM/yyyy'),

        endDate: endDate
          ? format(new Date(endDate), "dd/MM/yyyy à HH'h'mm")
          : format(new Date(deployment?.deploymentEndDate), 'dd/MM/yyyy'),

        status: getStatus(
          deployment,
          employee?.employee,
          t,
          onDeleteEmployeeDeployment,
          onReportAbsent,
        ),
        offerOrderBy: deployment?.deploymentType
          ? t(`shared.deploymentTypes.${deployment?.deploymentType}`)
          : '',
        startDateOrderBy: loanDeliveryAppointment?.date
          ? loanDeliveryAppointment?.date
          : deployment?.deploymentStartDate,
        endDateOrderBy: loanReturnAppointment?.date
          ? loanReturnAppointment?.date
          : deployment?.deploymentEndDate,
      }
    })

    for (const [key, value] of Object.entries(orderBy || [])) {
      const castKey = key as DeploymentOrderByField

      if (castKey === 'name') {
        result = result.sort((a, b) => {
          return a.name.localeCompare(b.name) * (value === 'asc' ? 1 : -1)
        })
      } else if (castKey === 'offer') {
        result = result.sort((a, b) => {
          return (
            a.offerOrderBy.localeCompare(b.offerOrderBy) *
            (value === 'asc' ? 1 : -1)
          )
        })
      } else if (castKey === 'startDate') {
        result = result.sort((a, b) => {
          return (
            (Date.parse(a.startDateOrderBy) - Date.parse(b.startDateOrderBy)) *
            (value === 'asc' ? 1 : -1)
          )
        })
      } else if (castKey === 'endDate') {
        result = result.sort((a, b) => {
          return (
            (Date.parse(a.endDateOrderBy) - Date.parse(b.endDateOrderBy)) *
            (value === 'asc' ? 1 : -1)
          )
        })
      }
    }

    return result
  }, [deployments, employee, selectedDeploymentId, orderBy, t])

  const columns = useMemo<Column[]>(() => {
    let columnValues = [
      {
        Header: t('employee.deployments.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        maxWidth: 100,
        Cell: ({ row }: CellProps<typeof formattedData[number]>) => {
          return <Flex justify="center">{row.original.action}</Flex>
        },
      },
      {
        Header: t('employee.deployments.table.headers.offer') as string,
        accessor: 'offer',
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: t('employee.deployments.table.headers.deployment') as string,
        accessor: 'name',
        maxWidth: 200,
        cellStyle: () => ({
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }),
      },
      {
        Header: t('employee.deployments.table.headers.startDate') as string,
        accessor: 'startDate',
      },
      {
        Header: t('employee.deployments.table.headers.endDate') as string,
        accessor: 'endDate',
      },
      {
        Header: t('employee.deployments.table.headers.status') as string,
        accessor: 'status',
        disableSortBy: true,
      },
    ]
    return columnValues
  }, [t, formattedData])

  return (
    <Flex
      direction="column"
      css={{
        width: '100%',
      }}
    >
      <div
        css={{
          minHeight: 150,
          display: 'flex',
          width: '100%',
        }}
      >
        <Table
          {...getTableProps()}
          columns={columns}
          data={formattedData}
          loading={loading}
        />
      </div>

      <div
        css={{
          marginTop: '20px',
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Button
          loading={loading}
          type="primary"
          submit
          disabled={selectedDeploymentId === ''}
          onClick={onSubmit}
        >
          {t('employee.deployments.submit')}
        </Button>
      </div>

      <DeleteEmployeeDeploymentModal
        id={selectedEmployeeDeploymentId}
        deploymentName={selectedDeploymentInfos.name}
        deploymentType={selectedDeploymentInfos.type}
        startDate={selectedDeploymentInfos.startDate}
        endDate={selectedDeploymentInfos.endDate}
        {...modalPropsDelete}
      />

      {employee?.employee?.contractUrl && employee?.employee?.id && (
        <ModalAbsent
          appointment={selectedAppointement}
          contractUrl={employee?.employee?.contractUrl}
          employeeId={employee?.employee?.id}
          {...modalPropsAbsent}
        />
      )}
    </Flex>
  )
}

const getAction = (
  deployment: any,
  employee: any,
  selectedDeploymentId: string,
  onDeploymentSelect: any,
): JSX.Element => {
  if (!deployment || !employee) {
    return <span></span>
  }

  const today = new Date()
  const start = new Date(deployment.deploymentStartDate).getTime()
  const daysBeforeStart = differenceInDays(start, today)

  const allStatus = ['CANDIDATE', 'SELECTED', 'BENEFICIARY']

  const employeeStatus: string = deployment.employeeDeployments.find(
    (employeeDeployment: any) => employeeDeployment.employeeId === employee.id,
  )?.status

  const stepNbr = deployment.programStep.stepNbr

  if (stepNbr === 2) {
    if (allStatus.includes(employeeStatus))
      return <Checkbox isChecked={true} size="medium" onClick={() => void 0} />
    else
      return (
        <Checkbox
          isChecked={selectedDeploymentId === deployment.id}
          size="medium"
          onClick={() => onDeploymentSelect(deployment.id)}
        />
      )
  }

  if (stepNbr > 2) {
    if (allStatus.includes(employeeStatus))
      return (
        <Checkbox
          isChecked={true}
          size="medium"
          onClick={() => void 0}
          color="gray"
        />
      )
    else if (daysBeforeStart >= 0 && stepNbr < 5)
      return (
        <Checkbox
          isChecked={selectedDeploymentId === deployment.id}
          size="medium"
          onClick={() => onDeploymentSelect(deployment.id)}
        />
      )
    else return <span />
  }

  return <span />
}

const getStatus = (
  deployment: any,
  employee: any,
  t: any,
  onDeleteEmployeeDeployment: any,
  onReportAbsent: any,
): JSX.Element => {
  if (!deployment || !employee) {
    return <span></span>
  }
  const deploymentId = deployment.id
  const stepNbr = deployment.programStep.stepNbr

  const loanDeliveryAppointment = employee.appointments.find(
    (appointment: any) =>
      appointment.deploymentId === deploymentId &&
      appointment.category === 'LOAN_DELIVERY',
  )

  const LoanDeliveryAbsence = employee.absences.find(
    (absence: any) =>
      absence.deploymentId === deploymentId &&
      absence.category === 'LOAN_DELIVERY',
  )

  const loanReturnAppointment = employee.appointments.find(
    (appointment: any) =>
      appointment.deploymentId === deploymentId &&
      appointment.category === 'LOAN_RETURN',
  )

  const loanReturnAbsence = employee.absences.find(
    (absence: any) =>
      absence.deploymentId === deploymentId &&
      absence.category === 'LOAN_RETURN',
  )

  const employeeDeployment = deployment.employeeDeployments.find(
    (employeeDeployment: any) => employeeDeployment.employeeId === employee.id,
  )

  const employeeStatus = employeeDeployment?.status.toLowerCase()
  const employeeDeploymentId = employeeDeployment?.id

  const bikeLoan = employee.bikeLoan.find(
    (bl: any) => bl.employeeDeployment?.id === employeeDeploymentId,
  )

  const bikeReturned = bikeLoan?.bikeReturned

  const today = new Date()

  const deliveryDate = loanDeliveryAppointment
    ? new Date(loanDeliveryAppointment.date).getTime()
    : 0
  const returnDate = loanReturnAppointment
    ? new Date(loanReturnAppointment.date).getTime()
    : 0
  const daysBeforeDelivery = loanDeliveryAppointment
    ? differenceInDays(deliveryDate, today)
    : 0
  const daysBeforeReturn = loanReturnAppointment
    ? differenceInDays(returnDate, today)
    : 0

  if (employeeStatus === 'candidate' && stepNbr < 5) {
    return (
      <div>
        {t(`company.employees.table.employeeStatus.${employeeStatus}`)} <br />
        <Link
          to="#"
          css={{ color: 'red' }}
          onClick={() =>
            onDeleteEmployeeDeployment(deploymentId, employeeDeploymentId)
          }
        >
          {t('forms.button.cancel')}
        </Link>
      </div>
    )
  }

  if (employeeStatus === 'selected' && stepNbr >= 2 && LoanDeliveryAbsence) {
    return (
      <div>
        {t(`company.employees.table.employeeStatus.${employeeStatus}`)} <br />
        <span css={{ fontSize: '14px', fontWeight: 700 }}>
          {t('employee.dashboard.deliveryAbsent')}
        </span>
      </div>
    )
  }

  if (
    employeeStatus === 'selected' &&
    stepNbr >= 2 &&
    loanDeliveryAppointment &&
    daysBeforeDelivery >= 0
  ) {
    return (
      <div>
        {t(`company.employees.table.employeeStatus.${employeeStatus}`)} <br />
        <Link
          to="#"
          css={{ color: 'red', fontSize: '12px' }}
          onClick={() => onReportAbsent(loanDeliveryAppointment)}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t('employee.dashboard.reportDeliveryAbsent'),
            }}
          />
        </Link>
      </div>
    )
  }

  if (
    employeeStatus === 'beneficiary' &&
    stepNbr > 3 &&
    stepNbr <= 5 &&
    loanReturnAbsence
  ) {
    return (
      <div>
        {t(`company.employees.table.employeeStatus.${employeeStatus}`)} <br />
        <span css={{ fontSize: '14px', fontWeight: 700 }}>
          {t('employee.dashboard.returnAbsent')}
        </span>
        <br />
        {bikeReturned === true && (
          <span css={{ color: 'grey', fontSize: '12px', fontWeight: 700 }}>
            {t('employee.dashboard.bikeReturned')}
          </span>
        )}
      </div>
    )
  }

  if (
    employeeStatus === 'beneficiary' &&
    stepNbr > 3 &&
    stepNbr <= 5 &&
    loanReturnAppointment &&
    daysBeforeReturn >= 0
  ) {
    return (
      <div>
        {t(`company.employees.table.employeeStatus.${employeeStatus}`)} <br />
        {bikeReturned === true ? (
          <span css={{ color: 'grey', fontSize: '12px', fontWeight: 700 }}>
            {t('employee.dashboard.bikeReturned')}
          </span>
        ) : (
          <Link
            to="#"
            css={{ color: 'red', fontSize: '12px' }}
            onClick={() => onReportAbsent(loanReturnAppointment)}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t('employee.dashboard.reportReturnAbsent'),
              }}
            />
          </Link>
        )}
      </div>
    )
  }

  return employeeStatus ? (
    <div>
      {t(`company.employees.table.employeeStatus.${employeeStatus}`)}
      <br />
      {bikeReturned === true && (
        <span css={{ color: 'grey', fontSize: '12px', fontWeight: 700 }}>
          {t('employee.dashboard.bikeReturned')}
        </span>
      )}
    </div>
  ) : (
    <span></span>
  )
}

const findDeploymentInfos = (
  deployments: any,
  deploymentId: string,
  t: any,
): any => {
  if (!deployments || !deploymentId || deployments.deployments.length === 0) {
    return { name: '', type: '', startDate: '', endDate: '' }
  }

  const deploymentType = deployments.deployments.find(
    (deployment: any) => deployment.id === deploymentId,
  )?.deploymentType
  const deploymentStartDate = deployments.deployments.find(
    (deployment: any) => deployment.id === deploymentId,
  )?.deploymentStartDate
  const deploymentEndDate = deployments.deployments.find(
    (deployment: any) => deployment.id === deploymentId,
  )?.deploymentEndDate

  return {
    name:
      deployments.deployments.find(
        (deployment: any) => deployment.id === deploymentId,
      )?.name || '',
    type: deploymentType ? t(`shared.deploymentTypes.${deploymentType}`) : '',
    startDate: deploymentStartDate
      ? format(new Date(deploymentStartDate), 'dd/MM/yyyy')
      : '',
    endDate: deploymentEndDate
      ? format(new Date(deploymentEndDate), 'dd/MM/yyyy')
      : '',
  }
}

export default DeploymentsList
