/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex } from '../../../../components/Flex'
import theme from '../../../../styles/theme'
import {
  BikeModel,
  Deployment,
  DeploymentBikeModelPrice,
  DeploymentType,
  BabySeatOptions,
} from '../../../../__generated__/graphql'

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { displayBikeModelImage } from '../../../../utils/files'

export interface BikeModelCardProps {
  css?: Record<string, any>
  choosenOption?: BabySeatOptions
  deployment: Pick<
    Deployment,
    'id' | 'deploymentType' | 'deploymentStartDate' | 'deploymentEndDate'
  >
  pricing: Pick<
    DeploymentBikeModelPrice,
    'id' | 'remainingPrice' | 'childSeat'
  > & {
    bikemodel: Pick<
      BikeModel,
      | 'bikeType'
      | 'name'
      | 'description'
      | 'publicPrice'
      | 'weight'
      | 'brand'
      | 'modelSizes'
      | 'pictureUrl'
    >
  }
}

export const BikeModelCard: React.FC<BikeModelCardProps> = ({
  deployment,
  pricing,
  choosenOption,
  children,
  ...rest
}) => {
  const bikeModel = pricing.bikemodel
  const childSeat = !!pricing.childSeat
  const { t } = useTranslation()

  const hasChildSeat = useMemo(() => {
    return childSeat
  }, [childSeat])

  const bikeOption = (
    choosenOption: BabySeatOptions | undefined,
    hasChildSeat: boolean,
    t: (key: string) => string,
  ): string => {
    if (choosenOption) {
      return `${t('shared.babySeat.label')} : ${t(
        `shared.babySeatOptions.${choosenOption}`,
      )}`
    } else {
      return hasChildSeat
        ? `${t('employee.application.possibleOptions')} : ${t(
            'employee.application.childSeat',
          )}`
        : `${t('employee.application.possibleOptions')} : ${t(
            'employee.application.none',
          )}`
    }
  }

  const bikeSize = (
    choosenOption: BabySeatOptions | undefined,
    bikeModel: any,
    t: (key: string) => string,
  ): string => {
    if (choosenOption && bikeModel.modelSizes) {
      const sizes = bikeModel.modelSizes.join(',')
      return `${t('employee.application.size')} : ${sizes}`
    }
    return ''
  }

  const bikeWeight = (
    choosenOption: BabySeatOptions | undefined,
    bikeModel: any,
    t: (key: string) => string,
  ): string => {
    if (choosenOption && bikeModel.weight) {
      return `${t('employee.application.weight')} : ${bikeModel.weight} kg`
    }
    return ''
  }

  return (
    <Flex
      css={{
        position: 'relative',
        background: 'white',
        borderRadius: '16px',
        padding: '16px',
        boxShadow: '4px 8px 20px 0px #00000026',
        color: theme.colors.gray3,
      }}
      direction="column"
      {...rest}
    >
      <div
        css={{
          position: 'absolute',
          color:
            deployment.deploymentType === DeploymentType.Rental
              ? '#4F4F4F'
              : '#D2D2D2',
          border: `1px solid ${
            deployment.deploymentType === DeploymentType.Rental
              ? '#4F4F4F'
              : '#D2D2D2'
          }`,
          borderRadius: '32px',
          padding: '8px 16px',
          right: '16px',
          background: 'white',
        }}
      >
        {t(
          deployment.deploymentType === DeploymentType.Rental
            ? 'employee.application.rental'
            : 'employee.application.discovery',
        )}
      </div>
      <div
        css={{
          minHeight: '180px',
          backgroundImage:
            'url(' +
            displayBikeModelImage(bikeModel.pictureUrl || undefined) +
            ')',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left center',
        }}
      ></div>
      <Flex
        direction="column"
        css={{
          position: 'relative',
          margin: '16px -16px 0 -16px',
          padding: '8px 32px',
          background: '#3CD9F11A',
          color: theme.colors.gray2,
          borderBottom: `1px solid ${theme.colors.primary}`,
          borderTop: `1px solid ${theme.colors.primary}`,
          fontSize: '14px',
        }}
      >
        <p>
          {t(`shared.specialBikes.abbreviation.${bikeModel.bikeType}`)}
          <br />
          <span
            css={{
              fontWeight: 500,
              color: theme.colors.primary,
            }}
          >
            {bikeModel.brand}
          </span>
          <br />
          {t('employee.application.model')} : {bikeModel.name}
          <span css={{ marginLeft: '20px' }}>
            {bikeSize(choosenOption, bikeModel, t)}
          </span>
          <span css={{ marginLeft: '20px' }}>
            {bikeWeight(choosenOption, bikeModel, t)}
          </span>
        </p>

        {deployment.deploymentType === DeploymentType.Rental && (
          <Flex
            direction="column"
            gap="5px"
            css={{
              position: 'absolute',
              textAlign: 'center',
              fontSize: '9px',
              padding: '12px 8px',
              borderRadius: '8px',
              right: '16px',
              top: '-50px',
              width: '71px',
              color: theme.colors.white,
              background: theme.colors.primary,
            }}
          >
            <p>{t('employee.application.remainder')}</p>
            <Flex
              direction="row"
              align="end"
              justify="between"
              css={{ fontWeight: 500 }}
            >
              <span css={{ fontSize: '20px' }}>{pricing.remainingPrice}</span>
              <span>{t('employee.application.euroPerMonth')}</span>
            </Flex>
          </Flex>
        )}
      </Flex>

      <Flex
        direction="column"
        css={{
          position: 'relative',
          margin: '0 -16px',
          padding: '8px 32px',
          background: '#3CD9F11A',
          color: theme.colors.gray2,
          borderBottom: `1px solid ${theme.colors.primary}`,
          fontSize: '14px',
        }}
      >
        <p>
          {t('employee.application.from')}
          {format(new Date(deployment.deploymentStartDate), 'dd/MM/yyyy')}
          <span> </span>
          {t('employee.application.to')}
          {format(new Date(deployment.deploymentEndDate), 'dd/MM/yyyy')}
        </p>
      </Flex>
      <Flex
        direction="column"
        css={{
          position: 'relative',
          margin: '0 -16px 16px -16px',
          padding: '8px 32px',
          background: '#3CD9F11A',
          color: theme.colors.gray2,
          borderBottom: `1px solid ${theme.colors.primary}`,
          fontSize: '14px',
        }}
      >
        <p>
          {t('employee.application.public_price')} : {bikeModel.publicPrice}{' '}
          &euro; HT
          <br />
          {bikeOption(choosenOption, hasChildSeat, t)}
        </p>
      </Flex>
      <p css={{ fontSize: '13px', textAlign: 'justify' }}>
        {bikeModel.description}
      </p>
      {children}
    </Flex>
  )
}
