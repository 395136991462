"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyType = exports.AppointmentCategoryForDeployment = exports.DeploymentType = exports.SpecialBikesType = exports.RewardLevel = exports.VerifyDocActionsType = exports.UserRoles = exports.TravelModeType = exports.StateOfUseType = exports.RestrictionType = exports.ProgramStepType = exports.ParkingType = exports.EmployeeDocumentsType = exports.EmployeeBikeHabit = exports.CompanyUploadRestrictionsType = exports.CompanyMonitoringType = exports.RejectionReasonType = exports.CompanyMonitoringStatusType = exports.CompanyStepType = exports.BikeConditionType = exports.BikeRideCategoryType = exports.EmployeeCivility = exports.PackageAccessoryType = exports.BikeComponentPartType = exports.UploadOptionsTypes = void 0;
var UploadOptionsTypes;
(function (UploadOptionsTypes) {
    UploadOptionsTypes["ANIMATOR"] = "ANIMATOR";
    UploadOptionsTypes["REFERRER"] = "REFERRER";
    UploadOptionsTypes["EMPLOYEE"] = "EMPLOYEE";
    UploadOptionsTypes["ANIMATOR_REFERRER"] = "ANIMATOR_REFERRER";
    UploadOptionsTypes["ANIMATOR_EMPLOYEE"] = "ANIMATOR_EMPLOYEE";
    UploadOptionsTypes["REFERRER_EMPLOYEE"] = "REFERRER_EMPLOYEE";
})(UploadOptionsTypes = exports.UploadOptionsTypes || (exports.UploadOptionsTypes = {}));
var BikeComponentPartType;
(function (BikeComponentPartType) {
    BikeComponentPartType["BIKE_FRAME"] = "bikeFrame";
    BikeComponentPartType["BRAKES"] = "brakes";
    BikeComponentPartType["BACK_WHEEL"] = "backWheel";
    BikeComponentPartType["FRONT_WHEEL"] = "frontWheel";
    BikeComponentPartType["TRANSMISSIONS"] = "transmissions";
    BikeComponentPartType["HANDLEBAR"] = "handlebar";
    BikeComponentPartType["DEVICES"] = "devices";
    BikeComponentPartType["BATTERY"] = "battery";
    BikeComponentPartType["GLOBAL"] = "global";
})(BikeComponentPartType = exports.BikeComponentPartType || (exports.BikeComponentPartType = {}));
var PackageAccessoryType;
(function (PackageAccessoryType) {
    PackageAccessoryType["LOCK"] = "lock";
    PackageAccessoryType["BATTERY_CHARGER"] = "batteryCharger";
    PackageAccessoryType["SATCHELS"] = "satchels";
    PackageAccessoryType["SMARTPHONE_HOLDER"] = "smartphoneHolder";
    PackageAccessoryType["RAIN_ACCESSORIES"] = "rainAccessories";
    PackageAccessoryType["HELMET"] = "helmet";
    PackageAccessoryType["KIDS_HELMET"] = "kidsHelmet";
    PackageAccessoryType["BABY_SEAT"] = "babySeat";
    PackageAccessoryType["FRONT_RACK"] = "frontRack";
})(PackageAccessoryType = exports.PackageAccessoryType || (exports.PackageAccessoryType = {}));
var EmployeeCivility;
(function (EmployeeCivility) {
    EmployeeCivility["MISTER"] = "MISTER";
    EmployeeCivility["MISTRESS"] = "MISTRESS";
    EmployeeCivility["NONE"] = "NONE";
})(EmployeeCivility = exports.EmployeeCivility || (exports.EmployeeCivility = {}));
var BikeRideCategoryType;
(function (BikeRideCategoryType) {
    BikeRideCategoryType["HOME_WORK"] = "homeWork";
    BikeRideCategoryType["OTHER"] = "other";
})(BikeRideCategoryType = exports.BikeRideCategoryType || (exports.BikeRideCategoryType = {}));
var BikeConditionType;
(function (BikeConditionType) {
    BikeConditionType["STOCK"] = "stock";
    BikeConditionType["ON_LOAN"] = "onLoan";
    BikeConditionType["REPARATION"] = "reparation";
    BikeConditionType["OUT_OF_SERVICE"] = "outOfService";
    BikeConditionType["STOLEN"] = "stolen";
    BikeConditionType["SOLD"] = "sold";
    BikeConditionType["DEMONSTRATION"] = "demonstration";
})(BikeConditionType = exports.BikeConditionType || (exports.BikeConditionType = {}));
var CompanyStepType;
(function (CompanyStepType) {
    CompanyStepType["LOAN"] = "loan";
    CompanyStepType["TRAINING"] = "training";
    CompanyStepType["RETURN"] = "return";
    CompanyStepType["NONE"] = "none";
})(CompanyStepType = exports.CompanyStepType || (exports.CompanyStepType = {}));
var CompanyMonitoringStatusType;
(function (CompanyMonitoringStatusType) {
    CompanyMonitoringStatusType["NOT_STARTED"] = "notStarted";
    CompanyMonitoringStatusType["IN_PROGRESS"] = "inProgress";
    CompanyMonitoringStatusType["COMPLETED"] = "completed";
    CompanyMonitoringStatusType["NONE"] = "none";
})(CompanyMonitoringStatusType = exports.CompanyMonitoringStatusType || (exports.CompanyMonitoringStatusType = {}));
var RejectionReasonType;
(function (RejectionReasonType) {
    RejectionReasonType["OTHER"] = "other";
    RejectionReasonType["LOW_CYCLING_TERRITORY"] = "lowCyclingTerritory";
    RejectionReasonType["SMALL_STAFF"] = "smallStaff";
})(RejectionReasonType = exports.RejectionReasonType || (exports.RejectionReasonType = {}));
var CompanyMonitoringType;
(function (CompanyMonitoringType) {
    CompanyMonitoringType[CompanyMonitoringType["AUTO"] = 0] = "AUTO";
    CompanyMonitoringType[CompanyMonitoringType["MANUAL"] = 1] = "MANUAL";
})(CompanyMonitoringType = exports.CompanyMonitoringType || (exports.CompanyMonitoringType = {}));
var CompanyUploadRestrictionsType;
(function (CompanyUploadRestrictionsType) {
    CompanyUploadRestrictionsType["REFERRER"] = "REFERRER";
    CompanyUploadRestrictionsType["REFERRER_EMPLOYEE"] = "REFERRER_EMPLOYEE";
})(CompanyUploadRestrictionsType = exports.CompanyUploadRestrictionsType || (exports.CompanyUploadRestrictionsType = {}));
var EmployeeBikeHabit;
(function (EmployeeBikeHabit) {
    EmployeeBikeHabit["NEVER"] = "never";
    EmployeeBikeHabit["RARELY"] = "rarely";
    EmployeeBikeHabit["TIME_TO_TIME"] = "timeToTime";
    EmployeeBikeHabit["REGULARLY"] = "regularly";
})(EmployeeBikeHabit = exports.EmployeeBikeHabit || (exports.EmployeeBikeHabit = {}));
var EmployeeDocumentsType;
(function (EmployeeDocumentsType) {
    EmployeeDocumentsType["IDENTITY_CARD"] = "IDENTITY_CARD";
    EmployeeDocumentsType["PROOF_OF_ADDRESS"] = "PROOF_OF_ADDRESS";
    EmployeeDocumentsType["DEPOSIT"] = "DEPOSIT";
})(EmployeeDocumentsType = exports.EmployeeDocumentsType || (exports.EmployeeDocumentsType = {}));
var ParkingType;
(function (ParkingType) {
    ParkingType["NONE"] = "none";
    ParkingType["OUTSIDE_WITH_KEY"] = "outside_with_key";
    ParkingType["INSIDE_WITH_KEY"] = "inside_with_key";
})(ParkingType = exports.ParkingType || (exports.ParkingType = {}));
var ProgramStepType;
(function (ProgramStepType) {
    ProgramStepType[ProgramStepType["REFUSED"] = -1] = "REFUSED";
    ProgramStepType[ProgramStepType["PENDING"] = 0] = "PENDING";
    ProgramStepType[ProgramStepType["CONTRACTUALIZATION"] = 1] = "CONTRACTUALIZATION";
    ProgramStepType[ProgramStepType["EMPLOYEE_LOANS_SELECTION"] = 2] = "EMPLOYEE_LOANS_SELECTION";
    ProgramStepType[ProgramStepType["PREPARING_LOAN"] = 3] = "PREPARING_LOAN";
    ProgramStepType[ProgramStepType["LOAN_BIKES_IN_PROGRESS"] = 4] = "LOAN_BIKES_IN_PROGRESS";
    ProgramStepType[ProgramStepType["PROGRAM_REVIEW"] = 5] = "PROGRAM_REVIEW";
})(ProgramStepType = exports.ProgramStepType || (exports.ProgramStepType = {}));
var RestrictionType;
(function (RestrictionType) {
    RestrictionType["ANIMATOR_W"] = "animator:write";
    RestrictionType["ANIMATOR_R"] = "animator:read";
    RestrictionType["ANIMATOR_D"] = "animator:delete";
    RestrictionType["COMPANY_W"] = "company:write";
    RestrictionType["COMPANY_R"] = "company:read";
    RestrictionType["COMPANY_D"] = "company:delete";
    RestrictionType["EMPLOYEE_W"] = "employee:write";
    RestrictionType["EMPLOYEE_R"] = "employee:read";
    RestrictionType["EMPLOYEE_D"] = "employee:delete";
})(RestrictionType = exports.RestrictionType || (exports.RestrictionType = {}));
var StateOfUseType;
(function (StateOfUseType) {
    StateOfUseType["EXCELLENT"] = "excellent";
    StateOfUseType["GOOD_CONDITION"] = "goodCondition";
    StateOfUseType["USED"] = "used";
    StateOfUseType["OUT_OF_USE"] = "outOfUse";
    StateOfUseType["MISSED"] = "missed";
})(StateOfUseType = exports.StateOfUseType || (exports.StateOfUseType = {}));
var TravelModeType;
(function (TravelModeType) {
    TravelModeType["NONE"] = "none";
    TravelModeType["BIKE"] = "bike";
    TravelModeType["ELECTRIC_BIKE"] = "electicBike";
    TravelModeType["WALKING"] = "walking";
    TravelModeType["PUBLIC_TRANSPORTATION"] = "publicTransportation";
    TravelModeType["CARPOOLING"] = "carpooling";
    TravelModeType["MOTORISED_TWO_WHEELS"] = "motorisedTwoWheels";
    TravelModeType["CAR"] = "car";
})(TravelModeType = exports.TravelModeType || (exports.TravelModeType = {}));
var UserRoles;
(function (UserRoles) {
    UserRoles["EMPLOYEE"] = "EMPLOYEE";
    UserRoles["COMPANY"] = "COMPANY";
    UserRoles["ANIMATOR"] = "ANIMATOR";
    UserRoles["ADMIN"] = "ADMIN";
})(UserRoles = exports.UserRoles || (exports.UserRoles = {}));
var VerifyDocActionsType;
(function (VerifyDocActionsType) {
    VerifyDocActionsType["VERIFIED"] = "VERIFIED";
    VerifyDocActionsType["REJECTED"] = "REJECTED";
})(VerifyDocActionsType = exports.VerifyDocActionsType || (exports.VerifyDocActionsType = {}));
var RewardLevel;
(function (RewardLevel) {
    RewardLevel["BEGINNER"] = "BEGINNER";
    RewardLevel["INTERMEDIATE"] = "INTERMEDIATE";
    RewardLevel["CONFIRMED"] = "CONFIRMED";
    RewardLevel["NONE"] = "NONE";
    RewardLevel["SPECIFIC"] = "SPECIFIC";
})(RewardLevel = exports.RewardLevel || (exports.RewardLevel = {}));
var SpecialBikesType;
(function (SpecialBikesType) {
    SpecialBikesType["URBAN_BIKE"] = "URBAN_BIKE";
    SpecialBikesType["FOLDING_BIKE"] = "FOLDING_BIKE";
    SpecialBikesType["CARGO_BIKE"] = "CARGO_BIKE";
})(SpecialBikesType = exports.SpecialBikesType || (exports.SpecialBikesType = {}));
var DeploymentType;
(function (DeploymentType) {
    DeploymentType["Discovery"] = "DISCOVERY";
    DeploymentType["None"] = "NONE";
    DeploymentType["Rental"] = "RENTAL";
})(DeploymentType = exports.DeploymentType || (exports.DeploymentType = {}));
var AppointmentCategoryForDeployment;
(function (AppointmentCategoryForDeployment) {
    AppointmentCategoryForDeployment["LoanDelivery"] = "LOAN_DELIVERY";
    AppointmentCategoryForDeployment["LoanReturn"] = "LOAN_RETURN";
})(AppointmentCategoryForDeployment = exports.AppointmentCategoryForDeployment || (exports.AppointmentCategoryForDeployment = {}));
var CompanyType;
(function (CompanyType) {
    CompanyType["Association"] = "ASSOCIATION";
    CompanyType["ClubCompany"] = "CLUB_COMPANY";
    CompanyType["Colectivity"] = "COLECTIVITY";
    CompanyType["Company"] = "COMPANY";
    CompanyType["Healthcare"] = "HEALTHCARE";
    CompanyType["None"] = "NONE";
    CompanyType["Other"] = "OTHER";
    CompanyType["PublicAdministration"] = "PUBLIC_ADMINISTRATION";
})(CompanyType = exports.CompanyType || (exports.CompanyType = {}));
