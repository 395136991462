import React from 'react'

export enum EmployeeTableTypes {
  SELECTED = 'selected',
  ALL = 'all',
}

const initalContext: TableRefetchContextType = {
  refetchTables: () => null,
  removeTableToFetch: () => null,
  tableRefetchPlanner: {
    [EmployeeTableTypes.SELECTED]: false,
    [EmployeeTableTypes.ALL]: false,
  },
}

export type TableRefetchContextType = {
  refetchTables: (tables: EmployeeTableTypes[]) => void
  removeTableToFetch: (table: EmployeeTableTypes) => void
  tableRefetchPlanner: { [tables in EmployeeTableTypes]: boolean }
}

export const EmployeeTableRefetchContext =
  React.createContext<TableRefetchContextType>(initalContext)

const EmployeeTableRefetchProvider: React.FC = ({ children }) => {
  const [needToRefetch, setNeedToRefetch] = React.useState<
    EmployeeTableTypes[]
  >([])

  const tableRefetchPlanner = React.useMemo(
    () => ({
      [EmployeeTableTypes.SELECTED]: needToRefetch.includes(
        EmployeeTableTypes.SELECTED,
      ),
      [EmployeeTableTypes.ALL]: needToRefetch.includes(EmployeeTableTypes.ALL),
    }),
    [needToRefetch],
  )

  const removeTableToFetch = React.useCallback((table: EmployeeTableTypes) => {
    setNeedToRefetch(prevState => prevState.filter(t => t !== table))
  }, [])

  const refetchTables = React.useCallback(
    (tablesToRefetch: EmployeeTableTypes[]) => {
      setNeedToRefetch(tablesToRefetch)
    },
    [],
  )

  return (
    <EmployeeTableRefetchContext.Provider
      value={{ refetchTables, tableRefetchPlanner, removeTableToFetch }}
    >
      {children}
    </EmployeeTableRefetchContext.Provider>
  )
}

export default EmployeeTableRefetchProvider
