/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'
import Modal from '../../../../../components/Modal'
import Popup from '../../../../../components/Popup'

import { RefusedCompaniesTableRow } from '../../../../../types/AnimatorCompanies'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../../contexts/NotificationContext'
import { TableTypes } from '../TableRefetchContext'

import apiErrorCatcher from '../../../../../utils/apiErrorCatcher'
import {
  UndoCompanyRefusalMutation,
  UndoCompanyRefusalMutationVariables,
} from '../../../../../__generated__/graphql'

const MUTATION_UNDO_COMPANY_REFUSAL = gql`
  mutation UndoCompanyRefusal($companyId: String!) {
    undoCompanyRefusal(companyId: $companyId)
  }
`

interface UndoModalProps {
  isOpen: boolean
  closeModal: (tablesToRefetch?: TableTypes[]) => void
  company: RefusedCompaniesTableRow
}

const UndoModal: React.FC<UndoModalProps> = ({
  isOpen,
  closeModal,
  company,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [undoCompanyRefusal, { loading }] = useMutation<
    UndoCompanyRefusalMutation,
    UndoCompanyRefusalMutationVariables
  >(MUTATION_UNDO_COMPANY_REFUSAL)

  const handleSubmit = () => {
    if (company?.id) {
      undoCompanyRefusal({ variables: { companyId: company.id } })
        .then(() => {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t(
              'animator.companies.undoCompanyRefusal.successNotification',
              { companyName: company.name },
            ),
          })
          closeModal([TableTypes.REFUSED, TableTypes.PENDING])
        })
        .catch(apiErrorCatcher(notifications))
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        closable
        title={t('animator.companies.undoCompanyRefusal.title')}
        onClose={closeModal as () => void}
        footer={
          <React.Fragment>
            <div css={{ marginRight: 14 }}>
              <Button
                disabled={loading}
                onClick={() => closeModal()}
                type="tertiary"
              >
                {t('shared.button.cancel')}
              </Button>
            </div>
            <Button
              submit
              type="primary"
              loading={loading}
              onClick={handleSubmit}
            >
              {t('shared.button.confirm')}
            </Button>
          </React.Fragment>
        }
      >
        <div
          css={{
            padding: 20,
            textAlign: 'center',
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t('animator.companies.undoCompanyRefusal.description', {
                companyName: company.name,
              }),
            }}
          />
        </div>
      </Popup>
    </Modal>
  )
}

export default UndoModal
