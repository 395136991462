/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  PackageAccessoryType,
  StateOfUseType,
} from '@goodwatt/shared/dist/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Column, Row } from 'react-table'

import Table from '../../../../../../components/Table'
import PackageAccessoryStarRatingCell from '../../../../../../components/Table/CustomCells/PackageAccessoryStarRatingCell'
import PartsConditionActionCell from '../../../../../../components/Table/CustomCells/PartsConditionActionCell'
import SelectAccessoryCell from '../../../../../../components/Table/CustomCells/SelectAccessoryCell'
import PartModal from '../Shared/PartModal'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../../../contexts/NotificationContext'
import { setLoanBikeForm } from '../../../../../../redux/forms'
import { StoreState } from '../../../../../../redux/rootReducer'
import {
  PartsConditionModalSettings,
  PartsConditionTableModal,
  PartsConditionTableRow,
} from '../../../../../../types/AnimatorCompanyEmployees'

const i18nLoanAccessoriesConditionNamespace =
  'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeLoanAccessoriesCondition'

const initialModalSettings: PartsConditionModalSettings = {
  part: {
    id: '',
    name: '',
    stateOfUse: StateOfUseType.EXCELLENT,
    note: '',
    attachments: undefined,
  },
  actionName: PartsConditionTableModal.NONE,
}

const LoanAccessoriesCondition: React.FC = () => {
  const dispatch = useDispatch()
  const notifications = useNotifications()
  const { accessories } = useSelector(
    (state: StoreState) => state.forms.loanBikeForm,
  )
  const [currModal, setCurrModal] = React.useState(initialModalSettings)
  const { t } = useTranslation()

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<PartsConditionTableRow> }) => (
      <PartsConditionActionCell
        setModal={setCurrModal}
        part={row.original}
        isIncluded={
          accessories[row.original.name as PackageAccessoryType].included
        }
      />
    ),
    [accessories],
  )

  const SelectAccessoryNameCell = React.useCallback(
    ({ row }: { row: Row<PartsConditionTableRow> }) => {
      const isSelected = [
        PackageAccessoryType.BATTERY_CHARGER,
        PackageAccessoryType.SATCHELS,
        PackageAccessoryType.HELMET,
        PackageAccessoryType.LOCK,
        PackageAccessoryType.RAIN_ACCESSORIES,
        PackageAccessoryType.SMARTPHONE_HOLDER,
      ].includes(row.original.name as PackageAccessoryType)

      return (
        <SelectAccessoryCell accessory={row.original} selected={isSelected} />
      )
    },
    [],
  )

  const LoanPackageRatingCell = React.useCallback(
    ({ row }: { row: Row<PartsConditionTableRow> }) => (
      <PackageAccessoryStarRatingCell accessory={row.original} />
    ),
    [],
  )

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: t(
          `${i18nLoanAccessoriesConditionNamespace}.table.headers.accessory`,
        ) as string,
        accessor: 'name',
        disableSortBy: true,
        maxWidth: 200,
        Cell: SelectAccessoryNameCell,
      },
      {
        Header: t(
          `${i18nLoanAccessoriesConditionNamespace}.table.headers.conditionDescription`,
        ) as string,
        accessor: 'stateOfUse',
        disableSortBy: true,
        Cell: LoanPackageRatingCell,
      },
      {
        Header: t(
          `${i18nLoanAccessoriesConditionNamespace}.table.headers.actions`,
        ) as string,
        accessor: 'action',
        disableSortBy: true,
        maxWidth: 95,
        Cell: ActionCell,
      },
    ],
    [t, SelectAccessoryNameCell, LoanPackageRatingCell, ActionCell],
  )

  const formattedData = React.useMemo(() => {
    return Object.entries(accessories).map(([accessoryName, value]) => ({
      id: value.id,
      name: accessoryName,
      stateOfUse: value.stateOfUse,
      action: '',
      note: value.note,
      attachments: value.attachments,
    }))
  }, [accessories])

  const onNoteModalSubmit = (textAreaValue: string, partName: string) => {
    dispatch(
      setLoanBikeForm({
        accessories: {
          ...accessories,
          [partName as PackageAccessoryType]: {
            ...accessories[partName as PackageAccessoryType],
            note: textAreaValue,
          },
        },
      }),
    )
    notifications.newNotification({
      type: NotificationTypes.SUCCESS,
      message: t('shared.notification.willUpdateOnEnding'),
    })
    setCurrModal(initialModalSettings)
  }
  const partModalTitle = React.useMemo(() => {
    const partName = t(
      `shared.loanPackageAccessoryLabel.${currModal.part.name}`,
    )
    return `${t(
      `${i18nLoanAccessoriesConditionNamespace}.bikeLoanAccessoryModal.title`,
      { partName },
    )}`
  }, [currModal.part.name, t])

  return (
    <React.Fragment>
      <div css={{ minHeight: 300, padding: '0 25px' }}>
        <Table columns={columns} data={formattedData} />
      </div>
      <PartModal
        isOpen={
          currModal.actionName === PartsConditionTableModal.NOTE ||
          currModal.actionName === PartsConditionTableModal.CAMERA
        }
        shouldOpenCameraInput={
          currModal.actionName === PartsConditionTableModal.CAMERA
        }
        closeAutomaticCameraInput={() =>
          setCurrModal({
            ...currModal,
            actionName: PartsConditionTableModal.NOTE,
          })
        }
        closeModal={() => setCurrModal(initialModalSettings)}
        title={partModalTitle}
        componentType="loanPackage"
        label={t(`${i18nLoanAccessoriesConditionNamespace}.bikeLoanAccessoryModal.noteLabel`)} //prettier-ignore
        part={currModal.part}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFormAction={setLoanBikeForm as any} // FIXME: any
        onSubmit={onNoteModalSubmit}
      />
    </React.Fragment>
  )
}

export default LoanAccessoriesCondition
