/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { Link } from 'react-router-dom'
import LogoImg from '../assets/images/logo.png'

const AuthContainer: React.FC = ({ children }) => {
  const theme = useTheme()

  return (
    <div
      css={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '70px 0',
        backgroundColor: theme.colors.background,
        [theme.media.mobile]: {
          backgroundColor: theme.colors.white,
          padding: 0,
        },
      }}
    >
      {/* Logo */}
      <div
        css={{
          margin: '45px 0',
          [theme.media.mobile]: {
            marginBottom: 0,
          },
        }}
      >
        <Link to="/">
          <img src={LogoImg} css={{ width: 220 }} alt="logo" />
        </Link>
      </div>
      {children}
    </div>
  )
}

export default AuthContainer
