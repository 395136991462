/** @jsx jsx */
import { jsx } from '@emotion/core'

import Card from '../../../components/Card'
import Icon, { IconProps } from '../../../components/Icon'
import { Flex } from '../../../components/Flex'
import theme from '../../../styles/theme'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import {
  GetDeploymentFirstAppointmentQuery,
  GetDeploymentFirstAppointmentQueryVariables,
} from '../../../__generated__/graphql'
import { QUERY_DEPLOYMENT_FIRST_APPOINTMENT } from '../../../utils/gql/queries'
import { format } from 'date-fns'

import { useCurrentDeploymentId } from '../../Animator/Deployment/hooks/useCurrentDeploymentId'

interface EmployeeInfoCardProps {
  iconProps: IconProps
  title: string
  location: string
}

const EmployeeInfoCard: React.FC<EmployeeInfoCardProps> = ({
  title,
  location,
  iconProps,
}) => {
  return (
    <Card
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        backgroundColor: '#3CD9F1',
      }}
    >
      <Icon {...iconProps} />
      <p
        css={{
          color: 'white',
          fontSize: '14px',
          lineHeight: '14px',
          textAlign: 'center',
        }}
      >
        {title}
        <br />
        {location}
      </p>
    </Card>
  )
}

const EmployeeAppointment: React.FC = () => {
  const { t } = useTranslation()
  const deploymentId = useCurrentDeploymentId()

  const { data } = useQuery<
    GetDeploymentFirstAppointmentQuery,
    GetDeploymentFirstAppointmentQueryVariables
  >(QUERY_DEPLOYMENT_FIRST_APPOINTMENT, {
    variables: {
      id: deploymentId,
    },
  })

  const deploymentStartDate = data?.deployment?.deploymentStartDate
    ? {
        date: data?.deployment?.deploymentStartDate,
        location: data?.deployment?.startLocation,
      }
    : null
  const deploymentEndDate = data?.deployment?.deploymentEndDate
    ? {
        date: data?.deployment?.deploymentEndDate,
        location: data?.deployment?.endLocation,
      }
    : null
  const firstDeliveryAppointment = data?.deployment?.firstDeliveryAppointment
    ? data?.deployment?.firstDeliveryAppointment
    : deploymentStartDate
  const firstReturnAppointment = data?.deployment?.firstReturnAppointment
    ? data?.deployment?.firstReturnAppointment
    : deploymentEndDate

  return (
    <div>
      <Flex>
        <EmployeeInfoCard
          iconProps={{
            type: 'bike',
            color: theme.colors.yellowLegend,
            size: 'large',
          }}
          title={t('company.card.bikeLoan', {
            date: firstDeliveryAppointment
              ? format(new Date(firstDeliveryAppointment?.date), 'dd/MM/yyyy')
              : '',
            hour: firstDeliveryAppointment
              ? format(new Date(firstDeliveryAppointment?.date), 'HH:mm')
              : '',
          })}
          location={firstDeliveryAppointment?.location ?? ''}
        />
        <EmployeeInfoCard
          iconProps={{
            type: 'returnCalendar',
            color: theme.colors.yellowLegend,
            size: 'large',
          }}
          title={t('company.card.bikeReturn', {
            date: firstReturnAppointment
              ? format(new Date(firstReturnAppointment?.date), 'dd/MM/yyyy')
              : '',
            hour: firstReturnAppointment
              ? format(new Date(firstReturnAppointment?.date), 'HH:mm')
              : '',
          })}
          location={firstReturnAppointment?.location ?? ''}
        />
      </Flex>
    </div>
  )
}

export default EmployeeAppointment
