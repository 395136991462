/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { Row } from 'react-table'
import React from 'react'

import Icon from '../../Icon'

import {
  BikeModalSettings,
  BikesTableRow,
  BikeTableModal,
} from '../../../types/AnimatorBikes'

interface BikesActionCellProps {
  setModal: (obj: BikeModalSettings<BikesTableRow, BikeTableModal>) => void
  row: Row<BikesTableRow>
}

const BikesActionCell: React.FC<BikesActionCellProps> = ({ setModal, row }) => {
  const theme = useTheme()
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: BikeTableModal,
    ) => {
      e.stopPropagation()
      setModal({ bike: row.original, actionName })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <div
        css={{ cursor: 'pointer', marginLeft: 8 }}
        onClick={e => handleClick(e, BikeTableModal.EDIT)}
      >
        <Icon type="edit" color={theme.colors.gray2} />
      </div>
      <div
        css={{ cursor: 'pointer', marginLeft: 8 }}
        onClick={e => handleClick(e, BikeTableModal.DELETE)}
      >
        <Icon type="trash" color={theme.colors.gray2} />
      </div>
    </div>
  )
}

export default BikesActionCell
