/** @jsx jsx */
import { gql, useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'react-table'
import Card from '../../../components/Card'
import Pagination from '../../../components/Pagination'
import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '../../../components/Table'
import { QUERY_EMPLOYEE_RIDES_RECAP_COUNT } from '../../../utils/gql/queries'
import {
  EmployeeRidesRecapColumnOrdered,
  GetEmployeeRidesCountQuery,
  GetEmployeeRidesRecapQuery,
  GetEmployeeRidesRecapQueryVariables,
} from '../../../__generated__/graphql'

const QUERY_EMPLOYEE_RIDES_RECAP = gql`
  query GetEmployeeRidesRecap(
    $orderBy: EmployeeRidesRecapOrderByInput
    $offset: Int
  ) {
    employeeRidesRecap(orderBy: $orderBy, offset: $offset) {
      date
      totalDistance
      totalHomework
    }
  }
`

const LastEmployeeRides: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)

  const [currPage, setCurrPage] = useState(1)

  const { data, loading } = useQuery<
    GetEmployeeRidesRecapQuery,
    GetEmployeeRidesRecapQueryVariables
  >(QUERY_EMPLOYEE_RIDES_RECAP, {
    fetchPolicy: 'network-only',
    variables: {
      offset: 7 * (currPage - 1),
      orderBy:
        orderBy.sort && orderBy.columnName
          ? {
              columnName: orderBy.columnName as EmployeeRidesRecapColumnOrdered,
              sort: orderBy.sort,
            }
          : null,
    },
  })

  const { data: dataCount } = useQuery<GetEmployeeRidesCountQuery>(
    QUERY_EMPLOYEE_RIDES_RECAP_COUNT,
    {
      fetchPolicy: 'network-only',
    },
  )

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('employee.monitoring.ridesTable.columns.date') as string,
        accessor: 'date',
      },
      {
        Header: t(
          'employee.monitoring.ridesTable.columns.totalDistances',
        ) as string,
        accessor: 'totalDistance',
        sortField: 'totalDistance',
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: t(
          'employee.monitoring.ridesTable.columns.totalHomeWork',
        ) as string,
        accessor: 'totalHomework',
        sortField: 'totalHomework',
        cellStyle: () => ({ textAlign: 'center' }),
      },
    ],
    [t],
  )

  const formattedData = useMemo(() => {
    if (!data?.employeeRidesRecap?.length) {
      return []
    }
    return data.employeeRidesRecap.map(dayRide => ({
      date: dayRide?.date as string,
      totalDistance: `${Math.round(dayRide?.totalDistance || 0)} km`,
      totalHomework: dayRide?.totalHomework || 0,
    }))
  }, [data])

  return (
    <Card
      css={{
        height: 'fit-content',
        gridColumn: 'span 2 / span 2',
      }}
      title={
        <span
          css={{
            fontSize: '1.6rem',
            color: theme.colors.gray2,
            fontWeight: 'bold',
            fontFamily: theme.fontFamily,
            marginBottom: '21px',
          }}
        >
          {t('company.monitoring.ridesTable.title')}
        </span>
      }
    >
      <Table
        columns={columns}
        data={formattedData}
        loading={loading}
        onSortBy={setOrderBy}
      />
      <div
        css={{
          position: 'relative',
          display: 'flex',
          marginTop: '21px',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={7}
          totalData={dataCount?.employeeRidesRecapCount}
        />
      </div>
    </Card>
  )
}

export default LastEmployeeRides
