/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { CompanySites } from './components/sites'

const Sites: React.FC = () => {
  return <CompanySites />
}

export default Sites
