/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { Fragment } from 'react'
import theme from '../../styles/theme'
import Icon, { IconTypes } from '../Icon'

interface ActionCardProps {
  variant: 'primary' | 'secondary' | 'tertiary'
  icon: IconTypes
  iconColor?: string
  label?: string
  onCardClick?: () => void
  actions?: Array<{
    icon: IconTypes
    onClick: () => void
  }>
  editClick?: () => void
  css?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
}

const variantCss = {
  primary: {
    cardColor: theme.colors.primary,
    iconColor: theme.colors.yellowLegend,
    labelColor: theme.colors.white,
  },
  secondary: {
    cardColor: theme.colors.yellowLegend,
    iconColor: theme.colors.primary,
    labelColor: theme.colors.gray1,
  },
  tertiary: {
    cardColor: theme.colors.white,
    iconColor: theme.colors.gray1,
    labelColor: theme.colors.gray1,
  },
}

const ActionCard: React.FC<ActionCardProps> = ({
  variant = 'primary',
  icon,
  label,
  onCardClick,
  actions,
  editClick,
  children,
  iconColor,
  ...props
}) => {
  const theme = useTheme()

  return (
    <div
      css={{
        position: 'relative',
        width: '230px',
        height: '100%',
        borderRadius: '30px',
        backgroundColor: variantCss[variant].cardColor,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 18,
        cursor: onCardClick ? 'pointer' : 'auto',
        boxShadow: '4px 8px 20px rgba(0, 0, 0, 0.15)',
        [theme.media.mobile]: {
          width: '100%',
        },
      }}
      onClick={onCardClick}
      // https://emotion.sh/docs/with-props
      {...props}
    >
      <div>
        <Icon
          type={icon}
          color={iconColor ? iconColor : variantCss[variant].iconColor}
          size="large"
        />
      </div>
      {label && (
        <span
          css={{
            fontFamily: theme.fontFamily,
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '1.6rem',
            lineHeight: '2.2rem',
            color: variantCss[variant].labelColor,
            marginTop: '12px',
          }}
        >
          {label}
        </span>
      )}
      {children && (
        <div css={{ width: '100%', marginTop: '12px' }}>{children}</div>
      )}
      {actions && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '70%',
            marginTop: '12px',
          }}
        >
          {actions.map(action => (
            <button
              key={action.icon}
              onClick={e => {
                e.stopPropagation()
                action.onClick()
              }}
            >
              <Icon
                type={action.icon}
                size="medium"
                color={variantCss[variant].labelColor}
              />
            </button>
          ))}
        </div>
      )}
      {!!editClick && (
        <Fragment>
          <div css={{ height: '16px' }} />
          <button
            onClick={editClick}
            css={{ position: 'absolute', bottom: 15, right: 15 }}
          >
            <Icon
              type="edit"
              size="small"
              color={variantCss[variant].labelColor}
            />
          </button>
        </Fragment>
      )}
    </div>
  )
}

export default ActionCard
