/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../../../../components/Card'
import { Flex } from '../../../../components/Flex'
import { DeploymentsList } from './DeploymentsList'

export const AvailableDeployments: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Card
      title={
        <span
          css={{
            fontWeight: 'bold',
            fontSize: '1.8rem',
            lineHeight: '20px',
            fontFamily: 'Raleway',
          }}
        >
          {t('employee.deployments.title')}
        </span>
      }
    >
      <Flex
        direction="row"
        align="end"
        css={{ marginTop: '16px', marginBottom: '16px' }}
      >
        <DeploymentsList />
      </Flex>
    </Card>
  )
}
