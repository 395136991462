import { createSlice } from '@reduxjs/toolkit'

export interface DepositState {
  depositDone: boolean
}

const initialState: DepositState = {
  depositDone: false,
}

const depositSlice = createSlice({
  name: 'deposit',
  initialState,
  reducers: {
    depositDoneAction() {
      return {
        depositDone: true,
      }
    },
  },
})

export const { depositDoneAction } = depositSlice.actions

export default depositSlice.reducer
