/** @jsx jsx */
import { useMutation } from '@apollo/client'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Button from '../../../../../../../components/Button'
import Input from '../../../../../../../components/Input'

import { useNotifications } from '../../../../../../../contexts/NotificationContext'
import {
  EmployeeSignType,
  EmployeeValidationState,
  setLoanBikeForm,
} from '../../../../../../../redux/forms'
import apiErrorCatcher from '../../../../../../../utils/apiErrorCatcher'
import { MUTATION_LOGIN } from '../../../../../../../utils/gql/mutations'
import { LoginMutation } from '../../../../../../../__generated__/graphql'

type PasswordInputState = { value: string; error: string }
const initialPassowrdInputState: PasswordInputState = { value: '', error: '' }

interface CredsFormProps {
  email: string
  requirePassword: boolean
}

const CredsForm: React.FC<CredsFormProps> = ({
  email,
  requirePassword = true,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const notifications = useNotifications()

  const [login, { loading }] = useMutation<LoginMutation>(MUTATION_LOGIN)

  const dispatch = useDispatch()
  const [passwordInput, setPasswordInput] = React.useState<PasswordInputState>(
    initialPassowrdInputState,
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordInput({
      value: e.target.value,
      error: !e.target.value ? t('forms.errors.required') : '',
    })
  }

  const handleSignOnApp = () => {
    dispatch(
      setLoanBikeForm({
        employeeValidation: EmployeeValidationState.SIGN_ON_APP,
        employeeSignType: EmployeeSignType.SIGN_ON_APP,
      }),
    )
  }

  const handleSubmit = async (
    evt: React.MouseEvent | React.FormEvent<HTMLFormElement>,
  ) => {
    evt.preventDefault()

    if (requirePassword) {
      if (!passwordInput.value) return

      login({
        variables: {
          email,
          password: passwordInput.value,
        },
      })
        .then(async result => {
          if (!result.data?.login?.token) return

          dispatch(
            setLoanBikeForm({
              employeeValidation: EmployeeValidationState.VALIDATED,
            }),
          )
        })
        .catch(apiErrorCatcher(notifications))
    } else {
      dispatch(
        setLoanBikeForm({
          employeeValidation: EmployeeValidationState.VALIDATED,
        }),
      )
    }
  }

  return (
    <form onSubmit={handleSubmit} css={{ width: '100%', maxWidth: 375 }}>
      {requirePassword && [
        <Input
          key="1"
          type="text"
          name="employeeEmail"
          value={email}
          disabled
        />,
        <Input
          key="2"
          type="password"
          name="password"
          placeholder={t('forms.label.password')}
          value={passwordInput.value}
          error={!!passwordInput.error}
          helperText={passwordInput.error}
          onChange={handleInputChange}
          onBlur={handleInputChange}
        />,
      ]}
      <div css={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Button type="primary" submit onClick={handleSubmit} loading={loading}>
          {t('forms.button.accept')}
        </Button>
      </div>

      <div
        css={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginTop: '20px',
        }}
      >
        {requirePassword && (
          <span
            css={{
              color: theme.colors.blue1,
              textDecorationLine: 'underline',
              cursor: 'pointer',
              margin: '0 48px',
            }}
            onClick={handleSignOnApp}
          >
            {t('forms.button.signOnApp')}
          </span>
        )}
      </div>
    </form>
  )
}

export default CredsForm
