/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Helmet } from 'react-helmet'

import ActionCard from '../../../components/Card/ActionCard'

import { downloadCSVFile } from '../../../components/Button/DownloadCSVButton'
import {
  formatComunityExport,
  QUERY_COMUNITY_EXPORT,
} from '../../../utils/gql/communityExport'
import { useQuery } from '@apollo/client'
import { GetComunityExportQuery } from '../../../__generated__/graphql'

const ExportMonitoring: React.FC = () => {
  const { data: exportData } = useQuery<GetComunityExportQuery>(
    QUERY_COMUNITY_EXPORT,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `comunity-images-${formatedDate}`
  const formatExport = formatComunityExport(exportData)

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goodwatt Admin - Exports</title>
        <link rel="canonical" href="https://goodwatt.fr/animator/quizzes" />
      </Helmet>

      <ActionCard
        variant="primary"
        label="Export Communauté"
        onCardClick={() => downloadCSVFile(formatExport, filename)}
        icon="download"
      />
    </div>
  )
}

export default ExportMonitoring
