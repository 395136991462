/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useEffect, MouseEvent } from 'react'
import useTimeout from '../hooks/useTimeout'
import { NotificationTypes } from '../contexts/NotificationContext'
import Icon from './Icon'

const toastSuccessText = '#219653'
const toastSuccessBackground = '#E3F7EE'
const toastWarningText = '#E26A00'
const toastWarningBackground = '#FFF8E7'
const toastErrorText = '#A30000'
const toastErrorBackground = '#FFE1E1'

type NotificationProps = {
  onUnmount: (uid: number) => void
  uid: number
  message: string
  type: NotificationTypes
  ms?: number
  infinite?: boolean
}

const Toast: React.FC<NotificationProps> = ({
  type,
  message,
  onUnmount,
  uid,
}) => {
  const backgroundColor =
    type === NotificationTypes.SUCCESS
      ? toastSuccessBackground
      : type === NotificationTypes.WARNING
      ? toastWarningBackground
      : toastErrorBackground
  const fontColor =
    type === NotificationTypes.SUCCESS
      ? toastSuccessText
      : type === NotificationTypes.WARNING
      ? toastWarningText
      : toastErrorText

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '80%',
        maxWidth: 540,
        minHeight: '3.8rem',
        padding: '10px 18px',
        backgroundColor: backgroundColor,
        marginBottom: 8,
        pointerEvents: 'auto',
        borderRadius: 8,
      }}
    >
      {type === NotificationTypes.SUCCESS ? (
        <Icon
          type="checkbox"
          customSize={{ width: 17, height: 17 }}
          color={fontColor}
        />
      ) : (
        <Icon
          type="warning"
          customSize={{ width: 18, height: 17 }}
          color={fontColor}
        />
      )}
      <span
        css={{
          fontFamily: 'Roboto',
          width: '80%',
          textAlign: 'center',
          fontSize: '1.6rem',
          color: fontColor,
          whiteSpace: 'pre-line',
        }}
      >
        {message}
      </span>
      <div
        css={{
          cursor: 'pointer',
        }}
        onClick={(() => onUnmount(uid)) as (event: MouseEvent) => void}
      >
        <Icon type="close" size="small" color={fontColor} />
      </div>
    </div>
  )
}

const NotificationToast: React.FC<NotificationProps> = ({
  infinite,
  onUnmount,
  uid,
  message,
  type,
  ms,
}) => {
  const endOfTimeout = useTimeout(ms)

  useEffect(() => {
    if (endOfTimeout && !infinite) {
      onUnmount(uid)
    }
  }, [endOfTimeout, infinite, onUnmount, uid])

  return <Toast onUnmount={onUnmount} uid={uid} message={message} type={type} />
}

export default NotificationToast
