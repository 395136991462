/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import CardFoldable from '@goodwatt/client/src/components/Card/Foldable'
import CardTitle from '../../../components/Card/CardTitle'
import ListAnimator from './AnimatorPanel/AnimatorList'
import ListArea from './AreaPanel/AreaList'
import TerritoryList from './TerritoryPanel/TerritoryList'

import AdminTableRefetchContext from '../AdminTableRefetchContext'

const PAGESIZE = 7

const CompanyBike: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goodwatt Animateur - Mes velos</title>
        <link rel="canonical" href="https://goodwatt.fr/animator/bikes" />
      </Helmet>

      <AdminTableRefetchContext>
        <div css={{ marginBottom: '30px' }}>
          <CardFoldable
            title={<CardTitle label={t('admin.animator.animatorTitleLabel')} />}
            isDefaultFolded={false}
          >
            <div css={{ marginTop: 24 }}>
              <ListAnimator pageSize={PAGESIZE} />
            </div>
          </CardFoldable>
        </div>

        <div css={{ marginBottom: '30px' }}>
          <CardFoldable
            title={<CardTitle label={t('admin.area.areaTitleLabel')} />}
            isDefaultFolded={false}
          >
            <div css={{ marginTop: 24 }}>
              <ListArea pageSize={PAGESIZE} />
            </div>
          </CardFoldable>
        </div>

        <div css={{ marginBottom: '30px' }}>
          <CardFoldable
            title={
              <CardTitle label={t('admin.territory.territoryTitleLabel')} />
            }
            isDefaultFolded={false}
          >
            <div css={{ marginTop: 24 }}>
              <TerritoryList />
            </div>
          </CardFoldable>
        </div>
      </AdminTableRefetchContext>
    </div>
  )
}

export default CompanyBike
