/** @jsx jsx */
import { gql, useMutation, useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'

import { useTheme } from 'emotion-theming'
import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ComponentRow } from '..'

import Icon from '../../../../../../components/Icon'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../../../../utils/apiErrorCatcher'
import { formatBytes } from '../../../../../../utils/files'
import {
  GetAttachmentsQuery,
  GetAttachmentsQueryVariables,
  GetPresignedAttachmentMutation,
  GetPresignedAttachmentMutationVariables,
} from '../../../../../../__generated__/graphql'

const QUERY_ATTACHMENTS = gql`
  query GetAttachments(
    $bikeComponentId: String
    $loanPackageId: String
    $bikeLoanId: String
  ) {
    bikeLoanAttachments(
      where: {
        OR: [
          {
            AND: [
              { bikeLoanId: { equals: $bikeLoanId } }
              { bikeComponentId: { equals: $bikeComponentId } }
              { bikeComponentId: { not: null } }
            ]
          }
          {
            AND: [
              { bikeLoanId: { equals: $bikeLoanId } }
              { loanPackageId: { equals: $loanPackageId } }
              { loanPackageId: { not: null } }
            ]
          }
        ]
      }
    ) {
      id
      size
      title
    }
  }
`

const MUTATION_GET_PRESIGNED_ATTACHMENT = gql`
  mutation GetPresignedAttachment($attachmentId: String!) {
    getPresignedBikeLoanAttachment(attachmentId: $attachmentId)
  }
`

interface ReadOnlyAttachmentsProps {
  component: ComponentRow
  bikeLoanId?: string
  shouldOpenCameraInput?: boolean
  closeAutomaticCameraInput?: () => void
}

const ReadOnlyAttachments: React.FC<ReadOnlyAttachmentsProps> = ({
  component: { id },
  bikeLoanId,
  shouldOpenCameraInput = false,
  closeAutomaticCameraInput,
}) => {
  const theme = useTheme()
  const notifications = useNotifications()
  const { t } = useTranslation()
  const { data } = useQuery<GetAttachmentsQuery, GetAttachmentsQueryVariables>(
    QUERY_ATTACHMENTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        bikeComponentId: id,
        loanPackageId: null,
        bikeLoanId,
      },
    },
  )

  const shouldDisplayInput =
    data?.bikeLoanAttachments && data?.bikeLoanAttachments.length < 3

  const [getPresignedAttachment, { loading }] = useMutation<
    GetPresignedAttachmentMutation,
    GetPresignedAttachmentMutationVariables
  >(MUTATION_GET_PRESIGNED_ATTACHMENT)

  const handleDisabledAction = useCallback(
    (action: 'delete' | 'upload') => {
      notifications.newNotification({
        type: NotificationTypes.WARNING,
        message: t(
          `animator.bikes.modal.bikeComponentsCondition.bikeComponentPartModal.actionsDisabled.${action}`,
        ),
      })
    },
    [notifications, t],
  )

  useEffect(() => {
    if (shouldOpenCameraInput) {
      closeAutomaticCameraInput && closeAutomaticCameraInput()
      handleDisabledAction('delete')
    }
  }, [closeAutomaticCameraInput, handleDisabledAction, shouldOpenCameraInput])

  const handleOpenPreview = (
    e: React.MouseEvent<HTMLDivElement>,
    fileId: string,
  ) => {
    e.preventDefault()
    getPresignedAttachment({
      variables: {
        attachmentId: fileId,
      },
    })
      .then(res => {
        if (res.data?.getPresignedBikeLoanAttachment)
          window.open(res.data.getPresignedBikeLoanAttachment, '_blank')
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 25,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          minHeight: 24,
          alignItems: 'center',
          marginLeft: 24,
          marginBottom: 4,
        }}
      >
        <span css={{ marginRight: 10 }}>
          {t(
            'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeComponentsCondition.bikeComponentPartModal.uploadedFiles',
          )}
        </span>
        <AnimatePresence>
          {loading && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Icon type="loader" strokeColor={theme.colors.primary} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {data?.bikeLoanAttachments?.map(file => (
          // A file is really uploaded at the end of every steps, until then we just storing it in redux's state
          <motion.div
            onClick={e => handleOpenPreview(e, file.id)}
            key={file.id}
            css={{
              cursor: 'pointer',
              width: '100%',
              padding: '10px 12px',
              marginBottom: 2,
              fontSize: '1.4rem',
              border: `2px solid ${theme.colors.gray6}`,
              borderRadius: 22,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: theme.colors.white,
            }}
            initial={{
              y: 50,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            exit={{
              y: 50,
              opacity: 0,
            }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div css={{ marginRight: 20 }}>
                <Icon
                  type="unknownDoc"
                  color={theme.colors.gray1}
                  customSize={{ width: 19, height: 19 }}
                />
              </div>
              <span css={{ color: theme.colors.gray1 }}>{file.title}</span>
            </div>
            <span css={{ fontSize: '1.2rem' }}>{formatBytes(file.size)}</span>
            <div onClick={() => handleDisabledAction('delete')}>
              <Icon
                type="trash"
                color={theme.colors.gray3}
                customSize={{ width: 19, height: 19 }}
              />
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
      {shouldDisplayInput && (
        <div
          css={{
            width: '100%',
            marginTop: 18,
            textAlign: 'center',
            fontSize: '1.6rem',
          }}
        >
          <span
            onClick={() => handleDisabledAction('upload')}
            css={{
              color: theme.colors.gray2,
              textDecoration: 'underline',
            }}
          >
            {t(
              'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeComponentsCondition.bikeComponentPartModal.addFile',
            )}
          </span>
        </div>
      )}
    </div>
  )
}

export default ReadOnlyAttachments
