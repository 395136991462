/** @jsx jsx */
import React, { Fragment, useEffect, useState } from 'react'
import Popup from '../../../../components/Popup'
import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { FleetsTableRow } from '../../../../types/AnimatorFleets'
import { useTranslation } from 'react-i18next'
import InputSelect from '@goodwatt/client/src/components/InputSelect'
import {
  MUTATION_CHANGE_FLEET,
  MUTATION_DELETE_ONE_FLEET,
} from '../../../../utils/gql/mutations'

import { QUERY_ALL_FLEET_NAMES } from '../../../../utils/gql/queries'
import { useMutation, useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../redux/rootReducer'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import i18n from '../../../../i18n/config'
import { UserRoles } from '@goodwatt/shared/dist/types'
import {
  ChangeFleetMutation,
  ChangeFleetMutationVariables,
  DeleteOneFleetMutation,
  DeleteOneFleetMutationVariables,
  GetAllFleetNamesQuery,
  GetAllFleetNamesQueryVariables,
} from '../../../../__generated__/graphql'

interface ModalProps {
  isOpen: boolean
  closeModal: (shouldRefetch?: boolean) => void
  fleet: FleetsTableRow | undefined
}

const ModalDeleteFleet: React.FC<ModalProps> = ({
  isOpen,
  fleet,
  closeModal,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { id: userId, role: userRole } = useSelector(
    (state: StoreState) => state.user,
  )
  const [selectedFleet, setSelectedFleet] = useState<
    GetAllFleetNamesQuery['fleets'][0] | null
  >(null)

  const notifications = useNotifications()

  const [visibility, setVisibility] = useState<boolean>(false)

  const isAdmin = userRole === UserRoles.ADMIN

  const {
    data: fleets,
    loading: loadingFleets,
    refetch: refetchFleetNames,
  } = useQuery<GetAllFleetNamesQuery, GetAllFleetNamesQueryVariables>(
    QUERY_ALL_FLEET_NAMES,
    {
      variables: {
        where: isAdmin
          ? {}
          : {
              area: {
                animator: {
                  some: { userId: { equals: userId } },
                },
              },
            },
      },
    },
  )

  const [deleteOneFleet] = useMutation<
    DeleteOneFleetMutation,
    DeleteOneFleetMutationVariables
  >(MUTATION_DELETE_ONE_FLEET)

  const [changeFleet] = useMutation<
    ChangeFleetMutation,
    ChangeFleetMutationVariables
  >(MUTATION_CHANGE_FLEET)

  const onModalClose = (shouldRefetch = false) => {
    closeModal(shouldRefetch)
  }

  const handleSubmit = async () => {
    if (!selectedFleet) return

    changeFleet({
      variables: {
        lastFleetId: fleet!.id!,
        newFleetId: selectedFleet.id!,
      },
    })
      .then(() => {
        deleteOneFleet({
          variables: {
            where: {
              id: fleet!.id!,
            },
          },
        }).then(() => {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: i18n.t('animator.fleets.notification.successDelete'),
          })
          closeModal(true)
        })
      })
      .catch(e => {
        if (e.message === 'FORBIDDEN_OPERATION_SOME_BIKES_LOAN') {
          return notifications.newNotification({
            type: NotificationTypes.ERROR,
            message: t(
              'animator.fleets.modal.errors.fleetCurentlyUseByCompany',
            ),
          })
        }
      })
  }

  useEffect(() => {
    if (!isOpen || !fleet) {
      setVisibility(true)
      return
    }
    if (fleet.totalBike !== 0) {
      setVisibility(true)
      return
    }

    setVisibility(false)

    deleteOneFleet({
      variables: {
        where: {
          id: fleet.id,
        },
      },
    }).then(() => {
      notifications.newNotification({
        type: NotificationTypes.SUCCESS,
        message: i18n.t('animator.fleets.notification.successDelete'),
      })
      closeModal(true)
    })
  }, [fleet, closeModal, deleteOneFleet, isOpen])

  const onInputSelectChange = React.useCallback(({ value }) => {
    setSelectedFleet(value)
  }, [])

  React.useEffect(() => {
    refetchFleetNames()
  }, [isOpen, refetchFleetNames])

  return (
    <Modal
      isOpen={isOpen}
      style={{ visibility: visibility ? 'visible' : 'hidden' }}
    >
      <Popup
        overflowY="visible"
        closable
        title={t('animator.fleets.modal.delete.title')}
        titleColor={theme.colors.redLegend}
        onClose={() => onModalClose(false)}
        maxWidth={700}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button onClick={() => onModalClose(false)} type="tertiaryError">
                {t('shared.button.cancel')}
              </Button>
            </div>
            <Button
              type="primaryError"
              submit
              disabled={selectedFleet === null || loadingFleets}
              onClick={handleSubmit}
            >
              {t('animator.fleets.modal.delete.button.confirm')}
            </Button>
          </Fragment>
        }
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0 40px',
            color: theme.colors.gray1,
          }}
        >
          <p
            css={{
              textAlign: 'center',
            }}
          >
            {t('animator.fleets.modal.delete.contentConfirm')}
            <span
              css={{
                fontWeight: 700,
              }}
            >
              {fleet?.name}
            </span>
            ?
          </p>
          <p
            css={{
              textAlign: 'center',
              marginTop: '10px',
            }}
          >
            {t('animator.fleets.modal.delete.contentStart')}
            {fleet?.totalBike}
            {t('animator.fleets.modal.delete.contentEnd')}
          </p>
          <div
            css={{
              marginTop: '15px',
              width: '100%',
            }}
          >
            <InputSelect
              name="selectedFleet"
              placeholder={t('animator.fleets.modal.delete.placeholderSelect')}
              options={
                fleets?.fleets
                  ? fleets.fleets
                      .filter(f => f.id !== fleet?.id)
                      .map(fleet => ({
                        value: fleet,
                        label: fleet!.name!,
                      }))
                  : []
              }
              onChange={onInputSelectChange}
            />
          </div>
        </div>
      </Popup>
    </Modal>
  )
}

export default ModalDeleteFleet
