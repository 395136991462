import React, { Fragment } from 'react'
import AppointmentModal from '../../Company/Employees/AppointmentModal'
import { EmployeeDeployment } from '../../../../__generated__/graphql'
import { useModal } from '../../../../hooks/useModal'
import Icon from '../../../../components/Icon'

export interface UpdateEmployeeAppointmentButtonProps {
  employeeDeployment: EmployeeDeployment
}

export const EditEmployeeAppointmentButton: React.FC<
  UpdateEmployeeAppointmentButtonProps
> = ({ employeeDeployment }) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <AppointmentModal
        employee={employeeDeployment.employee}
        employeeDeploymentId={employeeDeployment.id}
        deploymentId={employeeDeployment.deploymentId}
        submitCloseModal={modalProps.onClose}
        {...modalProps}
      />
      <button onClick={onOpen}>
        <Icon type="calendarAlt" />
      </button>
    </Fragment>
  )
}
