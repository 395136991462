/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useCurrentDeploymentId } from '../hooks/useCurrentDeploymentId'
import { useModal } from '../../../../hooks/useModal'
import NextDeploymentStepModal from './NextDeploymentStepModal'

export const NextDeploymentStepButton: React.FC = () => {
  const id = useCurrentDeploymentId()
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <NextDeploymentStepModal id={id} {...modalProps} />
      <button onClick={onOpen}>
        <Icon type="arrowRight" />
      </button>
    </Fragment>
  )
}
