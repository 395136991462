/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Column, Row } from 'react-table'
import { ProgramStepType } from '@goodwatt/shared/dist/types'

import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '../../../../../components/Table'
import WithdrawalModal from '../SelectedEmployees/WithdrawalModal'
import SelectedEmployeeActionsCell from '../../../../../components/Table/CustomCells/CompanySelectedEmployeeActionsCell'
import EditEmployeeProfileModal from '../../../../Shared/EditEmployeeModal'
import AppointmentModal from '../AppointmentModal'

import {
  CompanyEmployeesModalSettings,
  CompanySelectedEmployeesTableModal,
  CompanySelectedEmployeesTableRow,
} from '../../../../../types/AnimatorCompanyEmployees'
import SendMailButton from '../../../../../components/Button/SendMail'
import DownloadCSVButton from '../../../../../components/Button/DownloadCSVButton'
import { useTheme } from 'emotion-theming'
import EmployeeDocumentCell from '../../../../../components/Table/CustomCells/EmployeeDocumentCell'
import EmployeeDocumentsModal from '../SelectedEmployees/DocumentsModal/'
import ReturnBikeModal from '../SelectedEmployees/ReturnBikeModal'
import {
  EmployeeTableRefetchContext,
  EmployeeTableTypes,
} from '../EmployeeTableRefetchContext'
import DepositRetentionModal from '../SelectedEmployees/DepositRetentionModal'

import {
  employeeNameFormatter,
  dateFormatter,
} from '@goodwatt/client/src/utils/formatter'

import {
  QUERY_COMPANY_EMPLOYEES_EXPORT,
  formatEmployeesExport,
} from '@goodwatt/client/src/utils/gql/employeesExport'
import usePromiseLazyQuery from '../../../../../hooks/usePromiseLazyQuery'
import EmployeeOptionsCell from '../../../../../components/Table/CustomCells/EmployeeOptionsCell'
import {
  AppointmentCategory,
  GetCompanyEmployeesExportQuery,
  GetCompanyEmployeesExportQueryVariables,
  GetSelectedCompanyEmployeesBikeQuery,
  GetSelectedCompanyEmployeesBikeQueryVariables,
  SelectedCompanyEmployeesColumnOrdered,
} from '../../../../../__generated__/graphql'

const QUERY_COMPANY_SELECTED_EMPLOYEES_BIKE = gql`
  query GetSelectedCompanyEmployeesBike(
    $companyId: String!
    $hasProfileComplete: Boolean
    $babySeatFilter: BabySeatOptions
    $multiFilter: String
    $specialBikeType: SpecialBikesType
    $orderBy: SelectedCompanyEmployeesOrderByInput
  ) {
    selectedCompanyEmployees(
      companyId: $companyId
      hasProfileComplete: $hasProfileComplete
      multiFilter: $multiFilter
      specialBikeType: $specialBikeType
      babySeatFilter: $babySeatFilter
      orderBy: $orderBy
      hasBike: false
    ) {
      id
      firstName
      lastName

      specialBikeType
      specialBikeAbsolutelyNeeded
      babySeat

      email
      height
      points
      bikeStickerId
      ridesTotalDistance
      homeWorkRides
      daysWithRide
      loanPossibleAction
      identityCardFrontFilePath
      identityCardBackFilePath
      identityCardVerified
      proofOfAddressFilePath
      proofOfAddressVerified
      depositId
      depositExpirationDate
      depositByCheque
      depositReleasedAt
      bikeReturned
      retentionOnDeposit

      absences {
        id
        category
      }

      bikeComponentHistories {
        estimatedRetention
      }

      loanPackageHistories {
        estimatedRetention
      }

      bikeLoan {
        bikeReturnedDate
        bikeDeliveredDate
      }

      trainingAppointment {
        id
        date
        location
      }
      loanDeliveryAppointment {
        id
        date
        location
      }
      loanReturnAppointment {
        id
        date
        location
      }
    }
  }
`

const initialModalSettings: CompanyEmployeesModalSettings = {
  employee: null,
  actionName: '',
}

interface SelectedCompanyEmployeesListProps {
  filters: {
    [filterName: string]: any
  }
  loanEventsFormatted: {
    loanReturnDate: string
    loanDeliveryDate: string
  }
  currentProgramStep: ProgramStepType
}

const SelectedCompanyEmployeesList: React.FC<
  SelectedCompanyEmployeesListProps
> = ({ filters, loanEventsFormatted, currentProgramStep }) => {
  const {
    refetchTables,
    removeTableToFetch,
    tableRefetchPlanner: { [EmployeeTableTypes.SELECTED]: needToRefetch },
  } = React.useContext(EmployeeTableRefetchContext)

  const { t } = useTranslation()
  const theme = useTheme()
  const { id: companyId } = useParams<{ id: string }>()
  const [orderBy, setOrderBy] =
    React.useState<ColumnSortBy>(initialColumnSortBy)
  const [currModal, setCurrModal] = React.useState(initialModalSettings)
  const [sendEmailDisabled, setSendEmailDisabled] = React.useState(false)
  const [documentsModal, setDocumentsModal] = React.useState<string | null>(
    null,
  )

  const resetModal = () => setCurrModal(initialModalSettings)

  const {
    data: employeesData,
    loading,
    refetch: refetchSelectedEmployees,
  } = useQuery<
    GetSelectedCompanyEmployeesBikeQuery,
    GetSelectedCompanyEmployeesBikeQueryVariables
  >(QUERY_COMPANY_SELECTED_EMPLOYEES_BIKE, {
    fetchPolicy: 'no-cache',
    variables: {
      companyId,
      specialBikeType: filters.specialBikeType,
      hasProfileComplete: filters.hasProfileComplete,
      multiFilter: filters.multiFilter,
      orderBy:
        orderBy.sort && orderBy.columnName
          ? {
              columnName:
                orderBy.columnName as SelectedCompanyEmployeesColumnOrdered,
              sort: orderBy.sort,
            }
          : null,
    },
  })

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<CompanySelectedEmployeesTableRow> }) => (
      <SelectedEmployeeActionsCell
        setModal={setCurrModal}
        row={row}
        loanEventsFormatted={loanEventsFormatted}
        currentProgramStep={currentProgramStep}
        hasBike={false}
      />
    ),
    [loanEventsFormatted, currentProgramStep],
  )

  const DocumentCell = React.useCallback(
    ({ row }: { row: Row<CompanySelectedEmployeesTableRow> }) => {
      return (
        <EmployeeDocumentCell
          setModal={employeeId => setDocumentsModal(employeeId)}
          row={row}
          deploymentType={null}
        />
      )
    },
    [],
  )

  const OptionsCell = React.useCallback(({ row }) => {
    return <EmployeeOptionsCell row={row} />
  }, [])

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: t(
          'animator.company.employees.selected.table.headers.name',
        ) as string,
        accessor: 'name',
        maxWidth: 200,
      },
      ...(currentProgramStep >= ProgramStepType.EMPLOYEE_LOANS_SELECTION
        ? [
            {
              Header: t(
                'animator.company.employees.selected.table.headers.documents',
              ) as string,
              accessor: 'document',
              disableSortBy: true,
              Cell: DocumentCell,
            },
          ]
        : []),
      {
        Header: t(
          'animator.company.employees.selected.table.headers.height',
        ) as string,
        accessor: 'height',
      },
      {
        Header: t(
          'animator.company.employees.selected.table.headers.options',
        ) as string,
        Cell: OptionsCell,
      },
      {
        Header: t(
          'animator.company.employees.selected.table.headers.loan',
        ) as string,
        accessor: 'loanDate',
      },
      {
        Header: t(
          'animator.company.employees.selected.table.headers.training',
        ) as string,
        accessor: 'trainingDate',
      },
      {
        Header: t('animator.companies.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [t, currentProgramStep, DocumentCell, OptionsCell, ActionCell],
  )

  const formattedData = React.useMemo(() => {
    if (!employeesData?.selectedCompanyEmployees?.length) {
      return []
    }

    return employeesData.selectedCompanyEmployees.map(employee => ({
      id: employee?.id,
      email: employee?.email,
      name: employeeNameFormatter(employee?.firstName, employee?.lastName),
      height: employee?.height ? `${employee?.height} cm` : '',
      bikeStickerId: employee?.bikeStickerId,
      homeWorkRides: employee?.homeWorkRides || null,

      specialBikeType: employee?.specialBikeType,
      specialBikeAbsolutelyNeeded: employee?.specialBikeAbsolutelyNeeded,
      babySeat: employee?.babySeat,

      identityCardFrontDocument: employee?.identityCardFrontFilePath,
      identityCardBackDocument: employee?.identityCardBackFilePath,
      identityCardVerified: employee?.identityCardVerified,
      proofOfAddressDocument: employee?.proofOfAddressFilePath,
      proofOfAddressVerified: employee?.proofOfAddressVerified,
      depositId: employee?.depositId,
      depositByCheque: employee?.depositByCheque,
      depositReleasedAt: employee?.depositReleasedAt,
      depositExpirationDate: employee?.depositExpirationDate,
      ridesTotalDistance: employee?.ridesTotalDistance
        ? `${employee?.ridesTotalDistance} km`
        : '',
      distancePerDay:
        employee?.ridesTotalDistance && employee?.daysWithRide
          ? `${Math.floor(
              employee?.ridesTotalDistance / employee?.daysWithRide,
            )} km/j`
          : null,
      loanPossibleAction: employee?.loanPossibleAction,

      loanDate: employee?.absences?.find(
        x => x?.category === AppointmentCategory.LoanDelivery,
      )
        ? t('forms.label.absent')
        : dateFormatter(employee?.loanDeliveryAppointment?.date),
      loanLocation: employee?.loanDeliveryAppointment?.location,

      loanEndDate: employee?.absences?.find(
        x => x?.category === AppointmentCategory.LoanReturn,
      )
        ? t('forms.label.absent')
        : dateFormatter(employee?.loanReturnAppointment?.date),
      loanEndLocation: employee?.loanReturnAppointment?.location,

      trainingDate: employee?.absences?.find(
        x => x?.category === AppointmentCategory.Training,
      )
        ? t('forms.label.absent')
        : dateFormatter(employee?.trainingAppointment?.date),
      trainingLocation: employee?.trainingAppointment?.location,
    }))
  }, [employeesData, t])

  const mailList = React.useMemo(() => {
    if (!employeesData?.selectedCompanyEmployees?.length) {
      setSendEmailDisabled(true)
      return []
    }
    setSendEmailDisabled(false)
    return employeesData.selectedCompanyEmployees.reduce<string[]>(
      (acc, employee) => {
        if (employee?.email) {
          return [...acc, employee?.email]
        }
        return acc
      },
      [],
    )
  }, [employeesData])

  const handleRefetch = (newData?: any) => {
    if (newData) {
      setCurrModal(prevState => ({
        ...prevState,
        employee: {
          ...prevState.employee,
          ...newData,
        },
      }))
    }
    refetchTables([EmployeeTableTypes.SELECTED, EmployeeTableTypes.ALL])
  }

  const getAllEmployeeExpot = usePromiseLazyQuery<
    GetCompanyEmployeesExportQuery,
    GetCompanyEmployeesExportQueryVariables
  >(QUERY_COMPANY_EMPLOYEES_EXPORT, 'no-cache')

  React.useEffect(() => {
    if (needToRefetch) {
      refetchSelectedEmployees().then(() =>
        removeTableToFetch(EmployeeTableTypes.SELECTED),
      )
    }
  }, [refetchSelectedEmployees, needToRefetch, removeTableToFetch])

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `selected-employees-loan-${formatedDate}`

  const getExportData = async () => {
    const { data } = await getAllEmployeeExpot({
      employeeWhereInput: {
        companyId: { equals: companyId },
        selectLoan: { equals: true },
        AND: [{ bikeLoan: null }],
      },
    })
    return formatEmployeesExport(data)
  }

  return (
    <React.Fragment>
      <div
        css={{
          minHeight: 380,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Table
          columns={columns}
          data={formattedData}
          onSortBy={setOrderBy}
          loading={loading}
        />
        <div
          css={{
            display: 'flex',
            alignSelf: 'flex-end',
            marginTop: 17,
            [theme.media.mobile]: {
              display: 'none',
            },
          }}
        >
          <DownloadCSVButton getData={getExportData} filename={filename} />
          <SendMailButton mailTo={mailList} disabled={sendEmailDisabled} />
        </div>
      </div>
      <EditEmployeeProfileModal
        isOpen={
          currModal.actionName === CompanySelectedEmployeesTableModal.EDIT
        }
        refetch={handleRefetch}
        employee={currModal.employee}
        submitCloseModal={resetModal}
        onClose={resetModal}
      />
      <WithdrawalModal
        isOpen={
          currModal.actionName === CompanySelectedEmployeesTableModal.WITHDRAWAL
        }
        employee={currModal.employee}
        closeModal={resetModal}
        refetchTables={handleRefetch}
      />
      {/* <LoanBikeModal
        employee={currModal.employee}
        loanEventsFormatted={loanEventsFormatted}
        closeModal={resetModal}
        refetchTables={handleRefetch}
        modalIsOpen={
          currModal.actionName === CompanySelectedEmployeesTableModal.LOAN_BIKE
        }
      /> */}
      <AppointmentModal
        // @ts-ignore
        employee={employeesData?.selectedCompanyEmployees?.find(
          x => x?.id === currModal?.employee?.id,
        )}
        // Old component don't have deploymentId
        deploymentId=""
        onClose={resetModal}
        refetch={handleRefetch}
        isOpen={
          currModal.actionName ===
          CompanySelectedEmployeesTableModal.APPOINTMENT
        }
        submitCloseModal={resetModal}
      />
      <ReturnBikeModal
        employee={currModal.employee}
        closeModal={resetModal}
        refetchTables={handleRefetch}
        modalIsOpen={
          currModal.actionName ===
          CompanySelectedEmployeesTableModal.RETURN_BIKE
        }
      />
      {documentsModal && (
        <EmployeeDocumentsModal
          isOpen={!!documentsModal}
          closeModal={() => setDocumentsModal(null)}
          employeeId={documentsModal}
          deploymentType={null}
          refetchTable={refetchSelectedEmployees}
        />
      )}
      {currModal.actionName ===
        CompanySelectedEmployeesTableModal.RETENTION && (
        <DepositRetentionModal
          employee={currModal.employee}
          employeeDeploymentId=""
          closeModal={resetModal}
          refetch={refetchSelectedEmployees}
        />
      )}
    </React.Fragment>
  )
}

export default SelectedCompanyEmployeesList
