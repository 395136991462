/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Row } from 'react-table'
import React from 'react'
import {
  QuizTableModalActions,
  QuizSettings,
  QuizTableRow,
} from '../../../types/Quizzes'
import Icon from '../../Icon'

interface QuizActionCellProps {
  setModal: (obj: QuizSettings<QuizTableRow, QuizTableModalActions>) => void
  row: Row<QuizTableRow>
  actions: Array<QuizTableModalActions>
}

const QuizActionCell: React.FC<QuizActionCellProps> = ({
  setModal,
  row,
  actions,
}) => {
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: QuizTableModalActions,
    ) => {
      e.stopPropagation()
      setModal({ quiz: row.original, actionName })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      {actions.map(actionName => (
        <div
          key={actionName}
          css={{ cursor: 'pointer' }}
          onClick={e => handleClick(e, actionName)}
        >
          <Icon type={actionName} />
        </div>
      ))}
    </div>
  )
}

export default QuizActionCell
