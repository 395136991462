/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useModal } from '../../../../hooks/useModal'
import DeleteCompanyMonitoringModal from '../../Company/Monitoring/DeleteCompanyMonitoringModal'

export interface DeleteCompanyMonitoringButtonProps {
  id: string
}

export const DeleteCompanyMonitoringButton: React.FC<
  DeleteCompanyMonitoringButtonProps
> = ({ id }) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <DeleteCompanyMonitoringModal id={id} {...modalProps} />
      <button onClick={onOpen}>
        <Icon type="trash" />
      </button>
    </Fragment>
  )
}
