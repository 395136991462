/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'

import Icon from '../Icon'

const Separator: React.FC = () => {
  const theme = useTheme()

  return (
    <div css={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <div css={{ width: '90%' }}>
        <span
          css={{
            display: 'block',
            border: 'none',
            color: 'white',
            height: '1px',
            background: `-webkit-gradient(radial, 50% 50%, 0, 50% 50%, 350, from(${theme.colors.gray5}), to(${theme.colors.white}))`,
          }}
        ></span>
      </div>
    </div>
  )
}

export interface PopupProps {
  title?: string
  closable?: boolean
  onClose?: (e: React.MouseEvent) => void | undefined
  maxWidth?: string | number | React.ReactText[] | string[] | undefined
  footer?: JSX.Element
  titleColor?: string
  overflowY?: 'visible' | 'auto'
  disablePadding?: boolean
  separator?: boolean
  visibility?: boolean
}

// Popup is always used in a modal
const Popup: React.FC<PopupProps> = ({
  closable = true,
  onClose,
  title = '',
  children,
  maxWidth,
  footer,
  titleColor,
  overflowY = 'auto',
  disablePadding,
  separator = true,
  visibility = true,
}) => {
  const theme = useTheme()

  return (
    <div
      css={{
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: maxWidth || '500px',
        maxHeight: 'calc(100% - 64px)',
        borderRadius: '30px',
        boxShadow: '4px 8px 20px rgba(0, 0, 0, 0.15)',
        backgroundColor: theme.colors.white,
        opacity: visibility ? 1 : 0,
        overflowY: 'visible',
        [theme.media.mobile]: {
          position: 'absolute',
          maxHeight: 'calc(100% - 32px)',
          boxShadow: 'none',
          bottom: 0,
          borderRadius: 0,
        },
      }}
      onClick={e => e.stopPropagation()}
    >
      {/* Header */}
      <div
        css={{
          width: '100%',
          backgroundColor: theme.colors.white,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
        }}
      >
        <div
          css={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '2rem',
          }}
        >
          <div css={{ width: 24 }} />
          <div
            css={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <span
              css={{
                textAlign: 'center',
                color: titleColor ? titleColor : theme.colors.primary,
                fontFamily: 'Raleway',
                fontSize: '1.8rem',
                fontWeight: 'bold',
              }}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </div>
          <div
            css={{
              width: 24,
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
            }}
          >
            {closable && (
              <button onClick={onClose} type="button">
                <Icon
                  type="close"
                  color={titleColor ? titleColor : theme.colors.primary}
                  size="small"
                />
              </button>
            )}
          </div>
        </div>
        {separator && <Separator />}
      </div>
      {children && (
        <div
          css={{
            height: '100%',
            width: '100%',
            overflowY: overflowY,
            padding: disablePadding ? 0 : '2rem',
            [theme.media.mobile]: {
              overflowY: 'auto',
            },
          }}
        >
          {children}
        </div>
      )}
      {footer && (
        <div
          css={{
            width: '100%',
            backgroundColor: theme.colors.white,
            borderBottomRightRadius: '30px',
            borderBottomLeftRadius: '30px',
          }}
        >
          {separator && <Separator />}
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              padding: '2rem 0',
            }}
          >
            {footer}
          </div>
        </div>
      )}
    </div>
  )
}

export default Popup
