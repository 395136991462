/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import Icon, { IconTypes } from '../Icon'

export interface ButtonProps {
  type?:
    | 'primary'
    | 'primarySuccess'
    | 'primaryError'
    | 'secondary'
    | 'tertiary'
    | 'tertiarySuccess'
    | 'tertiaryError'
    | 'yellowLegend'
  children?: React.ReactNode
  block?: boolean
  onClick?: (e: React.MouseEvent) => void | undefined | Promise<void>
  onMouseEnter?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  onMouseLeave?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  disabled?: boolean
  icon?: IconTypes
  iconColor?: string
  textColor?: string
  submit?: boolean
  loading?: boolean
}

const Button: React.FC<ButtonProps> = ({
  children,
  type = 'primary',
  block,
  onClick = undefined,
  onMouseEnter = undefined,
  onMouseLeave = undefined,
  disabled = false,
  icon,
  iconColor,
  submit = false,
  loading = false,
  textColor: textColorProp,
}: ButtonProps) => {
  const theme = useTheme()

  const backgroundColor = {
    disabled: {
      primary: theme.colors.primaryLight,
      primarySuccess: theme.colors.success,
      primaryError: theme.colors.redLegend,
      secondary: theme.colors.secondaryLight,
      tertiary: theme.colors.gray6,
      tertiarySuccess: theme.colors.gray6,
      tertiaryError: theme.colors.gray6,
      yellowLegend: theme.colors.gray6,
    },
    enabled: {
      primary: theme.colors.primary,
      primarySuccess: theme.colors.success,
      primaryError: theme.colors.redLegend,
      secondary: theme.colors.secondary,
      tertiary: theme.colors.gray6,
      tertiarySuccess: theme.colors.gray6,
      tertiaryError: theme.colors.gray6,
      yellowLegend: theme.colors.yellowLegend,
    },
    hover: {
      primary: theme.colors.primaryDark,
      primarySuccess: theme.colors.successDark,
      primaryError: theme.colors.error,
      secondary: theme.colors.secondaryDark,
      tertiary: theme.colors.gray4,
      tertiarySuccess: theme.colors.gray4,
      tertiaryError: theme.colors.gray4,
      yellowLegend: theme.colors.yellowLegend,
    },
  }

  const textColor = {
    primary: theme.colors.white,
    primarySuccess: theme.colors.white,
    primaryError: theme.colors.white,
    secondary: theme.colors.white,
    tertiary: theme.colors.primary,
    tertiarySuccess: theme.colors.success,
    tertiaryError: theme.colors.redLegend,
    yellowLegend: theme.colors.lightDark,
  }

  return (
    <button
      type={submit && !loading ? 'submit' : 'button'}
      onMouseEnter={disabled || loading ? undefined : onMouseEnter}
      onMouseLeave={disabled || loading ? undefined : onMouseLeave}
      onClick={disabled || loading ? undefined : onClick}
      css={{
        boxSizing: 'border-box',
        background: disabled
          ? backgroundColor.disabled[type]
          : backgroundColor.enabled[type],
        transition: 'background .3s ease, min-width 2s',
        height: '4.4rem',
        width: block ? '100%' : undefined,
        minWidth: 44,
        borderRadius: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        outline: 'none',
        textDecoration: 'none',
        color: 'white',
        border: 'none',
        marginRight: 12,
        ':last-child': {
          marginRight: 0,
        },
        ':hover': {
          cursor: disabled ? 'default' : 'pointer',
          ...(disabled ? {} : { backgroundColor: backgroundColor.hover[type] }),
        },
      }}
    >
      {(icon || loading) && (
        <div css={{ position: 'absolute' }}>
          <Icon
            type={!loading && icon ? icon : 'loader'}
            color={iconColor}
            strokeColor={loading ? 'white' : iconColor}
            customSize={{
              width: loading ? 30 : 22,
              height: loading ? 30 : 22,
            }}
          />
        </div>
      )}
      {children && (
        <div css={{ padding: '0px 15px' }}>
          <span
            css={{
              fontFamily: 'Raleway',
              fontSize: '1.6rem',
              fontWeight: 500,
              color: loading ? 'transparent' : textColorProp ?? textColor[type],
            }}
          >
            {typeof children === 'string' ? children.toUpperCase() : children}
          </span>
        </div>
      )}
    </button>
  )
}

export default Button
