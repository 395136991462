/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment, useMemo, useState } from 'react'
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import EditFleetInput from './FleetInput'
import { BikeConditionType } from '@goodwatt/shared/dist/types'
import InputSelect from '../../../../components/InputSelect'
import Input from '../../../../components/Input'
import Switch from '../../../../components/Switch'
import {
  GetBikeQuery,
  GetBikeModelQuery,
} from '../../../../__generated__/graphql'

interface IdentifierInputsProps {
  control: Control
  fleetOptions: { label: string; value: string }[]
  bikeModelOptions: { label: string; value: string }[]
  bike?: GetBikeQuery['bike'] | null
  bikeModels?: GetBikeModelQuery['bikeModels'] | null
  errors?: DeepMap<any, FieldError>
}

const IdentifierInputs: React.FC<IdentifierInputsProps> = ({
  control,
  bike,
  fleetOptions,
  bikeModelOptions,
  bikeModels,
  errors,
}) => {
  const { t } = useTranslation()
  const [current, setBike] = useState(bike?.bikeModel.id)

  const onSelectChange = React.useCallback(
    ({ value }, { name }) => {
      if (name === 'bikeModel') {
        const newBikeModelId =
          bikeModels?.find(bm => bm.name === value)?.id || ''

        control.setValue('size', undefined, { shouldDirty: true })
        control.setValue('childSeat', false, { shouldDirty: true })
        control.setValue(
          'bikeModel',
          {
            value: newBikeModelId,
            label: value,
          },
          { shouldDirty: true },
        )

        setBike(newBikeModelId)
      } else if (name === 'size') {
        control.setValue(
          'size',
          {
            value: value,
            label: value,
          },
          { shouldDirty: true },
        )
      } else {
        control.setValue(name, value, { shouldDirty: true })
      }
    },
    [control, bikeModels],
  )

  const onChangeBikeChildSeat = () => {
    const current = control.getValues('childSeat')
    const bikeModel = control.getValues('bikeModel')

    const disabled =
      bikeModels?.find(bm => bm.id === bikeModel?.value)
        ?.childSeatCompatibility === false

    if (disabled || !bikeModel?.value) {
      control.setValue('childSeat', false, { shouldDirty: true })
    } else {
      control.setValue('childSeat', !current, { shouldDirty: true })
    }
  }

  const bikeSizeOptions = useMemo(() => {
    if (!bikeModels) return []

    const selectedModel = bikeModels.find(bm => bm.id === current)

    if (!selectedModel) return []

    return selectedModel.modelSizes.map(ms => ({
      value: ms,
      label: ms,
    }))
  }, [bikeModels, current])

  return (
    <Fragment>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <div css={{ marginRight: 20, flex: 5 }}>
          <Input
            type="text"
            disabled
            name="stickerId"
            defaultValue={bike?.stickerId}
            label={t('animator.bikes.modal.fields.stickerId')}
          />
        </div>
        <EditFleetInput
          disabled={bike?.bikeCondition.name === BikeConditionType.ON_LOAN}
          control={control}
          defaultValue={{
            label: bike?.fleet.name,
            value: bike?.fleet.name,
          }}
          fleetOptions={fleetOptions}
        />
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <div css={{ marginRight: 20, flex: 5 }}>
          <Controller
            name="morioId"
            control={control}
            defaultValue={bike?.morioId}
            render={({ onChange, value, name }) => (
              <Input
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                label={t('animator.bikes.modal.fields.morioId')}
              />
            )}
          />
        </div>
        <div css={{ flex: 5 }}>
          <Controller
            name="bicycode"
            control={control}
            defaultValue={bike?.bicycode || ''}
            render={({ onChange, value, name }) => (
              <Input
                type="text"
                name={name}
                value={value}
                error={!!errors?.bicycode}
                helperText={errors?.bicycode?.message}
                onChange={onChange}
                label={t('animator.bikes.modal.fields.bicycode')}
              />
            )}
          />
        </div>
      </div>

      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <div css={{ marginRight: 20, flex: 5 }}>
          <Controller
            name="frameId"
            control={control}
            defaultValue={bike?.frameId || ''}
            render={({ onChange, value, name }) => (
              <Input
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                defaultValue={bike?.frameId || ''}
                label={t('animator.bikes.modal.fields.frameId')}
              />
            )}
          />
        </div>
        <div css={{ flex: 5 }}>
          <Controller
            name="padlockId"
            control={control}
            defaultValue={bike?.padlockId || ''}
            render={({ onChange, value, name }) => (
              <Input
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                defaultValue={bike?.padlockId || ''}
                label={t('animator.bikes.modal.fields.padlockId')}
              />
            )}
          />
        </div>
      </div>

      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <div css={{ marginRight: 20, flex: 5 }}>
          <Controller
            name="keysId"
            control={control}
            defaultValue={bike?.keysId || ''}
            render={({ onChange, value, name }) => (
              <Input
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                defaultValue={bike?.keysId || ''}
                label={t('animator.bikes.modal.fields.keysId')}
              />
            )}
          />
        </div>
        <div css={{ flex: 5 }}>
          <Controller
            name="bikeModel"
            control={control}
            defaultValue={{
              value: bike?.bikeModel?.id || '',
              label: bike?.bikeModel?.name || '',
            }}
            render={({ onChange, value, name }) => (
              <InputSelect
                label={t('animator.bikes.filter.model.label')}
                name={name}
                value={value}
                onChange={onSelectChange}
                options={bikeModelOptions}
                noOptionMessage={t('animator.bikes.filter.model.noOptions')}
              />
            )}
          />
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <div css={{ marginRight: 20, flex: 5 }}>
          <Controller
            name="size"
            control={control}
            defaultValue={{
              value: bike?.size || '',
              label: bike?.size || '',
            }}
            render={({ onChange, value, name }) => (
              <InputSelect
                name={name}
                value={value}
                label={t('animator.bikes.table.headers.size')}
                onChange={onSelectChange}
                options={bikeSizeOptions}
              />
            )}
          />
        </div>

        <div css={{ flex: 5 }}>
          <div css={{ width: '100%' }}>
            <div css={{ marginLeft: '24px', marginBottom: '4px' }}>
              <label
                css={{
                  fontSize: '1.6rem',
                  fontWeight: 'normal',
                  color: '#828282',
                }}
              >
                {t(`animator.bikes.modal.fields.childSeatEquiped`)}
              </label>
            </div>
            <div
              css={{
                height: '44px',
                marginBottom: '11px',
                marginTop: '11px',
              }}
            >
              <Controller
                name="childSeat"
                control={control}
                defaultValue={bike?.childSeat || false}
                render={({ onChange, value, name }) => (
                  <Switch
                    isChecked={value}
                    onClick={() => onChangeBikeChildSeat()}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <Input
        type="text"
        disabled
        name="bikeId"
        defaultValue={bike?.id}
        label={t('animator.bikes.modal.fields.bikeId')}
      />
    </Fragment>
  )
}

export default IdentifierInputs
