/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '../../../../../components/Modal'
import Popup from '../../../../../components/Popup'

import { RefusedCompaniesTableRow } from '../../../../../types/AnimatorCompanies'

interface CommentModalProps {
  isOpen: boolean
  closeModal: () => void
  company: RefusedCompaniesTableRow
}

const CommentModal: React.FC<CommentModalProps> = ({
  isOpen,
  closeModal,
  company,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Modal isOpen={isOpen}>
      <Popup
        closable
        title={t('animator.companies.refusedCompanyInfo.title')}
        onClose={closeModal}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 10,
            fontSize: '1.6rem',
          }}
        >
          <div css={{ marginBottom: 15 }}>
            <span
              css={{
                color: theme.colors.gray3,
              }}
            >
              {t('animator.companies.refusedCompanyInfo.fields.rejectionDate')}{' '}
              :{' '}
            </span>
            <span css={{ color: theme.colors.gray1 }}>
              {company.rejectionDate}
            </span>
          </div>
          <div css={{ marginBottom: 15 }}>
            <span
              css={{
                color: theme.colors.gray3,
              }}
            >
              {t(
                'animator.companies.refusedCompanyInfo.fields.rejectionReason',
              )}{' '}
              :{' '}
            </span>
            <span css={{ color: theme.colors.gray1 }}>
              {company.rejectionReason}
            </span>
          </div>
          <div css={{ marginBottom: 15 }}>
            <span
              css={{
                color: theme.colors.gray3,
              }}
            >
              {t('animator.companies.refusedCompanyInfo.fields.note')} :{' '}
            </span>
            {company.rejectionNote ? (
              <span css={{ color: theme.colors.gray1 }}>
                {company.rejectionNote}
              </span>
            ) : (
              <span css={{ color: theme.colors.gray3 }}>
                {t('animator.companies.refusedCompanyInfo.noRejectionNote')}
              </span>
            )}
          </div>
        </div>
      </Popup>
    </Modal>
  )
}

export default CommentModal
