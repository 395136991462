/** @jsx jsx */
import React, { useCallback, useState } from 'react'
import { jsx } from '@emotion/core'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Modal from '@goodwatt/client/src/components/Modal'
import Popup from '@goodwatt/client/src/components/Popup'
import Button from '@goodwatt/client/src/components/Button'
import {
  useNotifications,
  NotificationTypes,
} from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import InputSelect from '../../../../components/InputSelect'
import { QUERY_ANIMATOR_GET_ADVICE_TAGS } from '../../../../utils/gql/queries'
import { PostTagTableRow } from '../../../../types/AnimatorPosts'
import theme from '../../../../styles/theme'
import {
  DeleteAdviceTagMutation,
  DeleteAdviceTagMutationVariables,
  GetAdviceTagsQuery,
} from '../../../../__generated__/graphql'

const MUTATION_DELETE_ADVICE_TAG = gql`
  mutation DeleteAdviceTag($id: String!, $newId: String!) {
    deleteAdviceTag(id: $id, newId: $newId) {
      id
    }
  }
`

interface DeleteModalProps {
  tag: PostTagTableRow
  isOpen: boolean
  closeModal: () => void
  updateList: () => void
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  tag,
  isOpen,
  closeModal,
  updateList,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [deletePostTag, { loading }] = useMutation<
    DeleteAdviceTagMutation,
    DeleteAdviceTagMutationVariables
  >(MUTATION_DELETE_ADVICE_TAG)

  const [newTagId, setNewTagId] = useState<string | null>()

  const onSubmit = () => {
    if (!newTagId) return

    deletePostTag({
      variables: {
        id: tag.id,
        newId: newTagId,
      },
    })
      .then(() => {
        updateList()
        closeModal()
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t(`animator.post.modal.delete.successNotification`),
        })
      })
      .catch(apiErrorCatcher(notifications))
  }

  const onUpdate = useCallback(
    async ({ value }) => {
      setNewTagId(value)
    },
    [setNewTagId],
  )

  const { data: tagData } = useQuery<GetAdviceTagsQuery>(
    QUERY_ANIMATOR_GET_ADVICE_TAGS,
  )

  const tagOptions = tagData?.adviceTags
    ?.map(x => ({
      label: x?.key || '',
      value: x?.id || '',
    }))
    .filter(x => x.value !== tag.id)

  return (
    <Modal isOpen={isOpen}>
      <Popup
        closable
        separator={false}
        title={t('admin.advice.modal.deleteAdviceTag')}
        titleColor={theme.colors.redLegend}
        onClose={closeModal}
        maxWidth={640}
        footer={
          <React.Fragment>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="tertiaryError"
                submit
                loading={loading}
                onClick={() => closeModal()}
              >
                {t('forms.button.cancel')}
              </Button>
            </div>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="primaryError"
                submit
                loading={loading}
                onClick={() => onSubmit()}
              >
                {t('forms.button.delete')}
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <p
          css={{ margin: '32px 12px', textAlign: 'center' }}
          dangerouslySetInnerHTML={{
            __html: t('admin.advice.modal.deleteTextLine1', {
              tagName: tag.key,
            }),
          }}
        ></p>

        <p css={{ margin: '32px 12px', textAlign: 'center' }}>
          {t('admin.advice.modal.deleteTextLine2')}
        </p>

        <InputSelect
          name="area"
          options={tagOptions || []}
          onChange={area => onUpdate(area)}
        />
      </Popup>
    </Modal>
  )
}

export default DeleteModal
