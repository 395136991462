/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'emotion-theming'
import { format } from 'date-fns'
import {
  fileIconMapping,
  formatBytes,
  getFileExtension,
} from '../../utils/files'
import ActionCard from './ActionCard'
import { gql, useMutation } from '@apollo/client'

import apiErrorCatcher from '../../utils/apiErrorCatcher'
import {
  NotificationTypes,
  useNotifications,
} from '../../contexts/NotificationContext'

import { RestrictionType } from '@goodwatt/shared/dist/types'
import i18n from '../../i18n/config'
import ModalDeletionConfirmation from './ModalDeletionConfirmation'
import {
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables,
  GetPresignedDocumentMutation,
  GetPresignedDocumentMutationVariables,
} from '../../__generated__/graphql'

export const MUTATION_GET_PRESIGNED_DOCUMENT = gql`
  mutation GetPresignedDocument($documentId: String!) {
    getPresignedDocument(documentId: $documentId)
  }
`

export const MUTATION_DELETE_DOCUMENT = gql`
  mutation DeleteDocument($documentId: String!) {
    deleteDocument(documentId: $documentId)
  }
`

/**
 * On the DocumentCard, we display the visibility restriction
 * of the document, so depends on the restrictions we will
 * display the right label to it.
 * Be careful, if the restrictions change in the server you
 * can only add the changes here, do not modify the existing
 * conditions below, otherwise there is chance that it breaks
 * document with outdated restrictions, and won't be able
 * to display the default value of the select input
 * @param restrictions ['company:write', 'company:read'...]
 */
export const visibilityLabelDefaultValue = (
  restrictions: string[],
): {
  uploadBy: string
  visibility: string
} | null => {
  // ANIMATOR VISIBILITY
  if (
    restrictions?.includes(RestrictionType.ANIMATOR_D) &&
    restrictions?.includes(RestrictionType.ANIMATOR_W) &&
    restrictions?.includes(RestrictionType.ANIMATOR_R) &&
    restrictions.length === 3
  )
    return {
      uploadBy: i18n.t('shared.documentCard.uploadByAnimator'),
      visibility: i18n.t('shared.documents.docModal.option1'),
    }
  if (
    restrictions?.includes(RestrictionType.ANIMATOR_D) &&
    restrictions?.includes(RestrictionType.ANIMATOR_W) &&
    restrictions?.includes(RestrictionType.ANIMATOR_R) &&
    restrictions?.includes(RestrictionType.COMPANY_R) &&
    restrictions.length === 4
  )
    return {
      uploadBy: i18n.t('shared.documentCard.uploadByAnimator'),
      visibility: i18n.t('shared.documents.docModal.option2'),
    }
  if (
    restrictions?.includes(RestrictionType.ANIMATOR_D) &&
    restrictions?.includes(RestrictionType.ANIMATOR_W) &&
    restrictions?.includes(RestrictionType.ANIMATOR_R) &&
    restrictions?.includes(RestrictionType.COMPANY_R) &&
    restrictions?.includes(RestrictionType.EMPLOYEE_R) &&
    restrictions.length === 5
  )
    return {
      uploadBy: i18n.t('shared.documentCard.uploadByAnimator'),
      visibility: i18n.t('shared.documents.docModal.option3'),
    }
  // COMPANY VISIBILITY
  if (
    restrictions?.includes(RestrictionType.COMPANY_W) &&
    restrictions?.includes(RestrictionType.COMPANY_R) &&
    restrictions?.includes(RestrictionType.COMPANY_D) &&
    restrictions?.includes(RestrictionType.ANIMATOR_R) &&
    restrictions?.includes(RestrictionType.ANIMATOR_D) &&
    restrictions.length === 5
  )
    return {
      uploadBy: i18n.t('shared.documentCard.uploadByCompany'),
      visibility: i18n.t('shared.documents.docModal.option1'),
    }
  if (
    restrictions?.includes(RestrictionType.COMPANY_W) &&
    restrictions?.includes(RestrictionType.COMPANY_R) &&
    restrictions?.includes(RestrictionType.COMPANY_D) &&
    restrictions?.includes(RestrictionType.ANIMATOR_R) &&
    restrictions?.includes(RestrictionType.ANIMATOR_D) &&
    restrictions?.includes(RestrictionType.EMPLOYEE_R) &&
    restrictions.length === 6
  )
    return {
      uploadBy: i18n.t('shared.documentCard.uploadByCompany'),
      visibility: i18n.t('shared.documents.docModal.option4'),
    }

  return null
}

interface DocumentCardProps {
  id: string
  refetch: () => void
  title: string
  filePath: string
  size: number
  date: string
  documentRestrictions: string[]
  onEdit?: () => void
  deletable?: boolean
  downloadable?: boolean
}

const DocumentCard: React.FC<DocumentCardProps> = ({
  downloadable = false,
  deletable = false,
  onEdit = null,
  ...props
}) => {
  const [deleteDocument] = useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(MUTATION_DELETE_DOCUMENT)

  const [getPresignedDocument] = useMutation<
    GetPresignedDocumentMutation,
    GetPresignedDocumentMutationVariables
  >(MUTATION_GET_PRESIGNED_DOCUMENT)
  const { t } = useTranslation()
  const theme = useTheme()
  const notifications = useNotifications()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false)

  const onDelete = useCallback(() => {
    if (!deletable) return null
    deleteDocument({
      variables: {
        documentId: props.id,
      },
    })
      .then(r => {
        if (r.data?.deleteDocument) {
          props.refetch()
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.documents.notifications.docDeleted'),
          })
        }
      })
      .catch(apiErrorCatcher(notifications))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletable, deleteDocument, props.refetch])

  const getDocument = useCallback(() => {
    if (!downloadable) return null
    getPresignedDocument({
      variables: {
        documentId: props.id,
      },
    })
      .then(r => {
        if (r.data?.getPresignedDocument)
          window.open(r.data?.getPresignedDocument, '_blank')
      })
      .catch(apiErrorCatcher(notifications))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadable, getPresignedDocument, props.id])

  const documentRights = useMemo(() => {
    return visibilityLabelDefaultValue(props.documentRestrictions)
  }, [props.documentRestrictions])

  let icon = fileIconMapping[getFileExtension(props.filePath)] || 'unknownDoc'

  if (props.filePath.includes('contrat')) {
    icon = 'conventionAlt'
  }

  return (
    <React.Fragment>
      <ActionCard
        css={{
          margin: '0 24px 24px 0',
          [theme.media.mobile]: {
            margin: '0 0px 24px 0',
          },
        }}
        variant="tertiary"
        label={props.title}
        actions={[
          ...((onEdit
            ? [
                {
                  icon: 'edit',
                  onClick: onEdit,
                },
              ]
            : []) as []),
          ...((downloadable
            ? [
                {
                  icon: 'download',
                  onClick: getDocument,
                },
              ]
            : []) as []),
          ...((deletable
            ? [
                {
                  icon: 'trash',
                  onClick: () => setIsDeleteModalOpen(true),
                },
              ]
            : []) as []),
        ]}
        icon={icon}
      >
        <div
          css={{
            marginBottom: '6px',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            fontSize: '1.4rem',
            fontFamily: theme.fontFamily,
            color: theme.colors.primary,
            fontWeight: 500,
          }}
        >
          <span>
            {t('shared.documentCard.size')}
            <span css={{ color: theme.colors.gray1 }}>
              {formatBytes(props.size)}
            </span>
          </span>
          <span>
            {t('shared.documentCard.date')}
            <span css={{ color: theme.colors.gray1 }}>
              {format(new Date(props.date), 'dd/MM/yyyy')}
            </span>
          </span>
          {documentRights?.uploadBy && (
            <span>
              {t('shared.documentCard.uploadBy')}
              <span css={{ color: theme.colors.gray1 }}>
                {documentRights?.uploadBy}
              </span>
            </span>
          )}
          {documentRights?.visibility && (
            <span>
              {t('shared.documentCard.visibility')}
              <span css={{ color: theme.colors.gray1 }}>
                {documentRights.visibility}
              </span>
            </span>
          )}
        </div>
      </ActionCard>

      <ModalDeletionConfirmation
        isOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        onDelete={onDelete}
      />
    </React.Fragment>
  )
}

export default DocumentCard
