import { StateOfUseType } from '@goodwatt/shared/dist/types'
import {
  BabySeatOptions,
  EmployeeLoanActions,
  GetEmployeeQuery,
  SpecialBikesType,
} from '../__generated__/graphql'

export interface CompanySelectedEmployeesTableRowDetails {
  id: string
  name: string
  bikeIndex: number
  homeWorkRides: number
  ridesTotalDistance: number
  distancePerDay: number
  loanPossibleAction: EmployeeLoanActions

  specialBikeType: SpecialBikesType | null
  specialBikeAbsolutelyNeeded: boolean | null
  babySeatNeeded: BabySeatOptions | null

  hasReturnBike: boolean
  identityCardFrontDocument: string | null
  identityCardBackDocument: string | null
  identityCardVerified: boolean | null
  proofOfAddressDocument: string | null
  proofOfAddressVerified: boolean | null
  depositId: string | null
  depositExpirationDate: Date | null
  depositByCheque: boolean | null
  depositReleasedAt: Date | null
  estimatedTotal: number
}

export interface CompanySelectedEmployeesTableRow {
  id: string
  email: string
  employee: any
}

export enum CompanySelectedEmployeesTableModal {
  EDIT = 'edit',
  WITHDRAWAL = 'withdrawal',
  LOAN_BIKE = 'loanBike',
  RETURN_PACKAGE = 'returnPackage',
  RETURN_BIKE = 'returnBike',
  RETENTION = 'retention',
  APPOINTMENT = 'appointment',
  RELEASE_DEPOSITS = 'releaseDeposits',
}

export type CompanyEmployeesModalSettings<
  D = GetEmployeeQuery['employee'],
  T = CompanySelectedEmployeesTableModal,
> = {
  employee: D | null
  actionName: '' | T
}

/**
 * PART LISTS
 */
export type PartsConditionModalSettings = {
  part: PartsConditionTableRow
  actionName: PartsConditionTableModal
}

export enum PartsConditionTableModal {
  NOTE = 'note',
  CAMERA = 'camera',
  NONE = 'none',
}

export type PartsConditionTableRow = {
  id: string | undefined
  name: string | undefined
  stateOfUse: StateOfUseType | undefined
  note: string | undefined
  attachments: { id: string; title: string; size: number }[] | undefined
}
