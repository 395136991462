/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import {
  QuizSettings,
  QuizTableModalActions,
  QuizTableRow,
} from '@goodwatt/client/src/types/Quizzes'
import Table from '@goodwatt/client/src/components/Table'
import QuizActionCell from '@goodwatt/client/src/components/Table/CustomCells/QuizActionCell'

import { useTheme } from 'emotion-theming'
import { QUERY_GET_QUIZZES } from '../../../utils/gql/queries'

import ViewModal from './ViewModal'
import {
  GetQuizzesQuery,
  GetQuizzesQueryVariables,
} from '../../../__generated__/graphql'

const initalModalSettings: QuizSettings = {
  quiz: {
    id: '',
    title: '',
    content: '',
    MIME: '',
    imageURL: '',
  },
  actionName: '',
}

interface AnimatorCompanyMonitoringProps {
  pageSize: number
}

const AnimatorCompanyMonitoringList: React.FC<
  AnimatorCompanyMonitoringProps
> = ({ pageSize }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [currModal, setCurrModal] = useState<QuizSettings>(initalModalSettings)

  const { data: quizData, loading } = useQuery<
    GetQuizzesQuery,
    GetQuizzesQueryVariables
  >(QUERY_GET_QUIZZES, {
    fetchPolicy: 'network-only',
    variables: {},
  })

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<QuizTableRow> }) => (
      <QuizActionCell
        setModal={setCurrModal}
        row={row}
        actions={[QuizTableModalActions.VIEW]}
      />
    ),
    [],
  )
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('quiz.table.headers.title') as string,
        accessor: 'title',
        disableSortBy: true,
        maxWidth: 200,
      },
      {
        Header: t('quiz.table.headers.content') as string,
        accessor: 'contentCroped',
        disableSortBy: true,
      },
      {
        Header: t('quiz.table.headers.imageURL') as string,
        accessor: 'imageURL',
        disableSortBy: true,
        cellStyle: () => ({ minWidth: '110px' }),
      },
      {
        Header: t(
          'animator.company.monitoring.table.headers.actions',
        ) as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [ActionCell, t],
  )
  const formattedData = useMemo(() => {
    if (!quizData?.quizzes?.length) return []

    return quizData?.quizzes?.map(quiz => ({
      id: quiz?.id,
      title: quiz?.title,
      content: quiz?.content,
      imageURL: quiz?.imageURL,
      contentCroped: quiz?.content,
      MIME: quiz?.MIME,
    }))
  }, [quizData])

  return (
    <React.Fragment>
      <div css={{ minHeight: 380 }}>
        <Table loading={loading} columns={columns} data={formattedData} />
      </div>
      <div
        css={{
          position: 'relative',
          display: 'flex',
          marginTop: '41px',
          marginBottom: '31px',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            paddingLeft: 77,
            [theme.media.mobile]: {
              paddingLeft: 0,
            },
          }}
        ></div>
        <ViewModal
          quiz={currModal.quiz}
          isOpen={currModal.actionName === QuizTableModalActions.VIEW}
          closeModal={() => setCurrModal(initalModalSettings)}
        />
      </div>
    </React.Fragment>
  )
}

export default AnimatorCompanyMonitoringList
