/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import AuthContainer from '../../components/AuthContainer'
import AuthenticationCard from '../../components/Card/AuthenticationCard'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import SuccessStep from './SuccessStep'
import StepSite from './StepSite'

const SignUpEmployee: React.FC = () => {
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  // Password is set here because it should not be persisted
  const [unpersistedForm, setUnpersistedForm] = useState<{
    password: string
    confirmPassword: string
    cgu: boolean
  }>({
    password: '',
    confirmPassword: '',
    cgu: false,
  })

  const onNext = () => setStep(step + 1)

  const onPrevious = () => setStep(step - 1)

  return (
    <AuthContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('auth.signupEmployee.metaTitle')}</title>
        <link rel="canonical" href="https://goodwatt.fr/signup-employee" />
      </Helmet>
      <AuthenticationCard
        title={
          step === 1
            ? t('auth.signupEmployee.stepSiteTitle')
            : step === 2
            ? t('auth.signupEmployee.step2Title')
            : step === 3
            ? t('auth.signupEmployee.step3Title')
            : step === 4
            ? t('auth.signupEmployee.stepSuccessTitle')
            : t('auth.signupEmployee.stepTitle')
        }
      >
        {step === 0 ? (
          <Step1 onNext={onNext} />
        ) : step === 1 ? (
          <StepSite onNext={onNext} onPrevious={onPrevious} />
        ) : step === 2 ? (
          <Step2 onNext={onNext} onPrevious={onPrevious} />
        ) : step === 3 ? (
          <Step3
            onNext={onNext}
            onPrevious={onPrevious}
            unpersistedForm={unpersistedForm}
            setUnpersistedForm={setUnpersistedForm}
          />
        ) : step === 4 ? (
          <SuccessStep />
        ) : null}
      </AuthenticationCard>
    </AuthContainer>
  )
}

export default SignUpEmployee
