/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import { format, startOfWeek, startOfYesterday } from 'date-fns'

import CardTitle from '../../../../../components/Card/CardTitle'
import CardFoldable from '../../../../../components/Card/Foldable'
import Input from '../../../../../components/Input'
import InputSelect from '../../../../../components/InputSelect'
import List from './List'

import { TextVariant } from '../../../../../hooks/useVariant/variants/text'
import { SelectVariant } from '../../../../../hooks/useVariant/variants/select'

import {
  EmployeeTableRefetchContext,
  EmployeeTableTypes,
} from '../EmployeeTableRefetchContext'
import {
  CompanyLoanPlacesQuery,
  CompanyLoanPlacesQueryVariables,
  IntFilter,
} from '../../../../../__generated__/graphql'

const QUERY_COMPANY_LOAN_PLACES = gql`
  query CompanyLoanPlaces($companyId: String) {
    company(where: { id: $companyId }) {
      id
      programStep {
        stepNbr
      }
      loanQuantityBikes
      employeesSelectedForLoan
    }
  }
`

let debouncedFn: undefined | (() => void)

const SelectedEmployeesLoan: React.FC = () => {
  const {
    tableRefetchPlanner: { [EmployeeTableTypes.SELECTED]: needToRefetch },
  } = React.useContext(EmployeeTableRefetchContext)
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    temporality: null,
    distance: null,
    multiFilter: null,
  })
  const { id: companyId } = useParams<{ id: string }>()
  const { data, refetch: refetchBadgeCount } = useQuery<
    CompanyLoanPlacesQuery,
    CompanyLoanPlacesQueryVariables
  >(QUERY_COMPANY_LOAN_PLACES, {
    variables: { companyId },
  })

  React.useEffect(() => {
    if (needToRefetch) {
      refetchBadgeCount()
    }
  }, [needToRefetch, refetchBadgeCount])
  React.useEffect(() => {
    return () => {
      debouncedFn = undefined
    }
  }, [])
  const theme = useTheme()

  const onTemporalitySelectChange = React.useCallback(({ value }, { name }) => {
    let newFilterValue: Date | null = null
    if (value === 'yesterday') {
      newFilterValue = startOfYesterday()
    } else if (value === 'lastWeek') {
      newFilterValue = startOfWeek(new Date().setHours(-7 * 24), {
        weekStartsOn: 1,
      })
    }

    setFilters(prevState => ({
      ...prevState,
      [name]: value !== 'all' ? newFilterValue : null,
    }))
  }, [])

  const onSelectChange = React.useCallback(({ value }, { name }) => {
    setFilters(prevState => ({
      ...prevState,
      [name]: value !== 'all' ? value : null,
    }))
  }, [])

  const onInputNameChange = React.useCallback(e => {
    /* signal to React not to nullify the event object */
    e.persist()

    if (!debouncedFn) {
      debouncedFn = debounce(() => {
        setFilters(prevState => ({
          ...prevState,
          multiFilter: e.target.value || undefined,
        }))
      }, 300)
    }
    debouncedFn()
  }, [])

  const temporalityFilterOptions = React.useMemo(
    () => [
      {
        label: t(
          'animator.company.employees.selected.filter.temporality.options.all',
        ),
        value: 'all',
      },
      {
        label: t(
          'animator.company.employees.selected.filter.temporality.options.lastWeek',
        ),
        value: 'lastWeek',
      },
      {
        label: t(
          'animator.company.employees.selected.filter.temporality.options.yesterday',
        ),
        value: 'yesterday',
      },
    ],
    [t],
  )

  const distanceFilterOptions = React.useMemo(
    () => [
      {
        label: t(
          'animator.company.employees.selected.filter.distance.options.all',
        ),
        value: 'all',
      },
      {
        label: t(
          'animator.company.employees.selected.filter.distance.options.lt1',
        ),
        value: { lt: 1 },
      },
      {
        label: t(
          'animator.company.employees.selected.filter.distance.options.gte1Lt10',
        ),
        value: { gte: 1, lt: 10 },
      },
      {
        label: t(
          'animator.company.employees.selected.filter.distance.options.gte10Lt50',
        ),
        value: { gte: 10, lt: 50 },
      },
      {
        label: t(
          'animator.company.employees.selected.filter.distance.options.gt50',
        ),
        value: { gte: 50 },
      },
    ],
    [t],
  )

  React.useEffect(() => (debouncedFn = undefined), [])

  return (
    <CardFoldable
      title={
        <CardTitle
          statusColor={theme.colors.greenLegend}
          label={t('animator.company.employees.selected.loanTitleLabel')}
          badge={{
            label: t('animator.company.employees.selected.ratio', {
              current: data?.company?.employeesSelectedForLoan,
              total: data?.company?.loanQuantityBikes || 0,
            }),
            fontColor: theme.colors.white,
            backgroundColor: theme.colors.greenLegend,
          }}
        />
      }
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          marginTop: '2.1rem',
          marginBottom: '2.1rem',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <div
          css={{
            display: 'flex',
            flex: 1,
            minWidth: '220px',
            paddingRight: '17px',
            width: '100%',
            [theme.media.mobile]: {
              paddingRight: '0px',
            },
          }}
        >
          <InputSelect<string>
            name="temporality"
            withError={false}
            variant={SelectVariant.white}
            label={t(
              'animator.company.employees.selected.filter.temporality.label',
            )}
            defaultValue="all"
            onChange={onTemporalitySelectChange}
            options={temporalityFilterOptions}
          />
        </div>
        <div
          css={{
            display: 'flex',
            flex: 1,
            minWidth: '220px',
            paddingRight: '17px',
            width: '100%',
            [theme.media.mobile]: {
              paddingRight: '0px',
              marginTop: '14px',
            },
          }}
        >
          <InputSelect<string | IntFilter>
            name="distance"
            withError={false}
            variant={SelectVariant.white}
            label={t(
              'animator.company.employees.selected.filter.distance.label',
            )}
            defaultValue="all"
            onChange={onSelectChange}
            options={distanceFilterOptions}
          />
        </div>
        <div
          css={{
            display: 'flex',
            flex: 2,
            minWidth: '220px',
            [theme.media.tablet]: {
              marginTop: '14px',
            },
          }}
        >
          <Input
            icon="magnifier"
            variant={TextVariant.white}
            name="companyEmployeesAutoComplete"
            withError={false}
            onChange={onInputNameChange}
          />
        </div>
      </div>
      <List
        filters={filters}
        loanEventsFormatted={{
          loanDeliveryDate: new Date()
            ? format(new Date(), 'dd/MM/yyyy')
            : '...',
          loanReturnDate: new Date() ? format(new Date(), 'dd/MM/yyyy') : '...',
        }}
        currentProgramStep={data?.company?.programStep.stepNbr || 0}
      />
    </CardFoldable>
  )
}

export default SelectedEmployeesLoan
