/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'

import Icon from './Icon'

const Loader: React.FC = () => {
  const theme = useTheme()

  return (
    <div
      css={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Icon type="loader" size="large" strokeColor={theme.colors.primary} />
    </div>
  )
}

export default Loader
