/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select'

import Card from '.'
import { SelectVariant } from '../../hooks/useVariant/variants/select'
import Icon, { IconTypes } from '../Icon'
import InputSelect from '../InputSelect'
import { SummaryFilter } from '../../__generated__/graphql'
import { Flex } from '../Flex'

export type SummaryItem = {
  id: string
  icon: IconTypes
  label: string
  description: string
}

interface SummaryCardProps {
  title: string
  itemList: SummaryItem[]
  linkTo?: string
  fullWidth?: boolean
  loading: boolean
  children?: React.ReactNode
  onSelectChange?:
    | ((
        value: ValueType<OptionTypeBase>,
        action: ActionMeta<OptionTypeBase>,
      ) => void)
    | undefined
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  title,
  itemList,
  linkTo,
  fullWidth,
  loading,
  children,
  onSelectChange,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Card
      css={{
        width: '100%',
        minWidth: 'calc(50% - 12px)',
        maxWidth: fullWidth ? undefined : '500px',
        [theme.media.desktop]: {
          maxWidth: 'unset',
        },
      }}
      title={
        <Flex direction="column">
          <span
            css={{
              fontSize: '1.6rem',
              color: theme.colors.gray2,
              fontWeight: 'bold',
              fontFamily: theme.fontFamily,
              flexShrink: 0,
            }}
          >
            {title}
          </span>
          {children}
          {onSelectChange && (
            <div
              css={{
                display: 'flex',
                flex: 1,
                minWidth: '220px',
                paddingRight: '17px',
                width: '100%',
                marginBottom: 18,
                marginTop: 4,
                alignItems: 'baseline',
                [theme.media.mobile]: {
                  paddingRight: '0px',
                  marginTop: '14px',
                  minWidth: 'unset',
                  flexWrap: 'wrap',
                },
              }}
            >
              <div
                css={{
                  width: '100%',
                }}
              >
                <InputSelect
                  name="rangeType"
                  variant={SelectVariant.white}
                  withError={false}
                  defaultValue={SummaryFilter.All}
                  options={[
                    {
                      value: SummaryFilter.All,
                      label: t('employee.monitoring.filter.all'),
                    },
                    {
                      value: SummaryFilter.Last7,
                      label: t('employee.monitoring.filter.last7'),
                    },
                  ]}
                  onChange={onSelectChange}
                />
              </div>
              <div
                css={{
                  width: '100%',
                }}
              >
                <Link
                  to="/employee/help"
                  css={{ marginTop: '1rem', marginLeft: '1rem', width: '100%' }}
                >
                  <span
                    css={{
                      color: theme.colors.primary,
                      textDecoration: 'underline',
                      fontSize: '1.4rem',
                    }}
                  >
                    {t('employee.monitoring.stats.howTo')}
                  </span>
                </Link>
              </div>
            </div>
          )}
        </Flex>
      }
    >
      <div
        css={{
          paddingRight: '2rem',
          paddingLeft: '2rem',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {itemList.map(summaryItem => (
          <div
            key={summaryItem.id}
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '1.6rem',
              width: '50%',
              minWidth: 170,
            }}
          >
            <div css={{ marginRight: '2.1rem' }}>
              <Icon type={summaryItem.icon} color={theme.colors.primary} />
            </div>
            <div css={{ display: 'flex', flexDirection: 'column' }}>
              <span
                css={{
                  color: theme.colors.gray1,
                  fontSize: '1.6rem',
                }}
              >
                {loading ? '...' : summaryItem.label}
              </span>
              <span css={{ color: theme.colors.gray3, fontSize: '1.3rem' }}>
                {summaryItem.description}
              </span>
            </div>
          </div>
        ))}
      </div>
      {linkTo && (
        <div
          css={{
            marginTop: '2rem',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Link
            css={{
              fontWeight: 'bold',
              color: theme.colors.gray2,
            }}
            to={linkTo}
          >
            {t('shared.label.moreDetails')}
          </Link>
        </div>
      )}
    </Card>
  )
}

export default SummaryCard
