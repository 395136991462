/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { gql, useMutation } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Modal from '@goodwatt/client/src/components/Modal'
import Popup from '@goodwatt/client/src/components/Popup'
import InputSelect from '@goodwatt/client/src/components/InputSelect'
import Input from '@goodwatt/client/src/components/Input'
import TextArea from '@goodwatt/client/src/components/TextArea'
import Button from '@goodwatt/client/src/components/Button'
import {
  useNotifications,
  NotificationTypes,
} from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import {
  PostTableModalActions,
  PostTableAdd,
  PostTableRow,
  ModalPostSubmit,
} from '@goodwatt/client/src/types/AnimatorPosts'

import theme from '../../../../styles/theme'
import {
  AddTagToPostMutation,
  AddTagToPostMutationVariables,
  CreatePostMutation,
  CreatePostMutationVariables,
  GetTagsQuery,
  RemoveTagFromPostMutation,
  RemoveTagFromPostMutationVariables,
  UpdatePostMutation,
  UpdatePostMutationVariables,
} from '../../../../__generated__/graphql'

const schema = yup.object().shape({
  tags: yup.mixed().required(''),
  title: yup.string().required(''),
  content: yup.string().required(''),
  order: yup.number().required(''),
})

const MUTATION_UPDATE_POST = gql`
  mutation UpdatePost($input: PostUpdateInput!) {
    updatePost(input: $input) {
      id
      title
      order
      content
      MIME
    }
  }
`
const MUTATION_CREATE_POST = gql`
  mutation CreatePost($input: PostCreationInput!) {
    createPost(input: $input) {
      id
    }
  }
`

const MUTATION_REMOVE_TAG_FROM_POST = gql`
  mutation RemoveTagFromPost($id: String, $tagKey: String) {
    removeTagFromPost(id: $id, tagKey: $tagKey) {
      id
      title
      tags {
        id
        key
      }
    }
  }
`

const MUTATION_ADD_TAG_TO_POST = gql`
  mutation AddTagToPost($id: String, $tagKey: String) {
    addTagToPost(id: $id, tagKey: $tagKey) {
      id
      title
      tags {
        id
        key
      }
    }
  }
`

interface AddOrEditModalProps {
  isOpen: boolean
  post: PostTableRow
  dataList: undefined | GetTagsQuery
  type: '' | PostTableAdd | PostTableModalActions
  closeModal: () => void
  updateList: () => void
}

const AddOrEditModal: React.FC<AddOrEditModalProps> = ({
  isOpen,
  closeModal,
  post,
  dataList,
  type,
  updateList,
}) => {
  const { register, handleSubmit, errors, control, reset } =
    useForm<PostTableRow>({
      resolver: yupResolver(schema),
    })

  React.useEffect(() => reset(), [post, reset, type])
  const { t } = useTranslation()
  const [updatePost, { loading }] = useMutation<
    UpdatePostMutation,
    UpdatePostMutationVariables
  >(MUTATION_UPDATE_POST)
  const [createPost, { loading: createLoading }] = useMutation<
    CreatePostMutation,
    CreatePostMutationVariables
  >(MUTATION_CREATE_POST)
  const [addTagToPost] = useMutation<
    AddTagToPostMutation,
    AddTagToPostMutationVariables
  >(MUTATION_ADD_TAG_TO_POST)
  const [removeTagFromPost] = useMutation<
    RemoveTagFromPostMutation,
    RemoveTagFromPostMutationVariables
  >(MUTATION_REMOVE_TAG_FROM_POST)
  const notifications = useNotifications()
  const onSubmit = async (data: ModalPostSubmit) => {
    const handleSuccess = () => {
      updateList()
      closeModal()
      notifications.newNotification({
        type: NotificationTypes.SUCCESS,
        message: t(`animator.post.modal.${type}.successNotification`),
      })
    }
    if (type === PostTableAdd.ADD) {
      try {
        const { data: createPostData } = await createPost({
          variables: {
            input: {
              content: data.content,
              title: data.title,
              MIME: 'text/markdown',
              order: data.order,
            },
          },
        })
        if (data?.tags?.value) {
          await addTagToPost({
            variables: {
              id: createPostData?.createPost?.id,
              tagKey: data.tags.label,
            },
          })
        }
        handleSuccess()
      } catch (error) {
        apiErrorCatcher(notifications)(error)
      }
    } else {
      // for now post only have one tag so we update tag only if it changed
      if (
        data?.tags?.value &&
        post.tags.findIndex(t => t.key === data.tags.label) === -1
      ) {
        addTagToPost({
          variables: { id: post.id, tagKey: data.tags.label },
        }).catch(apiErrorCatcher(notifications))
        if (post.tags[0])
          removeTagFromPost({
            variables: { id: post.id, tagKey: post.tags[0]?.key },
          }).catch(apiErrorCatcher(notifications))
      }

      return updatePost({
        variables: {
          input: {
            id: post.id,
            content: data.content,
            title: data.title,
            order: data.order,
            MIME: data.MIME || 'text/markdown',
          },
        },
      })
        .then(handleSuccess)
        .catch(apiErrorCatcher(notifications))
    }
  }
  return (
    <Modal isOpen={isOpen}>
      <Popup
        separator={false}
        closable
        title={t(`animator.post.modal.title`)}
        onClose={closeModal}
        maxWidth={750}
        footer={
          <React.Fragment>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="tertiary"
                submit
                loading={loading || createLoading}
                onClick={closeModal}
              >
                {t('forms.button.cancel')}
              </Button>
            </div>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="primary"
                submit
                loading={loading || createLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t('forms.button.confirm')}
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <div css={{ padding: '30px 54px 38px' }}>
          <form>
            <div
              css={{
                width: '100%',
                padding: '0 12px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                [theme.media.mobile]: {
                  padding: '0',
                },
              }}
            >
              <label
                css={{
                  fontSize: '1.6rem',
                  color: theme.colors.gray3,
                  textAlign: 'left',
                  marginBottom: 12,
                  width: '50%',
                }}
              >
                {t('forms.label.category')}
              </label>
              <Controller
                name="tags"
                control={control}
                defaultValue={
                  type !== PostTableAdd.ADD && post?.tags[0]?.key
                    ? {
                        label: post?.tags[0].key,
                        value: post.tags[0]?.id,
                      }
                    : null
                }
                render={({ onChange, onBlur, value, name }) => (
                  <InputSelect
                    name={name}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    options={
                      dataList?.postTags?.map(tag => ({
                        label: tag?.key || '',
                        value: tag?.id,
                      })) || []
                    }
                    error={!!errors.tags}
                  />
                )}
              />
            </div>

            <div
              css={{
                width: '100%',
                padding: '0 12px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                [theme.media.mobile]: {
                  padding: '0',
                },
              }}
            >
              <label
                css={{
                  fontSize: '1.6rem',
                  color: theme.colors.gray3,
                  textAlign: 'left',
                  marginBottom: 12,
                  width: '50%',
                }}
              >
                {t('forms.label.order')}
              </label>
              <Input
                center
                type="number"
                name="order"
                css={{
                  margin: 'none',
                }}
                defaultValue={post?.order || ''}
                register={register}
                error={!!errors.order}
              />
            </div>

            <div css={{ marginTop: 12 }}>
              <Controller
                name="title"
                control={control}
                defaultValue={post.title}
                render={({ onChange, value }) => {
                  return (
                    <Input
                      placeholder={t('forms.label.question')}
                      label={t('forms.label.question')}
                      onChange={onChange}
                      value={value}
                      error={!!errors.title}
                      helperText={errors?.title?.message}
                    />
                  )
                }}
              />
            </div>
            <div css={{ marginBottom: '2rem' }}>
              <Controller
                name="content"
                control={control}
                defaultValue={post.content}
                render={({ onChange, onBlur, value, name }) => (
                  <TextArea
                    block
                    name={name}
                    value={value}
                    label={t('forms.label.answer')}
                    onBlur={onBlur}
                    onChange={onChange}
                    rows={12}
                    withError={false}
                    register={register}
                    placeholder={t('forms.label.leaveAcontent')}
                    error={!!errors.content}
                    helperText={errors?.content?.message}
                  />
                )}
              />
            </div>
          </form>
        </div>
      </Popup>
    </Modal>
  )
}

export default AddOrEditModal
