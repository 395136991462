/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import DeselectEmployeeModal from './DeselectEmployeeModal'
import { useModal } from '../../../../hooks/useModal'

export interface DeselectEmployeeButtonProps {
  onCompleted?: () => void
  employeeDeploymentId: string
  employeeFullName: string
}

export const DeselectEmployeeButton: React.FC<DeselectEmployeeButtonProps> = ({
  onCompleted,
  employeeDeploymentId,
  employeeFullName,
}) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <DeselectEmployeeModal
        employeeDeploymentId={employeeDeploymentId}
        employeeFullName={employeeFullName}
        onCompleted={onCompleted}
        {...modalProps}
      />
      <button onClick={onOpen}>
        <Icon type="close" />
      </button>
    </Fragment>
  )
}
