/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  plusIcon,
  plusIconRotate,
  questionHidden,
  questionShared,
  tagsSelected,
  tagsSharedCss,
  tagsUnselected,
} from '../../styles/faq'
import Icon from '../../components/Icon'
import React, { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import Input from '../../components/Input'
import { TextVariant } from '../../hooks/useVariant/variants/text'
import { marked } from 'marked'
import { GetPostsQuery, GetTagsQuery } from '../../__generated__/graphql'
interface FAQProps {
  postData: undefined | GetPostsQuery
  postTags: undefined | GetTagsQuery
}

const FAQList: React.FC<FAQProps> = ({ postData, postTags }) => {
  const { t } = useTranslation()
  const [faqCurrentTag, setFaqCurrentTag] = useState<string | undefined>(
    undefined,
  )
  const [questionsUnfolded, setQuestionsUnfolded] = useState<{
    [key: string]: boolean
  }>({})
  const [searchField, setSearchField] = useState<string>('')

  React.useEffect(() => {
    const questionsDefaultFolding: { [key: string]: boolean } = {}
    postData?.posts?.forEach(post => {
      if (post?.title) questionsDefaultFolding[post.title] = false
    })
    setQuestionsUnfolded(questionsDefaultFolding)
  }, [postData])

  React.useEffect(() => {
    if (!postTags?.postTags[0]) return
    const defaultTag: string | undefined = postTags?.postTags[0].key
    if (defaultTag) setFaqCurrentTag(defaultTag)
  }, [postTags])

  const formattedData = useMemo(() => {
    if (!postData?.posts?.length) return []
    return postData?.posts?.map(post => ({
      id: post?.id,
      title: post?.title,
      content: post?.content,
      contentCroped: post?.content.substr(0, 40),
      MIME: post?.MIME,
      tags: post?.tags,
      currentTag: post?.tags[0]?.key || 'N/a',
    }))
  }, [postData])

  const onInputNameChange = React.useCallback(event => {
    setSearchField(event.target.value)
    event.persist()
  }, [])

  const questions = formattedData
    ?.filter(question =>
      question?.tags?.some(({ key: tag }) => tag === faqCurrentTag),
    )
    ?.filter(question => {
      if (searchField === '') return true
      return question.title?.includes(searchField)
    })

  return (
    <div id="faq">
      <Input
        icon="magnifier"
        variant={TextVariant.white}
        placeholder={t('forms.label.searchTag')}
        name="companySubscribedEmployeesAutoComplete"
        withError={false}
        value={searchField}
        onChange={onInputNameChange}
      />
      <div css={{ display: 'flex', width: '90%' }} id="faq-data">
        <div css={{ padding: '30px' }} id="tags">
          <ul>
            {postTags?.postTags?.map(
              ({ key, id }: { key: string; id: string }) => (
                <li
                  key={id}
                  css={
                    key === faqCurrentTag
                      ? [tagsSharedCss, tagsSelected]
                      : [tagsSharedCss, tagsUnselected]
                  }
                  onClick={() => setFaqCurrentTag(key)}
                >
                  {key}
                </li>
              ),
            )}
          </ul>
        </div>
        <div css={{ flex: 1 }} id="questions">
          <ul css={{ marginTop: '20px' }}>
            {questions.map(({ title, id, content }) => (
              <li key={id} css={[questionHidden, questionShared]}>
                <span
                  css={
                    title && questionsUnfolded[title]
                      ? [plusIcon, plusIconRotate]
                      : [plusIcon]
                  }
                >
                  <Icon type="plus" />
                </span>
                <p
                  css={{
                    height: '48px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (title) {
                      const currentlyUnfolded = questionsUnfolded[title]
                      setQuestionsUnfolded({
                        ...questionsUnfolded,
                        [title]: !currentlyUnfolded,
                      })
                    }
                  }}
                >
                  {title}
                </p>
                {title && questionsUnfolded[title] && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: marked.parse(content || ''),
                    }}
                    css={{ fontWeight: 300, padding: '12px 0 24px 0' }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FAQList
