/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'
import { UserRoles } from '@goodwatt/shared/dist/types'
import { CompanyReferrerTableModal } from '../../../types/CompanyReferrer'

import Icon from '../../Icon'
import Nav from './Nav'

import { NavItem } from '../../../hooks/useMenu'
import useWindowSize from '../../../hooks/useWindowSize'
import MobileHeader from '../MobileHeader'
import { StoreState } from '../../../redux/rootReducer'
import { CreateSiteModal } from './CreateSiteModal'
import EditReferrerModal from '../../../pages/Shared/Referrer/EditReferrerModal'

export interface MenuProps {
  navList: NavItem[]
  closeMenu: () => void
}

const Menu: React.FC<MenuProps> = ({ navList, closeMenu }): JSX.Element => {
  const theme = useTheme()
  const [open, setOpen] = React.useState<'referrer' | 'site' | false>(false)
  const { width } = useWindowSize()
  const user = useSelector((state: StoreState) => state.user)
  const { t } = useTranslation()
  const onModalClose = () => setOpen(false)

  // Password is set here because it should not be persisted
  const [unpersistedForm, setUnpersistedForm] = React.useState<{
    password: string
    confirmPassword: string
  }>({
    password: '',
    confirmPassword: '',
  })

  return (
    <div
      css={{
        height: '100%',
        overflowY: 'auto',
        [theme.media.tablet]: {
          paddingBottom: '80px',
          width: '100%',
        },
      }}
    >
      {width <= theme.breakpoints.tablet ? (
        <MobileHeader
          onLeftIconClicked={closeMenu}
          leftIcon="close"
          showDetails={false}
        />
      ) : (
        <div css={{ padding: '30px 0', textAlign: 'center' }}>
          <Link to="/">
            <Icon type="logo" customSize={{ width: 197, height: 54 }} />
          </Link>
        </div>
      )}
      <Nav navList={navList} closeMenu={closeMenu} />

      {user.role === UserRoles.COMPANY && (
        <div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              cursor: 'pointer',
              margin: '30px 0 0 16px',
              textAlign: 'center',
              textDecoration: 'underline',
              color: theme.colors.blue1,
            }}
          >
            <div onClick={() => setOpen('referrer')}>
              {t('company.menu.addReferee')}
            </div>

            <div onClick={() => setOpen('site')}>
              {t('company.menu.createSite')}
            </div>
          </div>

          <CreateSiteModal
            isOpen={open === 'site'}
            onModalClose={onModalClose}
          />

          <EditReferrerModal
            isOpen={open === 'referrer'}
            onModalClose={onModalClose}
            referrer={null}
            type={CompanyReferrerTableModal.ADD}
            refetch={() => {}}
            submitCloseModal={onModalClose}
            unpersistedForm={unpersistedForm}
            setUnpersistedForm={setUnpersistedForm}
          />
        </div>
      )}
    </div>
  )
}

export default Menu
