/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState } from 'react'
import { UserState } from '../redux/user'
import { UserRoles } from '../redux/user'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { IconTypes } from './Icon'
import Button from '../components/Button'
import AddBikeModal from '../pages/Shared/Bikes/AddBikeModal'
import ModalFleet from '../pages/Shared/Fleets/ModalFleet'
import DeploymentModal from '../pages/Shared/Deployments/AddDeploymentModal'
import { DeploymentType } from '../__generated__/graphql'
import { CreateSiteModal } from './Layout/Menu/CreateSiteModal'
import EditReferrerModal from '../pages/Shared/Referrer/EditReferrerModal'
import { BikeModalSettings, BikeTableModal } from '../types/AnimatorBikes'
import { FleetModalSetting, FleetModalType } from '../types/AnimatorFleets'
import { CompanyReferrerTableModal } from '../types/CompanyReferrer'
import {
  DeploymentModalSettings,
  DeploymentTableModal,
} from '../types/AnimatorDeployments'

const initalBikeModalSettings: BikeModalSettings = {
  bike: {
    id: '',
    index: 0,
    bikeModel: '',
    fleet: '',
    bikeCondition: '',
    stickerId: undefined,
    resolvedDate: undefined,
  },
  actionName: '',
}

const initialDeploymentSettings: DeploymentModalSettings = {
  deployment: {
    id: '',
    name: '',
    deploymentType: DeploymentType.None,
    deploymentStartDate: undefined,
    deploymentEndDate: undefined,
    userId: undefined,
    siteId: undefined,
    fleetId: undefined,
    cargoBike: false,
    foldingBike: false,
    urbanBike: false,
    cargoBikeChildSeat: false,
    urbanBikeChildSeat: false,
    loanQuantityBikes: 0,
    bikeModelPrices: [],
    appointmentTemplates: [],
  },
  actionName: '',
}

const initialFleetModalSetting: FleetModalSetting = {
  fleet: undefined,
  modalState: FleetModalType.NONE,
}

interface ContextualButtonProps {
  user: UserState
}

interface NavItem {
  icon?: IconTypes
  title: string
  action: any
}

const ContextualButton: React.FC<ContextualButtonProps> = ({ user }) => {
  const [open, setOpen] = useState<'referrer' | 'site' | false>(false)

  const [currBikeModal, setCurrBikeModal] = useState<BikeModalSettings>(
    initalBikeModalSettings,
  )
  const [currFleetModal, setCurrFleetModal] = useState<FleetModalSetting>(
    initialFleetModalSetting,
  )
  const [currDeploymentModal, setCurrDeploymentModal] =
    useState<DeploymentModalSettings>(initialDeploymentSettings)

  const handleEditBikeModalClose = (shouldRefetch = false) => {
    setCurrBikeModal(initalBikeModalSettings)
    if (shouldRefetch) {
    }
  }

  const handleEditFleetModalClose = (shouldRefetch = false) => {
    setCurrFleetModal(initialFleetModalSetting)
    if (shouldRefetch) {
    }
  }

  const handleEditDeploymentModalClose = (shouldRefetch = false) => {
    setCurrDeploymentModal(initialDeploymentSettings)
    if (shouldRefetch) {
    }
  }

  const handleModalClose = () => {
    setOpen(false)
  }

  // Password is set here because it should not be persisted
  const [unpersistedForm, setUnpersistedForm] = useState<{
    password: string
    confirmPassword: string
  }>({
    password: '',
    confirmPassword: '',
  })

  const { t } = useTranslation()
  const locationPathName = useLocation().pathname
  let isAdminOrAnimator = false
  let isCompany = false

  const buttonSetting = (
    userRoleName: any,
    locationPathName: string,
  ): NavItem[] => {
    if (userRoleName === UserRoles.COMPANY) {
      isCompany = true
      switch (locationPathName) {
        case '/company/referrers':
          return [
            {
              icon: 'company',
              title: t('shared.button.addReferrer'),
              action: () => setOpen('referrer'),
            },
          ]

        case '/company/sites':
          return [
            {
              icon: 'company',
              title: t('shared.button.addSite'),
              action: () => setOpen('site'),
            },
          ]

        default:
          return [
            {
              icon: 'company',
              title: '',
              action: '',
            },
          ]
      }
    }

    if (userRoleName === UserRoles.ADMIN) {
      isAdminOrAnimator = true
      switch (locationPathName) {
        case '/administrator/dashboard':
          return [
            {
              icon: 'company',
              title: t('shared.button.createDeployment'),
              action: () =>
                setCurrDeploymentModal({
                  deployment: undefined,
                  actionName: DeploymentTableModal.ADD,
                }),
            },
          ]
        default:
          return [
            {
              icon: 'company',
              title: '',
              action: '',
            },
          ]
      }
    }

    if (userRoleName === UserRoles.ANIMATOR) {
      isAdminOrAnimator = true
      isCompany = true
      let path = locationPathName

      if (locationPathName.includes('/animator/companies')) {
        path = '/animator/companies'
      }

      switch (path) {
        case '/animator/dashboard':
        case '/animator/profile':
        case '/animator/companies':
          return [
            {
              icon: 'company',
              title: t('shared.button.createDeployment'),
              action: () =>
                setCurrDeploymentModal({
                  deployment: undefined,
                  actionName: DeploymentTableModal.ADD,
                }),
            },
          ]

        case '/animator/bikes':
          return [
            {
              icon: 'company',
              title: t('shared.button.addFleet'),
              action: () =>
                setCurrFleetModal({
                  fleet: undefined,
                  modalState: FleetModalType.EDIT,
                }),
            },
            {
              icon: 'company',
              title: t('shared.button.addBike'),
              action: () =>
                setCurrBikeModal({
                  bike: undefined,
                  actionName: BikeTableModal.ADD,
                }),
            },
          ]

        default:
          return [
            {
              icon: 'company',
              title: '',
              action: '',
            },
          ]
      }
    }

    return [
      {
        icon: 'company',
        title: '',
        action: '',
      },
    ]
  }

  const navItems = buttonSetting(user.role, locationPathName)

  const buttons = navItems.map((navItem: NavItem) => {
    return navItem.title === '' ? null : (
      <Button onClick={navItem.action} key={navItem.title}>
        {navItem.title}
      </Button>
    )
  })

  return (
    <React.Fragment>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {buttons}
      </div>
      {isAdminOrAnimator && (
        <AddBikeModal
          isOpen={currBikeModal.actionName === BikeTableModal.ADD}
          closeModal={handleEditBikeModalClose}
        />
      )}
      {isAdminOrAnimator && (
        <ModalFleet
          fleet={currFleetModal.fleet}
          isOpen={currFleetModal.modalState === FleetModalType.EDIT}
          closeModal={handleEditFleetModalClose}
        />
      )}
      {isAdminOrAnimator && (
        <DeploymentModal
          type={DeploymentTableModal.ADD}
          isOpen={currDeploymentModal.actionName === DeploymentTableModal.ADD}
          onClose={handleEditDeploymentModalClose}
        />
      )}

      {isCompany && (
        <CreateSiteModal
          isOpen={open === 'site'}
          onModalClose={handleModalClose}
        />
      )}

      {isCompany && (
        <EditReferrerModal
          isOpen={open === 'referrer'}
          onModalClose={handleModalClose}
          referrer={null}
          type={CompanyReferrerTableModal.ADD}
          refetch={() => {}}
          submitCloseModal={handleModalClose}
          unpersistedForm={unpersistedForm}
          setUnpersistedForm={setUnpersistedForm}
        />
      )}
    </React.Fragment>
  )
}

export default ContextualButton
