/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import CardFoldable from '../../../../components/Card/Foldable'
import { Flex } from '../../../../components/Flex'
import { useFilters } from '../../../../hooks/useFilters'
import InputSelect from '../../../../components/InputSelect'
import { SelectVariant } from '../../../../hooks/useVariant/variants/select'
import { useTranslation } from 'react-i18next'
import Input from '../../../../components/Input'
import { TextVariant } from '../../../../hooks/useVariant/variants/text'
import theme from '../../../../styles/theme'
import { usePagination } from '../../../../hooks/usePagination'
import Pagination from '../../../../components/Pagination'
import Table from '../../../../components/Table'
import { CellProps } from 'react-table'
import {
  Employee,
  EmployeeDeploymentStatus,
} from '../../../../__generated__/graphql'
import { employeeNameFormatter } from '../../../../utils/formatter'
import { useCompanyEmployees } from '../hooks/useCompanyEmployees'
import { useCurrentCompanyId } from '../hooks/useCurrentCompanyId'
import { useLocalFilters } from '../../../../hooks/useLocalFilters'
import { useLocalOrderBy, useOrderBy } from '../../../../hooks/useOrderBy'
import { useLocalPagination } from '../../../../hooks/useLocalPagination'
import { DeploymentTypeIcon } from '../../Shared/DeploymentTypeIcon'
import { useDistinctBy, useDistinctById } from '../../../../hooks/useDistinctBy'
import { EditEmployeeProfileButton } from '../../Deployment/components/EditEmployeeProfileButton'
import Icon from '../../../../components/Icon'
import { ArchiveUserButton } from './ArchiveUserButton'
import { DeleteUserButton } from './DeleteUserButton'
import { EditEmployeeHabitsButton } from '../../Deployment/components/EditEmployeeHabitsButton'
import { VerifyEmployeeEmailButton } from '../../Deployment/components/VerifyEmployeeEmailButton'

interface EmployeesListFilters {
  site: string
  status: string
  deployment: string
  search: string
}

export interface EmployeesListProps {
  isArchived?: boolean
}

export const EmployeesList: React.FC<EmployeesListProps> = ({
  isArchived = false,
}) => {
  const { orderBy, getTableProps } = useOrderBy()
  const { register, filters } = useFilters<EmployeesListFilters>()
  const { variables, getPaginationProps } = usePagination()
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()
  const { data } = useCompanyEmployees(companyId)
  const filtered = useLocalFilters(data?.company?.employees, {
    'user.archived': { value: isArchived },
    'sites.0.id': { value: filters.site },
    'employeeDeployments.0.status': { value: filters.status },
    'employeeDeployments.0.deployment.id': { value: filters.deployment },
    search: {
      value: filters.search,
      apply: (value, filter) => {
        const lower = filter.toLowerCase()
        const name = employeeNameFormatter(
          value.firstName,
          value.lastName,
        ).toLowerCase()
        return (
          (name.includes(lower) ||
            value.employeeDeployments?.some(d =>
              d?.deployment.name.toLowerCase().includes(lower),
            ) ||
            value.phoneNumber.includes(lower) ||
            value.user.email.includes(lower)) ??
          false
        )
      },
    },
  })
  const ordered = useLocalOrderBy(orderBy, filtered)
  const page = useLocalPagination(variables, ordered)
  const sites = useDistinctById(
    data?.company?.employees.flatMap(e => e.sites) ?? [],
  )
  const deployments = useDistinctById(
    data?.company?.employees
      .flatMap(e => e.employeeDeployments)
      .map(e => e!.deployment) ?? [],
  )
  const statuses = useDistinctBy<EmployeeDeploymentStatus>(
    data?.company?.employees.flatMap(e => e.employeeDeployments) ?? [],
    'status',
  )

  const archiveButtonDisabled = (employee: Employee) => {
    if (isArchived) return false

    const { employeeDeployments } = employee
    const hasBike = employeeDeployments?.some(
      ed =>
        (ed?.status === 'BENEFICIARY' &&
          (ed?.bikeLoan?.bikeReturned === false ||
            ed?.bikeLoan?.retentionOnDeposit !== 0)) ||
        ed?.status === 'SELECTED' ||
        ed?.status === 'CANDIDATE',
    )
    return hasBike
  }

  return (
    <CardFoldable
      isDefaultFolded={false}
      title={
        <Flex direction="row" align="center" gap="16px">
          <span
            css={{
              height: '21px',
              width: '21px',
              borderRadius: '100%',
              backgroundColor: isArchived
                ? theme.colors.gray4
                : theme.colors.greenLegend,
            }}
          />
          <h3
            css={{
              fontWeight: 500,
              fontSize: '18px',
              color: theme.colors.gray1,
            }}
          >
            {isArchived
              ? t('animator.companies.employees.myArchivedUsers')
              : t('animator.companies.employees.myUsers')}
          </h3>
        </Flex>
      }
    >
      <Flex direction="column" gap="16px">
        <Flex
          direction="row"
          gap="16px"
          align="end"
          css={{ marginTop: '16px' }}
        >
          <InputSelect
            withError={false}
            variant={SelectVariant.white}
            label={t('animator.companies.employees.site')}
            defaultValue="all"
            options={[
              {
                value: 'all',
                label: t('animator.deployments.users.options.all'),
              },
              ...sites.map(s => ({
                value: s.id,
                label: s.name,
              })),
            ]}
            {...register('site')}
          />
          <InputSelect
            withError={false}
            variant={SelectVariant.white}
            label={t('animator.companies.employees.status')}
            defaultValue="all"
            options={[
              {
                value: 'all',
                label: t('animator.deployments.users.options.all'),
              },
              ...statuses.map(s => ({
                value: s,
                label: t(`animator.companies.employees.employeeStatus.${s}`),
              })),
              {
                value: 'NO_VALUE',
                label: t(
                  'company.employees.filters.testingMonth.options.registered',
                ),
              },
            ]}
            {...register('status')}
          />
          <InputSelect
            withError={false}
            variant={SelectVariant.white}
            label={t('animator.companies.employees.deployment')}
            defaultValue="all"
            options={[
              {
                value: 'all',
                label: t('animator.deployments.users.options.all'),
              },
              ...deployments.map(d => ({
                value: d.id,
                label: d.name,
              })),
            ]}
            {...register('deployment')}
          />
          <Input
            withError={false}
            icon="magnifier"
            variant={TextVariant.white}
            {...register('search')}
          />
        </Flex>
        <Table
          {...getTableProps()}
          columns={[
            {
              Header: t('animator.companies.employees.name') as string,
              Cell: ({ row: { original } }: CellProps<Employee>) => (
                <p
                  css={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {employeeNameFormatter(original.firstName, original.lastName)}
                </p>
              ),
              accessor: 'firstName',
            },
            {
              Header: t('animator.companies.employees.site') as string,
              Cell: ({ row: { original } }: CellProps<Employee>) => {
                const site = original.sites[0]
                return site?.name ?? t('none')
              },
              accessor: 'site.0.name',
            },
            {
              Header: t('animator.companies.employees.deployment') as string,
              Cell: ({ row: { original } }: CellProps<Employee>) => {
                const { employeeDeployments } = original
                if (
                  employeeDeployments == null ||
                  employeeDeployments.length === 0
                ) {
                  return null
                }
                return (
                  <Flex direction="column" gap="4px">
                    {employeeDeployments.map(employeeDeployment => (
                      <p
                        key={employeeDeployment?.id}
                        css={{
                          maxWidth: '256px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {employeeDeployment?.deployment.name}
                      </p>
                    ))}
                  </Flex>
                )
              },
              accessor: 'employeeDeployments.0.deployment.name',
            },
            {
              Header: t('animator.companies.employees.offer') as string,
              Cell: ({ row: { original } }: CellProps<Employee>) => {
                const { employeeDeployments } = original
                return (
                  <Flex direction="column" gap="4px">
                    {employeeDeployments?.map(employeeDeployment => {
                      const remainingPrice =
                        employeeDeployment?.chosenBikeModel?.remainingPrice
                      return (
                        <Flex
                          direction="row"
                          justify="center"
                          gap="4px"
                          key={employeeDeployment?.id}
                        >
                          <DeploymentTypeIcon
                            type={employeeDeployment!.deployment.deploymentType}
                          />
                          {remainingPrice ? (
                            <span>{remainingPrice.toFixed(2)} €</span>
                          ) : null}
                        </Flex>
                      )
                    })}
                  </Flex>
                )
              },
              accessor: 'employeeDeployments.0.deployment.type',
            },
            {
              Header: t('animator.companies.employees.status') as string,
              Cell: ({ row: { original } }: CellProps<Employee>) => {
                const { employeeDeployments } = original
                return (
                  <Flex direction="column" gap="4px">
                    {employeeDeployments && employeeDeployments.length > 0 ? (
                      employeeDeployments?.map(employeeDeployment => (
                        <p key={employeeDeployment?.id}>
                          {t(
                            `animator.companies.employees.employeeStatus.${employeeDeployment?.status}`,
                          )}
                        </p>
                      ))
                    ) : (
                      <p>
                        {t('company.employees.table.employeeStatus.noValue')}
                      </p>
                    )}
                  </Flex>
                )
              },
              accessor: 'employeeDeployments.0.status',
            },
            {
              Header: t('animator.companies.employees.actions') as string,
              Cell: ({ row: { original } }: CellProps<Employee>) => (
                <Flex direction="row" gap="8px">
                  <EditEmployeeProfileButton employee={original}>
                    <Icon type="anonymProfile" />
                  </EditEmployeeProfileButton>
                  <EditEmployeeHabitsButton employee={original} />
                  {!original.user.emailVerified && (
                    <VerifyEmployeeEmailButton employee={original} />
                  )}
                  {!original.user.archived && (
                    <ArchiveUserButton
                      id={original.user.id}
                      disabled={archiveButtonDisabled(original)}
                    />
                  )}
                  <DeleteUserButton
                    id={original.user.id}
                    disabled={archiveButtonDisabled(original)}
                  />
                </Flex>
              ),
              id: 'actions',
              disableSortBy: true,
            },
          ]}
          loading={page == null}
          data={page ?? []}
        />
        <Flex direction="row" justify="center">
          <Pagination {...getPaginationProps({ totalData: ordered?.length })} />
        </Flex>
      </Flex>
    </CardFoldable>
  )
}
