/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import FileDisplay from './FileDisplay'
import Icon from './Icon'

interface DropzoneProps {
  file?: FileList | null
  onChange?: (acceptedFiles: FileList | null) => void
  error?: boolean
  helperText?: string
  multiple?: boolean
}

const UploadZone: React.FC<DropzoneProps> = ({
  onChange,
  error = false,
  multiple = false,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length) {
        if (onChange) {
          onChange(acceptedFiles)
        }
      }
    },
    [onChange],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div
      css={{
        width: '100%',
        height: 156,
        border: `2px dashed ${
          !error ? theme.colors.gray3 : theme.colors.error
        }`,
        borderRadius: 22,
        outline: 'none',
        backgroundColor: theme.colors.gray6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} multiple={multiple} />
      <Icon type="uploadDoc" size="large" color={theme.colors.primary} />
      {isDragActive ? (
        <p
          css={{
            lineHeight: '2.8rem',
            fontSize: '1.4rem',
            fontWeight: 'bold',
            color: theme.colors.gray1,
          }}
        >
          {t('forms.label.dropzoneSlide')}
        </p>
      ) : (
        <p
          css={{
            lineHeight: '2.8rem',
            fontSize: '1.4rem',
            fontWeight: 'bold',
            color: theme.colors.primary,
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {t('forms.label.dropzoneFind')}
        </p>
      )}
    </div>
  )
}

const Dropzone: React.FC<DropzoneProps> = ({
  file,
  onChange,
  error = false,
  helperText = '',
  multiple = false,
}) => {
  const theme = useTheme()

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      {file && Object.keys(file).length ? (
        Object.keys(file).map(key => (
          <div key={key} css={{ marginBottom: '15px' }}>
            <FileDisplay
              onDelete={() => {
                if (onChange) onChange(null)
              }}
              filename={file[parseInt(key)].name}
              size={file[parseInt(key)].size}
              error={error}
            />
          </div>
        ))
      ) : (
        <UploadZone
          onChange={onChange}
          file={file}
          error={error}
          multiple={multiple}
        />
      )}
      <div
        css={{
          height: 20,
        }}
      >
        {helperText && (
          <span
            css={{
              fontFamily: 'Roboto',
              fontSize: '1.2rem',
              color: error ? theme.colors.error : theme.colors.gray3,
            }}
          >
            {helperText}
          </span>
        )}
      </div>
    </div>
  )
}

export default Dropzone
