import { useEffect, useState } from 'react'

const useTimeout = (ms = 0): boolean => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true)
    }, ms)

    return (): void => {
      clearTimeout(timer)
    }
  }, [ms])

  return ready
}

export default useTimeout
