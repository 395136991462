import {
  BikeConditionType,
  CompanyMonitoringStatusType,
  CompanyMonitoringType,
  BikeComponentPartType,
  PackageAccessoryType,
  ParkingType,
  TravelModeType,
  StateOfUseType,
  RejectionReasonType,
  EmployeeBikeHabit,
  UserRoles,
  CompanyStepType,
  SpecialBikesType,
} from '@goodwatt/shared/dist/types'

import { FleetStockLevel } from '../../../components/Table/CustomCells/FleetStockConditionCell'
import {
  AppointmentCategory,
  BabySeatOptions,
  CompanyType,
  DeploymentType,
  EmployeeDeploymentStatus,
} from '../../../__generated__/graphql'
import { TotalDistanceInterval } from '../../../pages/Animator/Deployment/const/TotalDistanceInterval'
import { LastRideTime } from '../../../pages/Animator/Deployment/const/LastRideTime'

export default {
  translation: {
    forms: {
      success: {
        releaseDepositSuccess: 'Caution levée avec succès',
        releaseDepositsSuccess: 'Cautions levées avec succès',
      },
      label: {
        country: 'Pays',
        category: 'Catégorie',
        order: 'Ordre d’affichage',
        titleAdvice: 'Titre du conseil',
        contentAdvice: 'Texte du conseil',
        question: 'Question',
        area: 'Zone d’affectation',
        answer: 'Réponse',
        absent: 'Absent',
        searchTag: 'Tapez un mot clé',
        password: 'Mot de passe',
        confirmPassword: 'Confirmez le mot de passe',
        oldPassword: 'Mot de passe actuel',
        newPassword: 'Nouveau mot de passe',
        confirmNewPassword: 'Confirmez le nouveau mot de passe',
        subscriptionCode: 'Code organisation',
        subscriptionCodePlaceholder: 'ex: 12345',
        email: 'Email',
        firstName: 'Prénom',
        lastName: 'Nom',
        phoneNumber: 'Téléphone portable',
        civility: 'Civilité',
        emailReferrer: 'Email du référent',
        firstNameReferrer: 'Prénom du référent',
        lastNameReferrer: 'Nom du référent',
        phoneNumberReferrer: 'Numéro de téléphone portable du référent',
        companyPhone: 'Téléphone professionnel',
        companyName: 'Nom de l’organisation',
        companyType: 'Type d’organisation',
        siret: 'Numéro SIRET',
        siretPlaceholder: 'Code SIRET à 14 chiffres',
        companyAddress: 'Adresse du site',
        companySiteName: 'Nom du site',
        street: 'Rue',
        address: 'Adresse',
        postalCode: 'Code postal',
        city: 'Ville',
        nEmployees: 'Nombre de collaborateurs sur le site',
        nEmployeesPlaceholder: 'ex: 10',
        birthdate: 'Date de naissance',
        placeholderBirthdate: 'jj/mm/aaaa',
        cityOfBirth: 'Ville de naissance',
        height: 'Taille (en cm)',
        heightDescription:
          'Cette information nous permettra de vous attribuer un vélo adapté à votre taille',
        heightPlaceholder: 'ex : 165cm',
        showCGU: 'Voir les conditions générales utilisation',
        cgu: 'J’ai lu et j’accepte les conditions générales du programme GoodWatt',
        hour: 'Heure',
        bikeModel: 'Modèle du vélo',
        fleet: "Flotte d'affectation",
        model: 'Modèle',
        bikeCondition: 'État du vélo',
        resolvedDate: 'Fin de réparation',
        note: 'Note',
        noteStatus: 'État de la note',
        leaveANote: 'Rédiger une note',
        entryDate: 'Date de la saisie',
        dueDate: "Date de l'échéance",
        dropzoneSlide: 'Glissez et déposez votre fichier ici',
        dropzoneFind: 'Cherchez dans les dossiers',
        freePlaceholder: 'Saisie libre',
        title: 'Titre',
        content: 'Contenu',
        tag: 'Tag',
        MIME: 'Type du contenu',
        leaveAcontent: 'Rédiger un contenu',
        siteName: 'Nom du site',
        selectSite: 'Sélectionnez un site',
      },
      errors: {
        cautionReason:
          'Veuillez remplir un motif pour le prélèvement de la caution',
        anErrorOccured: 'Une erreur est survenue',
        bikeLoanAttachmentNotFound: 'Pièce jointe introuvable',
        bikeNotAvailable: "Le vélo n'est pas disponible",
        minAppointment: 'Doit être supérieure a 0',
        contentNotFound: 'Le contenu est introuvable',
        deploymentNameTaken: 'Ce nom de déploiement est déjà pris',
        emailNotVerified: 'Email non vérifié',
        emailRegex: 'Adresse email non valide',
        emailTaken: 'Cet email est déjà utilisé',
        imageNotSupported: "Format d'image non supporté",
        fileFormat: "Le fichier importé n'est pas supporté",
        fileNumber: 'Le nombre maximum de fichiers importés est de deux',
        fileSize: 'La taille du fichier doit être inférieure à 5 Mb',
        nonValidDistance: 'Entrez une distance valide',
        nonValidDuration: 'Entrez une durée valide',
        passwordRegex:
          'Au minimum 6 caractères, un chiffre, une minuscule et une majuscule',
        passwordRegexNotif:
          'Pour garantir la sécurité, votre mot de passe doit contenir, au minimum 6 caractères, un chiffre, une minuscule et une majuscule',
        passwordsNotIdentical: 'Les mots de passe ne sont pas identiques',
        passwordMaybeNotUsed:
          'Si un utilisateur est déjà enregistré avec cet email et possède un mot de passe celui-ci ne sera pas utilisé !',
        phoneFormat: "Le numéro de téléphone n'est pas valide",
        postalCodeFormat: 'Le code postal doit contenir 5 chiffres',
        required: 'Ce champ est requis',
        signinFailed:
          "L'email n'existe pas ou la combinaison email/password n'est pas la bonne",
        siretErrorLetters: 'Le code SIRET ne peut pas contenir de lettres',
        siretRegex: 'Le code SIRET doit avoir 14 chiffres',
        siretTaken: 'Le code SIRET est déjà enregistré',
        subscriptionCodeNotFound:
          'Le code organisation est introuvable veuillez demander à votre organisation le bon code',
        userNotFound: "Nous n'avons pas pu identifier cet utilisateur",
        wrongFormat: 'Mauvais format',
        birthdateTooRecent: 'La date de naissance doit être avant 2010',
        birthdateTooOld: 'La date de naissance doit être après 1930',
        zipCodeNotFound:
          "Nous n'avons pas trouvé de correspondance à votre code postal ou nous ne gérons pas pour le moment ce territoire, veuillez nous contacter sur contact@goodwatt.fr pour avoir plus d'informations",
        resolvedDatePassed:
          'La date de résolution de la réparation doit être dans le futur',
        negativePrice: 'Entrez un prix positif ou nul',
        wrongBikeFleetAssociation:
          'Ce vélo ne fait pas partie de la flotte de cette organisation',
        addressPositionNotFound:
          "Impossible de récupérer les distances entre l'usager et l'organisation, veuillez vérifier les adresses renseignées",
        chooseAnOption: 'Veuillez choisir une option',
        companyHasNoSite: 'Cette organisation n’a pas de site',
      },
      button: {
        archive: 'Archiver',
        dearchive: 'Désarchiver',
        accept: 'Accepter',
        next: 'Suivant',
        update: 'Mettre à jour',
        backToWebsite: 'Retour au site',
        editPassword: 'Changer de mot de passe',
        close: 'Fermer',
        confirm: 'Confirmer',
        delete: 'Supprimer',
        cancel: 'Annuler',
        valid: 'Valider',
        signOnApp: "Signer sur l'App",
        save: 'Enregister',
        add: 'Ajouter',
        reload: 'Actualiser',
        return: 'Retour',
        googleMaps: 'Voir sur google maps',
        quit: 'Quitter',
      },
    },
    auth: {
      animatorGuard: {
        title: "Reconnexion de l'animateur",
        button: "Je m'identifie",
      },
      signin: {
        metaTitle: 'GoodWatt - Je me connecte',
        title: 'Connexion à mon espace personnel',
        button: 'Je me connecte',
        forgottenPassword: 'Mot de passe oublié ?',
        notSubscribed: "Vous n'êtes pas encore inscrit ?",
      },
      forgotPassword: {
        desc: 'Veuillez renseigner l’adresse email utilisée pour vous connecter au compte GoodWatt. Un message vous sera envoyé pour réinitialiser votre mot de passe.',
        metaTitle: 'GoodWatt - Mot de passe oublié ?',
        popupTitle: 'Mot de passe oublié ?',
        buttonTitle: 'Envoyer le mail',
        successNotification: 'Un email de vérification vous a été envoyé',
        redirect: 'Retour à la page de connexion',
      },
      resetPassword: {
        metaTitle: 'GoodWatt - Reinitialiser le mot de passe',
        popupTitle: 'Reinitialiser le mot de passe',
        button: 'Reinitialiser mon mot de passe',
        successNotification: 'Le mot de passe a bien été reinitialisé',
      },
      setPasswordFirstConnection: {
        popupTitle: 'Veuillez créer votre mot de passe',
        button: 'Créer mon mot de passe',
        successNotification: 'Le mot de passe a bien été créé',
      },
      signupCompany: {
        skipStep4: 'Je renseignerai ceci plus tard',
        metaTitle: 'GoodWatt - Inscription organisation',
        step1Title: 'J’inscris mon organisation au programme',
        step2Title: 'Je crée mon compte référent',
        step3Title: 'Création d’un site pour votre organisation',
        step3TitleEdit: 'Modification d’un site',
        step4Title: 'Je renseigne les facilités offertes par mon site',
        stepSuccessTitle:
          'Bravo, votre site est créé.<br />Vous y êtes presque !',
        description:
          'Le programme GoodWatt est proposé aux organisations publiques et privées. Pour en profiter, veuillez créer un compte pour l’organisation concernée.',
        bikeFormDesc:
          'Quelles solutions de stationnement vélo sont proposées ?',
        bikeInside: 'Places intérieures sécurisées',
        bikeOutside: 'Places extérieures sécurisées',
        bikeUnsafe: 'Places extérieures non sécurisées',
        carFormDesc: 'Quelles solutions sont proposées pour les voitures ?',
        carPlaces: 'Places de parking réservées aux usagers',
        carElectricStations: 'Bornes de recharge de voitures électriques',
        successDescription:
          'Afin de confirmer votre adresse email, un message de confirmation vous a été transmis. Suivez les instructions indiquées pour accéder à votre nouveau compte !<br /><br />Après cette validation, votre animateur territorial vous contactera par mail ou téléphone.',
      },
      signupEmployee: {
        metaTitle: 'GoodWatt - Inscription usager',
        stepTitle: "Je m'inscris à GoodWatt",
        step2Title: "Je m'inscris au programme",
        stepSiteTitle: 'Choisissez votre site',
        step3Title: 'Je choisis mon mot de passe',
        stepSuccessTitle: 'Bravo !<br />Votre compte est créé.',
        description:
          'GoodWatt est ouvert aux collaborateurs des organisations préalablement inscrites. Veuillez saisir le <strong>code organisation</strong> qui vous a été transmis.',
        button1: "Je m'inscris",
        isCompany: 'Vous êtes une organisation ? ',
        signUpHere: 'Inscrivez-vous ici',
        missingCode:
          'Vous n’avez pas de code ? Proposez à votre organisation de participer !',
        successDescription: `Afin de valider votre adresse email, un message de confirmation vous a été transmis. Suivez les instructions indiquées pour accéder à votre nouveau compte !`,
      },
      verifyEmail: {
        metaTitle: "GoodWatt - Verification de l'email",
        successTitle: 'Votre adresse email est confirmée !',
        successDescription:
          'Vous pouvez dès maintenant vous connecter à votre compte avec vos identifiants',
        button: 'Se connecter',
        errorTitle: 'Impossible de vérifier votre adresse email',
        errorDescription:
          "Veuillez verifier l'email de vérification dans votre boite mail",
      },
    },
    shared: {
      errors: {
        bikeNotFound: 'Vélo non trouvé',
        companyNotFound: 'Organisation non trouvée',
        unknown: 'Inconnu',
        requiredOption: 'Vous devez selectionner une option',
        referrerAlreadyExists: 'Cet utilisateur est déjà référent',
        BadCompanyForbiddenError:
          'Vous n’êtes pas authorisé à modifier à cet utilisateur',
      },
      none: 'Aucun',
      complete: 'Complet',
      incomplete: 'Incomplet',
      deploymentTypes: {
        [DeploymentType.Discovery]: 'Découverte',
        [DeploymentType.Rental]: 'Location',
      },
      bikeParkingLabel: {
        [ParkingType.NONE]: 'Non, je dois stationner mon vélo dans la rue',
        [ParkingType.OUTSIDE_WITH_KEY]:
          'Oui, dans une cour ou un jardin fermant à clé',
        [ParkingType.INSIDE_WITH_KEY]:
          'Oui, dans un espace intérieur fermé à clé (parking, cave, garage à vélo, ...)',
      },
      babySeatOptions: {
        [BabySeatOptions.Needed]: 'Si possible',
        [BabySeatOptions.NotNeeded]: 'Non nécessaire',
        [BabySeatOptions.AbsolutelyNeeded]: 'Indispensable',
      },
      parkingType: {
        [ParkingType.NONE]: 'Extérieur',
        [ParkingType.INSIDE_WITH_KEY]: 'Intérieur à clé',
        [ParkingType.OUTSIDE_WITH_KEY]: 'Extérieur à clé',
      },
      employeeBikeHabitLabel: {
        [EmployeeBikeHabit.REGULARLY]: 'Régulièrement',
        [EmployeeBikeHabit.TIME_TO_TIME]: 'De temps en temps',
        [EmployeeBikeHabit.RARELY]: 'Très rarement',
        [EmployeeBikeHabit.NEVER]: 'Jamais',
      },
      companyTypeLabel: {
        [CompanyType.Company]: 'Entreprise',
        [CompanyType.Colectivity]: 'Collectivité',
        [CompanyType.PublicAdministration]: 'Administration publique',
        [CompanyType.Healthcare]: 'Etablissement de santé',
        [CompanyType.Association]: 'Association',
        [CompanyType.ClubCompany]: 'Club d’entreprise',
        [CompanyType.Other]: 'Autre',
        [CompanyType.None]: 'Non renseigné',
      },
      deploymentTypeLabel: {
        [DeploymentType.Discovery]: 'Découverte',
        [DeploymentType.Rental]: 'Location',
      },
      deploymentTypeDesc: {
        [DeploymentType.Discovery]:
          'Expérimentation courte durée de vélo à assistance électrique pour découvrir les bienfaits du vélo au quotidien et changer ses habitudes.',
        [DeploymentType.Rental]:
          'Location moyenne et longue durée de vélo à assistance électrique pour adopter durablement le vélo dans ses déplacements du quotidien.',
      },
      travelModeLabel: {
        [TravelModeType.NONE]: 'Aucun (télétravail)',
        [TravelModeType.BIKE]: 'Vélo',
        [TravelModeType.WALKING]: 'Marche à pied',
        [TravelModeType.PUBLIC_TRANSPORTATION]: 'Transport en commun',
        [TravelModeType.CARPOOLING]: 'Covoiturage',
        [TravelModeType.MOTORISED_TWO_WHEELS]: 'Deux roues motorisées',
        [TravelModeType.CAR]: 'Voiture',
        [TravelModeType.ELECTRIC_BIKE]: 'Vélo électrique',
      },
      stateOfUseLabel: {
        [StateOfUseType.OUT_OF_USE]: "Hors d'usage",
        [StateOfUseType.USED]: 'Usagé',
        [StateOfUseType.GOOD_CONDITION]: 'Bon état',
        [StateOfUseType.EXCELLENT]: 'Excellent',
        [StateOfUseType.MISSED]: 'Non restitué',
      },
      programStepLabel: {
        refusedCompany: 'Candidature non retenue',
        pendingCompany: 'Candidature au dispositif',
        contractualization: 'Contractualisation',
        employeeLoansSelection: 'Candidature des usagers',
        preparingLoan: 'Préparation du mois de prêt',
        loanBikesInProgress: 'Mois de prêt des vélos',
        programReview: 'Bilan du dispositif',
      },
      programStepDescription: {
        refusedCompany:
          'La candidature de votre organisation au dispositif GoodWatt n’a pas été retenue. Le programme O’vélO! est vaste, il comprend d’autres solutions ! Il en existe peut-être une plus adaptée à votre organisation. Vous allez être contacté par un membre de notre équipe prochainement.',
        pendingCompany:
          'Votre organisation a candidaté au dispositif GoodWatt. Notre équipe va prendre contact avec vous pour évaluer la faisabilité du déploiement de GoodWatt au sein de votre organisation.',
        contractualization:
          'Félicitations, votre candidature a été retenue ! Veuillez compléter votre profil afin de signer la convention. Notre équipe va vous contacter pour choisir avec vous des dates pour la présentation de GoodWatt dans votre organisation et le mois de prêt des vélos.',
        employeeLoansSelection:
          'Vos usagers peuvent actuellement candidater pour participer au mois de prêt.',
        preparingLoan:
          'Certains de vos collaborateurs ont été sélectionnés pour participer au mois de prêt des vélos à assistance électrique. Ils finalisent actuellement leurs inscriptions au dispositif.',
        loanBikesInProgress:
          'Certains de vos collaborateurs participent actuellement au mois de prêt des vélos électriques pour leur déplacement domicile-travail. Vous pouvez suivre leurs progrès via votre espace. Pensez à les encourager !',
        programReview:
          'Toutes nos félicitations pour votre participation au dispositif GoodWatt ! Vous pouvez à présent consulter sur votre bilan du mois de prêt des vélos et un diagnostic personnalisé de la mobilité de vos collaborateurs.',
      },
      bikeConditionLabel: {
        [BikeConditionType.ON_LOAN]: 'En prêt',
        [BikeConditionType.REPARATION]: 'En réparation',
        [BikeConditionType.STOCK]: 'En stock',
        [BikeConditionType.OUT_OF_SERVICE]: 'Hors service',
        [BikeConditionType.STOLEN]: 'Volé',
        [BikeConditionType.DEMONSTRATION]: 'En démonstration',
        [BikeConditionType.SOLD]: 'Vendu',
      },
      bikeComponentPartLabel: {
        [BikeComponentPartType.BIKE_FRAME]: 'Cadre et fourche',
        [BikeComponentPartType.BRAKES]: 'Freins',
        [BikeComponentPartType.BACK_WHEEL]: 'Roue arrière',
        [BikeComponentPartType.FRONT_WHEEL]: 'Roue avant',
        [BikeComponentPartType.TRANSMISSIONS]: 'Moteur',
        [BikeComponentPartType.HANDLEBAR]: 'Guidon',
        [BikeComponentPartType.DEVICES]: 'Transmission',
        [BikeComponentPartType.BATTERY]: 'Batterie et display',
        [BikeComponentPartType.GLOBAL]: 'Avis global',
      },
      loanPackageAccessoryLabel: {
        [PackageAccessoryType.LOCK]: 'Antivol',
        [PackageAccessoryType.BATTERY_CHARGER]: 'Chargeur de batterie',
        [PackageAccessoryType.SATCHELS]: 'Sacoches',
        [PackageAccessoryType.SMARTPHONE_HOLDER]: 'Support smartphone',
        [PackageAccessoryType.RAIN_ACCESSORIES]: 'Accessoires de pluie',
        [PackageAccessoryType.HELMET]: 'Casque',
        [PackageAccessoryType.KIDS_HELMET]: 'Casque enfant',
        [PackageAccessoryType.BABY_SEAT]: 'Siège enfant',
        [PackageAccessoryType.FRONT_RACK]: 'Panier avant',
      },

      specialBikes: {
        [SpecialBikesType.URBAN_BIKE]: 'Un vélo électrique urbain',
        [SpecialBikesType.FOLDING_BIKE]: 'Un vélo électrique pliant',
        [SpecialBikesType.CARGO_BIKE]:
          'Un vélo électrique familial (cargo longtail)',

        short: {
          [SpecialBikesType.URBAN_BIKE]: 'Vélo électrique urbain',
          [SpecialBikesType.FOLDING_BIKE]: 'Vélo électrique pliant',
          [SpecialBikesType.CARGO_BIKE]: 'Vélo électrique familial',
        },
        abbreviation: {
          [SpecialBikesType.URBAN_BIKE]: 'Urbain',
          [SpecialBikesType.FOLDING_BIKE]: 'Pliant',
          [SpecialBikesType.CARGO_BIKE]: 'Familial',
        },
        description: {
          [SpecialBikesType.URBAN_BIKE]:
            'le vélo confortable et pratique pour tous les déplacements du quotidien.',
          [SpecialBikesType.FOLDING_BIKE]:
            'le vélo facile à transporter et à stationner pour les besoins d’intermodalité.',
          [SpecialBikesType.CARGO_BIKE]:
            'le vélo robuste et multiusage pour se déplacer à plusieurs.',
        },
      },

      babySeat: {
        [BabySeatOptions.Needed]:
          'Oui si possible, mais je participerai au test même si mon vélo n’en est pas équipé',
        [BabySeatOptions.NotNeeded]: 'Non, je n’en ai pas besoin',
        [BabySeatOptions.AbsolutelyNeeded]:
          'Oui et c’est indispensable pour mon usage pendant le test',
        short: {
          [BabySeatOptions.Needed]: 'Si possible',
          [BabySeatOptions.NotNeeded]: 'Non nécessaire',
          [BabySeatOptions.AbsolutelyNeeded]: 'Indispensable',
        },
        label: 'Siège enfant',
        unavailable: 'non disponible',
      },
      notification: {
        successEmployeeDelete: 'L’usager a bien été supprimé',
        successUpdate: 'Les changements ont bien été pris en compte',
        profileSuccessUpdate: 'Le profil a bien été modifié',
        avatarSuccessUpdate: "L'avatar a bien été modifié",
        pictureSuccessUpdate: "L'image a bien été modifié",
        passwordSuccessUpdate: 'Le mot de passe a bien été modifié',
        willUpdateOnEnding:
          'Les changements de la note seront définitivement enregistrés à la dernière étape',
        siteSuccessCreate: 'Le site a bien été créé',
        siteSuccessUpdate: 'Le site a bien été modifié',
        referrerCreated: 'Le référent a bien été créé',
        transfertSiteToCompany:
          'Le site a bien été transféré à une autre organisation',
        transferDeploymentToSite:
          'Le déploiement a bien été transféré à un autre site',
      },
      noData: 'Pas de résultat',
      header: {
        animator: 'Animateur',
        company: 'Référent organisation',
        employee: 'Usager',
        admin: 'Administrateur',
      },
      auth: {
        logout: 'Déconnexion',
        welcome: 'Bienvenue',
        sessionExpired: 'Votre session a expiré',
      },
      button: {
        sendEmail: 'Envoyer un mail',
        cancel: 'Annuler',
        confirm: 'Confirmer',
        createDeployment: 'Créer un déploiement',
        addFleet: 'Ajouter une flotte',
        addBike: 'Ajouter un vélo',
        addBikeModel: 'Ajouter un modèle de vélo',
        addReferrer: 'Ajouter un référent',
        addSite: 'Ajouter un site',
        debugGenerateRemissionOrder: 'Générer le bon de remise',
      },
      resources: {
        bike: 'Vélo',
        accessory: 'Accessoire',
        company: 'organisation',
        company_plural: 'organisations',
        companyCount: '{{count}} organisation',
        companyCount_plural: '{{count}} organisations',
        employee: 'usager',
        employee_plural: 'usagers',
        employeeCount: '{{count}} usager',
        employeeCount_plural: '{{count}} usagers',
        bikeCount: '{{count}} vélo',
        bikeCount_plural: '{{count}} vélos',
        bikeComponent: 'composant',
        beneficiary: 'bénéficiaire',
        beneficiary_plural: 'bénéficiaires',
        candidate: 'candidat',
        candidate_plural: 'candidats',
      },
      label: {
        moreDetails: 'Plus de détails',
        state: 'État',
      },
      placeholder: {
        inputSelect: 'Choisissez dans la liste',
        optionalComment: 'Commentaire facultatif',
        userEntry: 'Saisie utilisateur',
        noOptions: 'Aucune option disponible',
      },
      documents: {
        signedConvention: 'Convention signée',
        uploadSignedConvention: 'Charger la convention signée',
        conventionFilename: "Convention de l'organisation",
        generateConvention: "Générer la convention pour l'organisation",
        fileSize: 'Taille :',
        fileDate: 'Date :',
        addDocument: 'Ajouter un document à la plateforme partagée',
        front: 'Recto',
        back: 'Verso',
        docModal: {
          title: 'Ajout de document',
          filenameLabel: 'Nom du fichier',
          accessibility: 'Accessibilité',
          employee: 'Choix de l’usager',
          option1: 'Animateur',
          option2: 'Référent',
          option3: 'Référent et usagers',
          option4: 'Animateur et usagers',
          option5: 'Usagers',
        },
        updateDocModal: {
          title: 'Édition de document',
        },
        notifications: {
          docUpdated: 'Le document a bien été modifié',
          docAdded: "Le document '{{filename}}' a bien été ajouté",
          docDeleted: 'Le document a bien été supprimé',
        },
      },
      documentCard: {
        size: 'Taille : ',
        date: 'Date : ',
        uploadBy: 'Déposé par : ',
        visibility: 'Visibilité : ',
        uploadByAnimator: 'Animateur',
        uploadByCompany: 'Référent',
      },
      profile: {
        title: 'Mon profil',
        myInfo: 'Mes informations personnelles',
        myAvatar: 'Mon avatar',
        editPassword: 'Modifier mon mot de passe',
        editSite: 'Modifier mon site',
        whereToFindMe: 'Où me trouver ?',
        civility: {
          NONE: '',
          MISTER: 'Monsieur',
          MISTRESS: 'Madame',
        },
      },
      contact: {
        cardTitle: {
          [UserRoles.COMPANY]: 'Contacter mon référent organisation',
          [UserRoles.ANIMATOR]: 'Contacter mon animateur GoodWatt',
        },
        faq: 'Foire aux questions',
      },
      faq: {
        contactMyAnimator: 'Contacter mon animateur GoodWatt',
      },
      hello: 'Bonjour {{name}},',
      yes: 'Oui',
      no: 'Non',
      at: 'à',
      termsOfContract:
        'Je confirme que les informations renseignées sont exactes et permettent le respect des termes du contrat',
      edit: 'Modifier',
      delete: 'Supprimer',
      monthlyRent: 'Loyer mensuel (€ TTC/mois)',
    },

    admin: {
      animator: {
        table: {
          headers: {
            name: 'Nom',
            zone: "Zone d'affectation",
            email: 'Adresse mail',
            actions: 'Actions',
          },
        },
        modal: {
          deleteTitle: 'Suppression de l’animateur',
          deleteTextLine:
            'Confirmez-vous la suppression du compte de l’animateur <strong>{{name}}</strong> ?',
        },
        notification: {
          successUpdate: 'Animateur mis à jour !',
          successCreate: 'Animateur créé !',
          successDelete: 'Animateur supprimé !',
          error: "Imposible de mettre a jour l'animateur",
        },
        animatorTitleLabel: 'Gestion des animateurs',
      },
      area: {
        button: {
          update: 'Enregistrer',
          create: 'Ajouter',
          delete: 'Supprimer',
        },
        modal: {
          createTitle: 'Ajout d’une zone',
          updateTitle: 'Edition d’une zone',
          deleteTitle: 'Suppression de la zone',
          deleteTextline1: 'Confirmez-vous la suppression de la zone',
          deleteTextline2:
            'Vous devez transférer les départements, animateurs et organisations qui lui sont affectés à une autre zone',
          inputName: 'Nom de la zone',
        },
        notification: {
          successUpdate: 'Zone mis à jour !',
          successCreate: 'Zone crée !',
          successDelete: 'Zone supprimer !',
          error: 'Imposible de mettre a jour la zone',
        },
        table: {
          headers: {
            company: 'Nombre d’organisations par phase',
            employee: 'Nombre d’usagers',
            name: 'Nom de la zone',
            territoryCount: 'Dep.',
            companyStep1Count: '1',
            companyStep2Count: '2',
            companyStep3Count: '3',
            companyStep4Count: '4',
            companyStep5Count: '5',
            employeeSelectedCount: 'Inscrits',
            employeeBikeCount: 'Prêts',
            bikeCount: 'Vélos',
          },
        },
        areaTitleLabel: 'Gestion des zones',
      },
      territory: {
        territoryTitleLabel: 'Affectation des départements',
      },

      advice: {
        tagTitleLabel: 'Gestion des catégories',

        modal: {
          deleteAdviceTag: 'Suppression de la catégorie',
          updateAdviceTag: 'Edition d’une catégorie de conseils',
          createAdviceTag: 'Ajout d’une catégorie de conseils',

          deleteTextLine1:
            'Confirmez-vous la suppression de la catégorie “ <strong>{{tagName}}</strong> “ ?',
          deleteTextLine2:
            'Des conseils sont encore attribués à cette catégorie, vous devez transférer ces conseils à une autre catégorie',
        },

        tagForm: {
          label: 'Libellé',
          order: 'Ordre d’affichage',
        },

        taglist: {
          headers: {
            label: 'Libellé',
            order: 'Ordre d’affichage',
            questionCount: 'Nombre de conseils',
            action: 'Actions',
          },
        },
      },

      post: {
        tagTitleLabel: 'Gestion des catégories',

        modal: {
          deletePostTag: 'Suppression de la catégorie',

          deleteTextLine1:
            'Confirmez-vous la suppression de la catégorie “ <strong>{{tagName}}</strong> “ ?',
          deleteTextLine2:
            'Des questions sont encore attribuées à cette catégorie, vous devez transférer ces questions à une autre catégorie',

          createPostTag: 'Ajout d’une catégorie de questions',
          updatePostTag: 'Edition d’une catégorie de questions',
        },

        tagForm: {
          label: 'Libellé',
          order: 'Ordre d’affichage',
        },

        taglist: {
          headers: {
            label: 'Libellé',
            order: 'Ordre d’affichage',
            questionCount: 'Nombre de questions',
            action: 'Actions',
          },
        },
      },
    },

    animator: {
      menu: {
        exports: 'Communauté',
        home: 'Accueil',
        profile: 'Mon profil',
        adminBike: 'Les vélos',
        bike: 'Mes vélos',
        faq: 'FAQ',
        advices: 'Conseils',
        quizzes: 'Quiz',
        application: 'Application',
        animators: 'Animateurs',
        company: {
          adminTitle: 'Les organisations',
          title: 'Mes organisations',
          subMenu: {
            progress: 'Avancement',
            profile: 'Profil',
            employee: 'Usagers',
            monitoring: 'Suivi',
            document: 'Documents',
          },
        },
        deployments: {
          adminTitle: 'Les déploiements',
          title: 'Mes déploiements',
          subMenu: {
            progress: 'Avancement',
            monitoring: 'Suivi',
            users: 'Usagers',
          },
        },
      },
      dashboard: {
        filter: {
          company: 'Organisation',
          deployment_type: 'Offre',
          program_step: 'Phase',

          fleets: 'Flottes',

          options: {
            all_companies: 'Toutes les organisations',
            all_deployment_types: 'Toutes',
            deployment_types: {
              [DeploymentType.Discovery]: 'Découverte',
              [DeploymentType.Rental]: 'Location',
            },

            all_program_steps: 'Toutes les phases',
          },
        },

        deploymentsInProgressTitle: 'Mes déploiements en cours',
        archivedDeployments: 'Déploiements archivés',

        adminActions: {
          title: 'Actions admin:',

          transferSiteToCompany: {
            button: 'Déplacer site',
            labels: {
              site: 'Site :',
              fromCompany: 'Depuis l’organisation :',
              toCompany: 'Vers l’organisation :',
              company: 'Organisation :',
              deployment: 'Déploiement :',
              toSite: 'Vers le site :',
            },
            modal: {
              title: 'Transférer un site vers une autre organisation',
              text: 'Cette opération transfère le site sélectionné vers une nouvelle organisation. Elle transfère tous les déploiements associés au site vers cette organisation, ainsi que tous ses usagers.',
            },
          },

          transferDeploymentToSite: {
            button: 'Déplacer déploiement',

            modal: {
              title: 'Transférer un déploiement vers un autre site',
              text: 'Cette opération transfère un déploiement existant vers un nouveau site de la même organisation.',
            },
          },
        },

        tooltips: {
          view: 'Voir',
          edit: 'Modifier',
          archive: 'Archiver',
          archived: 'Archivé',
          unarchive: 'Désarchiver',
          delete: 'Supprimer',
          download: 'Télécharger',
        },

        myCompaniesCard: {
          title: 'Mes organisations',
          inActivity: 'en phase 1 à 5',
          pending: 'en attente',
          employeesSub: 'inscrits',
          employeesSubWithLoan: 'bénéficiaires',
        },
        summary: {
          bikes: 'Vélos',
          companies: 'Organisations',
          employees: 'Usagers',
          waiting: 'en attente',
          validated: 'validées',
          registered: 'inscrits',
          onLoan: 'bénéficiaires',
        },
        myBikesCard: {
          title: 'Mes vélos',
          onLoan: 'en cours de prêt',
          available: 'disponibles',
          inRepair: 'en réparation',
          outOfService: 'hors service',
        },
        nextSteps: {
          title: 'Mes prochaines étapes',
          companyName: 'Organisation',
          eventName: 'Étape',
          date: 'Date',
          animatorName: 'Animateur',
          indicator: 'Indicateurs',
          participantsCount: {
            loanEvent: '{{count}} usager sélectionné',
            loanEvent_plural: '{{count}} usagers sélectionnés',
            loanReturnEvent: '{{count}} vélo prêté',
            loanReturnEvent_plural: '{{count}} vélos prêtés',
          },
        },
      },
      companies: {
        inActivityTitleLabel: 'Organisations participant au programme',
        ArchivedTitleLabel: 'Organisations archivées',
        pendingTitleLabel: 'Organisations en attente de validation',
        refusedTitleLabel: 'Organisations refusées',
        companyInfoModalTitle: "Informations à propos de l'organisation",
        acceptCompany: {
          title: "Acceptation de l'organisation",
          warning:
            'Vous êtes sur le point d’accepter l’inscription d’une organisation au dispositif. Il est suggéré de valider l’inscription après un entretien préalable avec le référent de organisation, pour vérifier l’exactitude des informations.',
          choiceBikeFleet:
            'Veuillez choisir <strong>la flotte de vélos</strong> à laquelle il sera rattaché',
          eligibleToSpecialBikes:
            'Cette organisation est-il éligible aux vélos spéciaux ?',
          yes: 'Oui',
          no: 'Non',
          confirmation:
            'Confirmez-vous l’inscription de <strong>{{companyName}}</strong> ({{companyCity}}) ?',
          successNotification:
            '{{companyName}} a bien été accepté au programme !',
          deployments: {
            name: 'Déploiement',
            plural: 'Déploiements',
            site: 'Site',
            fleet: 'Flotte',
            phase: 'Phase',
            employees: 'Effectif',
            candidates: 'Candidats',
            actions: 'Actions',
            inProgress: 'déploiement(s) en cours',
            sites: 'site(s)',
            all: 'déploiement(s)',
          },
        },
        refuseCompany: {
          title: 'Refus de l’organisation',
          warning:
            'Vous êtes sur le point de refuser l’inscription d’une organisation au dispositif. Ce refus enverra un mail automatique au référent explicitant la raison du refus.',
          mainReason:
            'Veuillez choisir <strong>le principal motif du refus</strong>',
          confirmation:
            'Confirmez-vous le refus de <strong>{{companyName}}</strong> ({{companyCity}}) ?',
          successNotification: '{{companyName}} a bien été refusé',
          options: {
            [RejectionReasonType.LOW_CYCLING_TERRITORY]:
              'Territoire peu cyclable',
            [RejectionReasonType.SMALL_STAFF]: 'Faible effectif',
            [RejectionReasonType.OTHER]: 'Autre',
          },
        },
        refusedCompanyInfo: {
          title: 'Informations sur le refus',
          fields: {
            rejectionDate: 'Date du refus',
            rejectionReason: 'Motif du refus',
            note: 'Commentaire',
          },
          noRejectionNote: "Aucune note n'a été attachée au refus",
        },
        deleteCompanyRefusal: {
          successNotification: '{{companyName}} a bien été supprimée.',
          title: "Suppression de l'organisation",
          description:
            "Confirmez vous la suppression du compte de l'organisation <strong>{{companyName}}</strong> ?",
        },
        archiveCompany: {
          successNotification: '{{companyName}} a bien été archivée.',
          title: "Archivage de l'organisation",
          description:
            'Confirmez vous la demande d’archivage de l’organisation <strong>{{companyName}}</strong> ? </br></br> Les données des usagers seront anonymisées et leurs statistiques aggrégées. Cette action peut être annulée dans un délai maximum de 7 jours.',
        },
        unArchiveCompany: {
          successNotification: '{{companyName}} a bien été désarchivée.',
          title: "Désarchivage de l'organisation",
          description:
            'Souhaitez vous annuler la demande d’archivage de organisation <strong>{{companyName}}</strong> ? </br></br> Cette annulation n’est possible que dans un délai de 7 jours à compter de la demande d’archivage, jusqu’au {{endDate}} inclus. Les données des usagers ne seront pas anonymisées.',
        },
        undoCompanyRefusal: {
          successNotification:
            '{{companyName}} a bien été replacé dans la liste des organisations en attente.',
          title: "C'est votre dernier mot ?",
          description:
            "Vous êtes sur le point d'annuler le refus de l'organisation <strong>{{companyName}}</strong>. Il apparaîtra à nouveau dans la liste des organisations en attente.",
        },
        filter: {
          models: 'Modèle',
          fleets: 'Flottes',
          fleet: 'Flotte',
          departments: 'Départements',
          steps: 'Phases',
          type: 'Type',
          staff: "Tranche d'effectif",
          department: 'Département',
          areas: 'Zones',
          options: {
            all: 'Tous',
            all_feminine: 'Toutes',
            ltHND: 'Moins de 100',
            gteHNDLt2HND: 'Entre 100 et 200',
            gte2HNDLt3HND: 'Entre 200 et 300',
            gte3HNDLt4HND: 'Entre 300 et 400',
            gte4HNDLt5HND: 'Entre 400 et 500',
            gte5HND: 'Plus de 500',
            type: {
              NONE: 'Aucun',
              COMPANY: 'Entreprise',
              COLECTIVITY: 'Collectivité',
              PUBLIC_ADMINISTRATION: 'Administration publique',
              HEALTHCARE: 'Etablissement de santé',
              ASSOCIATION: 'Association',
              CLUB_COMPANY: 'Club',
              OTHER: 'Autre',
            },
          },
        },
        table: {
          headers: {
            companyIndex: 'ID',
            companyCode: 'Code',
            companySiret: 'SIRET',
            companyName: 'Nom',
            companyMunicipality: 'Commune',
            companyFleet: 'Flotte',
            areaName: 'Zone',
            animator: 'Animateur',
            companyNbrEmployees: 'Effectif',
            endLoanDate: 'Fin du prêt',
            bikeDistance: 'Distance vélo',
            companyProgramStep: 'Phase',
            companyLoan: 'Prêt',
            actions: 'Actions',
            rejectionReason: 'Motif',
            profile: 'Profil',
            offer: 'Offre',
            type: 'Type',
          },
        },
        tooltip: {
          openCompanyFile: "Ouvrir le dossier de l'organisation",
          commentCompanyRefusal: 'Motif et commentaire du refus',
          cancelCompanyRefusal: "Annuler le refus de l'organisation",
          deleteCompanyRefusal: "Supprimer l'organisation",
          archiveCompany: "Archiver l'organisation",
          unArchiveCompany: "Désarchiver l'organisation",
          downloadCompanyArchive: 'Télécharger',
          acceptCompany: "Accepter l'organisation",
          refuseCompany: "Refuser l'organisation",
          companyInfo: "Profil de l'organisation",
        },
        profile: {
          employees: 'Effectif',
          privateSpots: 'Places privées',
          electricChargePoints: 'Bornes électriques',
          bikeParking: 'Parking vélos',
          bikeSpaceCapacityUnsafe: 'places vélos extérieures non sécurisées',
          bikeSpaceCapacityOutside: 'places vélos extérieures sécurisées',
          bikeSpaceCapacityInside: 'places vélos intérieures sécurisées',
          parking: 'Parking voitures',
          organisation: 'Organisation',
          sites: 'Sites',
          referrers: 'Référents',
          createReferrer: 'Créer un référent',
          createSite: 'Créer un site',
          deleteReferrer: 'Supprimer un référent',
          deleteReferrerNoReferrers:
            "Il n'est pas possible de supprimer ce référent car aucun autre référent n'existe sur l'organisation.",
          deleteReferrerConfirmationMessage:
            'Voulez-vous vraiment supprimer le référent {{firstName}} {{lastName}} ?',
          deleteSite: 'Supprimer un site',
          deleteSiteNoDeployments:
            'Êtes-vous sûr de vouloir supprimer le site {{name}} ?',
          deleteSiteSelectNewSite:
            'Sélectionner un nouveau site pour les déploiements du site à supprimer:',
          deleteSiteNoReplacementSites:
            "Il n'est pas possible de supprimer ce site car aucun autre site n'existe sur l'organisation.",
        },
        progress: {
          offerTypes: {
            [DeploymentType.Rental]: 'Location',
            [DeploymentType.Discovery]: 'Découverte',
            [DeploymentType.None]: 'Aucun',
          },
          edit: 'Modifier',
          table: {
            start: 'Début',
            end: 'Fin',
            name: 'Nom',
            phase: 'Phase',
            offer: 'Offre',
            actions: 'Actions',
          },
        },
        employees: {
          myUsers: 'Mes usagers',
          myArchivedUsers: 'Mes usagers archivés',
          site: 'Site',
          status: 'Statut',
          deployment: 'Déploiement',
          name: 'Nom',
          offer: 'Offre',
          actions: 'Actions',
          search: 'Rechercher un nom ou un déploiement',
          employeeStatus: {
            [EmployeeDeploymentStatus.Candidate]: 'Candidat',
            [EmployeeDeploymentStatus.Selected]: 'Sélectionné',
            [EmployeeDeploymentStatus.Beneficiary]: 'Bénéficiaire',
          },
          archiveUserModal: {
            title: "Archiver l'utilisateur",
            description:
              "L'utilisateur ne sera plus affiché dans la liste des utilisateurs actifs",
          },
          deleteUserModal: {
            title: "Supprimer l'utilisateur",
            description:
              "L'utilisateur sera totalement supprimé, cette action est irréversible",
          },
        },
      },
      deployments: {
        archiveDeploymentModal: {
          title: 'Archiver le déploiement',
          description:
            'Le déploiement ne sera plus affiché dans la liste des déploiements actifs',
        },
        unArchiveDeploymentModal: {
          title: 'Désarchiver le déploiement',
          description:
            'Le déploiement sera affiché dans la liste des déploiements actifs',
        },
        deleteDeploymentModal: {
          title: 'Suppression du déploiement',
          description: 'Cette action est irréversible',
        },
        nextDeploymentStepModal: {
          title: 'Avancement du déploiement à la prochaine étape',
          description: 'Cette action est irréversible',
        },
        downloadDeploymentArchiveModal: {
          title: 'Télécharger le dossier du déploiement',
          description: 'Le dossier sera téléchargé au format ZIP',
        },
        table: {
          title: 'Informations de déploiement',
          phase: 'Phase',
          start: 'Début',
          startLocation: 'Lieu remise',
          end: 'Fin',
          endLocation: 'Lieu reprise',
          amount: 'Nb vélos',
          hasSpecialBike: 'Vélo S',
          group: 'Flotte',
          actions: 'Actions',
          endLoanDate: 'Fin du prêt',
          companyNbrEmployees: 'Effectif',
          site: 'Site',
          organisation: 'Organisation',
          deploymentName: 'Nom du déploiement',
          bikeDistance: 'Distance vélo',
        },
        progress: {
          users: {
            title: 'Mes usagers',
            users: 'usagers',
            candidates: 'candidats',
            beneficiaries: 'bénéficiaires',
            details: 'Détails sur mes usagers',
          },
          stats: {
            title: 'Les statistiques des usagers',
            days: 'jours',
            rides: 'trajets',
            traveled: 'parcourus',
            ofUse: "d'utilisation",
            saved: 'économisés',
            workHome: 'domicile - travail',
            avoided: 'évités',
            spent: 'dépensées',
            userDetails: 'Détails sur les usagers',
            bikeParking: 'Parking vélos',
            bikeSpaceCapacityUnsafe: 'places vélos extérieures non sécurisées',
            bikeSpaceCapacityOutside: 'places vélos extérieures sécurisées',
            bikeSpaceCapacityInside: 'places vélos intérieures sécurisées',
            parking: 'Parking voitures',
            totalUsers: 'Effectif',
            registered: 'Inscrits',
            beneficiaries: 'Bénéficiaires',
            privateParking: 'places privées',
            electricChargePoints: 'bornes électriques',
          },
        },
        filter: {
          options: {
            all: 'Tous',
          },
        },
        monitoring: {
          title: 'Suivi du déploiement',
          type: {
            manual: 'Manuel',
            auto: 'Auto',
          },
          status: {
            [CompanyMonitoringStatusType.NOT_STARTED]: 'En cours',
            [CompanyMonitoringStatusType.IN_PROGRESS]: 'À faire',
            [CompanyMonitoringStatusType.COMPLETED]: 'Réalisé',
            [CompanyMonitoringStatusType.NONE]: 'Aucun',
          },
          table: {
            date: 'Date',
            deadline: 'Echéance',
            note: 'Note',
            status: 'Statut',
            type: 'Type',
            actions: 'Actions',
          },
        },
        users: {
          options: { all: 'Tous', yes: 'Oui', no: 'Non' },
          delete: "Supprimer l'utilisateur du déploiement",
          beneficiaryTable: {
            title: 'Usagers bénéficiaires',
            // Columns
            name: 'Nom',
            docs: 'Docs',
            bike: 'Vélo',
            workRides: 'Trajets travail',
            totalDistance: 'Distance totale',
            dailyDistance: 'Distance par jour',
            end: 'Retour',
            actions: 'Actions',
            // Filters
            timeSpan: 'Temporalité',
            lastRideTime: {
              [LastRideTime.YESTERDAY]: 'Hier',
              [LastRideTime.LAST_WEEK]: 'La semaine dernière',
            },
            totalDistanceInterval: {
              [TotalDistanceInterval.LT_1]: 'Moins de 1km',
              [TotalDistanceInterval.BT_1_10]: 'Entre 1km et 10km',
              [TotalDistanceInterval.BT_10_50]: 'Entre 10km et 50km',
              [TotalDistanceInterval.GT_50]: 'Plus de 50km',
            },
          },
          selectedTable: {
            deselectEmployeeModal: {
              title: "Désélectionner l'usager",
              description:
                'Confirmez vous la désélection de {{employeeFullName}} ?',
            },
            title: 'Usagers sélectionnés',
            // Columns
            name: 'Nom',
            docs: 'Docs',
            size: 'Taille',
            options: 'Options',
            start: 'Prêt',
            actions: 'Actions',
            // Filters
            state: 'Etat du dossier',
            childSeat: 'Siège enfant',
          },
          candidatesTable: {
            title: 'Usagers candidats',
            // Columns
            name: 'Nom',
            dt: 'DT',
            size: 'Taille',
            mode: 'Mode',
            options: 'Options',
            profile: 'Profil',
            order: 'Ordre',
            actions: 'Actions',
            // Filters
            parking: 'Stationnement',
          },
        },
        form: {
          title: 'Caractéristiques du déploiement',
          successNotification: 'Déploiement créé !',
          step1: 'Formule',
          step2: 'Choix de vélos',
          step3: 'Remise des vélos',
          step4: 'Reprise des vélos',
          fields: {
            deploymentName: 'Nom du déploiement',
            deploymentCompany: "Choix de l'organisation",
            deploymentSite: 'Choix du site',
            deploymentFleet: 'Flotte',
            deploymentType: 'Type de déploiement',
            deploymentDate: 'Date de déploiement',
            deploymentDatePlaceholder: 'jj/mm/aaaa',
            deploymentLocation: 'Lieu de déploiement',
          },
        },
      },
      profile: {
        metaTitle: 'Goodwatt Animateur - Mon profil',
      },
      bikeModels: {
        modal: {
          add: {
            title: 'Ajout d’un modèle de vélo',
            success: 'Modèle de vélo ajouté !',
            failure:
              "Impossible d'ajouter ce modèle, vérifiez votre saisie, le nom du modèle doit être unique !",
          },
          edit: {
            title: 'Modifier un modèle de vélo',
            success: 'Modèle de vélo modifié !',
            failure:
              'Impossible de modifier ce modèle, vérifiez votre saisie !',
          },
          delete: {
            title: 'Suppression du modèle de vélo',
            success: 'Modèle de vélo supprimé !',
            description:
              'Attention ! Cette suppression est irréversible et ne sera réalisée que si le modèle est inutilisé.',
            confim:
              'Confirmez-vous la suppression du modèle <strong>{{modelName}}</strong> ?',
            failure: 'Impossible de supprimer le modèle {{modelName}} !',
          },
          fields: {
            name: 'Modèle',
            brand: 'Marque',
            bikeType: 'Type',
            modelSizes: 'Tailles',
            childSeatCompatibility: 'Compatible siège enfant',
            weight: 'Poids (Kg)',
            publicPrice: 'Prix public (€)',
            description: 'Description',
            leaveAcontent: 'Description',
            picture: 'Illustration',
          },
        },
      },
      bikes: {
        dashboardTitleLabel: 'Mes vélos',
        bikeModelsTitleLabel: 'Modèles de vélos',
        deleteBikeModal: {
          title: 'Suppression du vélo',
          description:
            'Attention ! Cette suppression ne doit avoir lieu que si l’ajout du vélo a été fait par erreur. Si le vélo est vendu ou volé, il doit être déclaré comme tel depuis le tableau de bord et ne doit pas être supprimé.',
          confim:
            'Confirmez-vous la suppression du vélo <strong>{{stickerId}}</strong> ?',
        },

        filter: {
          fleets: 'Flottes',
          model: {
            label: 'Modèle',
            noOptions: 'Aucun autre modèle possible',
          },
          bikeConditions: {
            label: 'État',
            options: {
              noOptions: 'Aucun autre état possible',
              all: 'Tous',
              [BikeConditionType.ON_LOAN]: 'En prêt',
              [BikeConditionType.REPARATION]: 'En réparation',
              [BikeConditionType.STOCK]: 'En stock',
              [BikeConditionType.OUT_OF_SERVICE]: 'Hors service',
              [BikeConditionType.STOLEN]: 'Volé',
              [BikeConditionType.DEMONSTRATION]: 'En démonstration',
              [BikeConditionType.SOLD]: 'Vendu',
            },
          },
        },
        table: {
          addBike: 'Ajouter un vélo',
          headers: {
            bikeSticker: 'N° vélo',
            bikeModelName: 'Modèle',
            bikeModelBrand: 'Marque',
            bikeModelType: 'Type',
            bikeModelSize: 'Taille',
            bikeModelChildSeat: 'Opt',
            companySalaryName: 'Usager',
            bikeConditionName: 'État',
            bikeFleetName: 'Flotte',
            actions: 'Actions',
            size: 'Taille',
          },
        },
        copyBikeId: {
          tooltip: "Copier l'ID du vélo",
          errorNotification: "L'ID n'a pas pu être copié.",
          successNotification: "L'ID du vélo à bien été copié.",
        },
        modal: {
          title: 'Édition des informations du vélo',
          successNotification: {
            bikeUpdated: 'Vélo {{stickerId}} mis à jour',
            repairCreated: 'Réparation du vélo {{stickerId}} enregistrée',
            repairUpdated: 'Réparation du vélo {{stickerId}} mise à jour',
            repairEnded:
              'Fin de la réparation du vélo {{stickerId}} enregistrée',
          },
          errors: {
            soldEmptyField:
              "La saisie de la date et du nom de l'acheteur sont obligatoire pour déclarer un vélo vendu",
            denoEmptyField:
              "La saisie de la date et du nom de l'organisation sont obligatoire",
            companyNotExist: "Cette organisation n'existe pas",
            morioIdNotFound: 'Identifiant Morio inconnu',
            alreadyUse: 'Cet identifiant est déjà utilisé',
            bicycodeMaxLens:
              'Le Bicycode doit comporter au maximum {{max}} caractères',
          },
          fields: {
            soldDate: 'Date de vente',
            buyer: 'Acheteur',
            company: 'Entreprise',
            returnDate: 'Date de retour',
            preventChangeDueToCondition:
              "Il est impossible de changer les informations d'un vélo dans son état actuel",
            stickerId: 'Numéro du vélo',
            morioId: 'Identifiant Morio',
            bicycode: 'Identifiant Bicycode',
            bikeId: 'Identifiant du vélo en base',
            headlightId: 'Identifiant du phare',
            keysId: 'Identifiant des clés',
            frameId: 'Identifiant du cadre',
            padlockId: 'Identifiant du cadenas',
            fleet: "Flotte d'affectation",
            bikeCondition: 'État général',
            repairerLocation: 'Lieu de réparation',
            repairerLocationPlaceholder: 'Nom, Adresse et téléphone',
            repairResolvedDate: 'Date prévisionnelle de fin de réparation',
            repairCost: 'Coût de la réparation',
            bikeComponents: {
              default: 'État des composants',
              beforeRepair: 'État des composants avant réparation',
              afterRepair: 'Etat des composants après réparation',
            },
            childSeatEquiped: 'Siège enfant équipé',
          },
          bikeComponentsCondition: {
            bikeComponentPartModal: {
              title: 'Composant "{{partName}}"',
              noteLabel: "Commentaire sur l'état du composant",
              uploadedFiles: 'Fichiers téléversés',
              addFile: 'Ajouter un fichier',
              actionsDisabled: {
                upload:
                  "Il n'est seulement possible de téléverser des fichiers lors d'un état des lieux",
                delete:
                  "Il n'est seulement possible de téléverser des fichiers lors d'un état des lieux",
              },
            },
            table: {
              globalRating: 'Avis global',
              headers: {
                bikeComponent: 'Composants',
                conditionDescription: "Description de l'état",
                actions: 'Commentaires',
              },
            },
          },
          add: {
            title: 'Ajouter un vélo',
          },
        },
      },
      fleets: {
        dashboardTitleLabel: 'Mes flottes',
        table: {
          addFleet: 'Ajouter une flotte',
          headers: {
            area: 'Zone',
            name: 'Nom',
            total: 'Total',
            stock: 'Stock',
            loan: 'Prêt',
            otherState: 'Autres états',
            nextStep: 'Prochaine étape',
            date: 'Date',
            actions: 'Actions',
          },
        },
        notification: {
          successUpdate: 'Flotte mis à jour !',
          successCreate: 'Flotte crée !',
          successDelete: 'Flotte supprimée !',
        },
        filter: {
          stock: 'Niveau du stock',
          fleetConditions: {
            steps: {
              all: 'Toutes',
              [CompanyStepType.LOAN]: 'Prêt',
              [CompanyStepType.RETURN]: 'Retour',
              [CompanyStepType.TRAINING]: 'Formation',
            },
            stepsCategory: {
              all: 'Toutes',
              LOAN_DELIVERY: 'Prêt',
              LOAN_RETURN: 'Retour',
              TRAINING: 'Formation',
            },
            stockLevel: {
              all: 'Tous',
              [FleetStockLevel.LOW]: 'Faible',
              [FleetStockLevel.MEDIUM]: 'Moyen',
              [FleetStockLevel.IMPORTANT]: 'Important',
            },
          },
        },
        modal: {
          add: {
            title: "Ajout d'une flotte de vélos",
          },
          edit: {
            title: "Edition d'une flotte de vélos",
          },
          delete: {
            title: 'Suppression de la flotte',
            contentConfirm: 'Confirmez-vous la suppression de la flotte ',
            contentStart: 'Cette flotte contient toujours ',
            contentEnd:
              ' vélos. Il est donc nécessaire de rattacher ces vélos à une nouvelle flotte.',
            button: {
              confirm: 'Confirmer',
            },
            placeholderSelect: 'Choisir dans la liste',
          },
          inputs: {
            fleetName: 'Nom de la nouvelle flotte',
            area: 'Zone d’affectation',
            tableTitle: 'Ajouts de vélos à la flotte',
          },
          table: {
            headers: {
              stickerId: 'N° Vélo',
              morioId: 'N° Morio',
              bicycode: 'N° Bicycode',
              headlightId: 'N° Phare',
              keysId: 'Clés',
              model: 'Modèle',
            },
            addBike: 'Ajouter un vélo supplémentaire',
            idAlreadyUse: 'Les identifiants sont déjà attribués',
          },
          errors: {
            fleetCurentlyUseByCompany:
              'Cette flotte est affectée à un ou plusieurs déploiement en phase 3 ou plus et ne peut pas être supprimée',
            fleetNameAlreadyUsed: 'Le nom de la flotte est déjà utilisé',
            fleetEmpty: "Le zone d'affectation ne peux pas être vide",
            areaNameEmpty: 'Le nom de la flotte ne peux pas être vide',
          },
        },
      },
      company: {
        updateAppointmentModal: {
          title: "Modification des rendez-vous de l'usager",
          loan: 'Prêt de vélo',
          training: 'Formation',
          loanReturn: 'Retour du vélo',
        },
        shared: {
          selectedCompany: 'Organisation sélectionnée:',
          toBeDefined: 'à définir',
        },
        progress: {
          quizz: {
            satisfaction: {
              title: 'Dites nous à quel point cet essai vous a plu...',
              satisfy1: 'Pas satisfait',
              satisfy2: 'Peu satisfait',
              satisfy3: 'Neutre',
              satisfy4: 'Satisfait',
              satisfy5: 'Très satisfait',
            },
            email: {
              title: 'Entrez votre adresse email',
              placeholder: 'Email',
            },
            employeeName: {
              title:
                'Il semblerait que vous n’ayez pas de compte sur goodwatt.fr... aidez nous à le créer pour vous.',
              firstNamePlaceholder: 'Prénom',
              lastNamePlaceholder: 'Nom',
              phoneNumberPlaceholder: 'Téléphone mobile (ex: 0612345678)',
              cguLabel:
                'Je valide les <a href="https://www.goodwatt.fr/" target="_blank" class="underline">conditions générales d’utilisation</a> et j’accepte de créer automatiquement mon compte sur goodwatt.fr',
            },
            habits: {
              title: 'D’habitude, comment vous rendez vous au travail ?',
            },
            rideHabits: {
              title1: 'Quelle est la distance de votre trajet ?',
              title2: 'Quelle est la durée moyenne de ce trajet ?',
            },
            bikeHabits: {
              title: 'Aujourd’hui, vous pratiquez le vélo...',
              timeToTimeSublabel: 'le weekend et en vacances',
            },
            subscribe: {
              title:
                'Souhaitez vous candidater au mois de prêt de vélo à assistance électrique ?',
              no: 'Non, je ne souhaite pas participer',
              yes: 'Oui, je souhaite candidater',
              description: `Sauf changement, le prêt se déroulera entre : le <span class="color-primary bold">{{loanDate}}</span> et le<br /><span class="color-primary bold">{{returnDate}}</span>.<br /><br />En cliquant sur suivant, vous certifiez être présent à ces dates et  majoritairement sur la période.`,
            },
            parking: {
              title:
                'A votre domicile, comment vous engagez vous à stocker le vélo électrique de 24kg environ ?',
              description:
                'La batterie du vélo peut etre décrochée pour son chargement.<br /><br />Dans tous les espaces qui ne sont pas exclusivement privés aux membres de votre foyer, le vélo devra être attaché à un élément fixe (arceau, poteau, etc.) à l’aide de l’antivol fourni.',
            },
            babySeat: {
              title:
                'Aurez vous besoin d’un siège enfant sur le porte-bagage pour votre trajet domicile travail ?',
              description:
                'Le stock de siège enfant est limité.<br /><br />Il se peut que votre candidature ne puisse être validée à cause de ce choix. Si celui ci vous est essentiel, validez vos réponses.',
              nextButtonLabel: 'Valider mes réponses',
            },
            success: {
              title: 'Merci pour participation et à bientôt à vélo !',
              description: 'Vos identifiants vous ont été envoyés par email',
              buttonLabel: 'Au suivant !',
            },
            quit: {
              title: 'Souhaitez vous vraiment quitter le questionnaire ?',
              description:
                'Seul l’animateur GoodWatt peut décider de quitter le questionnaire.',
            },
          },
          employeesRecap: {
            title: "Usagers de l'organisation",
            employees: '{{count}} usager',
            employees_plural: '{{count}} usagers',
            description0: 'inscrit',
            description0_plural: 'inscrits',
            description1: 'avec un profil complet',
            description1_plural: 'avec un profil complet',
            description2: 'validé sur ',
            description2_plural: 'validés sur ',
            description3: 'bénéficiaire',
            description3_plural: 'bénéficiaires',
            place: '{{count}} place',
            place_plural: '{{count}} places',
          },
          bikesRecap: {
            title: 'Suivi des déplacements',
            label0: '{{count}} km',
            description0: 'parcouru au total',
            description0_plural: 'parcourus au total',
            label1: '{{count}} kg CO2',
            description1: 'évité',
            description1_plural: 'évités',
            label2: '{{count}} trajet',
            label2_plural: '{{count}} trajets',
            description2: 'domicile - travail',
            label3: '{{count}} minute',
            label3_plural: '{{count}} minutes',
            description3: 'de vélo',
          },
          stepStatus: {
            title: 'Avancement',
            confirmationPopup: "C'est votre dernier mot ?",
            description1:
              'Ce changement est susceptible de provoquer l’envoi de mails automatiques à l’organisation et à ses usagers.',
            description2:
              'Confirmez-vous le passage de <strong>{{companyName}}</strong> à la <strong>phase {{stepNbr}} - {{stepLabel}}</strong> ?',
            errorStep1:
              'Veuillez vous assurer de remplir les dates et lieux du prêt et de la formation.',
            errorStep2:
              "Vous devez attendre la fin de l'essai découverte afin de changer de phase",
            errorStep3:
              'Au moins un usager doit être sélectionné pour le déploiement',
            errorStep4: {
              coordinatesNotFound:
                "Veuillez vérifier l'adresse de l'organisation, nous n'avons pas pu trouver les coodonnées de celle-ci",
              morioError:
                "Une erreur est survenue avec Morio, n'hésitez pas à contacter notre service afin d'avoir plus d'informations",
            },
            errorStep5:
              'Tous les vélos doivent avoir été rendus et les cautions des usagers toutes levées',
          },
          convention: {
            signed: 'Convention signé',
            unsigned: 'Convention non signée',
          },
          eligibleToSpecialBikes: {
            eligible: 'Vélos spéciaux disponibles',
            notEligible: 'Pas de vélos spéciaux',
          },
          launchQuizz: "Lancer le questionnaire de l'essai découverte",

          trainingDate: 'Formation<br />',
          bikeReturnDate: 'Retour des vélos<br />',

          trainingDateToDefine: 'Date de formation à définir',

          bikeLoanDate: {
            description: 'Prêt des vélos<br />',
            descriptionToDefine: 'Date de prêt des vélos à définir',
            place: '{{count}} place',
            place_plural: '{{count}} places',
          },

          appointmentDateTime: 'le {{date}} à {{time}}<br />à {{place}}',
          appointmentDate: 'le {{date}}<br />à {{place}}',

          bikeReturnDateToDefine: 'Date de retour des vélos à définir',
          modal: {
            title: 'Modification des dates et caractéristiques du prêt',
            date: 'Date',
            applyToAll: 'Appliquer à tous',
            applyToNew: 'Nouveaux usagers uniquement',
            skip: 'Passer >',
            confirmModal:
              'Vous avez ajouté des créneaux <strong>uniquement</strong> aux nouveaux usagers.',
            deliveryAppointment: {
              title: 'Prêt des vélos',
              location: 'Lieu précis',
              locationPlaceholder: 'Batiment C',
              startingDate: 'Horaire du début',
              slots: 'Créneaux',
              durationSubLabel: 'minutes',
              employeesSubLabel: 'usagers',
            },
            trainingAppointment: {
              title: 'Formation',
              location: 'Lieu précis',
              locationPlaceholder: 'Batiment C',
              startingDate: 'Horaire du début',
              slots: 'Créneaux',
              durationSubLabel: 'minutes',
              employeesSubLabel: 'usagers',
            },
            returnAppointment: {
              title: 'Retour des vélos',
              location: 'Lieu précis',
              locationPlaceholder: 'Batiment C',
              startingDate: 'Horaire du début',
              slots: 'Créneaux',
              durationSubLabel: 'minutes',
              employeesSubLabel: 'usagers',
            },
            nBikes: 'Nombre de vélos disponibles',
            notificationErrorUpdateBikePlaces:
              'Il y a déjà trop de personnes inscrites pour cet événement',
            notificationErrorEmptyField: 'Veuillez remplir tous les champs',
            notificationErrorEventDateShouldBeFollowing:
              'Les événements doivent se suivre',
          },
        },
        monitoring: {
          title: "Suivi de l'organisation",
          filter: {
            companyMonitoringStatus: {
              label: 'Status',
              options: {
                all: 'Tous',
                [CompanyMonitoringStatusType.NOT_STARTED]: 'En cours',
                [CompanyMonitoringStatusType.IN_PROGRESS]: 'À faire',
                [CompanyMonitoringStatusType.COMPLETED]: 'Réalisé',
                [CompanyMonitoringStatusType.NONE]: 'Aucun',
              },
            },
            companyMonitoringType: {
              label: 'Type',
              options: {
                all: 'Tous',
                [CompanyMonitoringType.MANUAL]: 'Manuel',
                [CompanyMonitoringType.AUTO]: 'Auto',
              },
            },
          },
          table: {
            headers: {
              date: 'Date',
              type: 'Type',
              note: 'Note',
              deadline: 'Echéance',
              companyMonitoringStatus: 'Status',
              actions: 'Actions',
            },
          },
          modal: {
            add: {
              title: 'Ajouter une nouvelle entrée',
              successNotification: 'Nouvelle information ajoutée',
            },
            edit: {
              title: "Suivi de l'organisation",
              successNotification: 'Organisation mise à jour',
            },
            delete: {
              title: "C'est votre dernier mot ?",
              confirmationMessage:
                'Souhaitez vous supprimer cette note de suivi ?',
              successNotification: 'Organisation mise à jour',
            },
          },
        },
        employees: {
          selected: {
            loanTitleLabel: 'Usagers bénéficiaires du prêt',
            titleLabel: 'Usagers sélectionnés',
            allTitleLabel: 'Usagers inscrits',
            ratio: '{{current}} sur {{total}}',
            failureStepEmployeeSelection:
              "Il est impossible de sélectionner un usager une fois l'organisation passée en phase 5",
            successEmployeeSelection:
              '{{employeeName}} a bien été sélectionné(e) pour le programme !',
            filter: {
              options: {
                label: 'Options',
                options: {
                  all: 'Tous',
                },
              },
              // babySeat: {
              //   label: 'Siège enfant',
              //   options: {
              //     all: 'Tous',
              //     [BabySeatOptions.NEEDED]: 'Si possible',
              //     [BabySeatOptions.NOT_NEEDED]: 'Non nécessaire',
              //     [BabySeatOptions.ABSOLUTELY_NEEDED]: 'Essentiel',
              //   },
              // },
              programStep: {
                label: 'État du dossier',
                options: {
                  all: 'Tous',
                  complete: 'Complet',
                  notComplete: 'Incomplet',
                },
              },
              temporality: {
                label: 'Temporalité',
                options: {
                  all: 'Durée du prêt',
                  lastWeek: 'Semaine dernière',
                  yesterday: 'Hier',
                },
              },
              distance: {
                label: 'Distance parcourue',
                options: {
                  all: 'Tous',
                  lt1: "Moins d'1 km",
                  gte1Lt10: 'Entre 1 et 10 km',
                  gte10Lt50: 'Entre 10 et 50 km',
                  gt50: 'Plus de 50 km',
                },
              },
            },
            table: {
              headers: {
                name: 'Nom',
                bike: 'Velo',
                height: 'Taille',
                loan: 'Prêt',
                training: 'Formation',
                options: 'Options',
                babySeat: 'Siège bébé',
                documents: 'Documents',
                accessories: 'Accessoires',
                homeWorkRides: 'Trajets travail',
                totalDistance: 'Distance totale',
                dailyDistance: 'Distance quotidienne',
                return: 'Retour',
              },
            },
            documents: {
              title: 'Documents',
              identity: "Carte d'identité",
              shortIdentity: 'Identité',
              proofOfAddress: 'Justificatif de domicile',
              shortProofOfAddress: 'Domicile',
              deposit: 'Caution bancaire',
              shortDeposit: 'Caution',
              successDeposit:
                'Caution enregistrée ({{ref}}), expire le {{date}}',
              expiredDeposit: 'Caution bancaire expirée',
              unavailableDeposit: 'Caution indisponible',
              unavailableByCheque: 'Caution par chèque',
              contract: 'Contrat',
              unavailableDoc: 'Document indisponible',
              verified: 'Vérifié',
              refused: 'Rejeté',
              modal: {
                title: "C'est votre dernier mot ?",
                verifiedIdentity:
                  'Confirmez-vous la validation de la pièce d’identité de',
                rejectedIdentity:
                  'Confirmez-vous le rejet de la pièce d’identité de',
                verifiedAddress:
                  'Confirmez-vous la validation du justificatif de domicile de',
                rejectedAddress:
                  'Confirmez-vous le rejet du justificatif de domicile de',
                verifiedDeposit:
                  'Confirmez-vous le dépôt de la caution par chèque de',
                rejectedDeposit:
                  "Confirmez-vous l'annulation du dépôt de la caution par chèque de",
              },
            },
            modals: {
              releaseDeposits: {
                title: 'Levée des cautions restantes',
                body: 'Vous souhaitez lever l’ensemble des cautions des usagers suite au retour de tous les vélos et accessoires.',
                people:
                  'Des cautions ont pourtant été calculées automatiquement et n’ont pas encore été prélevées : {{peopleNames}}. Le prélèvement ne sera plus possible après cette action.',
                chequePeople:
                  "Des cautions par chèque ont aussi été calculées et n'ont pas encore été prélevées : {{peopleNames}}, elles ne seront pas levées automatiquement",
                confirm: 'Confirmez vous la levée totale des cautions ?',
              },
              deposit: {
                reclaimDeposit: {
                  title: 'Confirmation du prélèvement de caution',
                  body: 'Confirmez vous le prélèvement de la caution de {{amount}}€ pour l’usager <strong>{{name}}</strong> ? La demande sera envoyée au prestataire de caution.',
                },
                depositByChequeSendEmail: {
                  title: 'Confirmation du prélèvement de la caution par chèque',
                  body: 'Confirmez-vous avoir informé <strong>{{name}}</strong> du prélèvement de sa caution par chèque ? En cliquant sur confirmer, la caution sera considérée comme prélevée',
                },
                releaseDeposit: {
                  title: 'Confirmation de la levée de caution',
                  body: 'Confirmez vous la levée de la caution pour l’usager <strong>{{name}}</strong> ?',
                },
                buttons: {
                  depositEmail: 'Informer le client',
                  releaseDeposit: 'Lever la caution',
                  reclaimDeposit: 'Prélever la caution',
                },
                title: 'Prélèvement de caution',
                damagedElement: 'Élément endommagé',
                depositByCheque: `Cet utilisateur n'a pas renseigné de caution bancaire, mais a fourni un chèque`,
                noDeposit: `Cet utilisateur n'a pas renseigné de caution bancaire`,
                relaseByChequeTitle: `Levée de la caution par chèque`,
                relaseByCheque: `Confirmez-vous la levée de la caution de <strong>{{name}}</strong> et la destruction du chèque associé ?`,
                before: 'Avant',
                after: 'Après',
                estimatedAmount: 'Montant estimé',
                idBike: 'Vélo : <strong>{{ref}}</strong>',
                employee: 'Usager : <strong>{{name}}</strong>',
                totalDeposit: 'Montant total de la retenue de caution',
                stateOfUse: 'État : ',
                comment: 'Commentaire : ',
                errorAmount: 'Le montant est plus élevé que la caution prélevé',
                reasonPlaceholder: 'Motif du prélèvement (obligatoire)',
              },
              employeeProgramWithdrawal: {
                title: 'Retrait de l’usager',
                description:
                  "Vous êtes sur le point de retirer <strong>{{employeeName}}</strong> de la liste des usagers sélectionnés pour le mois de prêt. Cette action vous permettra d'avoir une place disponible supplémentaire pour le mois de prêt.",
                confirm: '<strong>Confirmez-vous ce choix ?</strong>',
                successNotification: '{{name}} a bien été retiré du programme',
              },
              employeeLoanBikeModal: {
                warnEmployeeNotVerified:
                  'Vous devez valider les documents de l’usager avant de pouvoir prêter un vélo',
                warnEmployeeDepositNotFound:
                  'L’usager doit valider sa caution bancaire pour pouvoir profiter du prêt',
                warnEmployeeDepositNotValid:
                  "La caution de l’usager a expiré, demandez lui de la remplir à nouveau pour qu'il puisse profiter du prêt",
                warnNoDateSelected:
                  'Vous devez selectionner une date de prêt et de retour des vélos avant de pouvoir prêter un vélo',
                successLoanNotification: 'Le prêt à bien été pris en compte !',
                bikeAttribution: {
                  title: 'Attribution d’un vélo',
                  description:
                    'Vous êtes sur le point d’attribuer un vélo à <strong>{{employeeName}}</strong>.',
                  babySeat:
                    'Lors de son inscription, l’usager a demandé le prêt d’un <strong>siège enfant</strong>',
                  loanDateRange:
                    'Le prêt se déroule entre le <strong>{{loanDeliveryDate}}</strong> et le <strong>{{loanReturnDate}}</strong>.',
                  loanDateRangeWithoutEnd:
                    'Le prêt se déroule à partir du <strong>{{loanDeliveryDate}}</strong>.',
                  selectBike:
                    'Sélectionnez <strong>l’identifiant du vélo</strong> pour confirmer.',
                },
                bikeComponentsCondition: {
                  title: 'Etat des lieux du vélo {{stickerId}}',
                  bikeComponentPartModal: {
                    title: 'Composant "{{partName}}"',
                    noteLabel: "Commentaire sur l'état du composant",
                    uploadedFiles: 'Fichiers téléversés',
                    addFile: 'Ajouter un fichier',
                    maxLimitAttachmentReached:
                      "Vous ne pouvez télécharger que 3 fichiers par pièce, supprimez en un avant d'en télécharger un nouveau",
                    wellUploadedNotification:
                      'Pièce jointe téléchargée, cliquez dessus pour prévisualiser son contenu',
                    wellDeletedNotification: 'Pièce jointe supprimée',
                  },
                  table: {
                    globalRating: 'Avis global',
                    headers: {
                      bikeComponent: 'Composants',
                      conditionDescription: "Description de l'état",
                      actions: 'Commentaires',
                    },
                  },
                },
                bikeLoanAccessoriesCondition: {
                  title: 'Sélection et état des accessoires',
                  bikeLoanAccessoryModal: {
                    title: 'Accessoire "{{partName}}"',
                    noteLabel: "Commentaire sur l'état de l'accessoire",
                  },
                  table: {
                    headers: {
                      accessory: 'Accessoires',
                      conditionDescription: "Description de l'état",
                      actions: 'Commentaires',
                    },
                  },
                },
                animatorLoanValidation: {
                  title: "Validation de la location par l'animateur",
                  description: {
                    bike: "Vous êtes sur le point de finaliser l'attribution du vélo <strong>{{stickerId}}</strong>",
                    accessories: 'et des accessoires',
                    employee: 'à l’usager  <strong>{{employeeName}}</strong>',
                    loanRanges:
                      'entre le <strong>{{loanDeliveryDate}}</strong> et le <strong>{{loanReturnDate}}</strong>',
                    loanRangesWithoutEnd:
                      'à partir du <strong>{{loanDeliveryDate}}</strong>',
                  },
                  verification:
                    "Après avoir vérifier l'ensemble des éléments constitutifs du dossier, veuillez valider le début de la location.",
                },
                employeeLoanValidation: {
                  title: 'Validation de la location par L’usager',
                  description: {
                    bike: 'Vous êtes sur le point de finaliser la location du vélo <strong>{{stickerId}}</strong>',
                    accessories: 'et des accessoires précédemment listés',
                    loanRanges:
                      'entre le <strong>{{loanDeliveryDate}}</strong> et le <strong>{{loanReturnDate}}</strong>',
                    loanRangesWithoutEnd:
                      'à partir du <strong>{{loanDeliveryDate}}</strong>',
                  },
                  verificationAlreadySign:
                    "L’usager ayant signé en amont son contrat sur l'application, le bon de remise est déjà pré-accepté. Il vous suffit de valider le prêt.",
                  verificationAbsent:
                    'L’usager ayant signalé son absence, le bon de remise est déjà pré-accepté. Il vous suffit de valider le prêt.',
                  verification:
                    'En entrant mes identifiants ou en validant mon contrat via l’app mobile, je confirme que les informations renseignées sont exactes et m’engage à respecter les termes du contrat d’utilisation.',
                  backToPartsState: "Retour à l'état des lieux",
                  paperContract: "Signature d'un contrat papier",
                  animatorLogBack: "Reconnexion de l'animateur",
                  authPassed: {
                    main: {
                      validated: 'Votre location est validée.',
                      enjoy: "Profitez de l'expérience électrique !",
                    },
                    thanks:
                      "Merci de rendre l'appareil à votre animateur GoodWatt",
                  },
                  signPaper: {
                    main: {
                      enjoy:
                        "En accord avec l'animateur GoodWatt, vous avez choisi de signer un contrat papier",
                    },
                    thanks:
                      "Merci de rendre l'appareil à votre animateur GoodWatt",
                  },
                  signOnApp: {
                    main: {
                      enjoy: `Vous avez choisi de signer électroniquement le contrat sur l'application mobile GoodWatt`,
                    },
                    errors: {
                      notSign:
                        "Le contrat doit être signé sur l'app avant de pouvoir continuer",
                    },
                    thanks:
                      "Merci de rendre l'appareil à votre animateur GoodWatt",
                  },
                  correctionsNeeded: {
                    main: "Vous souhaitez revenir sur les éléments de l'état des lieux...",
                    thanks:
                      "Merci de rendre l'appareil à votre animateur GoodWatt",
                  },
                },
              },
              employeeReturnBikeModal: {
                successReturnPackagesNotification:
                  'Le retour des accessoires a bien été pris en compte',
                successReturnNotification:
                  'Le retour du vélo à bien été pris en compte',
                bikeSelection: {
                  title: "Retour d'un vélo",
                  description: {
                    bike: 'Vous êtes sur le point d’enregistrer le retour du vélo',
                    accessories: 'et des accessoires prêtés',
                    employee: 'à <strong>{{employeeName}}</strong>.',
                  },
                  selectBike:
                    'Sélectionnez <strong>l’identifiant du vélo</strong> prêté.',
                },
                bikeComponentsCondition: {
                  title: 'Retour du vélo {{stickerId}}',
                },
                returnPackagesCondition: {
                  title: 'Retour des accessoires',
                  body: 'Vous êtes sur le point d’enregistrer le retour des accessoires prêtés à <strong>{{employeeName}}</ strong>.',
                  bikeLoanAccessoryModal: {
                    title: 'Accessoire "{{partName}}"',
                    noteLabel: "Commentaire sur l'état de l'accessoire",
                  },
                  table: {
                    noDataText: "Aucun accessoire n'avait été prêté",
                    headers: {
                      accessory: 'Accessoires',
                      conditionDescription: "Description de l'état",
                      actions: 'Commentaires',
                    },
                  },
                },
                animatorReturnValidation: {
                  title: "Validation du retour du vélo par l'animateur",
                  description: {
                    bike: 'Vous êtes sur le point de conclure le prêt du vélo',
                    accessories: 'et des accessoires',
                    employee: 'de <strong>{{employeeName}}</strong>.',
                    retentionOnDeposit:
                      'Les éventuelles différences entre les états des lieux avant et après le prêt sont susceptibles de conduire au prélèvement d’une caution dont le montant sera défini à posteriori.',
                  },
                },
              },
            },
          },
          subscribed: {
            titleLabel: 'Usagers inscrits',
            filter: {
              mode: {
                label: 'Mode',
                options: {
                  all: 'Tous',
                  [TravelModeType.NONE]: 'Aucun (télétravail)',
                  [TravelModeType.BIKE]: 'Vélo',
                  [TravelModeType.WALKING]: 'Marche à pied',
                  [TravelModeType.PUBLIC_TRANSPORTATION]: 'Transport en commun',
                  [TravelModeType.CARPOOLING]: 'Covoiturage',
                  [TravelModeType.MOTORISED_TWO_WHEELS]: 'Deux roues motorisés',
                  [TravelModeType.CAR]: 'Voiture',
                  [TravelModeType.ELECTRIC_BIKE]: 'Vélo électrique',
                },
              },
              parkingType: {
                label: 'Stationnement',
                options: {
                  all: 'Tous',
                  [ParkingType.NONE]: 'Extérieur',
                  [ParkingType.INSIDE_WITH_KEY]: 'Intérieur à clé',
                  [ParkingType.OUTSIDE_WITH_KEY]: 'Extérieur à clé',
                },
              },
            },
            table: {
              headers: {
                name: 'Nom',
                distance: 'DT',
                parkingType: 'Stationnement',
                mode: 'Mode',
                discovery: 'Essai',
                profile: 'Profil',
                order: 'Ordre',
              },
              row: {
                profile: {
                  complete: 'Complet',
                },
              },
            },
            modal: {
              title: 'Nombre maximal de vélos atteint',
              companyLoanMax:
                'Le nombre de vélos défini pour le mois de prêt pour cette organisation a été fixé à <strong>{{companyLoanMax}}</strong>. Vous ne pouvez plus sélectionner de nouveaux usagers.',
              updateCompanyLoanMax:
                'Si besoin, vous avez la possibilité de <strong>modifier ce nombre</strong> de vélos sur la page d’avancement de l’organisation ou <strong>retirer un usager</strong> de la liste.',
              confirm: 'C’est noté',
            },
          },
        },
        documents: {
          modals: {
            title: "C'est votre dernier mot ?",
            deletionMessage: 'Souhaitez-vous supprimer ce document ?',
          },
        },
      },
      post: {
        filter: {
          tags: {
            options: {
              bikeAndAccessories: 'Vélo et accessoires',
              testPeriod: 'Mois de test',
              insurance: 'Assurance',
              Assurance: 'Assurance',
              support: 'Assistance',
            },
          },
          MIME: {
            options: {
              'text/plain': 'Texte',
              'text/markdown': 'Mardown',
            },
          },
        },
        modal: {
          add: {
            title: 'Ajouter une nouvelle entrée',
            successNotification: 'Nouvelle information ajoutée',
          },
          edit: {
            title: 'Edition d’une question de la foire',
            successNotification: 'Question mis à jour',
          },
          delete: {
            title: 'Suppression de la question',
            confirmationMessage:
              'Confirmez-vous la suppression de la question “ <strong>{{question}}</strong> ” ?',
            successNotification: 'Question supprimée',
          },
          view: {
            tags: 'Tags',
            content: 'Contenu',
          },
          title: 'Ajout d’une question de la foire',
        },
        dashboardTitleLabel: 'Tableau de bord des questions',
        table: {
          headers: {
            tags: 'Catégorie',
            order: 'Ordre',
            title: 'Question',
            state: 'Etat',
            content: 'Réponse',
          },
        },
      },
      advice: {
        filter: {
          tags: {
            options: {
              gettingAroundOnADaily: 'Se déplacer au quotidien',
              maintenance: 'Entretien',
              preventionAndSafety: 'Prévention et sécurité',
            },
          },
        },
        modal: {
          add: {
            title: 'Ajout d’un conseil',
            successNotification: 'Nouvelle information ajoutée',
          },
          edit: {
            title: 'Edition d’un conseil',
            successNotification: 'Conseils mis à jour',
          },
          delete: {
            title: 'Suppression du conseil',
            confirmationMessage:
              'Confirmez-vous la suppression du conseil “<strong>{{content}}</strong>” ?',
            successNotification: 'Conseil supprimé',
          },
          view: {
            tags: 'Tags',
            content: 'Contenu',
          },
          title: 'Ajouter un nouveau conseil',
        },

        dashboardTitleLabel: 'Tableau de bord des conseils',
        table: {
          headers: {
            tags: 'Catégorie',
            order: 'Ordre',
            title: 'Titre du conseil',
            content: 'Texte du conseil',
            state: 'Etat',
          },
        },
      },
    },
    quiz: {
      modal: {
        fileUploadText:
          'Glissez et déposez votre fichier ici pour remplacer l’image, ou ',
        fileUploadHighlight: 'cherchez dans les dossiers',
        deleteImage: 'Supprimer l’image',
        add: {
          title: 'Ajout d’un quiz',
          successNotification: 'Quiz ajoutée',
        },
        edit: {
          title: 'Edition d’un quiz',
          successNotification: 'Quiz mis à jour',
        },
        delete: {
          title: 'Suppression du quiz',
          confirmationMessage:
            'Confirmez-vous la suppression du quiz “<strong>{{content}}</strong>” ?',
          successNotification: 'Quiz supprimé',
        },
        view: {
          content: 'Contenu',
        },
        title: 'Ajouter un nouveau quiz',
        label: 'Titre du quiz',
        link: 'Lien URL',
        image: 'Choix d’une image',
        linkPlaceholder: 'lien…',
      },
      dashboardTitleLabel: 'Tableau de bord des quiz',
      table: {
        headers: {
          imageURL: 'Image',
          title: 'Intitulé',
          state: 'Etat',
          content: 'Lien URL du quiz',
        },
      },
    },
    employee: {
      offer: {
        title: 'Mon offre',
        name: 'Déploiement',
        type: {
          [DeploymentType.Discovery]: 'Découverte',
          [DeploymentType.Rental]: 'Location',
        },
        from: 'Du : ',
        until: 'Au : ',
        contactUs: 'Un imprévu ? Contactez-nous',
      },
      onBoarding: {
        congrasModal: {
          title: 'Votre dossier est complet !',
          description: [
            'Félicitations ! Votre dossier est complet et vous pouvez désormais candidater à une location GoodWatt dans votre structure !',
            'En cas de problème, les éléments que vous venez de renseigner peuvent être modifiés dans la rubrique <strong>Mon Profil</strong> et <strong>Mes Documents</strong> avant la sélection des usagers.',
          ],
        },
        welcomeModal: {
          title: 'Bienvenue dans votre espace personnel',
          description:
            'Pour candidater à l’aventure GoodWatt, nous avons besoin d’en savoir un peu plus sur vous. Merci de compléter les quelques formulaires et charger certains justificatifs.',
        },
      },
      deployments: {
        title: 'Mes déploiements Goodwatt éligibles',
        offers: 'Les offres Goodwatt',
        bikes: 'Les vélos Goodwatt',
        table: {
          headers: {
            actions: 'Candidater',
            offer: 'Offre',
            deployment: 'Nom du déploiement',
            startDate: 'Date de début',
            endDate: 'Date de fin',
            status: 'Statut',
          },
        },
        submit: 'Je candidate à ce déploiement',
        modal: {
          title: 'Je suis candidat !',
          description:
            '<strong style="color: #000;font-weight: normal;font-size: 1.5rem;">Je confirme vouloir me porter <strong>candidat</strong><br> au déploiement GoodWatt {{deploymentType}}<br> du {{startDate}} au {{endDate}}.<br><br>Je choisis le vélo <strong>{{bikeType}}</strong> <strong>{{bikeBrand}}</strong> <strong>{{bikeModel}}</strong>, {{bikeOption}}.</><br><br><span style="font-size: 1.5rem;color: red;"><strong>Attention, il est impossible de se désister une fois sélectionné pour le déploiement. Veuillez bien vérifier votre disponibilité aux dates de début et de fin de celui-ci.</strong></span>',
          bikeType: {
            [SpecialBikesType.CARGO_BIKE]: 'Familial',
            [SpecialBikesType.FOLDING_BIKE]: 'Pliant',
            [SpecialBikesType.URBAN_BIKE]: 'Urbain',
          },
          deploymentType: {
            [DeploymentType.Discovery]: 'Découverte',
            [DeploymentType.Rental]: 'Location',
            [DeploymentType.None]: 'Aucun',
          },
          options: {
            childSeat: 'avec option siège enfant',
            none: 'sans option',
          },
        },
        deleteModal: {
          title: 'Je souhaite me désinscrire d’un déploiement',
          description:
            '<span style="color: #000;font-weight: normal;font-size: 1.5rem;">Je confirme vouloir <strong>me désinscrire</strong> du déploiement GoodWatt {{deploymentType}} du {{startDate}} au {{endDate}}.</span><br><br><span style="color: #000;font-weight: normal;font-size: 1.5rem;"><strong>Cette action est irrémédiable, êtes-vous sûr de vouloir cela ?</strong></span><span style="color: #000;font-weight: normal;font-size: 1.5rem;">Attention, il est impossible de se désister une fois sélectionné pour le déploiement. Veuillez bien vérifier votre disponibilité aux dates de début et de fin de celui-ci.</span>',
          success: 'Désinscription effectuée',
        },
      },
      application: {
        from: 'Du ',
        to: 'au ',
        model: 'Modèle',
        possibleOptions: 'Option possible',
        childSeat: 'Siège enfant',
        none: 'Aucune',
        needChildSeat: "J'aimerais un siège enfant",
        yourRequestIs: 'Votre demande est :',
        requiredRequest: 'Indispensable',
        optionalRequest:
          'Facultative : j’accepte aussi de participer sans siège enfant',
        optionalRequestWarning:
          'Le stock de siège enfant étant limité, votre candidature peut être rejetée en cas de forte demande si ce choix vous est essentiel. Le siège enfant est obligatoire pour les enfants de moins de 5 ans ou 20 kg.',
        remainder: 'RESTE A VOTRE CHARGE',
        euroPerMonth: '€/mois',
        rental: 'Location',
        discovery: 'Découverte',
        public_price: 'Prix public',
        size: 'Taille',
        weight: 'Poids',
      },
      warning: {
        missingDocuments: 'Pour valider votre candidature, ',
        missingDocumentsLink: 'veuillez compléter votre profil',
        missingCaution:
          'Pour bénéficier de votre vélo, il vous faut impérativement ',
        missingCautionLink: 'renseigner votre caution bancaire.',
      },
      dashboard: {
        siteOf: 'Site de {{siteName}} ',
        welcome:
          'Bienvenue dans votre espace personnel. Vous trouverez ici tout ce qu’il vous faut pour candidater puis suivre votre participation à GoodWatt.',
        appHighlight: [
          'Fixez-vous <strong>des objectifs d’utilisation</strong> chaque semaine',
          'Suivez <strong>votre progression</strong> avec nos indicateurs de pratique',
          'Cumulez des badges et des points pour devenir un expert du vélo électrique !',
        ],
        appHighlightDownload: 'Je télécharge l’application',
        bikeIssue: 'Un problème avec le vélo ?',
        bikeIssueNote: '(perte, casse, vol)',
        reportAbsent: 'Je signale mon absence',
        reportDeliveryAbsent: 'Je signale mon absence<br /> au prêt du vélo',
        reportReturnAbsent: 'Je signale mon absence<br /> au retour du vélo',
        absent: 'Absent(e)',
        deliveryAbsent: 'Absent(e) au prêt du vélo',
        returnAbsent: 'Absent(e) au retour du vélo',
        bikeReturned: 'Vélo rendu',
        bikeIssueModal: {
          title: 'Un problème avec le vélo ?',
          selectLabel: 'Sélectionnez votre problème',
          optionStolen: "J'ai perdu ou me suis fait volé le vélo",
          optionIssue: "J'ai un problème avec le vélo",
          whatIsYourProblem:
            'Parmi les propositions ci-dessous, sélectionnez celle qui correspond le mieux au problème que vous rencontrez',
          lastPosition:
            '<br/>Le vélo était situé au point suivant : <strong>{{coordinates}}</strong><br/>',
          lastDatePosition:
            'A l’aide du traceur, nous avons identifié que la dernière position géographique du vélo a été enregistrée le <strong>{{date}}</strong>.',
          couldNotFindPosition:
            'Impossible de récupérer les coordonnées du vélo',
          ifNotHelping:
            'Si cette information ne vous permet pas de retrouver le vélo, veuillez au plus vite déclarer le vol du vélo. L’information sera transmise à notre <strong>prestataire de recherche</strong> et aux <strong>services d’assurances.</strong>',
          declareStolenOrLostBike: 'Déclarer la perte ou le vol',
          haveAnIssue:
            "Décrivez le plus précisément possible le problème que vous rencontrez (origine du problème, implications, etc.). Précisez-nous également si ce problème vous empêche d'utiliser le vélo.",
          declareButton: 'Déclarer le problème',
          stolenSuccess:
            'Nous vous remercions pour la déclaration de perte ou de vol. Les services de recherche',
          issueSuccess:
            'Nous vous remercions pour votre déclaration.<br /><br />Le problème a bien été transmis à votre animateur qui vous recontactera dans les plus brefs délais.',
        },
        modal: {
          [AppointmentCategory.Training]: {
            title: 'Je signale mon absence à la formation',
            subTitle:
              'Je serai absent(e) lors de la formation pratique au savoir rouler en ville.',
            contentLine1:
              'A savoir : votre participation à la formation au savoir rouler en ville est vivement recommandée. En 1h, vous découvrirez toutes les bonnes pratiques et les astuces à connaître pour vous déplacer à vélo électrique en ville en toute sécurité.',
          },

          [AppointmentCategory.LoanDelivery]: {
            title: 'Je signale mon absence au prêt des vélos',
            subTitle: 'Je serai absent(e) lors du prêt de vélos.',
            contentLine1:
              "Je m'engage à mandater une personne pour récupérer mon vélo et mes accessoires auprès de l'équipe GoodWatt au créneau horaire qui m'est attribué.",
            contentLine2:
              "Pour pouvoir bénéficier du vélo en prêt, j'accepte au préalable les conditions du ",
            contentLine3:
              'Mon bon de remise sera disponible dans mon espace "Mes documents" à l\'issue du prêt de mon vélo.',
            locationContact: 'contrat de location',
          },

          [AppointmentCategory.LoanReturn]: {
            title: 'Je signale mon absence au retour des vélos',
            subTitle: 'Je serai absent(e) lors du retour de mon vélo.',
            contentLine1: `Je m'engage à mandater une personne pour remettre mon vélo et mes accessoires à l'équipe GoodWatt, au créneau horaire qui m'est attribué.`,
            contentLine2: `En cas de non restitution du vélo au créneau prévu, je m'expose à des pénalités financières telles que prévues dans le `,
            locationContact: 'contrat de location',
          },

          putPassword: 'Veuillez saisir votre mot de passe, puis accepter',
          errorPassword: 'Le mot de passe saisie est incorrect',
        },
        bikeFound: "J'ai retrouvé mon vélo !",
        bikeFoundModal: {
          title: "J'ai retrouvé mon vélo",
          description:
            'Les services de recherche seront informés et cesseront leur travail dès lors que vous aurez validé que le vélo a bien été retrouvé.<br /><br />Confirmez-vous avoir retrouvé votre vélo ?',
        },
        missingProfileData: {
          description: 'Pour profiter du prêt !',
          buttonLabel: 'Complétez votre profil',
        },
        missingDocumentDate: {
          description: 'Pour profiter du prêt !',
          buttonLabel: 'Renseignez vos pièces justificatives',
          descriptionValidating:
            'Vos pièces justificatives sont en attente de validation',
          buttonLabelCaution: 'Renseignez votre caution bancaire',
          buttonLabelValidating: 'Mes documents',
        },
      },
      menu: {
        home: 'Accueil',
        profile: 'Mon profil',
        documents: 'Mes documents',
        path: 'Mon trajet',
        monitoring: 'Mon suivi',
        videos: 'Mes tutos',
        bikeMaterials: 'Mon matériel',
        help: 'Aide & Contact',
      },
      profile: {
        editModalTitle: 'Modifier le profil de {{name}}',
        birth: 'Naissance',
        birthAndHeight: 'Naissance et taille',
        contact: 'Mes coordonnées',
        address: 'Mon adresse',
        habits: 'Mes habitudes de déplacement',
        toGoWork: 'Pour aller au travail',
        myDocuments: 'Mes documents',
        myBikeInfo: {
          title: 'Ma pratique du vélo',
          babySeat: {
            label: 'Êtes-vous intéressé par un siège enfant ?',
          },
        },
        confirmEmailModal: {
          title: 'Validation du mail de l’usager',
          body: 'Confirmez-vous la validation du mail de l’usager {{employeeName}} ?\nEn cas d’erreur, vous pouvez modifier ce mail avant validation.',
          CTA: 'Confirmer',
        },
        confirmDeleteModal: {
          title: 'Suppression de l’usager',
          body: 'Confirmez-vous la suppression du compte de l’usager <strong>{{employeeName}}</strong> ?',
          CTA: 'Confirmer',
        },
        editHabitsModal: {
          title: {
            default: 'Habitudes et besoins - {{employeeName}}',
            myInfo: 'J’édite mes habitudes et besoins',
          },
          workTravel: {
            title: 'Habituellement, comment allez-vous au travail ?',
            mode: 'Mode de transport utilisé le plus fréquemment',
            distance: 'Distance (en km)',
            duration: 'Durée (en minutes)',
          },
          bikeUsage: {
            title: 'Actuellemment, quelle est votre pratique du vélo ?',
          },
          bikeParking: {
            title:
              'A votre domicile, disposez-vous d’un espace pour stationner un vélo ?',
          },
          babySeat: {
            title:
              'Avez vous impérativement besoin d’un siège enfant pour le programme ?',
            stockWarning:
              'Vous avez demandé un siège enfant. Le stock étant limité, nous ne pouvons vous garantir le prêt du matériel',
          },
          specialBikes: {
            model: {
              title:
                'De quel modèle de vélo à assistance électrique souhaiteriez-vous bénéficier pendant le mois de test ?',
              URBAN_BIKE: 'Un vélo électrique urbain',
              FOLDING_BIKE: 'Un vélo électrique pliant',
              CARGO_BIKE: 'Un vélo électrique familial (cargo longtail)',
            },
            absolutelyNeeded: {
              title: 'Votre demande de vélo spécifique est :',
              yes: 'Indispensable pour mon usage pendant le test',
              no: "Souhaitée, mais j'accepte également de participer avec le modèle urbain",
              warning:
                'Le stock de vélos électriques spécifiques (pliants et familiaux) étant limité, votre candidature peut être rejetée en cas de forte demande si ce choix vous est <u>essentiel</u>.',
            },
            babyseat: {
              warning:
                'Le stock de siège enfant étant limité, votre candidature peut être rejetée en cas de forte demande si ce choix vous est <u>essentiel</u>. Le siège enfant est obligatoire pour les enfants de moins de 5 ans ou 20kg.',
            },
          },
        },
      },
      documents: {
        idCardStatus: {
          upload: "Charger une pièce d'identité",
          waitingValidation: "Pièce d'identité en cours de validation",
          refused:
            "Pièce d'identité refusée, charger une nouvelle version du document",
          accepted: "Pièce d'identité validée",
        },
        proofOfAddressStatus: {
          upload: 'Charger un justificatif de domicile',
          waitingValidation: 'Justificatif de domicile en cours de validation',
          refused:
            'Justificatif de domicile refusé, charger un nouveau justificatif de domicile',
          accepted: 'Justificatif de domicile validé',
        },
        depositStatus: {
          upload:
            'Renseigner ma caution bancaire pour le déploiement {{deploymentName}}',
          accepted:
            'Votre caution bancaire pour le déploiement {{deploymentName}} a bien été renseignée',
          released:
            'Votre caution bancaire pour le déploiement {{deploymentName}} a été libérée ou prélevée le cas échéant le {{releaseDate}}',
        },
        contract: {
          sign: 'Signer le contrat de prestation',
          signed: 'Mon contrat de prêt pour le déploiement {{deploymentName}}',
        },
        uploadModal: {
          idCardTitle: 'J’ajoute ma pièce d’identité',
          idCardDesc:
            "Les documents acceptés sont : une copie <strong>recto-verso de votre carte d'identité</strong> ou de votre <strong>passeport</strong>. Vous pouvez charger deux fichiers distincts en une seule fois. Pour cela, il vous suffit de les ranger dans le même dossier puis de les sélectionner en même temps.",
          proofOfAddressTitle: 'J’ajoute mon justificatif de domicile',
          proofOfAddressDesc:
            "Les documents acceptés sont : une facture d'électricité, de gaz ou de téléphone, une quittance de loyer ou un avis d'imposition.",
        },
        remissionOrder:
          'Mon bon de remise pour le déploiement {{deploymentName}}',
      },
      monitoring: {
        filter: {
          all: 'Depuis le début',
          last7: 'Depuis 7 jours',
        },
        stats: {
          title: 'Mes statistiques',
          label0: '{{count}} km',
          description0: 'parcouru',
          description0_plural: 'parcourus',
          label1: '{{count}} kg CO2',
          description1: 'évité',
          description1_plural: 'évités',
          label2: '{{count}} trajet',
          label2_plural: '{{count}} trajets',
          description2: 'domicile - travail',
          label3: '{{count}} kcal',
          description3: 'dépensé',
          description3_plural: 'dépensés',
          label4: '{{count}} € gagné',
          label4_plural: '{{count}} € gagnés',
          description4: 'par rapport à mon mode habituel',
          label5: '{{count}} jours',
          description5: "d'utilisation",
          howTo: 'Comment sont calculés ces chiffres ?',
        },
        ridesTable: {
          title: 'Récapitulatif des trajets',
          columns: {
            date: 'Date',
            homeWorkRideDistances: 'Travail',
            otherRideDistances: 'Autre',
            totalDistances: 'Distance',
            totalHomeWork: 'Nombre de trajets\n Domicile-Travail',
          },
        },
        diagram: {
          title: 'Mes trajets hebdomadaires',
          tooltipLabels: {
            ride: 'Trajets',
            distance: 'Distances (km)',
            homeWork: 'Travail',
            other: 'Autre',
          },
        },
      },
    },
    company: {
      dashboard: {
        welcome:
          'Bienvenue dans votre espace. Vous trouverez ici tout ce qu’il vous faut pour gérer le dispositif GoodWatt dans votre organisation.',
        stepTitle: 'Avancement du programme',
        updateSite: 'Modifier',
      },
      card: {
        employees: 'Mes usagers',
        bikeLoan: 'Prêt des vélos le {{date}} à {{hour}}',
        bikeReturn: 'Retour des vélos le {{date}} à {{hour}}',
        moreDetailsOnEmployees: 'Plus de détails sur mes usagers',
      },
      menu: {
        home: 'Accueil',
        sites: 'Mes sites',
        profile: 'Mon profil',
        documents: 'Mes documents',
        employees: 'Mes usagers',
        siteEmployees: 'Usagers',
        monitoring: 'suivi',
        results: 'Mon bilan',
        help: 'Aide & Contact',
        avancement: 'Avancement',
        myCompany: 'Mon organisation',
        application: 'Application',
        helpAndContact: 'Aide et Contact',
        createSite: 'Créer un site',
        addReferee: 'Ajouter un référent',
        allSites: 'Sites',
        allReferrers: 'Référents',
        informations: 'Informations',
      },
      modals: {
        site: {
          update: {
            title: 'Modifier le site',
          },
          create: {
            title: 'Créer un site',
          },
        },
      },
      tables: {
        filters: {
          deployment_type: {
            all_companies: 'Toutes les organisations',
            all_deployment_types: 'Offre',
            deployment_types: {
              [DeploymentType.Discovery]: 'Découverte',
              [DeploymentType.Rental]: 'Location',
            },

            all_program_steps: 'Toutes les phases',
          },
          status: 'Statut',
          sites: 'Sites',
          deployment: 'Déploiement',
        },
        headers: {
          startDate: 'Début',
          endDate: 'Fin',
          name: 'Nom',
          candidats: 'Candidats',
          deployment_type: 'Offre',
        },
      },
      profile: {
        nameLabel: 'Nom',
        siretLabel: 'Siret',
        typeLabel: "Type d'organisation",
        siret: 'SIRET : {{siret}}',
        employeesCountLabel: 'Effectif',
        employee: '{{count}} usager',
        employee_plural: '{{count}} usagers',
        referee: 'Référent',
        address: 'Adresse',
        animator: 'Animateur',
        noAnimator: "Pas d'animateur affecté à cet employeur",
        bikeParking: 'Stationnement de vélos',
        bikeSpaceCapacityUnsafe:
          '{{count}} place vélo extérieure non sécurisée',
        bikeSpaceCapacityUnsafe_plural:
          '{{count}} places vélos extérieures non sécurisées',
        bikeSpaceCapacityOutside: '{{count}} place vélo extérieure sécurisée',
        bikeSpaceCapacityOutside_plural:
          '{{count}} places vélos extérieures sécurisées',
        bikeSpaceCapacityInside: '{{count}} place vélo intérieure sécurisée',
        bikeSpaceCapacityInside_plural:
          '{{count}} places vélos intérieures sécurisées',
        carParking: 'Parking voiture',
        carSpaceCapacity: '{{count}} place privée',
        carSpaceCapacity_plural: '{{count}} places privées',
        electricStationCapacity: '{{count}} borne électrique',
        electricStationCapacity_plural: '{{count}} bornes électriques',
        modifyFleet: 'Modifier la flotte',
        modifyAnimator: "Modifier l'animateur",
        editModalTitle: "J'édite le profil de l'organisation",
        editParkingTitle: "J'édite l'offre de stationnement de l'organisation",
        confirmEmailModal: {
          title: 'Validation du mail du référent',
          body: 'Confirmez-vous la validation du mail du référent {{employeeName}} ?\nEn cas d’erreur, vous pouvez modifier ce mail avant validation.',
          CTA: 'Confirmer',
        },
        siteLabel: 'Site',
      },
      modifyFleetModal: {
        title: "Je modifie la flotte affectée à l'organisation",
        description: `Vous souhaitez modifier la flotte de vélos associée à l'organisation. Une fois en phase de prêt des vélos, cette modification sera impossible.`,
        description2:
          'Veuillez choisir <strong>la nouvelle flotte affectée à l’organisation</strong>',
        notificationSuccess: 'La flotte a bien été modifié',
      },
      modifyAnimatorModal: {
        title: "Je modifie l'animateur affecté à l'organisation",
        description: `Vous souhaitez modifier l'animateur affecté à l'organisation.`,
        description2:
          "Veuillez choisir <strong>le nouvel animateur affecté à l'organisation</strong>",
        notificationSuccess: "L'animateur a bien été modifié",
      },
      employees: {
        table: {
          title: 'Liste des usagers',
          headers: {
            name: 'Nom',
            site: 'Site',
            deployment: 'Déploiement',
            mensual: 'Mensualité',
            status: 'Statut',
            testingMonth: 'Mois de prêt',
            actions: 'Actions',
            runningDeployments: 'déploiements en cours',
          },
          employeeStatus: {
            selected: 'Sélectionné',
            validated: 'Validé',
            profileCompleted: 'Candidat',
            profileNonCompleted: 'Non candidat',
            noValue: 'Inscrit',
            beneficiary: 'Bénéficiaire',
            candidate: 'Candidat',
          },
        },
        filters: {
          testingMonth: {
            label: 'Mois de test',
            options: {
              all: 'Tous',
              selected: 'Sélectionnés',
              validated: 'Validés',
              profileCompleted: 'Candidats',
              profileNonCompleted: 'Non candidats',
              beneficiary: 'Bénéficiaires',
              registered: 'Inscrits',
            },
          },
        },
      },
      monitoring: {
        title: '{{address}} - {{name}} -',
        stats: {
          title: 'Statistiques des usagers',
          label0: '{{count}} km',
          description0: 'parcouru',
          description0_plural: 'parcourus',
          label1: '{{count}} kg CO2',
          description1: 'évité',
          description1_plural: 'évités',
          label2: '{{count}} trajet',
          label2_plural: '{{count}} trajets',
          description2: 'domicile - travail',
          label3: '{{count}} kcal',
          description3: 'dépensé',
          description3_plural: 'dépensés',
          label4: '{{count}} € gagné',
          label4_plural: '{{count}} € gagnés',
          description4: 'pour les usagers',
          label5: '{{count}} € économisé',
          label5_plural: '{{count}} € économisés',
          description5: "pour l'organisation",
          label6: '{{count}} jour',
          label6_plural: '{{count}} jours',
          description6: "d'arrêt maladie évité par an",
          description6_plural: "d'arrêt maladie évités par an",
          howTo: 'Comment sont calculés ces chiffres ?',
          employeesBikeDayUsed: '{{count}} jour',
          employeesBikeDayUsed_plural: '{{count}} jours',
          employeesBikeDayUsedDescription: 'd’utilisation',
        },
        ridesTable: {
          title: 'Récapitulatif des trajets',
          columns: {
            date: 'Date',
            activeEmployees: 'Usagers actifs',
            homeWorkRideDistances: 'Travail',
            otherRideDistances: 'Autre',
            totalDistances: 'Distance',
            totalHomeWork: 'Trajets\n Domicile-Travail',
          },
        },
        diagram: {
          title: 'Trajets hebdomadaires des usagers',
          tooltipLabels: {
            homeWork: 'Travail',
            distance: 'Distances (km)',
            other: 'Autre',
          },
        },
      },
    },
  },
}
