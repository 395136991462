/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  PackageAccessoryType,
  StateOfUseType,
} from '@goodwatt/shared/dist/types'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useMutation } from '@apollo/client'

import Checkbox from '../../Checkbox'

import { updateBikeLoanAccessory } from '../../../redux/forms'
import { StoreState } from '../../../redux/rootReducer'
import { PartsConditionTableRow } from '../../../types/AnimatorCompanyEmployees'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import { useNotifications } from '../../../contexts/NotificationContext'
import {
  CreateOneLoanPackageMutation,
  CreateOneLoanPackageMutationVariables,
  DeleteOneLoanPackageMutation,
  DeleteOneLoanPackageMutationVariables,
} from '../../../__generated__/graphql'

const MUTATION_CREATE_ONE_LOAN_PACKAGE = gql`
  mutation CreateOneLoanPackage($data: LoanPackageCreateInput!) {
    createOneLoanPackage(data: $data) {
      id
      stateOfUse {
        name
      }
      note
    }
  }
`

const MUTATION_DELETE_ONE_LOAN_PACKAGE = gql`
  mutation DeleteOneLoanPackage($where: LoanPackageWhereUniqueInput!) {
    deleteOneLoanPackage(where: $where) {
      id
    }
  }
`

interface SelectAccesoryCell {
  accessory: PartsConditionTableRow
  selected?: boolean
}

const SelectAccessoryCell: React.FC<SelectAccesoryCell> = ({
  accessory,
  selected = false,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const { accessories } = useSelector(
    (state: StoreState) => state.forms.loanBikeForm,
  )
  const [createOneLoanPackage] = useMutation<
    CreateOneLoanPackageMutation,
    CreateOneLoanPackageMutationVariables
  >(MUTATION_CREATE_ONE_LOAN_PACKAGE)
  const [deleteOneLoanPackage] = useMutation<
    DeleteOneLoanPackageMutation,
    DeleteOneLoanPackageMutationVariables
  >(MUTATION_DELETE_ONE_LOAN_PACKAGE)
  const dispatch = useDispatch()

  const currentAccessory = React.useMemo(
    () => accessories[accessory.name as PackageAccessoryType],
    [accessories, accessory.name],
  )

  const handleSelect = useCallback(
    async (isDefaultIncluded = false) => {
      const { data } = await createOneLoanPackage({
        variables: {
          data: {
            packageAccessory: {
              connect: {
                name: accessory.name,
              },
            },
            stateOfUse: {
              connect: {
                name: StateOfUseType.EXCELLENT,
              },
            },
          },
        },
      })
      dispatch(
        updateBikeLoanAccessory({
          name: accessory.name as PackageAccessoryType,
          payload: {
            ...currentAccessory,
            stateOfUse: data?.createOneLoanPackage.stateOfUse
              .name as StateOfUseType,
            note: data?.createOneLoanPackage.note || '',
            id: data?.createOneLoanPackage.id,
            included: true,
            isDefaultIncluded,
          },
        }),
      )
    },
    [accessory, createOneLoanPackage, currentAccessory, dispatch],
  )

  const handleCheckBoxClick = async () => {
    try {
      if (!currentAccessory.included) {
        await handleSelect()
      } else {
        await deleteOneLoanPackage({
          variables: {
            where: {
              id: accessory.id,
            },
          },
        })
        dispatch(
          updateBikeLoanAccessory({
            name: accessory.name as PackageAccessoryType,
            payload: {
              ...currentAccessory,
              stateOfUse: undefined,
              note: undefined,
              included: false,
              attachments: [],
              isDefaultIncluded: false,
            },
          }),
        )
      }
    } catch (error) {
      apiErrorCatcher(notifications)(error)
    }
  }

  useEffect(() => {
    if (!selected) return
    if (!accessories[accessory.name as PackageAccessoryType].isDefaultIncluded)
      return

    if (accessories[accessory.name as PackageAccessoryType].included) return

    handleSelect(true)
  })

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <span>{t(`shared.loanPackageAccessoryLabel.${accessory.name}`)}</span>
      <Checkbox
        isChecked={
          (selected && currentAccessory.isDefaultIncluded) ||
          currentAccessory.included
        }
        onClick={handleCheckBoxClick}
      />
    </div>
  )
}

export default SelectAccessoryCell
