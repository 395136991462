import {
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client'
import {
  DeleteDeploymentEmployeeMutation,
  DeleteDeploymentEmployeeMutationVariables,
} from '../../../../__generated__/graphql'

export const MUTATION_DELETE_DEPLOYMENT_EMPLOYEE = gql`
  mutation DeleteDeploymentEmployee($id: String!) {
    deleteEmployeeDeployment(id: $id)
  }
`

export const useDeleteDeploymentEmployee = (
  options?: MutationHookOptions<
    DeleteDeploymentEmployeeMutation,
    DeleteDeploymentEmployeeMutationVariables
  >,
): MutationTuple<
  DeleteDeploymentEmployeeMutation,
  DeleteDeploymentEmployeeMutationVariables
> => {
  return useMutation(MUTATION_DELETE_DEPLOYMENT_EMPLOYEE, {
    refetchQueries: ['GetDeploymentEmployees'],
    ...options,
  })
}
