import { CompanyMonitoringType } from '@goodwatt/shared/dist/types'

export enum CompanyMonitoringTableModalActions {
  EDIT = 'edit',
  TRASH = 'trash',
}

export enum CompanyMonitoringTableAdd {
  ADD = 'add',
}

export interface CompanyMonitoringSettings<
  D = CompanyMonitoringTableRow,
  T = CompanyMonitoringTableModalActions | CompanyMonitoringTableAdd,
> {
  companyMonitoring: D
  actionName: '' | T
}

export interface CompanyMonitoringTableRow {
  id: string
  note: string
  statusId: string
  statusName: string
  type?: {
    name: string
    value: CompanyMonitoringType
  }
  date: Date | undefined
  deadline?: Date | undefined
}

export interface ModalCompanyMonitoringSubmit<
  T = { name: string; value: string },
> {
  note: string
  status: T
  date: T
  deadline?: Date
}
