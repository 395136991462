export enum PostTableModalActions {
  EDIT = 'edit',
  TRASH = 'trash',
  VIEW = 'view',
}

export enum PostTableAdd {
  ADD = 'add',
}

export interface TagTableRow {
  id: string
  key: string
}

export interface PostSettings<
  D = PostTableRow,
  T = PostTableModalActions | PostTableAdd,
> {
  post: D
  actionName: '' | T
}

export interface PostTagSettings<
  D = PostTagTableRow,
  T = PostTableModalActions | PostTableAdd,
> {
  tag: D
  actionName: '' | T
}

export interface PostTableRow {
  id: string
  title: string
  content: string
  order: number | undefined
  MIME: string
  tags: TagTableRow[]
}

export interface PostTagTableRow {
  id: string
  key: string
  order: number | undefined
}

export interface ModalPostSubmit {
  title: string
  content: string
  order: number
  MIME: string
  tags: { value: string; label: string }
}

export interface ModalPostTagSubmit {
  key: string
  order: number
}
