/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { Flex } from '../../../components/Flex'

import { GoodwattOffers } from './components/GoodwattOffers'
import { GoodwattBikes } from './components/GoodwattBikes'
import { AvailableDeployments } from './components/AvailableDeployments'
import { DocumentsAndCaution } from './components/DocumentsAndCaution'

export const Deployments: React.FC = () => {
  return (
    <Fragment>
      <DocumentsAndCaution />

      <Flex direction="row" css={{ marginBottom: '20px' }}>
        <GoodwattOffers />
        <GoodwattBikes />
      </Flex>

      <AvailableDeployments />
    </Fragment>
  )
}

export default Deployments
