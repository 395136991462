/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useNotifications,
  NotificationTypes,
} from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import { useDeleteEmployeeDeployment } from '../hooks/useDeleteEmployeeDeployment'
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../../../../components/ConfirmationModal'

export interface DeleteEmployeeDeploymentModalProps
  extends Pick<ConfirmationModalProps, 'isOpen' | 'onClose'> {
  id: string
  deploymentName: string
  deploymentType: string
  startDate: string
  endDate: string
}

export const DeleteEmployeeDeploymentModal: React.FC<
  DeleteEmployeeDeploymentModalProps
> = ({ id, deploymentName, deploymentType, startDate, endDate, ...rest }) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [mutate, { loading }] = useDeleteEmployeeDeployment()

  const onSubmit = async () => {
    try {
      await mutate({
        variables: { id },
      }).then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t('employee.deployments.deleteModal.success'),
        })

        rest.onClose()
      })
    } catch (e) {
      apiErrorCatcher(notifications)
    }
  }

  const description = () => {
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: t('employee.deployments.deleteModal.description', {
            deploymentType,
            startDate,
            endDate,
          }),
        }}
      />
    )
  }

  return (
    <ConfirmationModal
      title={t('employee.deployments.deleteModal.title')}
      description={description()}
      loading={loading}
      onSubmit={onSubmit}
      {...rest}
    />
  )
}

export default DeleteEmployeeDeploymentModal
