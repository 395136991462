/** @jsx jsx */
import React, { useCallback, useMemo, useState } from 'react'
import { jsx } from '@emotion/core'
import CardTitle from '../../../components/Card/CardTitle'
import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '../../../components/Table'
import { CellProps, Column, Row } from 'react-table'
import { gql, useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import { UserRoles } from '@goodwatt/shared/dist/types'
import {
  QUERY_COMPANIES_NAME,
  QUERY_ANIMATOR_DEPLOYMENTS,
  QUERY_ANIMATOR_DEPLOYMENTS_COUNT,
} from '../../../utils/gql/queries'
import {
  DeploymentType,
  GetCompaniesNamesQuery,
  GetCompaniesNamesQueryVariables,
  GetDeploymentsCountQuery,
  GetDeploymentsCountQueryVariables,
  GetDeploymentsQuery,
  GetDeploymentsQueryVariables,
  SortOrder,
  CompanyArchiveStatus,
  GetArchivedDeploymentRangesQuery,
} from '../../../__generated__/graphql'
import Pagination from '../../../components/Pagination'
import CardFoldable from '../../../components/Card/Foldable'
import InputSelect from '../../../components/InputSelect'
import { useTranslation } from 'react-i18next'
import { SelectVariant } from '../../../hooks/useVariant/variants/select'
import Input from '../../../components/Input'
import { TextVariant } from '../../../hooks/useVariant/variants/text'
import { debounce } from 'lodash'
import { DeploymentTypeIcon } from '../Shared/DeploymentTypeIcon'
import { NavLink } from 'react-router-dom'
import theme from '../../../styles/theme'
import { DeploymentActions } from '../Deployment/components/DeploymentActions'
import { ArchivedDeploymentActions } from '../Deployment/components/ArchivedDeploymentActions'
import { format } from 'date-fns'
import DownloadCSVButton from '../../../components/Button/DownloadCSVButton'
import { formatDeploymentExport } from '../../../utils/gql/deploymentExport'
import { Flex } from '../../../components/Flex'
import usePromiseLazyQuery from '../../../hooks/usePromiseLazyQuery'

const PAGE_SIZE = 8

const QUERY_ARCHIVE_DEPLOYMENT_RANGES = gql`
  query GetArchivedDeploymentRanges {
    deploymentsExportRanges {
      label
      startDate
      endDate
      count
    }
  }
`

export const programStepFilterOptions = Array.from(Array(5).keys()).map(
  (_, i) => ({
    value: `${i >= 1 ? i + 1 : i + 1}`,
    label: `Phase ${i >= 1 ? i + 1 : i + 1}`,
  }),
)

interface Filters {
  company?: string
  deployment_type?: DeploymentType
  programStep?: string
  multiFilter?: string
}

interface Props {
  title: string
  statusColor: string
  archived?: boolean
  isDefaultFolded?: boolean
}

export default function DeploymentsTable(props: Props) {
  const { t } = useTranslation()
  const { id: userId, role: userRole } = useSelector(
    (state: StoreState) => state.user,
  )
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<Filters>({})
  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)
  const isAdmin = userRole === UserRoles.ADMIN

  const { data: companiesData } = useQuery<
    GetCompaniesNamesQuery,
    GetCompaniesNamesQueryVariables
  >(QUERY_COMPANIES_NAME, {
    variables: {
      where: {
        AND: [
          isAdmin
            ? {}
            : {
                //area: { animator: { some: { userId: { equals: userId } } } },
                sites: {
                  some: {
                    area: {
                      animator: {
                        some: {
                          userId: { equals: userId },
                        },
                      },
                    },
                  },
                },
              },
          {
            OR: [
              props.archived
                ? {}
                : {
                    archive: null as any,
                  },
              props.archived
                ? {}
                : {
                    archive: {
                      status: {
                        not: { equals: CompanyArchiveStatus.Archived },
                      },
                    },
                  },
            ],
          },
        ],
      },
      orderBy: [
        {
          name: SortOrder.Asc,
        },
      ],
    },
  })

  const { data: countData } = useQuery<
    GetDeploymentsCountQuery,
    GetDeploymentsCountQueryVariables
  >(QUERY_ANIMATOR_DEPLOYMENTS_COUNT, {
    variables: {
      company: filters.company,
      deploymentType: filters.deployment_type,
      programStep: filters.programStep ? parseInt(filters.programStep, 10) : 0,
      multiFilter: filters.multiFilter,
      archived: props.archived ?? false,
    },
  })

  const variables = {
    orderBy:
      orderBy.sort && orderBy.columnName
        ? { [orderBy.columnName]: orderBy.sort }
        : undefined,
    company: filters.company,
    deploymentType: filters.deployment_type,
    programStep: filters.programStep ? parseInt(filters.programStep, 10) : 0,
    multiFilter: filters.multiFilter,
    archived: props.archived ?? false,
  }

  const { data, loading } = useQuery<
    GetDeploymentsQuery,
    GetDeploymentsQueryVariables
  >(QUERY_ANIMATOR_DEPLOYMENTS, {
    fetchPolicy: 'network-only',
    variables: {
      skip: (page - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
      ...variables,
    },
  })

  const companiesFilterOptions = useMemo(
    () =>
      companiesData?.companies?.map(c => ({
        value: c!.id!,
        label: c!.name!,
      })) ?? [],
    [companiesData],
  )

  const formattedData = useMemo(
    () =>
      data?.deployments?.map(d => ({
        id: d?.id,
        name: d?.name,
        company: d?.site?.company?.name,
        companyId: d?.site?.company?.id,
        site: d?.site?.name,
        programStep: d?.programStep?.stepNbr,
        employeesOnSite: d?.site?.employeesOnSite,
        employeesCount: d?.employeesCount,
        deploymentType: d?.deploymentType,
        selectedEmployeesCount: d?.selectedEmployeesCount,
        candidateCount: d?.candidateCount,
        beneficiaryCount: d?.beneficiaryCount,
        deploymentEndDate: format(new Date(d?.deploymentEndDate), 'dd/MM/yyyy'),
        employeesTotalDistanceInKm: d?.employeesTotalDistanceInKm,
        updatedAt: d?.updatedAt,
      })) ?? [],
    [data],
  )

  const onSelectChange = useCallback(({ value }, { name }) => {
    setFilters(prevState => ({
      ...prevState,
      [name]: value !== 'all' ? value : undefined,
    }))
  }, [])

  const updateMultiFilter = useMemo(
    () =>
      debounce(value => {
        setFilters(prevState => ({
          ...prevState,
          multiFilter: value || undefined,
        }))
      }, 300),
    [],
  )

  const onMultiFilterChange = useCallback(
    e => {
      /* signal to React not to nullify the event object */
      e.persist()

      updateMultiFilter(e.target.value)
    },
    [updateMultiFilter],
  )

  const [downloadRange, setDownloadRange] = useState<unknown>(null)

  const getDeployments = usePromiseLazyQuery<
    GetDeploymentsQuery,
    GetDeploymentsQueryVariables
  >(QUERY_ANIMATOR_DEPLOYMENTS)

  const getExportData = async () => {
    if (downloadRange == null) {
      return []
    }

    const { data } = await getDeployments({
      ...variables,
    })

    if (data == null) return []

    return formatDeploymentExport(
      (data.deployments as any[]).filter(({ deploymentStartDate }) => {
        const date = new Date(deploymentStartDate)
        const start = new Date((downloadRange as any).value.startDate)
        const end = new Date((downloadRange as any).value.endDate)
        return date >= start && date < end
      }),
    )
  }

  const { data: rangesData } = useQuery<GetArchivedDeploymentRangesQuery>(
    QUERY_ARCHIVE_DEPLOYMENT_RANGES,
    {
      skip: !props.archived,
      fetchPolicy: 'network-only',
    },
  )

  const rangesOptions = React.useMemo(() => {
    if (rangesData?.deploymentsExportRanges == null) {
      return []
    }

    return rangesData.deploymentsExportRanges.map(range => ({
      label: range?.label as string,
      value: {
        startDate: range?.startDate as string,
        endDate: range?.endDate as string,
      },
    }))
  }, [rangesData])

  const ProgramStepCell = useCallback(
    ({ row }: { row: Row<typeof formattedData[0]> }) => {
      return (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 2,
          }}
        >
          {row.original.programStep}
          <DeploymentTypeIcon type={row.original.deploymentType!} />
        </div>
      )
    },
    [formattedData],
  )

  const EmployeesCountCell = useCallback(
    ({ row }: { row: Row<typeof formattedData[0]> }) => {
      return (
        <div>
          {row.original.candidateCount}{' '}
          <span css={{ color: 'hsla(0, 0%, 51%, 1)', fontSize: 14 }}>
            ({row.original.beneficiaryCount})
          </span>
        </div>
      )
    },
    [formattedData],
  )

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: t('animator.deployments.table.deploymentName') as string,
        Cell: ({ row }: CellProps<typeof formattedData[number]>) => {
          return (
            <NavLink
              css={{
                color: theme.colors.primary,
                textDecoration: 'underline',
              }}
              to={
                isAdmin
                  ? `/administrator/dashboard/${row.original.id}/progress`
                  : `/animator/dashboard/${row.original.id}/progress`
              }
            >
              {row.original.name}
            </NavLink>
          )
        },
        accessor: 'name',
        maxWidth: 200,
        cellStyle: () => ({
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }),
      },
      {
        Header: t('animator.deployments.table.organisation') as string,
        Cell: ({ row }: CellProps<typeof formattedData[number]>) => {
          return (
            <NavLink
              css={{
                color: theme.colors.primary,
                textDecoration: 'underline',
              }}
              to={
                isAdmin
                  ? `/administrator/companies/${row.original.companyId}/profile`
                  : `/animator/companies/${row.original.companyId}/profile`
              }
            >
              {row.original.company}
            </NavLink>
          )
        },
        accessor: 'company',
        maxWidth: 200,
        cellStyle: () => ({
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }),
      },
      {
        Header: t('animator.deployments.table.site') as string,
        accessor: 'site',
      },
      props.archived
        ? {
            Header: t('animator.deployments.table.endLoanDate') as string,
            accessor: 'deploymentEndDate',
          }
        : {
            Header: t('animator.deployments.table.phase') as string,
            Cell: ProgramStepCell,
          },
      props.archived
        ? {
            Header: t('animator.deployments.table.bikeDistance') as string,
            accessor: 'employeesTotalDistanceInKm',
            cellStyle: () => ({ textAlign: 'center' }),
          }
        : {
            Header: t(
              'animator.deployments.table.companyNbrEmployees',
            ) as string,
            accessor: 'employeesOnSite',
            cellStyle: () => ({ textAlign: 'center' }),
          },
      {
        Header: () => (
          <div>
            Candidats <div css={{ fontSize: 12 }}>(Bénéficiaires)</div>
          </div>
        ),
        accessor: 'employeesCount',
        Cell: EmployeesCountCell,
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: t('animator.deployments.table.actions') as string,
        Cell: ({ row }: CellProps<typeof formattedData[number]>) => {
          return props.archived ? (
            <ArchivedDeploymentActions
              companyId={row.original.companyId || ''}
              deploymentId={row.original.id}
              stepNbr={row.original.programStep}
              lastUpdated={row.original.updatedAt}
            />
          ) : (
            <DeploymentActions
              deploymentId={row.original.id}
              stepNbr={row.original.programStep}
              archived={false}
            />
          )
        },
        cellStyle: (): { overflow: string } => ({
          overflow: 'inherit',
        }),
      },
    ],
    [
      isAdmin,
      formattedData,
      ProgramStepCell,
      EmployeesCountCell,
      props.archived,
      t,
    ],
  )

  return (
    <div css={{ marginBottom: 22 }}>
      <CardFoldable
        title={
          <CardTitle label={props.title} statusColor={props.statusColor} />
        }
        isDefaultFolded={props.isDefaultFolded}
      >
        <div
          css={{
            display: 'flex',
            gap: 20,
            marginTop: '2.1rem',
            marginBottom: '2.1rem',
          }}
        >
          <div css={{ minWidth: '250px' }}>
            <InputSelect
              name="company"
              variant={SelectVariant.white}
              withError={false}
              label={t('animator.dashboard.filter.company')}
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t('animator.dashboard.filter.options.all_companies'),
                },
                ...companiesFilterOptions,
              ]}
              onChange={onSelectChange}
            />
          </div>

          <div css={{ minWidth: '150px' }}>
            <InputSelect
              name="deployment_type"
              variant={SelectVariant.white}
              withError={false}
              label={t('animator.dashboard.filter.deployment_type')}
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t(
                    'animator.dashboard.filter.options.all_deployment_types',
                  ),
                },

                {
                  value: DeploymentType.Discovery,
                  label: t(
                    `animator.dashboard.filter.options.deployment_types.${DeploymentType.Discovery}`,
                  ),
                },

                {
                  value: DeploymentType.Rental,
                  label: t(
                    `animator.dashboard.filter.options.deployment_types.${DeploymentType.Rental}`,
                  ),
                },
              ]}
              onChange={onSelectChange}
            />
          </div>

          <div css={{ minWidth: '200px' }}>
            <InputSelect
              name="programStep"
              variant={SelectVariant.white}
              withError={false}
              label={t('animator.dashboard.filter.program_step')}
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t(
                    'animator.dashboard.filter.options.all_program_steps',
                  ),
                },
                ...programStepFilterOptions,
              ]}
              onChange={onSelectChange}
            />
          </div>

          <div css={{ flex: 1, marginLeft: 10, alignSelf: 'end' }}>
            <Input
              icon="magnifier"
              variant={TextVariant.white}
              name="multiFilter"
              withError={false}
              onChange={onMultiFilterChange}
            />
          </div>
        </div>

        <div css={{ minHeight: 380 }}>
          <Table
            columns={columns}
            data={formattedData}
            onSortBy={setOrderBy}
            loading={loading}
          />
        </div>

        <div
          css={{
            marginTop: '41px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Pagination
            currentPage={page}
            onClick={setPage}
            pageSize={PAGE_SIZE}
            totalData={countData?.deploymentsCount}
          />
        </div>
        <Flex
          direction="row"
          css={{
            position: 'absolute',
            right: '24px',
            bottom: '20px',
          }}
        >
          {props.archived && (
            <InputSelect<{ startDate: string; endDate: string }>
              options={rangesOptions}
              isSearchable={false}
              css={{ width: 120, marginRight: 10 }}
              onChange={setDownloadRange}
              maxMenuHeight={100}
            />
          )}
          {data?.deployments && (
            <DownloadCSVButton
              getData={props.archived ? getExportData : undefined}
              data={
                props.archived
                  ? undefined
                  : formatDeploymentExport(data.deployments as any[])
              }
              filename={`${props.title
                .replaceAll(/\s+/g, '-')
                .toLowerCase()}.csv`}
            />
          )}
        </Flex>
      </CardFoldable>
    </div>
  )
}
