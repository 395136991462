/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'

import Icon, { IconTypes } from './Icon'
import useVariant from '../hooks/useVariant'
import {
  TextVariant,
  TextVariantsConfig,
} from '../hooks/useVariant/variants/text'

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined

export interface InputProps {
  name?: string
  value?: string
  description?: string
  variant?: TextVariant
  icon?: IconTypes
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
  type?: 'text' | 'password' | 'number'
  onlyNumbers?: boolean
  onlyFloat?: boolean
  placeholder?: string
  error?: boolean
  disabled?: boolean
  noPasswordPreview?: boolean
  block?: boolean
  label?: string
  helperText?: string
  // RefReturn type is needed for react-hook-form package
  register?: () => RefReturn
  withError?: boolean
  compact?: boolean
  center?: boolean
  subLabel?: string
  ultraCompact?: boolean
}

type Props = InputProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >

const Input: React.FC<Props> = ({
  name,
  label,
  value,
  icon,
  description,
  variant = TextVariant.gray,
  onChange: onChangeProp,
  error,
  disabled,
  placeholder,
  type = 'text',
  block = true,
  noPasswordPreview,
  helperText,
  onlyNumbers = false,
  withError = true,
  register,
  compact,
  ultraCompact,
  subLabel,
  center,
  ...props
}) => {
  const theme = useTheme()
  const textVariantConfig = useVariant('text')[
    variant
  ] as TextVariantsConfig[typeof variant]
  const [showPassword, setShowPassword] = React.useState(false)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const onlyNumbersRegex = /\D*/g

    if (onlyNumbers)
      e.target.value = e.target.value.replace(onlyNumbersRegex, '')
    if (onChangeProp) onChangeProp(e)
  }

  return (
    <div
      css={{
        width: block ? '100%' : 'fit-content',
      }}
    >
      {label && (
        <div
          css={{
            marginLeft: 21,
            marginBottom: 4,
          }}
        >
          <label
            css={{
              fontSize: '1.6rem',
              color: theme.colors.gray3,
            }}
          >
            {label}
          </label>
        </div>
      )}
      {description && (
        <div
          css={{
            marginLeft: 21,
            marginTop: '-2px',
            marginBottom: '4px',
          }}
        >
          <label
            css={{
              fontSize: '1.2rem',
              color: theme.colors.gray3,
            }}
          >
            {description}
          </label>
        </div>
      )}
      <div
        css={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 44,
          backgroundColor: textVariantConfig.bckgColor,
          borderRadius: 22,
          boxSizing: 'border-box',
          opacity: disabled ? 0.3 : 1,
          border: error
            ? `2px solid ${theme.colors.error}`
            : textVariantConfig.border,
          transition: 'border 0.2s ease-in',
          paddingRight:
            !noPasswordPreview && type === 'password' ? '44px' : '21px',
          paddingLeft: icon ? '50px' : '21px',
          ...(ultraCompact
            ? { minWidth: '124px' }
            : compact
            ? { maxWidth: '32rem' }
            : {}),
          ...(center ? { margin: 'auto' } : {}),
        }}
      >
        {icon ? (
          <div
            css={{
              position: 'absolute',
              top: '10px',
              left: '15px',
            }}
          >
            <Icon type={icon} color={theme.colors.gray4} />
          </div>
        ) : null}
        <input
          size={1}
          css={{
            ':read-only': {
              color: theme.colors.gray4,
            },
            flex: 1,
            fontFamily: 'Roboto',
            fontSize: '1.6rem',
            boxSizing: 'border-box',
            padding: '10px 0px 11px',
            border: 'none',
            outline: 'none',
            color: disabled ? theme.colors.gray3 : theme.colors.gray1,
            backgroundColor: 'transparent',
            transition: 'color 0.2s ease-in',
            overflow: 'hidden',
            boxShadow: 'none',
            '::placeholder': {
              fontStyle: 'italic',
              fontWeight: 300,
              color: theme.colors.gray3,
            },
            ...(center ? { textAlign: 'center' } : {}),
          }}
          id={name}
          placeholder={placeholder}
          type={showPassword ? 'text' : type}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          ref={register}
          {...props}
        />
        <div>
          <p css={{ fontSize: '16px', color: theme.colors.gray3, zIndex: 20 }}>
            {subLabel}
          </p>
        </div>
        {!noPasswordPreview && type === 'password' && (
          <div
            css={{
              cursor: 'pointer',
              position: 'absolute',
              top: 8,
              right: 11,
              userSelect: 'none',
            }}
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon type="fullEye" size="medium" color={theme.colors.gray3} />
          </div>
        )}
      </div>
      {withError && (
        <div
          css={{
            marginLeft: 8,
            height: 20,
          }}
        >
          {helperText && (
            <span
              css={{
                fontFamily: 'Roboto',
                fontSize: '1.2rem',
                color: error ? theme.colors.error : theme.colors.gray3,
              }}
            >
              {helperText}
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default Input
