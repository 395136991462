/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'

import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'

import Badge from '../../../../components/Badge'
import { useTranslation } from 'react-i18next'

interface Props {
  eligible: boolean
}

const SpecialBikesStatus: React.FC<Props> = ({ eligible }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Card css={{ height: '100%' }}>
      <div
        css={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <div css={{ marginBottom: 12, position: 'relative' }}>
          <div>
            <Icon
              type="foldingBike"
              customSize={{ width: 58, height: 58 }}
              color="black"
              style={{ marginRight: 8 }}
            />

            <Icon
              type="cargoBike"
              customSize={{ width: 105, height: 58 }}
              color="black"
            />
          </div>
          <div css={{ position: 'absolute', right: 0, top: 0 }}>
            <Icon
              type={eligible ? 'tick' : 'blockDocument'}
              customSize={{ width: 38, height: 38 }}
              color="white"
            />
          </div>
        </div>
        <Badge
          backgroundColor={theme.colors[eligible ? 'success' : 'error']}
          fontColor={theme.colors.white}
          label={
            eligible
              ? t('animator.company.progress.eligibleToSpecialBikes.eligible')
              : t(
                  'animator.company.progress.eligibleToSpecialBikes.notEligible',
                )
          }
        />
      </div>
    </Card>
  )
}

export default SpecialBikesStatus
