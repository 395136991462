import { gql, QueryResult, useQuery } from '@apollo/client'
import {
  GetAllCompanyEmployeesQuery,
  GetAllCompanyEmployeesQueryVariables,
} from '../../../../__generated__/graphql'

export const COMPANY_EMPLOYEES_QUERY = gql`
  query GetAllCompanyEmployees($id: String!) {
    company(where: { id: $id }) {
      employees {
        id
        firstName
        lastName
        avatarUrl
        civility
        city
        street
        postalCode
        birthdate
        cityOfBirth
        country
        height
        phoneNumber
        workTravelDistance
        workTravelDuration
        user {
          id
          email
          emailVerified
          archived
        }
        sites {
          id
          name
        }
        travelMode {
          name
        }
        employeeBikeHabit {
          name
        }
        bikeParkingType {
          name
        }
        employeeDeployments {
          id
          status
          deployment {
            id
            name
            deploymentType
          }
          chosenBikeModel {
            id
            remainingPrice
          }
          bikeLoan {
            bikeReturned
            retentionOnDeposit
          }
        }
      }
    }
  }
`

export const useCompanyEmployees = (
  id: string,
): QueryResult<
  GetAllCompanyEmployeesQuery,
  GetAllCompanyEmployeesQueryVariables
> => {
  return useQuery<
    GetAllCompanyEmployeesQuery,
    GetAllCompanyEmployeesQueryVariables
  >(COMPANY_EMPLOYEES_QUERY, {
    variables: { id },
  })
}
