/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { gql, useMutation } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Modal from '@goodwatt/client/src/components/Modal'
import Popup from '@goodwatt/client/src/components/Popup'
import Input from '@goodwatt/client/src/components/Input'
import Button from '@goodwatt/client/src/components/Button'
import {
  useNotifications,
  NotificationTypes,
} from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import {
  PostTableModalActions,
  PostTableAdd,
  ModalPostTagSubmit,
  PostTagTableRow,
} from '@goodwatt/client/src/types/AnimatorPosts'
import {
  CreateOneAdviceTagMutation,
  CreateOneAdviceTagMutationVariables,
  UpdateOneAdviceTagMutation,
  UpdateOneAdviceTagMutationVariables,
} from '../../../../__generated__/graphql'

const schema = yup.object().shape({
  key: yup.string().required(''),
  order: yup.number().required(''),
})

const MUTATION_UPDATE_ADVICE_TAG = gql`
  mutation UpdateOneAdviceTag(
    $data: AdviceTagUpdateInput!
    $where: AdviceTagWhereUniqueInput!
  ) {
    updateOneAdviceTag(data: $data, where: $where) {
      key
      order
    }
  }
`
const MUTATION_CREATE_ADVICE_TAG = gql`
  mutation CreateOneAdviceTag($data: AdviceTagCreateInput!) {
    createOneAdviceTag(data: $data) {
      id
    }
  }
`

interface AddOrEditModalProps {
  isOpen: boolean
  post: PostTagTableRow
  type: '' | PostTableAdd | PostTableModalActions
  closeModal: () => void
  updateList: () => void
}

const AddOrEditModal: React.FC<AddOrEditModalProps> = ({
  isOpen,
  closeModal,
  post,
  type,
  updateList,
}) => {
  const notifications = useNotifications()
  const { t } = useTranslation()

  const { register, handleSubmit, errors, control, reset } =
    useForm<PostTagTableRow>({
      resolver: yupResolver(schema),
    })

  React.useEffect(() => reset(), [post, reset, type])

  const [update, { loading }] = useMutation<
    UpdateOneAdviceTagMutation,
    UpdateOneAdviceTagMutationVariables
  >(MUTATION_UPDATE_ADVICE_TAG)

  const [create, { loading: createLoading }] = useMutation<
    CreateOneAdviceTagMutation,
    CreateOneAdviceTagMutationVariables
  >(MUTATION_CREATE_ADVICE_TAG)

  const onSubmit = async (data: ModalPostTagSubmit) => {
    const handleSuccess = () => {
      updateList()
      closeModal()
      notifications.newNotification({
        type: NotificationTypes.SUCCESS,
        message: t(`animator.post.modal.${type}.successNotification`),
      })
    }

    if (type === PostTableAdd.ADD) {
      try {
        await create({
          variables: {
            data: {
              key: data.key,
              order: data.order,
            },
          },
        })
        handleSuccess()
      } catch (error) {
        apiErrorCatcher(notifications)
      }
    } else {
      return update({
        variables: {
          where: {
            id: post.id,
          },
          data: {
            key: {
              set: data.key,
            },
            order: {
              set: data.order,
            },
          },
        },
      })
        .then(handleSuccess)
        .catch(apiErrorCatcher(notifications))
    }
  }
  return (
    <Modal isOpen={isOpen}>
      <Popup
        closable
        title={
          type === PostTableAdd.ADD
            ? t(`admin.advice.modal.createAdviceTag`)
            : t(`admin.advice.modal.updateAdviceTag`)
        }
        separator={false}
        onClose={closeModal}
        maxWidth={640}
        footer={
          <React.Fragment>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="tertiary"
                submit
                loading={loading || createLoading}
                onClick={closeModal}
              >
                {t('forms.button.cancel')}
              </Button>
            </div>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="primary"
                submit
                loading={loading || createLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t('forms.button.confirm')}
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <div css={{ padding: '30px 54px 38px' }}>
          <form>
            <div>
              <Controller
                name="key"
                control={control}
                defaultValue={post.key}
                render={({ onChange, value }) => {
                  return (
                    <Input
                      label={t('admin.advice.tagForm.label')}
                      onChange={onChange}
                      register={register}
                      value={value}
                      error={!!errors.key}
                      helperText={errors?.key?.message}
                    />
                  )
                }}
              />
            </div>
            <div>
              <Controller
                name="order"
                control={control}
                defaultValue={post.order}
                render={({ onChange, value }) => {
                  return (
                    <Input
                      label={t('admin.advice.tagForm.order')}
                      type="number"
                      register={register}
                      onChange={onChange}
                      value={value}
                      error={!!errors.order}
                    />
                  )
                }}
              />
            </div>
          </form>
        </div>
      </Popup>
    </Modal>
  )
}

export default AddOrEditModal
