/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import Button from '@goodwatt/client/src/components/Button'
import {
  PostTableAdd,
  PostTableModalActions,
  PostTagSettings,
  PostTagTableRow,
} from '@goodwatt/client/src/types/AnimatorPosts'
import Table, { ColumnSortBy } from '@goodwatt/client/src/components/Table'

import AddOrEditModal from './AddOrEditModal'
import DeleteModal from './DeleteModal'
import {
  QUERY_ANIMATOR_GET_TAGS,
  QUERY_COUNT_POST_TAG,
} from '../../../../utils/gql/queries'

import { useTheme } from 'emotion-theming'
import PostTagActionCell from '../../../../components/Table/CustomCells/PostTagActionCell'
import Icon from '../../../../components/Icon'
import Pagination from '../../../../components/Pagination'
import {
  AdminTableRefetchContext,
  AdminTableTypes,
} from '../../AdminTableRefetchContext'
import { initialColumnSortBy } from '../PostList/PostList'
import {
  CountPostTagQuery,
  GetAdviceTagsQueryVariables,
  GetTagsQuery,
} from '../../../../__generated__/graphql'

const initalModalSettings: PostTagSettings = {
  tag: {
    id: '',
    key: '',
    order: undefined,
  },
  actionName: '',
}

interface AnimatorCompanyMonitoringProps {
  pageSize: number
}

const AnimatorCompanyMonitoringList: React.FC<
  AnimatorCompanyMonitoringProps
> = ({ pageSize }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [currModal, setCurrModal] =
    useState<PostTagSettings>(initalModalSettings)
  const [currPage, setCurrPage] = useState(1)

  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)
  const { data: tagCount } = useQuery<CountPostTagQuery>(QUERY_COUNT_POST_TAG)

  const {
    data: tagsData,
    loading,
    refetch,
  } = useQuery<GetTagsQuery, GetAdviceTagsQueryVariables>(
    QUERY_ANIMATOR_GET_TAGS,
    {
      fetchPolicy: 'network-only',
      variables: {
        skip: (currPage - 1) * pageSize,
        take: pageSize,
        orderBy:
          orderBy.sort && orderBy.columnName
            ? [{ [orderBy.columnName]: orderBy.sort }]
            : undefined,
      },
    },
  )

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<PostTagTableRow> }) => (
      <PostTagActionCell
        setModal={setCurrModal}
        row={row}
        actions={[PostTableModalActions.EDIT, PostTableModalActions.TRASH]}
      />
    ),
    [],
  )
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('admin.post.taglist.headers.label') as string,
        accessor: 'key',
        cellStyle: () => ({ minWidth: '400px' }),
      },
      {
        Header: t('admin.post.taglist.headers.order') as string,
        accessor: 'order',
        cellStyle: () => ({ textAlign: 'center', minWidth: '184px' }),
      },
      {
        Header: t('admin.post.taglist.headers.questionCount') as string,
        accessor: 'postCount',
        cellStyle: () => ({ textAlign: 'center', minWidth: '184px' }),
      },
      {
        Header: t('admin.post.taglist.headers.action') as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [ActionCell, t],
  )
  const formattedData = useMemo(() => {
    if (!tagsData?.postTags?.length) return []

    return tagsData?.postTags?.map(tag => ({
      id: tag?.id,
      key: tag?.key,
      order: tag?.order || undefined,
      postCount: tag?.posts.length || 0,
    }))
  }, [tagsData])

  const {
    removeTableToFetch,
    tableRefetchPlanner: { [AdminTableTypes.FAQ]: needToRefetch },
  } = React.useContext(AdminTableRefetchContext)

  React.useEffect(() => {
    if (needToRefetch) {
      refetch()
      removeTableToFetch(AdminTableTypes.FAQ)
    }
  }, [needToRefetch, refetch, removeTableToFetch])

  return (
    <React.Fragment>
      <div css={{ minHeight: 380 }}>
        <Table
          loading={loading}
          columns={columns}
          data={formattedData}
          onSortBy={setOrderBy}
        />
      </div>
      <div
        css={{
          position: 'relative',
          display: 'flex',
          marginTop: '41px',
          marginBottom: '31px',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={tagCount?.postTagCount}
        />
        <div
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            paddingLeft: 77,
            [theme.media.mobile]: {
              paddingLeft: 0,
            },
          }}
        >
          <Button
            type="primary"
            submit
            onClick={() =>
              setCurrModal({
                ...initalModalSettings,
                actionName: PostTableAdd.ADD,
              })
            }
          >
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontSize: '1.6rem',
                fontWeight: 500,
                color: theme.colors.white,
              }}
            >
              <Icon
                strokeColor="white"
                type="plus"
                customSize={{ width: '16px', height: '16px' }}
              />
            </span>
          </Button>
        </div>
        <AddOrEditModal
          post={currModal.tag}
          isOpen={
            currModal.actionName === PostTableAdd.ADD ||
            currModal.actionName === PostTableModalActions.EDIT
          }
          type={currModal.actionName}
          // companyId={companyId}
          closeModal={() => setCurrModal(initalModalSettings)}
          updateList={refetch}
        />
        <DeleteModal
          tag={currModal.tag}
          isOpen={currModal.actionName === PostTableModalActions.TRASH}
          closeModal={() => setCurrModal(initalModalSettings)}
          updateList={refetch}
        />
      </div>
    </React.Fragment>
  )
}

export default AnimatorCompanyMonitoringList
