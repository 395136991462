/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { useTheme } from 'emotion-theming'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../../redux/rootReducer'
import Switch from '../../../../../components/Switch'
import Checkbox from '../../../../../components/Checkbox'
import { SpecialBikesType } from '../../../../../__generated__/graphql'
import { useTranslation } from 'react-i18next'
import { setDeploymentForm } from '../../../../../redux/forms'
import { camelCase, snakeCase } from 'lodash'
import { QUERY_ALL_BIKE_MODEL_NAMES } from '../../../../../utils/gql/queries'
import { useQuery } from '@apollo/client'
import {
  GetAllBikeModelNamesQuery,
  GetAllBikeModelNamesQueryVariables,
} from '../../../../../__generated__/graphql'
import { DeploymentType } from '../../../../../__generated__/graphql'
import Input from '../../../../../components/Input'

type Step1Inputs = {
  cargoBike: boolean
  foldingBike: boolean
  urbanBike: boolean
  cargoBikeChildSeat: boolean
  urbanBikeChildSeat: boolean
}

const Step1: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const form = useSelector((state: StoreState) => state.forms.deploymentForm)

  const { data: bikeModelNames } = useQuery<
    GetAllBikeModelNamesQuery,
    GetAllBikeModelNamesQueryVariables
  >(QUERY_ALL_BIKE_MODEL_NAMES)

  const onChangeBikeChildSeat = async (bikeModelId: string) => {
    if (form.deploymentBikeModelPrice && form.programStepNbr < 5) {
      let bike = form.deploymentBikeModelPrice.find(
        bike => bike.bikeModelId === bikeModelId,
      )
      if (bike) {
        dispatch(
          setDeploymentForm({
            deploymentBikeModelPrice: [
              ...form.deploymentBikeModelPrice.filter(
                bike => bike.bikeModelId !== bikeModelId,
              ),
              {
                ...bike,
                checked: true,
                childSeat: !bike.childSeat,
              },
            ],
          }),
        )
      } else {
        dispatch(
          setDeploymentForm({
            deploymentBikeModelPrice: [
              ...form.deploymentBikeModelPrice,
              {
                id: '',
                checked: true,
                bikeModelId: bikeModelId,
                remainingPrice: 0,
                childSeat: true,
              },
            ],
          }),
        )
      }
    }
  }

  const onChangeBike = async (bikeModelId: string) => {
    if (form.deploymentBikeModelPrice && form.programStepNbr < 5) {
      let bike = form.deploymentBikeModelPrice.find(
        bike => bike.bikeModelId === bikeModelId,
      )
      if (bike) {
        dispatch(
          setDeploymentForm({
            deploymentBikeModelPrice: [
              ...form.deploymentBikeModelPrice.filter(
                bike => bike.bikeModelId !== bikeModelId,
              ),
              {
                ...bike,
                checked: !bike.checked,
              },
            ],
          }),
        )
      } else {
        dispatch(
          setDeploymentForm({
            deploymentBikeModelPrice: [
              ...form.deploymentBikeModelPrice,
              {
                id: '',
                checked: true,
                bikeModelId: bikeModelId,
                remainingPrice: 0,
                childSeat: false,
              },
            ],
          }),
        )
      }

      await typeCheck(bikeModelId, !bike?.checked || false)
    }
  }

  const onChangePrice = async (data: any, bikeModelId: string) => {
    if (form.deploymentBikeModelPrice && form.programStepNbr < 5) {
      let price = form.deploymentBikeModelPrice.find(
        price => price.bikeModelId === bikeModelId,
      )

      if (price) {
        dispatch(
          setDeploymentForm({
            deploymentBikeModelPrice: [
              ...form.deploymentBikeModelPrice.filter(
                price => price.bikeModelId !== bikeModelId,
              ),
              {
                ...price,
                remainingPrice: parseInt(data.target.value),
              },
            ],
          }),
        )
      } else {
        dispatch(
          setDeploymentForm({
            deploymentBikeModelPrice: [
              ...form.deploymentBikeModelPrice,
              {
                id: '',
                checked: true,
                bikeModelId: bikeModelId,
                remainingPrice: parseInt(data.target.value),
                childSeat: false,
              },
            ],
          }),
        )
      }
    }
  }

  const findPrice = (bikeModelId: string | undefined | null) => {
    if (form.deploymentBikeModelPrice) {
      let price = form.deploymentBikeModelPrice.find(
        price => price.bikeModelId === bikeModelId,
      )
      return price?.remainingPrice.toString()
    }
  }

  const typeCheck = async (bikeModelId: string, bikeChecked: boolean) => {
    let checked = false
    const bike = bikeModelNames?.bikeModels?.find(
      bike => bike.id === bikeModelId,
    )

    if (bike) {
      let bikeType = bike.bikeType
      let variable = camelCase(bikeType)

      if (bikeChecked === false) {
        const bikes = bikeModelNames?.bikeModels?.filter(
          bike => bike.bikeType === bikeType && bike.id !== bikeModelId,
        )

        if (bikes) {
          bikes.map(bike => {
            let currentbikeModelPrices = form.deploymentBikeModelPrice
            let bikeModelId = bike.id
            let bikePrice = currentbikeModelPrices?.find(
              price => price.bikeModelId === bikeModelId,
            )
            if (bikePrice?.checked === true) {
              checked = true
            }
          })
        }
      } else {
        checked = true
      }

      dispatch(
        setDeploymentForm({
          [variable]: checked,
        }),
      )
    }
  }

  const onCheckType = (bikeType: string, value: boolean) => {
    const bikes = bikeModelNames?.bikeModels?.filter(
      bike => bike.bikeType === snakeCase(bikeType).toUpperCase(),
    )
    let checked = !value
    let currentbikeModelPrices = form.deploymentBikeModelPrice

    if (bikes) {
      bikes.map(bike => {
        let bikeModelId = bike.id
        let bikePrice = currentbikeModelPrices?.find(
          price => price.bikeModelId === bikeModelId,
        )

        if (bikePrice) {
          currentbikeModelPrices = [
            ...currentbikeModelPrices.filter(
              price => price.bikeModelId !== bikeModelId,
            ),
            {
              ...bikePrice,
              checked: checked,
            },
          ]
        } else {
          currentbikeModelPrices = [
            ...currentbikeModelPrices,
            {
              id: '',
              checked: checked,
              bikeModelId: bikeModelId,
              remainingPrice: 0,
              childSeat: false,
            },
          ]
        }
      })

      dispatch(
        setDeploymentForm({
          [bikeType]: checked,
          deploymentBikeModelPrice: currentbikeModelPrices,
        }),
      )
    } else {
      dispatch(
        setDeploymentForm({
          [bikeType]: checked,
        }),
      )
    }
  }

  return (
    <div css={{ width: '100%', textAlign: 'initial' }}>
      <form>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <div
            css={{ height: '40px', paddingBottom: '20px', width: '50%' }}
          ></div>
          <div css={{ height: '40px', paddingBottom: '20px', width: '25%' }}>
            <label>{t('shared.babySeat.label')}</label>
          </div>
          {form.deploymentType === DeploymentType.Rental ? (
            <div css={{ height: '40px', paddingBottom: '20px', width: '25%' }}>
              <label>{t('shared.monthlyRent')}</label>
            </div>
          ) : (
            ''
          )}
        </div>

        {Object.values(SpecialBikesType).map((value, index) => {
          let variable = camelCase(`${Object.keys(SpecialBikesType)[index]}`)
          let tmpvalue = form[variable as keyof Step1Inputs]

          return (
            <Fragment key={index}>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '50%',
                    paddingLeft: '120px',
                    marginBottom: '10px',
                  }}
                >
                  <Checkbox
                    isChecked={tmpvalue === true ? true : false}
                    size="small"
                    color="#828282"
                    onClick={() => {
                      form.programStepNbr < 5 && onCheckType(variable, tmpvalue)
                    }}
                  ></Checkbox>
                  <label css={{ marginLeft: '10px' }}>
                    {t(`shared.specialBikes.abbreviation.${value}`)}
                  </label>
                </div>
              </div>

              {bikeModelNames ? (
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  {bikeModelNames.bikeModels
                    ?.filter(bike => bike.bikeType === value)
                    .map(bike => {
                      return (
                        <Fragment key={bike.id}>
                          <div
                            css={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '100%',
                              marginBottom: '4px',
                            }}
                          >
                            <div
                              css={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '50%',
                                paddingLeft: '150px',
                                marginBottom: '10px',
                              }}
                            >
                              <Checkbox
                                isChecked={
                                  form.deploymentBikeModelPrice?.find(
                                    price => price.bikeModelId === bike.id,
                                  )?.checked ?? false
                                }
                                size="small"
                                color="#828282"
                                onClick={() => onChangeBike(bike.id)}
                              ></Checkbox>
                              <label css={{ marginLeft: '10px' }}>
                                {bike.name}
                              </label>
                            </div>

                            <div
                              css={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '25%',
                                marginBottom: '10px',
                              }}
                            >
                              {bike.childSeatCompatibility ? (
                                <Switch
                                  isChecked={
                                    form.deploymentBikeModelPrice?.find(
                                      price => price.bikeModelId === bike.id,
                                    )?.childSeat ?? false
                                  }
                                  onClick={() => onChangeBikeChildSeat(bike.id)}
                                />
                              ) : (
                                <label css={{ color: theme.colors.gray3 }}>
                                  {t('shared.babySeat.unavailable')}
                                </label>
                              )}
                            </div>

                            {form.deploymentType === DeploymentType.Rental ? (
                              <div
                                css={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '25%',
                                }}
                              >
                                <Input
                                  type="text"
                                  value={findPrice(bike.id)}
                                  placeholder="€ TTC/mois"
                                  onlyNumbers
                                  block={true}
                                  onChange={e => onChangePrice(e, bike.id)}
                                  withError={false}
                                  readOnly={form.programStepNbr > 4}
                                  ultraCompact
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </Fragment>
                      )
                    })}
                </div>
              ) : (
                ''
              )}
            </Fragment>
          )
        })}
      </form>
    </div>
  )
}

export default Step1
