/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import Modal from '../../../../../components/Modal'
import Popup from '../../../../../components/Popup'
import Button from '../../../../../components/Button'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../../contexts/NotificationContext'
import theme from '../../../../../styles/theme'
import i18n from '../../../../../i18n/config'
import { MUTATION_UNARCHIVE_COMPANY } from '../../../../../utils/gql/mutations'
import apiErrorCatcher from '../../../../../utils/apiErrorCatcher'

import { ArchivedCompaniesTableRow } from '../../../../../types/AnimatorCompanies'

import { addDays, parseISO } from 'date-fns'

import { shortDayDate } from '../../../../../utils/Date'
import {
  UnArchiveCompanyMutation,
  UnArchiveCompanyMutationVariables,
} from '../../../../../__generated__/graphql'

const ARCHIVAL_DAYS_DIFF = 7

interface ArchiveCompanyModalProps {
  isOpen: boolean
  company: Partial<ArchivedCompaniesTableRow>
  closeModal: () => void
  refetch: () => void
}

const CompanyUnArchiveModal: React.FC<ArchiveCompanyModalProps> = ({
  company,
  isOpen,
  closeModal = () => null,
  refetch,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()

  const [unArchiveCompany, { loading }] = useMutation<
    UnArchiveCompanyMutation,
    UnArchiveCompanyMutationVariables
  >(MUTATION_UNARCHIVE_COMPANY, {
    refetchQueries: ['companiesWithDeployments'],
  })

  const onSubmit = async () => {
    if (loading) return
    if (!company?.id) return

    await unArchiveCompany({
      variables: {
        id: company.id,
      },
    })
      .then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: i18n.t(
            'animator.companies.unArchiveCompany.successNotification',
            {
              companyName: company.name,
            },
          ),
        })
        refetch()
        closeModal()
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        separator={false}
        maxWidth={750}
        css={{ color: theme.colors.primary }}
        titleColor={theme.colors.primary}
        title={t('animator.companies.unArchiveCompany.title')}
        onClose={closeModal}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Button type="tertiary" onClick={closeModal}>
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginRight: 16 }} />
            <Button type="primary" loading={loading} onClick={onSubmit}>
              {t('forms.button.dearchive')}
            </Button>
          </div>
        }
      >
        <p
          css={{
            textAlign: 'center',
            marginBottom: 24,
            marginTop: 12,
            marginLeft: 48,
            marginRight: 48,
          }}
          dangerouslySetInnerHTML={{
            __html: t('animator.companies.unArchiveCompany.description', {
              companyName: company?.name,
              endDate: company?.archivedAt
                ? shortDayDate(
                    addDays(parseISO(company?.archivedAt), ARCHIVAL_DAYS_DIFF),
                  )
                : new Date(),
            }),
          }}
        ></p>
      </Popup>
    </Modal>
  )
}

export default CompanyUnArchiveModal
