import React from 'react'
import Button from '../../../../../../components/Button'

interface ReleaseDepositButtonProps {
  companyId: string
  onClick: () => void
}

const ReleaseDepositButton: React.FC<ReleaseDepositButtonProps> = ({
  onClick,
}) => {
  return <Button icon="releaseDeposit" onClick={onClick} />
}

export default ReleaseDepositButton
