/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import InputSelect from '../../../components/InputSelect'
import Input from '../../../components/Input'
import Modal from '../../../components/Modal'
import i18n from '../../../i18n/config'
import Button from '../../../components/Button'
import EditCompanyAvatar from '../EditAvatar'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import {
  NotificationTypes,
  useNotifications,
} from '../../../contexts/NotificationContext'

import Popup from '../../../components/Popup'

import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import { EmployeeCivility, UserRoles } from '@goodwatt/shared/dist/types'
import { updateUserName, updateCompanyName } from '../../../redux/user'
import Checkbox from '../../../components/Checkbox'
import {
  CompanyType,
  Civility,
  GetCompanyProfileQuery,
  Role,
  UpdateOneCompanyAndUpdateOneReferrerMutation,
  UpdateOneCompanyAndUpdateOneReferrerMutationVariables,
} from '../../../__generated__/graphql'

const MUTATION_UPDATE_ONE_COMPANY_AND_ONE_REFERRER = gql`
  mutation UpdateOneCompanyAndUpdateOneReferrer(
    $whereCompany: CompanyWhereUniqueInput!
    $dataCompany: CompanyUpdateInput!
    $whereReferrer: CompanyReferrerWhereUniqueInput!
    $dataReferrer: CompanyReferrerUpdateInput!
  ) {
    updateOneCompany(data: $dataCompany, where: $whereCompany) {
      id
    }
    updateOneCompanyReferrer(data: $dataReferrer, where: $whereReferrer) {
      id
    }
  }
`

const schema = yup.object().shape({
  name: yup.string().required(i18n.t('forms.errors.required')),
  companyType: yup.object().required(),
  civility: yup.string().required(i18n.t('forms.errors.required')),
  firstName: yup.string().required(i18n.t('forms.errors.required')),
  lastName: yup.string().required(i18n.t('forms.errors.required')),
  phoneNumber: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .length(10, i18n.t('forms.errors.phoneFormat')),
})

interface EditCompanyProfileForm {
  name: string
  companyType: {
    value: CompanyType
    label: string
  }
  civility: Civility
  firstName: string
  lastName: string
  phoneNumber: string
}

export enum AnchorEditCompanyProfileType {
  COMPANY_INFO = 'companyInfo',
  COMPANY_REFEREE_INFO = 'companyRefereeInfo',
  COMPANY_ADDRESS = 'companyAddress',
}

const scrollIntoViewSettings: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'start',
}

interface EditCompanyModalProps {
  modalIsOpen: boolean
  closeModal: () => void
  data: GetCompanyProfileQuery
  companyId: string
  refetch: () => void
  anchorOnOpen?: AnchorEditCompanyProfileType
}

const EditCompanyModal: React.FC<EditCompanyModalProps> = ({
  data,
  ...props
}) => {
  const dispatch = useDispatch()

  const { role: connectedUserRole, id: userId } = useSelector(
    (state: StoreState) => state.user,
  )
  /* ANCHORS FOR EDIT MODALS */
  const companyInfoRef = React.useRef<HTMLDivElement>(null)
  const companyRefereeInfoRef = React.useRef<HTMLDivElement>(null)
  const companyAddressRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (props.modalIsOpen) {
      if (props.anchorOnOpen === AnchorEditCompanyProfileType.COMPANY_INFO) {
        companyInfoRef?.current?.scrollIntoView(scrollIntoViewSettings)
      } else if (
        props.anchorOnOpen === AnchorEditCompanyProfileType.COMPANY_REFEREE_INFO
      ) {
        companyRefereeInfoRef?.current?.scrollIntoView(scrollIntoViewSettings)
      } else if (
        props.anchorOnOpen === AnchorEditCompanyProfileType.COMPANY_ADDRESS
      ) {
        companyAddressRef.current?.scrollIntoView(scrollIntoViewSettings)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalIsOpen])

  const notifications = useNotifications()
  const { t } = useTranslation()
  const { register, handleSubmit, errors, control } =
    useForm<EditCompanyProfileForm>({
      resolver: yupResolver(schema),
      mode: 'onBlur',
    })
  const [updateCompany, { loading }] = useMutation<
    UpdateOneCompanyAndUpdateOneReferrerMutation,
    UpdateOneCompanyAndUpdateOneReferrerMutationVariables
  >(MUTATION_UPDATE_ONE_COMPANY_AND_ONE_REFERRER)

  let companyReferrer = data.company?.companyReferrers.find(
    ref => ref.mainReferrer === true,
  )

  if (connectedUserRole === UserRoles.COMPANY) {
    companyReferrer = data.company?.companyReferrers.find(
      ref => ref.user.id === userId,
    )
  }

  const defaultCompanyType =
    data.company?.companyType !== null
      ? {
          value: data.company?.companyType,
          label: t(`shared.companyTypeLabel.${data.company?.companyType}`),
        }
      : undefined

  const onSubmit = (data: EditCompanyProfileForm) => {
    updateCompany({
      variables: {
        whereCompany: { id: props.companyId },
        dataCompany: {
          name: { set: data.name },
          companyType: { set: data.companyType.value },
        },
        whereReferrer: { id: companyReferrer!.id },
        dataReferrer: {
          firstName: { set: data.firstName },
          lastName: { set: data.lastName },
          civility: { set: data.civility },
          phoneNumber: { set: data.phoneNumber },
        },
      },
    })
      .then(result => {
        if (result.data?.updateOneCompany?.id) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.notification.profileSuccessUpdate'),
          })
          if (connectedUserRole === UserRoles.COMPANY) {
            dispatch(
              updateUserName({
                name: `${data.firstName} ${data.lastName.toUpperCase()}`,
              }),
            )
            dispatch(
              updateCompanyName({ companyName: data.name.toUpperCase() }),
            )
          }
          props.refetch()
          props.closeModal()
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={props.modalIsOpen}>
      <Popup
        maxWidth={750}
        title={t('company.profile.editModalTitle')}
        onClose={props.closeModal}
        footer={
          <Fragment>
            <Button type="tertiary" onClick={props.closeModal}>
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginRight: 40 }} />
            <Button
              loading={loading}
              type="primary"
              onClick={handleSubmit(onSubmit)}
              submit
            >
              {t('forms.button.save')}
            </Button>
          </Fragment>
        }
      >
        <div ref={companyInfoRef} />
        <div
          css={{
            visibility:
              props.anchorOnOpen === AnchorEditCompanyProfileType.COMPANY_INFO
                ? 'visible'
                : 'hidden',
            height:
              props.anchorOnOpen === AnchorEditCompanyProfileType.COMPANY_INFO
                ? 'auto'
                : 0,
          }}
        >
          <EditCompanyAvatar
            avatarUrl={data.company?.avatarUrl}
            resourceId={props.companyId}
            role={Role.Company}
            refetch={props.refetch}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div ref={companyAddressRef} />

          <div
            css={{
              width: '100%',
              padding: '0 24px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              css={{
                width: '100%',
                visibility:
                  props.anchorOnOpen ===
                  AnchorEditCompanyProfileType.COMPANY_INFO
                    ? 'visible'
                    : 'hidden',
                height:
                  props.anchorOnOpen ===
                  AnchorEditCompanyProfileType.COMPANY_INFO
                    ? 'auto'
                    : 0,
              }}
            >
              <Input
                type="text"
                name="name"
                defaultValue={data.company?.name}
                label={t('forms.label.companyName')}
                register={register}
                error={!!errors.name}
                helperText={errors.name?.message}
              />

              <div css={{ marginBottom: '24px' }} />

              <Controller
                name="companyType"
                control={control}
                defaultValue={defaultCompanyType}
                render={({ onChange, onBlur, value, name }) => (
                  <InputSelect
                    name={name}
                    label={t('forms.label.companyType')}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    options={
                      [
                        CompanyType.Company,
                        CompanyType.Association,
                        CompanyType.Colectivity,
                        CompanyType.Healthcare,
                        CompanyType.PublicAdministration,
                        CompanyType.ClubCompany,
                        CompanyType.Other,
                      ].map((value: string) => ({
                        value,
                        label: t(`shared.companyTypeLabel.${value}`),
                      })) as any
                    }
                    error={!!errors.companyType}
                    helperText={
                      errors.companyType ? t('forms.errors.chooseAnOption') : ''
                    }
                  />
                )}
              />

              <div css={{ marginBottom: '24px' }} />
            </div>

            <div ref={companyRefereeInfoRef} />

            <div
              css={{
                width: '100%',
                visibility:
                  props.anchorOnOpen !==
                  AnchorEditCompanyProfileType.COMPANY_INFO
                    ? 'visible'
                    : 'hidden',
                height:
                  props.anchorOnOpen !==
                  AnchorEditCompanyProfileType.COMPANY_INFO
                    ? 'auto'
                    : 0,
                overflow:
                  props.anchorOnOpen !==
                  AnchorEditCompanyProfileType.COMPANY_INFO
                    ? 'unset'
                    : 'hidden',
              }}
            >
              <Input
                type="text"
                name="firstName"
                label={t('forms.label.firstNameReferrer')}
                defaultValue={companyReferrer?.firstName}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                register={register}
              />
              <Input
                type="text"
                name="lastName"
                label={t('forms.label.lastNameReferrer')}
                defaultValue={companyReferrer?.lastName}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                register={register}
              />

              <span
                style={{
                  marginLeft: 21,
                  marginBottom: 12,
                  fontSize: '1.6rem',
                  color: '#828282',
                  alignSelf: 'flex-start',
                }}
              >
                {t('forms.label.civility')}
              </span>

              <Controller
                name="civility"
                control={control}
                defaultValue={companyReferrer?.civility}
                render={({ onChange, value }) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      gap: 48,
                      marginLeft: 21,
                    }}
                  >
                    <Checkbox
                      isChecked={value === EmployeeCivility.MISTER}
                      label="Monsieur"
                      size="medium"
                      error={!!errors.civility}
                      onClick={() => onChange(EmployeeCivility.MISTER)}
                    ></Checkbox>
                    <Checkbox
                      isChecked={value === EmployeeCivility.MISTRESS}
                      label="Madame"
                      size="medium"
                      error={!!errors.civility}
                      onClick={() => onChange(EmployeeCivility.MISTRESS)}
                    ></Checkbox>
                  </div>
                )}
              />

              <div css={{ marginBottom: '24px' }} />

              <Input
                type="text"
                name="email"
                label={t('forms.label.emailReferrer')}
                defaultValue={companyReferrer?.email}
                disabled
                // error={!!errors.email}
                // helperText={errors.email?.message}
                // register={register}
              />
              <Input
                type="text"
                name="phoneNumber"
                label={t('forms.label.phoneNumberReferrer')}
                defaultValue={companyReferrer?.phoneNumber}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
                register={register}
                onlyNumbers
                maxLength={10}
              />
            </div>
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default EditCompanyModal
