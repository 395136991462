import { AreaSearch } from '../../__generated__/graphql'

export enum AreaTableModal {
  EDIT = 'edit',
  LOCATE = 'locate',
  DELETE = 'delete',
  ADD = 'add',
}
export interface AreaModalSettings<D = AreaSearch, T = AreaTableModal> {
  area: D | null
  actionName: '' | T
}
