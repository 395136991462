/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'

import Card from '@goodwatt/client/src/components/Card'
import List from './List'
const PAGESIZE = 100

const CompanyMonitoring: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goodwatt - FAQ</title>
        <link rel="canonical" href="https://goodwatt.fr/animator/post" />
      </Helmet>
      <Card
        title={
          <span
            css={{
              fontFamily: theme.fontFamily,
              fontWeight: 'bold',
              fontSize: '1.6rem',
              paddingBottom: '1rem',
            }}
          >
            {t('animator.post.dashboardTitleLabel')}
          </span>
        }
      >
        <List pageSize={PAGESIZE} />
      </Card>
    </div>
  )
}

export default CompanyMonitoring
