import { GetAnimatorsQuery } from '../../__generated__/graphql'

export const formatAnimatorExport = (
  aniamtorExportData: GetAnimatorsQuery | undefined,
): any => {
  if (!aniamtorExportData?.animators?.length) return []

  const header = ['Prenom', 'Nom', 'E-mail', "Zone d'affectation"]

  return [
    header,
    ...aniamtorExportData?.animators.map(animator => ({
      firstName: animator.firstName,
      lastName: animator.lastName,
      email: animator.user.email,
      zone: animator.area.name,
    })),
  ]
}
