import React from 'react'

export enum TableTypes {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  REFUSED = 'refused',
  ARCHIVED = 'archived',
}

const initalContext: TableRefetchContextType = {
  refetchTables: () => null,
  removeTableToFetch: () => null,
  tableRefetchPlanner: {
    [TableTypes.ACCEPTED]: false,
    [TableTypes.PENDING]: false,
    [TableTypes.REFUSED]: false,
    [TableTypes.ARCHIVED]: false,
  },
}

export type TableRefetchContextType = {
  refetchTables: (tables: TableTypes[]) => void
  removeTableToFetch: (table: TableTypes) => void
  tableRefetchPlanner: { [tables in TableTypes]: boolean }
}

export const TableRefetchContext =
  React.createContext<TableRefetchContextType>(initalContext)

const TableRefetchProvider: React.FC = ({ children }) => {
  const [needToRefetch, setNeedToRefetch] = React.useState<TableTypes[]>([])

  const tableRefetchPlanner = React.useMemo(
    () => ({
      [TableTypes.ACCEPTED]: needToRefetch.some(
        table => table === TableTypes.ACCEPTED,
      ),
      [TableTypes.PENDING]: needToRefetch.some(
        table => table === TableTypes.PENDING,
      ),
      [TableTypes.REFUSED]: needToRefetch.some(
        table => table === TableTypes.REFUSED,
      ),
      [TableTypes.ARCHIVED]: needToRefetch.some(
        table => table === TableTypes.ARCHIVED,
      ),
    }),
    [needToRefetch],
  )

  const removeTableToFetch = React.useCallback((table: TableTypes) => {
    setNeedToRefetch(prevState => prevState.filter(t => t !== table))
  }, [])

  const refetchTables = React.useCallback((tablesToRefetch: TableTypes[]) => {
    setNeedToRefetch(tablesToRefetch)
  }, [])

  return (
    <TableRefetchContext.Provider
      value={{ refetchTables, tableRefetchPlanner, removeTableToFetch }}
    >
      {children}
    </TableRefetchContext.Provider>
  )
}

export default TableRefetchProvider
