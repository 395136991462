/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useTranslation } from 'react-i18next'

import Avatar from '../../components/Avatar'
import { MUTATION_UPLOAD_AVATAR } from '../../utils/gql/mutations'

import { avatarSchema } from '../../utils/schemaValidation'
import {
  NotificationTypes,
  useNotifications,
} from '../../contexts/NotificationContext'
import apiErrorCatcher from '../../utils/apiErrorCatcher'
import { displayAvatar } from '../../utils/files'
import {
  Role,
  UploadAvatarMutation,
  UploadAvatarMutationVariables,
} from '../../__generated__/graphql'

interface EditAvatarProps {
  avatarUrl?: string
  resourceId: string
  refetch?: (newData?: { avatarUrl: string | null }) => void
  role: Role
}

interface FormInput {
  avatar: FileList | null
}

const EditAvatar: React.FC<EditAvatarProps> = ({
  avatarUrl,
  resourceId,
  role,
  refetch,
}) => {
  const notifications = useNotifications()
  const { t } = useTranslation()
  const [uploadAvatar, { loading }] = useMutation<
    UploadAvatarMutation,
    UploadAvatarMutationVariables
  >(MUTATION_UPLOAD_AVATAR)
  const { register, handleSubmit, errors, reset } = useForm<FormInput>({
    resolver: yupResolver(avatarSchema),
    mode: 'onBlur',
  })

  useEffect(() => {
    if (errors?.avatar?.message)
      notifications.newNotification({
        type: NotificationTypes.ERROR,
        message: errors.avatar.message,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const onSubmit = async (data: FormInput) => {
    await uploadAvatar({
      variables: {
        file: data.avatar && data.avatar[0],
        role: role,
        id: resourceId,
      },
    })
      .then(({ data }) => {
        if (data?.uploadAvatar) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.notification.avatarSuccessUpdate'),
          })
          // @ts-ignore
          refetch?.({ avatarUrl: data.uploadAvatar.avatarUrl })
          reset()
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div css={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          css={{
            width: '160px',
            height: '160px',
            marginBottom: 24,
            alignSelf: 'center',
          }}
        >
          <Avatar
            name="avatar"
            loading={loading}
            register={register}
            onChange={handleSubmit(onSubmit)}
            imgUrl={displayAvatar(avatarUrl)}
          />
        </div>
      </div>
    </form>
  )
}

export default EditAvatar
