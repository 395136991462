import { useMemo } from 'react'

export const useLocalPagination = <T>(
  variables: { skip: number; take: number },
  arr: T[] | undefined,
): T[] | undefined => {
  return useMemo(() => {
    if (arr == null) return undefined
    return arr.slice(variables.skip, variables.skip + variables.take)
  }, [variables, arr])
}
