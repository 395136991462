/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useModal } from '../../../../hooks/useModal'
import DeleteUserModal from './DeleteUserModal'

export interface DeleteUserButtonProps {
  id: string
  disabled?: boolean
}

export const DeleteUserButton: React.FC<DeleteUserButtonProps> = ({
  id,
  disabled,
}: DeleteUserButtonProps) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      {modalProps.isOpen && <DeleteUserModal id={id} {...modalProps} />}
      <button
        onClick={onOpen}
        disabled={disabled}
        css={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <Icon
          color="transparent"
          strokeColor={disabled ? 'grey' : 'black'}
          type="trash"
        />
      </button>
    </Fragment>
  )
}
