/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FleetModalLineBike,
  FleetModalListProps,
} from '../../../../types/AnimatorFleets'
import BikeLineInput from './BikeLineInput'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'

interface LineError {
  index: string
  error: boolean
}

const BikeListInput: React.FC<FleetModalListProps> = ({
  numberOfRow,
  bikeModels,
  bikes,
  onBikesChange,
  bikeErrors,
}) => {
  const { t } = useTranslation()
  const [linesErrors, setLineErrors] = useState<LineError[]>([])
  const theme = useTheme()

  const initialBike: FleetModalLineBike = {
    stickerId: undefined,
    morioId: '',
    bicycode: '',
    keysId: '',
    modelId: bikeModels[0]?.id,
    id: '',
  }

  const onBikeChange = (bike: FleetModalLineBike) => {
    let stateCopy = [...bikes]
    const index = stateCopy.findIndex(b => b.id === bike.id)
    if (index !== -1) {
      if (
        Number(bike.id) &&
        !bike.stickerId &&
        !bike.morioId &&
        !bike.bicycode &&
        !bike.keysId
      )
        stateCopy = stateCopy.filter(b => b.id !== bike.id)
      else stateCopy[index] = bike
    } else {
      if (bike.stickerId || bike.morioId || bike.bicycode || bike.keysId)
        stateCopy.push(bike)
    }
    onBikesChange(stateCopy)
  }

  const onError = React.useCallback(
    (index: string, isError: boolean) => {
      const errors = linesErrors.filter(x =>
        bikes.find(bike => bike.id === x.index),
      )

      if (errors.length !== linesErrors.length) {
        setLineErrors(errors)
      }

      setLineErrors(prevState => {
        const stateCopy = [...prevState]
        const elIndex = stateCopy.findIndex(e => e.index === index)
        if (elIndex !== -1) {
          stateCopy[elIndex].error = isError
        } else {
          stateCopy.push({ index, error: isError })
        }
        return stateCopy
      })
    },
    [bikes, linesErrors],
  )

  useEffect(() => {
    if (numberOfRow === 0) {
      return setLineErrors([])
    }
  }, [numberOfRow, setLineErrors])

  const lines = Array(numberOfRow)
    .fill(0)
    .map((_, index) => {
      const key = bikes[index]
        ? bikes[index]?.id || index.toString()
        : index.toString()
      let bike

      if (bikes[index]) {
        bike = { ...bikes[index], id: key }
      } else {
        bike = { ...initialBike, id: key }
      }

      return (
        <BikeLineInput
          key={key}
          bike={bike}
          onBikeChange={onBikeChange}
          bikeModels={bikeModels}
          bikeErrors={bikeErrors}
          bikes={bikes}
          onError={isError => onError(key, isError)}
        />
      )
    })

  return (
    <div>
      <table css={{ width: '100%' }}>
        <thead css={{ color: theme.colors.gray3 }}>
          <tr>
            <th css={{ fontWeight: 'normal' }}>
              {t('animator.fleets.modal.table.headers.stickerId')}
            </th>
            <th css={{ fontWeight: 'normal' }}>
              {t('animator.fleets.modal.table.headers.morioId')}
            </th>
            <th css={{ fontWeight: 'normal' }}>
              {t('animator.fleets.modal.table.headers.bicycode')}
            </th>
            <th css={{ fontWeight: 'normal' }}>
              {t('animator.fleets.modal.table.headers.keysId')}
            </th>
            <th css={{ fontWeight: 'normal' }}>
              {t('animator.fleets.modal.table.headers.model')}
            </th>
          </tr>
        </thead>
        <tbody>{bikeModels.length > 0 && lines}</tbody>
      </table>

      {linesErrors.some(e => e.error) && (
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            color: theme.colors.error,
            marginTop: '5px',
          }}
        >
          {t('animator.fleets.modal.table.idAlreadyUse')}
        </div>
      )}
    </div>
  )
}

export default BikeListInput
