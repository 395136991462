/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { linkCss } from '../../styles/globalStyles'

import Input from '../../components/Input'
import Button from '../../components/Button'

import { useSelector, useDispatch } from 'react-redux'
import { StoreState } from '../../redux/rootReducer'
import { setCompanySignUpForm, resetCompanySignUpForm } from '../../redux/forms'
import { gql, useMutation } from '@apollo/client'
import { useNotifications } from '../../contexts/NotificationContext'
import i18n from '../../i18n/config'
import apiErrorCatcher from '../../utils/apiErrorCatcher'
import { useTranslation } from 'react-i18next'
import { CreateCompanyMutation } from '../../__generated__/graphql'

const MUTATION_CREATE_COMPANY = gql`
  mutation CreateCompany(
    $info: CompanyInfoInput!
    $referrer: ProfileInfoInput!
    $capacity: CompanyCapacityInput!
    $site: SiteCreateInput!
  ) {
    createCompany(
      info: $info
      referrer: $referrer
      capacity: $capacity
      site: $site
    ) {
      id
    }
  }
`

const schema = yup.object().shape({
  bikeInside: yup.string().required(i18n.t('forms.errors.required')),
  bikeOutside: yup.string().required(i18n.t('forms.errors.required')),
  bikeUnsafe: yup.string().required(i18n.t('forms.errors.required')),
  carPlaces: yup.string().required(i18n.t('forms.errors.required')),
  carElectricStations: yup.string().required(i18n.t('forms.errors.required')),
})

type Step4CompanyInputs = {
  bikeInside: string
  bikeOutside: string
  bikeUnsafe: string
  carPlaces: string
  carElectricStations: string
}

interface Step4Props {
  onNext: () => void
  onPrevious: () => void
  password: {
    password: string
    confirmPassword: string
  }
}

const Step4: React.FC<Step4Props> = ({ onNext, onPrevious, password }) => {
  const { register, handleSubmit, errors } = useForm<Step4CompanyInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const theme = useTheme()
  const dispatch = useDispatch()
  const form = useSelector((state: StoreState) => state.forms.companySignUpForm)
  const [createCompany, { loading }] = useMutation<CreateCompanyMutation>(
    MUTATION_CREATE_COMPANY,
  )

  const notifications = useNotifications()
  const { t } = useTranslation()

  const onSubmit = (data: Step4CompanyInputs) => {
    setCompanySignUpForm({
      bikeInside: data.bikeInside,
      bikeOutside: data.bikeOutside,
      bikeUnsafe: data.bikeUnsafe,
      carPlaces: data.carPlaces,
      carElectricStations: data.carElectricStations,
    })

    createCompany({
      variables: {
        info: {
          name: form.companyName,
          type: form.companyType,
          address: {
            city: form.city,
            street: form.street,
            postalCode: form.postalCode,
            country: form.country,
          },
          nbrEmployees: parseInt(form.nEmployees),
        },
        site: {
          companyId: '',
          name: form.siteName,
          address: {
            city: form.city,
            street: form.street,
            postalCode: form.postalCode,
            country: form.country,
          },
          nbrEmployees: parseInt(form.nEmployees),
          bike: {
            inside: parseInt(data.bikeInside),
            outside: parseInt(data.bikeOutside),
            unsafe: parseInt(data.bikeUnsafe),
          },
          car: {
            places: parseInt(data.carPlaces),
            electricStations: parseInt(data.carElectricStations),
          },
        },
        referrer: {
          password: password.password,
          email: form.email,
          firstName: form.firstName,
          lastName: form.lastName,
          civility: form.civility,
          phoneNumber: form.phoneNumber,
        },
        capacity: {
          bike: {
            inside: parseInt(data.bikeInside),
            outside: parseInt(data.bikeOutside),
            unsafe: parseInt(data.bikeUnsafe),
          },
          car: {
            places: parseInt(data.carPlaces),
            electricStations: parseInt(data.carElectricStations),
          },
        },
      },
    })
      .then(result => {
        if (result.data?.createCompany?.id) {
          dispatch(resetCompanySignUpForm())
          onNext()
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <div css={{ width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p
          css={{
            paddingLeft: '11px',
            fontSize: '1.6rem',
            marginBottom: '20px',
          }}
        >
          {t('auth.signupCompany.bikeFormDesc')}
        </p>
        <Input
          type="text"
          name="bikeInside"
          label={t('auth.signupCompany.bikeInside')}
          placeholder="ex: 0"
          defaultValue={form.bikeInside}
          error={!!errors.bikeInside}
          helperText={errors.bikeInside?.message}
          onlyNumbers
          register={register}
        />
        <Input
          type="text"
          name="bikeOutside"
          label={t('auth.signupCompany.bikeOutside')}
          placeholder="ex: 36"
          defaultValue={form.bikeOutside}
          error={!!errors.bikeOutside}
          helperText={errors.bikeOutside?.message}
          onlyNumbers
          register={register}
        />
        <Input
          type="text"
          name="bikeUnsafe"
          label={t('auth.signupCompany.bikeUnsafe')}
          placeholder="ex: 15"
          defaultValue={form.bikeUnsafe}
          error={!!errors.bikeUnsafe}
          helperText={errors.bikeUnsafe?.message}
          onlyNumbers
          register={register}
        />
        <p
          css={{
            marginTop: '30px',
            paddingLeft: '11px',
            fontSize: '1.6rem',
            marginBottom: '20px',
          }}
        >
          {t('auth.signupCompany.carFormDesc')}
        </p>
        <Input
          type="text"
          name="carPlaces"
          label={t('auth.signupCompany.carPlaces')}
          placeholder="ex: 150"
          defaultValue={form.carPlaces}
          error={!!errors.carPlaces}
          helperText={errors.carPlaces?.message}
          onlyNumbers
          register={register}
        />
        <Input
          type="text"
          name="carElectricStations"
          label={t('auth.signupCompany.carElectricStations')}
          placeholder="ex: 5"
          defaultValue={form.carElectricStations}
          error={!!errors.carElectricStations}
          helperText={errors.carElectricStations?.message}
          onlyNumbers
          register={register}
        />
        <div
          css={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '20px',
            alignItems: 'center',
            gap: '24px',
          }}
        >
          <Button
            type="tertiary"
            onClick={onPrevious}
            icon="back"
            iconColor={theme.colors.primary}
          />
          <Button loading={loading} type="primary" submit>
            {t('forms.button.valid')}
          </Button>
          <div
            css={{ ...linkCss, color: theme.colors.primary, cursor: 'pointer' }}
            onClick={() =>
              onSubmit({
                bikeInside: '0',
                bikeOutside: '0',
                bikeUnsafe: '0',
                carPlaces: '0',
                carElectricStations: '0',
              })
            }
          >
            {t('auth.signupCompany.skipStep4')}
          </div>
        </div>
      </form>
    </div>
  )
}

export default Step4
