/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'
import Modal from '../../../../../../components/Modal'
import Popup from '../../../../../../components/Popup'

interface ReclaimDepositConfirmationModalProps {
  closeModal: () => void
  onSubmit: () => Promise<void>
  onCancel: () => void
  amount: string
  name: string
  title?: string
  description?: string
}

const ReclaimDepositConfirmationModal: React.FC<
  ReclaimDepositConfirmationModalProps
> = ({ closeModal, onSubmit, onCancel, amount, name, title, description }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const submit = () => {
    setLoading(true)
    onSubmit().finally(() => setLoading(false))
  }

  return (
    <Modal isOpen>
      <Popup
        maxWidth={750}
        title={
          title
            ? title
            : t(
                'animator.company.employees.selected.modals.deposit.reclaimDeposit.title',
              )
        }
        titleColor={theme.colors.error}
        closable={false}
        footer={
          <Fragment>
            <Button
              type="tertiary"
              onClick={onCancel}
              textColor={theme.colors.error}
            >
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginLeft: '14px' }}>
              <Button
                submit
                type="primaryError"
                loading={loading}
                onClick={submit}
              >
                {t('forms.button.confirm')}
              </Button>
            </div>
          </Fragment>
        }
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            css={{ textAlign: 'center' }}
            dangerouslySetInnerHTML={{
              __html: description
                ? description
                : t(
                    'animator.company.employees.selected.modals.deposit.reclaimDeposit.body',
                    { amount, name },
                  ),
            }}
          />
        </div>
      </Popup>
    </Modal>
  )
}

export default ReclaimDepositConfirmationModal
