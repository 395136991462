import { Flex } from '../../../../components/Flex'
import {
  AppointmentCategory,
  EmployeeDeployment,
} from '../../../../__generated__/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

export interface EmployeeAppointmentDateOrAbsentProps {
  employeeDeployment: Pick<
    EmployeeDeployment,
    'deploymentId' | 'employee' | 'bikeLoan' | 'id'
  >
  category: AppointmentCategory
}

export const EmployeeAppointmentDateOrAbsent: React.FC<
  EmployeeAppointmentDateOrAbsentProps
> = ({ employeeDeployment, category }) => {
  const { t } = useTranslation()
  let loanDate = t('shared.none')

  const bikeLoan = employeeDeployment.employee?.bikeLoan?.find(
    bikeLoan => bikeLoan.employeeDeployment?.id === employeeDeployment.id,
  )

  const bikeDeliveredDate = bikeLoan?.bikeDeliveredDate
  const bikeReturnedDate = bikeLoan?.bikeReturnedDate

  if (category === AppointmentCategory.LoanDelivery) {
    const loanDeliveryAppointment =
      employeeDeployment.employee?.loanDeliveryAppointment?.find(
        appointment =>
          appointment?.deploymentId === employeeDeployment.deploymentId,
      )

    const loanDeliveryAbsence = employeeDeployment?.employee?.absences?.find(
      x =>
        x?.category === AppointmentCategory.LoanDelivery &&
        x?.deploymentId === employeeDeployment.deploymentId,
    )

    if (bikeDeliveredDate) {
      loanDate = format(new Date(bikeDeliveredDate), "dd/MM à HH'h'mm")
    } else if (loanDeliveryAbsence) {
      loanDate = t('employee.dashboard.absent')
    } else if (loanDeliveryAppointment) {
      loanDate = format(
        new Date(loanDeliveryAppointment.date),
        "dd/MM à HH'h'mm",
      )
    }
  }

  if (category === AppointmentCategory.LoanReturn) {
    const loanReturnAppointment =
      employeeDeployment.employee?.loanReturnAppointment?.find(
        appointment =>
          appointment?.deploymentId === employeeDeployment.deploymentId,
      )
    const loanReturnAbsence = employeeDeployment?.employee?.absences?.find(
      x =>
        x?.category === AppointmentCategory.LoanReturn &&
        x?.deploymentId === employeeDeployment.deploymentId,
    )

    if (bikeReturnedDate) {
      loanDate = format(new Date(bikeReturnedDate), "dd/MM à HH'h'mm")
    } else if (loanReturnAbsence) {
      loanDate = t('employee.dashboard.absent')
    } else if (loanReturnAppointment) {
      loanDate = format(new Date(loanReturnAppointment.date), "dd/MM à HH'h'mm")
    }
  }

  return (
    <Flex direction="row" justify="center" gap="8px">
      {loanDate}
    </Flex>
  )
}
