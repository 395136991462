/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useParams } from 'react-router-dom'

import ModifyFleet from './ModifyFleet'
import CompanyProfile from '../../../Shared/CompanyProfile/index'
import { ProgramStepType, UserRoles } from '@goodwatt/shared/dist/types'
import ModifyAnimator from './ModifyAnimator'
import { StoreState } from '../../../../redux/rootReducer'
import { useSelector } from 'react-redux'
import { GetCompanyProfileQuery } from '../../../../__generated__/graphql'

const shouldRenderModifyFleetComponent = (data: GetCompanyProfileQuery) => {
  const companyStepsAllowedToModifyFleet = [
    ProgramStepType.REFUSED,
    ProgramStepType.PENDING,
    ProgramStepType.CONTRACTUALIZATION,
    ProgramStepType.EMPLOYEE_LOANS_SELECTION,
    ProgramStepType.PREPARING_LOAN,
  ]
  return companyStepsAllowedToModifyFleet.includes(
    data.company?.programStep.stepNbr as number,
  )
}

const AnimatorCompanyProfile: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { role: userRole } = useSelector((state: StoreState) => state.user)

  const isAdmin = userRole === UserRoles.ADMIN

  return (
    <CompanyProfile
      companyId={id}
      renderExtra={(data: GetCompanyProfileQuery) =>
        shouldRenderModifyFleetComponent(data) || isAdmin ? (
          <React.Fragment>
            {shouldRenderModifyFleetComponent(data) && (
              <ModifyFleet companyId={id} />
            )}
            {isAdmin && <ModifyAnimator companyId={id} />}
          </React.Fragment>
        ) : null
      }
    />
  )
}

export default AnimatorCompanyProfile
