/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  PackageAccessoryType,
  StateOfUseType,
} from '@goodwatt/shared/dist/types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setReturnBikeForm } from '../../../redux/forms'
import { StoreState } from '../../../redux/rootReducer'

import { PartsConditionTableRow } from '../../../types/AnimatorCompanyEmployees'
import StarRating from '../../StarRating'

interface ReturnPackageAccessoryStarRatingCellProps {
  accessory: PartsConditionTableRow
}

const ReturnPackageAccessoryStarRatingCell: React.FC<
  ReturnPackageAccessoryStarRatingCellProps
> = ({ accessory }) => {
  const dispatch = useDispatch()
  const { accessories } = useSelector(
    (state: StoreState) => state.forms.returnBikeForm,
  )
  const [defaultStateOfUse, setDefaultStateOfUse] =
    React.useState<StateOfUseType>()
  const [isTouched, setIsTouched] = React.useState<boolean>(false)
  const currentAccessory = React.useMemo(
    () => accessories[accessory.name as PackageAccessoryType],
    [accessories, accessory.name],
  )

  React.useEffect(() => {
    if (!defaultStateOfUse) setDefaultStateOfUse(accessory.stateOfUse)
  }, [accessory.stateOfUse, defaultStateOfUse])

  const onClick = (newStateOfUse?: StateOfUseType) => {
    setIsTouched(true)
    dispatch(
      setReturnBikeForm({
        accessories: {
          ...accessories,
          [accessory.name as PackageAccessoryType]: {
            ...currentAccessory,
            stateOfUse: newStateOfUse,
          },
        },
      }),
    )
  }

  return (
    <StarRating
      id={accessory.name || ''}
      currentStateOfUse={defaultStateOfUse}
      updatedStateOfUse={isTouched ? currentAccessory?.stateOfUse : undefined}
      onRatingClick={onClick}
      onMissedClick={() =>
        onClick(
          currentAccessory?.stateOfUse !== StateOfUseType.MISSED
            ? StateOfUseType.MISSED
            : undefined,
        )
      }
    />
  )
}

export default ReturnPackageAccessoryStarRatingCell
