import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import { MUTATION_ARCHIVE_COMPANY } from '../../../../utils/gql/mutations'
import {
  ArchiveCompanyMutation,
  ArchiveCompanyMutationVariables,
} from '../../../../__generated__/graphql'

export const useArchiveCompany = (
  options?: MutationHookOptions<
    ArchiveCompanyMutation,
    ArchiveCompanyMutationVariables
  >,
): MutationTuple<ArchiveCompanyMutation, ArchiveCompanyMutationVariables> => {
  return useMutation<ArchiveCompanyMutation, ArchiveCompanyMutationVariables>(
    MUTATION_ARCHIVE_COMPANY,
    {
      refetchQueries: ['companiesWithDeployments'],
      ...options,
    },
  )
}
