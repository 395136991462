/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useCurrentDeploymentId } from '../hooks/useCurrentDeploymentId'
import { useModal } from '../../../../hooks/useModal'
import DeploymentModal from '../../../Shared/Deployments/AddDeploymentModal'
import { DeploymentTableModal } from '../../../../types/AnimatorDeployments'

export interface EditDeploymentButtonProps {
  id?: string
}
export const EditDeploymentButton: React.FC<EditDeploymentButtonProps> = ({
  id,
}: EditDeploymentButtonProps) => {
  const defaultId = useCurrentDeploymentId()
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      {modalProps.isOpen && (
        <DeploymentModal
          deploymentId={id ?? defaultId}
          type={DeploymentTableModal.EDIT}
          {...modalProps}
        />
      )}
      <button onClick={onOpen}>
        <Icon type="edit" />
      </button>
    </Fragment>
  )
}
