import { gql } from '@apollo/client'
import i18n from '../../i18n/config'
import {
  GetArchivedCompanyDataExportQuery,
  SpecialBikesType,
} from '../../__generated__/graphql'

export const QUERY_ARCHIVED_COMPANY_DATA_EXPORT = gql`
  query GetArchivedCompanyDataExport($companyId: String) {
    employees(where: { AND: [{ companyId: { equals: $companyId } }] }) {
      civility
      postalCode
      city
      age
      height

      travelMode {
        name
      }

      workTravelDistance
      workTravelDuration

      employeeBikeHabit {
        name
      }

      bikeParkingType {
        name
      }

      specialBikeType
      specialBikeAbsolutelyNeeded
      babySeat

      company {
        name
      }

      totalDistanceInKm
      homeWorkRides
      bikeDaysUsed
      totalMoneySaved
      totalEnergySpent
      totalKgCO2Saved
      currentLoanPoints
      hasMobileApp
    }
  }
`

export const formatArchivedCompanyDataExport = (
  data: GetArchivedCompanyDataExportQuery | undefined,
): any => {
  if (!data?.employees?.length) return []

  const header = [
    'Civilité',
    'Code postal',
    'Ville',
    'Age',
    'Taille (cm)',

    'Mode de transport',
    'Distance DT (km)',
    'Duree DT (min)',
    'Pratique du velo',
    'Stationnement',

    'Type de vélo',
    'Type de demande',
    'Siège enfant',

    "Nom de l'organisation",
    'Distance totale (km)',
    'Distance quotidienne (km/j)',
    'Nombre de trajets DT',
    "Nombre de jour d'utilisation",
    'Economie (€)',
    'Effort physique (kCal)',
    'Emissions (kgCO2)',
    'Nombre de points (appli)',
    'Téléchargement appli',
  ]

  return [
    header,
    ...data?.employees.map(employee => ({
      civility: i18n.t(`shared.profile.civility.${employee.civility}`),
      postalCode: employee.postalCode,
      city: employee.city,
      age: employee.age,
      height: employee.height,

      travelMode: employee?.travelMode?.name
        ? i18n.t(
            `animator.company.employees.subscribed.filter.mode.options.${employee.travelMode.name}`,
          )
        : '',
      workTravelDistance: employee.workTravelDistance,
      workTravelDuration: employee.workTravelDuration,
      employeeBikeHabit: employee?.employeeBikeHabit?.name
        ? i18n.t(
            `shared.employeeBikeHabitLabel.${employee?.employeeBikeHabit?.name}`,
          )
        : '',
      bikeParkingType: employee?.bikeParkingType?.name
        ? i18n.t(
            `animator.company.employees.subscribed.filter.parkingType.options.${employee.bikeParkingType.name}`,
          )
        : '',
      specialBikeType: employee.specialBikeType
        ? {
            [SpecialBikesType.UrbanBike]: 'Urbain',
            [SpecialBikesType.FoldingBike]: 'Pliant',
            [SpecialBikesType.CargoBike]: 'Familial',
          }[employee.specialBikeType]
        : '',
      specialBikeAbsolutelyNeeded:
        employee.specialBikeType === SpecialBikesType.UrbanBike
          ? 'Essentiel'
          : employee.specialBikeAbsolutelyNeeded
          ? 'Essentiel'
          : 'Si possible',
      babySeat: employee.babySeat
        ? i18n.t(`shared.babySeat.short.${employee.babySeat}`)
        : '',

      companyName: employee.company?.name,
      totalDistanceInKm: employee.totalDistanceInKm,
      totalDailyDistance:
        (employee.totalDistanceInKm || 0) / (employee.bikeDaysUsed || 1),
      homeWorkRides: employee.homeWorkRides,
      bikeDaysUsed: employee.bikeDaysUsed,
      totalMoneySaved: employee.totalMoneySaved,
      totalEnergySpent: employee.totalEnergySpent,
      totalKgCO2Saved: Math.round(employee.totalKgCO2Saved || 0),
      currentLoanPoints: employee.currentLoanPoints,
      hasMobileApp: employee.hasMobileApp,
    })),
  ]
}
