/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { Row } from 'react-table'

import Icon from '../../Icon'
import {
  BabySeatOptions,
  SpecialBikesType,
} from '../../../__generated__/graphql'

interface EmployeeDocumentCellProps {
  row: Row<
    Partial<{
      specialBikeType: SpecialBikesType | null
      specialBikeAbsolutelyNeeded: boolean | null
      babySeat: BabySeatOptions | null
    }>
  >
}

const EmployeeOptionsCell: React.FC<EmployeeDocumentCellProps> = ({
  row: { original: row },
}) => {
  const theme = useTheme()

  const specialBikeColor = row.specialBikeAbsolutelyNeeded
    ? theme.colors.optionsOrange
    : theme.colors.optionsYellow

  const babySitColor =
    row.babySeat === BabySeatOptions.AbsolutelyNeeded
      ? theme.colors.optionsOrange
      : theme.colors.optionsYellow

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
      }}
    >
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        {row.specialBikeType == null ||
        row.specialBikeType === SpecialBikesType.UrbanBike ? (
          <Icon
            type="bikeMenu"
            color={theme.colors.optionsGreen}
            customSize={{
              width: 26,
              height: 26,
            }}
          />
        ) : null}

        {row.specialBikeType === SpecialBikesType.FoldingBike ? (
          <Icon
            type="foldingBike"
            color={specialBikeColor}
            customSize={{
              width: 26,
              height: 26,
            }}
          />
        ) : null}

        {row.specialBikeType === SpecialBikesType.CargoBike ? (
          <Icon
            type="cargoBike"
            color={specialBikeColor}
            customSize={{
              width: 26,
              height: 26,
            }}
          />
        ) : null}

        {row.babySeat &&
        [BabySeatOptions.AbsolutelyNeeded, BabySeatOptions.Needed].includes(
          row.babySeat,
        ) ? (
          <Icon
            type="kidChair"
            color={babySitColor}
            customSize={{
              width: 26,
              height: 26,
            }}
          />
        ) : null}
      </div>
    </div>
  )
}

export default EmployeeOptionsCell
