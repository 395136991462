import parsePhoneNumber from 'libphonenumber-js'

export const employeeNameFormatter = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
): string => {
  if (!firstName && !lastName) return ''

  const lowerFirstName = firstName?.toLowerCase() || ''
  return `${
    lowerFirstName.charAt(0).toUpperCase() + lowerFirstName.slice(1)
  } ${lastName?.toUpperCase()}`
}

export const dateFormatter = (
  dateString: string | null | undefined,
): string => {
  if (!dateString) return ''

  const date = new Date(dateString)

  let day, month, hours, minutes

  day = date.getDate()
  month = date.getMonth() + 1
  hours = date.getHours()
  minutes = date.getMinutes()

  if (day < 10) day = `0${day}`
  if (month < 10) month = `0${month}`
  if (hours < 10) hours = `0${hours}`
  if (minutes < 10) minutes = `0${minutes}`

  return `${day}/${month} à ${hours}h${minutes}`
}

export const dateFormatterExport = (
  dateString: string | null | undefined,
  datetime = true,
  onlyDateTime = false,
): string => {
  if (!dateString) return ''

  const date = new Date(dateString)

  let day, month, hours, minutes

  day = date.getDate()
  month = date.getMonth() + 1
  hours = date.getHours()
  minutes = date.getMinutes()
  const year = date.getFullYear()

  if (day < 10) day = `0${day}`
  if (month < 10) month = `0${month}`
  if (hours < 10) hours = `0${hours}`
  if (minutes < 10) minutes = `0${minutes}`

  let data = `${day}/${month}/${year}`

  if (datetime) {
    if (onlyDateTime) return `${hours}h${minutes}`
    data += ` ${hours}h${minutes}`
  }

  return data
}

export const phoneFormatter = (phoneNumber: string): string => {
  const parsed = parsePhoneNumber(phoneNumber, 'FR')
  if (parsed == null) return phoneNumber
  return parsed.formatNational()
}
