/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import Modal from '@goodwatt/client/src/components/Modal'
import Popup from '@goodwatt/client/src/components/Popup'
import TextArea from '@goodwatt/client/src/components/TextArea'
import { TextVariant } from '../../../hooks/useVariant/variants/text'

import { QuizTableRow } from '@goodwatt/client/src/types/Quizzes'

interface ViewModalProps {
  isOpen: boolean
  quiz: QuizTableRow
  closeModal: () => void
}

const ViewModal: React.FC<ViewModalProps> = ({ isOpen, closeModal, quiz }) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen}>
      <Popup closable title={quiz.title} onClose={closeModal} maxWidth={750}>
        <div>
          <div css={{ marginTop: '1rem' }}>
            <img
              src={quiz.imageURL}
              alt="animator"
              css={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
          <div css={{ marginTop: '1rem' }}>
            <TextArea
              name={t('quiz.modal.view.content')}
              label={t('quiz.modal.view.content')}
              disabled
              value={quiz.content}
              variant={TextVariant.white}
            />
          </div>
        </div>
      </Popup>
    </Modal>
  )
}

export default ViewModal
