/** @jsx jsx */
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '../../../components/Table'
import Pagination from '../../../components/Pagination'
import {
  FleetListProps,
  FleetModalSetting,
  FleetModalType,
  FleetsTableRow,
} from '../../../types/AnimatorFleets'
import { gql, useQuery } from '@apollo/client'

import { Column, Row } from 'react-table'
import {
  FleetStockConditionCellStyle,
  getStockLevel,
} from '../../../components/Table/CustomCells/FleetStockConditionCell'
import FleetsActionCell from '../../../components/Table/CustomCells/FleetsActionCell'
import { shortDayDate } from '../../../utils/Date'
import { jsx } from '@emotion/core'
import Button from '../../../components/Button'
import theme from '../../../styles/theme'
import ModalFleet from './ModalFleet'

import ModalDeleteFleet from './ModalDeleteFleet'
import DownloadCSVButton from '../../../components/Button/DownloadCSVButton'
import {
  formatFleetsExport,
  QUERY_FLEETS_EXPORT,
} from '../../../utils/gql/fleetsExport'
import usePromiseLazyQuery from '../../../hooks/usePromiseLazyQuery'
import {
  AnimatorGetCompanyFleetsQuery,
  AnimatorGetCompanyFleetsQueryVariables,
  GetFleetsExportQuery,
  GetFleetsExportQueryVariables,
} from '../../../__generated__/graphql'

const QUERY_ANIMATOR_COMPANY_FLEETS = gql`
  query AnimatorGetCompanyFleets(
    $multiFilter: String
    $orderBy: FleetOrderByInput
    $pageSize: Int
  ) {
    fleetsSearch(
      multiFilter: $multiFilter

      take: $pageSize
      orderBy: $orderBy
    ) {
      id
      name
      totalBike
      totalLoanBike
      totalAvailableBike
      totalOtherBike
      areaName
      nextAppointment {
        date
        category
      }
      bikes {
        bikeCondition {
          name
        }
      }
      areaId
      area {
        id
        name
      }
      companies {
        name
        appointments(orderBy: { date: asc }) {
          category
          date
        }
      }
    }
  }
`

const initialModalSetting: FleetModalSetting = {
  fleet: undefined,
  modalState: FleetModalType.NONE,
}

const AnimatorFleetTableList: React.FC<FleetListProps> = ({
  filters,
  pageSize,
  onFleetsChange,
}) => {
  const { t } = useTranslation()
  const [currPage, setCurrPage] = useState(1)
  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)
  const [currModal, setCurrModal] =
    useState<FleetModalSetting>(initialModalSetting)

  React.useEffect(() => {
    setCurrPage(1)
  }, [filters])

  const {
    data: fleetsData,
    loading,
    refetch: refetchFleets,
  } = useQuery<
    AnimatorGetCompanyFleetsQuery,
    AnimatorGetCompanyFleetsQueryVariables
  >(QUERY_ANIMATOR_COMPANY_FLEETS, {
    fetchPolicy: 'network-only',
    variables: {
      multiFilter: filters.multiFilter,

      orderBy:
        orderBy.sort && orderBy.columnName
          ? { [orderBy.columnName]: orderBy.sort }
          : undefined,
      pageSize: 2147483647,
      //Without pageSize, ordering is compromise
    },
  })

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<FleetsTableRow> }) => (
      <FleetsActionCell setModal={setCurrModal} row={row} />
    ),
    [],
  )

  const columns = useMemo<Column[]>(() => {
    const tables: any = [
      {
        Header: t('animator.fleets.table.headers.name') as string,
        accessor: 'name',
        disableSortBy: false,
        cellStyle: () => ({ minWidth: '110px' }),
      },
      {
        Header: t('animator.fleets.table.headers.area') as string,
        accessor: 'areaName',
        cellStyle: () => ({ maxWidth: '184px' }),
        disableSortBy: false,
      },
      {
        Header: () => (
          <div css={{ margin: 'auto' }}>
            {t('animator.fleets.table.headers.total') as string}
          </div>
        ),
        // Header: t('animator.fleets.table.headers.total') as string,
        accessor: 'totalBike',
        maxWidth: 200,
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: () => (
          <div css={{ margin: 'auto' }}>
            {t('animator.fleets.table.headers.stock') as string}
          </div>
        ),
        // Header: t('animator.fleets.table.headers.stock') as string,
        accessor: 'totalAvailableBike',
        cellStyle: FleetStockConditionCellStyle,
      },
      {
        Header: () => (
          <div css={{ margin: 'auto' }}>
            {t('animator.fleets.table.headers.loan') as string}
          </div>
        ),
        // Header: t('animator.fleets.table.headers.loan') as string,
        accessor: 'totalLoanBike',
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: t('animator.fleets.table.headers.otherState') as string,
        accessor: 'totalOtherBike',
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: t('animator.fleets.table.headers.actions') as string,
        accessor: 'actions',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ]

    return tables
  }, [t, ActionCell])

  const mapFleetData = useMemo(() => {
    if (!fleetsData?.fleetsSearch?.length) return []
    return fleetsData.fleetsSearch.map(fleet => {
      const nextCompany = fleet?.companies.find(x =>
        x.appointments.find(app => app.date === fleet.nextAppointment?.date),
      )

      const formattedNextStep = fleet?.nextAppointment?.category
        ? `${t(
            `animator.fleets.filter.fleetConditions.stepsCategory.${fleet.nextAppointment?.category}`,
          )} (${nextCompany?.name})`
        : ''

      return {
        id: fleet?.id,
        name: fleet?.name,
        area: {
          id: fleet?.area?.id,
          name: fleet?.area?.name,
        },
        areaId: fleet?.areaId,
        areaName: fleet?.areaName,
        totalBike: fleet?.totalBike,
        totalAvailableBike: fleet?.totalAvailableBike,
        totalLoanBike: fleet?.totalLoanBike,
        totalOtherBike: fleet?.totalOtherBike,
        nextStep: fleet?.nextAppointment?.category,
        nextStepCategory: formattedNextStep,
        nextStepDate: fleet?.nextAppointment?.date
          ? shortDayDate(new Date(fleet?.nextAppointment?.date))
          : undefined,
      }
    })
  }, [t, fleetsData])

  const filteredData = useMemo(() => {
    let filteredData = mapFleetData
    if (filters.areaId)
      filteredData = filteredData.filter(
        fleet => fleet.areaId?.toString() === filters.areaId,
      )

    if (filters.stockLevel)
      filteredData = filteredData.filter(
        fleet =>
          getStockLevel(fleet.totalBike || 0, fleet.totalAvailableBike || 0) ===
          filters.stockLevel,
      )

    return filteredData
  }, [filters.stockLevel, filters.areaId, mapFleetData])

  const formattedData = useMemo(() => {
    return filteredData.slice((currPage - 1) * pageSize, currPage * pageSize)
  }, [pageSize, currPage, filteredData])

  const onCloseModal = (shouldRefetch?: boolean) => {
    setCurrModal({ ...initialModalSetting })
    if (shouldRefetch) {
      refetchFleets()
      onFleetsChange()
    }
  }

  const getFleetsExport = usePromiseLazyQuery<
    GetFleetsExportQuery,
    GetFleetsExportQueryVariables
  >(QUERY_FLEETS_EXPORT, 'no-cache')

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')

  const filename = `fleets-${formatedDate}`

  const getExportData = async () => {
    const { data } = await getFleetsExport({
      multiFilter: filters.multiFilter,
    })

    return formatFleetsExport(data)
  }

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={formattedData}
        onSortBy={setOrderBy}
        loading={loading}
      />
      <div
        css={{
          marginTop: '41px',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <div
          css={{
            display: 'flex',
            justifySelf: 'flex-start',
            position: 'absolute',
            left: 0,
          }}
        >
          <Button
            submit
            onClick={() =>
              setCurrModal({
                fleet: undefined,
                modalState: FleetModalType.EDIT,
              })
            }
          >
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontSize: '1.6rem',
                fontWeight: 500,
                color: theme.colors.white,
                textTransform: 'uppercase',
              }}
            >
              {t('animator.fleets.table.addFleet')}
            </span>
          </Button>
        </div>

        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={filteredData.length}
        />
        <div
          css={{
            display: 'flex',
            justifySelf: 'flex-end',
            position: 'absolute',
            right: 0,
          }}
        >
          <DownloadCSVButton getData={getExportData} filename={filename} />
        </div>
        <ModalFleet
          fleet={currModal.fleet}
          isOpen={currModal.modalState === FleetModalType.EDIT}
          closeModal={onCloseModal}
        />
        <ModalDeleteFleet
          fleet={currModal.fleet}
          isOpen={currModal.modalState === FleetModalType.DELETE}
          closeModal={onCloseModal}
        />
      </div>
    </React.Fragment>
  )
}

export default AnimatorFleetTableList
