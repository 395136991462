export enum BikeTableModal {
  EDIT = 'edit',
  LOCATE = 'locate',
  DELETE = 'delete',
  ADD = 'add',
}
export interface BikeModalSettings<
  D = BikesTableRow | undefined,
  T = BikeTableModal,
> {
  bike: D
  actionName: '' | T
}
export interface BikeListProps {
  filters: {
    [filterName: string]: string
  }
  pageSize: number
}

export interface BikesTableRow {
  id: string | undefined
  index: number | 0
  bikeModel: string | undefined
  fleet: string | undefined
  bikeCondition: string | undefined
  resolvedDate: Date | undefined
  stickerId: number | undefined
}

export interface ModalBikesSubmit<T = { name: string; value: string }> {
  bikeModel: T
  fleet: T
  bikeCondition: T
  resolvedDate: Date
}

export enum BikeModelTableModal {
  EDIT = 'edit',
  DELETE = 'delete',
  ADD = 'add',
}

export interface BikeModelModalSettings<
  D = BikeModelTableRow | undefined,
  T = BikeModelTableModal,
> {
  bikeModel: D
  actionName: '' | T
}

export interface BikeModelTableRow {
  id: string | undefined
  name: string | undefined
  brand: string | undefined
  bikeType: string | undefined
  childSeatCompatibility: boolean | false
}
