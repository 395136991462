/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { ProgramStepType } from '@goodwatt/shared/dist/types'

import Button from '../../../../components/Button'
import DateTime from '../../../../components/DateTime'
import Input from '../../../../components/Input'
import Popup from '../../../../components/Popup'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import {
  MUTATION_CREATE_APPOINTMENTS,
  MUTATION_UPDATE_ONE_COMPANY,
} from '../../../../utils/gql/mutations'

import theme from '../../../../styles/theme'

import Switch from '../../../../components/Switch'
import Icon from '../../../../components/Icon'
import i18n from '../../../../i18n/config'
import {
  AppointmentCategory,
  CreateAppointmentsWithInputMutation,
  CreateAppointmentsWithInputMutationVariables,
  GetCompanyProgressDetailsQuery,
  UpdateOneCompanyMutation,
  UpdateOneCompanyMutationVariables,
} from '../../../../__generated__/graphql'

const schema = yup.object().shape({
  appointmentDate: yup.date().required(i18n.t('forms.errors.required')),
  appointmentLocation: yup.string().required(i18n.t('forms.errors.required')),
  loanQuantityBikes: yup.string(),
  appointmentDuration: yup
    .number()
    .typeError('')
    .required(i18n.t('forms.errors.required'))
    .min(1, i18n.t('forms.errors.minAppointment')),
  appointmentEmployees: yup
    .number()
    .typeError('')
    .required(i18n.t('forms.errors.required'))
    .min(1, i18n.t('forms.errors.minAppointment')),
  applyOnlyToNew: yup.boolean(),
})

interface EditEventsModalInputs {
  loanQuantityBikes: string
  appointmentLocation: string
  appointmentDate: Date
  appointmentDuration: number
  appointmentEmployees: number
  applyOnlyToNew: boolean
}

interface EditEventsModal {
  onClose: () => void
  data?: GetCompanyProgressDetailsQuery
  refetch: () => void
  totalEmployeesForLoan?: number
  companyStepNumber: ProgramStepType
  defaultAppointmentType?: AppointmentCategory
}

const EditEventsModal: React.FC<EditEventsModal> = ({
  totalEmployeesForLoan = 0,
  onClose,
  data,
  refetch,
  companyStepNumber,
  defaultAppointmentType = AppointmentCategory.LoanDelivery,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const { id } = useParams<{ id: string }>()

  const [appointmentType, setAppointmentType] = React.useState(
    defaultAppointmentType,
  )

  const [showConfirmModal, setConfirmModal] = React.useState(false)

  const [updateOneCompany, { loading: loadingUpdateOneCompany }] = useMutation<
    UpdateOneCompanyMutation,
    UpdateOneCompanyMutationVariables
  >(MUTATION_UPDATE_ONE_COMPANY)

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    errors,
    control,
    reset: resetForm,
  } = useForm<EditEventsModalInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [createAppointements, { loading: loadingCreateAppointements }] =
    useMutation<
      CreateAppointmentsWithInputMutation,
      CreateAppointmentsWithInputMutationVariables
    >(MUTATION_CREATE_APPOINTMENTS)

  const onSubmit = async (data: EditEventsModalInputs) => {
    if (loadingUpdateOneCompany || loadingCreateAppointements) return

    if (getValues().applyOnlyToNew && !showConfirmModal) {
      return setConfirmModal(true)
    }

    setConfirmModal(false)

    if (
      !data.loanQuantityBikes &&
      appointmentType === AppointmentCategory.LoanDelivery
    ) {
      return setError('loanQuantityBikes', {
        message: i18n.t('forms.errors.required'),
      })
    }

    if (totalEmployeesForLoan > parseInt(data.loanQuantityBikes))
      return notifications.newNotification({
        type: NotificationTypes.ERROR,
        message: t(
          'animator.company.progress.modal.notificationErrorUpdateBikePlaces',
        ),
      })

    if (appointmentType === AppointmentCategory.LoanDelivery) {
      await updateOneCompany({
        variables: {
          where: { id },
          data: {
            loanQuantityBikes: {
              set: parseInt(data.loanQuantityBikes),
            },
          },
        },
      }).catch(apiErrorCatcher(notifications))
    }

    await createAppointements({
      variables: {
        input: {
          category: appointmentType,
          duration: Number(data.appointmentDuration),
          capacity: Number(data.appointmentEmployees),
          location: data.appointmentLocation,
          starting: data.appointmentDate,
          reset: !data.applyOnlyToNew,
          company: id,
        },
      },
    })

    refetch()
    notifications.newNotification({
      type: NotificationTypes.SUCCESS,
      message: t('shared.notification.successUpdate'),
    })

    resetForm()
    nextAppointmentType()
  }

  const updateAppointmentType = (type: AppointmentCategory) => {
    setAppointmentType(type)
    resetForm()
  }

  const nextAppointmentType = () => {
    switch (appointmentType) {
      case AppointmentCategory.LoanDelivery:
        updateAppointmentType(AppointmentCategory.Training)
        break
      case AppointmentCategory.Training:
        updateAppointmentType(AppointmentCategory.LoanReturn)
        break
      case AppointmentCategory.LoanReturn:
        onClose()
        break
    }
  }

  let appointmenti18nKey = ''
  let appointment = null

  switch (appointmentType) {
    case AppointmentCategory.LoanDelivery:
      appointmenti18nKey = 'animator.company.progress.modal.deliveryAppointment'
      appointment = data?.company?.deliveryAppointmentTemplate
      break
    case AppointmentCategory.Training:
      appointmenti18nKey = 'animator.company.progress.modal.trainingAppointment'
      appointment = data?.company?.trainingAppointmentTemplate
      break
    case AppointmentCategory.LoanReturn:
      appointmenti18nKey = 'animator.company.progress.modal.returnAppointment'
      appointment = data?.company?.returnAppointmentTemplate
      break
  }

  return (
    <Popup
      title={t('animator.company.progress.modal.title')}
      closable
      onClose={onClose}
      maxWidth={750}
      disablePadding
      footer={
        <React.Fragment>
          <Button type="tertiary" onClick={onClose}>
            {t('forms.button.cancel')}
          </Button>

          <Button
            type="primary"
            submit
            onClick={handleSubmit(onSubmit)}
            disabled={loadingUpdateOneCompany || loadingCreateAppointements}
          >
            {t('forms.button.valid')}
          </Button>

          <div
            onClick={nextAppointmentType}
            css={{
              cursor: 'pointer',
              position: 'absolute',
              lineHeight: '44px',
              margin: '0 24px',
              right: '24px',
              color: theme.colors.primary,
              userSelect: 'none',
            }}
          >
            <span>{t('animator.company.progress.modal.skip')}</span>
          </div>
        </React.Fragment>
      }
    >
      {showConfirmModal && (
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            background: 'white',
            zIndex: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Icon type="calendarPlus" customSize={{ width: 156, height: 156 }} />

          <div
            css={{
              margin: '36px 0',
              marginBottom: 48,
              fontSize: '18px',
              lineHeight: '21px',
              width: 356,
              textAlign: 'center',
            }}
            dangerouslySetInnerHTML={{
              __html: t('animator.company.progress.modal.confirmModal'),
            }}
          />

          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              gap: '24',
            }}
          >
            <Button type="tertiary" onClick={() => setConfirmModal(false)}>
              {t('forms.button.cancel')}
            </Button>
            <Button
              type="primary"
              submit
              onClick={handleSubmit(onSubmit)}
              disabled={loadingUpdateOneCompany || loadingCreateAppointements}
            >
              {t('forms.button.confirm')}
            </Button>
          </div>
        </div>
      )}

      <div style={styles.headContainer}>
        <div
          style={styles.headStepContainer}
          onClick={() =>
            updateAppointmentType(AppointmentCategory.LoanDelivery)
          }
        >
          <div
            style={
              appointmentType === AppointmentCategory.LoanDelivery
                ? { ...styles.headStepSelected }
                : {}
            }
            css={{
              ...styles.headStep,
            }}
          >
            <span>1</span>
          </div>
          <span
            style={
              appointmentType === AppointmentCategory.LoanDelivery
                ? { ...styles.headStepTitleSelected }
                : {}
            }
            css={{ ...styles.headStepTitle }}
          >
            {t('animator.company.progress.modal.deliveryAppointment.title')}
          </span>
        </div>

        <div
          css={{
            position: 'relative',
            height: 1,
            width: 86,
            marginTop: 60,
            background: theme.colors.gray4,
          }}
        >
          <div
            css={{
              position: 'absolute',
              top: -3,
              right: 0,
              height: 7,
              width: 7,
              borderTop: `1px solid ${theme.colors.gray4}`,
              borderRight: `1px solid ${theme.colors.gray4}`,
              transform: 'rotate(45deg)',
            }}
          ></div>
        </div>

        <div
          style={styles.headStepContainer}
          onClick={() => updateAppointmentType(AppointmentCategory.Training)}
        >
          <div
            style={
              appointmentType === AppointmentCategory.Training
                ? { ...styles.headStepSelected }
                : {}
            }
            css={{
              ...styles.headStep,
            }}
          >
            <span>2</span>
          </div>
          <span
            style={
              appointmentType === AppointmentCategory.Training
                ? { ...styles.headStepTitleSelected }
                : {}
            }
            css={{ ...styles.headStepTitle }}
          >
            {t('animator.company.progress.modal.trainingAppointment.title')}
          </span>
        </div>

        <div
          css={{
            position: 'relative',
            height: 1,
            width: 86,
            marginTop: 60,
            background: theme.colors.gray4,
          }}
        >
          <div
            css={{
              position: 'absolute',
              top: -3,
              right: 0,
              height: 7,
              width: 7,
              borderTop: `1px solid ${theme.colors.gray4}`,
              borderRight: `1px solid ${theme.colors.gray4}`,
              transform: 'rotate(45deg)',
            }}
          ></div>
        </div>

        <div
          style={styles.headStepContainer}
          onClick={() => updateAppointmentType(AppointmentCategory.LoanReturn)}
        >
          <div
            style={
              appointmentType === AppointmentCategory.LoanReturn
                ? { ...styles.headStepSelected }
                : {}
            }
            css={{
              ...styles.headStep,
            }}
          >
            <span>3</span>
          </div>
          <span
            style={
              appointmentType === AppointmentCategory.LoanReturn
                ? { ...styles.headStepTitleSelected }
                : {}
            }
            css={{ ...styles.headStepTitle }}
          >
            {t('animator.company.progress.modal.returnAppointment.title')}
          </span>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} key={appointment?.id}>
        <div
          css={{
            width: '100%',
            paddingInline: '9%',
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Fragment>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: '20px',
                alignItems: 'baseline',
              }}
            >
              <p css={styles.title}>
                {t('animator.company.progress.modal.nBikes')} :
              </p>
              {appointmentType === AppointmentCategory.LoanDelivery ? (
                <Input
                  type="text"
                  name="loanQuantityBikes"
                  placeholder="ex: 12"
                  defaultValue={
                    data?.company?.loanQuantityBikes?.toString() || ''
                  }
                  error={!!errors.loanQuantityBikes}
                  helperText={errors.loanQuantityBikes?.message}
                  register={register}
                  ultraCompact
                  center
                  block={false}
                  onlyNumbers
                />
              ) : (
                <span>
                  {data?.company?.loanQuantityBikes?.toString() || '0'}
                </span>
              )}
            </div>
          </Fragment>
          {
            <Fragment>
              <p css={styles.title}>{t(`${appointmenti18nKey}.title`)}</p>
              <Controller
                name="appointmentLocation"
                control={control}
                defaultValue={appointment?.location}
                render={({ onChange, value }) => {
                  return (
                    <div
                      css={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        gap: 20,
                      }}
                    >
                      <p css={styles.fieldLabel}>
                        {t(`${appointmenti18nKey}.location`)}
                      </p>
                      <Input
                        type="text"
                        name="deliveryAppointmentLocation"
                        value={value}
                        placeholder={t(
                          `${appointmenti18nKey}.locationPlaceholder`,
                        )}
                        compact
                        error={!!errors.appointmentLocation}
                        onChange={onChange}
                      />
                    </div>
                  )
                }}
              />
              <Controller
                name="appointmentDate"
                defaultValue={
                  appointment?.date ? new Date(appointment?.date) : null
                }
                control={control}
                render={({ onChange, value }) => {
                  return (
                    <div
                      css={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        gap: 20,
                      }}
                    >
                      <p css={styles.fieldLabel}>
                        {t(`${appointmenti18nKey}.startingDate`)}
                      </p>
                      <DateTime
                        block={false}
                        value={value}
                        placeholder="01/03/2022 - 11h00"
                        onChange={onChange}
                        type="dateTime"
                        error={!!errors.appointmentDate}
                        // helperText={errors.appointmentDate?.message}
                      />
                    </div>
                  )
                }}
              />
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  gap: 20,
                }}
              >
                <p css={styles.fieldLabel}>
                  {t(`${appointmenti18nKey}.slots`)}
                </p>

                <Controller
                  name="appointmentDuration"
                  control={control}
                  defaultValue={appointment?.duration}
                  render={({ onChange, value }) => {
                    return (
                      <Input
                        type="text"
                        name="deliveryAppointmentDuration"
                        value={value}
                        placeholder="5"
                        error={!!errors.appointmentDuration}
                        helperText={errors.appointmentDuration?.message}
                        ultraCompact
                        subLabel={t(`${appointmenti18nKey}.durationSubLabel`)}
                        onlyNumbers
                        block={false}
                        // helperText={errors}
                        onChange={onChange}
                      />
                    )
                  }}
                />

                <Controller
                  name="appointmentEmployees"
                  control={control}
                  defaultValue={appointment?.capacity}
                  render={({ onChange, value }) => {
                    return (
                      <Input
                        type="text"
                        name="deliveryAppointmentEmployees"
                        value={value}
                        placeholder="5"
                        error={!!errors.appointmentEmployees}
                        helperText={errors.appointmentEmployees?.message}
                        subLabel={t(`${appointmenti18nKey}.employeesSubLabel`)}
                        ultraCompact
                        onlyNumbers
                        block={false}
                        // helperText={errors}
                        onChange={onChange}
                      />
                    )
                  }}
                />
              </div>

              {companyStepNumber >= ProgramStepType.PREPARING_LOAN && (
                <Controller
                  name="applyOnlyToNew"
                  control={control}
                  defaultValue={false}
                  render={({ onChange, value }) => {
                    return (
                      <div
                        css={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: 32,
                        }}
                      >
                        <p
                          style={styles.switchTitle}
                          onClick={() => onChange(false)}
                        >
                          {t('animator.company.progress.modal.applyToAll')}
                        </p>
                        <Switch
                          isChecked={value}
                          onClick={() => onChange(!value)}
                          color={theme.colors.greenLegend}
                        />
                        <p
                          style={styles.switchTitle}
                          onClick={() => onChange(true)}
                        >
                          {t('animator.company.progress.modal.applyToNew')}
                        </p>
                      </div>
                    )
                  }}
                />
              )}
            </Fragment>
          }
        </div>
      </form>
    </Popup>
  )
}

export default EditEventsModal

const styles = {
  headContainer: {
    height: 148,
    background: 'rgba(60, 217, 241, 0.07)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,

  headStepContainer: {
    cursor: 'pointer',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 24px',
  } as React.CSSProperties,

  headStep: {
    transition: '.3s ease all',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: 48,
    width: 48,
    borderRadius: '50%',
    border: '1px solid rgba(199, 199, 199, 0.87)',
    color: 'rgba(199, 199, 199, 0.87)',
  },

  headStepTitle: {
    transition: '.3s ease all',
    marginTop: 8,
    lineHeight: '21px',
    color: 'rgba(199, 199, 199, 0.87)',
  } as React.CSSProperties,

  headStepTitleSelected: {
    lineHeight: '21px',
    color: '#3CD9F1',
  } as React.CSSProperties,

  headStepSelected: {
    border: '1px solid #3CD9F1',
    background: 'white',
    color: '#3CD9F1',
  },

  title: {
    fontSize: '1.6rem',
    margin: '22px 0px',
  },

  switchTitle: {
    fontSize: '1.6rem',
    margin: '22px 0px',
    cursor: 'pointer',
    userSelect: 'none',
  } as React.CSSProperties,

  field: {
    display: 'flex',
    flexDirection: 'row',
  },
  fieldLabel: {
    width: 132,
    maxWidth: 132,
    minWidth: 132,
    fontSize: '1.6rem',
    color: theme.colors.gray3,
  },
}
