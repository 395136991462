/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import Button from '.'

interface SendMailButtonProps {
  mailTo: Array<string>
  subject?: string
  body?: string
  block?: boolean
  disabled?: boolean
}

const SendMailButton: React.FC<SendMailButtonProps> = ({
  mailTo,
  subject,
  body,
  block,
  disabled,
}) => {
  const mailToFormatted = mailTo.reduce((acc, curr, index) => {
    if (!index) {
      return acc + curr
    }
    return acc + '; ' + curr
  }, 'mailto:')
  const subjectFormatted = subject ? `?subject=${subject}` : ''
  const bodyFormatted = body ? `${!subject ? '?' : '&'}body=${body}` : ''

  const sendMail = () => {
    // eslint-disable-next-line no-restricted-globals
    location.href = mailToFormatted + subjectFormatted + bodyFormatted
  }

  return (
    <Button
      disabled={disabled}
      block={block}
      submit
      onClick={sendMail}
      icon="mail"
      iconColor="white"
    />
  )
}

export default SendMailButton
