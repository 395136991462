import { useMemo, useState } from 'react'
import { TableProps } from '../components/Table'
import { get } from 'react-hook-form'

export interface UseOrderByValue {
  columnName: string
  sort: 'asc' | 'desc'
}

export interface UseOrderByParam {
  defaultValue?: UseOrderByValue
}

export interface UseOrderByResult {
  orderBy: Record<string, 'asc' | 'desc'> | undefined
  getTableProps: () => Pick<TableProps, 'onSortBy'>
}

export const useOrderBy = ({
  defaultValue,
}: UseOrderByParam = {}): UseOrderByResult => {
  const [value, setValue] = useState(defaultValue)

  return {
    orderBy: value
      ? {
          [value.columnName]: value.sort,
        }
      : defaultValue
      ? { [defaultValue.columnName]: defaultValue.sort }
      : undefined,
    getTableProps: () => ({
      onSortBy: value => {
        if (!value.sort) {
          setValue(undefined)
        } else {
          setValue(value as UseOrderByValue)
        }
      },
    }),
  }
}

export const useLocalOrderBy = <ArrayT extends unknown[] | undefined>(
  orderBy: UseOrderByResult['orderBy'],
  arr: ArrayT,
): ArrayT => {
  return useMemo(() => {
    if (arr == null || orderBy == null) return arr
    const key = Object.keys(orderBy)[0]
    const fact = orderBy[key] === 'asc' ? 1 : -1

    if (key === 'totalDistance') {
      return arr
        .map(value => ({ value, sortKey: `${get(value, key, '')}` }))
        .sort((a, b) => (parseFloat(a.sortKey) - parseFloat(b.sortKey)) * fact)
        .map(item => item.value) as ArrayT
    }

    if (key === 'dailyDistance') {
      const realKey = 'employee.dailyDistance'
      return arr
        .map(value => ({ value, sortKey: `${get(value, realKey, '')}` }))
        .sort((a, b) => (parseFloat(a.sortKey) - parseFloat(b.sortKey)) * fact)
        .map(item => item.value) as ArrayT
    }

    if (key === 'bikeLoan.loanDate') {
      const realKey = 'employee.loanDeliveryAppointment[0].date'
      return arr
        .map(value => ({ value, sortKey: `${get(value, realKey, '')}` }))
        .sort(
          (a, b) =>
            (new Date(a.sortKey).getTime() - new Date(b.sortKey).getTime()) *
            fact,
        )
        .map(item => item.value) as ArrayT
    }

    if (key === 'bikeLoan.loanEndDate') {
      const realKey = 'employee.loanReturnAppointment[0].date'
      return arr
        .map(value => ({ value, sortKey: `${get(value, realKey, '')}` }))
        .sort(
          (a, b) =>
            (new Date(a.sortKey).getTime() - new Date(b.sortKey).getTime()) *
            fact,
        )
        .map(item => item.value) as ArrayT
    }

    if (
      key === 'bikeLoan.bike.stickerId' ||
      key === 'employee.workTravelDistance' ||
      key === 'employee.height'
    ) {
      return arr
        .map(value => ({ value, sortKey: `${get(value, key, '')}` }))
        .sort((a, b) => (parseInt(a.sortKey) - parseInt(b.sortKey)) * fact)
        .map(item => item.value) as ArrayT
    }

    if (key === 'employee.order') {
      return arr
        .map(value => ({ value, sortKey: `${get(value, key, '')}` }))
        .sort((a, b) => (parseInt(a.sortKey) - parseInt(b.sortKey)) * fact)
        .map(item => item.value) as ArrayT
    } else {
      return arr
        .map(value => ({ value, sortKey: `${get(value, key, '')}` }))
        .sort((a, b) => a.sortKey.localeCompare(b.sortKey) * fact)
        .map(item => item.value) as ArrayT
    }
  }, [orderBy, arr])
}
