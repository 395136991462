import {
  BikeComponentPartType,
  PackageAccessoryType,
  StateOfUseType,
} from '@goodwatt/shared/dist/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CompanyType,
  DeploymentType,
  AppointmentCategoryForDeployment,
} from '../__generated__/graphql'

type CompanySignUpState = {
  // company info
  companyName: string
  companyType: CompanyType

  // site
  siteName: string
  city: string
  street: string
  postalCode: string
  country: string
  nEmployees: string

  // referrer info
  email: string
  firstName: string
  lastName: string
  civility: string
  phoneNumber: string
  // quizz
  bikeInside: string
  bikeOutside: string
  bikeUnsafe: string
  carPlaces: string
  carElectricStations: string
}

interface EmployeeSignUpState {
  subscriptionCode: string
  firstName: string
  lastName: string
  civility: string
  email: string
  phoneNumber: string
  siteId: string
  siteCity: string
  siteStreet: string
  sitePostalCode: string
}

type Attachment = {
  id: string
  title: string
  size: number
}

type RatedEntity = {
  attachments?: Attachment[]
  stateOfUse?: StateOfUseType
  note?: string
  id?: string
}

type LoanAccessoryForm = RatedEntity & {
  included: boolean
  isDefaultIncluded?: boolean
}

type ComponentForm = RatedEntity & {
  touched: boolean
}

export enum EmployeeValidationState {
  TODO,
  SIGN_ON_APP,
  SIGN_PAPER,
  VALIDATED,
  CORRECTIONS_NEEDED,
}

export enum EmployeeSignType {
  NONE,
  SIGN_ON_APP,
  SIGN_PAPER,
}

interface DeploymentState {
  programStepNbr: number
  areaId: number
  deploymentId: string
  name: string
  deploymentType: DeploymentType
  siteId: string
  fleetId: string
  companyId: string
  cargoBike: boolean
  foldingBike: boolean
  urbanBike: boolean
  cargoBikeChildSeat: boolean
  urbanBikeChildSeat: boolean
  quantity: number
  appointmentLoanDelivery: {
    id: string
    category: AppointmentCategoryForDeployment
    date: string
    location: string
    duration: number
    capacity: number
    resetAppointments: boolean
  }
  appointmentLoanReturn: {
    id: string
    category: AppointmentCategoryForDeployment
    date: string
    location: string
    duration: number
    capacity: number
    resetAppointments: boolean
  }
  deploymentBikeModelPrice: {
    id: string
    checked: boolean
    bikeModelId: string
    remainingPrice: number
    childSeat: boolean
  }[]
}

interface LoanBikeState {
  componentsFetched: boolean
  bikeId: string
  stickerId: number
  bikeLoanId?: string
  components: {
    [BikeComponentPartType.BIKE_FRAME]: ComponentForm
    [BikeComponentPartType.BRAKES]: ComponentForm
    [BikeComponentPartType.BACK_WHEEL]: ComponentForm
    [BikeComponentPartType.FRONT_WHEEL]: ComponentForm
    [BikeComponentPartType.TRANSMISSIONS]: ComponentForm
    [BikeComponentPartType.HANDLEBAR]: ComponentForm
    [BikeComponentPartType.DEVICES]: ComponentForm
    [BikeComponentPartType.BATTERY]: ComponentForm
    [BikeComponentPartType.GLOBAL]: ComponentForm
  }
  accessories: {
    [PackageAccessoryType.LOCK]: LoanAccessoryForm
    [PackageAccessoryType.BATTERY_CHARGER]: LoanAccessoryForm
    [PackageAccessoryType.SATCHELS]: LoanAccessoryForm
    [PackageAccessoryType.SMARTPHONE_HOLDER]: LoanAccessoryForm
    [PackageAccessoryType.RAIN_ACCESSORIES]: LoanAccessoryForm
    [PackageAccessoryType.HELMET]: LoanAccessoryForm
    [PackageAccessoryType.KIDS_HELMET]: LoanAccessoryForm
    [PackageAccessoryType.BABY_SEAT]: LoanAccessoryForm
    [PackageAccessoryType.FRONT_RACK]: LoanAccessoryForm
  }
  employeeValidation: EmployeeValidationState
  employeeSignType: EmployeeSignType
  animatorValidation: boolean
}

type ReturnBikeState = Pick<
  LoanBikeState,
  | 'bikeId'
  | 'animatorValidation'
  | 'components'
  | 'componentsFetched'
  | 'stickerId'
> & {
  bikeLoanId: string
  accessories: {
    [PackageAccessoryType.LOCK]?: RatedEntity
    [PackageAccessoryType.BATTERY_CHARGER]?: RatedEntity
    [PackageAccessoryType.SATCHELS]?: RatedEntity
    [PackageAccessoryType.SMARTPHONE_HOLDER]?: RatedEntity
    [PackageAccessoryType.RAIN_ACCESSORIES]?: RatedEntity
    [PackageAccessoryType.HELMET]?: RatedEntity
    [PackageAccessoryType.KIDS_HELMET]?: RatedEntity
    [PackageAccessoryType.BABY_SEAT]?: RatedEntity
    [PackageAccessoryType.FRONT_RACK]?: RatedEntity
  }
}

export interface FormsState {
  companySignUpForm: CompanySignUpState
  employeeSignUpForm: EmployeeSignUpState
  loanBikeForm: LoanBikeState
  returnBikeForm: ReturnBikeState
  deploymentForm: DeploymentState
}

const initialState: FormsState = {
  deploymentForm: {
    programStepNbr: 0,
    areaId: 0,
    deploymentId: '',
    name: '',
    deploymentType: DeploymentType.None,
    siteId: '',
    fleetId: '',
    companyId: '',
    cargoBike: false,
    foldingBike: false,
    urbanBike: false,
    cargoBikeChildSeat: false,
    urbanBikeChildSeat: false,
    quantity: 0,
    appointmentLoanDelivery: {
      id: '',
      category: AppointmentCategoryForDeployment.LoanDelivery,
      date: '',
      location: '',
      duration: 0,
      capacity: 0,
      resetAppointments: false,
    },
    appointmentLoanReturn: {
      id: '',
      category: AppointmentCategoryForDeployment.LoanReturn,
      date: '',
      location: '',
      duration: 0,
      capacity: 0,
      resetAppointments: false,
    },
    deploymentBikeModelPrice: [],
  },
  companySignUpForm: {
    // company info
    companyName: '',
    companyType: CompanyType.None,
    city: '',
    street: '',
    postalCode: '',
    country: 'France',
    nEmployees: '',
    siteName: '',
    // referrer info
    email: '',
    firstName: '',
    lastName: '',
    civility: '',
    phoneNumber: '',
    // quizz
    bikeInside: '',
    bikeOutside: '',
    bikeUnsafe: '',
    carPlaces: '',
    carElectricStations: '',
  },
  employeeSignUpForm: {
    subscriptionCode: '',
    firstName: '',
    civility: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    siteId: '',
    siteCity: '',
    siteStreet: '',
    sitePostalCode: '',
  },
  loanBikeForm: {
    stickerId: 0,
    componentsFetched: false,
    bikeId: '',
    components: {
      [BikeComponentPartType.BIKE_FRAME]: {
        touched: false,
      },
      [BikeComponentPartType.BRAKES]: {
        touched: false,
      },
      [BikeComponentPartType.BACK_WHEEL]: {
        touched: false,
      },
      [BikeComponentPartType.FRONT_WHEEL]: {
        touched: false,
      },
      [BikeComponentPartType.TRANSMISSIONS]: {
        touched: false,
      },
      [BikeComponentPartType.HANDLEBAR]: {
        touched: false,
      },
      [BikeComponentPartType.DEVICES]: {
        touched: false,
      },
      [BikeComponentPartType.BATTERY]: {
        touched: false,
      },
      [BikeComponentPartType.GLOBAL]: {
        touched: false,
      },
    },
    accessories: {
      [PackageAccessoryType.LOCK]: {
        included: false,
        isDefaultIncluded: true,
      },
      [PackageAccessoryType.BATTERY_CHARGER]: {
        included: false,
        isDefaultIncluded: true,
      },
      [PackageAccessoryType.SATCHELS]: {
        included: false,
        isDefaultIncluded: true,
      },
      [PackageAccessoryType.SMARTPHONE_HOLDER]: {
        included: false,
        isDefaultIncluded: true,
      },
      [PackageAccessoryType.RAIN_ACCESSORIES]: {
        included: false,
        isDefaultIncluded: true,
      },
      [PackageAccessoryType.HELMET]: {
        included: false,
        isDefaultIncluded: true,
      },
      [PackageAccessoryType.KIDS_HELMET]: {
        included: false,
      },
      [PackageAccessoryType.BABY_SEAT]: {
        included: false,
      },
      [PackageAccessoryType.FRONT_RACK]: {
        included: false,
        isDefaultIncluded: true,
      },
    },
    animatorValidation: false,
    employeeValidation: EmployeeValidationState.TODO,
    employeeSignType: EmployeeSignType.NONE,
  },
  returnBikeForm: {
    componentsFetched: false,
    bikeId: '',
    stickerId: 0,
    bikeLoanId: '',
    components: {
      [BikeComponentPartType.BIKE_FRAME]: {
        touched: false,
      },
      [BikeComponentPartType.BRAKES]: {
        touched: false,
      },
      [BikeComponentPartType.BACK_WHEEL]: {
        touched: false,
      },
      [BikeComponentPartType.FRONT_WHEEL]: {
        touched: false,
      },
      [BikeComponentPartType.TRANSMISSIONS]: {
        touched: false,
      },
      [BikeComponentPartType.HANDLEBAR]: {
        touched: false,
      },
      [BikeComponentPartType.DEVICES]: {
        touched: false,
      },
      [BikeComponentPartType.BATTERY]: {
        touched: false,
      },
      [BikeComponentPartType.GLOBAL]: {
        touched: false,
      },
    },
    accessories: {},
    animatorValidation: false,
  },
}

const forms = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    setCompanySignUpForm(
      state: FormsState,
      action: PayloadAction<
        Partial<{
          [key in keyof CompanySignUpState]: any
        }>
      >,
    ) {
      const form = action.payload
      return {
        ...state,
        companySignUpForm: { ...state.companySignUpForm, ...form },
      }
    },
    resetCompanySignUpForm(state: FormsState) {
      return { ...state, companySignUpForm: initialState.companySignUpForm }
    },

    setEmployeeSignUpForm(
      state: FormsState,
      action: PayloadAction<
        Partial<{
          [key in keyof EmployeeSignUpState]: string
        }>
      >,
    ) {
      const form = action.payload
      return {
        ...state,
        employeeSignUpForm: { ...state.employeeSignUpForm, ...form },
      }
    },

    resetEmployeeSignUpForm(state: FormsState) {
      return { ...state, employeeSignUpForm: initialState.employeeSignUpForm }
    },

    setLoanBikeForm(
      state: FormsState,
      action: PayloadAction<Partial<LoanBikeState>>,
    ) {
      const form = action.payload
      if (
        state.loanBikeForm.employeeValidation ===
          EmployeeValidationState.VALIDATED &&
        Object.keys(action.payload).some(
          key =>
            key === 'bikeId' ||
            key === 'secondariesCheck' ||
            key === 'components' ||
            key === 'accessories',
        )
      ) {
        form.employeeValidation = EmployeeValidationState.TODO
      }
      return {
        ...state,
        loanBikeForm: { ...state.loanBikeForm, ...form },
      }
    },

    updateBikeLoanAccessory(
      state: FormsState,
      action: PayloadAction<{
        name: PackageAccessoryType
        payload: Partial<LoanAccessoryForm>
      }>,
    ) {
      const form = action.payload

      const accessories = {
        ...state.loanBikeForm.accessories,
        [form.name]: {
          ...form.payload,
        },
      }

      return {
        ...state,
        loanBikeForm: {
          ...state.loanBikeForm,
          accessories,
        },
      }
    },
    setDeploymentForm(
      state: FormsState,
      action: PayloadAction<Partial<DeploymentState>>,
    ) {
      const form = action.payload
      return {
        ...state,
        deploymentForm: { ...state.deploymentForm, ...form },
      }
    },
    resetDeploymentForm(state: FormsState) {
      return { ...state, deploymentForm: initialState.deploymentForm }
    },
    resetLoanBikeForm(state: FormsState) {
      return { ...state, loanBikeForm: initialState.loanBikeForm }
    },
    setReturnBikeForm(
      state: FormsState,
      action: PayloadAction<Partial<ReturnBikeState>>,
    ) {
      const form = action.payload
      return {
        ...state,
        returnBikeForm: { ...state.returnBikeForm, ...form },
      }
    },
    resetReturnBikeForm(state: FormsState) {
      return { ...state, returnBikeForm: initialState.returnBikeForm }
    },
  },
})

export const {
  setCompanySignUpForm,
  resetCompanySignUpForm,
  setEmployeeSignUpForm,
  resetEmployeeSignUpForm,
  setLoanBikeForm,
  updateBikeLoanAccessory,
  resetLoanBikeForm,
  setReturnBikeForm,
  resetReturnBikeForm,
  setDeploymentForm,
  resetDeploymentForm,
} = forms.actions

export default forms.reducer
