/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'

import {
  PendingTableModal,
  PendingCompaniesTableRow,
  CompaniesModalSettings,
} from '../../../types/AnimatorCompanies'
import Icon from '../../Icon'
import Tooltip from '../../Tooltip'

const PendingCompaniesActionCell: React.FC<{
  setModal: (
    obj: CompaniesModalSettings<PendingCompaniesTableRow, PendingTableModal>,
  ) => void
  row: Row<PendingCompaniesTableRow>
}> = ({ setModal, row }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      icon: PendingTableModal,
    ) => {
      e.stopPropagation()
      setModal({ company: row.original, name: icon })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        css={{ cursor: 'pointer', marginRight: '7px' }}
        onClick={e => handleClick(e, PendingTableModal.INFO)}
      >
        <Tooltip
          title={t('animator.companies.tooltip.companyInfo')}
          placement="top-end"
        >
          <Icon type="info" color={theme.colors.gray1} />
        </Tooltip>
      </div>
      <div
        css={{ cursor: 'pointer', marginRight: '7px' }}
        onClick={e => handleClick(e, PendingTableModal.APPROVE)}
      >
        <Tooltip
          title={t('animator.companies.tooltip.acceptCompany')}
          placement="top-end"
        >
          <Icon type="tick" color="transparent" />
        </Tooltip>
      </div>
      <div
        css={{ cursor: 'pointer' }}
        onClick={e => handleClick(e, PendingTableModal.REFUSE)}
      >
        <Tooltip
          title={t('animator.companies.tooltip.refuseCompany')}
          placement="top-end"
        >
          <Icon type="block" color={theme.colors.error} />
        </Tooltip>
      </div>
    </div>
  )
}

export default PendingCompaniesActionCell
