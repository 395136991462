/** @jsx jsx */
import { jsx } from '@emotion/core'
import Modal from '../../Modal'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import {
  CreateSiteMutation,
  CreateSiteMutationVariables,
  UpdateSiteMutation,
  UpdateSiteMutationVariables,
} from '../../../__generated__/graphql'
import { countiesList } from '../../../utils/countries'
import Input from '../../Input'
import InputSelect from '../../InputSelect'
import { yupResolver } from '@hookform/resolvers'
import i18n from '../../../i18n/config'
import { useForm } from 'react-hook-form'

import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  MUTATION_CREATE_SITE,
  MUTATION_UPDATE_SITE,
} from '../../../utils/gql/mutations'
import { StoreState } from '../../../redux/rootReducer'
import {
  QUERY_GET_COMPANY_SITES,
  QUERY_GET_SITE,
} from '../../../utils/gql/queries'
import { TFunction } from 'i18next'
import StepPopup from '../../Popup/StepPopup'
import {
  resetCompanySignUpForm,
  setCompanySignUpForm,
} from '../../../redux/forms'
import {
  NotificationTypes,
  useNotifications,
} from '../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import { useTheme } from 'emotion-theming'
import { UserRoles } from '../../../redux/user'
import { useCurrentCompanyId } from '../../../pages/Animator/Companies/hooks/useCurrentCompanyId'

interface CompanySiteCreateInputs {
  // Site
  name: string
  nEmployees: string
  // Address
  street: string
  postalCode: string
  city: string
  country: {
    value: string
    label: string
  }
  // Bike
  bikeInside: string
  bikeOutside: string
  bikeUnsafe: string
  // Car
  carPlaces: string
  carElectricStations: string
}

interface CreateSiteModalProps {
  isOpen: boolean
  onModalClose: () => void
  siteId?: string
  type?: 'update' | 'create'
}

const schema = yup.object().shape({
  name: yup.string().required(i18n.t('forms.errors.required')),
  postalCode: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .length(5, i18n.t('forms.errors.wrongFormat')),
  city: yup.string().required(i18n.t('forms.errors.required')),
  nEmployees: yup.string().required(i18n.t('forms.errors.required')),
})

export function CreateSiteModal({
  isOpen,
  onModalClose,
  siteId,
  type,
}: CreateSiteModalProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notifications = useNotifications()
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true)
  const [step, setStep] = React.useState<number>(0)

  const userRoleName = useSelector(
    (state: StoreState) => state.user?.role,
  ) as string

  let companyId = ''
  const userCompanyId = useSelector(
    (state: StoreState) => state.user?.companyId,
  ) as string
  const animatorCompanyId = useCurrentCompanyId()

  if (userRoleName === UserRoles.ANIMATOR || userRoleName === UserRoles.ADMIN) {
    companyId = animatorCompanyId
  } else {
    companyId = userCompanyId
  }

  const selectedSite = useQuery(QUERY_GET_SITE, {
    variables: { id: siteId },
    skip: siteId === undefined || siteId === '' || type === 'create',
  })

  const form = useSelector((state: StoreState) => state.forms.companySignUpForm)

  const { register, watch, getValues, setValue, errors } =
    useForm<CompanySiteCreateInputs>({
      resolver: yupResolver(schema),
      mode: 'onBlur',
    })

  React.useEffect(() => {
    if (type === 'update' && selectedSite.data) {
      const site = selectedSite.data.site

      if (step === 0) {
        setValue('name', site.name)
        setValue('street', site.street)
        setValue('postalCode', site.postalCode)
        setValue('city', site.city)
        setValue('nEmployees', site.employeesOnSite.toString())
        setValue('country', site.country)
      } else {
        setValue('bikeInside', site.bikeSpaceCapacityInside.toString())
        setValue('bikeOutside', site.bikeSpaceCapacityOutside.toString())
        setValue('bikeUnsafe', site.bikeSpaceCapacityUnsafe.toString())
        setValue('carPlaces', site.carSpaceCapacity.toString())
        setValue('carElectricStations', site.electricStationCapacity.toString())
      }
    }
  }, [siteId, selectedSite.data, step, type, setValue])

  const [createSite, { loading }] = useMutation<
    CreateSiteMutation,
    CreateSiteMutationVariables
  >(MUTATION_CREATE_SITE, {
    refetchQueries: [
      {
        query: QUERY_GET_COMPANY_SITES,
        variables: {
          where: {
            companyId: { equals: companyId },
          },
        },
      },
      'GetCompanyProfile',
      'GetCompanyAllSites',
      'GetSite',
    ],
  })

  const [updateSite] = useMutation<
    UpdateSiteMutation,
    UpdateSiteMutationVariables
  >(MUTATION_UPDATE_SITE, {
    refetchQueries: [
      {
        query: QUERY_GET_COMPANY_SITES,
        variables: {
          where: {
            companyId: { equals: companyId },
          },
        },
      },
      'GetCompanyProfile',
      'GetCompanyAllSites',
      'GetSite',
    ],
  })

  const handleSubmit = async () => {
    const formValue = getValues()

    if (isNextDisabled) return false
    else if (step === 0) {
      dispatch(
        setCompanySignUpForm({
          siteName: formValue.name,
          street: formValue.street,
          postalCode: formValue.postalCode,
          city: formValue.city,
          nEmployees: formValue.nEmployees,
          country: formValue.country,
        }),
      )
      return true
    } else if (
      !formValue.bikeInside ||
      !formValue.bikeOutside ||
      !formValue.bikeUnsafe ||
      !formValue.carPlaces ||
      !formValue.carElectricStations
    )
      return false

    if (type === 'update') {
      await updateSite({
        variables: {
          data: {
            id: siteId ? siteId : '',
            companyId: companyId,
            name: form.siteName,
            address: {
              street: form.street,
              postalCode: form.postalCode,
              city: form.city,
              country: form.country,
            },
            nbrEmployees: parseInt(form.nEmployees),
            bike: {
              inside: parseInt(formValue.bikeInside),
              outside: parseInt(formValue.bikeOutside),
              unsafe: parseInt(formValue.bikeUnsafe),
            },
            car: {
              places: parseInt(formValue.carPlaces),
              electricStations: parseInt(formValue.carElectricStations),
            },
          },
        },
      })
        .then(() => {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: i18n.t('shared.notification.siteSuccessUpdate'),
          })
          setStep(0)
          setIsNextDisabled(true)
          resetCompanySignUpForm()
          onModalClose()
          return true
        })
        .catch(apiErrorCatcher(notifications))
    } else {
      await createSite({
        variables: {
          site: {
            name: form.siteName,
            address: {
              street: form.street,
              postalCode: form.postalCode,
              city: form.city,
              country: form.country,
            },
            nbrEmployees: parseInt(form.nEmployees),
            bike: {
              inside: parseInt(formValue.bikeInside),
              outside: parseInt(formValue.bikeOutside),
              unsafe: parseInt(formValue.bikeUnsafe),
            },
            car: {
              places: parseInt(formValue.carPlaces),
              electricStations: parseInt(formValue.carElectricStations),
            },
            companyId: companyId,
          },
        },
      })
        .then(() => {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.notification.siteSuccessCreate'),
          })
          setStep(0)
          setIsNextDisabled(true)
          resetCompanySignUpForm()
          onModalClose()
          return true
        })
        .catch(apiErrorCatcher(notifications))
    }

    return true
  }

  React.useEffect(() => {
    let currNextDisabled = false

    if (step === 0) {
      const value = watch([
        'name',
        'street',
        'postalCode',
        'city',
        'country',
        'nEmployees',
      ])

      currNextDisabled =
        !value.name ||
        !value.street ||
        !value.postalCode ||
        !value.city ||
        !value.country ||
        !value.nEmployees
    }

    setIsNextDisabled(currNextDisabled)
  }, [watch, step])

  const steps = React.useMemo(
    () => [
      {
        height: 440,
        mobileHeight: 280,
        title:
          type === 'update'
            ? t('auth.signupCompany.step3TitleEdit')
            : t('auth.signupCompany.step3Title'),
        isFirst: true,
        isLast: false,
      },
      {
        height: 395,
        mobileHeight: 280,
        title: t('auth.signupCompany.step4Title'),
        isFirst: false,
        isLast: true,
      },
    ],
    [t, type],
  )

  const changeStep = (direction: 1 | -1) => {
    if (direction > 0) {
      setStep(prevState => prevState + 1)
    } else if (direction < 0) {
      setStep(prevState => prevState - 1)
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <StepPopup
        processStep={steps[step]}
        processStepNbr={step}
        changeStep={changeStep}
        canGoNext={handleSubmit}
        disableNext={isNextDisabled}
        loading={loading}
        closeModal={onModalClose}
      >
        <form>
          {step === 0 ? (
            <Step1
              register={register}
              errors={errors}
              t={t}
              setValue={setValue}
              getValues={getValues}
            />
          ) : (
            <Step2
              register={register}
              errors={errors}
              t={t}
              setValue={() => {}}
              getValues={() => {}}
            />
          )}
        </form>
      </StepPopup>
    </Modal>
  )
}

interface StepsProps {
  register: any
  errors: any
  t: TFunction
  setValue: any
  getValues: any
}
const Step1: React.FC<StepsProps> = ({
  register,
  errors,
  t,
  setValue,
  getValues,
}) => {
  const onChangeCountry = async (data: any) => {
    setValue('country', data.value)
  }

  const formValue = getValues()

  const countries = countiesList.map(x => ({
    label: x,
    value: x,
  }))

  React.useEffect(() => {
    if (!formValue.country) {
      setValue('country', 'France')
    }
  }, [formValue, setValue])

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        textAlign: 'initial',
        margin: 'auto',
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <Input
          type="text"
          name="name"
          label={t('forms.label.companySiteName')}
          error={!!errors.name}
          helperText={errors.name?.message}
          register={register}
        />
        <Input
          type="text"
          name="street"
          label={t('forms.label.companyAddress')}
          error={!!errors.street}
          helperText={errors.street?.message}
          register={register}
        />
      </div>

      <div css={{ display: 'flex', flexDirection: 'row' }}>
        <div css={{ flex: 2, marginRight: 15 }}>
          <Input
            type="text"
            name="postalCode"
            label={t('forms.label.postalCode')}
            error={!!errors.postalCode}
            helperText={errors.postalCode?.message}
            maxLength={5}
            register={register}
          />
        </div>
        <div css={{ flex: 3 }}>
          <Input
            type="text"
            name="city"
            label={t('forms.label.city')}
            error={!!errors.city}
            helperText={errors.city?.message}
            register={register}
          />
        </div>
      </div>
      <div>
        <InputSelect
          value={
            formValue.country !== ''
              ? countries.find(c => c.label === formValue.country)
              : 'France'
          }
          label={t('forms.label.country')}
          options={countries}
          name="country"
          error={!!errors.country?.value}
          helperText={errors.country?.value?.message}
          onChange={onChangeCountry}
          register={register('country')}
        />
      </div>
      <div>
        <Input
          type="text"
          name="nEmployees"
          label={t('forms.label.nEmployees')}
          placeholder={t('forms.label.nEmployeesPlaceholder')}
          error={!!errors.nEmployees}
          helperText={errors.nEmployees?.message}
          register={register}
          onlyNumbers
          compact
        />
      </div>
    </div>
  )
}
const Step2: React.FC<StepsProps> = ({ register, errors, t }) => {
  const theme = useTheme()

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        textAlign: 'initial',
        margin: 'auto',
      }}
    >
      <p
        css={{
          paddingLeft: '11px',
          fontSize: '1.6rem',
          marginBottom: '20px',
        }}
      >
        {t('auth.signupCompany.bikeFormDesc')}
      </p>

      <div
        css={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}
      >
        <div css={{ flex: 4, marginRight: 15 }}>
          <p
            css={{
              paddingLeft: '11px',
              fontSize: '1.4rem',
              color: theme.colors.gray3,
            }}
          >
            {t('auth.signupCompany.bikeInside')}
          </p>
        </div>
        <div css={{ flex: 2 }}>
          <Input
            type="text"
            name="bikeInside"
            placeholder="ex: 0"
            error={!!errors.bikeInside}
            helperText={errors.bikeInside?.message}
            onlyNumbers
            register={register}
            compact
          />
        </div>
      </div>

      <div
        css={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}
      >
        <div css={{ flex: 4, marginRight: 15 }}>
          <p
            css={{
              paddingLeft: '11px',
              fontSize: '1.4rem',
              color: theme.colors.gray3,
            }}
          >
            {t('auth.signupCompany.bikeOutside')}
          </p>
        </div>
        <div css={{ flex: 2 }}>
          <Input
            type="text"
            name="bikeOutside"
            placeholder="ex: 36"
            error={!!errors.bikeOutside}
            helperText={errors.bikeOutside?.message}
            onlyNumbers
            register={register}
            compact
          />
        </div>
      </div>

      <div
        css={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}
      >
        <div css={{ flex: 4, marginRight: 15 }}>
          <p
            css={{
              paddingLeft: '11px',
              fontSize: '1.4rem',
              color: theme.colors.gray3,
            }}
          >
            {t('auth.signupCompany.bikeUnsafe')}
          </p>
        </div>
        <div css={{ flex: 2 }}>
          <Input
            type="text"
            name="bikeUnsafe"
            placeholder="ex: 15"
            error={!!errors.bikeUnsafe}
            helperText={errors.bikeUnsafe?.message}
            onlyNumbers
            register={register}
            compact
          />
        </div>
      </div>

      <p
        css={{
          marginTop: '0',
          paddingLeft: '11px',
          fontSize: '1.6rem',
          marginBottom: '15px',
        }}
      >
        {t('auth.signupCompany.carFormDesc')}
      </p>

      <div
        css={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}
      >
        <div css={{ flex: 4, marginRight: 15 }}>
          <p
            css={{
              paddingLeft: '11px',
              fontSize: '1.4rem',
              color: theme.colors.gray3,
            }}
          >
            {t('auth.signupCompany.carPlaces')}
          </p>
        </div>
        <div css={{ flex: 2 }}>
          <Input
            type="text"
            name="carPlaces"
            placeholder="ex: 150"
            error={!!errors.carPlaces}
            helperText={errors.carPlaces?.message}
            onlyNumbers
            register={register}
            compact
          />
        </div>
      </div>

      <div
        css={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}
      >
        <div css={{ flex: 4, marginRight: 15 }}>
          <p
            css={{
              paddingLeft: '11px',
              fontSize: '1.4rem',
              color: theme.colors.gray3,
            }}
          >
            {t('auth.signupCompany.carElectricStations')}
          </p>
        </div>
        <div css={{ flex: 2 }}>
          <Input
            type="text"
            name="carElectricStations"
            placeholder="ex: 5"
            error={!!errors.carElectricStations}
            helperText={errors.carElectricStations?.message}
            onlyNumbers
            register={register}
            compact
          />
        </div>
      </div>
    </div>
  )
}
