/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { useCallback, useEffect, useRef } from 'react'

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined

interface InputCameraProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: (instance: any) => RefReturn
  name?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  isOpen?: boolean
  onInputOpen?: () => void
  disabled?: boolean
  label: string
}

const InputCamera: React.FC<InputCameraProps> = ({
  register,
  onChange,
  name,
  disabled = false,
  label,
  isOpen = false,
  onInputOpen,
}) => {
  const uploadInputCamera = useRef<HTMLInputElement | null>()
  const theme = useTheme()

  const openInputCamera = useCallback(() => {
    if (disabled) return
    uploadInputCamera?.current?.click()
    if (onInputOpen) onInputOpen()
  }, [onInputOpen, disabled])

  useEffect(() => {
    if (isOpen && uploadInputCamera?.current) {
      openInputCamera()
    }
  }, [isOpen, openInputCamera])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e)
    if (uploadInputCamera.current?.value) uploadInputCamera.current.value = ''
  }

  return (
    <div
      css={{
        cursor: 'pointer',
      }}
      onClick={openInputCamera}
    >
      <span
        css={{
          color: disabled ? theme.colors.gray2 : theme.colors.primary,
          textDecoration: 'underline',
        }}
      >
        {label}
      </span>
      <input
        ref={e => {
          if (register) register(e)
          uploadInputCamera.current = e
        }}
        css={{ display: 'none' }}
        type="file"
        accept="image/*"
        multiple={false}
        name={name}
        capture="camera"
        onChange={handleOnChange}
      />
    </div>
  )
}

export default InputCamera
