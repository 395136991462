/** @jsx jsx */
import { gql, useMutation } from '@apollo/client'
import { jsx } from '@emotion/core'
import { constants } from '@goodwatt/shared'
import { yupResolver } from '@hookform/resolvers'
import React, { Fragment, useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import ActionCard from '../../../../components/Card/ActionCard'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import usePromiseLazyQuery from '../../../../hooks/usePromiseLazyQuery'
import i18n from '../../../../i18n/config'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import {
  DeleteConventionMutation,
  DeleteConventionMutationVariables,
  GetCompanyConventionQuery,
  GetCompanyConventionQueryVariables,
  UploadConventionMutation,
  UploadConventionMutationVariables,
} from '../../../../__generated__/graphql'

const MUTATION_UPLOAD_CONVENTION = gql`
  mutation UploadConvention($file: Upload!, $companyId: String!) {
    uploadConvention(file: $file, companyId: $companyId) {
      conventionFilePath
    }
  }
`

const MUTATION_DELETE_CONVENTION = gql`
  mutation DeleteConvention($companyId: String!) {
    deleteConvention(companyId: $companyId)
  }
`

const QUERY_GET_CONVENTION = gql`
  query GetCompanyConvention($where: CompanyWhereUniqueInput!) {
    company(where: $where) {
      getPresignedConventionDocument
    }
  }
`

const schema = yup.object().shape({
  file: yup
    .mixed()
    .required(i18n.t('forms.errors.required'))
    .test('fileSize', i18n.t('forms.errors.fileSize'), value => {
      if (!value || value.length === 0) return true
      return value[0].size <= constants.MAX_DOCUMENT_SIZE
    })
    .test('fileType', i18n.t('forms.errors.fileFormat'), value => {
      if (!value || value.length === 0) return true
      return Object.keys(constants.SUPPORTED_FORMATS_DOCS).includes(
        value[0].type,
      )
    }),
})

interface UploadConventionProps {
  companyId?: string
  refetch?: () => void
  hasConvention?: boolean
  deletable?: boolean
}

interface FormInput {
  file: FileList
}

const CompanyUploadConvention: React.FC<UploadConventionProps> = ({
  companyId,
  refetch,
  hasConvention = false,
  deletable = true,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [deleteConvention] = useMutation<
    DeleteConventionMutation,
    DeleteConventionMutationVariables
  >(MUTATION_DELETE_CONVENTION)
  const getPresignedConvention = usePromiseLazyQuery<
    GetCompanyConventionQuery,
    GetCompanyConventionQueryVariables
  >(QUERY_GET_CONVENTION)
  const [uploadConvention] = useMutation<
    UploadConventionMutation,
    UploadConventionMutationVariables
  >(MUTATION_UPLOAD_CONVENTION)
  const uploadInput = useRef<HTMLInputElement | null>()
  const { register, handleSubmit, trigger, reset, errors } = useForm<FormInput>(
    {
      resolver: yupResolver(schema),
      mode: 'onBlur',
    },
  )

  const onDelete = useCallback(() => {
    deleteConvention({
      variables: {
        companyId: companyId as string,
      },
    })
      .then(r => {
        if (r.data?.deleteConvention && refetch) refetch()
      })
      .catch(apiErrorCatcher(notifications))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, deleteConvention, refetch])

  const getDocument = useCallback(() => {
    getPresignedConvention({
      where: {
        id: companyId,
      },
    })
      .then(r => {
        if (r.data?.company?.getPresignedConventionDocument)
          window.open(r.data?.company.getPresignedConventionDocument, '_blank')
      })
      .catch(apiErrorCatcher(notifications))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPresignedConvention])

  const onSubmit = (data: FormInput) => {
    if (!companyId) return
    uploadConvention({
      variables: {
        file: data.file[0],
        companyId: companyId,
      },
    })
      .then(() => {
        if (refetch) refetch()
        reset()
      })
      .catch(apiErrorCatcher(notifications))
  }

  useEffect(() => {
    if (errors?.file?.message)
      notifications.newNotification({
        type: NotificationTypes.ERROR,
        message: errors.file.message,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  return (
    <div css={{ marginBottom: 40, display: 'flex' }}>
      {hasConvention ? (
        <ActionCard
          variant="primary"
          icon="signedConvention"
          label={t('shared.documents.signedConvention')}
          actions={[
            { icon: 'download', onClick: getDocument },
            ...((deletable
              ? [{ icon: 'trash', onClick: onDelete }]
              : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                []) as any),
          ]}
        />
      ) : (
        <Fragment>
          <ActionCard
            variant="secondary"
            icon="signedConvention"
            label={t('shared.documents.uploadSignedConvention')}
            onCardClick={() => uploadInput?.current?.click()}
          />
          <input
            name="file"
            type="file"
            css={{ display: 'none' }}
            ref={e => {
              if (register) register(e)
              uploadInput.current = e
            }}
            onChange={async () => {
              await trigger('file')
              handleSubmit(onSubmit)()
            }}
          />
        </Fragment>
      )}
    </div>
  )
}

export default CompanyUploadConvention
