export enum PendingTableModal {
  APPROVE = 'approve',
  REFUSE = 'refuse',
  INFO = 'info',
}

export enum RefusedTableModal {
  UNDO = 'undo',
  EDIT = 'edit',
  DELETE = 'delete',
}

export enum AccepetedTableModal {
  DELETE = 'delete',
  ARCHIVE = 'archive',
}

export enum ArchiveTableModal {
  DELETE = 'delete',
  UNARCHIVE = 'unarchive',
}

export type CompaniesModalSettings<
  D = RefusedCompaniesTableRow | PendingCompaniesTableRow,
  T = RefusedTableModal | PendingTableModal,
> = {
  company: D
  name: '' | T
}

export type AccepetedCompaniesTableRow = {
  id: string | undefined
}

export type RefusedCompaniesTableRow = {
  id: string | undefined
  companyType: string | undefined
  name: string | undefined
  municipality: { city: string | undefined; postalCode: string | undefined }
  nbrEmployees: number | undefined
  rejectionDate: string | undefined
  rejectionReason: string | undefined
  rejectionNote: string | undefined | null
}

export type ArchivedCompaniesTableRow = {
  id: string | undefined
  subscriptionCode: string | undefined
  name: string | undefined
  fleetName: string | undefined
  areaName: string | undefined
  nbrEmployees: number | undefined
  employeesSelectedForLoan: string | undefined
  loanEndDate: string | undefined
  bikeTotalDistance: string | undefined
  archivedAt: string
  archiveStatus: string | undefined
}

export type PendingCompaniesTableRow = {
  id: string | undefined
  name: string | undefined
  municipality: { city: string | undefined; postalCode: string | undefined }
  nbrEmployees: number | undefined
  areaId: number | undefined
  companyType: string | undefined
}
