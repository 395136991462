import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useModal } from '../../../../hooks/useModal'
import theme from '../../../../styles/theme'
import { Deployment, Employee } from '../../../../__generated__/graphql'
import LoanBikeModal from '../../Company/Employees/SelectedEmployees/LoanBikeModal'
import { format } from 'date-fns'

export interface PickupBikeButtonProps {
  deployment: Deployment | undefined
  employee: Employee
  employeeDeploymentId: string
}

export const PickupBikeButton = ({
  deployment,
  employee,
  employeeDeploymentId,
}: PickupBikeButtonProps) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      {modalProps.isOpen && (
        <LoanBikeModal
          employee={employee}
          employeeDeploymentId={employeeDeploymentId}
          loanEventsFormatted={{
            loanDeliveryDate: deployment?.deploymentStartDate
              ? format(new Date(deployment?.deploymentStartDate), 'dd/MM/yyyy')
              : '...',
            loanReturnDate: deployment?.deploymentEndDate
              ? format(new Date(deployment?.deploymentEndDate), 'dd/MM/yyyy')
              : '...',
          }}
          refetchTables={() => {}}
          closeModal={modalProps.onClose}
          modalIsOpen={modalProps.isOpen}
          {...modalProps}
        />
      )}
      <button onClick={onOpen}>
        <Icon type="bike" color={theme.colors.optionsGreen} />
      </button>
    </Fragment>
  )
}
