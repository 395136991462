/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import AddOrEditCompanyMonitoringModal from '../../Company/Monitoring/AddOrEditCompanyMonitoringModal'
import { CompanyMonitoringTableAdd } from '../../../../types/CompanyMonitoring'
import { useModal } from '../../../../hooks/useModal'
import Button from '../../../../components/Button'

export interface CreateCompanyMonitoringButtonProps {
  companyId?: string
  deploymentId?: string
}

export const CreateCompanyMonitoringButton: React.FC<
  CreateCompanyMonitoringButtonProps
> = ({ companyId, deploymentId, children }) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <AddOrEditCompanyMonitoringModal
        type={CompanyMonitoringTableAdd.ADD}
        companyId={companyId}
        deploymentId={deploymentId}
        {...modalProps}
      />
      <Button onClick={onOpen}>{children}</Button>
    </Fragment>
  )
}
