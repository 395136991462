/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from 'emotion-theming'

import Icon, { IconTypes } from '../Icon'
import CurrentRouteIndicator from './CurrentRouteIndicator'

interface HeaderProps {
  onLeftIconClicked: () => void
  leftIcon: IconTypes
  showDetails?: boolean
}

export const Header: React.FC<HeaderProps> = ({
  showDetails = true,
  ...props
}) => {
  const theme = useTheme()

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <div
        css={{
          padding: '20px 20px 30px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <button onClick={props.onLeftIconClicked}>
          <Icon
            type={props.leftIcon}
            color={theme.colors.primary}
            strokeColor={theme.colors.primary}
            customSize={{ width: 24, height: 24 }}
          />
        </button>
        <div css={{ marginBottom: '3px' }}>
          <Link to="/">
            <Icon type="logoNoDesc" customSize={{ width: 180, height: 30 }} />
          </Link>
        </div>
        <div css={{ width: 24, height: 24 }}>
          {/* <Icon
          type="search"
          color={theme.colors.gray1}
          customSize={{ width: 24, height: 24 }}
        /> */}
        </div>
      </div>
      {showDetails && <CurrentRouteIndicator />}
    </div>
  )
}

export default Header
