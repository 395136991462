import { gql } from '@apollo/client'
import i18n from '../../i18n/config'
import { shortDayDate } from '../Date'
import { GetFleetsExportQuery } from '../../__generated__/graphql'

export const QUERY_FLEETS_EXPORT = gql`
  query GetFleetsExport(
    $multiFilter: String
    $orderBy: FleetOrderByInput
    $pageSize: Int
  ) {
    fleetsSearch(
      multiFilter: $multiFilter

      take: $pageSize
      orderBy: $orderBy
    ) {
      id
      name

      area {
        id
        name
      }

      totalBike
      totalLoanBike
      totalAvailableBike
      totalOtherBike

      totalOutOfServiceBike
      totalReparationBike
      totalStolenBike
      totalSoldBike

      nextAppointment {
        date
        category
      }

      companies {
        name
        appointments(orderBy: { date: asc }) {
          category
          date
        }
      }
    }
  }
`

export const formatFleetsExport = (
  fleetsExportData: GetFleetsExportQuery | undefined,
): any => {
  if (!fleetsExportData?.fleetsSearch?.length) return []

  const header = [
    'Nom flotte',
    'Zone',

    'Total de vélos',
    'Vélos en stock',
    'Vélos en prêt',
    'Vélos hors service',
    'Vélos en réparation',
    'Vélos volés',
    'Vélos vendus',

    'Prochaine étape',
    'Date',
  ]

  return [
    header,
    ...fleetsExportData?.fleetsSearch.map(fleet => {
      const nextCompany = fleet?.companies.find(x =>
        x.appointments.find(app => app.date === fleet.nextAppointment?.date),
      )

      const formattedNextStep = fleet?.nextAppointment?.category
        ? `${i18n.t(
            `animator.fleets.filter.fleetConditions.stepsCategory.${fleet.nextAppointment?.category}`,
          )} (${nextCompany?.name})`
        : ''

      return {
        name: fleet?.name,
        area: fleet?.area?.name,

        totalBikes: fleet?.totalBike,
        totalAvailableBikes: fleet?.totalAvailableBike,
        totalLoanBike: fleet?.totalLoanBike,

        totalOutOfServiceBike: fleet?.totalOutOfServiceBike,
        totalReparationBike: fleet?.totalReparationBike,
        totalStolenBike: fleet?.totalStolenBike,
        totalSoldBike: fleet?.totalSoldBike,

        nextStepCategory: formattedNextStep,
        nextStepDate: fleet?.nextAppointment?.date
          ? shortDayDate(new Date(fleet?.nextAppointment?.date))
          : undefined,
      }
    }),
  ]
}
