/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { ProgramStepType } from '@goodwatt/shared/dist/types'

import SelectedEmployeesLoan from './SelectedEmployees'
import SelectedEmployees from './SelectedEmployeesLoan'
import SubscribedEmployees from './SubscribedEmployees'

import EmployeeTableRefetchProvider from './EmployeeTableRefetchContext'
import { QUERY_COMPANY_PROGRESS_DETAILS } from '../../../../utils/gql/queries'
import Loader from '../../../../components/Loader'
import {
  GetCompanyProgressDetailsQuery,
  GetCompanyProgressDetailsQueryVariables,
} from '../../../../__generated__/graphql'

const CompanyEmployees: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useQuery<
    GetCompanyProgressDetailsQuery,
    GetCompanyProgressDetailsQueryVariables
  >(QUERY_COMPANY_PROGRESS_DETAILS, {
    variables: {
      where: { id },
    },
  })

  if (loading) return <Loader />

  return (
    <React.Fragment>
      <EmployeeTableRefetchProvider>
        {(data?.company?.programStep?.stepNbr as number) >=
          ProgramStepType.EMPLOYEE_LOANS_SELECTION && [
          <div css={{ marginBottom: 20 }} key="loan">
            <SelectedEmployeesLoan />
          </div>,
          <div css={{ marginBottom: 20 }} key="selected">
            <SelectedEmployees />
          </div>,
        ]}
        {(data?.company?.programStep?.stepNbr as number) >=
          ProgramStepType.EMPLOYEE_LOANS_SELECTION && (
          <div css={{ marginTop: 20 }}>
            <SubscribedEmployees
              companyStepNbr={data?.company?.programStep?.stepNbr as number}
            />
          </div>
        )}
      </EmployeeTableRefetchProvider>
    </React.Fragment>
  )
}

export default CompanyEmployees
