/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  NormalizedCacheObject,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { errors } from '@goodwatt/shared'

import { logoutAction } from './redux/user'
import { NotificationTypes } from './contexts/NotificationContext'
import store from './redux/store'
import i18n from './i18n/config'

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API,
  headers: {
    'Apollo-Require-Preflight': true,
  },
})

/**
 * If user is logged in, all the requests will have the headers
 * authorization set with the user token for authenticated requests
 */
const authLink = setContext((_, { headers }) => {
  const {
    user: { token },
  } = store.getState()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

/**
 * Catch and hanlde not autorised error responses
 */
const onErrorLink = onError(({ graphQLErrors, operation }) => {
  if (
    graphQLErrors &&
    graphQLErrors.some(
      gqlErr => gqlErr.message === errors.NotAutorisedError().message,
    )
  ) {
    store.dispatch(logoutAction())

    /* eslint-disable no-console */
    console.groupCollapsed(
      `GraphQL operation "${operation.operationName}" failed.`,
    )
    console.groupCollapsed('Query:')
    console.log(operation.query.loc?.source.body)
    console.groupEnd()

    console.log('Variables:', operation.variables)
    console.log('Errors:', graphQLErrors)
    console.groupEnd()
    /* eslint-enable no-console */

    const notifPayload = {
      type: NotificationTypes.ERROR,
      message: i18n.t('shared.auth.sessionExpired'),
    }

    dispatchEvent(new CustomEvent('newNotification', { detail: notifPayload }))
  }
})

/**
 * Client to make API Calls to the backend
 * with caching enabled
 */
export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([authLink, onErrorLink, httpLink]),
  cache: new InMemoryCache({
    // Used to simplify the cache merging on refetches
    typePolicies: {
      Query: {
        fields: {
          companies: {
            merge: (_, incoming) => incoming,
          },
          companyMonitorings: {
            merge: (_, incoming) => incoming,
          },
          company: {
            merge: (_, incoming) => incoming,
          },
          selectedCompanyEmployees: {
            merge: (_, incoming) => incoming,
          },
          bikeLoanAttachments: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      BikeComponent: {
        fields: {
          bikeLoanAttachments: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Company: {
        fields: {
          programStep: {
            merge: (_, incoming) => incoming,
          },
        },
      },
    },
  }),
})

const ApolloClientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <ApolloProvider client={client}>{children}</ApolloProvider>

export default ApolloClientProvider
