/** @jsx jsx */
import { gql, useMutation } from '@apollo/client'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@goodwatt/client/src/components/Button'
import Modal from '@goodwatt/client/src/components/Modal'
import Popup from '@goodwatt/client/src/components/Popup'

import { useNotifications } from '@goodwatt/client/src/contexts/NotificationContext'

import i18n from '@goodwatt/client/src/i18n/config'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'

import { BikesTableRow } from '@goodwatt/client/src/types/AnimatorBikes'
import {
  DeleteBikeMutation,
  DeleteBikeMutationVariables,
} from '../../../__generated__/graphql'

const MUTATION_DELETE_BIKE = gql`
  mutation DeleteBike($bikeId: String!) {
    deleteBike(bikeId: $bikeId) {
      id
    }
  }
`
interface ModalConfirmationProps {
  isOpen: boolean
  bike: BikesTableRow | undefined
  closeModal: () => void
  refetch: () => void
}

const DeleteBikeModal: React.FC<ModalConfirmationProps> = ({
  isOpen,
  bike,
  closeModal,
  refetch,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const notifications = useNotifications()
  const [deleteBike, { loading }] = useMutation<
    DeleteBikeMutation,
    DeleteBikeMutationVariables
  >(MUTATION_DELETE_BIKE)

  const onSubmit = async () => {
    if (!bike?.id) return

    try {
      await deleteBike({
        variables: { bikeId: bike.id },
      })
      refetch()
      closeModal()
    } catch (e) {
      apiErrorCatcher(notifications)
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        closable={false}
        title={t('animator.bikes.deleteBikeModal.title')}
        titleColor={theme.colors.primary}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button
                disabled={loading}
                onClick={() => closeModal()}
                type="tertiary"
              >
                {i18n.t('shared.button.cancel')}
              </Button>
            </div>
            <Button submit type="primary" loading={loading} onClick={onSubmit}>
              {i18n.t('shared.button.confirm')}
            </Button>
          </Fragment>
        }
      >
        <p
          css={{
            textAlign: 'center',
            padding: '0 18px',
            color: theme.colors.redLegend,
            fontWeight: 700,
          }}
        >
          {t('animator.bikes.deleteBikeModal.description')}
        </p>

        <p
          css={{ textAlign: 'center', padding: '0 18px', marginTop: 18 }}
          dangerouslySetInnerHTML={{
            __html: t('animator.bikes.deleteBikeModal.confim', {
              stickerId: bike?.stickerId,
            }),
          }}
        ></p>
      </Popup>
    </Modal>
  )
}

export default DeleteBikeModal
