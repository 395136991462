/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { regex } from '@goodwatt/shared'
import AuthContainer from '../components/AuthContainer'
import { Helmet } from 'react-helmet'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import i18n from '../i18n/config'
import Input from '../components/Input'
import Button from '../components/Button'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import {
  useNotifications,
  NotificationTypes,
} from '../contexts/NotificationContext'
import apiErrorCatcher from '../utils/apiErrorCatcher'
import AuthenticationCard from '../components/Card/AuthenticationCard'

const MUTATION_RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token)
  }
`

const schema = yup.object().shape({
  password: yup
    .string()
    .matches(regex.passwordRegex, i18n.t('forms.errors.passwordRegex'))
    .required(i18n.t('forms.errors.required')),
  confirmPassword: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .oneOf([yup.ref('password')], i18n.t('forms.errors.passwordsNotIdentical')),
})

type ResetPasswordInputs = {
  password: string
  confirmPassword: string
}

const ResetPassword: React.FC = () => {
  const notifications = useNotifications()
  const history = useHistory()
  const [resetPassword, { loading }] = useMutation(MUTATION_RESET_PASSWORD)
  const { token } = useParams<{
    token: string
  }>()
  const location = useLocation()
  const { register, handleSubmit, errors } = useForm<ResetPasswordInputs>({
    resolver: yupResolver(schema),
  })
  const { t } = useTranslation()

  const onSubmit = (data: ResetPasswordInputs) => {
    resetPassword({
      variables: {
        password: data.password,
        token,
      },
    })
      .then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t('auth.resetPassword.successNotification'),
        })
        history.push('/')
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <AuthContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="canonical"
          href={`https://www.goodwatt.fr/${location.pathname}`}
        />
      </Helmet>
      <AuthenticationCard title={t('auth.resetPassword.popupTitle')}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="password"
            name="password"
            label={t('forms.label.password')}
            defaultValue=""
            error={!!errors.password}
            helperText={errors.password?.message}
            register={register}
          />
          <Input
            type="password"
            name="confirmPassword"
            label={t('forms.label.confirmPassword')}
            defaultValue=""
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            register={register}
          />
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Button type="primary" submit loading={loading}>
              {t('auth.resetPassword.button')}
            </Button>
          </div>
        </form>
      </AuthenticationCard>
    </AuthContainer>
  )
}

export default ResetPassword
