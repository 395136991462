/** @jsx jsx */
import { jsx } from '@emotion/core'
import { DeploymentType } from '../../../__generated__/graphql'
import { useTranslation } from 'react-i18next'

export function DeploymentTypeIcon({ type }: { type: DeploymentType }) {
  const { t } = useTranslation()

  const letter = t(`shared.deploymentTypes.${type}`)[0].toUpperCase()

  const color =
    type === DeploymentType.Discovery
      ? 'hsla(188, 87%, 59%, 1)'
      : 'hsla(0, 0%, 31%, 1)'

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 18,
        height: 18,
        color: 'white',
        fontSize: 12,
        backgroundColor: color,
        borderRadius: 9,
        margin: 'auto',
      }}
    >
      {letter}
    </div>
  )
}
