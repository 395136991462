/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { RestrictionType, UserRoles } from '@goodwatt/shared/dist/types'
import { useMutation, useQuery } from '@apollo/client'

import ActionCard from '../../../../components/Card/ActionCard'
import UploadDocumentModal from '../../../Shared/CompanyDocuments/UploadDocumentsModal'
import DocumentCard from '../../../../components/Card/DocumentCard'
import UpdateDocumentModal from '../../../Shared/CompanyDocuments/UpdateDocumentsModal'
import {
  QUERY_GET_COMPANY_DOCUMENTS,
  QUERY_GET_SELECTED_EMPLOYEES,
} from '../../../../utils/gql/queries'

import UploadConvention from './UploadConvention'
import {
  accessibilitySelectOptions,
  MUTATION_UPDATE_DOCUMENT_BY_ANIMATOR,
  MUTATION_UPLOAD_DOCUMENT_BY_ANIMATOR,
} from './constants'

import { useSelector } from 'react-redux'
import { StoreState } from '../../../../redux/rootReducer'
import {
  AccessibilityAnimator,
  Document,
  GetCompanyDocumentsQuery,
  GetCompanyDocumentsQueryVariables,
  GetSelectedEmployeesQuery,
  GetSelectedEmployeesQueryVariables,
  UpdateDocumentByAnimatorMutation,
  UpdateDocumentByAnimatorMutationVariables,
  UploadDocumentByAnimatorMutation,
  UploadDocumentByAnimatorMutationVariables,
} from '../../../../__generated__/graphql'

const CompanyDocuments: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false)
  const [editDocModal, setEditDocModal] = useState<Document | null>(null)
  const { t } = useTranslation()
  // QUERY & MUTATIONS
  const [uploadDocument, { loading: uploadLoading }] = useMutation<
    UploadDocumentByAnimatorMutation,
    UploadDocumentByAnimatorMutationVariables
  >(MUTATION_UPLOAD_DOCUMENT_BY_ANIMATOR)
  const [updateDocument, { loading: updateLoading }] = useMutation<
    UpdateDocumentByAnimatorMutation,
    UpdateDocumentByAnimatorMutationVariables
  >(MUTATION_UPDATE_DOCUMENT_BY_ANIMATOR)
  const { data, refetch } = useQuery<
    GetCompanyDocumentsQuery,
    GetCompanyDocumentsQueryVariables
  >(QUERY_GET_COMPANY_DOCUMENTS, {
    variables: {
      where: {
        id,
      },
    },
  })

  const { data: employeeSelected } = useQuery<
    GetSelectedEmployeesQuery,
    GetSelectedEmployeesQueryVariables
  >(QUERY_GET_SELECTED_EMPLOYEES, {
    variables: {
      companyId: id,
    },
  })

  const { role: userRole } = useSelector((state: StoreState) => state.user)

  const isAdmin = userRole === UserRoles.ADMIN

  return (
    <div>
      <UploadConvention
        companyId={id}
        refetch={refetch}
        hasConvention={!!data?.company?.conventionFilePath}
      />
      <div css={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {data?.company?.documentList?.map(doc => {
          if (!doc) return null
          return (
            <DocumentCard
              key={doc.id}
              id={doc.id}
              refetch={refetch}
              title={doc.title}
              filePath={doc.filePath}
              size={doc.size}
              date={doc.createdAt}
              documentRestrictions={doc.restrictions as string[]}
              deletable={
                isAdmin
                  ? true
                  : doc.restrictions?.includes(RestrictionType.ANIMATOR_D)
              }
              downloadable={
                isAdmin
                  ? true
                  : doc.restrictions?.includes(RestrictionType.ANIMATOR_R)
              }
              onEdit={
                doc.restrictions?.includes(RestrictionType.ANIMATOR_W)
                  ? () => {
                      setEditDocModal(doc)
                    }
                  : undefined
              }
            />
          )
        })}
        <ActionCard
          variant="secondary"
          icon="uploadDoc"
          label={t('shared.documents.addDocument')}
          onCardClick={() => setAddModalOpen(true)}
        />
      </div>
      {editDocModal && (
        <UpdateDocumentModal
          document={editDocModal}
          loading={updateLoading}
          modalIsOpen={!!editDocModal}
          closeModal={() => setEditDocModal(null)}
          refetch={refetch}
          onUpdate={({ title, documentId, accessibility }) =>
            updateDocument({
              variables: {
                title,
                documentId,
                accessibility: accessibility as AccessibilityAnimator,
              },
            })
          }
          accessibilitySelectOptions={accessibilitySelectOptions}
        />
      )}
      <UploadDocumentModal
        modalIsOpen={addModalOpen}
        loading={uploadLoading}
        closeModal={() => setAddModalOpen(false)}
        refetch={refetch}
        onUpload={({ title, file, accessibility, employeeId }) =>
          uploadDocument({
            variables: {
              title,
              file,
              accessibility: accessibility as
                | AccessibilityAnimator
                | null
                | undefined,
              companyId: id,
              employeeId: employeeId,
            },
          })
        }
        employees={employeeSelected?.selectedCompanyEmployees}
        accessibilitySelectOptions={accessibilitySelectOptions}
      />
    </div>
  )
}

export default CompanyDocuments
