/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
/** @jsx jsx */
import { InterpolationWithTheme, jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { useTable, useSortBy, Column, Row } from 'react-table'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'

import HeaderCell from './CustomCells/HeaderCell'
import { SortOrder } from '../../__generated__/graphql'
import theme from '../../styles/theme'

// eslint-disable-next-line @typescript-eslint/ban-types
type Data = object

export const initialColumnSortBy: ColumnSortBy = {
  columnName: '',
  sort: false,
}

export type ColumnSortBy = {
  columnName: string
  sort: false | SortOrder
}

const variants = {
  default: {
    th: {
      color: theme.colors.white,
      background: theme.colors.primary,
      //'&:nth-child(1)': { borderRadius: '20px 0 0 0' },
      '&:nth-of-type(1)': { borderRadius: '20px 0 0 0' },
      '&:last-child': {
        borderTopRightRadius: '20px',
        borderRight: 0,
      },
    },
  },
  subTable: {
    th: {
      color: theme.colors.white,
      background: theme.colors.gray3,
      '&:last-child': { borderRight: 0 },
    },
  },
}

export type TableVariant = keyof typeof variants

export interface TableProps {
  columns: Column<Data>[]
  data: Data[]
  noDataText?: string
  onSortBy?: (opts: ColumnSortBy) => void
  onRowClick?: (row: Row) => void
  loading?: boolean
  defaultSortValue?: ColumnSortBy
  headerCategories?: Array<{
    name: string
    colspan: number
    hidden?: boolean
    css?: InterpolationWithTheme<any>
  }>
  variant?: TableVariant
}
const Table: React.FC<TableProps> = ({
  columns,
  data,
  noDataText,
  onSortBy,
  onRowClick,
  loading = false,
  defaultSortValue,
  headerCategories,
  variant = 'default',
}) => {
  const { t } = useTranslation()
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        manualSortBy: false,
      },
      useSortBy,
    )

  const [columnSortBy, setColumnSortBy] =
    React.useState<ColumnSortBy>(initialColumnSortBy)

  const onSortClick = React.useCallback(
    (column: { id: string; sortField?: string }): void => {
      const sortField = column.sortField || column.id
      if (sortField !== columnSortBy.columnName || !columnSortBy.sort) {
        setColumnSortBy({
          columnName: sortField,
          sort: SortOrder.Desc,
        })
      } else if (columnSortBy.sort === 'desc') {
        setColumnSortBy(prevState => ({
          ...prevState,
          sort: SortOrder.Asc,
        }))
      } else {
        setColumnSortBy(initialColumnSortBy)
      }
    },
    [columnSortBy, setColumnSortBy],
  )

  const isSorted = (column: { id: string; sortField?: string }) => {
    const sortField = column.sortField || column.id
    return columnSortBy.columnName === sortField ? columnSortBy.sort : false
  }

  React.useEffect(() => {
    if (onSortBy)
      onSortBy(
        !columnSortBy.sort && defaultSortValue
          ? defaultSortValue
          : columnSortBy,
      )
  }, [columnSortBy, onSortBy, defaultSortValue])

  // disabling multi headerGroup feature;
  const [headerGroup] = headerGroups

  const theme = useTheme()

  return (
    <div css={{ width: '100%', overflow: 'auto' }}>
      <table
        {...getTableProps()}
        css={{
          width: '100%',
          borderSpacing: 0,
        }}
      >
        <thead>
          {headerCategories?.length && (
            <tr>
              {headerCategories.map((column, i) => (
                <th
                  key={i}
                  colSpan={column.colspan}
                  css={{
                    opacity: column.hidden ? '0' : 'default',
                    width: '1%',
                    height: 45,
                    paddingRight: '1.3rem',
                    paddingLeft: '1.3rem',
                    fontSize: '1.6rem',

                    fontWeight: 'bold',
                    '&:collapse': {
                      width: '0.0000000001%',
                    },

                    borderRight: `2px solid ${theme.colors.white}`,
                    borderBottom: `2px solid ${theme.colors.white}`,

                    borderRadius:
                      i > 0 && headerCategories[i - 1].hidden
                        ? '20px 0 0 0'
                        : 'none',

                    ...variants[variant].th,
                  }}
                >
                  <span>{column.name}</span>
                </th>
              ))}
            </tr>
          )}
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                css={{
                  width: '1%',
                  height: 45,
                  paddingRight: '1.3rem',
                  paddingLeft: '1.3rem',
                  fontSize: '1.6rem',
                  fontWeight: 'bold',
                  borderRight: `2px solid ${theme.colors.white}`,
                  borderBottom: `2px solid ${theme.colors.white}`,
                  '&:collapse': {
                    width: '0.0000000001%',
                  },

                  ...variants[variant].th,
                }}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                onClick={
                  column.canSort ? () => onSortClick(column) : () => null
                }
              >
                <HeaderCell column={column} sort={isSorted(column)} />
              </th>
            ))}
          </tr>
        </thead>
        {!loading && (
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr
                  onClick={onRowClick ? () => onRowClick(row) : undefined}
                  {...row.getRowProps()}
                  css={{
                    background: theme.colors.gray6,
                    ...(onRowClick
                      ? {
                          cursor: 'pointer',
                          '&:hover': {
                            background: theme.colors.gray5,
                          },
                        }
                      : {}),
                    'td:last-child': {
                      borderRight: 0,
                    },
                    '&:last-child': {
                      td: {
                        borderBottom: 0,
                      },
                      // 'td:nth-child(1)': {
                      //   borderRadius: '0 0 0 20px',
                      // },
                      'td:nth-of-type(1)': {
                        borderRadius: '0 0 0 20px',
                      },
                      // 'td:nth-last-child(1)': {
                      //   borderRadius: '0 0 20px 0',
                      //   borderRight: 0,
                      // },
                      'td:nth-last-of-type(1)': {
                        borderRadius: '0 0 20px 0',
                        borderRight: 0,
                      },
                    },
                  }}
                >
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps()}
                      css={{
                        width: '1%',
                        '&:collapse': {
                          width: '0.0000000001%',
                        },
                        margin: 0,
                        padding: '1.3rem',
                        fontSize: '1.6rem',
                        ...(cell.column.maxWidth
                          ? {
                              maxWidth: cell.column.maxWidth,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }
                          : {}),
                        borderRight: `2px solid ${theme.colors.white}`,
                        borderBottom: `2px solid ${theme.colors.white}`,
                        ...((cell.column as any).cellStyle
                          ? (cell.column as any).cellStyle(cell.value, cell)
                          : {}),
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
      {!loading && !rows.length && (
        <div
          css={{
            height: 330,
            backgroundColor: theme.colors.gray6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
          }}
        >
          {noDataText ? noDataText : t('shared.noData')}
        </div>
      )}
      {loading && (
        <ContentLoader
          speed={2}
          width="100%"
          height="330px"
          backgroundColor={theme.colors.gray6}
          foregroundColor={theme.colors.gray5}
        >
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="45" />
          <rect x="0" y="47" rx="3" ry="3" width="100%" height="45" />
          <rect x="0" y="94" rx="3" ry="3" width="100%" height="45" />
          <rect x="0" y="141" rx="3" ry="3" width="100%" height="45" />
          <rect x="0" y="188" rx="3" ry="3" width="100%" height="45" />
          <rect x="0" y="235" rx="3" ry="3" width="100%" height="45" />
          <rect x="0" y="282" rx="3" ry="3" width="100%" height="20" />
          <rect x="0" y="282" rx="20" ry="20" width="100%" height="45" />
        </ContentLoader>
      )}
    </div>
  )
}

export default Table
