import Tooltip from '../../../../../components/Tooltip'
import React from 'react'
import { Flex } from '../../../../../components/Flex'
import { useTranslation } from 'react-i18next'
import { ArchiveCompanyButton } from '../ArchiveCompanyButton'
import { DeleteCompanyButton } from '../DeleteCompanyButton'
import { EditCompanyButton } from '../EditCompanyButton'

export interface CompanyActionsProps {
  companyId: string
  companyName: string
}

export const CompanyActions: React.FC<CompanyActionsProps> = ({
  companyId,
  companyName,
}) => {
  const { t } = useTranslation()

  return (
    <Flex direction="row" justify="center" align="center" gap="8px">
      <Tooltip
        title={t('animator.dashboard.tooltips.edit')}
        placement="top-end"
        css={{ cursor: 'pointer' }}
      >
        <EditCompanyButton id={companyId} />
      </Tooltip>
      <Tooltip
        title={t('animator.dashboard.tooltips.archive')}
        placement="top-end"
        css={{ cursor: 'pointer' }}
      >
        <ArchiveCompanyButton id={companyId} companyName={companyName} />
      </Tooltip>

      <Tooltip
        title={t('animator.dashboard.tooltips.delete')}
        placement="top-end"
        css={{ cursor: 'pointer' }}
      >
        <DeleteCompanyButton id={companyId} companyName={companyName} />
      </Tooltip>
    </Flex>
  )
}
