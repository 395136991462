/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, useRef } from 'react'
import { useTheme } from 'emotion-theming'
import Icon from './Icon'
import Loader from './Loader'

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined

interface AvatarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: (instance: any) => RefReturn
  imgUrl?: string
  name?: string
  onChange?: () => void
  editable?: boolean
  loading?: boolean
}

const Avatar: React.FC<AvatarProps> = ({
  name = '',
  register,
  imgUrl,
  onChange,
  editable = true,
  loading = false,
}) => {
  const theme = useTheme()
  const uploadInput = useRef<HTMLInputElement | null>()

  return (
    <div
      css={{
        position: 'relative',
        width: '100%',
        height: '100%',
        cursor: editable && !loading ? 'pointer' : 'auto',
      }}
      onClick={() => (!loading ? uploadInput?.current?.click() : null)}
    >
      {imgUrl ? (
        <img
          src={imgUrl}
          alt="animator"
          css={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            position: 'absolute',
            objectFit: 'cover',
          }}
        />
      ) : (
        <div
          css={{
            width: '100%',
            height: '100%',
            backgroundColor: theme.colors.yellowLegend,
            position: 'absolute',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px',
          }}
        >
          <Icon
            type="avatar"
            color={theme.colors.white}
            customSize={{ width: '100%', height: '100%' }}
          />
        </div>
      )}
      {editable && (
        <Fragment>
          {!loading && (
            <div
              css={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                backgroundColor: theme.colors.gray6,
                borderRadius: '50%',
              }}
            >
              <Icon type="edit" size="small" />
            </div>
          )}
          <div>
            <input
              ref={e => {
                if (register) register(e)
                uploadInput.current = e
              }}
              css={{ display: 'none' }}
              type="file"
              name={name}
              onChange={onChange}
            />
          </div>
          {loading && (
            <div
              css={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
              }}
            >
              <Loader />
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default Avatar
