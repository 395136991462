/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNotifications } from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import { useArchiveUser } from '../hooks/useArchiveUser'
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../../../../components/ConfirmationModal'

export interface ArchiveUserModalProps
  extends Pick<ConfirmationModalProps, 'isOpen' | 'onClose'> {
  id: string
  onCompleted?: () => void
}

export const ArchiveUserModal: React.FC<ArchiveUserModalProps> = ({
  id,
  onCompleted,
  ...rest
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [mutate, { loading }] = useArchiveUser({
    onCompleted,
  })

  const onSubmit = async () => {
    try {
      await mutate({
        variables: { data: { id } },
      })
      rest.onClose()
    } catch (e) {
      apiErrorCatcher(notifications)
    }
  }

  return (
    <ConfirmationModal
      title={t('animator.companies.employees.archiveUserModal.title')}
      description={t(
        'animator.companies.employees.archiveUserModal.description',
      )}
      loading={loading}
      onSubmit={onSubmit}
      {...rest}
    />
  )
}

export default ArchiveUserModal
