/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'
import Button from '../../../../components/Button'
import Input from '../../../../components/Input'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import { MUTATION_UPDATE_AREA } from '../../../../utils/gql/mutations'

import i18n from '../../../../i18n/config'
import theme from '../../../../styles/theme'
import { useForm } from 'react-hook-form'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import {
  AreaSearch,
  GetAreaSearchQueryVariables,
  UpdateAreaMutation,
  UpdateAreaMutationVariables,
} from '../../../../__generated__/graphql'

interface AreaEditModalProps {
  area: AreaSearch | null
  refetch: (newData?: Partial<GetAreaSearchQueryVariables>) => void

  closeModal: () => void
  modalIsOpen?: boolean
}

const schema = yup.object().shape({
  name: yup.string().required(i18n.t('forms.errors.required')),
})

interface EditAreaForm {
  name: string
}

const AreaEditModal: React.FC<AreaEditModalProps> = ({
  area,
  refetch,

  closeModal,
  modalIsOpen = true,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()

  const [updateArea, { loading }] = useMutation<
    UpdateAreaMutation,
    UpdateAreaMutationVariables
  >(MUTATION_UPDATE_AREA)

  const onSubmit = (input: EditAreaForm) => {
    if (!input.name) return

    updateArea({
      variables: {
        data: {
          name: {
            set: input.name,
          },
        },
        where: {
          id: area?.areaId,
        },
      },
    })
      .then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: i18n.t('admin.area.notification.successUpdate'),
        })
        refetch()
        closeModal()
      })
      .catch(apiErrorCatcher(notifications))
  }

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  return (
    <Modal isOpen={modalIsOpen}>
      <Popup
        maxWidth={750}
        title={t('admin.area.modal.updateTitle')}
        onClose={closeModal}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Button type="tertiary" onClick={closeModal}>
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginRight: 24 }} />
            <Button
              loading={loading}
              type="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {t('admin.area.button.update')}
            </Button>
          </div>
        }
      >
        <p
          css={{
            textAlign: 'center',
            whiteSpace: 'pre-line',
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            css={{
              width: '100%',
              padding: '0 12px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              [theme.media.mobile]: {
                padding: '0',
              },
            }}
          >
            <label
              css={{
                fontSize: '1.6rem',
                color: theme.colors.gray3,
                textAlign: 'right',
                marginBottom: 12,
                width: '50%',
              }}
            >
              {t('admin.area.modal.inputName')}
            </label>
            <Input
              compact
              center
              type="text"
              name="name"
              defaultValue={area?.name || ''}
              register={register}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default AreaEditModal
