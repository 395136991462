/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import Badge, { BadgeProps } from '../Badge'

interface CardTitleProps {
  statusColor?: string
  label: string
  badge?: BadgeProps
}

const CardTitle: React.FC<CardTitleProps> = ({ statusColor, label, badge }) => {
  const theme = useTheme()

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'center',
        [theme.media.mobile]: { flexDirection: 'column' },
      }}
    >
      <div css={{ display: 'flex', alignItems: 'center' }}>
        {statusColor && (
          <div
            css={{
              width: '21px',
              height: '21px',
              backgroundColor: statusColor,
              borderRadius: '50%',
              marginRight: '14px',
            }}
          />
        )}
        <span
          css={{
            fontFamily: theme.fontFamily,
            fontWeight: 'bold',
            fontSize: '1.6rem',
            color: theme.colors.gray1,
            ...(badge ? { marginRight: '12px' } : {}),
          }}
        >
          {label}
        </span>
      </div>
      {badge && (
        <div
          css={{
            width: 'fit-content',
            [theme.media.mobile]: { marginTop: 5, marginLeft: 41 },
          }}
        >
          <Badge {...badge} />
        </div>
      )}
    </div>
  )
}

export default CardTitle
