/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { useSelector } from 'react-redux'

import { StoreState } from '../../redux/rootReducer'

const CurrentRouteIndicator: React.FC = () => {
  const theme = useTheme()
  const menuTitle = useSelector((state: StoreState) => state.menu.menuTitle)
  const subMenuTitle = useSelector(
    (state: StoreState) => state.menu.subMenuTitle,
  )
  const subSubMenuTitle = useSelector(
    (state: StoreState) => state.menu.subSubMenuTitle,
  )

  return (
    <div css={{ marginBottom: '32px' }}>
      {/* ROUTE */}
      {(subMenuTitle || menuTitle) && (
        <div
          css={{
            padding: '0 24px',
            height: '30px',
            backgroundColor: theme.colors.primary,
            width: 'fit-content',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            css={{
              fontFamily: 'Raleway',
              color: theme.colors.white,
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            {subMenuTitle || menuTitle}
          </span>
        </div>
      )}
      {/* IF ENTERPRISE */}
      {subSubMenuTitle && (
        <div
          css={{
            padding: '0 24px',
            height: '30px',
            backgroundColor: theme.colors.white,
            width: 'fit-content',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            css={{
              fontFamily: 'Raleway',
              color: theme.colors.primary,
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            {subSubMenuTitle}
          </span>
        </div>
      )}
    </div>
  )
}

export default CurrentRouteIndicator
