/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState } from 'react'
import { useTheme } from 'emotion-theming'
import { format } from 'date-fns/esm'

import Card from '../../../components/Card'
import Icon from '../../../components/Icon'

import { useTranslation } from 'react-i18next'
import ConfirmModalAbsent from '../../Employee/Dashboard/ConfirmModalAbsent'
import Button from '../../../components/Button'
import { ProgramStepType } from '@goodwatt/shared/dist/types'
import {
  AppointmentCategory,
  GetCompanyProgressDetailsQuery,
  GetEmployeeDashboardQuery,
} from '../../../__generated__/graphql'

interface TrainingateProps {
  onEditClicked?: () => void
  isAbsentButton?: boolean
  onAbsentButton?: () => void
  employee?: GetEmployeeDashboardQuery['employee']
  company?: GetCompanyProgressDetailsQuery['company']
}

const TrainingDate: React.FC<TrainingateProps> = ({
  onEditClicked,
  employee,
  company,
  isAbsentButton = false,
  onAbsentButton,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleAbsentModal = (isConfirmed: boolean) => {
    setIsOpen(false)
    if (onAbsentButton && isConfirmed) onAbsentButton()
  }

  const appointment =
    employee?.trainingAppointment ||
    employee?.company.trainingAppointmentTemplate ||
    company?.nextTrainingAppointment ||
    company?.trainingAppointmentTemplate

  let formatAppointmentDate = t('animator.company.progress.trainingDate')

  const companyRef = company ? company : employee?.company
  const stepNumber = companyRef?.programStep.stepNbr

  if (appointment) {
    if (!stepNumber || stepNumber <= ProgramStepType.EMPLOYEE_LOANS_SELECTION) {
      formatAppointmentDate += t('animator.company.progress.appointmentDate', {
        date: format(new Date(appointment.date), 'dd/MM/yyyy'),
        place: appointment.location,
      })
    } else {
      formatAppointmentDate += t(
        'animator.company.progress.appointmentDateTime',
        {
          date: format(new Date(appointment.date), 'dd/MM/yyyy'),
          time: format(new Date(appointment.date), 'H:mm'),
          place: appointment.location,
        },
      )
    }
  }

  const absence = employee?.absences.find(
    a => a.category === AppointmentCategory.Training,
  )
  const isAbsent = absence != null

  return (
    <Card css={{ backgroundColor: theme.colors.primary, height: '100%' }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Icon
          type="presentation"
          customSize={{ width: 43, height: 43 }}
          color={theme.colors.yellowLegend}
        />
        <p
          css={{
            textAlign: 'center',
            fontFamily: theme.fontFamily,
            fontWeight: 'bold',
            fontSize: '1.4rem',
            color: theme.colors.white,
            margin: '14px 0',
            lineHeight: '22px',
          }}
          dangerouslySetInnerHTML={{
            __html: appointment
              ? formatAppointmentDate
              : t('animator.company.progress.trainingDateToDefine'),
          }}
        />
        {isAbsentButton && (
          <Button onClick={() => setIsOpen(true)} type="yellowLegend">
            <div
              css={{
                fontSize: '14px',
                fontWeight: 700,
              }}
            >
              {t('employee.dashboard.reportAbsent')}
            </div>
          </Button>
        )}

        {isAbsent && (
          <div
            css={{
              color: theme.colors.white,
              fontSize: '14px',
              fontWeight: 700,
            }}
          >
            {t('employee.dashboard.absent')}
          </div>
        )}

        {onEditClicked && (
          <div css={{ alignSelf: 'flex-end' }}>
            <button onClick={onEditClicked}>
              <Icon type="edit" color="white" />
            </button>
          </div>
        )}
      </div>
      <ConfirmModalAbsent
        category={AppointmentCategory.Training}
        contractUrl={employee?.contractUrl}
        isOpen={isOpen}
        onModalClose={handleAbsentModal}
      />
    </Card>
  )
}

export default TrainingDate
