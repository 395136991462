/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { useTheme } from 'emotion-theming'
import { EmployeeDeployment } from '../../../../__generated__/graphql'
import { useModal } from '../../../../hooks/useModal'
import Icon from '../../../../components/Icon'
import DepositRetentionModal from '../../Company/Employees/SelectedEmployees/DepositRetentionModal'

export interface RetentionOnDepositButtonProps {
  employeeDeployment: EmployeeDeployment
}

export const RetentionOnDepositButton: React.FC<
  RetentionOnDepositButtonProps
> = ({ employeeDeployment }) => {
  const theme = useTheme()
  const { onOpen, modalProps } = useModal()

  const iconColor = employeeDeployment.bikeLoan?.retentionOnDeposit
    ? theme.colors.error
    : theme.colors.greenLegend

  return (
    <Fragment>
      {modalProps.isOpen && (
        <DepositRetentionModal
          employee={employeeDeployment.employee}
          employeeDeploymentId={employeeDeployment.id}
          closeModal={modalProps.onClose}
          refetch={() => {}}
        />
      )}
      <button onClick={onOpen}>
        <Icon type="euro" color={iconColor} />
      </button>
    </Fragment>
  )
}
