/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../../../components/Input'
import { setReturnBikeForm } from '../../../../../../redux/forms'
import { StoreState } from '../../../../../../redux/rootReducer'
import { employeeNameFormatter } from '../../../../../../utils/formatter'
import { Employee } from '../../../../../../__generated__/graphql'

interface BikeSelectionProps {
  employee: Pick<Employee, 'firstName' | 'lastName'>
}

const BikeSelection: React.FC<BikeSelectionProps> = ({ employee }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { stickerId } = useSelector(
    (state: StoreState) => state.forms.returnBikeForm,
  )
  const [inputError, setInputError] = React.useState<string | undefined>()

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setReturnBikeForm({ stickerId: parseInt(e.target.value, 10) }))
  }
  const onInputBlur = () => {
    setInputError(!stickerId ? t('forms.errors.required') : undefined)
  }

  return (
    <div css={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <div css={{ maxWidth: 550 }}>
        <form>
          <p>
            <span>
              {t(
                'animator.company.employees.selected.modals.employeeReturnBikeModal.bikeSelection.description.bike',
              )}
            </span>{' '}
            <span>
              {t(
                'animator.company.employees.selected.modals.employeeReturnBikeModal.bikeSelection.description.accessories',
              )}
            </span>{' '}
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'animator.company.employees.selected.modals.employeeReturnBikeModal.bikeSelection.description.employee',
                  {
                    employeeName: employeeNameFormatter(
                      employee?.firstName,
                      employee?.lastName,
                    ),
                  },
                ),
              }}
            />
          </p>
          <div css={{ marginBottom: 50 }} />
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                'animator.company.employees.selected.modals.employeeReturnBikeModal.bikeSelection.selectBike',
              ),
            }}
          />
          <div css={{ marginBottom: 20 }} />
          <Input
            name="stickerId"
            placeholder={t('shared.placeholder.userEntry')}
            value={`${stickerId || ''}`}
            onChange={onInputChange}
            onBlur={onInputBlur}
            error={!!inputError}
            helperText={inputError}
          />
        </form>
      </div>
    </div>
  )
}

export default BikeSelection
