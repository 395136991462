/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'

const TableHeader: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  const tableHeaderCellStyles = {
    display: 'flex',
    height: '100%',
    backgroundColor: theme.colors.primary,
    alignItems: 'center',
    color: theme.colors.white,
    justifyContent: 'center',
  }

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        height: 50,
        marginTop: '24px',
      }}
    >
      <div
        css={{
          flex: 1,
          borderTopLeftRadius: '22px',
          borderRight: `2px solid ${theme.colors.white}`,
          ...tableHeaderCellStyles,
        }}
      >
        <span css={{ textAlign: 'center', padding: '0 10px 0 10px' }}>
          {t(
            'animator.company.employees.selected.modals.deposit.damagedElement',
          )}
        </span>
      </div>
      <div
        css={{
          flex: 2,
          borderRight: `2px solid ${theme.colors.white}`,
          ...tableHeaderCellStyles,
        }}
      >
        <span css={{ textAlign: 'center', padding: '0 10px 0 10px' }}>
          {t('animator.company.employees.selected.modals.deposit.before')}
        </span>
      </div>
      <div
        css={{
          flex: 2,
          borderRight: `2px solid ${theme.colors.white}`,
          ...tableHeaderCellStyles,
        }}
      >
        <span css={{ textAlign: 'center', padding: '0 10px 0 10px' }}>
          {t('animator.company.employees.selected.modals.deposit.after')}
        </span>
      </div>
      <div
        css={{
          flex: 1,
          borderTopRightRadius: '22px',
          ...tableHeaderCellStyles,
        }}
      >
        <span css={{ textAlign: 'center', padding: '0 10px 0 10px' }}>
          {t(
            'animator.company.employees.selected.modals.deposit.estimatedAmount',
          )}
        </span>
      </div>
    </div>
  )
}

export default TableHeader
