/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useNotifications,
  NotificationTypes,
} from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import { useArchiveCompany } from '../hooks/useArchiveCompany'
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../../../../components/ConfirmationModal'

export interface ArchiveCompanyModalProps
  extends Pick<ConfirmationModalProps, 'isOpen' | 'onClose'> {
  id: string
  companyName: string
  onCompleted?: () => void
}

export const ArchiveCompanyModal: React.FC<ArchiveCompanyModalProps> = ({
  id,
  companyName,
  onCompleted,
  ...rest
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [mutate, { loading }] = useArchiveCompany({
    onCompleted,
  })

  const onSubmit = async () => {
    try {
      await mutate({
        variables: { id },
      }).then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t('animator.companies.archiveCompany.successNotification', {
            companyName: companyName,
          }),
        })
      })

      rest.onClose()
    } catch (e) {
      apiErrorCatcher(notifications)
    }
  }

  const description = useMemo(() => {
    return (
      <p
        css={{
          color: 'black',
          fontWeight: 'normal',
        }}
        dangerouslySetInnerHTML={{
          __html: t('animator.companies.archiveCompany.description', {
            companyName,
          }),
        }}
      />
    )
  }, [companyName, t])

  return (
    <ConfirmationModal
      title={t('animator.companies.archiveCompany.title')}
      description={description}
      loading={loading}
      onSubmit={onSubmit}
      {...rest}
    />
  )
}

export default ArchiveCompanyModal
