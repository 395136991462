/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { Row } from 'react-table'
import React from 'react'

import Icon from '../../Icon'

import {
  AnimatorModalSettings,
  AnimatorTableModal,
} from '../../../types/admin/AdminAnimator'
import { GetAnimatorsQuery } from '../../../__generated__/graphql'

interface BikesActionCellProps {
  setModal: (
    obj: AnimatorModalSettings<
      GetAnimatorsQuery['animators'][0],
      AnimatorTableModal
    >,
  ) => void
  row: Row<GetAnimatorsQuery['animators'][0]>
}

const AnimatorActionCell: React.FC<BikesActionCellProps> = ({
  setModal,
  row,
}) => {
  const theme = useTheme()
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: AnimatorTableModal,
    ) => {
      e.stopPropagation()
      setModal({ animateur: row.original, actionName })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <div
        css={{ cursor: 'pointer', marginLeft: 8 }}
        onClick={e => handleClick(e, AnimatorTableModal.EDIT)}
      >
        <Icon type="edit" color={theme.colors.gray2} />
      </div>
      <div
        css={{ cursor: 'pointer', marginLeft: 8 }}
        onClick={e => handleClick(e, AnimatorTableModal.DELETE)}
      >
        <Icon type="trash" color={theme.colors.gray2} />
      </div>
    </div>
  )
}

export default AnimatorActionCell
