import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import { MUTATION_DELETE_DEPLOYMENT } from '../../../../utils/gql/mutations'
import {
  DeleteDeploymentMutation,
  DeleteDeploymentMutationVariables,
} from '../../../../__generated__/graphql'

export const useDeleteDeployment = (
  options?: MutationHookOptions<
    DeleteDeploymentMutation,
    DeleteDeploymentMutationVariables
  >,
): MutationTuple<
  DeleteDeploymentMutation,
  DeleteDeploymentMutationVariables
> => {
  return useMutation(MUTATION_DELETE_DEPLOYMENT, {
    refetchQueries: ['GetDeployments', 'GetDeploymentsCount'],
    ...options,
  })
}
