import { gql } from '@apollo/client'

export const QUERY_AREA_COUNT = gql`
  query AreaCountFiltered($where: AreaWhereInput) {
    areaCount(where: $where)
  }
`

export const QUERY_EMPLOYEE_RIDES_RECAP_COUNT = gql`
  query GetEmployeeRidesCount {
    employeeRidesRecapCount
  }
`

export const QUERY_AREA_SEARCH = gql`
  query GetAreaSearch(
    $where: AreaWhereInput
    $orderBy: AreaOrderByInput
    $take: Int
    $skip: Int
  ) {
    areaSearch(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      name
      areaId
      territoryCount
      companyStep1Count
      companyStep2Count
      companyStep3Count
      companyStep4Count
      companyStep5Count
      employeeTotalCount
      employeeSelectedCount
      employeeBikeCount
      bikeCount
    }
  }
`

export const QUERY_ANIMATOR_COUNT = gql`
  query AnimatorCountFiltered($where: AnimatorWhereInput) {
    animatorCount(where: $where)
  }
`

export const QUERY_ANIMATORS = gql`
  query GetAnimators(
    $where: AnimatorWhereInput
    $orderBy: [AnimatorOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    animators(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      firstName
      lastName

      civility

      avatarUrl
      street
      city
      country
      postalCode
      phoneNumber

      area {
        id
        name
      }

      user {
        email
      }
    }
  }
`

export const QUERY_AREA_ANIMATOR = gql`
  query GetAreaAnimators($where: AnimatorWhereInput!) {
    animators(where: $where) {
      user {
        id
      }
    }
  }
`

export const QUERY_GET_COMPANY = gql`
  query GetCompanyProfile($where: CompanyWhereUniqueInput!) {
    company(where: $where) {
      id
      avatarUrl
      name
      siret
      subscriptionCode
      nbrEmployees
      street
      city
      postalCode
      bikeSpaceCapacityInside
      bikeSpaceCapacityOutside
      bikeSpaceCapacityUnsafe
      carSpaceCapacity
      electricStationCapacity
      companyType
      sites {
        id
        name
        street
        country
        city
        department
        postalCode
        employeesOnSite
        bikeSpaceCapacityInside
        bikeSpaceCapacityOutside
        bikeSpaceCapacityUnsafe
        carSpaceCapacity
        electricStationCapacity
      }
      animator {
        id
        firstName
        lastName
        phoneNumber
        user {
          email
        }
      }
      area {
        animator {
          id
          firstName
          lastName
          phoneNumber
          user {
            email
          }
        }
      }
      firstDeliveryAppointment {
        date
        location
      }
      firstTrainingAppointment {
        date
        location
      }
      firstReturnAppointment {
        date
        location
      }
      programStep {
        name
        stepNbr
      }
      companyReferrers {
        archived
        companyId
        id
        firstName
        lastName
        civility
        phoneNumber
        email
        mainReferrer
        user {
          id
          emailVerified
        }
      }
    }
  }
`

export const QUERY_COMPANY_PROGRESS_DETAILS = gql`
  query GetCompanyProgressDetails(
    $where: CompanyWhereUniqueInput!
    $summary: SummaryFilter
  ) {
    company(where: $where) {
      employeesCompletedProfile
      employeesSelectedForLoan
      employeesValidated
      employeesCount
      employeesWithBikeCount
      programStep {
        stepNbr
        name
      }
      companyReferrers {
        firstName
        lastName
        civility
        mainReferrer
      }

      deliveryAppointmentTemplate {
        id
        date
        location
        capacity
        duration
      }

      returnAppointmentTemplate {
        id
        date
        location
        capacity
        duration
      }

      trainingAppointmentTemplate {
        id
        date
        location
        capacity
        duration
      }

      nextDeliveryAppointment {
        date
        location
      }

      nextTrainingAppointment {
        date
        location
      }

      nextReturnAppointment {
        date
        location
      }

      firstDeliveryAppointment {
        date
        location
      }
      firstTrainingAppointment {
        date
        location
      }
      firstReturnAppointment {
        date
        location
      }
      loanQuantityBikes
      conventionFilePath
      employeesTotalDistanceInKm(filter: $summary)
      employeesTotalKgCO2Saved(filter: $summary)
      employeesRidesCount(filter: $summary)
      employeesBikeDayUsed(filter: $summary)
      companyTotalMoneySaved(filter: $summary)
      employeesTotalEnergySpent(filter: $summary)
      employeesTotalMoneySaved
      employeesTotalSickDaysPrevented
      eligibleToSpecialBikes
    }
  }
`

export const QUERY_GET_COMPANY_DOCUMENTS = gql`
  query GetCompanyDocuments($where: CompanyWhereUniqueInput!) {
    company(where: $where) {
      id
      conventionFilePath
      documentList {
        id
        title
        filePath
        size
        restrictions
        createdAt
      }
    }
  }
`

export const QUERY_GET_SELECTED_EMPLOYEES = gql`
  query GetSelectedEmployees(
    $companyId: String!
    $dateFilter: DateTime
    $multiFilter: String
    $orderBy: SelectedCompanyEmployeesOrderByInput
  ) {
    selectedCompanyEmployees(
      companyId: $companyId
      dateFilter: $dateFilter
      multiFilter: $multiFilter
      orderBy: $orderBy
      hasBike: true
    ) {
      id
      firstName
      lastName
    }
  }
`

export const QUERY_COMPANIES_NAME = gql`
  query GetCompaniesNames(
    $where: CompanyWhereInput!
    $orderBy: [CompanyOrderByWithRelationInput!]
  ) {
    companies(where: $where, orderBy: $orderBy) {
      id
      name
    }
  }
`

export const QUERY_FLEETS_NAME = gql`
  query GetFleetNames($areaId: Int!) {
    fleets(where: { areaId: { equals: $areaId } }) {
      id
      name
    }
  }
`

export const QUERY_BIKE_MODELS_NAME = gql`
  query GetBikeModelsNames {
    bikeModels {
      id
      name
    }
  }
`

export const QUERY_GET_EMPLOYEE_EMAIL_VERIFIED = gql`
  query GetEmployeeEmailVerified($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      id
      user {
        email
        emailVerified
      }
    }
  }
`

export const QUERY_GET_EMPLOYEE_ABSENCES = gql`
  query GetEmployeeAbsences($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      absences {
        id
        category
        date
        deploymentId
      }
    }
  }
`

export const QUERY_GET_EMPLOYEE_SIGN_STATUS = gql`
  query GetEmployeeSignStatus($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      contractAccepted
    }
  }
`

export const QUERY_GET_ADMIN = gql`
  query GetAdmin($where: AdministratorWhereUniqueInput!) {
    administrator(where: $where) {
      id
      firstName
      lastName
      avatarUrl

      address
      city
      postalCode
      country

      phoneNumber

      user {
        email
      }
    }
  }
`

export const QUERY_GET_EMPLOYEE = gql`
  query GetEmployee($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      id
      avatarUrl
      firstName
      lastName
      phoneNumber
      country
      civility
      street
      city
      postalCode
      birthdate
      cityOfBirth
      height

      specialBikeType
      specialBikeAbsolutelyNeeded

      babySeat
      selectLoan
      identityCardFrontFilePath
      identityCardBackFilePath
      identityCardVerified
      proofOfAddressFilePath
      proofOfAddressVerified
      depositId
      depositExpirationDate
      depositByCheque
      workTravelDistance
      contractAccepted
      contractUrl
      remissionOrderUrl
      trainingAppointment {
        date
      }

      loanReturnAppointment {
        id
        date
        location
        deploymentId
      }

      loanDeliveryAppointment {
        id
        date
        location
        deploymentId
      }

      company {
        programStep {
          stepNbr
        }
      }

      documentList {
        id
        title
        filePath
        size
        restrictions
        createdAt
      }

      bikeParkingType {
        name
      }

      workTravelDuration
      travelMode {
        name
      }

      employeeBikeHabit {
        name
      }

      user {
        id
        email
        emailVerified
      }

      eligibleToSpecialBikes
    }
  }
`

export const QUERY_GET_BIKE_COMPONENT_HISTORIES = gql`
  query GetBikeComponentHistories($where: BikeComponentHistoryWhereInput) {
    bikeComponentHistories(where: $where) {
      id
      bikeComponent {
        bikeComponentPart {
          name
        }
      }
      stateOfUseBefore {
        name
      }
      stateOfUseAfter {
        name
      }
      bikeLoan {
        employeeDeploymentId
        bike {
          id
        }
      }
      noteAfter
      noteBefore
      estimatedRetention
    }
  }
`

export const QUERY_GET_LOAN_PACKAGE_HISTORIES = gql`
  query GetLoanPackageHistories($where: LoanPackageHistoryWhereInput) {
    loanPackageHistories(where: $where) {
      id
      loanPackage {
        packageAccessory {
          name
        }
      }
      stateOfUseBefore {
        name
      }
      stateOfUseAfter {
        name
      }
      bikeLoan {
        employeeDeploymentId
        bike {
          id
        }
      }
      noteBefore
      noteAfter
      estimatedRetention
    }
  }
`

export const QUERY_COMPANY_EMPLOYEES_DEPLOYMENTS = gql`
  query GetCompanyEmployeesDeployments(
    $where: EmployeeWhereInput
    $orderBy: [EmployeeOrderByWithRelationInput!]
    $pageSize: Int
    $offset: Int
  ) {
    employees(
      where: $where
      orderBy: $orderBy
      take: $pageSize
      skip: $offset
    ) {
      id
      firstName
      lastName
      documentsVerified
      selectLoan
      bikeLoan {
        bike {
          id
          bikeModel {
            id
            name
          }
        }
      }
      hasCompletedProfile
      remissionOrderUrl
      user {
        email
      }
      deployments {
        id
        name
        deploymentType
        bikeModelPrices {
          id
          remainingPrice
          bikeModelId
        }
      }
      sites {
        id
        name
      }
      employeeDeployments {
        id
        status
        option
        chosenBikeModelId
        deployment {
          id
          deploymentType
          name
          bikeModelPrices {
            id
            remainingPrice
            bikeModelId
          }
        }
      }
    }
  }
`

export const QUERY_COMPANY_EMPLOYEES = gql`
  query GetCompanyEmployees(
    $where: EmployeeWhereInput
    $orderBy: [EmployeeOrderByWithRelationInput!]
    $pageSize: Int
    $offset: Int
  ) {
    employees(
      where: $where
      orderBy: $orderBy
      take: $pageSize
      skip: $offset
    ) {
      id
      firstName
      lastName
      satisfaction
      documentsVerified
      selectLoan
      workTravelDistance
      bikeParkingType {
        name
      }
      travelMode {
        name
      }

      bikeLoan {
        bike {
          id
        }
      }

      satisfaction
      selectLoan
      hasCompletedProfile
      user {
        email
      }
    }
  }
`

export const QUERY_GET_EMPLOYEE_DASHBOARD = gql`
  query GetEmployeeDashboard($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      id
      avatarUrl
      firstName
      lastName
      phoneNumber
      country
      civility
      street
      city
      postalCode
      birthdate
      cityOfBirth
      height
      hasCompletedProfile
      documentsVerified
      identityCardFrontFilePath
      identityCardBackFilePath
      identityCardVerified
      proofOfAddressFilePath
      proofOfAddressVerified
      depositId
      selectLoan
      depositExpirationDate
      depositByCheque
      totalDistanceInKm
      ridesCount
      totalKgCO2Saved
      totalEnergySpent
      totalMoneySaved
      bikeDaysUsed
      eligibleToSpecialBikes

      contractUrl
      remissionOrderUrl

      specialBikeType
      specialBikeAbsolutelyNeeded
      babySeat

      workTravelDistance
      contractAccepted

      appointments {
        id
        category
        date
        location
        deploymentId
        deployment {
          id
          deploymentType
          loanQuantityBikes
          programStep {
            stepNbr
            name
          }
        }
      }

      absences {
        id
        category
        date
        deploymentId
      }

      bikeLoan {
        bikeReturned
        bikeDeliveredDate
        employeeDeployment {
          id
          status
          deployment {
            id
            name
            deploymentType
            loanQuantityBikes
            programStep {
              stepNbr
              name
            }
          }
        }
        bike {
          id
          bikeCondition {
            name
          }
        }
      }

      trainingAppointment {
        id
        date
        location
      }

      loanReturnAppointment {
        id
        date
        location
        deploymentId
      }

      loanDeliveryAppointment {
        id
        date
        location
        deploymentId
      }

      employeeDeployments {
        id
        status

        depositId
        depositSwikToken
        depositExpirationDate
        depositReleasedAt
        depositByCheque

        contractUrl
        remissionOrderUrl

        deployment {
          id
          name
          deploymentType
          archiveStatus
          loanQuantityBikes
          programStep {
            stepNbr
            name
          }
          appointmentTemplates {
            category
            location
            date
          }
        }
      }

      company {
        deliveryAppointmentTemplate {
          date
          location
        }

        returnAppointmentTemplate {
          date
          location
        }

        trainingAppointmentTemplate {
          date
          location
        }

        programStep {
          stepNbr
          name
        }
        loanQuantityBikes
      }

      documentList {
        id
        title
        filePath
        size
        restrictions
        createdAt
      }

      bikeParkingType {
        name
      }

      workTravelDuration

      travelMode {
        name
      }

      employeeBikeHabit {
        name
      }

      user {
        id
        email
        emailVerified
      }

      documentList {
        id
        title
        filePath
        size
        restrictions
        createdAt
      }

      bikeParkingType {
        name
      }

      workTravelDuration

      travelMode {
        name
      }

      employeeBikeHabit {
        name
      }

      user {
        id
        email
        emailVerified
      }

      sites {
        id
      }
    }
  }
`

export const QUERY_GET_EMPLOYEE_SUMMARY = gql`
  query GetEmployeeSummary(
    $where: EmployeeWhereUniqueInput!
    $summary: SummaryFilter!
  ) {
    employee(where: $where) {
      totalDistanceInKm(filter: $summary)
      totalKgCO2Saved(filter: $summary)
      ridesCount(filter: $summary)
      totalEnergySpent(filter: $summary)
      totalMoneySaved(filter: $summary)
      bikeDaysUsed(filter: $summary)
    }
  }
`

export const QUERY_GET_EMPLOYEE_SITE = gql`
  query GetEmployeeSite($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      id
      sites {
        id
        name
      }
    }
  }
`

export const QUERY_IDENTITY_CARD_FRONT_DOCUMENT = gql`
  query GetEmployeeIdentityCardFront($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      getPresignedIdentityCardFrontDocument
    }
  }
`

export const QUERY_IDENTITY_CARD_BACK_DOCUMENT = gql`
  query GetEmployeeIdentityCardBack($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      getPresignedIdentityCardBackDocument
    }
  }
`

export const QUERY_PROOF_OF_ADDRESS_DOCUMENT = gql`
  query GetEmployeeProofOfAddress($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      getPresignedProofOfAddressDocument
    }
  }
`

export const QUERY_ANIMATOR_NEXT_STEPS = gql`
  query AnimatorNextSteps(
    $fleetId: String
    $orderBy: AnimatorNextStepsOrderByInput
  ) {
    animatorNextSteps(fleetId: $fleetId, orderBy: $orderBy) {
      companyId
      companyName
      eventId
      eventName
      date
      participantsCount
      animatorName
    }
  }
`

export const QUERY_ANIMATOR_DEPLOYMENTS_COUNT = gql`
  query GetDeploymentsCount(
    $programStep: Int
    $deploymentType: String
    $company: String
    $multiFilter: String
    $archived: Boolean
  ) {
    deploymentsCount(
      programStep: $programStep
      deploymentType: $deploymentType
      company: $company
      multiFilter: $multiFilter
      archived: $archived
    )
  }
`

export const QUERY_ANIMATOR_DEPLOYMENTS = gql`
  query GetDeployments(
    $programStep: Int
    $deploymentType: String
    $company: String
    $take: Int
    $skip: Int
    $orderBy: [DeploymentOrderByInput]
    $multiFilter: String
    $archived: Boolean
  ) {
    deployments(
      programStep: $programStep
      deploymentType: $deploymentType
      company: $company
      take: $take
      skip: $skip
      orderBy: $orderBy
      multiFilter: $multiFilter
      archived: $archived
    ) {
      id
      name
      deploymentType
      candidateCount
      beneficiaryCount
      beneficiaryWomenCount
      beneficiaryMenCount
      deploymentStartDate
      deploymentEndDate
      employeesCount
      employeesTotalDistanceInKm
      employeesTotalMoneySaved
      employeesTotalEnergySpent
      employeesTotalKgCO2Saved
      selectedEmployeesCount
      hasSpecialBike
      updatedAt
      averageEmployeeAge
      averageEmployeeWorkTravelDistance
      employeeWithAppCount
      site {
        name
        street
        postalCode
        city
        company {
          id
          name
        }
        employeesOnSite
      }
      fleet {
        name
        area {
          name
        }
      }
      programStep {
        id
        stepNbr
        name
      }
    }
  }
`

export const QUERY_ANIMATOR_DEPLOYMENT_EDIT = gql`
  query GetDeploymentEdit($id: String!) {
    deployment(id: $id) {
      id
      name
      deploymentType
      deploymentStartDate
      startLocation
      deploymentEndDate
      endLocation
      cargoBike
      foldingBike
      urbanBike
      cargoBikeChildSeat
      urbanBikeChildSeat
      loanQuantityBikes
      bikeModelPrices {
        id
        remainingPrice
        bikeModelId
        childSeat
      }
      site {
        id
        company {
          id
        }
        area {
          id
        }
      }
      fleet {
        id
      }
      programStep {
        id
        stepNbr
      }
      appointmentTemplates {
        id
        category
        location
        date
        duration
        capacity
        companyId
      }
    }
  }
`

export const QUERY_DEPLOYMENT_FIRST_APPOINTMENT = gql`
  query GetDeploymentFirstAppointment($id: String!) {
    deployment(id: $id) {
      deploymentStartDate
      deploymentEndDate
      startLocation
      endLocation
      firstDeliveryAppointment {
        date
        location
      }
      firstReturnAppointment {
        date
        location
      }
    }
  }
`

export const QUERY_ANIMATOR_DEPLOYMENT = gql`
  query GetDeployment($id: String!, $summary: SummaryFilter) {
    deployment(id: $id) {
      id
      name
      deploymentType
      deploymentStartDate
      startLocation
      deploymentEndDate
      endLocation
      loanQuantityBikes
      registeredCount
      beneficiaryCount
      candidateCount
      hasSpecialBike
      employeesRidesCount(filter: $summary)
      employeesTotalDistanceInKm(filter: $summary)
      employeesTotalKgCO2Saved(filter: $summary)
      employeesTotalEnergySpent(filter: $summary)
      employeesTotalMoneySaved(filter: $summary)
      employeesBikeDayUsed(filter: $summary)
      site {
        name
        postalCode
        city
        street
        employeesOnSite
        bikeSpaceCapacityInside
        bikeSpaceCapacityOutside
        bikeSpaceCapacityUnsafe
        carSpaceCapacity
        electricStationCapacity
        company {
          id
          name
        }
      }
      fleet {
        name
      }
      programStep {
        id
        stepNbr
      }
      companyMonitoring {
        id
        date
        deadline
        note
        type
        companyMonitoringStatus {
          id
          name
        }
      }
    }
  }
`

export const QUERY_LAST_BIKE_POSITION = gql`
  query GetLastBikePosition {
    getLastBikePosition {
      morioId
      latitude
      longitude
      timestamp
    }
  }
`

export const QUERY_CURRENT_BIKE_INFO = gql`
  query GetBike($where: BikeWhereUniqueInput!) {
    bike(where: $where) {
      id
      index
      stickerId
      bicycode
      keysId
      morioId
      size
      childSeat
      padlockId
      frameId

      bikeDemonstration {
        returnDate
        company {
          name
        }
      }

      bikeSold {
        soldAt
        buyerName
      }

      bikeModel {
        id
        name
        modelSizes
      }

      bikeLoans(where: { bikeReturned: { equals: false } }) {
        id
      }
      fleet {
        id
        name
      }
      bikeCondition {
        name
      }
      repairs(where: { completed: { equals: false } }) {
        id
        completed
        resolvedDate
        completionNote
        beginningNote
        repairerLocation
        price
      }
      bikeComponents {
        id
        bikeComponentPart {
          name
        }
        stateOfUse {
          name
        }
        note
        bikeLoanAttachments {
          id
          size
          title
        }
      }
    }
  }
`

export const QUERY_GET_CONTACTS = gql`
  query GetContacts {
    getContacts {
      firstName
      lastName
      userRole
      email
      phoneNumber
    }
  }
`

export const QUERY_ANIMATOR_GET_ADVICES = gql`
  query GetAdvicesGetPosts(
    $skip: Int
    $take: Int
    $orderBy: [AdvicePostOrderByInput!]
  ) {
    advicePosts(orderBy: $orderBy, skip: $skip, take: $take) {
      id
      title
      MIME
      content
      order
      tags {
        key
        id
      }
    }
  }
`

export const QUERY_ANIMATOR_GET_ADVICE_TAGS = gql`
  query GetAdviceTags(
    $skip: Int
    $take: Int
    $orderBy: [AdviceTagOrderByInput!]
  ) {
    adviceTags(orderBy: $orderBy, skip: $skip, take: $take) {
      id
      key
      order
      advices {
        id
      }
    }
  }
`

export const QUERY_COUNT_QUIZ = gql`
  query CountQuiz {
    quizCount
  }
`

export const QUERY_COUNT_ADVICE = gql`
  query CountAdvice {
    adviceCount
  }
`

export const QUERY_COUNT_ADVICE_TAG = gql`
  query CountAdviceTag {
    adviceTagCount
  }
`

export const QUERY_COUNT_POST = gql`
  query CountPost {
    postCount
  }
`

export const QUERY_COUNT_POST_TAG = gql`
  query CountPostTag {
    postTagCount
  }
`

export const QUERY_ANIMATOR_GET_POSTS = gql`
  query GetPosts($skip: Int, $take: Int, $orderBy: [PostOrderByInput!]) {
    posts(orderBy: $orderBy, skip: $skip, take: $take) {
      id
      title
      MIME
      content
      order
      tags {
        key
        id
      }
    }
  }
`

export const QUERY_ANIMATOR_GET_TAGS = gql`
  query GetTags($skip: Int, $take: Int, $orderBy: [PostTagOrderByInput!]) {
    postTags(orderBy: $orderBy, skip: $skip, take: $take) {
      id
      key
      order
      posts {
        id
      }
    }
  }
`

export const QUERY_GET_QUIZZES = gql`
  query GetQuizzes(
    $take: Int
    $skip: Int
    $orderBy: [QuizOrderByWithRelationInput!]
  ) {
    quizzes(take: $take, skip: $skip, orderBy: $orderBy) {
      id
      title
      MIME
      content
      imageURL
    }
  }
`

export const QUERY_GET_BIKE_MODELS = gql`
  query GetBikeModel($where: BikeModelWhereInput) {
    bikeModels(where: $where) {
      id
      name
      modelSizes
      childSeatCompatibility
    }
  }
`

export const QUERY_GET_BIKE_CONDITION = gql`
  query GetBikeCondition($where: BikeConditionWhereUniqueInput!) {
    bikeCondition(where: $where) {
      id
      name
    }
  }
`

export const QUERY_GET_ANIMATOR = gql`
  query GetAnimtor($where: AnimatorWhereUniqueInput!) {
    animator(where: $where) {
      areaId
    }
  }
`

export const QUERY_GET_BIKES = gql`
  query GetBikes($where: BikeWhereInput!) {
    bikes(where: $where) {
      id
      stickerId
      morioId
      bicycode
      keysId
      fleet {
        id
      }
      bikeModel {
        id
      }
      index
    }
  }
`

export const QUERY_ALL_FLEET_NAMES = gql`
  query GetAllFleetNames($where: FleetWhereInput) {
    fleets(where: $where) {
      id
      name
    }
  }
`

export const QUERY_ALL_BIKE_MODEL_NAMES = gql`
  query GetAllBikeModelNames(
    $where: BikeModelWhereInput
    $orderBy: [BikeModelOrderByWithRelationInput!]
  ) {
    bikeModels(where: $where, orderBy: $orderBy) {
      id
      brand
      name
      bikeType
      childSeatCompatibility
    }
  }
`

export const QUERY_AREAS = gql`
  query GetAreas {
    areas {
      id
      name
      territoryDepartment {
        code
        name
      }
    }
  }
`

export const QUERY_GET_AREA_NAMES = gql`
  query GetAreaNames($where: AreaWhereInput) {
    areas(where: $where) {
      id
      name
    }
  }
`

export const QUERY_TERRITORY_DEPARTEMENT = gql`
  query GetTerritoryDepartment {
    territoryDepartments {
      code
      name
      area {
        name
        id
      }
    }
  }
`

export const QUERY_ALL_BIKE_COMPONENT_PARTS = gql`
  query GetAllBikeComponentParts($where: BikeComponentPartWhereInput) {
    bikeComponentParts(where: $where) {
      id
    }
  }
`

export const QUERY_STATE_OF_USE = gql`
  query GetStateOfUse($where: StateOfUseWhereUniqueInput!) {
    stateOfUse(where: $where) {
      id
      name
    }
  }
`

export const QUERY_LIST_COMPANY_SITES_BY_SUBSCRIPTION_CODE = gql`
  query listCompanySitesBySubscriptionCode($subscriptionCode: Int!) {
    sitesBySubscriptionCode(subscriptionCode: $subscriptionCode) {
      id
      name
    }
  }
`

export const QUERY_GET_COMPANY_SITES = gql`
  query GetCompanySites($where: SiteWhereInput!) {
    sites(where: $where) {
      id
      name
      areaId
    }
  }
`

export const QUERY_GET_COMPANY_DEPLOYMENTS = gql`
  query GetCompanyDeployments($where: CompanyWhereUniqueInput!) {
    company(where: $where) {
      id
      sites {
        id
        name
        deployments {
          id
          name
        }
      }
    }
  }
`

export const QUERY_GET_SITE_DEPLOYMENTS = gql`
  query GetSiteDeployments(
    $siteId: String!
    $take: Int
    $skip: Int
    $programStep: Int
    $deploymentType: String
    $orderBy: DeploymentBySiteOrderByInput
    $multiFilter: String
  ) {
    siteDeployments(
      siteId: $siteId
      take: $take
      skip: $skip
      programStep: $programStep
      deploymentType: $deploymentType
      orderBy: $orderBy
      multiFilter: $multiFilter
    ) {
      id
      name
      programStep {
        id
        name
        stepNbr
      }
      deploymentType
      employeesCount
      deploymentEndDate
      deploymentStartDate
      selectedEmployeesCount
      candidateCount
      beneficiaryCount
      archiveStatus
    }
    siteDeploymentsCount(
      siteId: $siteId
      programStep: $programStep
      deploymentType: $deploymentType
      multiFilter: $multiFilter
    )
  }
`

export const QUERY_GET_SITE = gql`
  query GetSite($id: String!) {
    site(id: $id) {
      name
      street
      postalCode
      city
      country
      employeesOnSite
      bikeSpaceCapacityInside
      bikeSpaceCapacityOutside
      bikeSpaceCapacityUnsafe
      carSpaceCapacity
      electricStationCapacity
      company {
        id
        name
      }
    }
  }
`

export const QUERY_GET_SITE_ADDRESS = gql`
  query GetSiteAddress($id: String!) {
    site(id: $id) {
      name
      street
      postalCode
      city
    }
  }
`

export const QUERY_GET_DISTINCT_BIKE_MODELS = gql`
  query GetDistinctBikeModels($fleetId: String!) {
    bikesDistinctModelsByFleet(fleetId: $fleetId) {
      id
      name
      type
      childSeatCompatibility
    }
  }
`

export const QUERY_COMPANY_REFERRER = gql`
  query GetCompanyReferrer($id: String!) {
    companyReferrer(id: $id) {
      id
      companyId
      firstName
      lastName
      civility
      email
      phoneNumber
    }
  }
`

export const QUERY_CHECK_ZIPCODE = gql`
  query CheckZipCode($where: TerritoryDepartmentWhereUniqueInput!) {
    territoryDepartment(where: $where) {
      code
      name
    }
  }
`

export const QUERY_GET_EMPLOYEE_BIKELOAN = gql`
  query GetEmployeeBikeLoan($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      bikeLoan {
        id
      }
    }
  }
`

export const QUERY_GET_EMPLOYEE_EMPLOYEEDEPLOYMENT = gql`
  query GetEmployeeEmployeeDeployment($where: EmployeeDeploymentWhereInput) {
    employeeDeployments(where: $where) {
      id
      depositId
      depositSwikToken
      depositExpirationDate
      depositReleasedAt
      depositByCheque
      status
      deployment {
        archiveStatus
        programStep {
          stepNbr
        }
      }
    }
  }
`

export const QUERY_GET_BIKEMODEL_INFO = gql`
  query GetBikeModelInfo($where: BikeModelWhereUniqueInput!) {
    bikeModel(where: $where) {
      id
      name
      brand
      bikeType
      modelSizes
      pictureUrl
      weight
      publicPrice
      childSeatCompatibility
      description
    }
  }
`

// export const QUERY_GET_ANIMATOR_DEPLOYMENTS = gql`
//   query GetDeployments(
//       $take: Int
//       $skip: Int
//       $orderBy: [DeploymentOrderByInput!]
//       ) {
//     deployments($take: Int, $skip: Int, $orderBy: [DeploymentOrderByInput!]) {
//       id
//       name
//       deploymentType
//       deploymentStartDate
//       deploymentEndDate
//     }
//   }
// `
