import { gql, QueryResult, useQuery } from '@apollo/client'
import {
  GetCompanySitesWithDeploymentsQuery,
  GetCompanySitesWithDeploymentsQueryVariables,
} from '../../../__generated__/graphql'

export const QUERY_GET_COMPANY_SITES_WITH_DEPLOYMENTS = gql`
  query GetCompanySitesWithDeployments($where: SiteWhereInput!) {
    sites(where: $where) {
      id
      name
      deployments {
        archiveStatus
        id
        name
        deploymentType
        deploymentStartDate
        deploymentEndDate
        programStep {
          id
          stepNbr
        }
      }
    }
  }
`

export const useCompanySitesWithDeployments = (
  id: string,
): QueryResult<GetCompanySitesWithDeploymentsQuery> => {
  return useQuery<
    GetCompanySitesWithDeploymentsQuery,
    GetCompanySitesWithDeploymentsQueryVariables
  >(QUERY_GET_COMPANY_SITES_WITH_DEPLOYMENTS, {
    variables: {
      where: {
        companyId: { equals: id },
      },
    },
  })
}
