/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'

import Button from '../Button'
import Icon from '../Icon'
import Popup from '../Popup'

import useWindowSize from '../../hooks/useWindowSize'

const StepPopupFooter: React.FC<{
  currLoanProcessStep: number
  isFirst: boolean
  isLast: boolean
  disableNext: boolean
  loading: boolean
  onPrev: () => void
  onNext: () => void
  onMouseEnterEvent: (isNext: boolean) => void
  onMouseLeaveEvent: () => void
}> = props => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <React.Fragment>
      {!props.isFirst ? (
        <React.Fragment>
          <Button
            type="tertiary"
            onClick={props.onPrev}
            onMouseEnter={() => props.onMouseEnterEvent(false)}
            onMouseLeave={() => props.onMouseLeaveEvent()}
          >
            <div css={{ marginRight: '-15px', marginLeft: '-15px' }}>
              <Icon type="back" color={theme.colors.primary} />
            </div>
          </Button>
          <div css={{ marginRight: 40 }} />
        </React.Fragment>
      ) : null}
      <Button
        type="primary"
        loading={props.loading}
        disabled={props.disableNext}
        onClick={props.onNext}
        onMouseEnter={
          !props.isLast ? () => props.onMouseEnterEvent(true) : undefined
        }
        onMouseLeave={
          !props.isLast ? () => props.onMouseLeaveEvent() : undefined
        }
      >
        {props.isLast ? t('forms.button.valid') : t('forms.button.next')}
      </Button>
    </React.Fragment>
  )
}

const variants = {
  center: {
    x: '-50%',
    opacity: 1,
  },
  enter: (direction: number) => {
    return {
      x: `calc(-50% ${direction > 0 ? '+' : '-'} 650px)`,
      opacity: 1,
    }
  },
  exit: (direction: number) => {
    return {
      x: `calc(-50% ${direction < 0 ? '+' : '-'} 650px)`,
      opacity: 1,
    }
  },
  previewExit: (direction: number) => {
    return {
      x: `calc(-50% ${direction < 0 ? '+' : '-'} 70px)`,
      opacity: 0.5,
    }
  },
}

export interface PopupStepType {
  height: number
  mobileHeight: number
  title: string
  isFirst: boolean
  isLast: boolean
}

type AnimatedStepStateType = [string, number]
const initialAnimatedStepState: AnimatedStepStateType = ['center', 0]

interface StepPopupProps {
  processStep: PopupStepType
  processStepNbr: number
  changeStep: (direction: 1 | -1) => void
  canGoNext: () => Promise<boolean | void>
  closeModal: () => void
  children: React.ReactNode
  disableNext?: boolean
  loading?: boolean
}

const StepPopup: React.FC<StepPopupProps> = ({
  processStep,
  processStepNbr,
  changeStep,
  canGoNext,
  closeModal,
  children,
  disableNext = false,
  loading = false,
}) => {
  const theme = useTheme()
  const [[animate, direction], setAnimate] =
    React.useState<AnimatedStepStateType>(initialAnimatedStepState)
  const { width } = useWindowSize()

  const onMouseEnterEvent = (isNext: boolean) => {
    const animateValue = 'previewExit'
    const newDirection = isNext ? 1 : -1
    setAnimate([animateValue, newDirection])
  }
  const onMouseLeaveEvent = () => {
    setAnimate(initialAnimatedStepState)
  }

  const onNext = async () => {
    const canProgress = await canGoNext()

    if (canProgress) {
      if (!processStep.isLast) {
        setAnimate(['center', 1])
        changeStep(1)
      } else {
        closeModal()
      }
    } else {
      setAnimate(initialAnimatedStepState)
    }
  }
  const onPrev = () => {
    if (!processStep.isFirst) {
      setAnimate(['center', -1])
      changeStep(-1)
    }
  }

  return (
    <Popup
      closable
      title={processStep.title}
      titleColor={theme.colors.primary}
      footer={
        <StepPopupFooter
          isFirst={processStep.isFirst}
          isLast={processStep.isLast}
          currLoanProcessStep={processStepNbr}
          onNext={onNext}
          onPrev={onPrev}
          disableNext={disableNext}
          loading={loading}
          onMouseEnterEvent={onMouseEnterEvent}
          onMouseLeaveEvent={onMouseLeaveEvent}
        />
      }
      maxWidth={762}
      onClose={closeModal}
    >
      <motion.div
        css={{
          overflowX: 'hidden',
          width: '100%',
          position: 'relative',
        }}
        animate={{
          height:
            width <= theme.breakpoints.mobile
              ? processStep.mobileHeight
              : processStep.height,
        }}
      >
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            css={{
              width: '100%',
              left: '50%',
              position: 'absolute',
              textAlign: 'center',
            }}
            key={processStepNbr}
            custom={direction}
            initial="enter"
            exit="exit"
            animate={animate}
            variants={variants}
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </Popup>
  )
}

export default StepPopup
