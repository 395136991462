/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useCurrentDeploymentId } from '../hooks/useCurrentDeploymentId'
import DeleteDeploymentModal from './DeleteDeploymentModal'
import { useModal } from '../../../../hooks/useModal'

export interface DeleteDeploymentButtonProps {
  onCompleted?: () => void
  id?: string
}

export const DeleteDeploymentButton: React.FC<DeleteDeploymentButtonProps> = ({
  onCompleted,
  id,
}) => {
  const defaultId = useCurrentDeploymentId()
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <DeleteDeploymentModal
        onCompleted={onCompleted}
        id={id ?? defaultId}
        {...modalProps}
      />
      <button onClick={onOpen}>
        <Icon type="trash" />
      </button>
    </Fragment>
  )
}
