/** @jsx jsx */
import { gql, useMutation, useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import {
  BikeComponentPartType,
  PackageAccessoryType,
} from '@goodwatt/shared/dist/types'
import { format } from 'date-fns'
import { useTheme } from 'emotion-theming'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../../../../../../../components/Icon'
import InputCamera from '../../../../../../../components/InputCamera'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../../../../contexts/NotificationContext'
import { StoreState } from '../../../../../../../redux/rootReducer'
import { PartsConditionTableRow } from '../../../../../../../types/AnimatorCompanyEmployees'
import apiErrorCatcher from '../../../../../../../utils/apiErrorCatcher'
import { formatBytes } from '../../../../../../../utils/files'
import {
  DeleteOneBikeLoanAttachmentMutation,
  DeleteOneBikeLoanAttachmentMutationVariables,
  GetBikeLoanAttachmentsQuery,
  GetBikeLoanAttachmentsQueryVariables,
  GetPresignedBikeLoanAttachmentMutation,
  GetPresignedBikeLoanAttachmentMutationVariables,
  UploadOneBikeLoanAttachmentMutation,
  UploadOneBikeLoanAttachmentMutationVariables,
} from '../../../../../../../__generated__/graphql'

const QUERY_BIKE_LOAN_ATTACHMENTS = gql`
  query GetBikeLoanAttachments(
    $bikeComponentId: String
    $loanPackageId: String
    $bikeLoanId: String
  ) {
    bikeLoanAttachments(
      where: {
        OR: [
          {
            AND: [
              { bikeLoanId: { equals: $bikeLoanId } }
              { bikeComponentId: { equals: $bikeComponentId } }
              { bikeComponentId: { not: null } }
            ]
          }
          {
            AND: [
              { bikeLoanId: { equals: $bikeLoanId } }
              { loanPackageId: { equals: $loanPackageId } }
              { loanPackageId: { not: null } }
            ]
          }
        ]
      }
    ) {
      id
      size
      title
    }
  }
`

const MUTATION_UPLOAD_BIKE_LOAN_ATTACHMENT = gql`
  mutation UploadOneBikeLoanAttachment(
    $file: Upload!
    $title: String!
    $bikeComponentId: String
    $loanPackageId: String
    $bikeLoanId: String
  ) {
    uploadOneBikeLoanAttachment(
      file: $file
      title: $title
      bikeComponentId: $bikeComponentId
      loanPackageId: $loanPackageId
      bikeLoanId: $bikeLoanId
    ) {
      id
      title
      size
    }
  }
`

const MUTATION_DELETE_BIKE_LOAN_ATTACHMENT = gql`
  mutation DeleteOneBikeLoanAttachment($attachmentId: String!) {
    deleteOneBikeLoanAttachment(attachmentId: $attachmentId)
  }
`

const MUTATION_GET_PRESIGNED_BIKE_LOAN_ATTACHMENT = gql`
  mutation GetPresignedBikeLoanAttachment($attachmentId: String!) {
    getPresignedBikeLoanAttachment(attachmentId: $attachmentId)
  }
`

interface AttachmentsProps {
  part: PartsConditionTableRow
  setFormAction: (
    payload: Partial<
      | StoreState['forms']['loanBikeForm']
      | StoreState['forms']['returnBikeForm']
    >,
  ) => void
  componentType: 'bikeComponent' | 'loanPackage'
  bikeLoanId?: string
  shouldOpenCameraInput?: boolean
  closeAutomaticCameraInput?: () => void
}

const Attachments: React.FC<AttachmentsProps> = ({
  part: { id: partId, name: partName },
  setFormAction,
  bikeLoanId,
  componentType,
  shouldOpenCameraInput = false,
  closeAutomaticCameraInput,
}) => {
  const theme = useTheme()
  const notifications = useNotifications()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { accessories, components } = useSelector((state: StoreState) => {
    return bikeLoanId ? state.forms.returnBikeForm : state.forms.loanBikeForm
  })
  const { data, refetch: refetchAttachments } = useQuery<
    GetBikeLoanAttachmentsQuery,
    GetBikeLoanAttachmentsQueryVariables
  >(QUERY_BIKE_LOAN_ATTACHMENTS, {
    fetchPolicy: 'network-only',
    variables: {
      ...(componentType === 'bikeComponent'
        ? // Here we always need to send "null" for the `wrong` resource to be sure where clause works
          {
            bikeComponentId: partId,
            loanPackageId: null,
            bikeLoanId,
          }
        : {
            bikeComponentId: null,
            loanPackageId: partId,
            bikeLoanId,
          }),
    },
  })

  const shouldDisplayInput =
    data?.bikeLoanAttachments && data?.bikeLoanAttachments.length < 3

  useEffect(() => {
    if (
      shouldOpenCameraInput &&
      shouldDisplayInput === false &&
      closeAutomaticCameraInput
    ) {
      closeAutomaticCameraInput()
      notifications.newNotification({
        type: NotificationTypes.WARNING,
        message: t(
          'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeComponentsCondition.bikeComponentPartModal.maxLimitAttachmentReached',
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpenCameraInput, closeAutomaticCameraInput, shouldDisplayInput])

  const [uploadAttachment, { loading }] = useMutation<
    UploadOneBikeLoanAttachmentMutation,
    UploadOneBikeLoanAttachmentMutationVariables
  >(MUTATION_UPLOAD_BIKE_LOAN_ATTACHMENT)
  const [deleteAttachment, { loading: deleteLoading }] = useMutation<
    DeleteOneBikeLoanAttachmentMutation,
    DeleteOneBikeLoanAttachmentMutationVariables
  >(MUTATION_DELETE_BIKE_LOAN_ATTACHMENT)
  const [getPresignedAttachment, { loading: previewLoading }] = useMutation<
    GetPresignedBikeLoanAttachmentMutation,
    GetPresignedBikeLoanAttachmentMutationVariables
  >(MUTATION_GET_PRESIGNED_BIKE_LOAN_ATTACHMENT)

  const handleAddAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const fileTitle = `${bikeLoanId ? 'RETOUR_' : 'DEPART_'}${format(
        new Date(),
        'dd.MM.yyyy_HH:mm:ss',
      )}`
      uploadAttachment({
        variables: {
          file: e.target.files[0],
          title: fileTitle,
          ...(componentType === 'bikeComponent'
            ? {
                bikeComponentId: partId,
              }
            : { loanPackageId: partId }),
          bikeLoanId,
        },
      })
        .then(res => {
          if (!res.data?.uploadOneBikeLoanAttachment) throw new Error()
          dispatch(
            setFormAction({
              ...(componentType === 'bikeComponent'
                ? {
                    components: {
                      ...components,
                      [partName as BikeComponentPartType]: {
                        ...components[partName as BikeComponentPartType],
                        attachments: [
                          ...(components[partName as BikeComponentPartType]
                            ?.attachments ?? []),
                          {
                            id: res.data.uploadOneBikeLoanAttachment.id,
                            title: res.data.uploadOneBikeLoanAttachment.title,
                            size: res.data.uploadOneBikeLoanAttachment.size,
                          },
                        ],
                      },
                    },
                  }
                : {
                    accessories: {
                      ...accessories,
                      [partName as PackageAccessoryType]: {
                        ...accessories[partName as PackageAccessoryType],
                        attachments: [
                          ...(accessories[partName as PackageAccessoryType]
                            ?.attachments ?? []),
                          {
                            id: res.data.uploadOneBikeLoanAttachment.id,
                            title: res.data.uploadOneBikeLoanAttachment.title,
                            size: res.data.uploadOneBikeLoanAttachment.size,
                          },
                        ],
                      },
                    },
                  }),
            }),
          )
          return refetchAttachments()
        })
        .then(() => {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t(
              'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeComponentsCondition.bikeComponentPartModal.wellUploadedNotification',
            ),
          })
        })
        .catch(apiErrorCatcher(notifications))
      return
    }
  }

  const handleDeleteAttachment = (
    e: React.MouseEvent<HTMLDivElement>,
    fileId: string,
  ) => {
    e.stopPropagation()
    deleteAttachment({ variables: { attachmentId: fileId } })
      .then(() => {
        dispatch(
          setFormAction({
            ...(componentType === 'bikeComponent'
              ? {
                  components: {
                    ...components,
                    [partName as BikeComponentPartType]: {
                      ...components[partName as BikeComponentPartType],
                      attachments:
                        components[
                          partName as BikeComponentPartType
                        ]?.attachments?.filter(file => file.id !== fileId) ??
                        [],
                    },
                  },
                }
              : {
                  accessories: {
                    ...accessories,
                    [partName as PackageAccessoryType]: {
                      ...accessories[partName as PackageAccessoryType],
                      attachments:
                        accessories[
                          partName as PackageAccessoryType
                        ]?.attachments?.filter(file => file.id !== fileId) ??
                        [],
                    },
                  },
                }),
          }),
        )
        return refetchAttachments()
      })
      .then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t(
            'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeComponentsCondition.bikeComponentPartModal.wellDeletedNotification',
          ),
        })
      })
      .catch(apiErrorCatcher(notifications))
  }

  const handleOpenPreview = (
    e: React.MouseEvent<HTMLDivElement>,
    fileId: string,
  ) => {
    e.preventDefault()
    getPresignedAttachment({
      variables: {
        attachmentId: fileId,
      },
    })
      .then(res => {
        if (res.data?.getPresignedBikeLoanAttachment)
          window.open(res.data.getPresignedBikeLoanAttachment, '_blank')
      })
      .catch(apiErrorCatcher(notifications))
  }

  const isFetching = loading || deleteLoading || previewLoading

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 25,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          minHeight: 24,
          alignItems: 'center',
          marginLeft: 24,
          marginBottom: 4,
        }}
      >
        <span css={{ marginRight: 10 }}>
          {t(
            'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeComponentsCondition.bikeComponentPartModal.uploadedFiles',
          )}
        </span>
        <AnimatePresence>
          {isFetching && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Icon type="loader" strokeColor={theme.colors.primary} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {data?.bikeLoanAttachments?.map(file => (
          // A file is really uploaded at the end of every steps, until then we just storing it in redux's state
          <motion.div
            onClick={e => handleOpenPreview(e, file.id)}
            key={file.id}
            css={{
              cursor: 'pointer',
              width: '100%',
              padding: '10px 12px',
              marginBottom: 2,
              fontSize: '1.4rem',
              border: `2px solid ${theme.colors.gray6}`,
              borderRadius: 22,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: theme.colors.white,
            }}
            initial={{
              y: 50,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            exit={{
              y: 50,
              opacity: 0,
            }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div css={{ marginRight: 20 }}>
                <Icon
                  type="unknownDoc"
                  color={theme.colors.gray1}
                  customSize={{ width: 19, height: 19 }}
                />
              </div>
              <span css={{ color: theme.colors.gray1 }}>{file.title}</span>
            </div>
            <span css={{ fontSize: '1.2rem' }}>{formatBytes(file.size)}</span>
            <div onClick={e => handleDeleteAttachment(e, file.id)}>
              <Icon
                type="trash"
                color={theme.colors.gray2}
                customSize={{ width: 19, height: 19 }}
              />
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
      {shouldDisplayInput && (
        <div
          css={{
            width: '100%',
            marginTop: 18,
            textAlign: 'center',
            fontSize: '1.6rem',
          }}
        >
          <InputCamera
            label={t(
              'animator.company.employees.selected.modals.employeeLoanBikeModal.bikeComponentsCondition.bikeComponentPartModal.addFile',
            )}
            name="partPicture"
            isOpen={shouldOpenCameraInput}
            onInputOpen={closeAutomaticCameraInput}
            onChange={handleAddAttachment}
          />
        </div>
      )}
    </div>
  )
}

export default Attachments
