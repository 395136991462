import * as yup from 'yup'
import { constants, regex } from '@goodwatt/shared'
import i18n from '../i18n/config'

export const avatarSchema = yup.object().shape({
  avatar: yup
    .mixed()
    .test('fileSize', i18n.t('forms.errors.fileSize'), value => {
      if (!value || value.length === 0) return true
      return value[0].size <= constants.MAX_AVATAR_SIZE
    })
    .test('fileType', i18n.t('forms.errors.fileFormat'), value => {
      if (!value || value.length === 0) return true
      return constants.SUPPORTED_FORMATS_IMG.includes(value[0].type)
    }),
})

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required(i18n.t('forms.errors.required')),
  newPassword: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .matches(regex.passwordRegex, i18n.t('forms.errors.passwordRegex')),
  confirmNewPassword: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .oneOf(
      [yup.ref('newPassword')],
      i18n.t('forms.errors.passwordsNotIdentical'),
    ),
})
