/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import Modal from '@goodwatt/client/src/components/Modal'
import Popup from '@goodwatt/client/src/components/Popup'
import TextArea from '@goodwatt/client/src/components/TextArea'
import { TextVariant } from '../../../hooks/useVariant/variants/text'

import { AdviceTableRow } from '@goodwatt/client/src/types/AnimatorAdvices'

interface ViewModalProps {
  isOpen: boolean
  advice: AdviceTableRow
  closeModal: () => void
}

const ViewModal: React.FC<ViewModalProps> = ({
  isOpen,
  closeModal,
  advice,
}) => {
  const { t } = useTranslation()

  const tags = advice.tags.reduce(
    (sum, value) =>
      sum + t(`animator.advice.filter.tags.options.${value.key}`) + ', ',
    '',
  )

  return (
    <Modal isOpen={isOpen}>
      <Popup closable title={advice.title} onClose={closeModal} maxWidth={750}>
        <div>
          <div css={{ marginTop: '1rem' }}>
            <TextArea
              name={t('animator.advice..modal.view.tags')}
              label={t('animator.advice.modal.view.tags')}
              disabled
              value={tags}
            />
          </div>
          <div css={{ marginTop: '1rem' }}>
            <TextArea
              name={t('animator.advice..modal.view.content')}
              label={t('animator.advice.modal.view.content')}
              disabled
              value={advice.content}
              variant={TextVariant.white}
            />
          </div>
        </div>
      </Popup>
    </Modal>
  )
}

export default ViewModal
