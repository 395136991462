/** @jsx jsx */
import React, { Fragment, useState } from 'react'
import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'
import Button from '../../../../components/Button'
import TextArea from '@goodwatt/client/src/components/TextArea'
import Switch from '../../../../components/Switch'
import { jsx } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../../../components/Checkbox'
import Input from '../../../../components/Input'
import { useMutation, useQuery } from '@apollo/client'
import { BikeModelTableModal } from '../../../../types/AnimatorBikes'
import {
  useNotifications,
  NotificationTypes,
} from '../../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import EditPicture from './EditPicture'
import { QUERY_GET_BIKEMODEL_INFO } from '../../../../utils/gql/queries'
import {
  MUTATION_CREATE_ONE_BIKEMODEL,
  MUTATION_UPDATE_ONE_BIKEMODEL,
} from '../../../../utils/gql/mutations'
import {
  GetBikeModelInfoQuery,
  GetBikeModelInfoQueryVariables,
  CreateOneBikeModelMutation,
  CreateOneBikeModelMutationVariables,
  UpdateOneBikeModelMutation,
  UpdateOneBikeModelMutationVariables,
  SpecialBikesType,
} from '../../../../__generated__/graphql'
import theme from '../../../../styles/theme'
import InputSelect from '../../../../components/InputSelect'

// Cannot use enum from generated grapqhql types because order is not guaranteed
enum BikeSize {
  Xs = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  Xl = 'XL',
}

interface ModalProps {
  isOpen: boolean
  bikeModelId?: string
  type: BikeModelTableModal | ''
  closeModal: (shouldRefetch?: boolean) => void
}

interface AddModelBike {
  id: string
  brand: string
  name: string
  bikeType: SpecialBikesType
  modelSizes: BikeSize[] | undefined
  weight?: string | undefined
  publicPrice?: string | undefined
  childSeatCompatibility: boolean
  description?: string
  pictureUrl?: string | undefined
  size?: number | undefined
  batteryPower?: number | undefined
}

const initialAddBikeState: AddModelBike = {
  id: '',
  brand: '',
  name: '',
  bikeType: SpecialBikesType.UrbanBike,
  modelSizes: undefined,
  weight: '',
  publicPrice: '',
  childSeatCompatibility: false,
  description: '',
  pictureUrl: undefined,
  size: 0,
  batteryPower: 0,
}

const BikeModelModal: React.FC<ModalProps> = ({
  isOpen,
  bikeModelId,
  type,
  closeModal,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [BikeModel, setBikeModel] = useState<AddModelBike>(initialAddBikeState)

  const onClose = (mustRefetch: boolean) => {
    setBikeModel(initialAddBikeState)
    closeModal(mustRefetch)
  }

  const { data, loading, refetch } = useQuery<
    GetBikeModelInfoQuery,
    GetBikeModelInfoQueryVariables
  >(QUERY_GET_BIKEMODEL_INFO, {
    variables: {
      where: {
        id: bikeModelId,
      },
    },
    skip: !bikeModelId,
  })

  const [createBikeModel, { loading: creating }] = useMutation<
    CreateOneBikeModelMutation,
    CreateOneBikeModelMutationVariables
  >(MUTATION_CREATE_ONE_BIKEMODEL, {
    onCompleted: () => {
      notifications.newNotification({
        type: NotificationTypes.SUCCESS,
        message: t('animator.bikeModels.modal.add.success'),
      })
      onClose(true)
    },
    onError: error => {
      notifications.newNotification({
        type: NotificationTypes.ERROR,
        message: t('animator.bikeModels.modal.add.failure'),
      })
      apiErrorCatcher(notifications)
    },
    refetchQueries: ['GetAllBikeModelNames', 'GetBikeModelInfo'],
  })

  const [updateBikeModel, { loading: updating }] = useMutation<
    UpdateOneBikeModelMutation,
    UpdateOneBikeModelMutationVariables
  >(MUTATION_UPDATE_ONE_BIKEMODEL, {
    onCompleted: () => {
      notifications.newNotification({
        type: NotificationTypes.SUCCESS,
        message: t('animator.bikeModels.modal.edit.success'),
      })
      onClose(true)
    },
    onError: error => {
      notifications.newNotification({
        type: NotificationTypes.ERROR,
        message: t('animator.bikeModels.modal.edit.failure'),
      })
      apiErrorCatcher(notifications)
    },
    refetchQueries: ['GetAllBikeModelNames', 'GetBikeModelInfo'],
  })

  React.useEffect(() => {
    if (data?.bikeModel) {
      setBikeModel({
        id: data.bikeModel.id,
        brand: data.bikeModel.brand,
        name: data.bikeModel.name,
        bikeType: data.bikeModel.bikeType,
        modelSizes: data.bikeModel.modelSizes,
        weight: data.bikeModel.weight,
        publicPrice: data.bikeModel.publicPrice,
        childSeatCompatibility: !!data.bikeModel.childSeatCompatibility,
        description: data.bikeModel.description || '',
        pictureUrl: data.bikeModel.pictureUrl || '',
      })
    } else {
      setBikeModel(initialAddBikeState)
    }
  }, [data, isOpen])

  const isLoading = loading || creating || updating

  const onBikeInputChange = React.useCallback(e => {
    e.persist()
    setBikeModel(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }, [])

  const onChangeBikeChildSeat = () => {
    setBikeModel(prevState => ({
      ...prevState,
      childSeatCompatibility: !prevState.childSeatCompatibility,
    }))
  }

  const onChangeBikeType = async (data: any) => {
    setBikeModel(prevState => ({
      ...prevState,
      bikeType: data.value,
    }))
  }

  const onChangeBikeSize = (size: BikeSize) => {
    setBikeModel(prevState => {
      const modelSizes = prevState.modelSizes || []
      const newModelSizes = modelSizes.includes(size)
        ? modelSizes.filter(ms => ms !== size)
        : [...modelSizes, size]

      return {
        ...prevState,
        modelSizes: newModelSizes,
      }
    })
  }

  const onChangeBikePicture = (url: string) => {
    setBikeModel(prevState => ({
      ...prevState,
      pictureUrl: url,
    }))
  }

  const isFormValid =
    BikeModel.brand &&
    BikeModel.name &&
    BikeModel.bikeType &&
    BikeModel.modelSizes &&
    BikeModel.modelSizes.length > 0

  const handleSubmit = () => {
    if (!isFormValid || isLoading) return

    if (type === BikeModelTableModal.ADD) {
      createBikeModel({
        variables: {
          data: {
            brand: BikeModel.brand,
            name: BikeModel.name,
            bikeType: BikeModel.bikeType,
            modelSizes: {
              set: BikeModel.modelSizes,
            },
            weight: BikeModel.weight,
            publicPrice: BikeModel.publicPrice,
            childSeatCompatibility: BikeModel.childSeatCompatibility,
            description: BikeModel.description,
            pictureUrl: BikeModel.pictureUrl,
            size: BikeModel.size,
            batteryPower: BikeModel.batteryPower,
          },
        },
      })
    } else {
      updateBikeModel({
        variables: {
          data: {
            brand: { set: BikeModel.brand },
            name: { set: BikeModel.name },
            bikeType: { set: BikeModel.bikeType },
            modelSizes: { set: BikeModel.modelSizes },
            weight: { set: BikeModel.weight || null },
            publicPrice: { set: BikeModel.publicPrice || null },
            childSeatCompatibility: { set: BikeModel.childSeatCompatibility },
            description: { set: BikeModel.description || '' },
            pictureUrl: { set: BikeModel.pictureUrl },
          },
          where: {
            id: BikeModel.id,
          },
        },
      })
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        overflowY="visible"
        closable
        title={
          type === BikeModelTableModal.ADD
            ? t('animator.bikeModels.modal.add.title')
            : t('animator.bikeModels.modal.edit.title')
        }
        onClose={() => onClose(false)}
        maxWidth={1000}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button onClick={() => onClose(false)} type="tertiary">
                {t('shared.button.cancel')}
              </Button>
            </div>
            <Button
              type="primary"
              submit
              loading={isLoading}
              onClick={handleSubmit}
              disabled={!isFormValid}
            >
              {t('forms.button.save')}
            </Button>
          </Fragment>
        }
      >
        <form onSubmit={handleSubmit}>
          <div
            css={{ display: 'flex', flexDirection: 'column', padding: '0 30' }}
          >
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div css={{ marginRight: 20, flex: 2 }}>
                <Input
                  type="text"
                  name="brand"
                  onChange={onBikeInputChange}
                  value={BikeModel?.brand}
                  label={t('animator.bikeModels.modal.fields.brand')}
                />
              </div>
              <div css={{ display: 'flex', flex: 2, marginRight: 20 }}>
                <Input
                  type="text"
                  name="name"
                  value={BikeModel?.name}
                  onChange={onBikeInputChange}
                  label={t('animator.bikeModels.modal.fields.name')}
                />
              </div>
              <div css={{ display: 'flex', flex: 2 }}>
                <InputSelect
                  name="bikeType"
                  options={Object.values(SpecialBikesType).map(value => {
                    return {
                      label: t(`shared.specialBikes.abbreviation.${value}`),
                      value,
                    }
                  })}
                  onChange={onChangeBikeType}
                  value={
                    BikeModel?.bikeType
                      ? {
                          label: t(
                            `shared.specialBikes.abbreviation.${BikeModel?.bikeType}`,
                          ),
                          value: BikeModel?.bikeType,
                        }
                      : undefined
                  }
                  label={t('animator.bikeModels.modal.fields.bikeType')}
                />
              </div>
            </div>

            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div css={{ marginRight: 20, flex: 2 }}>
                <Input
                  type="text"
                  name="weight"
                  onChange={onBikeInputChange}
                  value={BikeModel?.weight}
                  label={t('animator.bikeModels.modal.fields.weight')}
                />
              </div>
              <div css={{ display: 'flex', flex: 2 }}>
                <Input
                  type="text"
                  name="publicPrice"
                  value={BikeModel?.publicPrice}
                  onChange={onBikeInputChange}
                  label={t('animator.bikeModels.modal.fields.publicPrice')}
                />
              </div>
            </div>

            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                flexWrap: 'wrap',
              }}
            >
              <div css={{ marginRight: 20, flex: 2 }}>
                <TextArea
                  block
                  name="description"
                  value={BikeModel?.description}
                  label={t('animator.bikeModels.modal.fields.description')}
                  onChange={onBikeInputChange}
                  rows={8}
                  placeholder={t(
                    'animator.bikeModels.modal.fields.leaveAcontent',
                  )}
                />
              </div>
              <div
                css={{
                  display: 'flex',
                  flex: 2,
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Fragment>
                  <label
                    css={{
                      fontSize: '1.6rem',
                      color: theme.colors.gray3,
                      textAlign: 'left',
                      marginBottom: 4,
                      marginLeft: '24px',
                      width: '100%',
                    }}
                  >
                    {t('animator.bikeModels.modal.fields.picture')}
                  </label>
                  <EditPicture
                    resourceId={BikeModel?.id || ''}
                    refetch={() =>
                      BikeModel?.id !== '' ? refetch() : undefined
                    }
                    avatarUrl={BikeModel?.pictureUrl || undefined}
                    onChangeBikePicture={onChangeBikePicture}
                  />
                </Fragment>
              </div>
            </div>

            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                flexWrap: 'wrap',
              }}
            >
              <div css={{ marginRight: 20, flex: 2 }}>
                <div css={{ width: '100%' }}>
                  <div css={{ marginLeft: '24px', marginBottom: '4px' }}>
                    <label
                      css={{
                        fontSize: '1.6rem',
                        fontWeight: 'normal',
                        color: theme.colors.gray3,
                      }}
                    >
                      {t(`animator.bikeModels.modal.fields.modelSizes`)}
                    </label>
                  </div>
                  <div
                    css={{
                      marginTop: '11px',
                      marginLeft: '24px',
                      display: 'flex',
                      gap: '20px',
                    }}
                  >
                    {Object.values(BikeSize).map((value, index) => {
                      return (
                        <div
                          css={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '5px',
                          }}
                        >
                          <span>{value}</span>
                          <Checkbox
                            isChecked={
                              BikeModel?.modelSizes?.includes(value) || false
                            }
                            onClick={() => onChangeBikeSize(value)}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div css={{ flex: 2 }}>
                <div css={{ width: '100%' }}>
                  <div css={{ marginLeft: '24px', marginBottom: '4px' }}>
                    <label
                      css={{
                        fontSize: '1.6rem',
                        fontWeight: 'normal',
                        color: theme.colors.gray3,
                      }}
                    >
                      {t(
                        `animator.bikeModels.modal.fields.childSeatCompatibility`,
                      )}
                    </label>
                  </div>
                  <div
                    css={{
                      height: '44px',
                      marginBottom: '11px',
                      marginTop: '11px',
                      marginLeft: '24px',
                    }}
                  >
                    <Switch
                      isChecked={BikeModel?.childSeatCompatibility}
                      onClick={() => onChangeBikeChildSeat()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default BikeModelModal
