/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import {
  AdviceSettings,
  AdviceTableModalActions,
  AdviceTableRow,
} from '@goodwatt/client/src/types/AnimatorAdvices'
import Table from '@goodwatt/client/src/components/Table'
import AdviceActionCell from '@goodwatt/client/src/components/Table/CustomCells/AdviceActionCell'
import { QUERY_ANIMATOR_GET_ADVICES } from '../../../utils/gql/queries'

import { useTheme } from 'emotion-theming'

import ViewModal from './ViewModal'
import {
  GetAdvicesGetPostsQuery,
  GetAdvicesGetPostsQueryVariables,
} from '../../../__generated__/graphql'

const initalModalSettings: AdviceSettings = {
  advice: {
    id: '',
    title: '',
    content: '',
    order: undefined,
    MIME: '',
    tags: [],
  },
  actionName: '',
}

interface AnimatorCompanyMonitoringProps {
  pageSize: number
}

const AnimatorCompanyMonitoringList: React.FC<
  AnimatorCompanyMonitoringProps
> = ({ pageSize }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [currModal, setCurrModal] =
    useState<AdviceSettings>(initalModalSettings)

  const { data: adviceData, loading } = useQuery<
    GetAdvicesGetPostsQuery,
    GetAdvicesGetPostsQueryVariables
  >(QUERY_ANIMATOR_GET_ADVICES, {
    fetchPolicy: 'network-only',
    variables: {
      skip: 0,
      take: pageSize,
    },
  })

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<AdviceTableRow> }) => (
      <AdviceActionCell
        setModal={setCurrModal}
        row={row}
        actions={[AdviceTableModalActions.VIEW]}
      />
    ),
    [],
  )
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('animator.advice.table.headers.tags') as string,
        accessor: 'currentTag',
        disableSortBy: true,
        cellStyle: () => ({ minWidth: '110px' }),
      },
      {
        Header: t('animator.advice.table.headers.title') as string,
        accessor: 'title',
        disableSortBy: true,
        maxWidth: 200,
      },
      {
        Header: t('animator.advice.table.headers.content') as string,
        accessor: 'contentCroped',
        disableSortBy: true,
      },
      {
        Header: t(
          'animator.company.monitoring.table.headers.actions',
        ) as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [ActionCell, t],
  )
  const formattedData = useMemo(() => {
    if (!adviceData?.advicePosts?.length) return []

    return adviceData?.advicePosts?.map(advice => ({
      id: advice?.id,
      title: advice?.title,
      content: advice?.content,
      contentCroped: advice?.content.substr(0, 40),
      MIME: advice?.MIME,
      tags: advice?.tags,
      currentTag: advice?.tags[0]?.key
        ? t(`animator.advice.filter.tags.options.${advice?.tags[0]?.key}`)
        : null,
    }))
  }, [adviceData, t])

  return (
    <React.Fragment>
      <div css={{ minHeight: 380 }}>
        <Table loading={loading} columns={columns} data={formattedData} />
      </div>
      <div
        css={{
          position: 'relative',
          display: 'flex',
          marginTop: '41px',
          marginBottom: '31px',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            paddingLeft: 77,
            [theme.media.mobile]: {
              paddingLeft: 0,
            },
          }}
        ></div>
        <ViewModal
          advice={currModal.advice}
          isOpen={currModal.actionName === AdviceTableModalActions.VIEW}
          closeModal={() => setCurrModal(initalModalSettings)}
        />
      </div>
    </React.Fragment>
  )
}

export default AnimatorCompanyMonitoringList
