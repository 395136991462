/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'

import Card from '@goodwatt/client/src/components/Card'
import AdviceList from './AdviceList/List'
import AdviceTagList from './AdviceTagList/AdviceTagList'

import AdminTableRefetchContext from '../AdminTableRefetchContext'

const PAGESIZE = 7

const CompanyMonitoring: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goodwatt Admin - Conseil</title>
        <link rel="canonical" href="https://goodwatt.fr/animator/advices" />
      </Helmet>

      <AdminTableRefetchContext>
        <Card
          css={{ marginBottom: '30px' }}
          title={
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontWeight: 'bold',
                fontSize: '1.6rem',
                paddingBottom: '1rem',
                marginBottom: 24,
              }}
            >
              {t('animator.advice.dashboardTitleLabel')}
            </span>
          }
        >
          <AdviceList pageSize={PAGESIZE} />
        </Card>

        <Card
          css={{ marginBottom: '30px' }}
          title={
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontWeight: 'bold',
                fontSize: '1.6rem',
                paddingBottom: '1rem',
                marginBottom: 24,
              }}
            >
              {t('admin.advice.tagTitleLabel')}
            </span>
          }
        >
          <AdviceTagList pageSize={PAGESIZE} />
        </Card>
      </AdminTableRefetchContext>
    </div>
  )
}

export default CompanyMonitoring
