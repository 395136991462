/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNotifications } from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import { useArchiveDeployment } from '../hooks/useArchiveDeployment'
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../../../../components/ConfirmationModal'

export interface ArchiveDeploymentModalProps
  extends Pick<ConfirmationModalProps, 'isOpen' | 'onClose'> {
  id: string
  onCompleted?: () => void
}

export const ArchiveDeploymentModal: React.FC<ArchiveDeploymentModalProps> = ({
  id,
  onCompleted,
  ...rest
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [mutate, { loading }] = useArchiveDeployment({
    onCompleted,
  })

  const onSubmit = async () => {
    try {
      await mutate({
        variables: { data: { id } },
      })
      rest.onClose()
    } catch (e) {
      apiErrorCatcher(notifications)
    }
  }

  return (
    <ConfirmationModal
      title={t('animator.deployments.archiveDeploymentModal.title')}
      description={t('animator.deployments.archiveDeploymentModal.description')}
      loading={loading}
      onSubmit={onSubmit}
      {...rest}
    />
  )
}

export default ArchiveDeploymentModal
