import { ThemeType } from 'emotion-theming'
import { CSSProperties } from 'react'

export enum SelectVariant {
  gray = 'gray',
  white = 'white',
}

export type ElementsConfig = {
  labelColor: string
  labelFontWeight: CSSProperties['fontWeight']
  controlBckgColor: string
  controlBorder: string
  menuBckgColor: string
  menuBorder: string
  optionHoverBckgColor: string
}

export type SelectVariantsConfig = {
  [variant in SelectVariant]: ElementsConfig
}

export default (theme: ThemeType): SelectVariantsConfig => ({
  [SelectVariant.gray]: {
    labelColor: theme.colors.gray3,
    labelFontWeight: 'normal',
    controlBckgColor: theme.colors.gray6,
    controlBorder: `2px solid ${theme.colors.gray6}`,
    menuBckgColor: theme.colors.gray6,
    menuBorder: 'none',
    optionHoverBckgColor: theme.colors.gray5,
  },
  [SelectVariant.white]: {
    labelColor: theme.colors.gray2,
    labelFontWeight: 500,
    controlBckgColor: theme.colors.white,
    controlBorder: `1px solid ${theme.colors.gray4}`,
    menuBckgColor: theme.colors.white,
    menuBorder: `1px solid ${theme.colors.gray4}`,
    optionHoverBckgColor: theme.colors.gray6,
  },
})
