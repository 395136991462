/** @jsx jsx */
import { gql, useMutation } from '@apollo/client'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../../components/Button'

import Modal from '../../../../../../components/Modal'
import Popup from '../../../../../../components/Popup'
import { useNotifications } from '../../../../../../contexts/NotificationContext'
import i18n from '../../../../../../i18n/config'

import apiErrorCatcher from '../../../../../../utils/apiErrorCatcher'
import {
  DocumentTypes,
  DocumentsSetOptions,
  VerifyDocumentMutation,
  VerifyDocumentMutationVariables,
} from '../../../../../../__generated__/graphql'

const MUTATION_VERIFY_DOCUMENT = gql`
  mutation VerifyDocument(
    $employeeId: String!
    $employeeDeploymentId: String!
    $type: DocumentTypes
    $set: DocumentsSetOptions
  ) {
    verifyDocumentByCompanyOrAnimator(
      employeeId: $employeeId
      employeeDeploymentId: $employeeDeploymentId
      type: $type
      set: $set
    ) {
      id
      identityCardVerified
      proofOfAddressVerified
    }
  }
`

interface ModalConfirmationProps {
  isOpen: boolean
  closeModal: () => void
  type: DocumentTypes
  set: DocumentsSetOptions
  employeeId: string
  employeeDeploymentId: string | undefined
  refetchModal?: () => void
  refetchTable?: () => void
  name: string
}

const ModalConfirmation: React.FC<ModalConfirmationProps> = ({
  isOpen,
  closeModal,
  employeeId,
  employeeDeploymentId,
  set,
  type,
  refetchModal,
  refetchTable,
  name,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const notifications = useNotifications()
  const [verifyDocument, { loading }] = useMutation<
    VerifyDocumentMutation,
    VerifyDocumentMutationVariables
  >(MUTATION_VERIFY_DOCUMENT, {
    refetchQueries: ['GetDeploymentEmployees'],
  })

  const onSubmit = () => {
    verifyDocument({
      variables: {
        employeeId,
        employeeDeploymentId: employeeDeploymentId || '',
        set,
        type,
      },
    })
      .then(() => {
        if (refetchTable) refetchTable()
        if (refetchModal) refetchModal()
        closeModal()
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        closable={false}
        title={t('animator.company.employees.selected.documents.modal.title')}
        titleColor={
          set === DocumentsSetOptions.Verified
            ? theme.colors.success
            : theme.colors.redLegend
        }
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button
                disabled={loading}
                onClick={() => closeModal()}
                type={
                  set === DocumentsSetOptions.Verified
                    ? 'tertiarySuccess'
                    : 'tertiaryError'
                }
              >
                {i18n.t('shared.button.cancel')}
              </Button>
            </div>
            <Button
              submit
              type={
                set === DocumentsSetOptions.Verified
                  ? 'primarySuccess'
                  : 'primaryError'
              }
              loading={loading}
              onClick={onSubmit}
            >
              {i18n.t('shared.button.confirm')}
            </Button>
          </Fragment>
        }
      >
        <p css={{ textAlign: 'center', padding: '0 18px' }}>
          {type === DocumentTypes.IdentityCard &&
            (set === DocumentsSetOptions.Verified
              ? t(
                  'animator.company.employees.selected.documents.modal.verifiedIdentity',
                )
              : t(
                  'animator.company.employees.selected.documents.modal.rejectedIdentity',
                ))}
          {type === DocumentTypes.ProofOfAddress &&
            (set === DocumentsSetOptions.Verified
              ? t(
                  'animator.company.employees.selected.documents.modal.verifiedAddress',
                )
              : t(
                  'animator.company.employees.selected.documents.modal.rejectedAddress',
                ))}
          {type === DocumentTypes.Deposit &&
            (set === DocumentsSetOptions.Verified
              ? t(
                  'animator.company.employees.selected.documents.modal.verifiedDeposit',
                )
              : t(
                  'animator.company.employees.selected.documents.modal.rejectedDeposit',
                ))}
          <strong> {name}</strong> ?
        </p>
      </Popup>
    </Modal>
  )
}

export default ModalConfirmation
