/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'
import { regex } from '@goodwatt/shared'
import { useMutation } from '@apollo/client'

import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import {
  useNotifications,
  NotificationTypes,
} from '../../../contexts/NotificationContext'
import i18n from '../../../i18n/config'

import { MUTATION_UPDATE_ONE_ADMIN } from '../../../utils/gql/mutations'
import {
  GetAdminQuery,
  UpdateOneAdministratorMutation,
  UpdateOneAdministratorMutationVariables,
} from '../../../__generated__/graphql'

const schema = yup.object().shape({
  firstName: yup.string().required(i18n.t('forms.errors.required')),
  lastName: yup.string().required(i18n.t('forms.errors.required')),
  phoneNumber: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .matches(regex.phoneNumberRegex, i18n.t('forms.errors.phoneFormat')),
  address: yup.string().required(i18n.t('forms.errors.required')),
  postalCode: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .matches(regex.postalCodeRegex, i18n.t('forms.errors.wrongFormat')),
  city: yup.string().required(i18n.t('forms.errors.required')),
})

interface Props {
  data: GetAdminQuery | undefined
  userId: string
}

type Form = {
  firstName: string
  lastName: string
  phoneNumber: string
  address: string
  postalCode: string
  city: string
}

const EditProfileAnimator: React.FC<Props> = ({ data, userId }) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const { register, handleSubmit, errors } = useForm<Form>({
    resolver: yupResolver(schema),
  })
  const [updateOneAnimator, { loading }] = useMutation<
    UpdateOneAdministratorMutation,
    UpdateOneAdministratorMutationVariables
  >(MUTATION_UPDATE_ONE_ADMIN)

  const onSubmit = (data: Form) => {
    updateOneAnimator({
      variables: {
        where: { userId },
        data: {
          firstName: { set: data.firstName },
          lastName: { set: data.lastName },
          phoneNumber: { set: data.phoneNumber },
          address: { set: data.address },
          postalCode: { set: data.postalCode },
          city: { set: data.city },
        },
      },
    })
      .then(result => {
        if (result.data?.updateOneAdministrator?.id) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.notification.profileSuccessUpdate'),
          })
          // const { firstName, lastName } = result.data.updateOneEmployee

          // dispatch(updateUserName({ name: `${firstName} ${lastName}` }))
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <React.Fragment>
      <h2 css={{ margin: '18px 0' }}>{t('shared.profile.title')}</h2>
      <h3 css={{ margin: '14px 0' }}>{t('shared.profile.myInfo')}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="text"
          label={t('forms.label.email')}
          name="email"
          defaultValue={data?.administrator?.user.email}
          disabled
        />
        <Input
          type="text"
          label={t('forms.label.firstName')}
          name="firstName"
          defaultValue={data?.administrator?.firstName}
          register={register}
          error={!!errors.firstName}
          helperText={errors?.firstName?.message}
        />
        <Input
          type="text"
          label={t('forms.label.lastName')}
          name="lastName"
          defaultValue={data?.administrator?.lastName}
          register={register}
          error={!!errors.lastName}
          helperText={errors?.lastName?.message}
        />
        <Input
          type="text"
          label={t('forms.label.phoneNumber')}
          name="phoneNumber"
          defaultValue={data?.administrator?.phoneNumber}
          register={register}
          error={!!errors.phoneNumber}
          helperText={errors?.phoneNumber?.message}
        />
        <h3 css={{ margin: '14px 0' }}>{t('shared.profile.whereToFindMe')}</h3>
        <Input
          type="text"
          label={t('forms.label.street')}
          name="address"
          defaultValue={data?.administrator?.address || ''}
          register={register}
          error={!!errors.address}
          helperText={errors?.address?.message}
        />
        <div css={{ display: 'flex', flexDirection: 'row' }}>
          <div css={{ flex: 2, marginRight: 15 }}>
            <Input
              type="text"
              name="postalCode"
              label={t('forms.label.postalCode')}
              defaultValue={data?.administrator?.postalCode || ''}
              error={!!errors.postalCode}
              helperText={errors.postalCode?.message}
              onlyNumbers
              maxLength={5}
              register={register}
            />
          </div>
          <div css={{ flex: 3 }}>
            <Input
              type="text"
              name="city"
              label={t('forms.label.city')}
              defaultValue={data?.administrator?.city || ''}
              error={!!errors.city}
              helperText={errors.city?.message}
              register={register}
            />
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button submit loading={loading}>
            {t('forms.button.update')}
          </Button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default EditProfileAnimator
