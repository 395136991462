import { gql } from '@apollo/client'
import i18n from '../../i18n/config'
import { GetArchivedCompanyIdentityExportQuery } from '../../__generated__/graphql'

export const QUERY_ARCHIVED_COMPANY_IDENTITY_EXPORT = gql`
  query GetArchivedCompanyIdentityExport($companyId: String) {
    employees(where: { AND: [{ companyId: { equals: $companyId } }] }) {
      civility
      firstName
      lastName
      user {
        email
      }
    }
  }
`

export const formatArchivedCompanyIdentityExport = (
  data: GetArchivedCompanyIdentityExportQuery | undefined,
): any => {
  if (!data?.employees?.length) return []

  const header = ['Civilité', 'Prenom', 'Nom', 'E-mail']

  return [
    header,
    ...data?.employees.map(employee => ({
      civility: i18n.t(`shared.profile.civility.${employee.civility}`),
      firstName: employee.firstName,
      lastName: employee.lastName,
      email: employee.user.email,
    })),
  ]
}
