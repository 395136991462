/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { CSSProperties } from 'react'
import ReactSelect, {
  ValueType,
  OptionTypeBase,
  ActionMeta,
} from 'react-select'
import { ThemeType, useTheme } from 'emotion-theming'
import { SelectComponentsProps } from 'react-select/src/Select'
import { useTranslation } from 'react-i18next'

import Icon from './Icon'

import useVariant from '../hooks/useVariant'
import {
  SelectVariant,
  ElementsConfig,
  SelectVariantsConfig,
} from '../hooks/useVariant/variants/select'

const customStyles = (
  theme: ThemeType,
  selectVariantConfig: ElementsConfig,
) => ({
  control: (provided: CSSProperties, state: any): CSSProperties =>
    ({
      ...provided,
      boxSizing: 'border-box',
      height: '44px',
      minHeight: '0px',
      boxShadow: 'none',
      borderTopLeftRadius: '22px',
      borderTopRightRadius: '22px',
      borderBottomLeftRadius: state.menuIsOpen ? 0 : '22px',
      borderBottomRightRadius: state.menuIsOpen ? 0 : '22px',
      backgroundColor: selectVariantConfig.controlBckgColor,
      border:
        state.selectProps.error && !state.menuIsOpen
          ? `2px solid ${theme.colors.error}`
          : selectVariantConfig.controlBorder,
      '&:hover': {
        boxShadow: 'none',
      },
    } as CSSProperties),
  valueContainer: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    paddingLeft: '20px',
    fontSize: '1.6rem',
    lineHeight: '18px',
    fontFamily: 'Roboto',
  }),
  menu: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    border: selectVariantConfig.menuBorder,
    borderBottomLeftRadius: '22px',
    borderBottomRightRadius: '22px',
    marginTop: '-8px',
    boxShadow: 'none',
    backgroundColor: selectVariantConfig.menuBckgColor,
    overflow: 'hidden',
  }),
  indicatorsContainer: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    paddingRight: '15px',
  }),
  input: (): CSSProperties => ({
    color: theme.colors.gray1,
    fontSize: '1.4rem',
    lineHeight: '17px',
    fontFamily: 'Roboto',
  }),
  placeholder: (): CSSProperties => ({
    color: theme.colors.gray3,
    fontStyle: 'italic',
    fontWeight: 300,
    fontSize: '1.6rem',
    fontFamily: 'Roboto',
  }),
  option: (provided: CSSProperties): CSSProperties =>
    ({
      ...provided,
      color: theme.colors.gray1,
      fontSize: '1.4rem',
      lineHeight: '17px',
      fontFamily: 'Roboto',
      backgroundColor: 'none',
      borderTop: `1px solid ${theme.colors.white}`,
      marginTop: '-1px',
      '&:hover': { backgroundColor: selectVariantConfig.optionHoverBckgColor },
      '&:active': { backgroundColor: theme.colors.border },
    } as CSSProperties),
})

const CustomDropdownIndicator = (): JSX.Element => {
  const theme = useTheme()

  return (
    <div css={{ marginTop: 0 }}>
      <Icon color={theme.colors.gray3} type="fullArrowDown" size="small" />
    </div>
  )
}

interface InputSelectType<T> {
  options: { value: T; label: string }[]
  variant?: SelectVariant
  placeholder?: string
  onChange?: (
    value: ValueType<OptionTypeBase>,
    action: ActionMeta<OptionTypeBase>,
  ) => void
  defaultValue?: T
  label?: string
  block?: boolean
  helperText?: string
  error?: boolean
  withError?: boolean
  noOptionMessage?: string
  isSearchable?: boolean
}

const InputSelect = <T extends unknown>({
  options,
  variant = SelectVariant.gray,
  placeholder,
  defaultValue,
  label,
  helperText,
  onChange,
  block = true,
  error = false,
  withError = true,
  isSearchable = true,
  noOptionMessage,
  ...props
}: InputSelectType<T> & SelectComponentsProps): JSX.Element => {
  const { t } = useTranslation()
  const theme = useTheme()
  const selectVariantConfig = useVariant('select')[
    variant
  ] as SelectVariantsConfig[typeof variant]
  const noOptionLabel = (): string =>
    noOptionMessage || t('shared.placeholder.noOptions')

  return (
    <div
      css={{
        width: block ? '100%' : 'fit-content',
      }}
    >
      {label && (
        <div
          css={{
            marginLeft: 24,
            marginBottom: 4,
          }}
        >
          <label
            css={{
              fontFamily: 'Roboto',
              fontSize: '1.6rem',
              fontWeight: selectVariantConfig.labelFontWeight,
              color: selectVariantConfig.labelColor,
            }}
          >
            {label}
          </label>
        </div>
      )}
      <ReactSelect
        placeholder={placeholder}
        defaultValue={options.find(o => o.value === defaultValue)}
        isSearchable={isSearchable}
        options={options}
        styles={customStyles(theme, selectVariantConfig)}
        onChange={onChange}
        components={{
          IndicatorSeparator: (): null => null,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        error={error}
        noOptionsMessage={noOptionLabel}
        {...props}
      />
      {withError && (
        <div
          css={{
            marginLeft: 24,
            height: 20,
          }}
        >
          <span
            css={{
              fontFamily: 'Roboto',
              fontSize: '1.2rem',
              color: error ? theme.colors.error : theme.colors.gray3,
            }}
          >
            {helperText}
          </span>
        </div>
      )}
    </div>
  )
}

export default InputSelect
