import { useQuery, NetworkStatus } from '@apollo/client'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import ProfilePreview from './Preview'
import EditEmployeeModal, {
  AnchorEditEmployeeProfileType,
} from '../../Shared/EditEmployeeModal'

import { StoreState } from '../../../redux/rootReducer'
import { QUERY_GET_EMPLOYEE } from '../../../utils/gql/queries'

import EditEmployeeHabitsModal, {
  AnchorEditHabitType as AnchorEditEmployeeHabitType,
  AnchorEditHabitType,
} from '../../Shared/EditEmployeeHabitsModal'
import Loader from '../../../components/Loader'
import {
  GetEmployeeQuery,
  GetEmployeeQueryVariables,
} from '../../../__generated__/graphql'

type AnchorEditModalType<A> = {
  isOpen: boolean
  anchor: A | undefined
}

const initialModalVisibilityState = { isOpen: false, anchor: undefined }

const EmployeeProfile: React.FC = () => {
  const userId = useSelector((state: StoreState) => state.user.id)
  const [profileModalVisibility, setProfileModalVisibility] = React.useState<
    AnchorEditModalType<AnchorEditEmployeeProfileType>
  >(initialModalVisibilityState)
  const [habitModalVisibility, setHabitModalVisibility] = React.useState<
    AnchorEditModalType<AnchorEditEmployeeHabitType>
  >(initialModalVisibilityState)

  const {
    data,
    loading: loadingQuery,
    refetch,
    networkStatus,
  } = useQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(
    QUERY_GET_EMPLOYEE,
    {
      nextFetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        where: {
          userId,
        },
      },
    },
  )

  const didMountRef = useRef(false)

  const closeModalEditEmployee = () => {
    setProfileModalVisibility(initialModalVisibilityState)
  }

  const closeModalEditEmployeeHabits = () => {
    setHabitModalVisibility(initialModalVisibilityState)
  }

  const openNeededModal = () => {
    if (!data) return
    const employee = data.employee

    if (!employee?.firstName || !employee?.lastName) {
      return setProfileModalVisibility({
        isOpen: true,
        anchor: AnchorEditEmployeeProfileType.USER_INFO,
      })
    } else if (
      !employee?.birthdate ||
      !employee?.cityOfBirth ||
      !employee?.height
    ) {
      return setProfileModalVisibility({
        isOpen: true,
        anchor: AnchorEditEmployeeProfileType.USER_BIRTH,
      })
    } else if (!data?.employee?.user.email || !data?.employee?.phoneNumber) {
      return setProfileModalVisibility({
        isOpen: true,
        anchor: AnchorEditEmployeeProfileType.USER_CONTACT,
      })
    } else if (!employee?.street || !employee?.city || !employee?.postalCode) {
      return setProfileModalVisibility({
        isOpen: true,
        anchor: AnchorEditEmployeeProfileType.USER_ADDRESS,
      })
    } else if (
      !employee?.travelMode?.name ||
      !employee?.workTravelDistance ||
      !employee?.workTravelDuration
    ) {
      return setHabitModalVisibility({
        isOpen: true,
        anchor: AnchorEditHabitType.USER_HABIT_WORK_TRAVEL,
      })
    } else if (
      !employee?.employeeBikeHabit?.name ||
      !employee?.bikeParkingType?.name
    ) {
      return setHabitModalVisibility({
        isOpen: true,
        anchor: AnchorEditHabitType.USER_HABIT_BIKE_USAGE,
      })
    }
  }

  const handleEditEmployeeClose = () => {
    closeModalEditEmployee()
  }

  const handleEditEmployeeHabitsClose = () => {
    closeModalEditEmployeeHabits()
  }

  useEffect(() => {
    if (networkStatus !== NetworkStatus.ready) {
      if (didMountRef.current) didMountRef.current = false
    }

    if (networkStatus === NetworkStatus.ready) {
      if (data && !didMountRef.current) {
        openNeededModal()
        didMountRef.current = true
      }
    }
  })

  if (loadingQuery && !data) return <Loader />
  return (
    <>
      <ProfilePreview
        data={data as GetEmployeeQuery}
        openProfileModal={(anchor: AnchorEditEmployeeProfileType) =>
          setProfileModalVisibility({ isOpen: true, anchor })
        }
        openHabitsModal={(anchor: AnchorEditEmployeeHabitType) =>
          setHabitModalVisibility({ isOpen: true, anchor })
        }
      />
      <EditEmployeeModal
        employee={data?.employee || null}
        isOpen={profileModalVisibility.isOpen}
        anchorOnOpen={profileModalVisibility.anchor}
        onClose={closeModalEditEmployee}
        submitCloseModal={handleEditEmployeeClose}
        refetch={() => refetch()}
        ownEmployeeInfo
      />
      <EditEmployeeHabitsModal
        employee={data?.employee || null}
        isOpen={habitModalVisibility.isOpen}
        anchorOnOpen={habitModalVisibility.anchor}
        onClose={closeModalEditEmployeeHabits}
        submitCloseModal={handleEditEmployeeHabitsClose}
        refetch={refetch}
        ownEmployeeInfo
      />
    </>
  )
}

export default EmployeeProfile
