/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useCallback } from 'react'
import Icon from './Icon'
import { useTheme } from 'emotion-theming'

interface CheckboxProps {
  id?: string
  isChecked: boolean
  onClick?: ((id: string) => void) | undefined
  size?: 'small' | 'medium' | 'large'
  label?: string
  error?: boolean
  color?: string
}

const Checkbox: React.FC<CheckboxProps> = ({
  id = '',
  size = 'small',
  label = '',
  error = false,
  ...props
}) => {
  const theme = useTheme()
  const squareSize = {
    small: 22,
    medium: 26,
    large: 44,
  }

  const color = props.color ?? theme.colors.primary

  const handleOnClick = useCallback(
    e => {
      e.preventDefault()
      props.onClick && props.onClick(id)
    },
    [id, props],
  )

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={handleOnClick}
    >
      <button type="button">
        <div
          css={{
            cursor: 'pointer',
            minWidth: squareSize[size],
            width: squareSize[size],
            height: squareSize[size],
            backgroundColor: 'transparent',
            border: `${props.isChecked ? 0 : 2}px solid ${
              error ? theme.colors.error : color
            }`,
            boxSizing: 'border-box',
            borderRadius: squareSize[size] / 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.isChecked && (
            <Icon
              type="checkbox"
              customSize={{
                width: squareSize[size],
                height: squareSize[size],
              }}
              color={color}
            />
          )}
        </div>
      </button>

      {label && (
        <span
          css={{
            marginLeft: '1.2rem',
            fontSize: '1.4rem',
            color: error ? theme.colors.error : theme.colors.gray2,
          }}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      )}
    </div>
  )
}

export default Checkbox
