/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Link, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import AuthContainer from '../components/AuthContainer'
import AuthenticationCard from '../components/Card/AuthenticationCard'
import { useTheme } from 'emotion-theming'
import Button from '../components/Button'
import { VerifyEmailMutation } from '../__generated__/graphql'

const MUTATION_VERIFY_EMAIL = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`

const VerifyEmail: React.FC = () => {
  const { token } = useParams<{ token: string }>()
  const [verifyEmail, { loading, data, error }] =
    useMutation<VerifyEmailMutation>(MUTATION_VERIFY_EMAIL)
  const { t } = useTranslation()
  const theme = useTheme()

  useEffect(() => {
    // eslint-disable-next-line no-console
    verifyEmail({ variables: { token } }).catch(e => console.log(e))
  }, [token, verifyEmail])

  return (
    <AuthContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('auth.verifyEmail.metaTitle')}</title>
        <link rel="canonical" href="https://goodwatt.fr/verify-email" />
      </Helmet>
      {!loading && (
        <AuthenticationCard
          title={
            error
              ? t('auth.verifyEmail.errorTitle')
              : data
              ? t('auth.verifyEmail.successTitle')
              : ''
          }
        >
          <div
            css={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span
              css={{
                fontFamily: 'Roboto',
                fontSize: '1.6rem',
                color: theme.colors.gray1,
                textAlign: 'center',
              }}
            >
              {error
                ? t('auth.verifyEmail.errorDescription')
                : data
                ? t('auth.verifyEmail.successDescription')
                : ''}
            </span>
            {data && (
              <Link to="/" css={{ marginTop: '40px' }}>
                <Button>{t('auth.verifyEmail.button')}</Button>
              </Link>
            )}
          </div>
        </AuthenticationCard>
      )}
    </AuthContainer>
  )
}

export default VerifyEmail
