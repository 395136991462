/** @jsx jsx */
import { gql, useMutation } from '@apollo/client'
import { jsx } from '@emotion/core'
import {
  BikeComponentPartType,
  PackageAccessoryType,
} from '@goodwatt/shared/dist/types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import {
  Employee,
  EmployeeDeployment,
  FinishBikeLoanMutation,
  FinishBikeLoanMutationVariables,
  VerifyReturnBikeFromEmployeeMutation,
  VerifyReturnBikeFromEmployeeMutationVariables,
} from '../../../../__generated__/graphql'
import { StoreState } from '../../../../redux/rootReducer'
import { resetReturnBikeForm, setReturnBikeForm } from '../../../../redux/forms'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import i18n from 'i18next'
import Modal from '../../../../components/Modal'
import StepPopup from '../../../../components/Popup/StepPopup'
import BikeSelection from '../../Company/Employees/SelectedEmployees/ReturnBikeModal/BikeSelection'
import BikeComponentsCondition from '../../Company/Employees/SelectedEmployees/Shared/BikeComponentsCondition'
import ReturnBikeAnimatorValidation from '../../Company/Employees/SelectedEmployees/ReturnBikeModal/AnimatorValidation'

const MUTATION_VERIFY_RETURN_BIKE_FROM_EMPLOYEE = gql`
  mutation VerifyReturnBikeFromEmployee(
    $stickerId: String!
    $employeeId: String!
  ) {
    verifyReturnBikeFromEmployee(
      stickerId: $stickerId
      employeeId: $employeeId
    ) {
      id
      bike {
        id
        stickerId
        bikeComponents {
          id
          bikeComponentPart {
            name
          }
          note
          stateOfUse {
            name
          }
          bikeLoanAttachments {
            id
            title
            size
          }
        }
      }
      loanPackages {
        id
        note
        packageAccessory {
          name
        }
        stateOfUse {
          name
        }
        bikeLoanAttachments {
          id
          title
          size
        }
      }
    }
  }
`

const MUTATION_FINISH_BIKE_LOAN = gql`
  mutation FinishBikeLoan(
    $bikeId: String!
    $employeeId: String!
    $bikeComponents: [BikeComponentInput!]!
  ) {
    finishBikeLoan(
      bikeId: $bikeId
      employeeId: $employeeId
      bikeComponents: $bikeComponents
    )
  }
`

export interface ReturnBikeModalProps {
  isOpen: boolean
  onClose: () => void
  employeeDeployment: Pick<EmployeeDeployment, 'id'> & {
    employee: Pick<Employee, 'id' | 'firstName' | 'lastName'>
  }
}

export const ReturnBikeModal: React.FC<ReturnBikeModalProps> = ({
  isOpen,
  onClose,
  employeeDeployment,
}) => {
  const dispatch = useDispatch()
  const [returnProcessStep, setReturnProcessStep] = React.useState<number>(0)
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(true)
  const { returnBikeForm } = useSelector((state: StoreState) => state.forms)
  const notifications = useNotifications()

  const [checkReturnBike, { loading: loadingCheckReturn }] = useMutation<
    VerifyReturnBikeFromEmployeeMutation,
    VerifyReturnBikeFromEmployeeMutationVariables
  >(MUTATION_VERIFY_RETURN_BIKE_FROM_EMPLOYEE)
  const [finishBikeLoan, { loading: loadingFinishBikeLoan }] = useMutation<
    FinishBikeLoanMutation,
    FinishBikeLoanMutationVariables
  >(MUTATION_FINISH_BIKE_LOAN, { refetchQueries: ['GetDeploymentEmployees'] })

  const loading = loadingCheckReturn && loadingFinishBikeLoan

  React.useEffect(() => {
    let currNextDisabled = false
    if (returnProcessStep === 0) {
      currNextDisabled =
        returnBikeForm.stickerId === 0 || isNaN(returnBikeForm.stickerId)
    }

    if (returnProcessStep === 2) {
      currNextDisabled = !returnBikeForm.animatorValidation
    }

    setIsNextDisabled(currNextDisabled)
  }, [returnProcessStep, returnBikeForm])

  const canGoNext = async (): Promise<boolean | void> => {
    // Handle step 0 onNext
    if (!loading && returnProcessStep === 0) {
      return checkReturnBike({
        variables: {
          stickerId: `${returnBikeForm.stickerId}`,
          // TODO use employeeDeployment instead with a new mutation
          employeeId: employeeDeployment.employee.id,
        },
      })
        .then(result => {
          if (result.data?.verifyReturnBikeFromEmployee) {
            const loanAccessories =
              result.data.verifyReturnBikeFromEmployee.loanPackages.reduce<
                StoreState['forms']['returnBikeForm']['accessories']
              >(
                (acc, accessory) => ({
                  ...acc,
                  [accessory.packageAccessory.name as PackageAccessoryType]: {
                    id: accessory.id,
                    missing: false,
                    stateOfUse: accessory.stateOfUse.name,
                    note: accessory.note,
                    attachments: accessory.bikeLoanAttachments,
                  },
                }),
                {},
              )
            const bikeComponents =
              result.data.verifyReturnBikeFromEmployee.bike?.bikeComponents.reduce<
                StoreState['forms']['returnBikeForm']['components']
              >(
                (acc, component) => ({
                  ...acc,
                  [component.bikeComponentPart.name as BikeComponentPartType]: {
                    touched:
                      returnBikeForm.components[
                        component.bikeComponentPart
                          .name as BikeComponentPartType
                      ].touched,
                    id: component.id,
                    stateOfUse: component.stateOfUse.name,
                    note: component.note,
                    attachments: component.bikeLoanAttachments,
                  },
                }),
                returnBikeForm.components,
              )
            dispatch(
              setReturnBikeForm({
                bikeId: result.data.verifyReturnBikeFromEmployee.bike?.id,
                stickerId:
                  result.data.verifyReturnBikeFromEmployee.bike?.stickerId,
                bikeLoanId: result.data.verifyReturnBikeFromEmployee.id,
                accessories: loanAccessories,
                components: bikeComponents,
              }),
            )
            return true
          }
          return false
        })
        .catch(apiErrorCatcher(notifications))
    } else if (returnProcessStep === 1) {
      return true
    } else if (returnProcessStep === 2) {
      if (!returnBikeForm.animatorValidation) {
        return
      }

      const bikeComponents = Object.entries(returnBikeForm.components).map(
        ([componentName, value]) => ({
          id: value.id || '',
          name: componentName,
          stateOfUse: value.stateOfUse || '',
          note: value.note,
        }),
      )

      return finishBikeLoan({
        variables: {
          bikeId: returnBikeForm.bikeId,
          // TODO use employeeDeployment instead with a new mutation
          employeeId: employeeDeployment.employee.id,
          bikeComponents,
        },
      })
        .then(() => {
          dispatch(resetReturnBikeForm())
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: i18n.t(
              'animator.company.employees.selected.modals.employeeReturnBikeModal.successReturnNotification',
            ),
          })
          setReturnProcessStep(0)
        })
        .then(() => true)
        .catch(apiErrorCatcher(notifications))
    }
  }

  const changeStep = (direction: 1 | -1) => {
    setReturnProcessStep(prevState => prevState + direction)
  }

  const steps = React.useMemo(
    () => [
      {
        height: 210,
        mobileHeight: 230,
        title: i18n.t(
          'animator.company.employees.selected.modals.employeeReturnBikeModal.bikeSelection.title',
        ),
        isFirst: true,
        isLast: false,
      },
      {
        height: 420,
        mobileHeight: 385,
        title: i18n.t(
          'animator.company.employees.selected.modals.employeeReturnBikeModal.bikeComponentsCondition.title',
          { stickerId: returnBikeForm.stickerId },
        ),
        isFirst: false,
        isLast: false,
      },
      {
        height: 250,
        mobileHeight: 280,
        title: i18n.t(
          'animator.company.employees.selected.modals.employeeReturnBikeModal.animatorReturnValidation.title',
        ),
        isFirst: false,
        isLast: true,
      },
    ],
    [returnBikeForm.stickerId],
  )

  return (
    <Modal isOpen={isOpen}>
      <StepPopup
        processStep={steps[returnProcessStep]}
        processStepNbr={returnProcessStep}
        changeStep={changeStep}
        canGoNext={canGoNext}
        disableNext={isNextDisabled}
        loading={loading}
        closeModal={onClose}
      >
        {returnProcessStep === 0 && (
          <BikeSelection employee={employeeDeployment.employee} />
        )}
        {returnProcessStep === 1 && (
          <BikeComponentsCondition
            bikeId={returnBikeForm.bikeId}
            returningBike
            setFormAction={setReturnBikeForm}
          />
        )}
        {returnProcessStep === 2 && (
          <ReturnBikeAnimatorValidation
            employee={employeeDeployment.employee}
          />
        )}
      </StepPopup>
    </Modal>
  )
}
