/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import queryString from 'query-string'

import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Input from '../../components/Input'
import Button from '../../components/Button'
import { StoreState } from '../../redux/rootReducer'
import { setEmployeeSignUpForm } from '../../redux/forms'
import i18n from '../../i18n/config'

import apiErrorCatcher from '../../utils/apiErrorCatcher'
import { useNotifications } from '../../contexts/NotificationContext'
import { linkCss } from '../../styles/globalStyles'
import { useTheme } from 'emotion-theming'
import { CheckCompanySubscriptionCodeMutation } from '../../__generated__/graphql'

const MUTATION_CHECK_SUBSCRIPTION_CODE = gql`
  mutation CheckCompanySubscriptionCode($code: Int!) {
    verifyCompanySubscriptionCode(code: $code)
  }
`

const schema = yup.object().shape({
  subscriptionCode: yup.string().required(i18n.t('forms.errors.required')),
})

type Step1EmployeeInput = {
  subscriptionCode: string
}

interface Step1Props {
  onNext: () => void
}

const Step1: React.FC<Step1Props> = ({ onNext }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const notifications = useNotifications()
  const theme = useTheme()
  const form = useSelector(
    (state: StoreState) => state.forms.employeeSignUpForm,
  )
  const [checkSubscriptionCode, { loading }] =
    useMutation<CheckCompanySubscriptionCodeMutation>(
      MUTATION_CHECK_SUBSCRIPTION_CODE,
    )

  const { register, handleSubmit, errors } = useForm<Step1EmployeeInput>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const onSubmit = (data: Step1EmployeeInput) => {
    dispatch(
      setEmployeeSignUpForm({
        subscriptionCode: data.subscriptionCode,
      }),
    )
    checkSubscriptionCode({
      variables: { code: parseInt(data.subscriptionCode) },
    })
      .then(result => {
        if (result.data?.verifyCompanySubscriptionCode) onNext()
      })
      .catch(apiErrorCatcher(notifications))
  }

  //If subscriptionCode in Url Query String, we skip stepOne
  useEffect(() => {
    // eslint-disable-next-line
    const params = queryString.parse(location.search)
    if (params && params.subscriptionCode) {
      const data: Step1EmployeeInput = {
        subscriptionCode: params.subscriptionCode.toString(),
      }
      // eslint-disable-next-line
      onSubmit(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div
        css={{
          marginBottom: '40px',
        }}
      >
        <p
          css={{
            fontSize: '1.6rem',
            textAlign: 'center',
            color: theme.colors.gray1,
          }}
          dangerouslySetInnerHTML={{
            __html: t('auth.signupEmployee.description'),
          }}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="text"
          label={t('forms.label.subscriptionCode')}
          name="subscriptionCode"
          placeholder={t('forms.label.subscriptionCodePlaceholder')}
          defaultValue={form.subscriptionCode}
          error={!!errors.subscriptionCode}
          helperText={errors.subscriptionCode?.message}
          onlyNumbers
          register={register}
        />
        <div
          css={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <Button loading={loading} type="primary" submit>
            {t('auth.signupEmployee.button1')}
          </Button>
        </div>
      </form>
      <div
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          css={{
            ...linkCss,
            marginTop: '40px',
            textDecoration: 'none',
            textAlign: 'center',
          }}
        >
          {t('auth.signupEmployee.isCompany')}
          <a href="/signup-company" css={{ ...linkCss }}>
            {t('auth.signupEmployee.signUpHere')}
          </a>
        </p>
        <p
          css={{
            ...linkCss,
            lineHeight: '1.8rem',
            marginTop: '10px',
            textDecoration: 'none',
            textAlign: 'center',
          }}
        >
          {t('auth.signupEmployee.missingCode')}
        </p>
      </div>
    </div>
  )
}

export default Step1
