import {
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client'
import {
  UpdateEmployeeDeploymentMutation,
  UpdateEmployeeDeploymentMutationVariables,
} from '../../../../__generated__/graphql'

export const MUTATION_UPDATE_EMPLOYEE_DEPLOYMENT = gql`
  mutation UpdateEmployeeDeployment(
    $id: String!
    $data: EmployeeDeploymentUpdateInput!
  ) {
    updateEmployeeDeployment(data: $data, id: $id) {
      id
    }
  }
`

export const useUpdateEmployeeDeployment = (
  options?: MutationHookOptions<
    UpdateEmployeeDeploymentMutation,
    UpdateEmployeeDeploymentMutationVariables
  >,
): MutationTuple<
  UpdateEmployeeDeploymentMutation,
  UpdateEmployeeDeploymentMutationVariables
> => {
  return useMutation(MUTATION_UPDATE_EMPLOYEE_DEPLOYMENT, {
    refetchQueries: ['GetDeploymentEmployees'],
    ...options,
  })
}
