/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, gql } from '@apollo/client'

import SummaryCard, { SummaryItem } from '../../../components/Card/Summary'

import { ProgramStepType } from '@goodwatt/shared/dist/types'
import {
  GetCompaniesCountsDashboardQuery,
  GetCompaniesCountsDashboardQueryVariables,
} from '../../../__generated__/graphql'

export const QUERY_COMPANIES_COUNT_FILTERED = gql`
  query GetCompaniesCountsDashboard(
    $inActivityFilter: CompanyWhereInput
    $pendingFilter: CompanyWhereInput
    $employeesFilter: EmployeeWhereInput
    $employeesWithLoanFilter: EmployeeWhereInput
  ) {
    companiesInActivity: companyCount(where: $inActivityFilter)
    companiesPending: companyCount(where: $pendingFilter)
    companyEmployeesCount(where: $employeesFilter)
    companyEmployeesWithLoanCount: companyEmployeesCount(
      where: $employeesWithLoanFilter
    )
  }
`

interface DashbardMyCompanies {
  animatorUserId: string
  fleetIdFilter: string | null
}

const DashboardMyCompanies: React.FC<DashbardMyCompanies> = ({
  animatorUserId,
  fleetIdFilter,
}) => {
  const { t } = useTranslation()
  const fleetFilter =
    fleetIdFilter !== null
      ? {
          fleet: {
            id: {
              equals: fleetIdFilter,
            },
          },
        }
      : {}

  const { loading, data: companyData } = useQuery<
    GetCompaniesCountsDashboardQuery,
    GetCompaniesCountsDashboardQueryVariables
  >(QUERY_COMPANIES_COUNT_FILTERED, {
    variables: {
      inActivityFilter: {
        AND: [
          {
            area: {
              animator: {
                some: {
                  userId: { equals: animatorUserId },
                },
              },
            },
          },
          {
            programStep: {
              stepNbr: {
                gte: ProgramStepType.CONTRACTUALIZATION,
                lte: ProgramStepType.PROGRAM_REVIEW,
              },
            },
          },
          fleetFilter,
        ],
      },
      pendingFilter: {
        AND: [
          {
            area: {
              animator: {
                some: {
                  userId: { equals: animatorUserId },
                },
              },
            },
          },
          { programStep: { stepNbr: { equals: ProgramStepType.PENDING } } },
          fleetFilter,
        ],
      },
      employeesFilter: {
        AND: [
          {
            company: {
              area: {
                animator: {
                  some: {
                    userId: { equals: animatorUserId },
                  },
                },
              },
            },
          },
          {
            company: fleetFilter,
          },
        ],
      },
      employeesWithLoanFilter: {
        AND: [
          {
            company: {
              area: {
                animator: {
                  some: {
                    userId: { equals: animatorUserId },
                  },
                },
              },
            },
          },
          {
            company: fleetFilter,
          },
          {
            bikeLoan: { some: { bikeId: { not: { equals: null } } } },
          },
        ],
      },
    },
  })

  const myCompaniesData: SummaryItem[] = React.useMemo(
    () => [
      {
        id: 'companyInActivity',
        icon: 'building',
        label: t('shared.resources.companyCount', {
          count: companyData?.companiesInActivity ?? 0,
        }),
        description: t('animator.dashboard.myCompaniesCard.inActivity'),
      },
      {
        id: 'companyPending',
        icon: 'building',
        label: t('shared.resources.companyCount', {
          count: companyData?.companiesPending ?? 0,
        }),
        description: t('animator.dashboard.myCompaniesCard.pending'),
      },
      {
        id: 'subscribedEmployees',
        icon: 'people',
        label: t('shared.resources.employeeCount', {
          count: companyData?.companyEmployeesCount ?? 0,
        }),
        description: t('animator.dashboard.myCompaniesCard.employeesSub'),
      },
      {
        id: 'subscribedEmployeesWithLoan',
        icon: 'people',
        label: t('shared.resources.employeeCount', {
          count: companyData?.companyEmployeesWithLoanCount ?? 0,
        }),
        description: t(
          'animator.dashboard.myCompaniesCard.employeesSubWithLoan',
        ),
      },
    ],
    [companyData, t],
  )

  return (
    <SummaryCard
      loading={loading}
      title={t('animator.dashboard.myCompaniesCard.title')}
      linkTo="/animator/companies"
      itemList={myCompaniesData}
    />
  )
}

export default DashboardMyCompanies
