/** @jsx jsx */
import React, { useMemo, useState } from 'react'
import { jsx } from '@emotion/core'
import { useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '@goodwatt/client/src/components/Table'
import Pagination from '@goodwatt/client/src/components/Pagination'

import Button from '../../../../components/Button'
import theme from '../../../../styles/theme'
import Icon from '../../../../components/Icon'
import { employeeNameFormatter } from '../../../../utils/formatter'

import {
  QUERY_ANIMATORS,
  QUERY_ANIMATOR_COUNT,
} from '../../../../utils/gql/queries'

import DownloadCSVButton from '../../../../components/Button/DownloadCSVButton'
import { formatAnimatorExport } from '../../../../utils/gql/animatorExport'
import usePromiseLazyQuery from '../../../../hooks/usePromiseLazyQuery'

import AnimateurDeleteModal from './AnimateurDeleteModal'
import {
  AnimatorModalSettings,
  AnimatorTableModal,
} from '../../../../types/admin/AdminAnimator'
import AnimatorActionCell from '../../../../components/Table/CustomCells/AnimatorActionCell'

import EditAnimatorModal from '@goodwatt/client/src/pages/Shared/EditAnimatorModal'
import {
  AnimatorCountFilteredQuery,
  AnimatorCountFilteredQueryVariables,
  GetAnimatorsQuery,
  GetAnimatorsQueryVariables,
} from '../../../../__generated__/graphql'

const initalModalSettings: AnimatorModalSettings = {
  animateur: null,
  actionName: AnimatorTableModal.NONE,
}
const AnimatorBikeTableList: React.FC<{ pageSize: number }> = ({
  pageSize,
}) => {
  const { t } = useTranslation()

  const [currModal, setCurrModal] =
    useState<AnimatorModalSettings>(initalModalSettings)
  const [currPage, setCurrPage] = useState(1)
  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)

  const { data: countData, refetch: refetchBikesCount } = useQuery<
    AnimatorCountFilteredQuery,
    AnimatorCountFilteredQueryVariables
  >(QUERY_ANIMATOR_COUNT)
  const {
    data: bikesData,
    loading,
    refetch: refetchBikesData,
  } = useQuery<GetAnimatorsQuery, GetAnimatorsQueryVariables>(QUERY_ANIMATORS, {
    fetchPolicy: 'network-only',
    variables: {
      skip: (currPage - 1) * pageSize,
      orderBy:
        orderBy.sort && orderBy.columnName
          ? [{ [orderBy.columnName]: orderBy.sort }]
          : undefined,
      take: pageSize,
    },
  })

  const getAllAnimator = usePromiseLazyQuery<
    GetAnimatorsQuery,
    GetAnimatorsQueryVariables
  >(QUERY_ANIMATORS)

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<GetAnimatorsQuery['animators'][0]> }) => (
      <AnimatorActionCell setModal={setCurrModal} row={row} />
    ),
    [],
  )
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('admin.animator.table.headers.name') as string,
        accessor: 'name',
        sortField: 'firstName',
      },
      {
        Header: t('admin.animator.table.headers.zone') as string,
        accessor: 'zone',
        sortField: 'areaId',
      },
      {
        Header: t('admin.animator.table.headers.email') as string,
        accessor: 'email',
        disableSortBy: true,
      },
      {
        Header: t('admin.animator.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [t, ActionCell],
  )

  const formattedData = useMemo(() => {
    if (!bikesData?.animators?.length) return []

    return bikesData.animators.map(animator => {
      return {
        ...animator,
        id: animator?.id,
        name: employeeNameFormatter(animator.firstName, animator.lastName),
        zone: animator.area?.name,
        email: animator.user?.email,
      }
    })
  }, [bikesData])

  const handleEditModalClose = (shouldRefetch = false) => {
    setCurrModal(initalModalSettings)
    if (shouldRefetch) {
      refetchBikesCount()
      refetchBikesData()
    }
  }

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `animateurs-${formatedDate}`
  const getExportData = async () => {
    const { data } = await getAllAnimator({})
    return formatAnimatorExport(data)
  }

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={formattedData}
        onSortBy={setOrderBy}
        loading={loading}
      />
      <div
        css={{
          marginTop: '41px',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={countData?.animatorCount}
        />
        <div
          css={{
            display: 'flex',
            justifySelf: 'flex-end',
            position: 'absolute',
            right: 0,
          }}
        >
          <Button
            submit
            onClick={() =>
              setCurrModal({
                animateur: null,
                actionName: AnimatorTableModal.ADD,
              })
            }
          >
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontSize: '1.6rem',
                fontWeight: 500,
                color: theme.colors.white,
              }}
            >
              <Icon
                strokeColor="white"
                type="plus"
                customSize={{ width: '16px', height: '16px' }}
              />
            </span>
          </Button>
          <DownloadCSVButton getData={getExportData} filename={filename} />
        </div>
      </div>
      <EditAnimatorModal
        modalIsOpen={[AnimatorTableModal.EDIT, AnimatorTableModal.ADD].includes(
          currModal.actionName,
        )}
        type={currModal.actionName}
        refetch={refetchBikesData}
        animator={currModal.animateur}
        submitCloseModal={handleEditModalClose}
        closeModal={handleEditModalClose}
      />
      <AnimateurDeleteModal
        animateur={currModal.animateur}
        modalIsOpen={currModal.actionName === AnimatorTableModal.DELETE}
        closeModal={handleEditModalClose}
        refetch={refetchBikesData}
      />
    </React.Fragment>
  )
}

export default AnimatorBikeTableList
