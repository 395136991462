/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../../components/Button'
import Modal from '../../../../../../../components/Modal'
import Popup from '../../../../../../../components/Popup'
import TextArea from '../../../../../../../components/TextArea'
import { StoreState } from '../../../../../../../redux/rootReducer'

import { PartsConditionTableRow } from '../../../../../../../types/AnimatorCompanyEmployees'
import Attachments from './Attachments'

interface PartModalProps {
  isOpen: boolean
  title: string
  label: string
  bikeLoanId?: string
  part: PartsConditionTableRow
  setFormAction: (
    payload: Partial<
      | StoreState['forms']['loanBikeForm']
      | StoreState['forms']['returnBikeForm']
    >,
  ) => void
  shouldOpenCameraInput: boolean
  componentType: 'bikeComponent' | 'loanPackage'
  onSubmit: (noteValue: string, partName: string) => void
  closeModal: () => void
  closeAutomaticCameraInput?: () => void
}

const PartModal: React.FC<PartModalProps> = ({
  isOpen,
  closeModal,
  title,
  label,
  componentType,
  bikeLoanId,
  part,
  setFormAction,
  shouldOpenCameraInput,
  closeAutomaticCameraInput,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [textAreaValue, setTextAreaValue] = React.useState<string>('')

  React.useEffect(() => {
    if (part.note !== textAreaValue) {
      setTextAreaValue(part.note || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part.note])

  return (
    <Modal isOpen={isOpen}>
      <Popup
        title={title}
        onClose={closeModal}
        closable={false}
        maxWidth={570}
        titleColor={theme.colors.gray2}
        footer={
          <React.Fragment>
            <Button
              submit
              type="primary"
              onClick={() => onSubmit(textAreaValue, part.name as string)}
            >
              {t('forms.button.return')}
            </Button>
          </React.Fragment>
        }
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <TextArea
            label={label}
            block
            resizable={false}
            name="partComment"
            value={textAreaValue}
            withError={false}
            rows={5}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setTextAreaValue(e.target.value)
            }
          />
          <Attachments
            shouldOpenCameraInput={shouldOpenCameraInput}
            closeAutomaticCameraInput={closeAutomaticCameraInput}
            part={part}
            setFormAction={setFormAction}
            componentType={componentType}
            bikeLoanId={bikeLoanId}
          />
        </div>
      </Popup>
    </Modal>
  )
}

export default PartModal
