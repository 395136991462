/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { Row } from 'react-table'
import React from 'react'

import Icon from '../../Icon'

import {
  BikeModelModalSettings,
  BikeModelTableRow,
  BikeModelTableModal,
} from '../../../types/AnimatorBikes'

interface BikeModelsActionCellProps {
  setModal: (
    obj: BikeModelModalSettings<BikeModelTableRow, BikeModelTableModal>,
  ) => void
  row: Row<BikeModelTableRow>
}

const BikeModelsActionCell: React.FC<BikeModelsActionCellProps> = ({
  setModal,
  row,
}) => {
  const theme = useTheme()
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: BikeModelTableModal,
    ) => {
      e.stopPropagation()
      setModal({ bikeModel: row.original, actionName })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        css={{ cursor: 'pointer', marginLeft: 8 }}
        onClick={e => handleClick(e, BikeModelTableModal.EDIT)}
      >
        <Icon type="edit" color={theme.colors.gray2} />
      </div>
      <div
        css={{ cursor: 'pointer', marginLeft: 8 }}
        onClick={e => handleClick(e, BikeModelTableModal.DELETE)}
      >
        <Icon type="trash" color={theme.colors.gray2} />
      </div>
    </div>
  )
}

export default BikeModelsActionCell
