/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Row } from 'react-table'
import React from 'react'
import {
  PostTableModalActions,
  PostSettings,
  PostTableRow,
} from '../../../types/AnimatorPosts'
import Icon from '../../Icon'

interface PostActionCellProps {
  setModal: (obj: PostSettings<PostTableRow, PostTableModalActions>) => void
  row: Row<PostTableRow>
  actions: Array<PostTableModalActions>
}

const PostActionCell: React.FC<PostActionCellProps> = ({
  setModal,
  row,
  actions,
}) => {
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: PostTableModalActions,
    ) => {
      e.stopPropagation()
      setModal({ post: row.original, actionName })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      {actions.map(actionName => (
        <div
          key={actionName}
          css={{ cursor: 'pointer' }}
          onClick={e => handleClick(e, actionName)}
        >
          <Icon type={actionName} />
        </div>
      ))}
    </div>
  )
}

export default PostActionCell
