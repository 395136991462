import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { client } from '../ApolloClientProvider'

export enum UserRoles {
  EMPLOYEE = 'EMPLOYEE',
  COMPANY = 'COMPANY',
  ANIMATOR = 'ANIMATOR',
  ADMIN = 'ADMIN',
}

export interface UserState {
  token: string
  email: string
  name: string
  companyId?: string
  companyName: string
  companyProgramStep?: {
    name: string
    stepNbr: number
  }
  role: UserRoles | ''
  id: string
  animatorGuard: boolean
  subscriptionCode: string
}

const initialState: UserState = {
  token: '',
  email: '',
  name: '',
  companyId: '',
  companyName: '',
  companyProgramStep: undefined,
  role: '',
  id: '',
  animatorGuard: false,
  subscriptionCode: '',
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginAction(
      state: UserState,
      action: PayloadAction<Omit<UserState, 'animatorGuard'>>,
    ) {
      return { ...action.payload, animatorGuard: false }
    },
    logoutAction() {
      // Since Apollo caches all of the query results,
      // it's important to get rid of them when the login state changes.
      // by using client.clearStore()
      client.clearStore()
      return initialState
    },
    activateAnimatorGuard(state: UserState) {
      state.animatorGuard = true
    },
    updateUserName(
      state: UserState,
      action: PayloadAction<Pick<UserState, 'name'>>,
    ) {
      state.name = action.payload.name
    },
    updateCompanyName(
      state: UserState,
      action: PayloadAction<Pick<UserState, 'companyName'>>,
    ) {
      state.companyName = action.payload.companyName
    },
  },
})

export const {
  loginAction,
  logoutAction,
  activateAnimatorGuard,
  updateUserName,
  updateCompanyName,
} = userSlice.actions

export default userSlice.reducer
