/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { BikeConditionType } from '@goodwatt/shared/dist/types'
import { useTranslation } from 'react-i18next'

const bikeConditionColors: {
  [condition in BikeConditionType]: string
} = {
  stock: '#FBDFA0',
  onLoan: '#D4EF9B',
  reparation: '#F7BCBC',
  outOfService: '#F7BCBC',
  stolen: '#828282',
  sold: '#F7BCBC',
  demonstration: '#FBDFA0',
}

export const bikeConditionCellStyle = (
  bikeConditionName: BikeConditionType,
): { backgroundColor: string } => ({
  backgroundColor: bikeConditionColors[bikeConditionName],
})

interface BikeConditionCellProps {
  value: BikeConditionType
}

const BikeConditionCell: React.FC<BikeConditionCellProps> = ({ value }) => {
  const { t } = useTranslation()

  return (
    <span>{t(`animator.bikes.filter.bikeConditions.options.${value}`)}</span>
  )
}
export default BikeConditionCell
