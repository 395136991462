/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon, { IconTypes } from './Icon'
import React, { ReactNode } from 'react'
import { Flex } from './Flex'

export interface LabeledIconProps {
  icon: IconTypes
  label: ReactNode
}

export const LabeledIcon: React.FC<LabeledIconProps> = ({ label, icon }) => {
  return (
    <Flex direction="row" align="center" gap="16px">
      <Icon size="small" color="currentColor" type={icon} />
      <span css={{ fontSize: '14px', lineHeight: '18px', fontWeight: 400 }}>
        {label}
      </span>
    </Flex>
  )
}
