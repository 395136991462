/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useQuery } from '@apollo/client'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Icon from '../../../components/Icon'
import Modal from '../../../components/Modal'
import Popup from '../../../components/Popup'
import Input from '../../../components/Input'
import InputSelect from '../../../components/InputSelect'
import { useTheme } from 'emotion-theming'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import { MUTATION_UPDATE_EMPLOYEE_SITE } from '../../../utils/gql/mutations'
import {
  NotificationTypes,
  useNotifications,
} from '../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import {
  QUERY_GET_SITE_ADDRESS,
  QUERY_GET_COMPANY_SITES,
  QUERY_GET_EMPLOYEE_SITE,
} from '../../../utils/gql/queries'
import {
  GetSiteAddressQuery,
  GetSiteAddressQueryVariables,
  GetCompanySitesQuery,
  GetCompanySitesQueryVariables,
  GetEmployeeSiteQuery,
  GetEmployeeSiteQueryVariables,
  UpdateEmployeeSiteMutation,
  UpdateEmployeeSiteMutationVariables,
} from '../../../__generated__/graphql'

type SiteEmployeeInput = {
  siteId: { value: string; label: string }
}

const schema = yup.object().shape({
  siteId: yup.object().required(),
})

const ModifySite: React.FC = () => {
  const user = useSelector((state: StoreState) => state.user)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [siteInfos, setSiteInfo] = useState<{
    street: string
    postalCode: string
    city: string
  }>({
    street: '',
    postalCode: '',
    city: '',
  })
  const notifications = useNotifications()
  const companyId = user.companyId ? user.companyId : ''

  const [editSite, { loading }] = useMutation<
    UpdateEmployeeSiteMutation,
    UpdateEmployeeSiteMutationVariables
  >(MUTATION_UPDATE_EMPLOYEE_SITE, {
    refetchQueries: [
      {
        query: QUERY_GET_EMPLOYEE_SITE,
        variables: {
          where: { userId: user.id },
        },
      },
    ],
  })

  const { data: site } = useQuery<
    GetEmployeeSiteQuery,
    GetEmployeeSiteQueryVariables
  >(QUERY_GET_EMPLOYEE_SITE, {
    fetchPolicy: 'network-only',
    variables: {
      where: { userId: user.id },
    },
  })

  const employeeId = site?.employee?.id
  const siteEmployee = site?.employee?.sites[0]

  useEffect(() => {
    const fetchSiteInfo = async () => {
      if (siteEmployee) {
        const result = await refetch({
          id: siteEmployee.id,
        })

        if (result.data?.site) {
          setSiteInfo({
            street: result.data.site.street,
            postalCode: result.data.site.postalCode,
            city: result.data.site.city,
          })
        }
      }
    }
    fetchSiteInfo()
  }, [siteEmployee])

  const { data } = useQuery<
    GetCompanySitesQuery,
    GetCompanySitesQueryVariables
  >(QUERY_GET_COMPANY_SITES, {
    variables: {
      where: { companyId: { equals: companyId } },
    },
  })

  const { refetch } = useQuery<
    GetSiteAddressQuery,
    GetSiteAddressQueryVariables
  >(QUERY_GET_SITE_ADDRESS)

  const onChangeSelect = async (data: any) => {
    const result = await refetch({
      id: data.value,
    })

    if (result.data?.site) {
      setSiteInfo({
        street: result.data.site.street,
        postalCode: result.data.site.postalCode,
        city: result.data.site.city,
      })
    }
  }

  const theme = useTheme()
  const { t } = useTranslation()

  const { handleSubmit, errors, control } = useForm<SiteEmployeeInput>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const onSubmit = (data: SiteEmployeeInput) => {
    editSite({
      variables: {
        employeeId: employeeId || '',
        siteId: data.siteId.value,
      },
    })
      .then(result => {
        if (result?.data?.updateEmployeeSite) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.notification.successUpdate'),
          })
          setModalOpen(false)
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Fragment>
      <div
        css={{
          display: 'flex',
          flex: 1,
          height: '100%',
          minHeight: '147px',
          // width: '230px',
          cursor: 'pointer',
        }}
      >
        <Card
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            height: '100%',
            backgroundColor: theme.colors.yellowLegend,
          }}
          onClick={() => setModalOpen(true)}
        >
          <Icon
            color={theme.colors.primary}
            type="company"
            customSize={{ width: 75, height: 75 }}
          />
          <div>
            <p
              css={{
                fontFamily: theme.fontFamily,
                fontWeight: 'bold',
                fontSize: '1.8rem',
                lineHeight: '32px',
                color: theme.colors.gray1,
                textAlign: 'center',
              }}
            >
              {t('shared.profile.editSite')}
            </p>
          </div>
        </Card>
      </div>
      <Modal isOpen={modalOpen}>
        <Popup
          maxWidth={750}
          title={t('shared.profile.editSite')}
          closable
          overflowY="visible"
          onClose={() => setModalOpen(false)}
          footer={
            <Fragment>
              <Button
                type="tertiary"
                disabled={loading}
                onClick={() => setModalOpen(false)}
              >
                {t('forms.button.cancel')}
              </Button>
              <div css={{ marginLeft: '14px' }}>
                <Button
                  submit
                  loading={loading}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t('forms.button.confirm')}
                </Button>
              </div>
            </Fragment>
          }
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontSize: '1.6rem',
              lineHeight: '24px',
              padding: '0 28px',
              [theme.media.tablet]: {
                padding: '0 0',
              },
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} css={{ width: '100%' }}>
              <div css={{ marginTop: '18px', width: '100%' }}>
                <div css={{ display: 'flex', flexDirection: 'column' }}>
                  <Controller
                    name="siteId"
                    control={control}
                    defaultValue={{
                      value: siteEmployee?.id,
                      label: siteEmployee?.name || '',
                    }}
                    render={({ onChange, onBlur, value, name }) => (
                      <InputSelect
                        placeholder={t('forms.label.selectSite')}
                        name={name}
                        label={t('forms.label.siteName')}
                        onBlur={onBlur}
                        onChange={(...props) => {
                          onChangeSelect(props[0])
                          onChange(...props)
                        }}
                        options={
                          data?.sites?.map(elem => ({
                            value: elem?.id,
                            label: elem?.name || '',
                          })) || []
                        }
                        value={value}
                        error={!!errors.siteId}
                        helperText={
                          errors.siteId ? t('forms.errors.chooseAnOption') : ''
                        }
                      />
                    )}
                  />
                  <Input
                    type="text"
                    name="street"
                    label={t('forms.label.companyAddress')}
                    value={siteInfos.street}
                    readOnly
                  />
                </div>
                <div css={{ display: 'flex', flexDirection: 'row' }}>
                  <div css={{ flex: 2, marginRight: 15 }}>
                    <Input
                      type="text"
                      name="postalCode"
                      label={t('forms.label.postalCode')}
                      value={siteInfos.postalCode}
                      readOnly
                    />
                  </div>
                  <div css={{ flex: 3 }}>
                    <Input
                      type="text"
                      name="city"
                      label={t('forms.label.city')}
                      value={siteInfos.city}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Popup>
      </Modal>
    </Fragment>
  )
}

export default ModifySite
