/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, gql } from '@apollo/client'
import { BikeConditionType } from '@goodwatt/shared/dist/types'

import SummaryCard, { SummaryItem } from '../../../components/Card/Summary'
import {
  GetBikesCountsDashboardQuery,
  GetBikesCountsDashboardQueryVariables,
} from '../../../__generated__/graphql'

export const QUERY_BIKES_COUNT_FILTERED = gql`
  query GetBikesCountsDashboard(
    $fleet: String
  ) {
    bikesOnLoan: bikeCount(bikeCondition: "${BikeConditionType.ON_LOAN}", fleet: $fleet)
    bikesAvailable: bikeCount(bikeCondition: "${BikeConditionType.STOCK}", fleet: $fleet)
    bikesInRepair: bikeCount(bikeCondition: "${BikeConditionType.REPARATION}", fleet: $fleet)
    bikesOutOfService: bikeCount(bikeCondition: "${BikeConditionType.OUT_OF_SERVICE}", fleet: $fleet)
  }
`

interface DashbardMyBikes {
  animatorUserId: string
  fleetIdFilter: string | null
}

const DashboardMyBikes: React.FC<DashbardMyBikes> = ({ fleetIdFilter }) => {
  const { t } = useTranslation()

  const { loading, data: bikeData } = useQuery<
    GetBikesCountsDashboardQuery,
    GetBikesCountsDashboardQueryVariables
  >(QUERY_BIKES_COUNT_FILTERED, {
    fetchPolicy: 'network-only',
    variables: {
      fleet: fleetIdFilter,
    },
  })

  const myBikesData: SummaryItem[] = React.useMemo(
    () => [
      {
        id: 'bikesInActivity',
        icon: 'hourglass',
        label: t('shared.resources.bikeCount', {
          count: bikeData?.bikesOnLoan ?? 0,
        }),
        description: t('animator.dashboard.myBikesCard.onLoan'),
      },
      {
        id: 'bikesAvailable',
        icon: 'valid',
        label: t('shared.resources.bikeCount', {
          count: bikeData?.bikesAvailable ?? 0,
        }),
        description: t('animator.dashboard.myBikesCard.available'),
      },
      {
        id: 'bikesInRepair',
        icon: 'tools',
        label: t('shared.resources.bikeCount', {
          count: bikeData?.bikesInRepair ?? 0,
        }),
        description: t('animator.dashboard.myBikesCard.inRepair'),
      },
      {
        id: 'bikesOutOfService',
        icon: 'block',
        label: t('shared.resources.bikeCount', {
          count: bikeData?.bikesOutOfService ?? 0,
        }),
        description: t('animator.dashboard.myBikesCard.outOfService'),
      },
    ],
    [bikeData, t],
  )

  return (
    <SummaryCard
      loading={loading}
      title={t('animator.dashboard.myBikesCard.title')}
      linkTo="/animator/bikes"
      itemList={myBikesData}
    />
  )
}

export default DashboardMyBikes
