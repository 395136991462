/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useTranslation } from 'react-i18next'

import Picture from '../../../../components/Picture'
import { MUTATION_UPLOAD_BIKEMODEL_PICTURE } from '../../../../utils/gql/mutations'

import { avatarSchema } from '../../../../utils/schemaValidation'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import { displayBikeModelImage } from '../../../../utils/files'
import {
  UploadBikeModelPictureMutation,
  UploadBikeModelPictureMutationVariables,
} from '../../../../__generated__/graphql'

interface EditAvatarProps {
  avatarUrl?: string
  resourceId: string
  refetch: () => void
  onChangeBikePicture: (url: string) => void
}

interface FormInput {
  avatar: FileList | null
}

const EditPicture: React.FC<EditAvatarProps> = ({
  avatarUrl,
  resourceId,
  refetch,
  onChangeBikePicture,
}) => {
  const notifications = useNotifications()
  const { t } = useTranslation()
  const [uploadAvatar, { loading }] = useMutation<
    UploadBikeModelPictureMutation,
    UploadBikeModelPictureMutationVariables
  >(MUTATION_UPLOAD_BIKEMODEL_PICTURE)
  const { register, handleSubmit, errors, reset } = useForm<FormInput>({
    resolver: yupResolver(avatarSchema),
    mode: 'onBlur',
  })

  const [imgUrl, setimgUrl] = useState<string | undefined>(avatarUrl)

  useEffect(() => {
    if (errors?.avatar?.message)
      notifications.newNotification({
        type: NotificationTypes.ERROR,
        message: errors.avatar.message,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  useEffect(() => {
    setimgUrl(avatarUrl)
  }, [avatarUrl])

  useEffect(() => {
    onChangeBikePicture(imgUrl!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgUrl])

  const onSubmit = async (data: FormInput) => {
    await uploadAvatar({
      variables: {
        file: data.avatar && data.avatar[0],
        id: resourceId,
      },
    })
      .then(({ data }) => {
        if (data?.uploadBikeModelPicture) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.notification.pictureSuccessUpdate'),
          })
          setimgUrl(data.uploadBikeModelPicture.avatarUrl!)

          refetch()
          reset()
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          backgroundColor: '#F2F2F2',
          borderRadius: '22px',
          border: '2px solid #F2F2F2',
        }}
      >
        <div
          css={{
            width: '400px',
            height: '170px',
            alignSelf: 'center',
          }}
        >
          <Picture
            name="avatar"
            loading={loading}
            register={register}
            onChange={handleSubmit(onSubmit)}
            imgUrl={displayBikeModelImage(imgUrl)}
          />
        </div>
      </div>
    </form>
  )
}

export default EditPicture
