/** @jsx jsx */
import React, { useState } from 'react'
import Modal from '../../../../components/Modal'
import StepPopup from '../../../../components/Popup/StepPopup'
import { StoreState } from '../../../../redux/rootReducer'
import { useTranslation } from 'react-i18next'
import { jsx } from '@emotion/core'
import { useDispatch, useSelector } from 'react-redux'
import { UserRoles } from '@goodwatt/shared/dist/types'
import DeploymentFormStepIndicator from '../../../../components/DeploymentStepIndicator'
import Step0 from './AddDeploymentForms/step0'
import Step1 from './AddDeploymentForms/step1'
import Step2 from './AddDeploymentForms/step2'
import Step3 from './AddDeploymentForms/step3'
import {
  MUTATION_CREATE_DEPLOYMENT,
  MUTATION_UPDATE_DEPLOYMENT,
} from '../../../../utils/gql/mutations'
import {
  CreateDeploymentMutation,
  CreateDeploymentMutationVariables,
  UpdateDeploymentMutation,
  UpdateDeploymentMutationVariables,
} from '../../../../__generated__/graphql'
import { useQuery, useMutation } from '@apollo/client'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import i18n from '../../../../i18n/config'
import { resetDeploymentForm, setDeploymentForm } from '../../../../redux/forms'
import { DeploymentTableModal } from '../../../../types/AnimatorDeployments'
import {
  QUERY_ANIMATOR_DEPLOYMENT_EDIT,
  QUERY_AREA_ANIMATOR,
} from '../../../../utils/gql/queries'

interface ModalProps {
  isOpen: boolean
  deploymentId?: string
  type?: DeploymentTableModal
  onClose: (shouldRefetch?: boolean) => void
}

const DeploymentModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  deploymentId,
  type,
}) => {
  const notifications = useNotifications()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { id: userId, role: userRole } = useSelector(
    (state: StoreState) => state.user,
  )

  const isAdmin = userRole === UserRoles.ADMIN

  const deploymentForm = useSelector(
    (state: StoreState) => state.forms.deploymentForm,
  )
  const [processStep, setProcessStep] = useState<number>(0)
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true)
  const [animatorId, setAnimatorId] = useState<string>(userId)
  const [areaId, setareaId] = React.useState(deploymentForm.areaId)

  React.useEffect(() => {
    isOpen && setareaId(deploymentForm.areaId)
  }, [deploymentForm.areaId])

  const { data: selectedDeployment } = useQuery(
    QUERY_ANIMATOR_DEPLOYMENT_EDIT,
    {
      variables: { id: deploymentId as string },
      skip: !deploymentId || !isOpen,
    },
  )

  const { data: animatorData } = useQuery(QUERY_AREA_ANIMATOR, {
    variables: { where: { areaId: { equals: areaId } } },
  })

  const [createDeployment, { loading: loadingCheckReturn }] = useMutation<
    CreateDeploymentMutation,
    CreateDeploymentMutationVariables
  >(MUTATION_CREATE_DEPLOYMENT, {
    refetchQueries: ['GetDeployments', 'GetDeploymentsCount'],
  })

  const [updateDeployment] = useMutation<
    UpdateDeploymentMutation,
    UpdateDeploymentMutationVariables
  >(MUTATION_UPDATE_DEPLOYMENT, {
    refetchQueries: [
      'GetDeployments',
      'GetDeploymentsCount',
      'GetDeploymentEmployees',
      'GetDeploymentEdit',
    ],
  })

  const loading = loadingCheckReturn

  React.useEffect(() => {
    if (isAdmin && animatorData?.animators.length > 0) {
      setAnimatorId(animatorData.animators[0].user.id)
    }
  }, [areaId, animatorData, isAdmin])

  React.useEffect(() => {
    if (type === DeploymentTableModal.EDIT && selectedDeployment && isOpen) {
      const appointmentLoanDelivery =
        selectedDeployment.deployment.appointmentTemplates.find(
          (appointment: { category: string }) =>
            appointment.category === 'LOAN_DELIVERY',
        )

      const appointmentLoanReturn =
        selectedDeployment.deployment.appointmentTemplates.find(
          (appointment: { category: string }) =>
            appointment.category === 'LOAN_RETURN',
        )

      dispatch(
        setDeploymentForm({
          programStepNbr: selectedDeployment.deployment.programStep.stepNbr,
          deploymentId: selectedDeployment.deployment.id,
          areaId: selectedDeployment.deployment.site.area.id,
          name: selectedDeployment.deployment.name,
          deploymentType: selectedDeployment.deployment.deploymentType,
          siteId: selectedDeployment.deployment.site.id,
          fleetId: selectedDeployment.deployment.fleet?.id, // fleetId is not optional but with old deployment it is not set
          companyId: selectedDeployment.deployment.site.company.id,
          cargoBike: selectedDeployment.deployment.cargoBike,
          foldingBike: selectedDeployment.deployment.foldingBike,
          urbanBike: selectedDeployment.deployment.urbanBike,
          cargoBikeChildSeat: selectedDeployment.deployment.cargoBikeChildSeat,
          urbanBikeChildSeat: selectedDeployment.deployment.urbanBikeChildSeat,
          quantity: selectedDeployment.deployment.loanQuantityBikes,
          appointmentLoanDelivery: appointmentLoanDelivery,
          appointmentLoanReturn: appointmentLoanReturn,
          deploymentBikeModelPrice:
            selectedDeployment.deployment.bikeModelPrices.map(
              (bikeModelPrice: {
                id: string
                bikeModelId: string
                remainingPrice: number
                childSeat: boolean
              }) => {
                return {
                  id: bikeModelPrice.id,
                  bikeModelId: bikeModelPrice.bikeModelId,
                  remainingPrice: bikeModelPrice.remainingPrice,
                  checked: true,
                  childSeat: bikeModelPrice.childSeat,
                }
              },
            ),
        }),
      )
    }

    if (type === DeploymentTableModal.ADD && isOpen) {
      dispatch(resetDeploymentForm())
    }
  }, [deploymentId, isOpen, selectedDeployment, type])

  React.useEffect(() => {
    if (isOpen) {
      let currNextDisabled = false
      if (processStep === 0) {
        currNextDisabled =
          !deploymentForm?.name ||
          deploymentForm.deploymentType === 'NONE' ||
          !deploymentForm.siteId ||
          !deploymentForm.fleetId ||
          !deploymentForm.companyId
      }

      if (processStep === 1) {
        const hasprice = deploymentForm.deploymentBikeModelPrice.some(
          bike => bike.checked === true,
        )

        currNextDisabled = !hasprice
      }

      if (processStep === 2) {
        currNextDisabled =
          !deploymentForm.quantity ||
          !deploymentForm.appointmentLoanDelivery.location ||
          !deploymentForm.appointmentLoanDelivery.date ||
          !deploymentForm.appointmentLoanDelivery.duration ||
          !deploymentForm.appointmentLoanDelivery.capacity
      }

      if (processStep === 3) {
        currNextDisabled =
          !deploymentForm.appointmentLoanReturn.location ||
          !deploymentForm.appointmentLoanReturn.date ||
          !deploymentForm.appointmentLoanReturn.duration ||
          !deploymentForm.appointmentLoanReturn.capacity
      }

      type === DeploymentTableModal.EDIT && (currNextDisabled = false)

      setIsNextDisabled(currNextDisabled)
    }
  }, [deploymentForm, processStep, type])

  const onModalClose = (shouldRefetch = false) => {
    onClose(shouldRefetch)
    setProcessStep(0)
  }

  const steps = [
    {
      height: 530,
      mobileHeight: 280,
      title: t('animator.deployments.form.title'),
      isFirst: true,
      isLast: false,
    },
    {
      height: 530,
      mobileHeight: 385,
      title: t('animator.deployments.form.title'),
      isFirst: false,
      isLast: false,
    },
    {
      height: 530,
      mobileHeight: 380,
      title: t('animator.deployments.form.title'),
      isFirst: false,
      isLast: false,
    },
    {
      height: 530,
      mobileHeight: 280,
      title: t('animator.deployments.form.title'),
      isFirst: false,
      isLast: true,
    },
  ]

  const changeStep = (direction: 1 | -1) => {
    if (direction > 0) {
      setProcessStep(prevState => prevState + 1)
    } else if (direction < 0) {
      setProcessStep(prevState => prevState - 1)
    }
  }

  const canGoNext = async (): Promise<boolean | void> => {
    if (isNextDisabled) {
      return false
    }

    if (processStep === steps.length - 1) {
      if (type === DeploymentTableModal.EDIT) {
        let formattedbikeModelPrices = deploymentForm.deploymentBikeModelPrice
          .filter(bike => bike.checked === true)
          .map(bike => {
            return {
              id: bike.id,
              bikeModelId: bike.bikeModelId,
              remainingPrice: bike.remainingPrice,
              childSeat: bike.childSeat,
            }
          })

        let formattedData = {
          id: deploymentForm.deploymentId,
          name: deploymentForm.name,
          deploymentType: deploymentForm.deploymentType,
          siteId: deploymentForm.siteId,
          fleetId: deploymentForm.fleetId || '', // fleetId is not optional but with old deployment it is not set
          userId: animatorId,
          deploymentStartDate: deploymentForm.appointmentLoanDelivery.date,
          deploymentEndDate: deploymentForm.appointmentLoanReturn.date,
          cargoBike: deploymentForm.cargoBike,
          foldingBike: deploymentForm.foldingBike,
          urbanBike: deploymentForm.urbanBike,
          cargoBikeChildSeat: deploymentForm.cargoBikeChildSeat,
          urbanBikeChildSeat: deploymentForm.urbanBikeChildSeat,
          loanQuantityBikes: deploymentForm.quantity,
          bikeModelPrices: formattedbikeModelPrices,
          appointmentTemplates: [
            {
              id: deploymentForm.appointmentLoanDelivery.id,
              category: deploymentForm.appointmentLoanDelivery.category,
              location: deploymentForm.appointmentLoanDelivery.location,
              date: deploymentForm.appointmentLoanDelivery.date,
              duration: deploymentForm.appointmentLoanDelivery.duration,
              capacity: deploymentForm.appointmentLoanDelivery.capacity,
              companyId: deploymentForm.companyId,
            },
            {
              id: deploymentForm.appointmentLoanReturn.id,
              category: deploymentForm.appointmentLoanReturn.category,
              location: deploymentForm.appointmentLoanReturn.location,
              date: deploymentForm.appointmentLoanReturn.date,
              duration: deploymentForm.appointmentLoanReturn.duration,
              capacity: deploymentForm.appointmentLoanReturn.capacity,
              companyId: deploymentForm.companyId,
            },
          ],
          resetAppointments: [
            {
              category: deploymentForm.appointmentLoanDelivery.category,
              resetAppointments:
                deploymentForm.appointmentLoanDelivery.resetAppointments,
            },
            {
              category: deploymentForm.appointmentLoanReturn.category,
              resetAppointments:
                deploymentForm.appointmentLoanReturn.resetAppointments,
            },
          ],
        }

        await updateDeployment({
          variables: {
            data: formattedData,
          },
        })
          .then(() => {
            notifications.newNotification({
              type: NotificationTypes.SUCCESS,
              message: i18n.t('shared.notification.successUpdate'),
            })
            onModalClose(true)
            return true
          })
          .catch(apiErrorCatcher(notifications))
      } else {
        let formattedbikeModelPrices = deploymentForm.deploymentBikeModelPrice
          .filter(bike => bike.checked === true)
          .map(bike => {
            return {
              bikeModelId: bike.bikeModelId,
              remainingPrice: bike.remainingPrice,
              childSeat: bike.childSeat,
            }
          })

        let formattedData = {
          name: deploymentForm.name,
          deploymentType: deploymentForm.deploymentType,
          siteId: deploymentForm.siteId,
          fleetId: deploymentForm.fleetId,
          userId: animatorId,
          deploymentStartDate: deploymentForm.appointmentLoanDelivery.date,
          deploymentEndDate: deploymentForm.appointmentLoanReturn.date,
          cargoBike: deploymentForm.cargoBike,
          foldingBike: deploymentForm.foldingBike,
          urbanBike: deploymentForm.urbanBike,
          cargoBikeChildSeat: deploymentForm.cargoBikeChildSeat,
          urbanBikeChildSeat: deploymentForm.urbanBikeChildSeat,
          loanQuantityBikes: deploymentForm.quantity,
          bikeModelPrices: formattedbikeModelPrices,
          appointmentTemplates: [
            {
              category: deploymentForm.appointmentLoanDelivery.category,
              location: deploymentForm.appointmentLoanDelivery.location,
              date: deploymentForm.appointmentLoanDelivery.date,
              duration: deploymentForm.appointmentLoanDelivery.duration,
              capacity: deploymentForm.appointmentLoanDelivery.capacity,
              companyId: deploymentForm.companyId,
            },
            {
              category: deploymentForm.appointmentLoanReturn.category,
              location: deploymentForm.appointmentLoanReturn.location,
              date: deploymentForm.appointmentLoanReturn.date,
              duration: deploymentForm.appointmentLoanReturn.duration,
              capacity: deploymentForm.appointmentLoanReturn.capacity,
              companyId: deploymentForm.companyId,
            },
          ],
        }

        await createDeployment({
          variables: {
            data: formattedData,
          },
        })
          .then(() => {
            notifications.newNotification({
              type: NotificationTypes.SUCCESS,
              message: i18n.t('shared.notification.successUpdate'),
            })
            onModalClose(true)
            return true
          })
          .catch(apiErrorCatcher(notifications))
      }
    }

    return true
  }

  return (
    <Modal isOpen={isOpen}>
      <StepPopup
        processStep={steps[processStep]}
        processStepNbr={processStep}
        changeStep={changeStep}
        canGoNext={canGoNext}
        disableNext={isNextDisabled}
        loading={loading}
        closeModal={onModalClose}
      >
        <DeploymentFormStepIndicator
          step={processStep}
          nbrSteps={steps.length}
        />

        {processStep === 0 && <Step0 />}

        {processStep === 1 && <Step1 />}

        {processStep === 2 && <Step2 type={type} />}

        {processStep === 3 && <Step3 type={type} isOpen={isOpen} />}
      </StepPopup>
    </Modal>
  )
}

export default DeploymentModal
