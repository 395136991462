/** @jsx jsx */
import { gql, useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import { format } from 'date-fns'
import { useTheme } from 'emotion-theming'
import { useMemo, useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import Card from '../../../components/Card'
import Icon from '../../../components/Icon'
import { useParams } from 'react-router-dom'

import { ChartOptions } from 'chart.js'
import { getDatesInRange, shortDayDate } from '../../../utils/Date'
import {
  CompanyGetEmployeeRidesRangeQuery,
  CompanyGetEmployeeRidesRangeQueryVariables,
  GetInfosDeploymentQuery,
  GetInfosDeploymentQueryVariables,
} from '../../../__generated__/graphql'

// Dont change this number, its a constant in backend;
const PAGE_SIZE = 14

const QUERY_INFOS_DEPLOYMENT = gql`
  query GetInfosDeployment($id: String!) {
    deployment(id: $id) {
      deploymentStartDate
      deploymentEndDate
      programStep {
        stepNbr
      }
    }
  }
`

const QUERY_COMPANY_EMPLOYEE_RIDES_RANGE = gql`
  query CompanyGetEmployeeRidesRange(
    $deploymentId: String!
    $startDate: String!
    $endDate: String!
  ) {
    companyEmployeeRidesRecapRange(
      deploymentId: $deploymentId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      totalDistance
    }
  }
`

const options: ChartOptions<'bar'> = {
  plugins: {
    legend: {
      display: false,
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 0,
      },
    },
    y: {
      grid: {
        display: false,
      },
      type: 'linear',
    },
  },
}

const EmployeeRidesDiagram: React.FC = () => {
  const { deploymentId } = useParams<{ deploymentId: string }>()
  const { t } = useTranslation()
  const theme = useTheme()

  const { data: deployment } = useQuery<
    GetInfosDeploymentQuery,
    GetInfosDeploymentQueryVariables
  >(QUERY_INFOS_DEPLOYMENT, {
    variables: { id: deploymentId },
  })

  useEffect(() => {
    if (deployment?.deployment) {
      // Check if deployment has ended
      const DeploymentEndDate = new Date(
        deployment.deployment.deploymentEndDate,
      )
      const currentDate = new Date()

      if (DeploymentEndDate < currentDate) {
        setDateRange({
          startDate: new Date(
            new Date(deployment.deployment.deploymentEndDate).setHours(
              -24 * 14,
            ),
          ),
          endDate: new Date(deployment.deployment.deploymentEndDate),
        })
      }
    }
  }, [deployment])

  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setHours(-24 * 14)),
    endDate: new Date(),
  })

  const { data } = useQuery<
    CompanyGetEmployeeRidesRangeQuery,
    CompanyGetEmployeeRidesRangeQueryVariables
  >(QUERY_COMPANY_EMPLOYEE_RIDES_RANGE, {
    fetchPolicy: 'network-only',
    variables: {
      deploymentId: deploymentId,
      startDate: shortDayDate(dateRange.startDate, true),
      endDate: shortDayDate(dateRange.endDate, true),
    },
  })

  const diagramData = useMemo(() => {
    const mainLabels: string[] = []
    const dataSets: any = [
      {
        label: t('employee.monitoring.diagram.tooltipLabels.distance'),
        data: [],
        borderRadius: 5,
        backgroundColor: theme.colors.greenLegend,
      },
    ]

    if (!data || !data.companyEmployeeRidesRecapRange) {
      return {
        labels: mainLabels,
        datasets: dataSets,
      }
    }

    const dates = getDatesInRange(dateRange.startDate, dateRange.endDate)

    const entries = dates.map((x, i) => ({
      index: i,
      value: x,
    }))

    for (const { index, value } of entries) {
      const hasRide = data.companyEmployeeRidesRecapRange.find(
        x => x?.date === shortDayDate(value, true).replaceAll('/', '-'),
      )

      if (hasRide) {
        dataSets[0].data.push(hasRide.totalDistance || 0)
      } else {
        dataSets[0].data.push(0)
      }

      if (index % 2) {
        mainLabels.push('')
        continue
      }

      mainLabels.push(format(value, 'dd/MM'))
    }

    return {
      labels: mainLabels,
      datasets: dataSets,
    }
  }, [data, t, theme, dateRange])

  const onPreviousData = () => {
    setDateRange({
      startDate: new Date(dateRange.startDate.setHours((PAGE_SIZE + 1) * -24)),
      endDate: new Date(dateRange.endDate.setHours((PAGE_SIZE + 1) * -24)),
    })
  }

  const now = new Date()
  const isToday =
    now.getDate() === dateRange.endDate.getDate() &&
    now.getMonth() === dateRange.endDate.getMonth() &&
    now.getFullYear() === dateRange.endDate.getFullYear()

  const onNextData = () => {
    if (isToday) return

    setDateRange({
      startDate: new Date(dateRange.startDate.setHours((PAGE_SIZE + 1) * 24)),
      endDate: new Date(dateRange.endDate.setHours((PAGE_SIZE + 1) * 24)),
    })
  }

  return (
    <Card
      title={
        <span
          css={{
            fontSize: '1.6rem',
            color: theme.colors.gray2,
            fontWeight: 'bold',
            fontFamily: theme.fontFamily,
            marginBottom: '0.7rem',
          }}
        >
          {t('company.monitoring.diagram.title')}
        </span>
      }
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: 10,
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <div
          onClick={onPreviousData}
          css={{
            width: '20px',
            height: '20px',
            cursor: 'pointer',
          }}
        >
          <Icon
            type="triangleLeft"
            size="small"
            strokeColor={theme.colors.primary}
          />
        </div>
        <div
          css={{
            display: 'flex',
            flex: 1,
            marginRight: 10,
          }}
        >
          <Bar data={diagramData} options={options} />
        </div>
        <div
          onClick={onNextData}
          css={{
            width: '20px',
            height: '20px',
            cursor: isToday ? 'default' : 'pointer',
          }}
        >
          <Icon
            type="triangleRight"
            size="small"
            strokeColor={isToday ? theme.colors.gray4 : theme.colors.primary}
          />
        </div>
      </div>
    </Card>
  )
}

export default EmployeeRidesDiagram
