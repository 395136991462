import {
  DeploymentType,
  AppointmentCategoryForDeployment,
} from '@goodwatt/shared/dist/types'

export enum DeploymentTableModal {
  EDIT = 'edit',
  LOCATE = 'locate',
  DELETE = 'delete',
  ADD = 'add',
}

export interface DeploymentModalSettings<
  D = DeploymentTableRow | undefined,
  T = DeploymentTableModal,
> {
  deployment: D
  actionName: '' | T
}

export interface BikeModelPriceRow {
  bikeModelId: string
  remainingPrice: number
}

export interface AppointmentTemplateRow {
  category: AppointmentCategoryForDeployment
  location: string
  date: Date
  duration: number
  capacity: number
  companyId: string
}

export interface DeploymentTableRow {
  id: string | undefined
  name: string
  deploymentType: DeploymentType
  deploymentStartDate: Date | undefined
  deploymentEndDate: Date | undefined
  userId: string | undefined
  siteId: string | undefined
  fleetId: string | undefined
  cargoBike: boolean
  foldingBike: boolean
  urbanBike: boolean
  cargoBikeChildSeat: boolean
  urbanBikeChildSeat: boolean
  loanQuantityBikes: number
  bikeModelPrices: BikeModelPriceRow[]
  appointmentTemplates: AppointmentTemplateRow[]
}
