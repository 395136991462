/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { motion } from 'framer-motion'

import Card, { CardProps } from '../Card'
import Icon from '../Icon'

interface CardFoldableProps extends Omit<CardProps, 'title'> {
  children: React.ReactNode
  title: JSX.Element
  isDefaultFolded?: boolean
  alignEnd?: boolean
}

const variants = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
}

const CardFoldable: React.FC<CardFoldableProps> = ({
  title,
  isDefaultFolded = false,
  alignEnd = false,
  children,
  ...props
}) => {
  const [isFold, setIsFold] = React.useState(isDefaultFolded)

  const ExpendTitle = React.useMemo(
    () => (
      <div
        onClick={() => setIsFold(prevState => !prevState)}
        css={{
          userSelect: 'none',
          display: 'flex',
          cursor: 'pointer',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {title}
        <motion.div
          initial={isDefaultFolded ? 'closed' : 'open'}
          animate={isFold ? 'closed' : 'open'}
          variants={variants}
          transition={{ duration: 0.2 }}
          css={{
            marginLeft: alignEnd ? 'auto' : undefined,
          }}
        >
          <Icon type="fold" />
        </motion.div>
      </div>
    ),
    [isDefaultFolded, isFold, title, alignEnd],
  )

  return (
    <Card title={ExpendTitle} {...props}>
      {isFold ? null : children}
    </Card>
  )
}

export default CardFoldable
