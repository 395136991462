/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom'

import { NavItem, NavItemIdle, NavItemActive } from '../../../hooks/useMenu'

import Disconnect from '../Disconnect'
import ConditionalNavItem from './ConditionalNavItem'
import NavItemComponent from './NavItem'
import useWindowSize from '../../../hooks/useWindowSize'
import { ANIMATOR_GUARD_Z_INDEX } from '../../AnimatorGuard'

interface NavProps {
  navList: NavItem[]
  closeMenu?: () => void
}

const Nav: React.FC<NavProps> = ({ navList, closeMenu }): JSX.Element => {
  const [navOffsetClicked, setNavOffsetClicked] = React.useState<number>(0)
  const theme = useTheme()
  const locationPathName = useLocation().pathname
  const { width } = useWindowSize()

  return (
    <div
      css={{
        width: '250px',
        position: 'relative',
        [theme.media.tablet]: {
          width: '100%',
        },
      }}
    >
      <ul
        css={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        {navList.map((navItemConfig, index) => {
          const isActive = locationPathName.includes(navItemConfig.url)

          const updateNavCursor = (offsetTop: number) =>
            process.nextTick(() => {
              setNavOffsetClicked(offsetTop)
            })

          if ((navItemConfig as NavItemIdle).subNav) {
            return (
              <ConditionalNavItem
                key={index}
                moveNavIndictator={updateNavCursor}
                isActive={isActive}
                closeMenu={closeMenu}
                {...(navItemConfig as NavItemIdle)}
              />
            )
          }

          return (
            <NavItemComponent
              onNavClick={updateNavCursor}
              key={index}
              isActive={isActive}
              closeMenu={closeMenu}
              {...(navItemConfig as NavItemActive)}
            />
          )
        })}
      </ul>
      {width <= theme.breakpoints.tablet && (
        <div
          css={{
            marginTop: '50px',
            marginBottom: '50px',
            backgroundColor: theme.colors.white,
            width: '100%',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            zIndex: ANIMATOR_GUARD_Z_INDEX - 2,
          }}
        >
          <Disconnect
            closeMenu={() => {
              if (closeMenu) closeMenu()
            }}
          />
        </div>
      )}
      <motion.div
        initial={{ top: navOffsetClicked }}
        animate={{ top: navOffsetClicked }}
        css={{
          position: 'absolute',
          right: '0',
          width: '100%',
          height: '58px',
          backgroundColor: theme.colors.gray7,
        }}
      >
        <span
          css={{
            position: 'absolute',
            right: '0',
            width: '5px',
            height: '58px',
            backgroundColor: theme.colors.primary,
          }}
        />
      </motion.div>
    </div>
  )
}

export default Nav
