/** @jsx jsx */
import React, { useState } from 'react'
import { jsx } from '@emotion/core'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { gql, useMutation } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Modal from '@goodwatt/client/src/components/Modal'
import Popup from '@goodwatt/client/src/components/Popup'
import Input from '@goodwatt/client/src/components/Input'
import Button from '@goodwatt/client/src/components/Button'
import {
  useNotifications,
  NotificationTypes,
} from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import {
  QuizTableModalActions,
  QuizTableAdd,
  QuizTableRow,
  ModalQuizSubmit,
} from '@goodwatt/client/src/types/Quizzes'

import Icon from '../../../components/Icon'
import theme from '../../../styles/theme'
import {
  CreateQuizMutation,
  CreateQuizMutationVariables,
  UpdateQuizMutation,
  UpdateQuizMutationVariables,
} from '../../../__generated__/graphql'

const schema = yup.object().shape({
  title: yup.string(),
  content: yup.string(),
  MIME: yup.string(),
})

const MUTATION_UPDATE_QUIZ = gql`
  mutation UpdateQuiz($input: QuizUpdateInputWithImage!) {
    updateQuiz(input: $input) {
      id
      title
      content
      MIME
    }
  }
`
const MUTATION_CREATE_QUIZ = gql`
  mutation CreateQuiz($input: QuizCreationInputWithImage!) {
    createQuiz(input: $input) {
      id
    }
  }
`

interface AddOrEditModalProps {
  isOpen: boolean
  quiz: QuizTableRow
  type: '' | QuizTableAdd | QuizTableModalActions
  closeModal: () => void
  updateList: () => void
}

const AddOrEditModal: React.FC<AddOrEditModalProps> = ({
  isOpen,
  closeModal,
  quiz,
  type,
  updateList,
}) => {
  const { handleSubmit, errors, control, reset, setValue } =
    useForm<QuizTableRow>({
      resolver: yupResolver(schema),
    })

  React.useEffect(() => reset(), [quiz, reset, type])
  const { t } = useTranslation()

  const data: { data: File | null } = { data: null }
  const [file, setFile] = useState(data)

  const [updateQuiz, { loading }] = useMutation<
    UpdateQuizMutation,
    UpdateQuizMutationVariables
  >(MUTATION_UPDATE_QUIZ)
  const [createQuiz, { loading: createLoading }] = useMutation<
    CreateQuizMutation,
    CreateQuizMutationVariables
  >(MUTATION_CREATE_QUIZ)
  const notifications = useNotifications()

  const handleClose = () => {
    handleRemoveImage()
    closeModal()
  }

  const onSubmit = async (data: ModalQuizSubmit) => {
    const handleSuccess = () => {
      updateList()
      handleClose()

      notifications.newNotification({
        type: NotificationTypes.SUCCESS,
        message: t(`quiz.modal.${type}.successNotification`),
      })
    }
    if (type === QuizTableAdd.ADD) {
      try {
        await createQuiz({
          variables: {
            input: {
              content: data.content,
              title: data.title,
              MIME: 'text/url',
              image: file.data ? file.data : undefined,
            },
          },
        })
        handleSuccess()
      } catch (error) {
        apiErrorCatcher(notifications)(error)
      }
    } else {
      if (file.data) {
        return updateQuiz({
          variables: {
            input: {
              id: quiz.id,
              content: data.content,
              title: data.title,
              MIME: 'text/url',
              image: file.data ? file.data : undefined,
            },
          },
        })
          .then(handleSuccess)
          .catch(apiErrorCatcher(notifications))
      } else {
        return updateQuiz({
          variables: {
            input: {
              id: quiz.id,
              content: data.content,
              title: data.title,
              MIME: 'text/url',
            },
          },
        })
          .then(handleSuccess)
          .catch(apiErrorCatcher(notifications))
      }
    }
  }

  const handleRemoveImage = () => {
    quiz.imageURL = ''
    setFile({ data: null })
    setValue('imageURL', '')
  }

  const onFileFetch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files != null) {
      setValue('imageURL', URL.createObjectURL(files[0]))
      setFile({ data: files[0] })
    } else {
      setFile({ data: null })
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        separator={false}
        closable
        title={t(`quiz.modal.title`)}
        onClose={handleClose}
        maxWidth={750}
        footer={
          <React.Fragment>
            <div css={{ padding: '0 7px' }}>
              <Button type="tertiary" submit onClick={handleClose}>
                {t('forms.button.cancel')}
              </Button>
            </div>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="primary"
                submit
                loading={loading || createLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t('forms.button.confirm')}
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <div css={{ padding: '30px 54px 38px' }}>
          <form>
            <div>
              <Controller
                name="title"
                control={control}
                defaultValue={quiz.title}
                render={({ onChange, value }) => {
                  return (
                    <Input
                      placeholder={t('quiz.modal.label')}
                      label={t('quiz.modal.label')}
                      onChange={onChange}
                      value={value}
                      error={!!errors.title}
                      helperText={errors?.title?.message}
                    />
                  )
                }}
              />
            </div>
            <div>
              <Controller
                name="content"
                control={control}
                defaultValue={quiz.content}
                render={({ onChange, value }) => (
                  <Input
                    placeholder={t('quiz.modal.linkPlaceholder')}
                    label={t('quiz.modal.link')}
                    onChange={onChange}
                    value={value}
                    error={!!errors.title}
                    helperText={errors?.title?.message}
                  />
                )}
              />
            </div>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 12,
                marginTop: 24,
              }}
            >
              <div
                css={{
                  display:
                    quiz.imageURL || file.data != null ? 'block' : 'none',
                  width: '50%',
                  marginRight: 12,
                  maxHeight: 284,
                }}
              >
                <Controller
                  name="imageURL"
                  control={control}
                  defaultValue={quiz.imageURL}
                  render={({ value }) =>
                    value != null ? (
                      <img
                        style={{
                          borderRadius: 10,
                        }}
                        src={value}
                        alt="animator"
                        css={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <div></div>
                    )
                  }
                />
              </div>

              <div
                css={{
                  width: '50%',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '2px dashed #848484',
                    position: 'relative',
                    background: '#F2F2F2',
                    borderRadius: 10,
                    transition: 'ease all .3s',
                    height: 284,
                    ':hover': {
                      background: '#92d4f038',
                    },
                  }}
                >
                  <input
                    type="file"
                    name="file"
                    onChange={onFileFetch}
                    css={{
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer',
                      opacity: 0,
                      margin: 0,
                      padding: 0,
                      outline: 'none',
                      position: 'absolute',
                    }}
                  />

                  <Icon
                    strokeColor="white"
                    type="fileUpload"
                    customSize={{ width: '68px', height: '68px' }}
                  />

                  <div
                    css={{
                      marginTop: 18,
                      textAlign: 'center',
                      fontSize: 14,
                      fontWeight: 'bold',
                      margin: '12px 0',
                    }}
                  >
                    <span>{t('quiz.modal.fileUploadText')}</span>
                    <span
                      css={{
                        color: theme.colors.blue1,
                        textDecoration: 'outline',
                      }}
                    >
                      {t('quiz.modal.fileUploadHighlight')}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              css={{
                width: '50%',
                textAlign: 'center',
                display: quiz.imageURL || file.data != null ? 'block' : 'none',
              }}
            >
              <span
                css={{
                  color: theme.colors.blue1,
                  textDecoration: 'outline',
                  cursor: 'pointer',
                }}
                onClick={handleRemoveImage}
              >
                {t('quiz.modal.deleteImage')}
              </span>
            </div>
          </form>
        </div>
      </Popup>
    </Modal>
  )
}

export default AddOrEditModal
