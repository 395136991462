/** @jsx jsx */
import Card, { CardProps } from './index'
import React, { useState } from 'react'
import { jsx } from '@emotion/core'
import { motion } from 'framer-motion'
import Icon from '../Icon'
import theme from '../../styles/theme'

const variants = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
}

interface CardCollapsibleProps extends Omit<CardProps, 'title'> {
  collapsibleContent: React.ReactNode
  children: React.ReactNode
}

export const CardCollapsible: React.FC<CardCollapsibleProps> = ({
  collapsibleContent,
  children,
  ...props
}) => {
  const [isFolded, setIsFolded] = useState(true)

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '16px',
      }}
    >
      <Card {...props}>
        <div
          onClick={() => setIsFolded(!isFolded)}
          css={{
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              gap: '16px',
              flexGrow: 1,
            }}
          >
            {children}
          </div>
          <motion.div
            initial="closed"
            animate={isFolded ? 'closed' : 'open'}
            variants={variants}
            transition={{ duration: 0.2 }}
            css={{
              marginLeft: '20px',
              [theme.media.mobile]: { alignSelf: 'flex-start' },
            }}
          >
            <Icon type="fold" />
          </motion.div>
        </div>
      </Card>
      {!isFolded && (
        <div
          css={{
            marginTop: '-32px',
            padding: '48px 64px 16px 64px',
            backgroundColor: theme.colors.primaryPale,
            boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.15)',
            borderRadius: '0 0 30px 30px',
          }}
        >
          {collapsibleContent}
        </div>
      )}
    </div>
  )
}
