import { gql } from '@apollo/client'
import i18n from '../../i18n/config'
import { GetArchivedDeploymentIdentityExportQuery } from '../../__generated__/graphql'

export const QUERY_ARCHIVED_DEPLOYMENT_IDENTITY_EXPORT = gql`
  query GetArchivedDeploymentIdentityExport(
    $companyId: String
    $deploymentId: String
  ) {
    employees(
      where: {
        AND: [
          { companyId: { equals: $companyId } }
          {
            employeeDeployments: {
              some: { deploymentId: { equals: $deploymentId } }
            }
          }
        ]
      }
    ) {
      civility
      firstName
      lastName
      user {
        email
      }
    }
  }
`

export const formatArchivedDeploymentIdentityExport = (
  data: GetArchivedDeploymentIdentityExportQuery | undefined,
): any => {
  if (!data?.employees?.length) return []

  const header = ['Civilité', 'Prenom', 'Nom', 'E-mail']

  return [
    header,
    ...data?.employees.map(employee => ({
      civility: i18n.t(`shared.profile.civility.${employee.civility}`),
      firstName: employee.firstName,
      lastName: employee.lastName,
      email: employee.user.email,
    })),
  ]
}
