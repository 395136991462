import { dateFormatterExport } from '../formatter'
import { differenceInYears } from 'date-fns'
import i18n from '../../i18n/config'
import { SpecialBikesType, Appointment } from '../../__generated__/graphql'

const findLoanAppointmentDate = (
  loanAppointments: Appointment[],
  deploymentId: string,
) => {
  const LoanDelivery = loanAppointments.find(
    (loanAppointment: Appointment) =>
      loanAppointment.deploymentId === deploymentId,
  )
  return LoanDelivery?.date
}

export const formatEmployeesExportV3 = (
  employeeExportData: any | undefined,
): any => {
  if (!employeeExportData?.employees?.length) return []

  const ed = employeeExportData.employees

  const header = [
    'Civilité',
    'Prenom',
    'Nom',
    'E-mail',
    'Telephone portable',
    'Adresse',
    'Code postal',
    'Ville',
    'Date de naissance',
    'Age',
    'Ville de naissance',
    'Taille (cm)',

    'Mode de transport',
    'Distance DT (km)',
    'Duree DT (min)',
    'Pratique du velo',
    'Stationnement',

    "Nom de l'organisation",
    'Nom du site',

    'Type de vélo',
    'Marque du vélo',
    'Modèle du vélo',
    'Siège enfant',

    'Ordre',

    'Caution',
    'Pièces justificatives',

    'Numero velo',

    'Date de remise',
    'Heure de remise',
    'Date de reprise',
    'Heure de reprise',

    'Nombre de trajets DT',
    "Nombre de jour d'utilisation",
    'Distance totale (km)',
    'Distance quotidienne (km/j)',
    'Economie (€)',
    'Effort physique (kCal)',
    'Emissions (kgCO2)',
    'Nombre de points (appli)',
    'Téléchargement app',
  ]

  return [
    header,
    ...ed.map((ed: any) => ({
      civility: i18n.t(`shared.profile.civility.${ed.employee?.civility}`),
      firstName: ed.employee?.firstName,
      lastName: ed.employee?.lastName,
      email: ed.employee?.user?.email,
      phoneNumber: ed.employee?.phoneNumber,
      street: ed.employee?.street,
      postalCode: ed.employee?.postalCode,
      city: ed.employee?.city,
      birthdate: dateFormatterExport(ed.employee?.birthdate, false),
      age: differenceInYears(new Date(), new Date(ed.employee?.birthdate)),
      cityOfBirth: ed.employee?.cityOfBirth,
      height: ed.employee?.height,

      travelMode: ed.employee?.travelMode?.name
        ? i18n.t(
            `animator.company.employees.subscribed.filter.mode.options.${ed.employee.travelMode.name}`,
          )
        : '',
      workTravelDistance: ed.employee.workTravelDistance,
      workTravelDuration: ed.employee.workTravelDuration,
      employeeBikeHabit: ed.employee?.employeeBikeHabit?.name
        ? i18n.t(
            `shared.employeeBikeHabitLabel.${ed.employee?.employeeBikeHabit?.name}`,
          )
        : '',
      bikeParkingType: ed.employee?.bikeParkingType?.name
        ? i18n.t(
            `animator.company.employees.subscribed.filter.parkingType.options.${ed.employee.bikeParkingType.name}`,
          )
        : '',

      companyName: ed.employee?.company?.name,
      siteName: ed.employee?.sites[0]?.name,

      specialBikeType: ed.chosenBikeModel?.bikemodel?.bikeType
        ? {
            [SpecialBikesType.UrbanBike]: 'Urbain',
            [SpecialBikesType.FoldingBike]: 'Pliant',
            [SpecialBikesType.CargoBike]: 'Familial',
          }[ed.chosenBikeModel?.bikemodel?.bikeType as SpecialBikesType]
        : '',
      bikeBrand: ed.chosenBikeModel?.bikemodel?.brand,
      bikeModel: ed.chosenBikeModel?.bikemodel?.name,

      babySeat: ed.option ? i18n.t(`shared.babySeat.short.${ed.option}`) : '',

      order: ed.employee?.order,

      deposit:
        ed.employee?.depositId || ed.employee?.depositByCheque ? 'Oui' : 'Non',
      proofs:
        ed.employee?.identityCardVerified && ed.employee?.proofOfAddressVerified
          ? 'Oui'
          : ed.employee?.identityCardFrontFilePath &&
            ed.employee?.proofOfAddressFilePath
          ? 'En attente'
          : 'Non',

      bikeId: ed.bikeLoan?.bike?.stickerId,

      bikeLoanDate: ed.employee?.bikeLoan.bikeDeliveredDate
        ? dateFormatterExport(ed.employee?.bikeLoan.bikeDeliveredDate, false)
        : dateFormatterExport(
            findLoanAppointmentDate(
              ed.employee?.loanDeliveryAppointment,
              ed.deploymentId,
            ),
            false,
          ),
      bikeLoanDateHour: ed.employee?.bikeLoan.bikeDeliveredDate
        ? dateFormatterExport(
            ed.employee?.bikeLoan.bikeDeliveredDate,
            true,
            true,
          )
        : dateFormatterExport(
            findLoanAppointmentDate(
              ed.employee?.loanDeliveryAppointment,
              ed.deploymentId,
            ),
            true,
            true,
          ),

      bikeLoanEndDate: ed.employee?.bikeLoan.bikeReturnedDate
        ? dateFormatterExport(ed.employee?.bikeLoan.bikeReturnedDate, false)
        : dateFormatterExport(
            findLoanAppointmentDate(
              ed.employee?.loanReturnAppointment,
              ed.deploymentId,
            ),
            false,
          ),
      bikeLoanRetrunDateHour: ed.employee?.bikeLoan.bikeReturnedDate
        ? dateFormatterExport(
            ed.employee?.bikeLoan.bikeReturnedDate,
            true,
            true,
          )
        : dateFormatterExport(
            findLoanAppointmentDate(
              ed.employee?.loanReturnAppointment,
              ed.deploymentId,
            ),
            true,
            true,
          ),

      ridesCount: ed.employee?.homeWorkRides,
      bikeDaysUsed: ed.employee?.bikeDaysUsed,
      totalDistanceInKm: ed.employee?.totalDistanceInKm,

      totalDailyDistance: (
        (ed.employee?.totalDistanceInKm || 0) / (ed.employee?.bikeDaysUsed || 1)
      ).toFixed(2),

      totalMoneySaved: ed.employee?.totalMoneySaved,
      totalEnergySpent: ed.employee?.totalEnergySpent,
      totalKgCO2Saved: Math.round(ed.employee?.totalKgCO2Saved || 0),
      currentLoanPoints: ed.employee?.currentLoanPoints,
      hasMobileApp: ed.employee?.hasMobileApp ? 'Oui' : 'Non',
    })),
  ]
}
