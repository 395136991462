import {
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client'
import {
  UpdateSelectLoanEmployeeDeploymentMutation,
  UpdateSelectLoanEmployeeDeploymentMutationVariables,
} from '../../../../__generated__/graphql'

export const MUTATION_SELECT_LOAN_EMPLOYEE_DEPLOYMENT = gql`
  mutation UpdateSelectLoanEmployeeDeployment(
    $employeeDeploymentId: String!
    $selectBool: Boolean!
  ) {
    updateSelectLoanEmployeeDeployment(
      employeeDeploymentId: $employeeDeploymentId
      selectEmployee: $selectBool
    ) {
      id
    }
  }
`

export const useSelectLoanEmployeeDeployment = (
  options?: MutationHookOptions<
    UpdateSelectLoanEmployeeDeploymentMutation,
    UpdateSelectLoanEmployeeDeploymentMutationVariables
  >,
): MutationTuple<
  UpdateSelectLoanEmployeeDeploymentMutation,
  UpdateSelectLoanEmployeeDeploymentMutationVariables
> => {
  return useMutation(MUTATION_SELECT_LOAN_EMPLOYEE_DEPLOYMENT, {
    refetchQueries: ['GetDeploymentEmployees'],
    ...options,
  })
}
