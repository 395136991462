/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useMutation, useQuery } from '@apollo/client'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import Button from '../../../../components/Button'
import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'
import InputSelect from '../../../../components/InputSelect'
import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'

import i18n from '../../../../i18n/config'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import {
  GetAnimatorsAndCompanyQuery,
  GetAnimatorsAndCompanyQueryVariables,
  UpdateCompanyAnimatorMutation,
  UpdateCompanyAnimatorMutationVariables,
} from '../../../../__generated__/graphql'

const schema = yup.object().shape({
  animator: yup.object().shape({
    value: yup.string().required(i18n.t('forms.errors.required')),
  }),
})

interface FormInput {
  animator: { value: string; label: string }
}

const MUTATION_UPDATE_COMPANY_ANIMATOR = gql`
  mutation UpdateCompanyAnimator($companyId: String!, $animatorId: String!) {
    updateOneCompany(
      where: { id: $companyId }
      data: { animator: { connect: { id: $animatorId } } }
    ) {
      animator {
        id
        firstName
        lastName
      }
    }
  }
`

const QUERY_GET_ANIMATORS_AND_COMPANY = gql`
  query GetAnimatorsAndCompany(
    $companyId: String!
    $where: AnimatorWhereInput
  ) {
    animators(where: $where) {
      id
      firstName
      lastName
    }
    company(where: { id: $companyId }) {
      animator {
        id
        firstName
        lastName
      }
    }
  }
`

interface ModifyAnimatorProps {
  companyId: string
}

const ModifyAnimator: React.FC<ModifyAnimatorProps> = ({ companyId }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const notifications = useNotifications()
  const theme = useTheme()
  const { t } = useTranslation()
  const { data, refetch: refetchFleets } = useQuery<
    GetAnimatorsAndCompanyQuery,
    GetAnimatorsAndCompanyQueryVariables
  >(QUERY_GET_ANIMATORS_AND_COMPANY, {
    variables: {
      companyId,
      where: {
        area: {
          company: {
            some: {
              id: {
                equals: companyId,
              },
            },
          },
        },
      },
    },
  })
  const [updateComanyAnimator] = useMutation<
    UpdateCompanyAnimatorMutation,
    UpdateCompanyAnimatorMutationVariables
  >(MUTATION_UPDATE_COMPANY_ANIMATOR)
  const { handleSubmit, errors, control } = useForm<FormInput>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const onSubmit = (data: FormInput) => {
    updateComanyAnimator({
      variables: { companyId, animatorId: data.animator.value },
    })
      .then(() => {
        refetchFleets()
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t('company.modifyAnimatorModal.notificationSuccess'),
        })
        setModalOpen(false)
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Fragment>
      <div
        css={{
          display: 'flex',
          flex: 1,
          height: '100%',
          minHeight: '147px',
          cursor: 'pointer',
        }}
      >
        <Card
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            height: '100%',
            backgroundColor: theme.colors.yellowLegend,
          }}
          onClick={() => setModalOpen(true)}
        >
          <Icon
            color={theme.colors.primary}
            type="profile"
            customSize={{ width: 80, height: 80 }}
          />
          <div>
            <p
              css={{
                fontFamily: theme.fontFamily,
                fontWeight: 'bold',
                fontSize: '1.8rem',
                lineHeight: '32px',
                color: theme.colors.gray1,
                textAlign: 'center',
              }}
            >
              {t('company.profile.modifyAnimator')}
            </p>
          </div>
        </Card>
      </div>
      <Modal isOpen={modalOpen}>
        <Popup
          maxWidth={750}
          title={t('company.modifyAnimatorModal.title')}
          closable
          overflowY="visible"
          onClose={() => setModalOpen(false)}
          footer={
            <Fragment>
              <Button type="tertiary" onClick={() => setModalOpen(false)}>
                {t('forms.button.cancel')}
              </Button>
              <div css={{ marginLeft: '14px' }}>
                <Button submit onClick={handleSubmit(onSubmit)}>
                  {t('forms.button.confirm')}
                </Button>
              </div>
            </Fragment>
          }
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontSize: '1.6rem',
              lineHeight: '24px',
              padding: '0 60px',
              [theme.media.tablet]: {
                padding: '0 0',
              },
            }}
          >
            <p css={{ textAlign: 'center' }}>
              {t('company.modifyAnimatorModal.description')}
            </p>
            <p
              css={{ textAlign: 'center' }}
              dangerouslySetInnerHTML={{
                __html: t('company.modifyAnimatorModal.description2'),
              }}
            />
            <form onSubmit={handleSubmit(onSubmit)} css={{ width: '100%' }}>
              <div
                css={{ marginTop: '18px', width: '100%', marginBottom: '60px' }}
              >
                <Controller
                  name="animator"
                  control={control}
                  defaultValue={{
                    label: data?.company?.animator
                      ? `${data?.company?.animator?.firstName} ${data?.company?.animator?.lastName}`
                      : '',
                    value: data?.company?.animator?.id,
                  }}
                  render={({ onChange, value, name }) => (
                    <InputSelect
                      name={name}
                      value={value}
                      onChange={onChange}
                      error={!!errors.animator}
                      helperText={errors?.animator?.value?.message}
                      options={
                        data?.animators.map(animator => ({
                          label: `${animator.firstName} ${animator.lastName}`,
                          value: animator.id,
                        })) || []
                      }
                    />
                  )}
                />
              </div>
            </form>
          </div>
        </Popup>
      </Modal>
    </Fragment>
  )
}

export default ModifyAnimator
