/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'
import DeploymentsTable from '../../Animator/Dashboard/DeploymentsTable'
import HeaderSummary from '../../../components/Dasboard/HeaderSummary'

const DeploymentsAdmin: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <HeaderSummary />

      <DeploymentsTable
        title={t('animator.dashboard.deploymentsInProgressTitle')}
        statusColor={theme.colors.greenLegend}
      />

      <DeploymentsTable
        title={t('animator.dashboard.archivedDeployments')}
        statusColor={theme.colors.gray6}
        archived
        isDefaultFolded
      />
    </div>
  )
}

export default DeploymentsAdmin
