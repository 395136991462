/** @jsx jsx */
import { jsx } from '@emotion/core'
import Icon from '../../../../components/Icon'
import React, { Fragment } from 'react'
import { useModal } from '../../../../hooks/useModal'
import AddOrEditCompanyMonitoringModal from '../../Company/Monitoring/AddOrEditCompanyMonitoringModal'
import {
  CompanyMonitoringTableModalActions,
  CompanyMonitoringTableRow,
} from '../../../../types/CompanyMonitoring'

export interface EditCompanyMonitoringButtonProps {
  companyMonitoring: CompanyMonitoringTableRow
}

export const EditCompanyMonitoringButton: React.FC<
  EditCompanyMonitoringButtonProps
> = ({ companyMonitoring }) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      <AddOrEditCompanyMonitoringModal
        companyMonitoring={companyMonitoring}
        type={CompanyMonitoringTableModalActions.EDIT}
        {...modalProps}
      />
      <button onClick={onOpen}>
        <Icon type="edit" />
      </button>
    </Fragment>
  )
}
