/** @jsx jsx */
import React, { useMemo, useState } from 'react'
import { jsx } from '@emotion/core'
import { gql, useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '@goodwatt/client/src/components/Table'
import Pagination from '@goodwatt/client/src/components/Pagination'
import BikeContionCell, {
  bikeConditionCellStyle,
} from '@goodwatt/client/src/components/Table/CustomCells/BikeConditionCell'
import BikesActionCell from '@goodwatt/client/src/components/Table/CustomCells/BikesActionCell'
import {
  BikeListProps,
  BikeModalSettings,
  BikesTableRow,
  BikeTableModal,
} from '@goodwatt/client/src/types/AnimatorBikes'

import EditBikeModal from './EditBikeModal'
import Button from '../../../components/Button'
import theme from '../../../styles/theme'
import Icon from '../../../components/Icon'
import AddBikeModal from './AddBikeModal'
import DeleteBikeModal from './DeleteBikeModal'
import DownloadCSVButton from '../../../components/Button/DownloadCSVButton'
import usePromiseLazyQuery from '../../../hooks/usePromiseLazyQuery'
import {
  formatBikesExport,
  QUERY_BIKES_EXPORT,
} from '../../../utils/gql/bikesExport'

import {
  AnimatorBikeCountFilteredQuery,
  AnimatorBikeCountFilteredQueryVariables,
  AnimatorGetCompanyBikesQuery,
  AnimatorGetCompanyBikesQueryVariables,
  DeploymentType,
  GetBikesExportQuery,
  GetBikesExportQueryVariables,
} from '../../../__generated__/graphql'
import { SpecialBikesType } from '@goodwatt/shared/dist/types'

const QUERY_ANIMATOR_BIKE_COUNT_FILTERED = gql`
  query AnimatorBikeCountFiltered(
    $bikeCondition: String
    $fleet: String
    $model: String
    $multiFilter: String
  ) {
    bikeCount(
      bikeCondition: $bikeCondition
      fleet: $fleet
      model: $model
      multiFilter: $multiFilter
    )
  }
`

const QUERY_ANIMATOR_COMPANY_BIKES = gql`
  query AnimatorGetCompanyBikes(
    $bikeCondition: String
    $fleet: String
    $model: String
    $multiFilter: String
    $take: Int
    $skip: Int
    $orderBy: BikeOrderByInput
  ) {
    bikesSearch(
      bikeCondition: $bikeCondition
      fleet: $fleet
      model: $model
      multiFilter: $multiFilter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      stickerId
      index

      bikeModelSize
      bikeModelType

      companySalaryName

      repairs(where: { completed: { equals: false } }) {
        resolvedDate
      }

      bikeCondition {
        name
      }

      bikeLoans {
        bikeReturned
        employeeDeployment {
          deployment {
            deploymentType
          }
        }
      }

      bikeModel {
        name
        size
        modelSizes
        bikeType
      }

      fleet {
        name
      }

      childSeat
    }
  }
`

const initalModalSettings: BikeModalSettings = {
  bike: {
    id: '',
    index: 0,
    bikeModel: '',
    fleet: '',
    bikeCondition: '',
    stickerId: undefined,
    resolvedDate: undefined,
  },
  actionName: '',
}
const AnimatorBikeTableList: React.FC<BikeListProps> = ({
  filters,
  pageSize,
}) => {
  const { t } = useTranslation()
  const [currModal, setCurrModal] =
    useState<BikeModalSettings>(initalModalSettings)
  const [currPage, setCurrPage] = useState(1)
  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)

  const searchVariables = {
    bikeCondition: filters.bikeCondition,
    fleet: filters.fleet,
    model: filters.model,
    multiFilter: filters.multiFilter,
  }

  // Resetting to first page on filter change
  React.useEffect(() => {
    setCurrPage(1)
  }, [filters])

  const { data: countData, refetch: refetchBikesCount } = useQuery<
    AnimatorBikeCountFilteredQuery,
    AnimatorBikeCountFilteredQueryVariables
  >(QUERY_ANIMATOR_BIKE_COUNT_FILTERED, {
    variables: searchVariables,
  })
  const {
    data: bikesData,
    loading,
    refetch: refetchBikesData,
  } = useQuery<
    AnimatorGetCompanyBikesQuery,
    AnimatorGetCompanyBikesQueryVariables
  >(QUERY_ANIMATOR_COMPANY_BIKES, {
    fetchPolicy: 'network-only',
    variables: {
      ...searchVariables,

      take: pageSize,
      skip: (currPage - 1) * pageSize,
      orderBy:
        orderBy.sort && orderBy.columnName
          ? { [orderBy.columnName]: orderBy.sort }
          : undefined,
    },
  })

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<BikesTableRow> }) => (
      <BikesActionCell setModal={setCurrModal} row={row} />
    ),
    [],
  )
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: () => (
          <div css={{ margin: 'auto' }}>
            {t('animator.bikes.table.headers.bikeSticker') as string}
          </div>
        ),
        // Header: t('animator.bikes.table.headers.bikeSticker') as string,
        accessor: 'stickerId',
        cellStyle: () => ({ minWidth: '110px' }),
        Cell: ({ value }: { value: string }) => {
          return (
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>{value}</span>
            </div>
          )
        },
      },

      {
        Header: t('animator.bikes.table.headers.bikeModelName') as string,
        accessor: 'bikeModelId',
        maxWidth: 200,
      },

      {
        Header: t('animator.bikes.table.headers.bikeModelType') as string,
        accessor: 'bikeModelType',
        Cell: ({ value }: { value: SpecialBikesType }) => {
          return (
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {value === SpecialBikesType.URBAN_BIKE && (
                <Icon type="bikeUrban" color={theme.colors.gray2} />
              )}

              {value === SpecialBikesType.FOLDING_BIKE && (
                <Icon type="bikeFolding" color={theme.colors.gray6} />
              )}

              {value === SpecialBikesType.CARGO_BIKE && (
                <Icon type="bikeCargo" color={theme.colors.gray2} />
              )}
            </div>
          )
        },
      },

      {
        Header: t('animator.bikes.table.headers.bikeModelSize') as string,
        accessor: 'bikeModelSize',
        Cell: ({ value }: { value: string }) => {
          return (
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>{value}</span>
            </div>
          )
        },
      },
      {
        Header: t('animator.bikes.table.headers.bikeModelChildSeat') as string,
        accessor: 'childSeat',
        Cell: ({ value }: { value: boolean }) => {
          return (
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {value && <Icon type="kidOption" color={theme.colors.gray2} />}
            </div>
          )
        },
      },
      {
        Header: t('animator.bikes.table.headers.bikeFleetName') as string,
        accessor: 'fleetId',
      },
      {
        Header: t('animator.bikes.table.headers.bikeConditionName') as string,
        accessor: 'bikeConditionId',
        cellStyle: bikeConditionCellStyle,
        Cell: BikeContionCell,
      },
      {
        Header: t('animator.bikes.table.headers.companySalaryName') as string,
        accessor: 'companySalaryName',
        Cell: ({ value, row }: { value: boolean; row: any }) => {
          const deploymentType = row.original.deploymentType as DeploymentType
          const bikeCondition = row.original.bikeConditionId as
            | string
            | undefined

          return (
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
              }}
            >
              {deploymentType &&
                deploymentType !== DeploymentType.None &&
                bikeCondition === 'onLoan' && (
                  <div
                    css={{
                      height: 18,
                      width: 18,
                      borderRadius: '100%',
                      background:
                        deploymentType === DeploymentType.Discovery
                          ? 'hsla(188, 87%, 59%, 1)'
                          : 'hsla(0, 0%, 31%, 1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 12,
                      color: 'white',
                    }}
                  >
                    {deploymentType === DeploymentType.Discovery ? 'D' : 'L'}
                  </div>
                )}
              <span>{value}</span>
            </div>
          )
        },
      },
      {
        Header: t('animator.bikes.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [t, ActionCell],
  )

  const formattedData = useMemo(() => {
    if (!bikesData?.bikesSearch?.length) return []

    return bikesData.bikesSearch.map(bike => {
      return {
        id: bike?.id,
        index: bike?.index,
        stickerId: bike?.stickerId,
        bikeModelSize: bike?.bikeModelSize,
        bikeModelType: bike?.bikeModelType,
        deploymentType: bike?.bikeLoans?.find(bl => bl.bikeReturned === false)
          ?.employeeDeployment?.deployment?.deploymentType,
        childSeat: bike?.childSeat,
        companySalaryName: bike?.companySalaryName,
        bikeConditionId: bike?.bikeCondition?.name,
        bikeModelId: bike?.bikeModel?.name,
        resolvedDate: bike?.repairs[0]?.resolvedDate
          ? new Date(bike?.repairs[0]?.resolvedDate)
          : undefined,
        fleetId: bike?.fleet?.name,
      }
    })
  }, [bikesData])

  const handleEditModalClose = (shouldRefetch = false) => {
    setCurrModal(initalModalSettings)
    if (shouldRefetch) {
      refetchBikesCount()
      refetchBikesData()
    }
  }

  const getBikesExport = usePromiseLazyQuery<
    GetBikesExportQuery,
    GetBikesExportQueryVariables
  >(QUERY_BIKES_EXPORT, 'no-cache')

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')

  const filename = `bikes-${formatedDate}`

  const getExportData = async () => {
    const { data } = await getBikesExport({
      ...searchVariables,
    })

    return formatBikesExport(data)
  }

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={formattedData}
        onSortBy={setOrderBy}
        loading={loading}
      />
      <div
        css={{
          marginTop: '41px',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <div
          css={{
            display: 'flex',
            justifySelf: 'flex-start',
            position: 'absolute',
            left: 0,
          }}
        >
          <Button
            submit
            onClick={() =>
              setCurrModal({ bike: undefined, actionName: BikeTableModal.ADD })
            }
          >
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontSize: '1.6rem',
                fontWeight: 500,
                color: theme.colors.white,
                textTransform: 'uppercase',
              }}
            >
              {t('animator.bikes.table.addBike')}
            </span>
          </Button>
        </div>

        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={countData?.bikeCount}
        />
        <div
          css={{
            display: 'flex',
            justifySelf: 'flex-end',
            position: 'absolute',
            right: 0,
          }}
        >
          <DownloadCSVButton getData={getExportData} filename={filename} />
        </div>
      </div>
      <EditBikeModal
        bikeId={currModal.bike?.id}
        isOpen={currModal.actionName === BikeTableModal.EDIT}
        closeModal={handleEditModalClose}
      />
      <DeleteBikeModal
        bike={currModal.bike}
        refetch={refetchBikesData}
        isOpen={currModal.actionName === BikeTableModal.DELETE}
        closeModal={handleEditModalClose}
      />
      <AddBikeModal
        isOpen={currModal.actionName === BikeTableModal.ADD}
        closeModal={handleEditModalClose}
      />
    </React.Fragment>
  )
}

export default AnimatorBikeTableList
