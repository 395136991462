export const breakpoints = {
  mobile: 576,
  tablet: 768,
  laptop: 992,
  desktop: 1200,
}

export const media = {
  mobile: `@media only screen and (max-width : ${breakpoints.mobile}px)`,
  tablet: `@media only screen and (max-width : ${breakpoints.tablet}px)`,
  laptop: `@media only screen and (max-width : ${breakpoints.laptop}px)`,
  desktop: `@media only screen and (max-width : ${breakpoints.desktop}px)`,
}

const theme = {
  colors: {
    backgroundDimmed: 'rgba(0, 0, 0, 0.5)',
    primary: '#3CD9F1',
    primaryDark: '#4aacd3',
    primaryLight: '#86DAFC',
    primaryPale: '#f7feff',
    secondary: '#F7E937',
    secondaryDark: '#e4d734',
    secondaryLight: '#faee59',
    lightDark: '#404040',
    gray1: '#333333',
    gray2: '#4F4F4F',
    gray3: '#828282',
    gray4: '#BDBDBD',
    gray5: '#E0E0E0',
    gray6: '#F2F2F2',
    gray7: '#F9F7F7',
    blue1: '#45c9ff',
    white: '#FFFFFF',
    border: '#F1F1F1',
    greenLegend: '#93D704',
    redLegend: '#EB5757',
    orangeLegend: '#F6AF11',
    yellowLegend: '#FFDC83',
    background: '#E5E5E5',
    success: '#27AE60',
    successDark: '#219651',
    warning: '#F2994A',
    error: '#A30000',
    optionsGreen: '#4e8726',
    optionsOrange: '#be5108',
    optionsYellow: '#d49600',
  },
  fontFamily: 'Raleway',
  media,
  breakpoints,
}

export default theme
