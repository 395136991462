/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { regex } from '@goodwatt/shared'

import Input from '../../components/Input'
import Button from '../../components/Button'

import { useSelector, useDispatch } from 'react-redux'
import { StoreState } from '../../redux/rootReducer'
import { setCompanySignUpForm } from '../../redux/forms'
import { useNotifications } from '../../contexts/NotificationContext'
import { useMutation } from '@apollo/client'
import i18n from '../../i18n/config'
import apiErrorCatcher from '../../utils/apiErrorCatcher'
import { useTranslation } from 'react-i18next'
import { MUTATION_CHECK_EMAIL } from '../../utils/gql/mutations'

import Checkbox from '../../components/Checkbox'
import { EmployeeCivility } from '@goodwatt/shared/dist/types'
import {
  CheckEmailMutation,
  CheckEmailMutationVariables,
  Civility,
} from '../../__generated__/graphql'

const schema = yup.object().shape({
  email: yup
    .string()
    .email(i18n.t('forms.errors.emailRegex'))
    .required(i18n.t('forms.errors.required')),
  firstName: yup.string().required(i18n.t('forms.errors.required')),
  lastName: yup.string().required(i18n.t('forms.errors.required')),
  phoneNumber: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .length(10, i18n.t('forms.errors.phoneFormat')),
  password: yup
    .string()
    .matches(regex.passwordRegex, i18n.t('forms.errors.passwordRegex'))
    .required(i18n.t('forms.errors.required')),
  confirmPassword: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .oneOf([yup.ref('password')], i18n.t('forms.errors.passwordsNotIdentical')),
})

type Step3CompanyInputs = {
  email: string
  firstName: string
  lastName: string
  civility: Civility
  phoneNumber: string
  password: string
  confirmPassword: string
}

interface Step3Props {
  onNext: () => void
  onPrevious: () => void
  password: {
    password: string
    confirmPassword: string
  }
  setPassword: React.Dispatch<
    React.SetStateAction<{
      password: string
      confirmPassword: string
    }>
  >
}

const Step3: React.FC<Step3Props> = ({
  onNext,
  onPrevious,
  password,
  setPassword,
}) => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors, control } =
    useForm<Step3CompanyInputs>({
      resolver: yupResolver(schema),
    })
  const theme = useTheme()
  const form = useSelector((state: StoreState) => state.forms.companySignUpForm)
  const dispatch = useDispatch()
  const notifications = useNotifications()
  const [checkUserEmail, { loading }] = useMutation<
    CheckEmailMutation,
    CheckEmailMutationVariables
  >(MUTATION_CHECK_EMAIL)

  const onSubmit = (data: Step3CompanyInputs) => {
    dispatch(
      setCompanySignUpForm({
        email: data.email.toLowerCase(),
        firstName: data.firstName,
        lastName: data.lastName,
        civility: data.civility,
        phoneNumber: data.phoneNumber,
      }),
    )

    setPassword({
      password: data.password,
      confirmPassword: data.confirmPassword,
    })

    checkUserEmail({
      variables: { email: data.email },
    })
      .then(result => {
        if (result.data?.verifyUserEmail) onNext()
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <div css={{ width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="civility"
          control={control}
          render={({ onChange, value }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: 48,
                marginLeft: 21,
              }}
            >
              <Checkbox
                isChecked={value === EmployeeCivility.MISTER}
                label="Monsieur"
                size="medium"
                error={!!errors.civility}
                onClick={() => onChange(EmployeeCivility.MISTER)}
              ></Checkbox>
              <Checkbox
                isChecked={value === EmployeeCivility.MISTRESS}
                label="Madame"
                size="medium"
                error={!!errors.civility}
                onClick={() => onChange(EmployeeCivility.MISTRESS)}
              ></Checkbox>
            </div>
          )}
        />

        <div css={{ marginBottom: '24px' }} />

        <Input
          type="text"
          name="firstName"
          label={t('forms.label.firstName')}
          defaultValue={form.firstName}
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
          register={register}
        />
        <Input
          type="text"
          name="lastName"
          label={t('forms.label.lastName')}
          defaultValue={form.lastName}
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
          register={register}
        />

        <Input
          type="text"
          name="email"
          label={t('forms.label.email')}
          defaultValue={form.email}
          error={!!errors.email}
          helperText={errors.email?.message}
          register={register}
        />

        <Input
          type="text"
          name="phoneNumber"
          label={t('forms.label.companyPhone')}
          defaultValue={form.phoneNumber}
          error={!!errors.phoneNumber}
          helperText={errors.phoneNumber?.message}
          register={register}
          onlyNumbers
          maxLength={10}
        />

        <Input
          type="password"
          name="password"
          label={t('forms.label.password')}
          defaultValue={password.password}
          error={!!errors.password}
          helperText={errors.password?.message}
          register={register}
        />
        <Input
          type="password"
          name="confirmPassword"
          label={t('forms.label.confirmPassword')}
          defaultValue={password.confirmPassword}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword?.message}
          register={register}
        />

        <div
          css={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <div css={{ marginRight: 40, marginLeft: '-84px' /* -40 + -44 */ }}>
            <Button
              type="tertiary"
              onClick={onPrevious}
              icon="back"
              iconColor={theme.colors.primary}
            />
          </div>
          <Button loading={loading} type="primary" submit>
            {t('forms.button.next')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Step3
