/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'

import formsReducer from './forms'
import userReducer from './user'
import menuReducer from './menu'
import depositReducer from './deposit'

const SIGNOUT_REQUEST = 'user/logoutAction'

const appReducer = combineReducers({
  forms: formsReducer,
  user: userReducer,
  menu: menuReducer,
  deposit: depositReducer,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === SIGNOUT_REQUEST) {
    storage.removeItem('persist:root')
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export type StoreState = ReturnType<typeof appReducer>

export default rootReducer
