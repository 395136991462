import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import { UserRoles } from '../redux/user'
import { StoreState } from '../redux/rootReducer'

import Layout from '../components/Layout'

import publicRoutes from './Public'
import restrictedRoutes from './PublicRestricted'
import AnimatorRouting from './Animator'
import EmployeeRouting from './Employee'
import CompanyRouting from './Company'
import AdminstratorRouting from './Administrator'

interface PublicRouteType {
  restricted?: boolean
}

interface PrivateRouteType {
  protectedToRole: UserRoles
}

/**
 * Routes accessible to everybody, except if the user is authenticated
 * and the page is restricted
 */
export const PublicRoute: React.FC<PublicRouteType & RouteProps> = ({
  restricted = false,
  ...props
}) => {
  const user = useSelector((state: StoreState) => state.user)
  if (user.token && restricted && user.role) {
    if (user.role === 'COMPANY') return <Redirect to="/company/dashboard" />
    if (user.role === 'EMPLOYEE') return <Redirect to="/employee/profile" />
    if (user.role === 'ANIMATOR') return <Redirect to="/animator/dashboard" />
    if (user.role === 'ADMIN') return <Redirect to="/administrator/dashboard" />
  }
  return <Route {...props}>{props.children}</Route>
}

export const PrivateRoute: React.FC<PrivateRouteType & RouteProps> = ({
  protectedToRole,
  ...props
}) => {
  const user = useSelector((state: StoreState) => state.user)

  if (user.token === '' || user.role !== protectedToRole)
    return <Redirect to="/" />

  return <Route {...props}>{props.children}</Route>
}

const Routes: React.FC = () => {
  return (
    <Router>
      <Layout>
        <Switch>
          {publicRoutes.map((route, index) => (
            <PublicRoute key={index} exact={route.exact} path={route.path}>
              {route.main}
            </PublicRoute>
          ))}
          {restrictedRoutes.map((route, index) => (
            <PublicRoute
              restricted
              key={index}
              exact={route.exact}
              path={route.path}
            >
              {route.main}
            </PublicRoute>
          ))}
          {/* PORTALS */}
          <Route path="/animator">
            <AnimatorRouting />
          </Route>
          <Route path="/company">
            <CompanyRouting />
          </Route>
          <Route path="/employee">
            <EmployeeRouting />
          </Route>
          <Route path="/administrator">
            <AdminstratorRouting />
          </Route>
          {/* Page 404 */}
          <PublicRoute path="*">
            <p>page 404</p>
          </PublicRoute>
        </Switch>
      </Layout>
    </Router>
  )
}

export default Routes
