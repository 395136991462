/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Row } from 'react-table'
import {
  FleetModalSetting,
  FleetModalType,
  FleetsTableRow,
} from '../../../types/AnimatorFleets'
import React from 'react'
import { useTheme } from 'emotion-theming'
import Icon from '../../Icon'

interface FleetsActionCellProps {
  setModal: (settings: FleetModalSetting) => void
  row: Row<FleetsTableRow>
}

const FleetsActionCell: React.FC<FleetsActionCellProps> = ({
  setModal,
  row,
}) => {
  const theme = useTheme()

  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      modalType: FleetModalType,
    ) => {
      e.stopPropagation()
      setModal({ fleet: row.original, modalState: modalType })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <div
        css={{ cursor: 'pointer' }}
        onClick={e => handleClick(e, FleetModalType.EDIT)}
      >
        <Icon type="edit" color={theme.colors.gray2} />
      </div>
      <div
        css={{ cursor: 'pointer' }}
        onClick={e => handleClick(e, FleetModalType.DELETE)}
      >
        <Icon type="trash" />
      </div>
    </div>
  )
}

export default FleetsActionCell
