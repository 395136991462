import { GetCompanyReferrerQuery } from '../__generated__/graphql'

export enum CompanyReferrerTableModal {
  EDIT = 'edit',
  DELETE = 'delete',
  ADD = 'add',
  NONE = 'none',
}
export interface CompanyReferrerModalSettings<
  D = GetCompanyReferrerQuery['companyReferrer'],
  T = CompanyReferrerTableModal,
> {
  referrer: D | null
  actionName: T
}
