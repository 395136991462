/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'

export interface CardProps {
  children: React.ReactNode
  title?: JSX.Element
  css?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
  onClick?: () => void
}

const Card: React.FC<CardProps> = ({ children, title, onClick, ...props }) => {
  const theme = useTheme()

  return (
    <div
      css={{
        position: 'relative',
        width: '100%',
        borderRadius: '30px',
        boxShadow: '4px 8px 20px rgba(0, 0, 0, 0.15)',
        backgroundColor: theme.colors.white,
        display: 'flex',
        flexDirection: 'column',
        padding: '2.1rem',
        cursor: onClick ? 'pointer' : 'auto',
        marginRight: 24,
        ':last-child': { marginRight: 0 },
        [theme.media.desktop]: {
          marginBottom: 24,
        },
      }}
      onClick={e => {
        e.stopPropagation()
        if (onClick) onClick()
      }}
      // https://emotion.sh/docs/with-props
      {...props}
    >
      {title && title}
      {children}
    </div>
  )
}

export default Card
