/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { Controller, useForm } from 'react-hook-form'
import { useTheme } from 'emotion-theming'

import Modal from '../../../components/Modal'
import Popup from '../../../components/Popup'
import {
  NotificationTypes,
  useNotifications,
} from '../../../contexts/NotificationContext'
import Button from '../../../components/Button'
import i18n from '../../../i18n/config'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import {
  AdminTransferDeploymentToSiteMutation,
  AdminTransferDeploymentToSiteMutationVariables,
  GetCompaniesNamesQuery,
  GetCompaniesNamesQueryVariables,
  GetCompanyDeploymentsQuery,
  GetCompanyDeploymentsQueryVariables,
  SortOrder,
} from '../../../__generated__/graphql'
import { MUTATION_ADMIN_TRANSFER_DEPLOYMENT_TO_SITE } from '../../../utils/gql/mutations'
import {
  QUERY_COMPANIES_NAME,
  QUERY_GET_COMPANY_DEPLOYMENTS,
} from '../../../utils/gql/queries'
import InputSelect from '../../../components/InputSelect'
import { QUERY_COMPANIES_WITH_DEPLOYMENTS } from './components/CompanyDeploymentsList/useCompaniesWithDeployments'

const schema = yup.object().shape({
  deployment: yup.object().shape({
    value: yup.string().required(i18n.t('forms.errors.required')),
  }),

  site: yup.object().shape({
    value: yup.string().required(i18n.t('forms.errors.required')),
  }),
})

interface Form {
  deployment: {
    value: string
  }
  site: {
    value: string
  }
}

interface Props {
  onClose: () => void
  isOpen?: boolean
}

const AdminTransferDeploymentToSiteModal: React.FC<Props> = ({
  onClose,
  isOpen = true,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const notifications = useNotifications()
  const { handleSubmit, errors, setValue, control, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const { data: companies } = useQuery<
    GetCompaniesNamesQuery,
    GetCompaniesNamesQueryVariables
  >(QUERY_COMPANIES_NAME, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        archive: null,
      },
      orderBy: {
        name: SortOrder.Asc,
      },
    },
  })

  const [fromCompanyId, setFromCompanyId] = useState<string | null>(null)

  const { data: company } = useQuery<
    GetCompanyDeploymentsQuery,
    GetCompanyDeploymentsQueryVariables
  >(QUERY_GET_COMPANY_DEPLOYMENTS, {
    fetchPolicy: 'no-cache',
    skip: fromCompanyId == null,
    variables: {
      where: {
        id: fromCompanyId ?? '',
      },
    },
  })

  const deployments = useMemo(
    () =>
      company?.company?.sites
        .map(s => s.deployments)
        .flat()
        .map(d => ({
          label: d!.name,
          value: d!.id,
        })) ?? [],
    [company],
  )

  const deployment = watch('deployment')
  const deploymentSite = useMemo(
    () =>
      company?.company?.sites.find(s =>
        s.deployments.some(d => d?.id === deployment?.value),
      ),
    [company, deployment],
  )

  const sites = useMemo(
    () =>
      company?.company?.sites
        .map(s => ({
          label: s.name,
          value: s.id,
        }))
        .filter(s => s.value !== deploymentSite?.id) ?? [],
    [company, deploymentSite],
  )

  const [transferDeploymentToSite, { loading }] = useMutation<
    AdminTransferDeploymentToSiteMutation,
    AdminTransferDeploymentToSiteMutationVariables
  >(MUTATION_ADMIN_TRANSFER_DEPLOYMENT_TO_SITE, {
    refetchQueries: [
      {
        query: QUERY_COMPANIES_WITH_DEPLOYMENTS,
      },
    ],
  })

  const onSubmit = (inputData: Form) => {
    transferDeploymentToSite({
      variables: {
        deploymentId: inputData.deployment.value,
        siteId: inputData.site.value,
      },
    })
      .then(result => {
        if (result.data?.transferDeploymentToSite) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: i18n.t('shared.notification.transferDeploymentToSite'),
          })
          onClose()
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  if (companies == null) {
    return null
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        maxWidth={750}
        title={t(
          'animator.dashboard.adminActions.transferDeploymentToSite.modal.title',
        )}
        onClose={onClose}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginLeft: '-40px',
            }}
          >
            <Button type="tertiary" onClick={onClose}>
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginRight: 40 }} />
            <Button
              loading={loading}
              type="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {t('forms.button.confirm')}
            </Button>
          </div>
        }
      >
        <p
          css={{
            textAlign: 'center',
            whiteSpace: 'pre-line',
            marginBottom: 20,
          }}
        >
          {t(
            'animator.dashboard.adminActions.transferDeploymentToSite.modal.text',
          )}
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            css={{
              width: '100%',
              padding: '0 12px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minHeight: 300,
              [theme.media.mobile]: {
                padding: '0',
              },
            }}
          >
            <p>
              {t(
                'animator.dashboard.adminActions.transferSiteToCompany.labels.company',
              )}
            </p>
            <InputSelect
              options={companies.companies.map(c => ({
                label: c.name,
                value: c.id,
              }))}
              compact
              center
              onChange={value => {
                setValue('siteId', null)
                // @ts-ignore
                setFromCompanyId(value!.value)
              }}
            />

            <p>
              {t(
                'animator.dashboard.adminActions.transferSiteToCompany.labels.deployment',
              )}
            </p>
            <Controller
              name="deployment"
              control={control}
              render={({ onChange, value, name }) => (
                <InputSelect
                  name={name}
                  value={value}
                  onChange={onChange}
                  options={deployments}
                  compact
                  center
                  error={!!errors.siteId}
                  helperText={
                    errors.siteId ? t(errors.siteId.message) : undefined
                  }
                />
              )}
            />

            <p>
              {t(
                'animator.dashboard.adminActions.transferSiteToCompany.labels.toSite',
              )}
            </p>
            <Controller
              name="site"
              control={control}
              render={({ onChange, value, name }) => (
                <InputSelect
                  name={name}
                  value={value}
                  onChange={onChange}
                  options={sites}
                  compact
                  center
                  error={!!errors.companyId}
                  helperText={
                    errors.companyId ? t(errors.companyId.message) : undefined
                  }
                />
              )}
            />
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default AdminTransferDeploymentToSiteModal
