/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../../../redux/rootReducer'
import {
  PackageAccessoryType,
  StateOfUseType,
} from '@goodwatt/shared/dist/types'
import {
  PartsConditionModalSettings,
  PartsConditionTableModal,
  PartsConditionTableRow,
} from '../../../../../../types/AnimatorCompanyEmployees'
import { useTranslation } from 'react-i18next'

import PartsConditionActionCell from '../../../../../../components/Table/CustomCells/PartsConditionActionCell'
import { Column, Row } from 'react-table'
import { setReturnBikeForm } from '../../../../../../redux/forms'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../../../contexts/NotificationContext'
import Table from '../../../../../../components/Table'
import PartModal from '../Shared/PartModal'
import ReturnPackageAccessoryStarRatingCell from '../../../../../../components/Table/CustomCells/ReturnPackageAccessoryStarRatingCell'

const i18nLoanAccessoriesConditionNamespace =
  'animator.company.employees.selected.modals.employeeReturnBikeModal.returnPackagesCondition'

const initialModalSettings: PartsConditionModalSettings = {
  part: {
    id: '',
    name: '',
    stateOfUse: StateOfUseType.EXCELLENT,
    note: '',
    attachments: undefined,
  },
  actionName: PartsConditionTableModal.NONE,
}

const ReturnAccessoriesCondition: React.FC = () => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const dispatch = useDispatch()
  const { accessories, bikeLoanId } = useSelector(
    (state: StoreState) => state.forms.returnBikeForm,
  )

  const [currModal, setCurrModal] = React.useState(initialModalSettings)

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<PartsConditionTableRow> }) => (
      <PartsConditionActionCell setModal={setCurrModal} part={row.original} />
    ),
    [],
  )

  const ReturnPackageRatingCell = React.useCallback(
    ({ row }: { row: Row<PartsConditionTableRow> }) => (
      <ReturnPackageAccessoryStarRatingCell accessory={row.original} />
    ),
    [],
  )

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: t(
          `${i18nLoanAccessoriesConditionNamespace}.table.headers.accessory`,
        ) as string,
        accessor: 'formattedName',
        disableSortBy: true,
        maxWidth: 200,
        cellStyle: () => ({ textAlign: 'left' }),
      },
      {
        Header: t(
          `${i18nLoanAccessoriesConditionNamespace}.table.headers.conditionDescription`,
        ) as string,
        accessor: 'stateOfUse',
        disableSortBy: true,
        Cell: ReturnPackageRatingCell,
      },
      {
        Header: t(
          `${i18nLoanAccessoriesConditionNamespace}.table.headers.actions`,
        ) as string,
        accessor: 'action',
        disableSortBy: true,
        maxWidth: 95,
        Cell: ActionCell,
      },
    ],
    [t, ReturnPackageRatingCell, ActionCell],
  )

  const formattedData = React.useMemo(() => {
    return Object.entries(accessories).map(([accessoryName, value]) => ({
      id: value?.id,
      formattedName: t(`shared.loanPackageAccessoryLabel.${accessoryName}`),
      name: accessoryName,
      stateOfUse: value?.stateOfUse,
      action: '',
      note: value?.note,
      attachments: value?.attachments,
    }))
  }, [t, accessories])

  const onNoteModalSubmit = (textAreaValue: string, partName: string) => {
    dispatch(
      setReturnBikeForm({
        accessories: {
          ...accessories,
          [partName as PackageAccessoryType]: {
            ...accessories[partName as PackageAccessoryType],
            note: textAreaValue,
          },
        },
      }),
    )
    notifications.newNotification({
      type: NotificationTypes.SUCCESS,
      message: t('shared.notification.willUpdateOnEnding'),
    })
    setCurrModal(initialModalSettings)
  }
  const partModalTitle = React.useMemo(() => {
    const partName = t(
      `shared.loanPackageAccessoryLabel.${currModal.part.name}`,
    )
    return `${t(
      `${i18nLoanAccessoriesConditionNamespace}.bikeLoanAccessoryModal.title`,
      { partName },
    )}`
  }, [currModal.part.name, t])

  return (
    <React.Fragment>
      <div css={{ minHeight: 300, padding: '0 25px' }}>
        <Table
          columns={columns}
          data={formattedData}
          noDataText={t(
            'animator.company.employees.selected.modals.employeeReturnBikeModal.returnPackagesCondition.table.noDataText',
          )}
        />
      </div>
      <PartModal
        isOpen={
          currModal.actionName === PartsConditionTableModal.NOTE ||
          currModal.actionName === PartsConditionTableModal.CAMERA
        }
        shouldOpenCameraInput={
          currModal.actionName === PartsConditionTableModal.CAMERA
        }
        closeAutomaticCameraInput={() =>
          setCurrModal({
            ...currModal,
            actionName: PartsConditionTableModal.NOTE,
          })
        }
        setFormAction={setReturnBikeForm}
        closeModal={() => setCurrModal(initialModalSettings)}
        title={partModalTitle}
        componentType="loanPackage"
        bikeLoanId={bikeLoanId}
        label={t(`${i18nLoanAccessoriesConditionNamespace}.bikeLoanAccessoryModal.noteLabel`)} //prettier-ignore
        part={currModal.part}
        onSubmit={onNoteModalSubmit}
      />
    </React.Fragment>
  )
}

export default ReturnAccessoriesCondition
