/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react'
import {
  DocumentNode,
  FetchPolicy,
  OperationVariables,
  useApolloClient,
} from '@apollo/client'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function usePromiseLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  fetchPolicy?: FetchPolicy | undefined,
) {
  const client = useApolloClient()

  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        variables: variables,
        fetchPolicy,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client],
  )
}

export default usePromiseLazyQuery
