import Icon from '../../../../components/Icon'
import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  GenerateRemissionOrderMutation,
  GenerateRemissionOrderMutationVariables,
} from '../../../../__generated__/graphql'
import Tooltip from '../../../../components/Tooltip'

const MUTATION_GENERATE_REMISSION_ORDER = gql`
  mutation GenerateRemissionOrder($employeeId: String!, $bikeLoanId: String!) {
    debugGenerateRemissionOrder(
      employeeId: $employeeId
      bikeLoanId: $bikeLoanId
    )
  }
`

export interface GenerateRemissionOrderButtonProps {
  employeeId: string
  bikeLoanId: string
}

export const GenerateRemissionOrderButton: React.FC<
  GenerateRemissionOrderButtonProps
> = ({ employeeId, bikeLoanId }) => {
  const { t } = useTranslation()
  const [mutate] = useMutation<
    GenerateRemissionOrderMutation,
    GenerateRemissionOrderMutationVariables
  >(MUTATION_GENERATE_REMISSION_ORDER, {
    refetchQueries: ['GetDeploymentEmployees'],
  })

  return (
    <button
      onClick={async () => {
        await mutate({
          variables: {
            employeeId: employeeId,
            bikeLoanId: bikeLoanId,
          },
        })
      }}
    >
      <Tooltip
        title={t('shared.button.debugGenerateRemissionOrder')}
        placement="top-end"
      >
        <Icon type="remissionOrder" color="red" />
      </Tooltip>
    </button>
  )
}
