/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useOrderBy } from '../../../hooks/useOrderBy'
import { useTranslation } from 'react-i18next'
import { Column } from 'react-table'
import Table from '../../../components/Table'
import { useQuery } from '@apollo/client'
import { QUERY_COMPANY_EMPLOYEES_DEPLOYMENTS } from '../../../utils/gql/queries'
import {
  DeploymentType,
  GetCompanyEmployeesDeploymentsQuery,
  GetCompanyEmployeesDeploymentsQueryVariables,
  QueryMode,
  EmployeeDeploymentStatus,
} from '../../../__generated__/graphql'

import { employeeNameFormatter } from '@goodwatt/client/src/utils/formatter'
import Icon from '../../../components/Icon'
import { DeploymentTypeIcon } from '../../Animator/Shared/DeploymentTypeIcon'
import { Link } from 'react-router-dom'

type EmployeeOrderByField =
  | 'name'
  | 'site'
  | 'deployments'
  | 'mensual'
  | 'status'
  | 'offer'

export interface ListProps {
  userId: string
  filters: Record<string, unknown>
  origin: string
}

export const List: React.FC<ListProps> = ({ filters, userId, origin }) => {
  const { orderBy, getTableProps } = useOrderBy()
  const { t } = useTranslation()

  const where = React.useMemo(
    () => ({
      AND: [
        {
          // company: {
          //   user: {
          //     id: { equals: userId }
          //   }
          // },
          company: {
            companyReferrers: {
              some: {
                user: {
                  id: {
                    equals: userId,
                  },
                },
              },
            },
          },
        },
        filters.search
          ? {
              OR: [
                {
                  firstName: {
                    contains: filters.search as string,
                    mode: 'insensitive' as QueryMode,
                  },
                },
                {
                  lastName: {
                    contains: filters.search as string,
                    mode: 'insensitive' as QueryMode,
                  },
                },
              ],
            }
          : {},
        filters.deploymentId && filters.deploymentId !== 'all'
          ? {
              employeeDeployments: {
                some: {
                  deploymentId: {
                    equals: filters.deploymentId as string,
                  },
                },
              },
            }
          : {},
        filters.siteId && filters.siteId !== 'all'
          ? {
              sites: {
                some: {
                  id: {
                    equals: filters.siteId as string,
                  },
                },
              },
            }
          : {},
        filters.status && filters.status === 'selected'
          ? {
              employeeDeployments: {
                some: {
                  status: {
                    equals: EmployeeDeploymentStatus.Selected,
                  },
                },
              },
            }
          : {},
        filters.status && filters.status === 'candidate'
          ? {
              employeeDeployments: {
                some: {
                  status: {
                    equals: EmployeeDeploymentStatus.Candidate,
                  },
                },
              },
            }
          : {},
        filters.status && filters.status === 'beneficiary'
          ? {
              employeeDeployments: {
                some: {
                  status: {
                    equals: EmployeeDeploymentStatus.Beneficiary,
                  },
                },
              },
            }
          : {},
        filters.status && filters.status === 'registered'
          ? {
              AND: [
                {
                  employeeDeployments: {
                    none: {
                      status: {
                        equals: EmployeeDeploymentStatus.Selected,
                      },
                    },
                  },
                },
                {
                  employeeDeployments: {
                    none: {
                      status: {
                        equals: EmployeeDeploymentStatus.Candidate,
                      },
                    },
                  },
                },
                {
                  employeeDeployments: {
                    none: {
                      status: {
                        equals: EmployeeDeploymentStatus.Beneficiary,
                      },
                    },
                  },
                },
              ],
            }
          : {},
        filters.status && filters.status === 'validated'
          ? {
              documentsVerified: {
                equals: true,
              },
            }
          : {},
      ],
    }),
    [filters, userId],
  )

  const { data, loading } = useQuery<
    GetCompanyEmployeesDeploymentsQuery,
    GetCompanyEmployeesDeploymentsQueryVariables
  >(QUERY_COMPANY_EMPLOYEES_DEPLOYMENTS, {
    variables: {
      where: where,
    },
  })

  const formattedData = React.useMemo(() => {
    if (!data) {
      return []
    }

    let result = data.employees.map(employee => {
      return {
        id: employee?.id,
        name: employeeNameFormatter(employee?.firstName, employee?.lastName),
        site: siteName(employee?.sites),
        deployments: deploymentDetails(employee, t, filters),
        mensual: mensuality(employee),
        offer: offer(employee, filters),
        status: getStatus(employee, t, filters),
        action: getAction(employee),
        profileCompleted: employee?.hasCompletedProfile ? (
          <Icon type="tick" color="transparent" />
        ) : (
          ''
        ),
      }
    })

    for (const [key, value] of Object.entries(orderBy || [])) {
      const castKey = key as EmployeeOrderByField

      if (castKey === 'name') {
        result = result.sort((a, b) => {
          return a.name.localeCompare(b.name) * (value === 'asc' ? 1 : -1)
        })
      } else if (castKey === 'site') {
        result = result.sort((a, b) => {
          return a.site.localeCompare(b.site) * (value === 'asc' ? 1 : -1)
        })
      } else if (castKey === 'deployments') {
        result = result.sort((a, b) => {
          return (
            a.deployments.localeCompare(b.deployments) *
            (value === 'asc' ? 1 : -1)
          )
        })
      } else if (castKey === 'mensual') {
        result = result.sort((a, b) => {
          return (
            parseInt(a.mensual) -
            parseInt(b.mensual) * (value === 'asc' ? 1 : -1)
          )
        })
        // } else if (castKey === 'status') {
        //   result = result.sort((a, b) => {
        //     return a.status.localeCompare(b.status) * (value === 'asc' ? 1 : -1)
        //   })
      }
    }
    return result
  }, [data, t, orderBy, filters])

  const columns = React.useMemo<Column[]>(() => {
    let columnValues = [
      {
        Header: t('company.employees.table.headers.name') as string,
        accessor: 'name',
        maxWidth: 200,
      },
      {
        Header: t('company.employees.table.headers.site') as string,
        accessor: 'site',
      },
      {
        Header: t('company.employees.table.headers.deployment') as string,
        accessor: 'deployments',
        maxWidth: 250,
        cellStyle: () => ({
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }),
      },
      {
        Header: t('company.employees.table.headers.mensual') as string,
        accessor: 'mensual',
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: t('company.employees.table.headers.status') as string,
        accessor: 'status',
        disableSortBy: true,
      },
      {
        Header: t('animator.companies.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        cellStyle: () => ({ textAlign: 'center' }),
      },
    ]

    if (origin === 'employee') {
      columnValues.splice(3, 1, {
        Header: t('animator.companies.table.headers.offer') as string,
        accessor: 'offer',
        disableSortBy: true,
        cellStyle: () => ({ textAlign: 'center' }),
      })

      columnValues.splice(5, 1, {
        Header: t('animator.companies.table.headers.profile') as string,
        accessor: 'profileCompleted',
        disableSortBy: true,
        cellStyle: () => ({ textAlign: 'center' }),
      })
    }

    return columnValues
  }, [t, origin])

  return (
    <div css={{ minHeight: 380 }}>
      <Table
        {...getTableProps()}
        columns={columns}
        data={formattedData}
        loading={loading}
      />
    </div>
  )
}

const siteName = (sites: any): string => {
  if (!sites || sites.length === 0) {
    return '-'
  }

  if (sites.length === 1) {
    return sites[0].name
  } else {
    return sites.map((site: any) => site.name).join(', ')
  }
}

const deploymentDetails = (
  employee: any,
  t: any,
  filters: Record<string, unknown>,
): string => {
  let { employeeDeployments } = employee

  if (filters.deploymentId && filters.deploymentId !== 'all') {
    employeeDeployments = employeeDeployments.filter(
      (employeeDeployment: any) =>
        employeeDeployment.deployment.id === filters.deploymentId,
    )
  }
  if (!employeeDeployments || employeeDeployments.length === 0) {
    return '-'
  }

  if (employeeDeployments.length === 1) {
    return employeeDeployments[0].deployment.name
  } else {
    return `${employeeDeployments.length} ${t(
      'company.employees.table.headers.runningDeployments',
    )}`
  }
}

const getStatus = (
  employee: any,
  t: any,
  filters: Record<string, unknown>,
): JSX.Element => {
  let result = (
    <div css={{ marginBottom: '4px' }}>
      {t('company.employees.table.employeeStatus.noValue')}
    </div>
  )
  let { employeeDeployments } = employee

  if (filters.deploymentId && filters.deploymentId !== 'all') {
    employeeDeployments = employeeDeployments.filter(
      (employeeDeployment: any) =>
        employeeDeployment.deployment.id === filters.deploymentId,
    )
  }

  if (employeeDeployments && employeeDeployments.length > 0) {
    return employeeDeployments.map((employeeDeployment: any) => {
      switch (employeeDeployment.status) {
        case EmployeeDeploymentStatus.Selected:
          return (
            <div css={{ marginBottom: '4px' }}>
              {t('company.employees.table.employeeStatus.selected')}
            </div>
          )
        case EmployeeDeploymentStatus.Candidate:
          return (
            <div css={{ marginBottom: '4px' }}>
              {t('company.employees.table.employeeStatus.candidate')}
            </div>
          )
        case EmployeeDeploymentStatus.Beneficiary:
          return (
            <div css={{ marginBottom: '4px' }}>
              {t('company.employees.table.employeeStatus.beneficiary')}
            </div>
          )
        default:
          return (
            <div css={{ marginBottom: '4px' }}>
              {t('company.employees.table.employeeStatus.noValue')}
            </div>
          )
      }
    })
  }

  return result
}

const mensuality = (employee: any): string => {
  const { employeeDeployments } = employee

  if (!employeeDeployments || employeeDeployments.length === 0) {
    return '-'
  }

  const bikeModelIds = employeeDeployments.map((employeeDeployment: any) => {
    return employeeDeployment.chosenBikeModelId
  })

  if (!bikeModelIds || bikeModelIds.length === 0) {
    return '-'
  }

  const deployments = employeeDeployments.map(
    (employeeDeployment: any) => employeeDeployment.deployment,
  )

  const mensual = deployments.reduce((acc: number, deployment: any) => {
    if (!deployment.bikeModelPrices) {
      return acc
    }

    deployment.bikeModelPrices.map((bikeModelPrice: any) => {
      if (bikeModelIds.includes(bikeModelPrice.id)) {
        acc += parseFloat(bikeModelPrice.remainingPrice)
      }
    })

    return acc
  }, 0)

  return mensual.toString() + ' €'
}

const offer = (
  employee: any,
  filters: Record<string, unknown>,
): JSX.Element => {
  let { employeeDeployments } = employee

  if (filters.deploymentId && filters.deploymentId !== 'all') {
    employeeDeployments = employeeDeployments.filter(
      (employeeDeployment: any) =>
        employeeDeployment.deployment.id === filters.deploymentId,
    )
  }

  if (!employeeDeployments || employeeDeployments.length === 0) {
    return <span></span>
  }

  return employeeDeployments.map((employeeDeployment: any) => (
    <div css={{ marginBottom: '4px' }}>
      <DeploymentTypeIcon
        type={employeeDeployment.deployment.deploymentType as DeploymentType}
      />
    </div>
  ))
}

const getAction = (employee: any): JSX.Element => {
  if (employee.remissionOrderUrl) {
    return (
      <Link
        to="#"
        onClick={() => window.open(employee.remissionOrderUrl, '_blank')}
      >
        <Icon type="remissionOrder" color="transparent" />
      </Link>
    )
  }

  return <span></span>
}

export default List
