export enum AdviceTableModalActions {
  EDIT = 'edit',
  TRASH = 'trash',
  VIEW = 'view',
}

export enum AdviceTableAdd {
  ADD = 'add',
}

export interface TagTableRow {
  id: string
  key: string
}

export interface AdviceSettings<
  D = AdviceTableRow,
  T = AdviceTableModalActions | AdviceTableAdd,
> {
  advice: D
  actionName: '' | T
}

export interface AdviceTableRow {
  id: string
  title: string
  order: number | undefined
  content: string
  MIME: string
  tags: TagTableRow[]
}

export interface ModalAdviceSubmit<T = { name: string; value: string }> {
  title: string
  content: string
  order: number
  MIME: string
  tags: { value: string; label: string }
}
