/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'
import { Flex } from '../../../components/Flex'
import Card from '../../../components/Card'
import { useFilters } from '../../../hooks/useFilters'
import Input from '../../../components/Input'
import InputSelect from '../../../components/InputSelect'
import { TextVariant } from '../../../hooks/useVariant/variants/text'
import { SelectVariant } from '../../../hooks/useVariant/variants/select'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import { List } from '../../Shared/CompanyEmployees/List'
import { useCompanySitesWithDeployments } from '../../Shared/hooks/useCompanySitesWithDeployments'

interface Filters {
  status: string
  search: string
  siteId: string
  deploymentId: string
}

const CompanyEmployees: React.FC = () => {
  const companyId = useSelector(
    (state: StoreState) => state.user.companyId,
  ) as string
  const { t } = useTranslation()
  const userId = useSelector((state: StoreState) => state.user.id)

  const { data } = useCompanySitesWithDeployments(companyId)

  const sitesOptions = React.useMemo(
    () => [
      {
        value: 'all',
        label: t('animator.companies.filter.options.all'),
      },
      ...(data?.sites?.map(site => ({
        value: site.id,
        label: site.name,
      })) || []),
    ],
    [data, t],
  )

  const deploymentOptions = React.useMemo(
    () => [
      {
        value: 'all',
        label: t('animator.companies.filter.options.all'),
      },
      ...(data?.sites?.flatMap(site =>
        site.deployments.map(deployment => ({
          value: deployment.id,
          label: deployment.name,
        })),
      ) || []),
    ],
    [data, t],
  )

  const { filters, register } = useFilters<Filters>()

  const employeeStatusOptions = React.useMemo(
    () => [
      {
        value: 'all',
        label: t('animator.companies.filter.options.all'),
      },
      {
        value: 'candidate',
        label: t(
          'company.employees.filters.testingMonth.options.profileCompleted',
        ),
      },
      {
        value: 'selected',
        label: t('company.employees.filters.testingMonth.options.selected'),
      },
      {
        value: 'beneficiary',
        label: t('company.employees.filters.testingMonth.options.beneficiary'),
      },
      {
        value: 'registered',
        label: t('company.employees.filters.testingMonth.options.registered'),
      },
    ],
    [t],
  )

  return (
    <Fragment>
      <Card
        title={
          <span
            css={{
              fontWeight: 'bold',
              fontSize: '1.8rem',
              lineHeight: '20px',
              fontFamily: 'Raleway',
            }}
          >
            {t('company.card.employees')}
          </span>
        }
      >
        <Flex
          direction="row"
          gap="16px"
          align="end"
          css={{ marginTop: '16px', marginBottom: '16px' }}
        >
          <div css={{ flex: 1 }}>
            <InputSelect
              variant={SelectVariant.white}
              label={t(`company.tables.filters.sites`)}
              withError={false}
              defaultValue="all"
              options={sitesOptions}
              {...register('siteId')}
            />
          </div>

          <div css={{ flex: 1 }}>
            <InputSelect
              variant={SelectVariant.white}
              label={t(`company.tables.filters.status`)}
              withError={false}
              defaultValue="all"
              options={employeeStatusOptions}
              {...register('status')}
            />
          </div>

          <div css={{ flex: 1 }}>
            <InputSelect
              variant={SelectVariant.white}
              label={t(`company.tables.filters.deployment`)}
              withError={false}
              defaultValue="all"
              options={deploymentOptions}
              {...register('deploymentId')}
            />
          </div>

          <div css={{ flex: 2 }}>
            <Input
              icon="magnifier"
              variant={TextVariant.white}
              withError={false}
              {...register('search')}
            />
          </div>
        </Flex>

        <List userId={userId} filters={filters} origin="employee" />
      </Card>
    </Fragment>
  )
}

export default CompanyEmployees
