import { useMemo } from 'react'
import { get } from 'react-hook-form'

export interface LocalFilter<T> {
  value: unknown
  apply?: (value: T, filter: any) => boolean
}

export const useLocalFilters = <T>(
  items: T[] | undefined,
  filters: Record<string, LocalFilter<T>>,
): T[] | undefined => {
  return useMemo(() => {
    if (items == null) return items
    let filtered = items
    for (const [key, { value, apply }] of Object.entries(filters)) {
      if (value == null) continue
      // Special case for NO_VALUE to filter out null, undefined, and empty strings
      if (value === 'NO_VALUE') {
        filtered = filtered.filter(
          v =>
            get(v, key) === null ||
            get(v, key) === undefined ||
            get(v, key) === '',
        )
      } else if (apply) {
        filtered = filtered.filter(v => apply(v, value))
      } else {
        filtered = filtered.filter(v => get(v, key) === value)
      }
    }
    return filtered
  }, [items, filters])
}
