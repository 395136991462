import { gql, QueryResult, useQuery } from '@apollo/client'
import {
  GetCompanyAllReferrerQuery,
  GetCompanyAllReferrerQueryVariables,
} from '../../../../__generated__/graphql'

const QUERY_COMPANY_ALL_REFERRERS = gql`
  query GetCompanyAllReferrer($companyId: String!) {
    companyAllReferrers(companyId: $companyId) {
      id
      firstName
      lastName
      civility
      email
      phoneNumber
    }
  }
`

export const useCompanyAllReferrer = (
  companyId: string,
): QueryResult<
  GetCompanyAllReferrerQuery,
  GetCompanyAllReferrerQueryVariables
> => {
  return useQuery(QUERY_COMPANY_ALL_REFERRERS, {
    variables: { companyId },
  })
}
