/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { UserRoles } from '../redux/user'
import { UserState, logoutAction } from '../redux/user'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useWindowSize from '../hooks/useWindowSize'
import { useTheme } from 'emotion-theming'
import { IconTypes } from './Icon'
import Icon from './Icon'
import { Link } from 'react-router-dom'

interface UserMenuProps {
  closeMenu: () => void
  user: UserState
}

interface NavItem {
  icon: IconTypes
  title: string
  url: string
}

const UserMenu: React.FC<UserMenuProps> = ({ closeMenu, user }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const { width } = useWindowSize()

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen)
  }

  const onLogoutClick = React.useCallback(() => {
    dispatch(logoutAction())
    closeMenu()
    return history.push('/')
  }, [closeMenu, dispatch, history])

  const urlProfile = (userRoleName: any): NavItem => {
    switch (userRoleName) {
      case UserRoles.ANIMATOR:
        return {
          icon: 'profile',
          title: t('animator.menu.profile'),
          url: '/animator/profile',
        }
      case UserRoles.COMPANY:
        return {
          icon: 'profile',
          title: t('company.menu.profile'),
          url: '/company/profile',
        }
      case UserRoles.ADMIN:
        return {
          icon: 'profile',
          title: t('animator.menu.profile'),
          url: '/administrator/profile',
        }
      case UserRoles.EMPLOYEE:
        return {
          icon: 'profile',
          title: t('employee.menu.profile'),
          url: '/employee/profile',
        }
      default:
        return {
          icon: 'profile',
          title: t('animator.menu.profile'),
          url: '/animator/profile',
        }
    }
  }

  const menuItem = urlProfile(user.role)

  return (
    <div
      css={{
        position: 'relative',
        minWidth: '200px',
      }}
    >
      {width >= theme.breakpoints.tablet ? (
        <button
          onClick={toggleSubMenu}
          css={{
            width: '100%',
            height: '48px',
            border: '1px solid',
            borderRadius: '30px',
            borderColor: theme.colors.primary,
            color: theme.colors.primary,
            backgroundColor: isSubMenuOpen ? 'white' : 'transparent',
            padding: '10px',
            position: 'relative',
            zIndex: 1001,
          }}
        >
          <div
            css={{
              position: 'absolute',
              left: '0px',
              top: '0px',
              border: '1px solid',
              borderColor: theme.colors.primary,
              backgroundColor: 'white',
              borderRadius: '50%',
              width: '47px',
              height: '47px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon
              css={{
                position: 'relative',
              }}
              type="avatar"
              color={theme.colors.gray3}
              customSize={{ width: '32px', height: '36px' }}
            />
          </div>

          <div
            css={{
              paddingLeft: '40px',
              fontWeight: 'bold',
            }}
          >
            {user.name}
          </div>
        </button>
      ) : (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Link
            to={menuItem.url}
            onClick={closeMenu}
            css={{
              margin: '0 0 20px',
            }}
          >
            <Icon
              type={menuItem.icon}
              customSize={{ width: 24, height: 24 }}
              color={theme.colors.gray2}
            />
            <span css={{ cursor: 'pointer', marginLeft: '1rem' }}>
              {menuItem.title}
            </span>
          </Link>
          <button onClick={onLogoutClick}>
            <Icon
              type="logout"
              customSize={{ width: 24, height: 24 }}
              color={theme.colors.gray2}
            />
            <span css={{ cursor: 'pointer', marginLeft: '1rem' }}>
              {t('shared.auth.logout').toUpperCase()}
            </span>
          </button>
        </div>
      )}

      {isSubMenuOpen && (
        <ul
          css={{
            width: '100%',
            marginTop: '-18px',
            zIndex: 1000,
            backgroundColor: 'white',
            position: 'absolute',
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderBottom: '1px solid',
            borderColor: theme.colors.primary,
            borderRadius: '0 0 30px 30px',
            paddingBottom: '15px',
            paddingTop: '30px',
            textTransform: 'initial',
            boxShadow: '4px 8px 20px rgba(0,0,0,0.15)',
          }}
        >
          <li
            css={{
              margin: '15px 20px',
            }}
          >
            <Link to={menuItem.url} onClick={toggleSubMenu}>
              <Icon
                type={menuItem.icon}
                customSize={{ width: 16, height: 16 }}
                color={theme.colors.gray2}
              />
              <span css={{ cursor: 'pointer', marginLeft: '1rem' }}>
                {menuItem.title}
              </span>
            </Link>
          </li>
          <li
            css={{
              margin: '15px 20px',
            }}
          >
            <button onClick={onLogoutClick}>
              <Icon
                type="close"
                customSize={{ width: 16, height: 16 }}
                color={'red'}
              />
              <span css={{ cursor: 'pointer', marginLeft: '1rem' }}>
                {t('shared.auth.logout')}
              </span>
            </button>
          </li>
        </ul>
      )}
    </div>
  )
}

export default UserMenu
