/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { CompanySitesList } from '../../Shared/components/CompanySitesList'
import { useCurrentCompanyId } from './hooks/useCurrentCompanyId'
import { useCompany } from './hooks/useCompany'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import { UserRoles } from '@goodwatt/shared/dist/types'
import { Flex } from '../../../components/Flex'
import { Link } from 'react-router-dom'
import Icon from '../../../components/Icon'
import theme from '../../../styles/theme'

export const Progress: React.FC = () => {
  const companyId = useCurrentCompanyId()
  const { data } = useCompany(companyId, true)
  const { role: userRole } = useSelector((state: StoreState) => state.user)
  const isAdmin = userRole === UserRoles.ADMIN
  const linkTo = isAdmin ? '/administrator/companies' : '/animator/companies'

  return (
    <Flex direction="column" gap="32px">
      {data?.company && (
        <Flex direction="row" align="center" gap="16px">
          <h2
            css={{
              color: theme.colors.primary,
              fontSize: '20px',
              fontWeight: 500,
            }}
          >
            {data.company.name}
          </h2>
          <Link
            css={{
              background: theme.colors.primary,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '24px',
              height: '24px',
              borderRadius: '100%',
            }}
            to={linkTo}
          >
            <Icon size="small" type="close" color="white" />
          </Link>
        </Flex>
      )}
      <CompanySitesList
        getDeploymentLink={deployment =>
          isAdmin
            ? `/administrator/dashboard/${deployment.id}/progress`
            : `/animator/dashboard/${deployment.id}/progress`
        }
        companyId={companyId}
      />
    </Flex>
  )
}

export default Progress
