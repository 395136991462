"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceAlreadyExists = exports.CannotIncreaseCompanyStep = exports.MissingInput = exports.CompanyWrongStepError = exports.ForbiddenError = exports.ErrorOccured = exports.EmailIsNotVerified = exports.UniqueConstraint = exports.AlreadyTaken = exports.EmailSendingError = exports.NotAutorisedError = exports.ResourceNotFound = exports.MirioApiError = exports.FatalMinioError = exports.FatalPrismaError = exports.WrongCredentialsError = exports.FieldValidationError = void 0;
const helpers_1 = require("./helpers");
exports.FieldValidationError = (fieldName) => Error(`WRONG_FORMAT_FOR_FIELD_${fieldName.toUpperCase()}`);
exports.WrongCredentialsError = () => Error(`INVALID_CREDENTIALS`);
exports.FatalPrismaError = () => Error(`FATAL_PRISMA_ERROR`);
exports.FatalMinioError = () => Error(`FATAL_MINIO_ERROR`);
exports.MirioApiError = (reason) => Error(`MIRIO_ERROR${reason ? `_${helpers_1.toSnakeCase(reason, true)}` : ''}`);
exports.ResourceNotFound = (contextName) => Error(`${contextName ? contextName.toUpperCase() : 'RESOURCE'}_NOT_FOUND`);
exports.NotAutorisedError = () => new Error('NOT_AUTHORIZED');
exports.EmailSendingError = () => Error('FAIL_SENDING_EMAIL');
exports.AlreadyTaken = (field) => Error(`${field.toUpperCase()}_ALREADY_TAKEN`);
exports.UniqueConstraint = (field) => Error(`Unique constraint failed on the fields: (\`${field}\`)`);
exports.EmailIsNotVerified = () => Error(`EMAIL_IS_NOT_VERIFIED`);
exports.ErrorOccured = () => Error('AN_ERROR_OCCURED');
exports.ForbiddenError = (reason) => Error(`FORBIDDEN_OPERATION${reason ? `_${helpers_1.toSnakeCase(reason, true)}` : ''}`);
exports.CompanyWrongStepError = () => exports.ForbiddenError('wrong company program step');
exports.MissingInput = (field) => Error(`MISSING_INPUT_${field.toUpperCase()}`);
exports.CannotIncreaseCompanyStep = (deploymentStep) => Error(`CANNOT_INCREASE_COMPANY_STEP_${deploymentStep}`);
exports.ResourceAlreadyExists = (ressource) => Error(`${ressource.toUpperCase()}_ALREADY_EXISTS`);
