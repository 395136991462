/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '../../../../../../../../components/Icon'

interface AuthPassedProps {
  onExitClick: () => void
}

const SignPaper: React.FC<AuthPassedProps> = ({ onExitClick }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Icon type="coloredThumbsUp" customSize={{ width: 100, height: 100 }} />
        <div css={{ marginBottom: 20 }} />
        <div
          css={{
            maxWidth: 600,
            fontSize: 35,
            textAlign: 'center',
            lineHeight: '35px',
          }}
        >
          <p>
            {t(
              'animator.company.employees.selected.modals.employeeLoanBikeModal.employeeLoanValidation.signPaper.main.enjoy',
            )}
          </p>
        </div>
        <div css={{ marginBottom: 70 }} />
        <span
          css={{
            maxWidth: 600,
            fontSize: 22,
            lineHeight: '25px',
          }}
        >
          {t(
            'animator.company.employees.selected.modals.employeeLoanBikeModal.employeeLoanValidation.signPaper.thanks',
          )}
        </span>
      </div>
      <span
        css={{
          position: 'fixed',
          bottom: '20px',
          color: theme.colors.gray3,
          textDecorationLine: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => onExitClick()}
      >
        {t(
          'animator.company.employees.selected.modals.employeeLoanBikeModal.employeeLoanValidation.animatorLogBack',
        )}
      </span>
    </div>
  )
}

export default SignPaper
