import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import { UserRoles } from '../redux/user'

import { PrivateRoute } from './index'

import EmployeeDocuments from '../pages/Employee/Documents'
import EmployeePosts from '../pages/Employee/Posts'
import EmployeeProfile from '../pages/Employee/Profile'
import EmployeeLoanMonitoring from '../pages/Employee/LoanMonitoring'
import EmployeeOnBoarding from '../pages/Employee/OnBoarding'
import EmployeeApplication from '../pages/Employee/Application'
import EmployeeDeployments from '../pages/Employee/Deployments'

const routes = [
  {
    path: '/application/:deploymentId',
    exact: false,
    main: <EmployeeApplication />,
  },
  {
    path: '/dashboard',
    exact: false,
    main: <EmployeeDeployments />,
  },
  {
    path: '/on-boarding',
    exact: false,
    main: <EmployeeOnBoarding />,
  },
  {
    path: '/profile',
    exact: false,
    main: <EmployeeProfile />,
  },
  {
    path: '/documents',
    exact: false,
    main: <EmployeeDocuments />,
  },
  {
    path: '/help',
    exact: false,
    main: <EmployeePosts />,
  },
  {
    path: '/monitoring',
    exact: false,
    main: <EmployeeLoanMonitoring />,
  },
]

const EmployeeRouting: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      {routes.map((route, index) => (
        <PrivateRoute
          protectedToRole={UserRoles.EMPLOYEE}
          key={`${path}-${index}`}
          exact={route.exact}
          path={`${path}${route.path}`}
        >
          {route.main}
        </PrivateRoute>
      ))}
    </Switch>
  )
}

export default EmployeeRouting
