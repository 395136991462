/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import AuthContainer from '../../components/AuthContainer'
import Step1 from './Step1'
import StepSite from './StepSite'
import StepReferer from './StepReferer'
import Step4 from './Step4'
import SuccessStep from './SuccessStep'
import AuthenticationCard from '../../components/Card/AuthenticationCard'

const SignUpCompany: React.FC = () => {
  const { t } = useTranslation()
  const [step, setStep] = React.useState<number>(0)
  const [password, setPassword] = useState<{
    password: string
    confirmPassword: string
  }>({
    password: '',
    confirmPassword: '',
  })

  const onNext = () => setStep(step + 1)

  const onPrevious = () => setStep(step - 1)

  return (
    <AuthContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('auth.signupCompany.metaTitle')}</title>
        <link rel="canonical" href="https://goodwatt.fr/signup-company" />
      </Helmet>
      <AuthenticationCard
        title={
          step === 0
            ? t('auth.signupCompany.step1Title')
            : step === 1
            ? t('auth.signupCompany.step2Title')
            : step === 2
            ? t('auth.signupCompany.step3Title')
            : step === 4
            ? t('auth.signupCompany.stepSuccessTitle')
            : t('auth.signupCompany.step4Title')
        }
      >
        {step === 0 ? (
          <Step1 onNext={onNext} />
        ) : step === 1 ? (
          <StepReferer
            onNext={onNext}
            onPrevious={onPrevious}
            password={password}
            setPassword={setPassword}
          />
        ) : step === 2 ? (
          <StepSite onNext={onNext} onPrevious={onPrevious} />
        ) : step === 3 ? (
          <Step4 onNext={onNext} onPrevious={onPrevious} password={password} />
        ) : step === 4 ? (
          <SuccessStep />
        ) : null}
      </AuthenticationCard>
    </AuthContainer>
  )
}

export default SignUpCompany
