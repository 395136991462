/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { CardCollapsible } from '../../../components/Card/Collapsible'
import Icon, { IconTypes } from '../../../components/Icon'
import Card from '../../../components/Card'
import CardTitle from '../../../components/Card/CardTitle'
import { useTranslation } from 'react-i18next'
import { Flex } from '../../../components/Flex'
import theme from '../../../styles/theme'
import { NavLink } from 'react-router-dom'
import { DeploymentPageLayout } from './DeploymentPageLayout'
import { useCurrentDeploymentId } from './hooks/useCurrentDeploymentId'
import { useQuery } from '@apollo/client'
import { QUERY_ANIMATOR_DEPLOYMENT } from '../../../utils/gql/queries'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import { UserRoles } from '../../../redux/user'

export const AnimatorDeploymentProgress: React.FC = () => {
  const { t } = useTranslation()
  const id = useCurrentDeploymentId()
  const { data } = useQuery(QUERY_ANIMATOR_DEPLOYMENT, {
    variables: { id },
  })
  const role = useSelector((state: StoreState) => state.user.role)
  return (
    <DeploymentPageLayout>
      <Flex direction="row" gap="32px">
        <div css={{ flexShrink: 0 }}>
          <Card css={{ gap: '16px' }}>
            <CardTitle label={t('animator.deployments.progress.users.title')} />
            <Flex direction="column" gap="16px" css={{ paddingRight: '32px' }}>
              {data && (
                <Fragment>
                  <DataCell
                    icon="people"
                    value={`${data.deployment.candidateCount} ${t(
                      'animator.deployments.progress.users.users',
                    )}`}
                    label={t('animator.deployments.progress.users.candidates')}
                  />
                  <DataCell
                    icon="bike"
                    value={`${data.deployment.beneficiaryCount} ${t(
                      'animator.deployments.progress.users.users',
                    )}`}
                    label={t(
                      'animator.deployments.progress.users.beneficiaries',
                    )}
                  />
                </Fragment>
              )}
            </Flex>
            <NavLink
              css={{
                color: theme.colors.primary,
                textDecoration: 'underline',
              }}
              to={
                role === UserRoles.ADMIN
                  ? `/administrator/dashboard/${id}/users`
                  : `/animator/dashboard/${id}/users`
              }
            >
              {t('animator.deployments.progress.stats.userDetails')}
            </NavLink>
          </Card>
        </div>
        <Card css={{ gap: '16px', justifyContent: 'space-between' }}>
          <CardTitle label={t('animator.deployments.progress.stats.title')} />
          {data && (
            <div
              css={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '16px',
              }}
            >
              <DataCell
                icon="road"
                value={`${data.deployment.employeesTotalDistanceInKm} km`}
                label={t('animator.deployments.progress.stats.traveled')}
              />
              <DataCell
                icon="calendar"
                value={`${data.deployment.employeesBikeDayUsed} ${t(
                  'animator.deployments.progress.stats.days',
                )}`}
                label={t('animator.deployments.progress.stats.ofUse')}
              />
              <DataCell
                icon="euro"
                value={`${data.deployment.employeesTotalMoneySaved} €`}
                label={t('animator.deployments.progress.stats.saved')}
              />
              <DataCell
                icon="address"
                value={`${data.deployment.employeesRidesCount} ${t(
                  'animator.deployments.progress.stats.rides',
                )}`}
                label={t('animator.deployments.progress.stats.workHome')}
              />
              <DataCell
                icon="leaves"
                value={`${data.deployment.employeesTotalKgCO2Saved} kg CO2`}
                label={t('animator.deployments.progress.stats.avoided')}
              />
              <DataCell
                icon="burger"
                value={`${data.deployment.employeesTotalEnergySpent} kcal`}
                label={t('animator.deployments.progress.stats.spent')}
              />
            </div>
          )}
        </Card>
      </Flex>
      <CardCollapsible
        collapsibleContent={
          <Flex direction="column" gap="16px">
            {data && (
              <Fragment>
                <Flex direction="row" gap="50px">
                  <Flex direction="column" gap="4px">
                    <p>
                      {t('animator.deployments.progress.stats.bikeParking')}
                    </p>
                    <Flex
                      direction="row"
                      align="center"
                      gap="10px"
                      css={{ fontSize: '14px', fontWeight: 400 }}
                    >
                      <Flex direction="row" align="center" gap="4px">
                        <Icon type="bikeUnsecure" />
                        <span>
                          {data.deployment.site.bikeSpaceCapacityUnsafe}{' '}
                          {t(
                            'animator.deployments.progress.stats.bikeSpaceCapacityUnsafe',
                          )}
                        </span>
                      </Flex>
                      <Flex direction="row" align="center" gap="4px">
                        <Icon type="bikeSecure" />
                        <span>
                          {data.deployment.site.bikeSpaceCapacityOutside}{' '}
                          {t(
                            'animator.deployments.progress.stats.bikeSpaceCapacityOutside',
                          )}
                        </span>
                      </Flex>
                      <Flex direction="row" align="center" gap="4px">
                        <Icon type="bikeInside" />
                        <span>
                          {data.deployment.site.bikeSpaceCapacityInside}{' '}
                          {t(
                            'animator.deployments.progress.stats.bikeSpaceCapacityInside',
                          )}
                        </span>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <hr css={{ margin: 0 }} />
                <Flex direction="column" gap="4px">
                  <p>{t('animator.deployments.progress.stats.parking')}</p>
                  <Flex
                    direction="row"
                    align="center"
                    gap="10px"
                    css={{ fontSize: '14px', fontWeight: 400 }}
                  >
                    <Flex direction="row" align="center" gap="4px">
                      <Icon type="parking" />
                      <span>
                        {data.deployment.site.carSpaceCapacity}{' '}
                        {t(
                          'animator.deployments.progress.stats.privateParking',
                        )}
                      </span>
                    </Flex>
                    <Flex direction="row" align="center" gap="4px">
                      <Icon type="electricCharge" />
                      <span>
                        {data.deployment.site.electricStationCapacity}{' '}
                        {t(
                          'animator.deployments.progress.stats.electricChargePoints',
                        )}
                      </span>
                    </Flex>
                  </Flex>
                </Flex>
              </Fragment>
            )}
          </Flex>
        }
      >
        {data && (
          <Flex
            direction="column"
            gap="8px"
            css={{ fontSize: '14px', fontWeight: 400 }}
          >
            <Flex direction="row" gap="50px">
              <Flex direction="row" align="center" gap="10px">
                <Icon type="home" />
                <span>{data.deployment.site.name}</span>
              </Flex>
              <Flex direction="row" align="center" gap="10px">
                <Icon type="animators" />
                <span>
                  {t('animator.deployments.progress.stats.totalUsers')} :{' '}
                  {data.deployment.site.employeesOnSite}
                </span>
                <span>
                  {t('animator.deployments.progress.stats.registered')} :{' '}
                  {data.deployment.registeredCount}
                </span>
                <span>
                  {t('animator.deployments.progress.stats.beneficiaries')} :{' '}
                  {data.deployment.beneficiaryCount}
                </span>
              </Flex>
            </Flex>
            <Flex direction="row" align="center" gap="10px">
              <Icon type="locate" />
              <span>
                {data.deployment.site.street}, {data.deployment.site.postalCode}{' '}
                {data.deployment.site.city}
              </span>
            </Flex>
          </Flex>
        )}
      </CardCollapsible>
    </DeploymentPageLayout>
  )
}

interface DataCellProps {
  icon: IconTypes
  value: React.ReactNode
  label: React.ReactNode
}

const DataCell: React.FC<DataCellProps> = ({ icon, label, value }) => {
  return (
    <Flex direction="row" gap="16px" align="center">
      <Icon type={icon} size="large" color={theme.colors.primary} />
      <Flex direction="column">
        <p>{value}</p>
        <p css={{ fontSize: '14px', color: theme.colors.gray3 }}>{label}</p>
      </Flex>
    </Flex>
  )
}

export default AnimatorDeploymentProgress
