import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { UserRoles } from '../redux/user'

import { PrivateRoute } from './index'
import AdministratorAdvices from '../pages/Administrator/Advices'
import AdministratorPosts from '../pages/Administrator/Posts'

import AdministratorProfile from '../pages/Administrator/Profile'
import AdministratorBikes from '../pages/Shared/Bikes'
import AdministratorCompanies from '../pages/Animator/Companies'
import AdministratorQuizzes from '../pages/Administrator/Quizzes'
import AdministratorExports from '../pages/Administrator/Exports'
import AdministratorAnimator from '../pages/Administrator/Animator'

import DeploymentsAdmin from '../pages/Administrator/Deployments'

import AnimatorCompanyProgress from '../pages/Animator/Company/Progress/index'
import AnimatorCompanyMonitoring from '../pages/Animator/Company/Monitoring'
import AnimatorCompanyDocuments from '../pages/Animator/Company/Documents'
import AnimatorCompanyEmployees from '../pages/Animator/Company/Employees'
import AnimatorCompanyProfile from '../pages/Animator/Company/Profile'
import SharedLayout from '../pages/Animator/Company/Shared/Layout'

import AnimatorDeploymentProgress from '../pages/Animator/Deployment/Progress'
import AnimatorDeploymentUsers from '../pages/Animator/Deployment/Users'

import AnimatorCompaniesProgress from '../pages/Animator/Companies/Progress'
import AnimatorCompaniesProfile from '../pages/Animator/Companies/Profile'
import AnimatorEmployeesProfile from '../pages/Animator/Companies/Employees'
import AnimatorDeploymentMonitoring from '../pages/Animator/Deployment/Monitoring'

const routes = [
  {
    path: '/dashboard/:deploymentId/progress',
    exact: false,
    main: <AnimatorDeploymentProgress />,
  },
  {
    path: '/dashboard/:deploymentId/monitoring',
    exact: false,
    main: <AnimatorDeploymentMonitoring />,
  },
  {
    path: '/dashboard/:deploymentId/users',
    exact: false,
    main: <AnimatorDeploymentUsers />,
  },
  {
    path: '/dashboard',
    exact: false,
    main: <DeploymentsAdmin />,
  },
  {
    path: '/profile',
    exact: false,
    main: <AdministratorProfile />,
  },
  {
    path: '/bikes',
    exact: false,
    main: <AdministratorBikes />,
  },
  {
    path: '/companies/:companyId/progress',
    exact: false,
    main: <AnimatorCompaniesProgress />,
  },
  {
    path: '/companies/:companyId/profile',
    exact: false,
    main: <AnimatorCompaniesProfile />,
  },
  {
    path: '/companies/:companyId/employees',
    exact: false,
    main: <AnimatorEmployeesProfile />,
  },
  {
    path: '/companies',
    exact: false,
    main: <AdministratorCompanies />,
  },
  {
    path: '/animators',
    exact: false,
    main: <AdministratorAnimator />,
  },
  {
    path: '/application/faq',
    exact: false,
    main: <AdministratorPosts />,
  },
  {
    path: '/application/advices',
    exact: false,
    main: <AdministratorAdvices />,
  },
  {
    path: '/application/quizzes',
    exact: false,
    main: <AdministratorQuizzes />,
  },
  {
    path: '/application/exports',
    exact: false,
    main: <AdministratorExports />,
  },
]

const companyRoutes = [
  {
    path: '/progress',
    exact: false,
    main: <AnimatorCompanyProgress />,
  },
  {
    path: '/monitoring',
    exact: false,
    main: <AnimatorCompanyMonitoring />,
  },
  {
    path: '/profile',
    exact: false,
    main: <AnimatorCompanyProfile />,
  },
  {
    path: '/documents',
    exact: false,
    main: <AnimatorCompanyDocuments />,
  },
  {
    path: '/employees',
    exact: false,
    main: <AnimatorCompanyEmployees />,
  },
]

const AnimatorCompanyRouting: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <SharedLayout>
        {companyRoutes.map((route, index) => (
          <PrivateRoute
            protectedToRole={UserRoles.ADMIN}
            key={`${path}-${index}`}
            exact={route.exact}
            path={`${path}${route.path}`}
          >
            {route.main}
          </PrivateRoute>
        ))}
      </SharedLayout>
    </Switch>
  )
}

const AdministratorRouting: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      {routes.map((route, index) => (
        <PrivateRoute
          protectedToRole={UserRoles.ADMIN}
          key={`${path}-${index}`}
          exact={route.exact}
          path={`${path}${route.path}`}
        >
          {route.main}
        </PrivateRoute>
      ))}
      <Route path={`${path}/company/:id`}>
        <AnimatorCompanyRouting />
      </Route>
    </Switch>
  )
}

export default AdministratorRouting
