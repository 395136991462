/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../../redux/rootReducer'
import { UserRoles } from '@goodwatt/shared/dist/types'
import { useTheme } from 'emotion-theming'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { setDeploymentForm } from '../../../../../redux/forms'
import { DeploymentType } from '../../../../../__generated__/graphql'
import Input from '../../../../../components/Input'
import InputSelect from '../../../../../components/InputSelect'
import Checkbox from '../../../../../components/Checkbox'
import {
  QUERY_FLEETS_NAME,
  QUERY_COMPANIES_NAME,
  QUERY_GET_COMPANY_SITES,
} from '../../../../../utils/gql/queries'
import { SelectVariant } from '../../../../../hooks/useVariant/variants/select'
import {
  GetFleetNamesQuery,
  GetFleetNamesQueryVariables,
  GetCompaniesNamesQuery,
  GetCompaniesNamesQueryVariables,
  GetCompanySitesQuery,
  GetCompanySitesQueryVariables,
  SortOrder,
} from '../../../../../__generated__/graphql'

import { ReactComponent as GoodwattLogoWhite } from '../../../../../assets/images/goodwatt_white.svg'
import { ReactComponent as GoodwattLogoColor } from '../../../../../assets/images/goodwatt_color.svg'
import { CompanyArchiveStatus } from '../../../../../__generated__/graphql'

interface Step0Props {}

const Step0: React.FC<Step0Props> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()

  const { id: userId, role: userRole } = useSelector(
    (state: StoreState) => state.user,
  )
  const isAdmin = userRole === UserRoles.ADMIN
  const form = useSelector((state: StoreState) => state.forms.deploymentForm)
  const [areaId, setareaId] = React.useState(form.areaId)

  useEffect(() => {
    setareaId(form.areaId)
  }, [form.areaId])

  const { data } = useQuery<GetFleetNamesQuery, GetFleetNamesQueryVariables>(
    QUERY_FLEETS_NAME,
    { variables: { areaId } },
  )

  const { data: company } = useQuery<
    GetCompaniesNamesQuery,
    GetCompaniesNamesQueryVariables
  >(QUERY_COMPANIES_NAME, {
    variables: {
      where: {
        AND: [
          isAdmin
            ? {}
            : {
                sites: {
                  some: {
                    area: {
                      animator: {
                        some: {
                          userId: { equals: userId },
                        },
                      },
                    },
                  },
                },
              },
          {
            OR: [
              {
                archive: null as any,
              },
              {
                archive: {
                  status: { not: { equals: CompanyArchiveStatus.Archived } },
                },
              },
            ],
          },
        ],
      },
      orderBy: [
        {
          name: SortOrder.Asc,
        },
      ],
    },
  })

  const { data: sites } = useQuery<
    GetCompanySitesQuery,
    GetCompanySitesQueryVariables
  >(QUERY_GET_COMPANY_SITES, {
    variables: {
      where: {
        AND: [
          isAdmin
            ? {}
            : {
                area: { animator: { some: { userId: { equals: userId } } } },
              },
          {
            companyId: { equals: form.companyId },
          },
        ],
      },
    },
  })

  const fleetFilterOptions = React.useMemo(
    () =>
      data?.fleets
        ? [
            {
              label: t('forms.errors.chooseAnOption'),
              value: 'all',
            },
            ...data?.fleets.map(({ id, name }) => ({
              value: id,
              label: name,
            })),
          ]
        : [
            {
              label: t('forms.errors.chooseAnOption'),
              value: 'all',
            },
          ],
    [data, t],
  )

  const companyFilterOptions = React.useMemo(
    () =>
      company?.companies
        ? [
            {
              label: t('forms.errors.chooseAnOption'),
              value: 'all',
            },
            ...company?.companies.map(({ id, name }) => ({
              value: id,
              label: name,
            })),
          ]
        : [
            {
              label: t('forms.errors.chooseAnOption'),
              value: 'all',
            },
          ],
    [company, t],
  )

  const siteFilterOptions = React.useMemo(
    () =>
      sites?.sites
        ? [
            {
              label: t('forms.errors.chooseAnOption'),
              value: 'all',
            },
            ...sites?.sites.map(({ id, name }) => ({
              value: id,
              label: name,
            })),
          ]
        : [
            {
              label: t('forms.errors.chooseAnOption'),
              value: 'all',
            },
          ],
    [sites, t],
  )

  const onFleetSelectChange = async (data: any) => {
    dispatch(
      setDeploymentForm({
        fleetId: data.value !== 'all' ? data.value : '',
      }),
    )
  }

  const onChangeSelectCompany = async (data: any) => {
    dispatch(
      setDeploymentForm({
        companyId: data.value !== 'all' ? data.value : '',
        siteId: '',
        fleetId: '',
      }),
    )

    setareaId(0)
  }

  const onChangeSelectSite = async (data: any) => {
    const areaId =
      sites?.sites?.find(site => site.id === data.value)?.areaId || 0

    dispatch(
      setDeploymentForm({
        areaId: areaId,
        siteId: data.value,
        fleetId: '',
      }),
    )

    setareaId(areaId)
  }

  const onInputNameChange = async (e: any) => {
    /* signal to React not to nullify the event object */
    e.persist()

    dispatch(
      setDeploymentForm({
        name: e.target.value,
      }),
    )
  }

  return (
    <div
      css={{
        width: '100%',
        textAlign: 'initial',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <form>
        <div css={{ marginTop: '10px' }}>
          <Input
            type="text"
            name="name"
            placeholder="ex: Mon premier déploiement"
            label={t('animator.deployments.form.fields.deploymentName')}
            value={form.name || ''}
            onChange={onInputNameChange}
          />
        </div>

        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <div css={{ flex: 3, marginRight: 15 }}>
            {form.programStepNbr === 0 ? (
              <InputSelect
                label={t('animator.deployments.form.fields.deploymentCompany')}
                withError={false}
                name="company"
                variant={SelectVariant.gray}
                value={
                  form.companyId !== ''
                    ? companyFilterOptions.find(c => c.value === form.companyId)
                    : companyFilterOptions[0]
                }
                options={companyFilterOptions}
                onChange={onChangeSelectCompany}
              />
            ) : (
              <Input
                type="text"
                name="company"
                label={t('animator.deployments.form.fields.deploymentCompany')}
                value={
                  companyFilterOptions.find(c => c.value === form.companyId)
                    ?.label || ''
                }
                readOnly
                withError={false}
              />
            )}
          </div>

          <div css={{ flex: 3 }}>
            {form.programStepNbr < 2 ? (
              <InputSelect
                label={t('animator.deployments.form.fields.deploymentSite')}
                withError={false}
                name="site"
                variant={SelectVariant.gray}
                value={
                  form.siteId !== ''
                    ? siteFilterOptions.find(c => c.value === form.siteId)
                    : siteFilterOptions[0]
                }
                options={siteFilterOptions}
                onChange={onChangeSelectSite}
              />
            ) : (
              <Input
                type="text"
                name="site"
                label={t('animator.deployments.form.fields.deploymentSite')}
                value={
                  siteFilterOptions.find(c => c.value === form.siteId)?.label ||
                  ''
                }
                readOnly
                withError={false}
              />
            )}
          </div>
        </div>

        <div css={{ marginTop: '20px' }}>
          {form.programStepNbr < 4 ? (
            <InputSelect
              label={t('animator.deployments.form.fields.deploymentFleet')}
              withError={false}
              name="fleet"
              variant={SelectVariant.gray}
              value={
                form.fleetId !== ''
                  ? fleetFilterOptions.find(c => c.value === form.fleetId)
                  : fleetFilterOptions[0]
              }
              options={fleetFilterOptions}
              onChange={onFleetSelectChange}
            />
          ) : (
            <Input
              type="text"
              name="fleet"
              label={t('animator.deployments.form.fields.deploymentFleet')}
              value={
                fleetFilterOptions.find(c => c.value === form.fleetId)?.label ||
                ''
              }
              readOnly
              withError={false}
            />
          )}
        </div>

        <div css={{ marginTop: '20px' }}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              borderRadius: '20px',
              border: `1px solid ${theme.colors.primary}`,
            }}
          >
            <div
              css={{
                backgroundColor:
                  form.deploymentType === DeploymentType.Discovery
                    ? theme.colors.primary
                    : 'white',
                width: '50%',
                height: '110px',
                borderBottomLeftRadius: '20px',
                borderTopLeftRadius: '20px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {form.deploymentType === DeploymentType.Discovery ? (
                <GoodwattLogoWhite
                  css={{
                    marginBottom: '5px',
                  }}
                />
              ) : (
                <GoodwattLogoColor
                  css={{
                    marginBottom: '5px',
                  }}
                />
              )}

              <span
                css={{
                  color:
                    form.deploymentType === DeploymentType.Discovery
                      ? 'white'
                      : theme.colors.gray3,
                  marginBottom: '5px',
                }}
              >
                {t(`shared.deploymentTypeLabel.${DeploymentType.Discovery}`)}
              </span>

              <Checkbox
                isChecked={form.deploymentType === DeploymentType.Discovery}
                size="medium"
                onClick={() => {
                  form.programStepNbr < 2 &&
                    dispatch(
                      setDeploymentForm({
                        deploymentType: DeploymentType.Discovery,
                      }),
                    )
                }}
                color="#828282"
              ></Checkbox>
            </div>

            <div
              css={{
                backgroundColor:
                  form.deploymentType === DeploymentType.Rental
                    ? theme.colors.primary
                    : 'white',
                width: '50%',
                height: '110px',
                borderBottomRightRadius: '20px',
                borderTopRightRadius: '20px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {form.deploymentType === DeploymentType.Rental ? (
                <GoodwattLogoWhite
                  css={{
                    marginBottom: '5px',
                  }}
                />
              ) : (
                <GoodwattLogoColor
                  css={{
                    marginBottom: '5px',
                  }}
                />
              )}

              <span
                css={{
                  color:
                    form.deploymentType === DeploymentType.Rental
                      ? 'white'
                      : theme.colors.gray3,
                  marginBottom: '5px',
                }}
              >
                {t(`shared.deploymentTypeLabel.${DeploymentType.Rental}`)}
              </span>

              <Checkbox
                isChecked={form.deploymentType === DeploymentType.Rental}
                size="medium"
                onClick={() => {
                  form.programStepNbr < 2 &&
                    dispatch(
                      setDeploymentForm({
                        deploymentType: DeploymentType.Rental,
                      }),
                    )
                }}
                color="#828282"
              ></Checkbox>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Step0
