import { gql, QueryResult, useQuery } from '@apollo/client'
import { GetDeploymentsAvailablesQuery } from '../../../../__generated__/graphql'

export const QUERY_DEPLOYMENTS_AVAILABLES = gql`
  query GetDeploymentsAvailables(
    $company: String
    $siteId: String
    $take: Int
    $skip: Int
    $orderBy: [DeploymentOrderByInput]
    $archived: Boolean
  ) {
    deployments(
      company: $company
      siteId: $siteId
      take: $take
      skip: $skip
      orderBy: $orderBy
      archived: $archived
    ) {
      id
      name
      deploymentType
      deploymentEndDate
      deploymentStartDate
      site {
        id
        name
      }
      programStep {
        id
        stepNbr
      }
      employeeDeployments {
        id
        employeeId
        status
      }
    }
  }
`

export interface useDeploymentsAvailablesParam {
  variables?: Record<string, unknown>
}

export const useDeploymentsAvailables = ({
  variables,
}: useDeploymentsAvailablesParam): QueryResult<GetDeploymentsAvailablesQuery> => {
  return useQuery(QUERY_DEPLOYMENTS_AVAILABLES, {
    variables,
  })
}
