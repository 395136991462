/** @jsx jsx */
import { jsx } from '@emotion/core'

import { Row } from 'react-table'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { useLazyQuery, useQuery } from '@apollo/client'

import {
  CompanySubscribedEmployeesTableModalActions,
  CompanySubscribedEmployeesModalSettings,
  CompanySubscribedEmployeesTableRow,
} from '../../../types/AnimatorCompanySubscribedEmployees'
import Icon from '../../Icon'
import Checkbox from '../../Checkbox'

import { useNotifications } from '../../../contexts/NotificationContext'

import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import {
  QUERY_GET_EMPLOYEE,
  QUERY_GET_EMPLOYEE_EMAIL_VERIFIED,
} from '../../../utils/gql/queries'

import { ProgramStepType } from '@goodwatt/shared/dist/types'
import {
  GetEmployeeQuery,
  GetEmployeeQueryVariables,
} from '../../../__generated__/graphql'

interface CompanySubscribedEmployeesActionCellProps {
  setModal: (
    obj: CompanySubscribedEmployeesModalSettings<
      GetEmployeeQuery['employee'],
      CompanySubscribedEmployeesTableModalActions
    >,
  ) => void
  handleToggleSelect: (arg: CompanySubscribedEmployeesTableRow) => void
  row: Row<CompanySubscribedEmployeesTableRow>
  companyLoanMaxQuantityReached: boolean
  companyStepNbr: ProgramStepType
}

let currActionName: CompanySubscribedEmployeesTableModalActions | null = null

const CompanySubscribedEmployeesActionCell: React.FC<
  CompanySubscribedEmployeesActionCellProps
> = ({
  setModal,
  handleToggleSelect,
  row,
  companyLoanMaxQuantityReached,
  companyStepNbr,
}) => {
  const theme = useTheme()
  const { data, loading } = useQuery<
    GetEmployeeQuery,
    GetEmployeeQueryVariables
  >(QUERY_GET_EMPLOYEE_EMAIL_VERIFIED, {
    variables: { where: { id: row.original.id } },
    onError: error =>
      apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
  })

  const [getEmployee] = useLazyQuery<
    GetEmployeeQuery,
    GetEmployeeQueryVariables
  >(QUERY_GET_EMPLOYEE, {
    onCompleted: data => {
      if (data?.employee) {
        setModal({
          employee: data.employee,
          actionName:
            currActionName as CompanySubscribedEmployeesTableModalActions,
        })
      }
    },
    onError: error =>
      apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
    fetchPolicy: 'network-only',
  })

  const notifications = useNotifications()

  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: CompanySubscribedEmployeesTableModalActions,
    ) => {
      e.stopPropagation()
      currActionName = actionName
      getEmployee({ variables: { where: { id: row.original.id } } })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getEmployee, row.original.id, setModal],
  )

  const handleUpgradeClick = React.useCallback(
    () => handleToggleSelect(row.original),
    [row, handleToggleSelect],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      {companyStepNbr >= ProgramStepType.EMPLOYEE_LOANS_SELECTION && (
        <div
          css={{
            cursor: 'pointer',
            opacity: companyLoanMaxQuantityReached ? 0.2 : 1,
            marginRight: '1.3rem',
          }}
        >
          <Checkbox
            isChecked={row.original.selectLoan}
            onClick={handleUpgradeClick}
            color={theme.colors.success}
          />
        </div>
      )}
      <div
        css={{ cursor: 'pointer', margin: '0 1.3rem 0 0' }}
        onClick={e =>
          handleClick(
            e,
            CompanySubscribedEmployeesTableModalActions.EDIT_PROFILE,
          )
        }
      >
        <Icon type="anonymProfile" />
      </div>
      {loading || row.original.emailVerified ? (
        <div
          css={{ cursor: 'pointer' }}
          onClick={e =>
            handleClick(e, CompanySubscribedEmployeesTableModalActions.HABITS)
          }
        >
          <Icon type="road" />
        </div>
      ) : (
        <div
          css={{ cursor: 'pointer' }}
          onClick={e =>
            handleClick(
              e,
              CompanySubscribedEmployeesTableModalActions.CONFIRM_EMAIL,
            )
          }
        >
          <Icon type="mailCheck" />
        </div>
      )}
      <div
        css={{ cursor: 'pointer', marginLeft: '1.3rem' }}
        onClick={e =>
          handleClick(
            e,
            CompanySubscribedEmployeesTableModalActions.CONFIRM_DELETE,
          )
        }
      >
        <Icon type="trash" />
      </div>
    </div>
  )
}

export default CompanySubscribedEmployeesActionCell
