/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Column, Row } from 'react-table'
import Button from '@goodwatt/client/src/components/Button'
import {
  QuizTableAdd,
  QuizTableModalActions,
  QuizSettings,
  QuizTableRow,
} from '@goodwatt/client/src/types/Quizzes'
import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '@goodwatt/client/src/components/Table'
import QuizActionCell from '@goodwatt/client/src/components/Table/CustomCells/QuizActionCell'

import AddOrEditModal from './AddOrEditModal'
import DeleteModal from './DeleteModal'
import { QUERY_COUNT_QUIZ, QUERY_GET_QUIZZES } from '../../../utils/gql/queries'

import { useTheme } from 'emotion-theming'
import Icon from '../../../components/Icon'
import DownloadCSVButton from '../../../components/Button/DownloadCSVButton'
import Pagination from '../../../components/Pagination'
import usePromiseLazyQuery from '../../../hooks/usePromiseLazyQuery'
import {
  CountQuizQuery,
  GetQuizzesQuery,
  GetQuizzesQueryVariables,
} from '../../../__generated__/graphql'

const initalModalSettings: QuizSettings = {
  quiz: {
    id: '',
    title: '',
    content: '',
    MIME: '',
    imageURL: '',
  },
  actionName: '',
}

interface AnimatorCompanyMonitoringProps {
  pageSize: number
}

const AnimatorCompanyMonitoringList: React.FC<
  AnimatorCompanyMonitoringProps
> = ({ pageSize }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [currModal, setCurrModal] = useState<QuizSettings>(initalModalSettings)
  const [currPage, setCurrPage] = useState(1)

  const { data: quizCount } = useQuery<CountQuizQuery>(QUERY_COUNT_QUIZ)

  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)

  const {
    data: quizData,
    refetch,
    loading,
  } = useQuery<GetQuizzesQuery, GetQuizzesQueryVariables>(QUERY_GET_QUIZZES, {
    fetchPolicy: 'network-only',
    variables: {
      skip: (currPage - 1) * pageSize,
      take: pageSize,
      orderBy:
        orderBy.sort && orderBy.columnName
          ? [{ [orderBy.columnName]: orderBy.sort }]
          : undefined,
    },
  })

  const getAllQuizzes = usePromiseLazyQuery<
    GetQuizzesQuery,
    GetQuizzesQueryVariables
  >(QUERY_GET_QUIZZES)

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<QuizTableRow> }) => (
      <QuizActionCell
        setModal={setCurrModal}
        row={row}
        actions={[QuizTableModalActions.EDIT, QuizTableModalActions.TRASH]}
      />
    ),
    [],
  )
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t('quiz.table.headers.title') as string,
        accessor: 'title',
      },
      {
        Header: t('quiz.table.headers.content') as string,
        accessor: 'contentCroped',
        sortField: 'content',
      },
      {
        Header: t('quiz.table.headers.imageURL') as string,
        accessor: 'hasImage',
        sortField: 'imageURL',
        cellStyle: () => ({ textAlign: 'center', maxWidth: 48 }),
      },
      {
        Header: t(
          'animator.company.monitoring.table.headers.actions',
        ) as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [ActionCell, t],
  )
  const formattedData = useMemo(() => {
    if (!quizData?.quizzes?.length) return []

    return quizData?.quizzes?.map(quiz => ({
      id: quiz?.id,
      title: quiz?.title,
      content: quiz?.content,
      contentCroped: quiz?.content || '',
      hasImage: quiz?.imageURL ? 'Oui' : 'Non',
      imageURL: quiz?.imageURL,
      MIME: quiz?.MIME,
    }))
  }, [quizData])

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `quiz-${formatedDate}`

  const getExportData = async () => {
    const { data } = await getAllQuizzes({})

    if (!data?.quizzes) return []

    return [
      ['Intitulé', 'Lien URL du quiz', 'Image URL'],
      ...data.quizzes.map(quiz => ({
        label: quiz?.title,
        url: quiz?.content,
        image: quiz?.imageURL,
      })),
    ]
  }

  return (
    <React.Fragment>
      <div css={{ minHeight: 380 }}>
        <Table
          loading={loading}
          columns={columns}
          data={formattedData}
          onSortBy={setOrderBy}
        />
      </div>
      <div
        css={{
          position: 'relative',
          display: 'flex',
          marginTop: '41px',
          marginBottom: '31px',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Pagination
          currentPage={currPage}
          onClick={setCurrPage}
          pageSize={pageSize}
          totalData={quizCount?.quizCount}
        />
        <div
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            paddingLeft: 77,
            [theme.media.mobile]: {
              paddingLeft: 0,
            },
          }}
        >
          <Button
            type="primary"
            submit
            onClick={() =>
              setCurrModal({
                ...initalModalSettings,
                actionName: QuizTableAdd.ADD,
              })
            }
          >
            <span
              css={{
                fontFamily: theme.fontFamily,
                fontSize: '1.6rem',
                fontWeight: 500,
                color: theme.colors.white,
              }}
            >
              <Icon
                strokeColor="white"
                type="plus"
                customSize={{ width: '16px', height: '16px' }}
              />
            </span>
          </Button>
          <DownloadCSVButton getData={getExportData} filename={filename} />
        </div>
        <AddOrEditModal
          quiz={currModal.quiz}
          isOpen={
            currModal.actionName === QuizTableAdd.ADD ||
            currModal.actionName === QuizTableModalActions.EDIT
          }
          type={currModal.actionName}
          closeModal={() => setCurrModal(initalModalSettings)}
          updateList={refetch}
        />
        <DeleteModal
          quiz={currModal.quiz}
          isOpen={currModal.actionName === QuizTableModalActions.TRASH}
          closeModal={() => setCurrModal(initalModalSettings)}
          updateList={refetch}
        />
      </div>
    </React.Fragment>
  )
}

export default AnimatorCompanyMonitoringList
