/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNotifications } from '@goodwatt/client/src/contexts/NotificationContext'
import apiErrorCatcher, {
  newErrorNotification,
} from '@goodwatt/client/src/utils/apiErrorCatcher'
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../../../../components/ConfirmationModal'
import { ApolloError, useMutation } from '@apollo/client'
import { MUTATION_DEPLOYMENT_NEXT_STEP } from '../../../../utils/gql/mutations'

export interface NextDeploymentStepModalProps
  extends Pick<ConfirmationModalProps, 'isOpen' | 'onClose'> {
  id: string
}

export const NextDeploymentStepModal: React.FC<
  NextDeploymentStepModalProps
> = ({ id, ...rest }) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [mutate, { loading }] = useMutation(MUTATION_DEPLOYMENT_NEXT_STEP, {
    refetchQueries: [
      'GetDeployments',
      'GetDeployment',
      'GetDeploymentEmployees',
    ],
  })

  const onSubmit = async () => {
    mutate({
      variables: { data: { id } },
    })
      .then(() => {
        rest.onClose()
      })
      .catch((e: { graphQLErrors: ApolloError[] }) => {
        const apiErrors = e.graphQLErrors.map((e: any) => e.message)
        const notif = newErrorNotification(notifications)

        if (apiErrors.length) {
          if (apiErrors.includes('CANNOT_INCREASE_COMPANY_STEP_2')) {
            return notif(t('animator.company.progress.stepStatus.errorStep3'))
          }
          if (apiErrors.includes('CANNOT_INCREASE_COMPANY_STEP_4')) {
            return notif(t('animator.company.progress.stepStatus.errorStep5'))
          }
        }

        return apiErrorCatcher(notifications)(e)
      })
  }

  return (
    <ConfirmationModal
      title={t('animator.deployments.nextDeploymentStepModal.title')}
      description={t(
        'animator.deployments.nextDeploymentStepModal.description',
      )}
      loading={loading}
      onSubmit={onSubmit}
      {...rest}
    />
  )
}

export default NextDeploymentStepModal
