/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useEffect } from 'react'
import { useTheme } from 'emotion-theming'
import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Badge from '../../../../components/Badge'

import { setSubMenuTitle } from '../../../../redux/menu'
import { useTranslation } from 'react-i18next'
import {
  GetCompanyNameQuery,
  GetCompanyNameQueryVariables,
} from '../../../../__generated__/graphql'

const QUERY_COMPANY_NAME = gql`
  query GetCompanyName($companyId: String!) {
    company(where: { id: $companyId }) {
      name
      fleet {
        name
      }
    }
  }
`

const SharedLayout: React.FC = ({ children }) => {
  const theme = useTheme()
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { data, loading } = useQuery<
    GetCompanyNameQuery,
    GetCompanyNameQueryVariables
  >(QUERY_COMPANY_NAME, {
    variables: {
      companyId: id,
    },
  })

  useEffect(() => {
    if (data?.company?.name)
      dispatch(
        setSubMenuTitle(
          `${data.company.name}${
            data?.company?.fleet?.name ? ` (${data?.company?.fleet?.name})` : ''
          }`,
        ),
      )
    return () => {
      dispatch(setSubMenuTitle(''))
    }
  }, [data, dispatch])

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 50,
          [theme.media.tablet]: {
            display: 'none',
          },
        }}
      >
        <span
          css={{
            fontWeight: 'bold',
            fontSize: '1.6rem',
            color: theme.colors.gray2,
            marginRight: 20,
          }}
        >
          {t('animator.company.shared.selectedCompany')}
        </span>
        <Badge
          backgroundColor={theme.colors.primary}
          fontColor={theme.colors.white}
          label={
            loading
              ? '...'
              : `${data?.company?.name} (${
                  data?.company?.fleet?.name ||
                  t('animator.company.shared.toBeDefined')
                })`
          }
        />
      </div>
      {children}
    </div>
  )
}

export default SharedLayout
