/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '../../../../../../components/Checkbox'
import { setLoanBikeForm } from '../../../../../../redux/forms'
import { StoreState } from '../../../../../../redux/rootReducer'

import { format } from 'date-fns'
import { employeeNameFormatter } from '../../../../../../utils/formatter'
import { useQuery } from '@apollo/client'
import { QUERY_GET_EMPLOYEE_ABSENCES } from '../../../../../../utils/gql/queries'
import {
  AppointmentCategory,
  GetEmployeeAbsencesQuery,
  GetEmployeeAbsencesQueryVariables,
  GetEmployeeQuery,
} from '../../../../../../__generated__/graphql'

interface AnimatorLoanValidationProps {
  loanEventsFormatted: { loanDeliveryDate: string; loanReturnDate: string }
  employee: GetEmployeeQuery['employee'] | null | undefined
  deploymentId: string
}

const currStepi18nNamespace =
  'animator.company.employees.selected.modals.employeeLoanBikeModal.animatorLoanValidation'

const AnimatorLoanValidation: React.FC<AnimatorLoanValidationProps> = ({
  deploymentId,
  employee,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { accessories, stickerId, animatorValidation } = useSelector(
    (state: StoreState) => state.forms.loanBikeForm,
  )
  const dispatch = useDispatch()

  const handleValidationClick = () => {
    dispatch(setLoanBikeForm({ animatorValidation: !animatorValidation }))
  }

  const hasAccessory = React.useMemo(
    () => Object.values(accessories).some(accessory => accessory.included),
    [accessories],
  )

  const employeeName = employee
    ? employeeNameFormatter(employee?.firstName, employee?.lastName)
    : null

  const { data: employeeAbsence } = useQuery<
    GetEmployeeAbsencesQuery,
    GetEmployeeAbsencesQueryVariables
  >(QUERY_GET_EMPLOYEE_ABSENCES, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: employee?.id,
      },
    },
  })

  const loanDeliveryAppointment = employee?.loanDeliveryAppointment?.find(
    appointment => appointment?.deploymentId === deploymentId,
  )

  const loanReturnAppointment = employee?.loanReturnAppointment?.find(
    appointment => appointment?.deploymentId === deploymentId,
  )

  const loanDeliveryAbsence = employeeAbsence?.employee?.absences?.find(
    x =>
      x?.category === AppointmentCategory.LoanDelivery &&
      x?.deploymentId === deploymentId,
  )

  const loanReturnAbsence = employeeAbsence?.employee?.absences?.find(
    x =>
      x?.category === AppointmentCategory.LoanReturn &&
      x?.deploymentId === deploymentId,
  )

  const loanDeliveryDate = loanDeliveryAppointment?.date
    ? format(new Date(loanDeliveryAppointment.date), 'dd/MM/yyyy')
    : loanDeliveryAbsence
    ? format(new Date(loanDeliveryAbsence.date), 'dd/MM/yyyy')
    : null

  const loanReturnDate = loanReturnAppointment?.date
    ? format(new Date(loanReturnAppointment.date), 'dd/MM/yyyy')
    : loanReturnAbsence
    ? format(new Date(loanReturnAbsence.date), 'dd/MM/yyyy')
    : null

  let loanDateRange = t(
    `${currStepi18nNamespace}.description.loanRangesWithoutEnd`,
    {
      loanDeliveryDate: loanDeliveryDate || 'n/a',
    },
  )

  if (loanDeliveryDate && loanReturnDate) {
    loanDateRange = t(`${currStepi18nNamespace}.description.loanRanges`, {
      loanDeliveryDate: loanDeliveryDate || 'n/a',
      loanReturnDate: loanReturnDate || 'n/a',
    })
  }

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 550,
        }}
      >
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: t(`${currStepi18nNamespace}.description.bike`, {
                stickerId,
              }),
            }}
          />{' '}
          {hasAccessory && (
            <span>
              {t(`${currStepi18nNamespace}.description.accessories`)}{' '}
            </span>
          )}
          <span
            dangerouslySetInnerHTML={{
              __html: t(`${currStepi18nNamespace}.description.employee`, {
                employeeName: employeeName,
              }),
            }}
          />{' '}
          <span
            dangerouslySetInnerHTML={{
              __html: loanDateRange,
            }}
          />
        </div>
        <div css={{ marginBottom: 35 }} />
        <span>{t(`${currStepi18nNamespace}.verification`)}</span>
        <div css={{ marginBottom: 35 }} />
        <div
          onClick={handleValidationClick}
          css={{
            padding: 20,
            display: 'flex',
            alignItems: 'center',
            border: `2px solid ${theme.colors.gray6}`,
            borderRadius: '22px',
            cursor: 'pointer',
          }}
        >
          <Checkbox isChecked={animatorValidation} />
          <span
            css={{
              color: theme.colors.primary,
              fontSize: '1.6rem',
              fontWeight: 'bold',
              userSelect: 'none',
            }}
          >
            {t('shared.termsOfContract')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default AnimatorLoanValidation
