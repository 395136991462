/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../../../../components/ConfirmationModal'
import { SpecialBikesType } from '../../../../__generated__/graphql'
import { format } from 'date-fns'

export interface SelectedDeploymentModalProps
  extends Pick<ConfirmationModalProps, 'isOpen' | 'onClose'> {
  id: string
  deploymentName: string
  deploymentType: string
  startDate: string
  endDate: string
  bikeType: SpecialBikesType
  bikeBrand: string
  bikeModel: string
  bikeOption: 'none' | 'childSeat'
  onSubmit: () => void
}

export const SelectedDeploymentModal: React.FC<
  SelectedDeploymentModalProps
> = ({
  id,
  deploymentName,
  deploymentType,
  startDate,
  endDate,
  bikeType,
  bikeBrand,
  bikeModel,
  bikeOption,
  onSubmit,
  ...rest
}) => {
  const { t } = useTranslation()

  const description = () => {
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: t('employee.deployments.modal.description', {
            deploymentType: t(
              `employee.deployments.modal.deploymentType.${deploymentType}`,
            ),
            startDate: format(new Date(startDate), 'dd/MM/yyyy'),
            endDate: format(new Date(endDate), 'dd/MM/yyyy'),
            bikeType: t(`employee.deployments.modal.bikeType.${bikeType}`),
            bikeBrand,
            bikeModel,
            bikeOption: t(`employee.deployments.modal.options.${bikeOption}`),
          }),
        }}
      />
    )
  }

  return (
    <ConfirmationModal
      title={t('employee.deployments.modal.title')}
      description={description()}
      onSubmit={onSubmit}
      {...rest}
    />
  )
}

export default SelectedDeploymentModal
