/** @jsx jsx */
import React, { useState } from 'react'
import { jsx } from '@emotion/core'
import { useMutation, useQuery } from '@apollo/client'

import theme from '../../../../styles/theme'
import Icon from '../../../../components/Icon'

import {
  QUERY_AREAS,
  QUERY_TERRITORY_DEPARTEMENT,
} from '../../../../utils/gql/queries'

import InputSelect from '../../../../components/InputSelect'
import { MUTATION_UPDATE_TERRITORY } from '../../../../utils/gql/mutations'

import DownloadCSVButton from '../../../../components/Button/DownloadCSVButton'
import {
  AdminTableRefetchContext,
  AdminTableTypes,
} from '../../AdminTableRefetchContext'
import {
  GetAreasQuery,
  GetTerritoryDepartmentQuery,
  UpdateTerritoryMutation,
  UpdateTerritoryMutationVariables,
} from '../../../../__generated__/graphql'

type RegionType = {
  name: string
  territories: (string | number)[]
}

const regions: RegionType[] = [
  {
    name: 'Auvergne Rhone-Alpes',
    territories: ['01', '03', '07', 15, 26, 38, 42, 43, 63, 69, 73, 74],
  },
  {
    name: 'Bourgogne-Franche-Comté',
    territories: [21, 25, 39, 58, 70, 71, 89, 90],
  },
  {
    name: 'Bretagne',
    territories: [22, 29, 35, 56],
  },
  {
    name: 'Centre-Val de Loire',
    territories: [18, 28, 36, 37, 41, 45],
  },
  {
    name: 'Corse',
    territories: ['2A', '2B'],
  },
  {
    name: 'Grand Est',
    territories: ['08', 10, 51, 52, 54, 55, 57, 67, 68, 88],
  },
  {
    name: 'Hauts-de-France',
    territories: ['02', 59, 60, 62, 80],
  },
  {
    name: 'Île-de-France',
    territories: [75, 77, 78, 91, 92, 93, 94, 95],
  },
  {
    name: 'Normandie',
    territories: [14, 27, 50, 61, 76],
  },
  {
    name: 'Nouvelle-Aquitaine',
    territories: [16, 17, 19, 23, 24, 33, 40, 47, 64, 79, 86, 87],
  },
  {
    name: 'Occitanie',
    territories: ['09', 11, 12, 30, 31, 32, 34, 46, 48, 65, 66, 81, 82],
  },
  {
    name: 'Pays de la Loire',
    territories: [44, 49, 53, 72, 85],
  },
  {
    name: "Provence-Alpes-Côte d'Azur",
    territories: ['04', '05', '06', 13, 83, 84],
  },
]

const TerritoryList: React.FC = () => {
  const { data: areaTerrytories, refetch: refetchTerrytories } =
    useQuery<GetTerritoryDepartmentQuery>(QUERY_TERRITORY_DEPARTEMENT)

  const [updateTerritory] = useMutation<
    UpdateTerritoryMutation,
    UpdateTerritoryMutationVariables
  >(MUTATION_UPDATE_TERRITORY)

  const [currRegion, setCurrRegion] = useState<string>()

  const { data: areaData } = useQuery<GetAreasQuery>(QUERY_AREAS)

  const areaOptions = areaData?.areas.map(x => ({
    label: x.name || '',
    value: x.id || '',
  }))

  const selectRegion = (regionName: string) => {
    if (currRegion === regionName) return setCurrRegion(undefined)
    setCurrRegion(regionName)
  }

  const {
    refetchTables,
    removeTableToFetch,
    tableRefetchPlanner: { [AdminTableTypes.TERRITORY]: needToRefetch },
  } = React.useContext(AdminTableRefetchContext)

  React.useEffect(() => {
    if (needToRefetch) {
      refetchTerrytories()
      removeTableToFetch(AdminTableTypes.TERRITORY)
    }
  }, [needToRefetch, refetchTerrytories, removeTableToFetch])

  const onUpdateTerritory = React.useCallback(
    async (territoryCode, { value }) => {
      await updateTerritory({
        variables: {
          data: {
            area: {
              connect: {
                id: value,
              },
            },
          },
          where: {
            code: territoryCode,
          },
        },
      })

      refetchTerrytories()
      refetchTables([AdminTableTypes.AREAS])
    },
    [updateTerritory, refetchTerrytories, refetchTables],
  )

  const filename = `departement-affectation`
  const formatExport = [
    ['Departement', "Zone d'affectation"],
    ...(areaTerrytories?.territoryDepartments.map(territory => ({
      name: `${territory.code} - ${territory.name}`,
      zone: territory.area?.name,
    })) || []),
  ]

  return (
    <React.Fragment>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            width: '100%',
            paddingBottom: 64,
            paddingLeft: 24,
          }}
        >
          {areaTerrytories &&
            regions.map(region => {
              const territories = []

              for (const code of region.territories) {
                territories.push(`${code}`)
              }

              const lists = territories.map(territoryCode =>
                areaTerrytories?.territoryDepartments.find(
                  x => x.code === territoryCode,
                ),
              )

              const areaList = lists.reduce((acc, value) => {
                const areaName = value?.area?.name
                if (acc.find(x => x === areaName)) return acc

                acc.push(areaName)
                return acc
              }, new Array<string | undefined>())

              return [
                <div
                  key={region.name}
                  style={{
                    height: 48,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #D2D2D2',
                    cursor: 'pointer',
                    transition: 'ease all .3s',
                    userSelect: 'none',
                  }}
                  onClick={() => selectRegion(region.name)}
                  css={{
                    '&:hover': {
                      background: '#3dd9f117',
                    },
                  }}
                >
                  <div>
                    <span>{region.name}</span>{' '}
                    <span
                      style={{
                        marginLeft: 12,
                        color: '#828282',
                      }}
                    >
                      (
                      {areaList.map((areaName, i) => {
                        if (areaName === undefined)
                          return (
                            <span style={{ color: theme.colors.redLegend }}>
                              Non affecté
                              {i !== areaList.length - 1 ? ', ' : ''}
                            </span>
                          )
                        return (
                          <span key={areaName}>
                            {areaName}
                            {i !== areaList.length - 1 ? ', ' : ''}
                          </span>
                        )
                      })}
                      )
                    </span>
                  </div>
                  <div
                    style={{
                      width: 48,
                      height: 48,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      transition: 'ease all .3s',
                    }}
                    css={
                      currRegion === region.name
                        ? {
                            transform: 'rotate(45deg)',
                          }
                        : {}
                    }
                  >
                    <Icon
                      strokeColor="#3CD9F1"
                      type="plus"
                      customSize={{ width: '16px', height: '16px' }}
                    />
                  </div>
                </div>,
                region.name === currRegion && (
                  <div
                    key={region.name + 'content'}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '60%',
                      paddingLeft: 20,
                      marginTop: 12,
                    }}
                  >
                    {territories.map(territoryCode => {
                      const territory =
                        areaTerrytories?.territoryDepartments.find(
                          x => x.code === territoryCode,
                        )

                      if (!territory) return null

                      return (
                        <div
                          key={territoryCode}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <label
                            css={{
                              fontSize: '1.6rem',
                              color: theme.colors.gray3,
                              width: 224,
                              marginBottom: 12,
                              marginRight: 24,
                            }}
                          >
                            {territory.code} - {territory.name}
                          </label>
                          <InputSelect
                            name="area"
                            options={areaOptions || []}
                            defaultValue={territory.area?.id || ''}
                            onChange={value =>
                              onUpdateTerritory(territory.code, value)
                            }
                          />
                        </div>
                      )
                    })}
                  </div>
                ),
              ]
            })}
        </div>

        <div
          css={{
            display: 'flex',
            justifySelf: 'flex-end',
            position: 'absolute',
            right: 0,
            bottom: 0,
          }}
        >
          <DownloadCSVButton data={formatExport} filename={filename} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default TerritoryList
