import { gql, QueryHookOptions, QueryResult, useQuery } from '@apollo/client'
import {
  GetEmployeeDeploymentBikeLoanQuery,
  GetEmployeeDeploymentBikeLoanQueryVariables,
} from '../../../../__generated__/graphql'

const QUERY_EMPLOYEE_DEPLOYMENT_BIKE_LOAN = gql`
  query GetEmployeeDeploymentBikeLoan($id: String!) {
    employeeDeployment(id: $id) {
      employee {
        id
        firstName
        lastName
      }
      bikeLoan {
        id
        bike {
          id
          stickerId
        }
        loanPackages {
          id
          note
          packageAccessory {
            name
          }
          stateOfUse {
            name
          }
          bikeLoanAttachments {
            id
            title
            size
          }
        }
      }
    }
  }
`

export const useEmployeeDeploymentBikeLoan = (
  isOpen: boolean,
  id: string,
  options?: Omit<
    QueryHookOptions<
      GetEmployeeDeploymentBikeLoanQuery,
      GetEmployeeDeploymentBikeLoanQueryVariables
    >,
    'variables'
  >,
): QueryResult<
  GetEmployeeDeploymentBikeLoanQuery,
  GetEmployeeDeploymentBikeLoanQueryVariables
> => {
  return useQuery<
    GetEmployeeDeploymentBikeLoanQuery,
    GetEmployeeDeploymentBikeLoanQueryVariables
  >(QUERY_EMPLOYEE_DEPLOYMENT_BIKE_LOAN, {
    ...options,
    variables: { id },
    skip: !isOpen,
  })
}
