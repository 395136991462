import { gql } from '@apollo/client'

import i18n from '../../i18n/config'
import { BikeComponentPartType } from '@goodwatt/shared/dist/types'
import { Bike, GetBikesExportQuery } from '../../__generated__/graphql'

export const QUERY_BIKES_EXPORT = gql`
  query GetBikesExport(
    $bikeCondition: String
    $fleet: String
    $multiFilter: String
  ) {
    bikesSearch(
      bikeCondition: $bikeCondition
      fleet: $fleet
      multiFilter: $multiFilter
    ) {
      id

      stickerId
      keysId
      morioId
      bicycode

      bikeModel {
        name
      }

      fleet {
        name
      }

      companySalaryName

      bikeCondition {
        name
      }

      bikeComponents {
        id
        note

        bikeComponentPart {
          index
          name
        }

        stateOfUse {
          name
        }
      }
    }
  }
`

export const formatBikesExport = (
  bikesExportData: GetBikesExportQuery | undefined,
): any => {
  if (!bikesExportData?.bikesSearch?.length) return []

  const header = [
    'N° vélo',
    'Identifiant des clés',
    'Identifiant Morio',
    'Identifiant Bicycode',
    'Modèle',
    'Flotte',
    'Salarié',
    'Etat général',
    'Cadre et fourche',
    'Commentaire',
    'Freins',
    'Commentaire',
    'Roue arrière',
    'Commentaire',
    'Roue avant',
    'Commentaire',
    'Transmissions et moteur',
    'Commentaire',
    'Guidon',
    'Commentaire',
    'Périphériques',
    'Commentaire',
    'Batterie et display',
    'Commentaire',
    'Avis global',
    'Commentaire',
  ]

  function getPart(bike: Bike | null, part: BikeComponentPartType) {
    return bike?.bikeComponents.find(c => c.bikeComponentPart.name === part)
  }

  function getPartStateOfUse(bike: Bike | null, part: BikeComponentPartType) {
    const stateOfUse = bike?.bikeComponents.find(
      c => c.bikeComponentPart.name === part,
    )?.stateOfUse

    return stateOfUse != null
      ? i18n.t(`shared.stateOfUseLabel.${stateOfUse.name}`)
      : ''
  }

  return [
    header,
    ...bikesExportData?.bikesSearch.map(bike => ({
      stickerId: bike?.stickerId,
      keysId: bike?.keysId,
      morioId: bike?.morioId,
      bicycode: bike?.bicycode,
      bikeModel: bike?.bikeModel?.name,
      fleet: bike?.fleet?.name,
      companySalaryName: bike?.companySalaryName,
      bikeCondition:
        bike?.bikeCondition?.name != null
          ? i18n.t(`shared.bikeConditionLabel.${bike?.bikeCondition?.name}`)
          : '',

      [BikeComponentPartType.BIKE_FRAME]: getPartStateOfUse(
        bike as Bike,
        BikeComponentPartType.BIKE_FRAME,
      ),
      [BikeComponentPartType.BIKE_FRAME + '_note']: getPart(
        bike as Bike,
        BikeComponentPartType.BIKE_FRAME,
      )?.note,

      [BikeComponentPartType.BRAKES]: getPartStateOfUse(
        bike as Bike,
        BikeComponentPartType.BRAKES,
      ),
      [BikeComponentPartType.BRAKES + '_note']: getPart(
        bike as Bike,
        BikeComponentPartType.BRAKES,
      )?.note,
      [BikeComponentPartType.BACK_WHEEL]: getPartStateOfUse(
        bike as Bike,
        BikeComponentPartType.BACK_WHEEL,
      ),
      [BikeComponentPartType.BACK_WHEEL + '_note']: getPart(
        bike as Bike,
        BikeComponentPartType.BACK_WHEEL,
      )?.note,
      [BikeComponentPartType.FRONT_WHEEL]: getPartStateOfUse(
        bike as Bike,
        BikeComponentPartType.FRONT_WHEEL,
      ),
      [BikeComponentPartType.FRONT_WHEEL + '_note']: getPart(
        bike as Bike,
        BikeComponentPartType.FRONT_WHEEL,
      )?.note,
      [BikeComponentPartType.TRANSMISSIONS]: getPartStateOfUse(
        bike as Bike,
        BikeComponentPartType.TRANSMISSIONS,
      ),
      [BikeComponentPartType.TRANSMISSIONS + '_note']: getPart(
        bike as Bike,
        BikeComponentPartType.TRANSMISSIONS,
      )?.note,
      [BikeComponentPartType.HANDLEBAR]: getPartStateOfUse(
        bike as Bike,
        BikeComponentPartType.HANDLEBAR,
      ),
      [BikeComponentPartType.HANDLEBAR + '_note']: getPart(
        bike as Bike,
        BikeComponentPartType.HANDLEBAR,
      )?.note,
      [BikeComponentPartType.DEVICES]: getPartStateOfUse(
        bike as Bike,
        BikeComponentPartType.DEVICES,
      ),
      [BikeComponentPartType.DEVICES + '_note']: getPart(
        bike as Bike,
        BikeComponentPartType.DEVICES,
      )?.note,
      [BikeComponentPartType.BATTERY]: getPartStateOfUse(
        bike as Bike,
        BikeComponentPartType.BATTERY,
      ),
      [BikeComponentPartType.BATTERY + '_note']: getPart(
        bike as Bike,
        BikeComponentPartType.BATTERY,
      )?.note,
      [BikeComponentPartType.GLOBAL]: getPartStateOfUse(
        bike as Bike,
        BikeComponentPartType.GLOBAL,
      ),
      [BikeComponentPartType.GLOBAL + '_note']: getPart(
        bike as Bike,
        BikeComponentPartType.GLOBAL,
      )?.note,
    })),
  ]
}
