/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import Icon from '../../Icon'

import {
  CompaniesModalSettings,
  AccepetedCompaniesTableRow,
  ArchiveTableModal,
  ArchivedCompaniesTableRow,
} from '../../../types/AnimatorCompanies'
import Tooltip from '../../Tooltip'
import { downloadZip } from '../../Button/DownloadCSVButton'
import usePromiseLazyQuery from '../../../hooks/usePromiseLazyQuery'
import {
  formatArchivedCompanyDataExport,
  QUERY_ARCHIVED_COMPANY_DATA_EXPORT,
} from '../../../utils/gql/archivedCompanyDataExport'
import {
  formatArchivedCompanyIdentityExport,
  QUERY_ARCHIVED_COMPANY_IDENTITY_EXPORT,
} from '../../../utils/gql/archivedCompanyIdentityExport'
import {
  GetArchivedCompanyDataExportQuery,
  GetArchivedCompanyDataExportQueryVariables,
  GetArchivedCompanyIdentityExportQuery,
  GetArchivedCompanyIdentityExportQueryVariables,
} from '../../../__generated__/graphql'

const ArchivedCompaniesActionCell: React.FC<{
  setModal: (
    obj: CompaniesModalSettings<AccepetedCompaniesTableRow, ArchiveTableModal>,
  ) => void
  row: Row<ArchivedCompaniesTableRow>
}> = ({ setModal, row }) => {
  const { t } = useTranslation()

  const getArchivedCompanyDataExport = usePromiseLazyQuery<
    GetArchivedCompanyDataExportQuery,
    GetArchivedCompanyDataExportQueryVariables
  >(QUERY_ARCHIVED_COMPANY_DATA_EXPORT, 'no-cache')

  const getArchivedCompanyDataExportData = React.useCallback(async () => {
    const { data } = await getArchivedCompanyDataExport({
      companyId: row.original.id,
    })
    return formatArchivedCompanyDataExport(data)
  }, [getArchivedCompanyDataExport, row.original.id])

  const getArchivedCompanyIdentityExport = usePromiseLazyQuery<
    GetArchivedCompanyIdentityExportQuery,
    GetArchivedCompanyIdentityExportQueryVariables
  >(QUERY_ARCHIVED_COMPANY_IDENTITY_EXPORT, 'no-cache')

  const getArchivedCompanyIdentityExportData = React.useCallback(async () => {
    const { data } = await getArchivedCompanyIdentityExport({
      companyId: row.original.id,
    })
    return formatArchivedCompanyIdentityExport(data)
  }, [getArchivedCompanyIdentityExport, row.original.id])

  const handleModalClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      icon: ArchiveTableModal,
    ) => {
      e.stopPropagation()
      setModal({ company: row.original, name: icon })
    },
    [row, setModal],
  )

  const handleDownloadClick = React.useCallback(
    async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()

      downloadZip(
        [
          {
            filename: 'archived-company-data.csv',
            data: await getArchivedCompanyDataExportData(),
          },
          {
            filename: 'archived-company-identity.csv',
            data: await getArchivedCompanyIdentityExportData(),
          },
        ],
        'archived-company.zip',
      )
    },
    [getArchivedCompanyDataExportData, getArchivedCompanyIdentityExportData],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        css={{ cursor: 'pointer', marginRight: '10px' }}
        onClick={handleDownloadClick}
      >
        <Tooltip
          title={t('animator.companies.tooltip.downloadCompanyArchive')}
          placement="top-end"
        >
          <Icon strokeColor="black" type="download" size="small" />
        </Tooltip>
      </div>

      {row.original.archiveStatus !== 'ARCHIVED' ? (
        <div
          css={{ cursor: 'pointer', marginRight: '7px' }}
          onClick={e => handleModalClick(e, ArchiveTableModal.UNARCHIVE)}
        >
          <Tooltip
            title={t('animator.companies.tooltip.unArchiveCompany')}
            placement="top-end"
          >
            <Icon color="transparent" strokeColor="black" type="unArchive" />
          </Tooltip>
        </div>
      ) : null}

      <div
        css={{ cursor: 'pointer', marginRight: '7px' }}
        onClick={e => handleModalClick(e, ArchiveTableModal.DELETE)}
      >
        <Tooltip
          title={t('animator.companies.tooltip.deleteCompanyRefusal')}
          placement="top-end"
        >
          <Icon type="trash" />
        </Tooltip>
      </div>
    </div>
  )
}

export default ArchivedCompaniesActionCell
