/** @jsx jsx */
import { jsx } from '@emotion/core'
import Tooltip from '../../../../components/Tooltip'
import React from 'react'
import { Flex } from '../../../../components/Flex'
import { useTranslation } from 'react-i18next'
import { UnArchiveDeploymentButton } from './UnArchiveDeploymentButton'
import { DeleteDeploymentButton } from './DeleteDeploymentButton'
import { DownloadArchivedDeploymentButton } from './DownloadArchivedDeploymentButton'
import { differenceInDays, parseISO } from 'date-fns'

export interface ArchivedDeploymentActionsProps {
  companyId: string
  deploymentId?: string
  stepNbr?: number
  lastUpdated?: string
}

export const ArchivedDeploymentActions: React.FC<
  ArchivedDeploymentActionsProps
> = ({ companyId, deploymentId, lastUpdated }) => {
  const { t } = useTranslation()

  const ARCHIVAL_DAYS_DIFF = 7
  const today = new Date()
  const lastUpdatedDate = parseISO(lastUpdated || today.toString())
  const unArchivable =
    differenceInDays(today, lastUpdatedDate) < ARCHIVAL_DAYS_DIFF

  return (
    <Flex direction="row" justify="center" align="center" gap="8px">
      <Tooltip
        title={t('animator.dashboard.tooltips.download')}
        placement="top-end"
        css={{ cursor: 'pointer' }}
      >
        <DownloadArchivedDeploymentButton
          id={deploymentId}
          companyId={companyId}
        />
      </Tooltip>

      {unArchivable ? (
        <Tooltip
          title={t('animator.dashboard.tooltips.unarchive')}
          placement="top-end"
          css={{ cursor: 'pointer' }}
        >
          <UnArchiveDeploymentButton id={deploymentId} />
        </Tooltip>
      ) : null}

      <Tooltip
        title={t('animator.dashboard.tooltips.delete')}
        placement="top-end"
        css={{ cursor: 'pointer' }}
      >
        <DeleteDeploymentButton id={deploymentId} />
      </Tooltip>
    </Flex>
  )
}
