import { get } from 'react-hook-form'
import { useMemo } from 'react'

export const useDistinctBy = <T>(arr: unknown[], key: string): T[] => {
  return useMemo(() => {
    return arr
      .map(v => get(v, key))
      .filter((v, i, arr) => v != null && arr.indexOf(v) === i)
  }, [arr, key])
}

export const useDistinctById = <T extends { id: string }>(arr: T[]): T[] => {
  return useMemo(() => {
    return arr.filter((v, i, arr) => arr.findIndex(o => o.id === v.id) === i)
  }, [arr])
}
