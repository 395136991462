/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState } from 'react'
import { Flex } from '../../../../../components/Flex'
import { motion } from 'framer-motion'
import theme from '../../../../../styles/theme'
import Icon from '../../../../../components/Icon'
import { useCompaniesWithDeployments } from './useCompaniesWithDeployments'
import { usePagination } from '../../../../../hooks/usePagination'
import Pagination from '../../../../../components/Pagination'
import { useCompaniesSearchCount } from './useCompaniesSearchCount'
import {
  CompanySearch,
  Deployment,
  SortOrder,
  GetCompaniesExportQuery,
  GetCompaniesExportQueryVariables,
} from '../../../../../__generated__/graphql'
import { useQuery } from '@apollo/client'
import { QUERY_ANIMATOR_DEPLOYMENTS } from '../../../../../utils/gql/queries'
import Table from '../../../../../components/Table'

import { useTranslation } from 'react-i18next'
import { CellProps, Column } from 'react-table'
import { DeploymentStepLabel } from '../../../Deployment/components/DeploymentStepLabel'
import { useOrderBy } from '../../../../../hooks/useOrderBy'
import { NavLink } from 'react-router-dom'
import { NavigationLink } from '../../../../../components/NavigationLink'
import { DeploymentActions } from '../../../Deployment/components/DeploymentActions'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../redux/rootReducer'
import { UserRoles } from '../../../../../redux/user'
import { CompanyActions } from './CompanyActions'

import DownloadCSVButton from '../../../../../components/Button/DownloadCSVButton'
import usePromiseLazyQuery from '../../../../../hooks/usePromiseLazyQuery'

import {
  formatCompaniesExport,
  QUERY_COMPANIES_EXPORT,
} from '../../../../../utils/gql/companiesExport'

export interface CompanyDeploymentsGroupedListProps {
  filters: Record<string, unknown>
}

export const CompanyDeploymentsGroupedList: React.FC<
  CompanyDeploymentsGroupedListProps
> = ({ filters }) => {
  const pagination = usePagination({ take: 10 })
  const variables = {
    archived: false,
    ...filters,
    ...pagination.variables,
  }

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `organisations-participantes-${formatedDate}`

  const { data } = useCompaniesWithDeployments({
    variables: {
      ...variables,
      orderBy: { name: SortOrder.Asc },
    },
  })
  const { data: countData } = useCompaniesSearchCount({ variables })

  const getCompanies = usePromiseLazyQuery<
    GetCompaniesExportQuery,
    GetCompaniesExportQueryVariables
  >(QUERY_COMPANIES_EXPORT)

  const getExportData = async () => {
    const { data } = await getCompanies({
      ...variables,
    })

    return formatCompaniesExport(data)
  }

  return (
    <Flex direction="column" gap="2.1rem">
      <Flex direction="column">
        {data?.companiesSearch.map((company: CompanySearch) => (
          <CompanyDeploymentsGroupedListRow
            key={company.id}
            company={company}
          />
        ))}
      </Flex>
      {countData && (
        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            {...pagination.getPaginationProps({
              totalData: countData.companiesSearchCount,
            })}
          />
          <div
            css={{
              display: 'flex',
              justifySelf: 'flex-end',
              position: 'absolute',
              right: 21,
            }}
          >
            <DownloadCSVButton
              getData={getExportData}
              filename={filename}
              disabled={false}
            />
          </div>
        </div>
      )}
    </Flex>
  )
}

const variants = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
}

export interface CompanyDeploymentsGroupedListRowProps {
  company: CompanySearch
}

export const CompanyDeploymentsGroupedListRow: React.FC<
  CompanyDeploymentsGroupedListRowProps
> = ({ company }) => {
  const [isFolded, setIsFolded] = useState(true)
  const { t } = useTranslation()
  const role = useSelector((state: StoreState) => state.user.role)
  return (
    <Flex direction="column">
      <Flex
        role="button"
        onClick={() => setIsFolded(!isFolded)}
        direction="row"
        align="center"
        gap="32px"
        css={{
          backgroundColor: theme.colors.primary,
          color: theme.colors.white,
          cursor: 'pointer',
          fontSize: '18px',
          lineHEight: '20px',
          height: '45px',
          padding: '0 16px',
        }}
      >
        <div css={{ width: '64px' }}>{company.subscriptionCode}</div>
        <div css={{ fontWeight: 700, flex: '3 1 0' }}>
          <NavLink
            css={{ textDecoration: 'underline', color: theme.colors.white }}
            to={
              role === UserRoles.ADMIN
                ? `/administrator/companies/${company.id}/profile`
                : `/animator/companies/${company.id}/profile`
            }
            onClick={e => e.stopPropagation()}
          >
            {company.name}
          </NavLink>
        </div>
        <div css={{ flex: '1 1 0' }}>
          {company.siteCount}{' '}
          {t('animator.companies.acceptCompany.deployments.sites')}
        </div>
        <div css={{ flex: '2 1 0' }}>
          {company.deploymentCount}{' '}
          {t('animator.companies.acceptCompany.deployments.inProgress')}
        </div>
        <div onClick={e => e.stopPropagation()} css={{ flex: '0 1 0' }}>
          <CompanyActions
            companyId={company.id || ''}
            companyName={company.name || ''}
          />
        </div>
        <motion.div
          initial="closed"
          animate={isFolded ? 'closed' : 'open'}
          variants={variants}
          transition={{ duration: 0.2 }}
          css={{
            marginLeft: '20px',
            [theme.media.mobile]: { alignSelf: 'flex-start' },
          }}
        >
          <Icon color="white" strokeColor="white" type="foldTransparent" />
        </motion.div>
      </Flex>
      {!isFolded && (
        <CompanyDeploymentsGroupedListTable
          companyId={company.id as string}
          isFolded={isFolded}
        />
      )}
    </Flex>
  )
}

interface CompanyDeploymentsGroupedListTableProps {
  companyId: string
  isFolded?: boolean
}

export const CompanyDeploymentsGroupedListTable: React.FC<
  CompanyDeploymentsGroupedListTableProps
> = ({ companyId, isFolded }) => {
  const { orderBy, getTableProps } = useOrderBy()
  const { data, loading } = useQuery(QUERY_ANIMATOR_DEPLOYMENTS, {
    variables: { company: companyId, archived: false, orderBy },
    skip: isFolded,
  })
  const { t } = useTranslation()
  const role = useSelector((state: StoreState) => state.user.role)

  return (
    <Table
      {...getTableProps()}
      variant="subTable"
      columns={[
        {
          Header: t(
            'animator.companies.acceptCompany.deployments.name',
          ) as string,
          Cell: ({ value, row }: CellProps<Deployment>) => (
            <NavigationLink
              to={
                role === UserRoles.ADMIN
                  ? `/administrator/dashboard/${row.original.id}/progress`
                  : `/animator/dashboard/${row.original.id}/progress`
              }
            >
              {value}
            </NavigationLink>
          ),
          accessor: 'name',
          maxWidth: 250,
        },
        {
          Header: t(
            'animator.companies.acceptCompany.deployments.site',
          ) as string,
          Cell: ({ row: { original } }: CellProps<Deployment>) =>
            original.site.name,
          accessor: 'siteId',
        },
        {
          Header: t(
            'animator.companies.acceptCompany.deployments.fleet',
          ) as string,
          Cell: ({ row: { original } }: CellProps<Deployment>) => (
            <Flex justify="center" align="center">
              {original.fleet?.name}
            </Flex>
          ),
          accessor: 'fleetId',
        },
        {
          Header: t(
            'animator.companies.acceptCompany.deployments.phase',
          ) as string,
          Cell: ({ row: { original } }: CellProps<Deployment>) => (
            <DeploymentStepLabel deployment={original} />
          ),
          accessor: 'phase',
          disableSortBy: true,
        },
        {
          Header: t(
            'animator.companies.acceptCompany.deployments.employees',
          ) as string,
          Cell: ({ row: { original } }: CellProps<Deployment>) => (
            <Flex justify="center" align="center">
              {original.site.employeesOnSite}
            </Flex>
          ),
          accessor: 'employeesOnSite',
        },
        {
          Header: t(
            'animator.companies.acceptCompany.deployments.candidates',
          ) as string,
          // TODO link to actual value when it is available
          Cell: ({ row: { original } }: CellProps<Deployment>) => (
            <Flex justify="center" align="center">
              {original.candidateCount}
            </Flex>
          ),
          accessor: 'candidateCount',
          disableSortBy: true,
        },
        {
          Header: t(
            'animator.companies.acceptCompany.deployments.actions',
          ) as string,
          Cell: ({ row: { original } }: CellProps<Deployment>) => (
            <DeploymentActions
              deploymentId={original.id}
              stepNbr={original.programStep.stepNbr}
              archived={false}
            />
          ),
          accessor: 'actions',
          disableSortBy: true,
          cellStyle: () => ({ overflow: 'visible' }),
        } as Column,
      ]}
      data={data?.deployments ?? []}
      loading={loading}
    />
  )
}

export default CompanyDeploymentsGroupedList
