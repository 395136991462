/** @jsx jsx */

import { Cell } from 'react-table'

export enum FleetStockLevel {
  LOW = 'low',
  MEDIUM = 'medium',
  IMPORTANT = 'important',
}

export const getStockLevel = (
  total: number,
  stockNumber: number,
): FleetStockLevel => {
  if (stockNumber > 0.8 * total) return FleetStockLevel.IMPORTANT
  else if (stockNumber > 0.6 * total) return FleetStockLevel.MEDIUM
  else return FleetStockLevel.LOW
}

export const FleetStockConditionCellStyle = (
  fleetStockValue: number,
  cell: Cell,
): { backgroundColor: string; textAlign: string } => {
  const { totalBike } = cell.row.values

  switch (getStockLevel(totalBike, fleetStockValue)) {
    case FleetStockLevel.LOW:
      return { backgroundColor: '#F7BCBC', textAlign: 'center' }
    case FleetStockLevel.MEDIUM:
      return { backgroundColor: '#FBDFA0', textAlign: 'center' }
    case FleetStockLevel.IMPORTANT:
      return { backgroundColor: '#D4EF9B', textAlign: 'center' }
  }
}
