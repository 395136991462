import { gql, QueryResult, useQuery } from '@apollo/client'
import {
  GetDeploymentEmployeesQuery,
  GetDeploymentEmployeesQueryVariables,
} from '../../../../__generated__/graphql'

const QUERY_DEPLOYMENT_EMPLOYEES = gql`
  query GetDeploymentEmployees($id: String!) {
    deployment(id: $id) {
      id
      deploymentStartDate
      deploymentEndDate
      deploymentType
      loanQuantityBikes
      programStep {
        stepNbr
      }
      employeeDeployments {
        id
        status
        option
        totalDistance
        lastRideDate
        loanPossibleAction
        deploymentId
        depositId
        depositByCheque
        depositExpirationDate
        depositReleasedAt

        remissionOrderUrl

        chosenBikeModel {
          bikemodel {
            name
            brand
            bikeType
          }
        }

        bikeLoan {
          id
          loanDate
          loanEndDate
          bikeReturned
          bikeDeliveredDate
          bikeReturnedDate
          retentionOnDeposit

          bike {
            id
            morioId
            stickerId
          }
        }

        employee {
          id
          firstName
          lastName
          avatarUrl
          civility
          city
          street
          postalCode
          birthdate
          cityOfBirth
          country
          height
          index
          profileCompleted

          identityCardFrontFilePath
          identityCardBackFilePath
          identityCardVerified
          proofOfAddressFilePath
          proofOfAddressVerified

          depositId
          depositByCheque
          depositExpirationDate
          depositReleasedAt

          phoneNumber

          order(deploymentId: $id)
          hasMobileApp
          contractAccepted
          workTravelDistance
          workTravelDuration

          ridesCount
          homeWorkRides
          totalDistanceInKm
          bikeDaysUsed(deploymentId: $id)
          dailyDistance(deploymentId: $id)
          totalMoneySaved
          totalEnergySpent
          totalKgCO2Saved

          currentLoanPoints
          interest

          bikeLoan {
            employeeDeploymentId
            retentionOnDeposit
            bikeReturnedDate
            bikeDeliveredDate
            bike {
              stickerId
            }
          }

          employeeBikeHabit {
            name
          }

          company {
            name
          }

          sites {
            name
          }

          user {
            id
            email
            emailVerified
          }

          travelMode {
            name
          }

          bikeParkingType {
            name
          }

          loanDeliveryAppointment {
            id
            date
            location
            deploymentId
          }

          loanReturnAppointment {
            id
            date
            location
            deploymentId
          }

          absences {
            id
            category
            deploymentId
          }
        }
      }
    }
  }
`

export const useDeploymentEmployees = (
  id: string,
): QueryResult<
  GetDeploymentEmployeesQuery,
  GetDeploymentEmployeesQueryVariables
> => {
  return useQuery(QUERY_DEPLOYMENT_EMPLOYEES, {
    variables: { id },
  })
}
