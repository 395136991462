/** @jsx jsx */
import React, { Fragment, useEffect, useState } from 'react'
import Modal from '../../../../components/Modal'
import Button from '../../../../components/Button'
import { useTranslation } from 'react-i18next'
import { jsx } from '@emotion/core'
import Popup from '../../../../components/Popup'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'

import {
  QUERY_ALL_BIKE_COMPONENT_PARTS,
  QUERY_ALL_FLEET_NAMES,
  QUERY_GET_ANIMATOR,
  QUERY_GET_BIKE_CONDITION,
  QUERY_GET_BIKE_MODELS,
  QUERY_GET_BIKES,
  QUERY_STATE_OF_USE,
  QUERY_AREAS,
} from '../../../../utils/gql/queries'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'

import Input from '../../../../components/Input'
import { useTheme } from 'emotion-theming'
import BikeListInput from './BikeListInput'
import {
  FleetModalLineBike,
  FleetsTableRow,
} from '../../../../types/AnimatorFleets'

import {
  MUTATION_CREATE_FLEET,
  MUTATION_UPDATE_FLEET,
} from '../../../../utils/gql/mutations'

import { useSelector } from 'react-redux'
import { StoreState } from '../../../../redux/rootReducer'

import InputSelect from '../../../../components/InputSelect'

import { UserRoles } from '@goodwatt/shared/dist/types'
import i18n from '../../../../i18n/config'
import {
  BikeComponentCreateWithoutBikeInput,
  BikeCreateWithoutFleetInput,
  BikeUpdateManyWithoutFleetInput,
  BikeUpdateWithWhereUniqueWithoutFleetInput,
  CreateOneFleetMutation,
  CreateOneFleetMutationVariables,
  FleetCreateInput,
  GetAllBikeComponentPartsQuery,
  GetAllBikeComponentPartsQueryVariables,
  GetAllFleetNamesQuery,
  GetAllFleetNamesQueryVariables,
  GetAnimtorQuery,
  GetAnimtorQueryVariables,
  GetAreasQuery,
  GetBikeConditionQuery,
  GetBikeConditionQueryVariables,
  GetBikeModelQuery,
  GetBikeModelQueryVariables,
  GetBikesQuery,
  GetBikesQueryVariables,
  GetStateOfUseQuery,
  GetStateOfUseQueryVariables,
  UpdateOneFleetMutation,
  UpdateOneFleetMutationVariables,
} from '../../../../__generated__/graphql'

interface ModalProps {
  isOpen: boolean
  closeModal: (shouldRefetch?: boolean) => void
  fleet: FleetsTableRow | undefined
}

const DEFAULT_ROW_NUMBER = 0

const ModalFleet: React.FC<ModalProps> = ({ isOpen, fleet, closeModal }) => {
  const [bikeModels, setBikeModels] = useState<GetBikeModelQuery['bikeModels']>(
    [],
  )
  const [allFleetNames, setAllFleetNames] = useState<string[]>([])
  const [bikeConditionStock, setBikeConditionStock] = useState<
    GetBikeConditionQuery['bikeCondition'] | null
  >(null)

  const [numberOfRows, setNumberOfRows] = useState(0)
  const [bikes, setBikes] = useState<FleetModalLineBike[]>([])
  const [bikeErrors, setBikeErrors] = useState<GetBikesQuery>()

  const [allAreas, setAllAreas] = useState<GetAreasQuery['areas']>([])

  const [fleetName, setFleetName] = useState(fleet?.name ? fleet.name : '')
  const [fleetNameError, setFleetNameError] = useState(false)

  const userId = useSelector((state: StoreState) => state.user.id)
  const notifications = useNotifications()
  const { t } = useTranslation()
  const theme = useTheme()

  const { role: userRole } = useSelector((state: StoreState) => state.user)
  const isAdmin = userRole === UserRoles.ADMIN

  const onModalClose = (shouldRefetch = false) => {
    setFleetName('')
    setBikes([])
    setNumberOfRows(DEFAULT_ROW_NUMBER)
    setBikeErrors(undefined)
    closeModal(shouldRefetch)
  }

  const { data: bikeComponentParts, loading: loadingBikeComponentParts } =
    useQuery<
      GetAllBikeComponentPartsQuery,
      GetAllBikeComponentPartsQueryVariables
    >(QUERY_ALL_BIKE_COMPONENT_PARTS)

  const { data: stateOfUse, loading: loadingStateOfUse } = useQuery<
    GetStateOfUseQuery,
    GetStateOfUseQueryVariables
  >(QUERY_STATE_OF_USE, {
    variables: {
      where: {
        name: 'excellent',
      },
    },
  })

  const { data: animator } = useQuery<
    GetAnimtorQuery,
    GetAnimtorQueryVariables
  >(QUERY_GET_ANIMATOR, {
    variables: {
      where: { userId },
    },
  })

  const [areaId, setAreaId] = useState(fleet?.area?.id || 2)

  const [getFleetNames] = useLazyQuery<
    GetAllFleetNamesQuery,
    GetAllFleetNamesQueryVariables
  >(QUERY_ALL_FLEET_NAMES, {
    onCompleted: data => {
      if (data?.fleets) {
        let fleets = data.fleets

        if (fleet) fleets = fleets.filter(f => f.id !== fleet.id)

        setAllFleetNames(fleets.map(fleet => fleet.name.toLowerCase()))
      }
    },
    onError: error =>
      apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
    fetchPolicy: 'network-only',
  })

  const [getAreas] = useLazyQuery<GetAreasQuery>(QUERY_AREAS, {
    onCompleted: data => {
      if (!data.areas) return

      setAllAreas(data.areas)
    },
    onError: error =>
      apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
    fetchPolicy: 'network-only',
  })

  const [getBikeErrors, { loading: bikeErrorsLoading }] = useLazyQuery<
    GetBikesQuery,
    GetBikesQueryVariables
  >(QUERY_GET_BIKES, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data) {
        setBikeErrors(data)
      }

      const createInput: BikeCreateWithoutFleetInput[] = []
      const updateInput: BikeUpdateWithWhereUniqueWithoutFleetInput[] = []

      for (const bike of bikes) {
        if (!bike.stickerId || !bike.morioId) return

        const exist = data?.bikes
          .filter(x => x.fleet.id === fleet?.id)
          .find(x => x.id === bike.id)

        if (exist) {
          const updateBikeValues: BikeUpdateWithWhereUniqueWithoutFleetInput = {
            data: {
              stickerId: {
                set: Number(bike.stickerId),
              },
              morioId: {
                set: bike.morioId,
              },
              bikeModel: {
                connect: {
                  id: bike.modelId,
                },
              },
            },
            where: {
              id: bike.id,
            },
          }

          if (bike.bicycode) {
            updateBikeValues.data.bicycode = {
              set: bike.bicycode,
            }
          }

          if (bike.keysId) {
            updateBikeValues.data.keysId = {
              set: bike.keysId,
            }
          }

          updateInput.push(updateBikeValues)
        } else {
          const bikeComponentPartsInput: BikeComponentCreateWithoutBikeInput[] =
            bikeComponentParts!.bikeComponentParts.map(bcp => ({
              bikeComponentPart: {
                connect: { id: bcp.id },
              },
              stateOfUse: {
                connect: { id: stateOfUse!.stateOfUse!.id! },
              },
            }))

          const createBikeValue: BikeCreateWithoutFleetInput = {
            stickerId: parseInt(bike.stickerId!.toString()),
            morioId: bike.morioId,
            bikeCondition: {
              connect: {
                id: bikeConditionStock?.id,
              },
            },
            bikeModel: {
              connect: {
                id: bike.modelId,
              },
            },
            bikeComponents: { create: bikeComponentPartsInput },
          }

          if (bike.bicycode) {
            createBikeValue.bicycode = bike.bicycode
          }

          if (bike.keysId) {
            createBikeValue.keysId = bike.keysId
          }

          createInput.push(createBikeValue)
        }
      }

      if (!fleet) submitCreateFleet(createInput)
      else submitUpdateFleet(createInput, updateInput)
    },
  })

  const [getBikeModelInfo] = useLazyQuery<
    GetBikeModelQuery,
    GetBikeModelQueryVariables
  >(QUERY_GET_BIKE_MODELS, {
    onCompleted: data => {
      if (data?.bikeModels) {
        setBikeModels(data.bikeModels)
      }
    },
    onError: error =>
      apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
    fetchPolicy: 'network-only',
    variables: {},
  })

  const [getBikes] = useLazyQuery<GetBikesQuery, GetBikesQueryVariables>(
    QUERY_GET_BIKES,
    {
      onCompleted: data => {
        if (data?.bikes) {
          const dataFormated = data.bikes.map(bike => ({
            id: bike.id,
            bicycode: bike.bicycode,
            keysId: bike.keysId,
            morioId: bike.morioId,
            stickerId: bike.stickerId,
            modelId: bike.bikeModel.id,
          })) as FleetModalLineBike[]
          if (fleet) {
            setBikes(dataFormated)
          }
        }
      },
      onError: error =>
        apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
      fetchPolicy: 'network-only',
      variables: {
        where: {
          fleetId: {
            equals: fleet?.id,
          },
        },
      },
    },
  )

  const [getBikeConditionInfo] = useLazyQuery<
    GetBikeConditionQuery,
    GetBikeConditionQueryVariables
  >(QUERY_GET_BIKE_CONDITION, {
    onCompleted: data => {
      if (data?.bikeCondition) {
        setBikeConditionStock(data.bikeCondition)
      }
    },
    onError: error =>
      apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
    fetchPolicy: 'network-only',
  })

  const [createFleet, { loading: createFleetLoading }] = useMutation<
    CreateOneFleetMutation,
    CreateOneFleetMutationVariables
  >(MUTATION_CREATE_FLEET)

  const [updateFleet, { loading: updateFleetLoading }] = useMutation<
    UpdateOneFleetMutation,
    UpdateOneFleetMutationVariables
  >(MUTATION_UPDATE_FLEET)

  const isLoading =
    createFleetLoading ||
    bikeErrorsLoading ||
    updateFleetLoading ||
    loadingStateOfUse ||
    loadingBikeComponentParts

  useEffect(() => {
    setAreaId(0)

    if (isOpen) {
      if (fleet) {
        getBikes()
        setNumberOfRows(fleet.totalBike || 0)
        setAreaId(fleet.area?.id || 2)
      } else {
        setBikes([])
        setNumberOfRows(DEFAULT_ROW_NUMBER)
        setAreaId(animator?.animator?.areaId || 2)
      }

      getBikeModelInfo({
        variables: {
          where: {
            name: {
              in: [
                'Gitane eC01-D9 (L)',
                'Gitane eC01-D9 (M)',
                'eC01 D9 (46)',
                'eC01 D9 (51)',
              ],
            },
          },
        },
      })
      getBikeConditionInfo({
        variables: {
          where: {
            name: 'stock',
          },
        },
      })
      getFleetNames()
      getAreas()
    }
  }, [
    animator,
    isOpen,
    getBikeConditionInfo,
    getBikeModelInfo,
    getFleetNames,
    getAreas,
    getBikes,
    userId,
    fleet,
  ])

  useEffect(() => {
    setFleetName(fleet?.name ? fleet.name : '')
  }, [fleet])

  const handleSubmit = async () => {
    if (
      !fleetName ||
      !!allFleetNames.find(name => name === fleetName.toLowerCase())
    ) {
      setFleetNameError(true)
      return
    }

    if (bikes.find(bike => !bike.stickerId)) return

    const stickerIds = bikes.map(bike => parseInt(bike.stickerId!.toString()))
    const morioIds = bikes.map(bike => bike.morioId)
    const bicycodes = bikes
      .map(bike => bike.bicycode)
      .filter(x => x !== null) as string[]
    const keysIds = bikes
      .map(bike => bike.keysId)
      .filter(x => x !== null) as string[]

    if (
      new Set(stickerIds).size !== stickerIds.length &&
      new Set(morioIds).size !== morioIds.length &&
      new Set(bicycodes).size !== bicycodes.length &&
      new Set(keysIds).size !== keysIds.length
    )
      return

    const whereBikeErrors = {
      OR: [
        { stickerId: { in: stickerIds } },
        { morioId: { in: morioIds } },
        { bicycode: { in: bicycodes } },
        { keysId: { in: keysIds } },
      ],
    }

    getBikeErrors({
      variables: {
        where: whereBikeErrors,
      },
    })
  }

  const submitCreateFleet = (bikeInputs: BikeCreateWithoutFleetInput[]) => {
    const createFleetPayload: FleetCreateInput = {
      name: fleetName,
      area: {
        connect: { id: areaId },
      },
      bikes: { create: bikeInputs },
    }
    createFleet({
      variables: { data: createFleetPayload },
    }).then(() => {
      notifications.newNotification({
        type: NotificationTypes.SUCCESS,
        message: i18n.t('animator.fleets.notification.successCreate'),
      })
      setFleetName('')
      setBikes([])
      onModalClose(true)
    })
  }

  const submitUpdateFleet = (
    bikeInputs: BikeCreateWithoutFleetInput[],
    bikeUpdateInput: BikeUpdateWithWhereUniqueWithoutFleetInput[],
  ) => {
    if (!fleet?.id) return

    const bikesUpdate: BikeUpdateManyWithoutFleetInput = {}
    if (bikeInputs.length) bikesUpdate.create = bikeInputs
    if (bikeUpdateInput.length) bikesUpdate.update = bikeUpdateInput

    updateFleet({
      variables: {
        data: {
          name: { set: fleetName },
          bikes: bikesUpdate,
          area: {
            connect: {
              id: areaId,
            },
          },
        },
        where: {
          id: fleet?.id,
        },
      },
    }).then(() => {
      notifications.newNotification({
        type: NotificationTypes.SUCCESS,
        message: i18n.t('animator.fleets.notification.successUpdate'),
      })
      onModalClose(true)
    })
  }

  const onFleetNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFleetName(e.target.value)
    setFleetNameError(false)
  }

  const onAreaChange = React.useCallback(({ value }) => {
    setAreaId(value)
  }, [])

  return (
    <Modal isOpen={isOpen}>
      <Popup
        overflowY="auto"
        closable
        title={
          !fleet
            ? t('animator.fleets.modal.add.title')
            : t('animator.fleets.modal.edit.title')
        }
        onClose={() => onModalClose(false)}
        maxWidth={1000}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button onClick={() => onModalClose(false)} type="tertiary">
                {t('shared.button.cancel')}
              </Button>
            </div>
            <Button
              type="primary"
              submit
              onClick={handleSubmit}
              loading={isLoading}
            >
              {t('forms.button.save')}
            </Button>
          </Fragment>
        }
      >
        <div
          css={{ display: 'flex', flexDirection: 'column', padding: '0 15px' }}
        >
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <label
              css={{
                fontSize: '1.6rem',
                color: theme.colors.gray3,
                marginRight: '25px',
                width: '50%',
              }}
            >
              {t('animator.fleets.modal.inputs.fleetName')}
            </label>
            <div
              css={{
                width: '100%',
                marginLeft: '25px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Input
                type="text"
                name="fleetName"
                withError={fleetNameError}
                helperText={
                  !fleetName
                    ? t('animator.fleets.modal.errors.fleetNameEmpty')
                    : t('animator.fleets.modal.errors.fleetNameAlreadyUsed')
                }
                value={fleetName}
                error={fleetNameError}
                onChange={onFleetNameChange}
              />
            </div>
          </div>
          {isAdmin && allAreas.length && areaId && (
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '25px',
              }}
            >
              <label
                css={{
                  fontSize: '1.6rem',
                  color: theme.colors.gray3,
                  marginRight: '25px',
                  width: '50%',
                }}
              >
                {t('animator.fleets.modal.inputs.area')}
              </label>
              <div
                css={{
                  width: '100%',
                  marginLeft: '25px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <InputSelect
                  name="areaId"
                  options={allAreas.map(x => ({
                    label: x.name,
                    value: x.id,
                  }))}
                  defaultValue={areaId}
                  onChange={onAreaChange}
                  withError={false}
                />
              </div>
            </div>
          )}
          <div css={{ marginTop: '40px' }}>
            <div
              css={{
                fontSize: '1.6rem',
                color: theme.colors.gray3,
                marginBottom: '10px',
              }}
            >
              {t('animator.fleets.modal.inputs.tableTitle')}
            </div>
            <BikeListInput
              key={fleet?.id}
              numberOfRow={numberOfRows}
              bikeModels={bikeModels}
              bikes={bikes}
              onBikesChange={bikes => setBikes(bikes)}
              bikeErrors={
                bikeErrors
                  ? bikeErrors.bikes.filter(x => x.fleet.id !== fleet?.id)
                  : []
              }
            />
          </div>
          <div
            css={{
              cursor: 'pointer',
              textDecoration: 'underline',
              color: theme.colors.blue1,
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
            onClick={() => setNumberOfRows(numberOfRows + 1)}
          >
            {t('animator.fleets.modal.table.addBike')}
          </div>
        </div>
      </Popup>
    </Modal>
  )
}

export default ModalFleet
