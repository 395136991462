/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import {
  BikeComponentPartType,
  StateOfUseType,
} from '@goodwatt/shared/dist/types'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'

interface EntityStatesOfUseProps {
  entities: {
    name: string
    translatedName: string
    stateOfUse: StateOfUseType
  }[]
  entityTitle: string
}

const EntityStatesOfUse: React.FC<EntityStatesOfUseProps> = ({
  entities,
  entityTitle,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <div
      css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          height: 35,
        }}
      >
        <div
          css={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
            borderTopLeftRadius: '22px',
            width: 185,
            borderRight: `2px solid ${theme.colors.white}`,
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            paddingLeft: 15,
          }}
        >
          <span>{entityTitle}</span>
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            width: 120,
            height: '100%',
            color: theme.colors.white,
            backgroundColor: theme.colors.primary,
            borderTopRightRadius: '22px',
            paddingLeft: 15,
          }}
        >
          <span>{t('shared.label.state')}</span>
        </div>
      </div>
      {entities.map(
        ({ name, stateOfUse, translatedName }, index, initialArr) => (
          <div
            key={`${name}_${index}`}
            css={{
              display: 'flex',
              alignItems: 'center',
              height: 35,
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: 185,
                backgroundColor: theme.colors.gray6,
                borderTop: `2px solid ${theme.colors.white}`,
                borderRight: `2px solid ${theme.colors.white}`,
                paddingLeft: 15,
                textAlign: 'left',
                ...(index === initialArr.length - 1
                  ? {
                      borderBottomLeftRadius: '22px',
                    }
                  : {}),
              }}
            >
              <span
                css={{
                  fontWeight:
                    name === BikeComponentPartType.GLOBAL ? 'bold' : 'normal',
                }}
              >
                {translatedName}
              </span>
            </div>
            <div
              css={{
                display: 'flex',
                width: 120,
                height: '100%',
                alignItems: 'center',
                paddingLeft: 15,
                borderTop: `2px solid ${theme.colors.white}`,
                backgroundColor: theme.colors.gray6,
                ...(index === initialArr.length - 1
                  ? {
                      borderBottomRightRadius: '22px',
                    }
                  : {}),
              }}
            >
              <span>{t(`shared.stateOfUseLabel.${stateOfUse}`)}</span>
            </div>
          </div>
        ),
      )}
    </div>
  )
}

export default EntityStatesOfUse
