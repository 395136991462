/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { types } from '@goodwatt/shared'
import CardTitle from '../../../../../components/Card/CardTitle'
import CardFoldable from '../../../../../components/Card/Foldable'
import Input from '../../../../../components/Input'
import InputSelect from '../../../../../components/InputSelect'
import List from './List'

import { TextVariant } from '../../../../../hooks/useVariant/variants/text'
import { SelectVariant } from '../../../../../hooks/useVariant/variants/select'

let debouncedFn: undefined | (() => void)
const PAGESIZE = 30

interface SubscribedEmployeesProps {
  companyStepNbr: number
}

const SubscribedEmployees: React.FC<SubscribedEmployeesProps> = ({
  companyStepNbr,
}) => {
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({})
  const theme = useTheme()

  const onSelectChange = React.useCallback(({ value }, { name }) => {
    setFilters(prevState => ({
      ...prevState,
      [name]: value !== 'all' ? value : null,
    }))
  }, [])

  const onInputNameChange = React.useCallback(e => {
    /* signal to React not to nullify the event object */
    e.persist()

    if (!debouncedFn) {
      debouncedFn = debounce(() => {
        setFilters(prevState => ({
          ...prevState,
          multiFilter: e.target.value || undefined,
        }))
      }, 300)
    }
    debouncedFn()
  }, [])

  React.useEffect(() => {
    return () => {
      debouncedFn = undefined
    }
  }, [])

  const travelModeFilterOptions = React.useMemo(
    () => [
      {
        label: t(
          'animator.company.employees.subscribed.filter.mode.options.all',
        ),
        value: 'all',
      },
      ...Object.values(types.TravelModeType)
        .map(travelMode => ({
          label: t(
            `animator.company.employees.subscribed.filter.mode.options.${travelMode}`,
          ),
          value: travelMode,
        }))
        .sort(({ label: a }, { label: b }) => a.localeCompare(b)),
    ],
    [t],
  )

  const parkingTypeFilterOptions = React.useMemo(
    () => [
      {
        label: t(
          'animator.company.employees.subscribed.filter.parkingType.options.all',
        ),
        value: 'all',
      },
      ...Object.values(types.ParkingType).map(parkingType => ({
        label: t(
          `animator.company.employees.subscribed.filter.parkingType.options.${parkingType}`,
        ),
        value: parkingType,
      })),
    ],
    [t],
  )

  React.useEffect(() => (debouncedFn = undefined), [])

  return (
    <CardFoldable
      title={
        <CardTitle
          statusColor={theme.colors.gray4}
          label={t('animator.company.employees.subscribed.titleLabel')}
        />
      }
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          marginTop: '2.1rem',
          marginBottom: '2.1rem',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <div
          css={{
            display: 'flex',
            flex: 1,
            minWidth: '220px',
            paddingRight: '17px',
            width: '100%',
            [theme.media.mobile]: {
              paddingRight: '0px',
            },
          }}
        >
          <InputSelect<string>
            name="travelMode"
            withError={false}
            variant={SelectVariant.white}
            label={t('animator.company.employees.subscribed.filter.mode.label')}
            defaultValue="all"
            onChange={onSelectChange}
            options={travelModeFilterOptions}
          />
        </div>
        <div
          css={{
            display: 'flex',
            flex: 1,
            minWidth: '220px',
            paddingRight: '17px',
            width: '100%',
            [theme.media.mobile]: {
              paddingRight: '0px',
              marginTop: '14px',
            },
          }}
        >
          <InputSelect<string>
            name="bikeParkingType"
            withError={false}
            variant={SelectVariant.white}
            label={t(
              'animator.company.employees.subscribed.filter.parkingType.label',
            )}
            defaultValue="all"
            onChange={onSelectChange}
            options={parkingTypeFilterOptions}
          />
        </div>
        <div
          css={{
            display: 'flex',
            flex: 2,
            minWidth: '220px',
            [theme.media.tablet]: {
              marginTop: '14px',
            },
          }}
        >
          <Input
            icon="magnifier"
            variant={TextVariant.white}
            name="companySubscribedEmployeesAutoComplete"
            withError={false}
            onChange={onInputNameChange}
          />
        </div>
      </div>
      <List
        filters={filters}
        pageSize={PAGESIZE}
        companyStepNbr={companyStepNbr}
      />
    </CardFoldable>
  )
}

export default SubscribedEmployees
