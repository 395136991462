/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  BikeComponentPartType,
  StateOfUseType,
} from '@goodwatt/shared/dist/types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StoreState } from '../../../redux/rootReducer'
import { PartsConditionTableRow } from '../../../types/AnimatorCompanyEmployees'
import StarRating from '../../StarRating'
import { BikeComponent } from '../../../__generated__/graphql'

interface BikeComponentStarRatingCellProps {
  bikeComponent: PartsConditionTableRow & { touched: boolean }
  returningBike: boolean
  onStarClickAction: (
    payload: Partial<
      | StoreState['forms']['loanBikeForm']
      | StoreState['forms']['returnBikeForm']
    >,
  ) => void
  savedBikeComponents: BikeComponent[]
}

const BikeComponentStarRatingCell: React.FC<
  BikeComponentStarRatingCellProps
> = ({
  bikeComponent,
  returningBike,
  onStarClickAction,
  savedBikeComponents,
}) => {
  const dispatch = useDispatch()
  const { components } = useSelector((state: StoreState) => {
    return returningBike ? state.forms.returnBikeForm : state.forms.loanBikeForm
  })
  const currentSavedBikeComponent = React.useMemo(
    () =>
      savedBikeComponents.find(
        savedComponent =>
          savedComponent.bikeComponentPart.name === bikeComponent.name,
      ),
    [bikeComponent.name, savedBikeComponents],
  )
  const [defaultStateOfUse, setDefaultStateOfUse] =
    React.useState<StateOfUseType>()
  const [updatedStateOfUse, setUpdatedStateOfUse] = React.useState<
    StateOfUseType | undefined
  >()

  React.useEffect(() => {
    if (!defaultStateOfUse && currentSavedBikeComponent)
      setDefaultStateOfUse(
        currentSavedBikeComponent.stateOfUse.name as StateOfUseType,
      )
  }, [currentSavedBikeComponent, defaultStateOfUse])

  React.useEffect(() => {
    if (bikeComponent.touched && bikeComponent.stateOfUse) setUpdatedStateOfUse(bikeComponent.stateOfUse); // prettier-ignore
  }, [bikeComponent.stateOfUse, bikeComponent.touched, updatedStateOfUse])

  const onClick = (newStateOfUse: StateOfUseType) => {
    dispatch(
      onStarClickAction({
        components: {
          ...components,
          [bikeComponent.name as BikeComponentPartType]: {
            ...bikeComponent,
            touched: true,
            stateOfUse: newStateOfUse,
          },
        },
      }),
    )
    setUpdatedStateOfUse(newStateOfUse)
  }

  return (
    <StarRating
      id={bikeComponent.id || ''}
      currentStateOfUse={defaultStateOfUse}
      updatedStateOfUse={updatedStateOfUse}
      onRatingClick={onClick}
    />
  )
}

export default BikeComponentStarRatingCell
