import { QueryResult, useQuery } from '@apollo/client'
import {
  GetCompanyProfileQuery,
  GetCompanyProfileQueryVariables,
} from '../../../../__generated__/graphql'
import { QUERY_GET_COMPANY } from '../../../../utils/gql/queries'

export const useCompany = (
  id: string,
  isOpen: boolean,
): QueryResult<GetCompanyProfileQuery> => {
  return useQuery<GetCompanyProfileQuery, GetCompanyProfileQueryVariables>(
    QUERY_GET_COMPANY,
    { variables: { where: { id } }, skip: !id || !isOpen },
  )
}
