/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useMemo, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Column, Row } from 'react-table'
import { onlyNumber } from '@goodwatt/shared/dist/regex'
import { ProgramStepType } from '@goodwatt/shared/dist/types'

import Table, {
  ColumnSortBy,
  initialColumnSortBy,
} from '../../../../../components/Table'
import CompanySubscribedEmployeesActionCell from '../../../../../components/Table/CustomCells/CompanySubscribedEmployeesActionCell'

import {
  CompanySubscribedEmployeesModalSettings,
  CompanySubscribedEmployeesTableModalActions,
  CompanySubscribedEmployeesTableRow,
} from '../../../../../types/AnimatorCompanySubscribedEmployees'
import SendMailButton from '../../../../../components/Button/SendMail'
import DownloadCSVButton from '../../../../../components/Button/DownloadCSVButton'
import { useTheme } from 'emotion-theming'

import EditEmployeeProfileModal from '../../../../Shared/EditEmployeeModal'
import Pagination from '../../../../../components/Pagination'
import LoanMaxQuantityReachModal from './LoanMaxQuantityReachModal'

import {
  EmployeeTableRefetchContext,
  EmployeeTableTypes,
} from '../EmployeeTableRefetchContext'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../../contexts/NotificationContext'
import EditEmployeeHabitsModal from '../../../../Shared/EditEmployeeHabitsModal'
import ConfirmEmailModal from './ConfirmEmailModal'
import ConfirmDeleteModal from './ConfirmDeleteModal'

import apiErrorCatcher from '../../../../../utils/apiErrorCatcher'

import { employeeNameFormatter } from '@goodwatt/client/src/utils/formatter'

import {
  QUERY_COMPANY_EMPLOYEES_EXPORT,
  formatEmployeesExport,
} from '@goodwatt/client/src/utils/gql/employeesExport'
import usePromiseLazyQuery from '../../../../../hooks/usePromiseLazyQuery'
import EmployeeOptionsCell from '../../../../../components/Table/CustomCells/EmployeeOptionsCell'
import {
  CompanySubscribedEmployeesCountFilteredQuery,
  CompanySubscribedEmployeesCountFilteredQueryVariables,
  GetCompanyEmployeesExportQuery,
  GetCompanyEmployeesExportQueryVariables,
  GetCompanySubscribedMaxLoanQuery,
  GetCompanySubscribedMaxLoanQueryVariables,
  GetEmployeeQuery,
  GetSubscribedCompanyEmployeesQuery,
  GetSubscribedCompanyEmployeesQueryVariables,
  SubscribedCompanyEmployeeOrderByInput,
  UpdateSelectLoanEmployeeMutation,
  UpdateSelectLoanEmployeeMutationVariables,
} from '../../../../../__generated__/graphql'

const QUERY_SUBSCRIBED_COMPANY_EMPLOYEES = gql`
  query GetSubscribedCompanyEmployees(
    $companyId: String!
    $travelMode: String
    $bikeParkingType: String
    $multiFilter: String
    $orderBy: SubscribedCompanyEmployeeOrderByInput
    $pageSize: Int
    $offset: Int
  ) {
    subscribedCompanyEmployees(
      companyId: $companyId
      travelMode: $travelMode
      bikeParkingType: $bikeParkingType
      multiFilter: $multiFilter
      orderBy: $orderBy
      pageSize: $pageSize
      offset: $offset
    ) {
      id
      email
      firstName
      lastName
      profileCompleted
      identityCardFrontFilePath
      proofOfAddressFilePath
      selectLoan
      bikeParkingType
      travelMode
      order
      workTravelDistance
      height
      specialBikeType
      specialBikeAbsolutelyNeeded
      babySeat
      emailVerified
    }
  }
`

const QUERY_COMPANY_SUBSCRIBED_MAX_LOAN = gql`
  query GetCompanySubscribedMaxLoan($where: CompanyWhereUniqueInput!) {
    company(where: $where) {
      programStep {
        name
        stepNbr
      }
      loanQuantityBikes
    }
  }
`

const QUERY_COMPANY_SUBSCRIBED_COUNT_FILTERED = gql`
  query CompanySubscribedEmployeesCountFiltered($where: EmployeeWhereInput) {
    companyEmployeesCount(where: $where)
  }
`

const MUTATION_UPGRADE_SUBSCRIBED_EMPLOYEE = gql`
  mutation UpdateSelectLoanEmployee(
    $employeeId: String!
    $selectBool: Boolean!
  ) {
    updateSelectLoanEmployee(
      employeeId: $employeeId
      selectEmployee: $selectBool
    ) {
      id
    }
  }
`

const initialModalSettings: CompanySubscribedEmployeesModalSettings = {
  employee: null,
  actionName: '',
}

interface SubscribedEmployeesListProps {
  filters: {
    [filterName: string]: string
  }
  pageSize: number
  companyStepNbr: ProgramStepType
}

const SubscribedEmployeesList: React.FC<SubscribedEmployeesListProps> = ({
  filters,
  pageSize,
  companyStepNbr,
}) => {
  const {
    refetchTables,
    removeTableToFetch,
    tableRefetchPlanner: { [EmployeeTableTypes.ALL]: needToRefetch },
  } = React.useContext(EmployeeTableRefetchContext)

  const { t } = useTranslation()
  const notifications = useNotifications()
  const theme = useTheme()
  const [currPage, setCurrPage] = useState(1)
  const { id: companyId } = useParams<{ id: string }>()
  const [orderBy, setOrderBy] = useState<ColumnSortBy>(initialColumnSortBy)
  const [currModal, setCurrModal] = useState(initialModalSettings)
  const [sendEmailDisabled, setSendEmailDisabled] = useState(false)
  const resetModal = () => setCurrModal(initialModalSettings)

  const whereClause = {
    companyId: { equals: companyId },
    AND: [
      filters.travelMode
        ? {
            travelMode: {
              name: { contains: filters.travelMode },
            },
          }
        : {},
      filters.bikeParkingType
        ? {
            bikeParkingType: {
              name: { contains: filters.bikeParkingType },
            },
          }
        : {},
      filters.multiFilter
        ? {
            OR: [
              {
                firstName: { contains: filters.multiFilter },
              },
              {
                lastName: { contains: filters.multiFilter },
              },
              ...(onlyNumber.test(filters.multiFilter)
                ? [
                    {
                      workTravelDistance: {
                        equals: parseFloat(filters.multiFilter),
                      },
                    },
                  ]
                : []),
              {
                travelMode: {
                  name: { contains: filters.multiFilter },
                },
              },
            ],
          }
        : {},
    ],
  }
  const {
    data: employeesData,
    loading,
    refetch: refetchEmployees,
  } = useQuery<
    GetSubscribedCompanyEmployeesQuery,
    GetSubscribedCompanyEmployeesQueryVariables
  >(QUERY_SUBSCRIBED_COMPANY_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    variables: {
      companyId,
      travelMode: filters.travelMode,
      bikeParkingType: filters.bikeParkingType,
      multiFilter: filters.multiFilter,
      offset: (currPage - 1) * pageSize,
      pageSize,
      orderBy:
        orderBy.sort && orderBy.columnName
          ? ({
              columnName: orderBy.columnName,
              sort: orderBy.sort,
            } as SubscribedCompanyEmployeeOrderByInput)
          : null,
    },
  })
  const { data: companySubscribedEmployeesCountTotal } = useQuery<
    CompanySubscribedEmployeesCountFilteredQuery,
    CompanySubscribedEmployeesCountFilteredQueryVariables
  >(QUERY_COMPANY_SUBSCRIBED_COUNT_FILTERED, {
    fetchPolicy: 'no-cache',
    variables: {
      where: whereClause,
    },
  })
  const {
    data: countCompanyTotalSelectedForLoan,
    refetch: refetchCountCompanyTotalSelectedForLoan,
  } = useQuery<
    CompanySubscribedEmployeesCountFilteredQuery,
    CompanySubscribedEmployeesCountFilteredQueryVariables
  >(QUERY_COMPANY_SUBSCRIBED_COUNT_FILTERED, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        companyId: { equals: companyId },
        AND: [{ selectLoan: { equals: true } }],
      },
    },
  })
  const [upgradeSubscribedEmployee] = useMutation<
    UpdateSelectLoanEmployeeMutation,
    UpdateSelectLoanEmployeeMutationVariables
  >(MUTATION_UPGRADE_SUBSCRIBED_EMPLOYEE)

  const { data: companyData } = useQuery<
    GetCompanySubscribedMaxLoanQuery,
    GetCompanySubscribedMaxLoanQueryVariables
  >(QUERY_COMPANY_SUBSCRIBED_MAX_LOAN, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: companyId,
      },
    },
  })

  React.useEffect(() => {
    if (needToRefetch) {
      Promise.all([
        refetchCountCompanyTotalSelectedForLoan(),
        refetchEmployees(),
      ]).then(() => removeTableToFetch(EmployeeTableTypes.ALL))
    }
  }, [
    needToRefetch,
    refetchEmployees,
    refetchCountCompanyTotalSelectedForLoan,
    removeTableToFetch,
  ])

  const handleToggleSelect = React.useCallback(
    original => {
      const selectEmployeeForbiddenProgramStep = [
        ProgramStepType.PROGRAM_REVIEW,
      ]
      if (
        selectEmployeeForbiddenProgramStep.includes(
          companyData?.company?.programStep.stepNbr as number,
        )
      ) {
        notifications.newNotification({
          type: NotificationTypes.ERROR,
          message: t(
            'animator.company.employees.selected.failureStepEmployeeSelection',
          ),
        })
        return
      }
      if (original.selectLoan) return
      else if (
        countCompanyTotalSelectedForLoan?.companyEmployeesCount &&
        companyData?.company?.loanQuantityBikes &&
        countCompanyTotalSelectedForLoan?.companyEmployeesCount >=
          companyData?.company?.loanQuantityBikes
      )
        return setCurrModal({
          ...initialModalSettings,
          actionName: CompanySubscribedEmployeesTableModalActions.CHECKBOX,
        })

      upgradeSubscribedEmployee({
        variables: {
          employeeId: original.id,
          selectBool: true,
        },
      })
        .then(() => {
          if (
            countCompanyTotalSelectedForLoan?.companyEmployeesCount &&
            companyData?.company?.loanQuantityBikes &&
            countCompanyTotalSelectedForLoan?.companyEmployeesCount + 1 >=
              companyData?.company?.loanQuantityBikes
          ) {
            setCurrModal({
              ...initialModalSettings,
              actionName: CompanySubscribedEmployeesTableModalActions.CHECKBOX,
            })
          }
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t(
              'animator.company.employees.selected.successEmployeeSelection',
              { employeeName: original.name },
            ),
          })
          refetchTables([EmployeeTableTypes.SELECTED, EmployeeTableTypes.ALL])
          refetchCountCompanyTotalSelectedForLoan()
        })
        .catch(apiErrorCatcher(notifications))
    },
    [
      companyData,
      countCompanyTotalSelectedForLoan,
      notifications,
      refetchCountCompanyTotalSelectedForLoan,
      refetchTables,
      t,
      upgradeSubscribedEmployee,
    ],
  )

  const ActionCell = React.useCallback(
    ({ row }: { row: Row<CompanySubscribedEmployeesTableRow> }) => (
      <CompanySubscribedEmployeesActionCell
        row={row}
        companyStepNbr={companyStepNbr}
        setModal={setCurrModal}
        handleToggleSelect={handleToggleSelect}
        companyLoanMaxQuantityReached={
          !!(
            countCompanyTotalSelectedForLoan?.companyEmployeesCount &&
            companyData?.company?.loanQuantityBikes &&
            countCompanyTotalSelectedForLoan?.companyEmployeesCount >=
              companyData?.company?.loanQuantityBikes
          )
        }
      />
    ),
    [
      handleToggleSelect,
      countCompanyTotalSelectedForLoan,
      companyData,
      companyStepNbr,
    ],
  )

  const OptionsCell = React.useCallback(({ row }) => {
    return <EmployeeOptionsCell row={row} />
  }, [])

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: t(
          'animator.company.employees.subscribed.table.headers.name',
        ) as string,
        accessor: 'name',
        sortField: 'lastName',
        maxWidth: 200,
      },
      {
        Header: t(
          'animator.company.employees.subscribed.table.headers.distance',
        ) as string,
        accessor: 'workTravelDistance',
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: t(
          'animator.company.employees.selected.table.headers.height',
        ) as string,
        accessor: 'height',
      },
      {
        Header: t(
          'animator.company.employees.selected.table.headers.options',
        ) as string,
        Cell: OptionsCell,
      },
      // {
      //   Header: t(
      //     'animator.company.employees.subscribed.table.headers.parkingType',
      //   ) as string,
      //   accessor: 'bikeParkingType',
      //   cellStyle: () => ({ textAlign: 'center' }),
      // },
      {
        Header: t(
          'animator.company.employees.subscribed.table.headers.mode',
        ) as string,
        accessor: 'travelMode',
      },
      {
        Header: t(
          'animator.company.employees.subscribed.table.headers.profile',
        ) as string,
        accessor: 'profileCompleted',
        cellStyle: () => ({ textAlign: 'center' }),
      },
      {
        Header: t(
          'animator.company.employees.subscribed.table.headers.order',
        ) as string,
        accessor: 'order',
      },
      {
        Header: t('animator.companies.table.headers.actions') as string,
        accessor: 'action',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [t, OptionsCell, ActionCell],
  )

  const formattedData = useMemo(() => {
    if (!employeesData?.subscribedCompanyEmployees?.length) {
      return []
    }

    return employeesData.subscribedCompanyEmployees.map(employee => ({
      id: employee?.id,
      name: employeeNameFormatter(employee?.firstName, employee?.lastName),
      emailVerified: employee?.emailVerified,
      workTravelDistance: `${
        employee?.workTravelDistance ? employee.workTravelDistance : 0
      } km`,
      selectLoan: employee?.selectLoan,
      order: employee?.order,
      height: employee?.height ? `${employee?.height} cm` : '',
      specialBikeType: employee?.specialBikeType,
      specialBikeAbsolutelyNeeded: employee?.specialBikeAbsolutelyNeeded,
      babySeat: employee?.babySeat,
      bikeParkingType: employee?.bikeParkingType
        ? t(
            `animator.company.employees.subscribed.filter.parkingType.options.${employee.bikeParkingType}`,
          )
        : '',
      travelMode: employee?.travelMode
        ? t(
            `animator.company.employees.subscribed.filter.mode.options.${employee.travelMode}`,
          )
        : '',
      profileCompleted: employee?.profileCompleted
        ? t('animator.company.employees.subscribed.table.row.profile.complete')
        : '-',
    }))
  }, [employeesData, t])

  const mailList = useMemo(() => {
    if (!employeesData?.subscribedCompanyEmployees?.length) {
      setSendEmailDisabled(true)
      return []
    }
    setSendEmailDisabled(false)
    return employeesData.subscribedCompanyEmployees.reduce<string[]>(
      (acc, employee) => {
        if (employee?.email) {
          return [...acc, employee.email]
        }
        return acc
      },
      [],
    )
  }, [employeesData])

  // Resetting to first page on filter change
  React.useEffect(() => {
    setCurrPage(1)
  }, [filters])

  const handleRefetch = (newData?: any) => {
    if (newData) {
      setCurrModal(prevState => ({
        ...prevState,
        employee: {
          ...(prevState.employee as GetEmployeeQuery['employee']),
          ...newData,
        },
      }))
    }
    refetchEmployees()
  }

  const getAllEmployeeExpot = usePromiseLazyQuery<
    GetCompanyEmployeesExportQuery,
    GetCompanyEmployeesExportQueryVariables
  >(QUERY_COMPANY_EMPLOYEES_EXPORT, 'no-cache')

  const formatedDate = new Intl.DateTimeFormat('fr-FR')
    .format(new Date())
    .replaceAll('/', '-')
  const filename = `employees-${formatedDate}`

  const getExportData = async () => {
    const { data } = await getAllEmployeeExpot({
      employeeWhereInput: {
        companyId: { equals: companyId },
      },
    })
    return formatEmployeesExport(data)
  }

  return (
    <React.Fragment>
      <div css={{ minHeight: 380, display: 'flex', flexDirection: 'column' }}>
        <Table
          columns={columns}
          data={formattedData}
          onSortBy={setOrderBy}
          loading={loading}
        />
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            marginTop: 17,
          }}
        >
          <Pagination
            currentPage={currPage}
            onClick={setCurrPage}
            pageSize={pageSize}
            totalData={
              companySubscribedEmployeesCountTotal?.companyEmployeesCount
            }
          />
          <div
            css={{
              position: 'absolute',
              display: 'flex',
              right: 0,
              [theme.media.mobile]: {
                display: 'none',
              },
            }}
          >
            <DownloadCSVButton getData={getExportData} filename={filename} />
            <SendMailButton mailTo={mailList} disabled={sendEmailDisabled} />
          </div>
        </div>
      </div>
      <EditEmployeeProfileModal
        isOpen={
          currModal.actionName ===
          CompanySubscribedEmployeesTableModalActions.EDIT_PROFILE
        }
        refetch={handleRefetch}
        employee={currModal.employee}
        submitCloseModal={resetModal}
        onClose={resetModal}
      />
      <EditEmployeeHabitsModal
        employee={currModal.employee}
        isOpen={
          currModal.actionName ===
          CompanySubscribedEmployeesTableModalActions.HABITS
        }
        submitCloseModal={resetModal}
        onClose={resetModal}
        refetch={handleRefetch}
      />
      <ConfirmEmailModal
        employee={currModal.employee}
        isOpen={
          currModal.actionName ===
          CompanySubscribedEmployeesTableModalActions.CONFIRM_EMAIL
        }
        onClose={resetModal}
        refetch={handleRefetch}
      />
      <ConfirmDeleteModal
        employee={currModal.employee}
        modalIsOpen={
          currModal.actionName ===
          CompanySubscribedEmployeesTableModalActions.CONFIRM_DELETE
        }
        closeModal={resetModal}
        refetch={_ => {
          handleRefetch()
          refetchTables([EmployeeTableTypes.SELECTED, EmployeeTableTypes.ALL])
          refetchCountCompanyTotalSelectedForLoan()
        }}
      />
      {currModal.actionName ===
        CompanySubscribedEmployeesTableModalActions.CHECKBOX && (
        <LoanMaxQuantityReachModal
          closeModal={resetModal}
          companyLoanQuantityBikes={
            companyData?.company?.loanQuantityBikes ?? 0
          }
        />
      )}
    </React.Fragment>
  )
}

export default SubscribedEmployeesList
