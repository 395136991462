/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { formatBytes, getFileIcon } from '../utils/files'
import Icon from './Icon'

interface FileDisplayProps {
  filename: string
  size: number
  error?: boolean
  onDelete?: () => void
}

const FileDisplay: React.FC<FileDisplayProps> = ({
  filename,
  size,
  error,
  onDelete = null,
}) => {
  const theme = useTheme()

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: `2px solid ${
          !error ? theme.colors.gray6 : theme.colors.redLegend
        }`,
        padding: 6,
        borderRadius: 22,
      }}
    >
      <div css={{ width: 24, height: 24, margin: '0 24px 0 8px' }}>
        <Icon type={getFileIcon(filename)} />
      </div>
      <div
        css={{
          flex: 4,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <span
          css={{
            fontSize: '1.2rem',
          }}
        >
          {filename}
        </span>
      </div>
      <div css={{ flex: 2, justifyContent: 'center' }}>
        <span css={{ fontSize: '1.2rem' }}>{formatBytes(size)}</span>
      </div>
      {onDelete && (
        <button onClick={onDelete} type="button" css={{ flex: 1 }}>
          <Icon type="trash" size="small" color={theme.colors.gray2} />
        </button>
      )}
    </div>
  )
}

export default FileDisplay
