/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { useModal } from '../../../../hooks/useModal'
import Button from '../../../../components/Button'
import ReleaseDepositsConfirmationModal from '../../Company/Employees/SelectedEmployees/ReleaseDeposits/Modal'

export interface ReleaseDepositButtonProps {
  employeeDeployment: any | undefined
}

export const ReleaseDepositButton: React.FC<ReleaseDepositButtonProps> = ({
  employeeDeployment,
}) => {
  const { onOpen, modalProps } = useModal()

  return (
    <Fragment>
      {modalProps.isOpen && (
        <ReleaseDepositsConfirmationModal
          employees={employeeDeployment}
          closeModal={modalProps.onClose}
          refetch={() => {}}
        />
      )}
      <Button icon="releaseDeposit" onClick={onOpen} />
    </Fragment>
  )
}
