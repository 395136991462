/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'

import React from 'react'

import JSZip from 'jszip'
import iconv from 'iconv-lite'

import Button from '.'

interface DownloadCSVButtonProps {
  data?: Array<any>
  getData?: () => Promise<Array<any>>
  filename: string
  disabled?: boolean
}

const processRow = (row: Array<any>) => {
  let finalVal = ''
  for (let j = 0; j < row.length; j++) {
    let innerValue = row[j] === null ? '' : row[j].toString()
    if (row[j] instanceof Date) {
      innerValue = row[j].toLocaleString()
    }
    let result = innerValue.replace(/"/g, '""')
    if (result.search(/("|;|\n)/g) >= 0) result = '"' + result + '"'
    if (j > 0) finalVal += ';'
    finalVal += result
  }
  return finalVal + '\n'
}

const downloadFile = (fileContent: string, filename: string) => {
  const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), fileContent], {
    type: 'text/csv;charset=utf-8;',
  })

  downloadBlob(blob, filename)
}

function downloadBlob(blob: Blob, filename: string) {
  // @ts-ignore
  if (navigator.msSaveBlob) {
    // IE 10+
    // @ts-ignore
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export const downloadCSVFile = (data: Array<any>, filename: string): void => {
  const fileContent = data
    .map(row => {
      const inlineData = Object.values(row).map(x =>
        x === undefined ? null : x,
      )
      return processRow(inlineData)
    })
    .join('')

  downloadFile(fileContent, filename)
}

export const downloadZip = async (
  files: Array<{ data: Array<any>; filename: string }>,
  zipName: string,
): Promise<void> => {
  const zip = new JSZip()

  files.forEach(({ filename, data }) => {
    const csv = data
      .map(row => {
        const inlineData = Object.values(row).map(x =>
          x === undefined ? null : x,
        )
        return processRow(inlineData)
      })
      .join('')

    zip.file(filename, iconv.encode(csv, 'utf-8', { addBOM: true }), {
      binary: true,
    })
  })

  const blob = await zip.generateAsync({ type: 'blob' })
  downloadBlob(blob, zipName)
}

const DownloadCSVButton: React.FC<DownloadCSVButtonProps> = ({
  data,
  getData,
  filename,
  disabled,
}) => {
  const theme = useTheme()

  const handleDownload = async () => {
    let contentData = []

    if (data) {
      contentData = data
    }

    if (getData) {
      contentData = await getData()
    }

    const fileContent = contentData
      .map(row => {
        const inlineData = Object.values(row).map(x =>
          x === undefined ? null : x,
        )
        return processRow(inlineData)
      })
      .join('')

    downloadFile(fileContent, filename)
  }

  return (
    <Button
      disabled={disabled}
      submit
      onClick={_ => handleDownload()}
      icon="download"
      iconColor={theme.colors.white}
    />
  )
}

export default DownloadCSVButton
