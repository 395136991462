/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'

import { IconTypes } from './Icon'
import useVariant from '../hooks/useVariant'
import {
  TextVariant,
  TextVariantsConfig,
} from '../hooks/useVariant/variants/text'

type RefReturn =
  | string
  | ((instance: HTMLTextAreaElement | null) => void)
  | React.RefObject<HTMLTextAreaElement>
  | null
  | undefined

export interface InputProps {
  name?: string
  rows?: number
  value?: string
  variant?: TextVariant
  icon?: IconTypes
  onChange?(e: React.ChangeEvent<HTMLTextAreaElement>): void
  placeholder?: string
  error?: boolean
  disabled?: boolean
  resizable?: boolean
  block?: boolean
  label?: string
  helperText?: string
  // RefReturn type is needed for react-hook-form package
  register?: () => RefReturn
  withError?: boolean
}

type Props = InputProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >

const TextArea: React.FC<Props> = ({
  rows = 5,
  name,
  resizable = false,
  label,
  value,
  icon,
  variant = TextVariant.gray,
  onChange: onChangeProp,
  error,
  disabled,
  placeholder,
  block = true,
  helperText,
  withError = true,
  register,
  ...props
}) => {
  const theme = useTheme()
  const textVariantConfig = useVariant('text')[
    variant
  ] as TextVariantsConfig[typeof variant]

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChangeProp) onChangeProp(e)
  }

  return (
    <div
      css={{
        width: block ? '100%' : 'fit-content',
      }}
    >
      {label && (
        <div
          css={{
            marginLeft: 24,
            marginBottom: 4,
          }}
        >
          <label
            css={{
              fontSize: '1.6rem',
              color: theme.colors.gray3,
            }}
          >
            {label}
          </label>
        </div>
      )}
      <div
        css={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: textVariantConfig.bckgColor,
          borderRadius: 22,
          boxSizing: 'border-box',
          border: error
            ? `2px solid ${theme.colors.error}`
            : textVariantConfig.border,
          transition: 'border 0.2s ease-in',
          paddingRight: '21px',
          paddingLeft: icon ? '50px' : '21px',
        }}
      >
        <textarea
          rows={rows}
          size={1}
          css={{
            resize: resizable ? 'both' : 'none',
            flex: 1,
            fontFamily: 'Roboto',
            fontSize: '1.6rem',
            boxSizing: 'border-box',
            padding: '10px 0px 11px',
            border: 'none',
            outline: 'none',
            color: theme.colors.gray1,
            backgroundColor: 'transparent',
            transition: 'color 0.2s ease-in',
            boxShadow: 'none',
            '::placeholder': {
              fontStyle: 'italic',
              fontWeight: 300,
              color: theme.colors.gray3,
            },
          }}
          id={name}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          ref={register}
          {...props}
        />
      </div>
      {withError && (
        <div
          css={{
            marginLeft: 24,
            height: 20,
          }}
        >
          {helperText && (
            <span
              css={{
                fontFamily: 'Roboto',
                fontSize: '1.2rem',
                color: error ? theme.colors.error : theme.colors.gray3,
              }}
            >
              {helperText}
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default TextArea
