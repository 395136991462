/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Input from '../components/Input'
import Button from '../components/Button'
import AuthContainer from '../components/AuthContainer'
import { useMutation } from '@apollo/client'
import { useNotifications } from '../contexts/NotificationContext'
import { loginAction, UserState } from '../redux/user'
import i18n from '../i18n/config'
import apiErrorCatcher from '../utils/apiErrorCatcher'
import AuthenticationCard from '../components/Card/AuthenticationCard'
import { MUTATION_LOGIN } from '../utils/gql/mutations'
import { linkCss } from '../styles/globalStyles'
import { LoginMutation } from '../__generated__/graphql'

const schema = yup.object().shape({
  email: yup
    .string()
    .email(i18n.t('forms.errors.wrongFormat'))
    .required(i18n.t('forms.errors.required')),
  password: yup.string().required(i18n.t('forms.errors.required')),
})

interface SignInInputs {
  email: string
  password: string
}

const SignIn: React.FC = () => {
  const [login, { loading }] = useMutation<LoginMutation>(MUTATION_LOGIN)
  const dispatch = useDispatch()
  const notifications = useNotifications()
  const history = useHistory()
  const { register, handleSubmit, errors } = useForm<SignInInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const { t } = useTranslation()

  const onSubmit = (data: SignInInputs) => {
    data.email = data.email.toLowerCase()
    login({ variables: data })
      .then(result => {
        if (!result.data?.login?.token) return
        dispatch(
          loginAction({
            id: result.data.login.user?.id || '',
            token: result.data.login.token,
            email: result.data.login.user?.email || '',
            name: result.data.login.user?.name || '',
            companyId: result.data.login?.user?.companyId || '',
            companyName: result.data.login?.user?.companyName || '',
            companyProgramStep:
              result.data.login.user?.companyProgramStep || undefined,
            role: result.data.login.user?.userRole.name as UserState['role'],
            subscriptionCode: result.data.login?.user?.companyCode || '',
          }),
        )

        if (result.data.login.user?.userRole.name === 'COMPANY')
          return history.push('/company/dashboard')
        if (result.data.login.user?.userRole.name === 'EMPLOYEE') {
          if (result.data.login.user?.nbrConnections === 1) {
            return history.push('/employee/on-boarding')
          }
          return history.push('/employee/dashboard')
        }
        if (result.data.login.user?.userRole.name === 'ANIMATOR')
          return history.push('/animator/dashboard')
        if (result.data.login.user?.userRole.name === 'ADMIN') {
          return history.push('/administrator/dashboard')
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <AuthContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('auth.signin.metaTitle')}</title>
        <link rel="canonical" href="http://goodwatt.fr/" />
      </Helmet>
      <AuthenticationCard title={t('auth.signin.title')}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="text"
            label={t('forms.label.email')}
            name="email"
            defaultValue=""
            register={register}
            error={!!errors.email}
            helperText={errors?.email?.message}
          />
          <Input
            type="password"
            label={t('forms.label.password')}
            name="password"
            register={register}
            error={!!errors.password}
            helperText={errors?.password?.message}
          />
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Button type="primary" submit loading={loading}>
              {t('auth.signin.button')}
            </Button>
          </div>
          <div
            css={{
              marginTop: '40px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Link
              to="/forgot-password"
              css={{
                ...linkCss,
                textAlign: 'center',
                marginBottom: '10px',
              }}
            >
              {t('auth.signin.forgottenPassword')}
            </Link>
            <Link
              to="/signup-employee"
              css={{
                ...linkCss,
                textAlign: 'center',
                marginBottom: '14px',
              }}
            >
              {t('auth.signin.notSubscribed')}
            </Link>
          </div>
          {['staging', 'development', 'preprod'].includes(
            process.env.REACT_APP_NODE_ENV as string,
          ) && (
            <div>
              <p css={{ fontSize: '1.0rem' }}>1 = Centre-Est</p>
              <p css={{ fontSize: '1.0rem' }}>2 = Nord - Ile-de-France</p>
              <p css={{ fontSize: '1.0rem' }}>3 = Nord-Est</p>
              <p css={{ fontSize: '1.0rem' }}>4 = Ouest</p>
              <p css={{ fontSize: '1.0rem' }}>5 = Sud</p>
              <p
                css={{ fontSize: '1.2rem', fontWeight: 'bold', marginTop: 12 }}
              >
                Compte animateur
              </p>
              <p css={{ fontSize: '1.0rem' }}>
                dev+animator_[1, 2, 3, 4, 5]@goodwatt.fr
              </p>
              <p css={{ fontSize: '1.0rem' }}>Bonjour1</p>
              {process.env.REACT_APP_NODE_ENV !== 'preprod' && (
                <Fragment>
                  <p
                    css={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      marginTop: 12,
                    }}
                  >
                    Compte organisation
                  </p>
                  <p css={{ fontSize: '1.0rem' }}>
                    dev+company_[1, 2, 3, 4, 5]@goodwatt.fr
                  </p>
                  <p css={{ fontSize: '1.0rem' }}>Bonjour1</p>
                </Fragment>
              )}
            </div>
          )}
        </form>
      </AuthenticationCard>
    </AuthContainer>
  )
}

export default SignIn
