/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  BabySeatOptions,
  Deployment,
  DeploymentBikeModelPrice,
} from '../../../../__generated__/graphql'

import React, { Fragment, useMemo, useState } from 'react'
import { Flex } from '../../../../components/Flex'
import Checkbox from '../../../../components/Checkbox'
import { useHistory, useParams } from 'react-router-dom'
import { useDeploymentBikeModels } from '../hooks/useDeploymentBikeModels'
import Button from '../../../../components/Button'
import { useTranslation } from 'react-i18next'
import { useCreateEmployeeDeployment } from '../hooks/useCreateEmployeeDeployment'
import { BikeModelCard } from './BikeModelCard'
import { useModal } from '../../../../hooks/useModal'
import SelectedDeploymentModal from '../../Deployments/components/SelectedDeploymentModal'

enum SpecialBikesType {
  UrbanBike = 'URBAN_BIKE',
  FoldingBike = 'FOLDING_BIKE',
  CargoBike = 'CARGO_BIKE',
}

export const BikeModelsList: React.FC = () => {
  const { deploymentId } = useParams<{ deploymentId: string }>()
  const { data } = useDeploymentBikeModels(deploymentId)

  return (
    <div
      css={{
        display: 'grid',
        gap: '16px',
        gridTemplateColumns: 'repeat(3, 1fr)',
      }}
    >
      {Object.values(SpecialBikesType).map(value => {
        const bikes = data?.deployment?.bikeModelPrices?.filter(i => i != null)
        if (!bikes) return null

        return bikes
          .filter(i => i?.bikemodel.bikeType === value)
          .map(bikeModelPrice => (
            <BikeModelListRow
              key={(bikeModelPrice as DeploymentBikeModelPrice).id}
              deployment={data!.deployment!}
              pricing={bikeModelPrice as DeploymentBikeModelPrice}
            />
          ))
      })}
    </div>
  )
}

export interface BikeModelsListRowProps {
  deployment: Pick<
    Deployment,
    | 'id'
    | 'name'
    | 'deploymentType'
    | 'deploymentStartDate'
    | 'deploymentEndDate'
  >
  pricing: Pick<
    DeploymentBikeModelPrice,
    'id' | 'remainingPrice' | 'bikemodel' | 'childSeat'
  >
}

export const BikeModelListRow: React.FC<BikeModelsListRowProps> = ({
  deployment,
  pricing,
}) => {
  const ChildSeat = !!pricing.childSeat
  const { t } = useTranslation()

  const hasChildSeat = useMemo(() => {
    return ChildSeat
  }, [ChildSeat])

  const [babySeatOption, setBabySeatOptions] = useState(
    BabySeatOptions.NotNeeded,
  )
  const [create, { loading }] = useCreateEmployeeDeployment()
  const history = useHistory()

  const handleSubmit = async () => {
    await create({
      variables: {
        data: {
          deploymentId: deployment.id,
          chosenBikeModelId: pricing.id,
          option: babySeatOption,
        },
      },
    })

    history.push('/employee/dashboard')
  }
  const { onOpen, modalProps } = useModal()

  const bikeOption = useMemo(() => {
    return babySeatOption === BabySeatOptions.Needed ||
      babySeatOption === BabySeatOptions.AbsolutelyNeeded
      ? 'childSeat'
      : 'none'
  }, [babySeatOption])

  return (
    <BikeModelCard deployment={deployment} pricing={pricing}>
      <SelectedDeploymentModal
        id={deployment.id}
        deploymentName={deployment.name}
        deploymentType={deployment.deploymentType}
        startDate={deployment.deploymentStartDate}
        endDate={deployment.deploymentEndDate}
        bikeType={pricing.bikemodel.bikeType}
        bikeBrand={pricing.bikemodel.brand}
        bikeModel={pricing.bikemodel.name}
        bikeOption={bikeOption}
        onSubmit={handleSubmit}
        {...modalProps}
      />
      {hasChildSeat && (
        <Fragment>
          <Flex
            direction="row"
            gap="16px"
            align="center"
            css={{ fontWeight: 600, marginTop: '8px' }}
          >
            <Checkbox
              isChecked={babySeatOption !== BabySeatOptions.NotNeeded}
              onClick={() =>
                setBabySeatOptions(o =>
                  o === BabySeatOptions.NotNeeded
                    ? BabySeatOptions.Needed
                    : BabySeatOptions.NotNeeded,
                )
              }
            />
            <label>{t('employee.application.needChildSeat')}</label>
          </Flex>
          {babySeatOption !== BabySeatOptions.NotNeeded && (
            <Flex
              direction="column"
              gap="8px"
              css={{
                background: '#F7E9371A',
                borderRadius: '16px',
                padding: '16px',
                marginTop: '8px',
              }}
            >
              <p>{t('employee.application.yourRequestIs')}</p>
              <Flex
                direction="column"
                gap="8px"
                css={{
                  padding: '0 8px',
                }}
              >
                <Flex direction="row" gap="8px" align="center">
                  <Checkbox
                    isChecked={
                      babySeatOption === BabySeatOptions.AbsolutelyNeeded
                    }
                    onClick={() =>
                      setBabySeatOptions(BabySeatOptions.AbsolutelyNeeded)
                    }
                  />
                  <label>{t('employee.application.requiredRequest')}</label>
                </Flex>
                <Flex direction="row" gap="8px" align="center">
                  <Checkbox
                    isChecked={babySeatOption === BabySeatOptions.Needed}
                    onClick={() => setBabySeatOptions(BabySeatOptions.Needed)}
                  />
                  <label>{t('employee.application.optionalRequest')}</label>
                </Flex>
                <p>{t('employee.application.optionalRequestWarning')} </p>
              </Flex>
            </Flex>
          )}
        </Fragment>
      )}
      <div css={{ alignSelf: 'center', marginTop: '1em' }}>
        <Button disabled={loading} onClick={onOpen}>
          Choisir ce vélo
        </Button>
      </div>
    </BikeModelCard>
  )
}
