"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MORIO_MINIMUM_RIDE_DISTANCE = exports.REWARD_TOKEN = exports.RATING_WEIGHTS = exports.DEFAULT_FALLBACK_PRISMA_ERROR_MESSAGE = exports.floatTolerance = exports.REF_STATE_OF_USE_RATING = exports.BUCKET_BIKE_MODEL = exports.BUCKET_BIKE_LOAN_ATTACHMENT = exports.BUCKET_EMPLOYEE_ADDRESS = exports.BUCKET_EMPLOYEE_ID_CARDS = exports.BUCKET_COMPANY_CONVENTION = exports.BUCKET_RAFFLE_GAME_AGREEMENT = exports.BUCKET_RAFFLEGAME_IMAGES = exports.BUCKET_MESSAGES_IMAGES = exports.BUCKET_DOCUMENT = exports.BUCKET_AVATAR = exports.BUCKET_CONTRACT_COMPANY = exports.BUCKET_CONTRACT = exports.MAX_DOCUMENT_SIZE = exports.SUPPORTED_FORMATS_DOCS = exports.SUPPORTED_FORMATS_IMG = exports.MAX_AVATAR_SIZE = exports.PRODUCTIVITY_GAINS_FACTOR = exports.SAVING_PARKING_COSTS_FACTOR = exports.SAVING_MILEAGE_COSTS_FACTOR = exports.SICK_DAYS_FACTOR = exports.DEPOSIT_AMOUNT = exports.PRESIGNED_URL_DURATION = void 0;
const types_1 = require("./types");
exports.PRESIGNED_URL_DURATION = 86400;
exports.DEPOSIT_AMOUNT = 250;
/**
 * Used for company statistics in sqlRawQueries/
 */
exports.SICK_DAYS_FACTOR = 1.7442;
exports.SAVING_MILEAGE_COSTS_FACTOR = 102.828;
exports.SAVING_PARKING_COSTS_FACTOR = 1000;
exports.PRODUCTIVITY_GAINS_FACTOR = 3936.6;
/**
 * Upload avatar
 */
exports.MAX_AVATAR_SIZE = 5 * 1024 * 1024;
exports.SUPPORTED_FORMATS_IMG = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
];
// https://developer.mozilla.org/fr/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
exports.SUPPORTED_FORMATS_DOCS = {
    // pdf
    'application/pdf': 'pdf',
    // doc / docx
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    // ppt / pptx
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    // xls / xlsx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-excel': 'xls',
    // images
    'image/jpeg': 'jpeg',
    'image/png': 'png',
};
exports.MAX_DOCUMENT_SIZE = 5 * 1024 * 1024;
exports.BUCKET_CONTRACT = 'contract';
exports.BUCKET_CONTRACT_COMPANY = 'contract-company';
exports.BUCKET_AVATAR = 'avatar';
exports.BUCKET_DOCUMENT = 'document';
exports.BUCKET_MESSAGES_IMAGES = 'message-images';
exports.BUCKET_RAFFLEGAME_IMAGES = 'raffle-game-images';
exports.BUCKET_RAFFLE_GAME_AGREEMENT = 'raffle-game-agreement';
exports.BUCKET_COMPANY_CONVENTION = 'company-convention';
exports.BUCKET_EMPLOYEE_ID_CARDS = 'employee-id-card';
exports.BUCKET_EMPLOYEE_ADDRESS = 'employee-proof-of-address';
exports.BUCKET_BIKE_LOAN_ATTACHMENT = 'bike-loan-attachment';
exports.BUCKET_BIKE_MODEL = 'bike-model-images';
exports.REF_STATE_OF_USE_RATING = {
    ratingFromStateOfUse: {
        [types_1.StateOfUseType.OUT_OF_USE]: 1,
        [types_1.StateOfUseType.USED]: 2,
        [types_1.StateOfUseType.GOOD_CONDITION]: 3,
        [types_1.StateOfUseType.EXCELLENT]: 4,
        [types_1.StateOfUseType.MISSED]: -1,
    },
    stateOfUseFromRating: {
        1: types_1.StateOfUseType.OUT_OF_USE,
        2: types_1.StateOfUseType.USED,
        3: types_1.StateOfUseType.GOOD_CONDITION,
        4: types_1.StateOfUseType.EXCELLENT,
        '-1': types_1.StateOfUseType.MISSED,
    },
};
exports.floatTolerance = 0.000001;
exports.DEFAULT_FALLBACK_PRISMA_ERROR_MESSAGE = 'Not authorized';
exports.RATING_WEIGHTS = {
    TRIAL: {
        valid: 5,
        invalid: 1,
    },
    PROFILE_COMPLETED: {
        valid: 2,
        invalid: 1,
    },
};
exports.REWARD_TOKEN = {
    rides_first_home_work: {
        level: types_1.RewardLevel.BEGINNER,
        ignoreForBadgeCount: false,
        point: 25,
    },
    rides_1month_home_work: {
        level: types_1.RewardLevel.CONFIRMED,
        ignoreForBadgeCount: false,
        point: 500,
    },
    rides_first: {
        level: types_1.RewardLevel.BEGINNER,
        ignoreForBadgeCount: false,
        point: 25,
    },
    rides_first_50km: {
        level: types_1.RewardLevel.INTERMEDIATE,
        ignoreForBadgeCount: false,
        point: 50,
    },
    ecology_bike_1: {
        level: types_1.RewardLevel.BEGINNER,
        ignoreForBadgeCount: false,
        point: 50,
    },
    ride_everyday: {
        level: types_1.RewardLevel.CONFIRMED,
        ignoreForBadgeCount: false,
        point: 100,
    },
    year_utilisation: {
        level: types_1.RewardLevel.INTERMEDIATE,
        ignoreForBadgeCount: false,
        point: 150,
    },
    two_years_utilisation: {
        level: types_1.RewardLevel.CONFIRMED,
        ignoreForBadgeCount: false,
        point: 250,
    },
    three_years_utilisation: {
        level: types_1.RewardLevel.CONFIRMED,
        ignoreForBadgeCount: false,
        point: 350,
    },
    ecology_bike_2: {
        level: types_1.RewardLevel.INTERMEDIATE,
        ignoreForBadgeCount: false,
        point: 100,
    },
    ecology_bike_3: {
        level: types_1.RewardLevel.CONFIRMED,
        ignoreForBadgeCount: false,
        point: 200,
    },
    goals_monthly: {
        level: types_1.RewardLevel.INTERMEDIATE,
        ignoreForBadgeCount: false,
        point: 150,
    },
    rides_first_100km: {
        level: types_1.RewardLevel.BEGINNER,
        ignoreForBadgeCount: false,
        point: 25,
    },
    rides_first_500km: {
        level: types_1.RewardLevel.INTERMEDIATE,
        ignoreForBadgeCount: false,
        point: 150,
    },
    rides_first_1000km: {
        level: types_1.RewardLevel.CONFIRMED,
        ignoreForBadgeCount: false,
        point: 500,
    },
    days_used_5: {
        level: types_1.RewardLevel.CONFIRMED,
        ignoreForBadgeCount: false,
        point: 50,
    },
    days_used_10: {
        level: types_1.RewardLevel.SPECIFIC,
        ignoreForBadgeCount: false,
        point: 150,
    },
    days_used_15: {
        level: types_1.RewardLevel.SPECIFIC,
        ignoreForBadgeCount: false,
        point: 300,
    },
    rides_first_weekend: {
        level: types_1.RewardLevel.INTERMEDIATE,
        ignoreForBadgeCount: false,
        point: 50,
    },
    goals_weekly: {
        level: types_1.RewardLevel.INTERMEDIATE,
        ignoreForBadgeCount: false,
        point: 150,
    },
    photo_upload_first: {
        level: types_1.RewardLevel.BEGINNER,
        ignoreForBadgeCount: false,
        point: 10,
    },
    photo_upload_4: {
        level: types_1.RewardLevel.CONFIRMED,
        ignoreForBadgeCount: false,
        point: 150,
    },
    quizz_first: {
        level: types_1.RewardLevel.BEGINNER,
        point: 10,
        ignoreForBadgeCount: false,
    },
    quizz_4: {
        level: types_1.RewardLevel.INTERMEDIATE,
        ignoreForBadgeCount: false,
        point: 150,
    },
    all_badges_beginner: {
        level: types_1.RewardLevel.BEGINNER,
        ignoreForBadgeCount: true,
        point: 150,
    },
    all_badges_intermediate: {
        level: types_1.RewardLevel.INTERMEDIATE,
        ignoreForBadgeCount: true,
        point: 250,
    },
    all_badges_confirmed: {
        level: types_1.RewardLevel.CONFIRMED,
        ignoreForBadgeCount: true,
        point: 500,
    },
};
exports.MORIO_MINIMUM_RIDE_DISTANCE = 0.5; // km
