import { GetAreaSearchQuery } from '../../__generated__/graphql'

export const formatZonesExport = (
  zonesExportData: GetAreaSearchQuery | undefined,
): any => {
  if (!zonesExportData?.areaSearch?.length) return []

  const header = [
    'Nom de la zone',
    'Nombre de départements',
    'Entreprises Etp. 1',
    'Entreprises Etp. 2',
    'Entreprises Etp. 3',
    'Entreprises Etp. 4',
    'Entreprises Etp. 5',
    'Salariés sélectionnés',
    'Salariés bénéficiaires',
    'Nombre de vélos',
  ]

  return [
    header,
    ...zonesExportData?.areaSearch.map(zone => ({
      name: zone?.name,
      territoryCount: zone?.territoryCount,
      companyStep1Count: zone?.companyStep1Count,
      companyStep2Count: zone?.companyStep2Count,
      companyStep3Count: zone?.companyStep3Count,
      companyStep4Count: zone?.companyStep4Count,
      companyStep5Count: zone?.companyStep5Count,
      employeeSelectedCount: zone?.employeeSelectedCount,
      employeeBikeCount: zone?.employeeBikeCount,
      bikeCount: zone?.bikeCount,
    })),
  ]
}
