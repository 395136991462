/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Icon from '../../../../../../components/Icon'

export type DocumentLineStateType =
  | 'VERIFIED'
  | 'REFUSED'
  | 'NEED_TO_BE_UPLOADED'
  | null

interface DocumentLineProps {
  label?: string
  state?: DocumentLineStateType
  description?: string
  onDownload?: () => void
  onDownloadFront?: () => void
  onDownloadBack?: () => void
  onVerified?: () => void
  onRejected?: (() => void) | null
}

const DocumentLine: React.FC<DocumentLineProps> = ({
  label,
  state,
  description = '',
  onDownload,
  onDownloadFront,
  onDownloadBack,
  onVerified,
  onRejected,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <div
      css={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 24,
      }}
    >
      <div css={{ flex: 1, fontWeight: 'bold' }}>{label}</div>
      <div
        css={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div css={{ flex: 1, textAlign: 'center' }}>
          {state && !description
            ? state === 'NEED_TO_BE_UPLOADED'
              ? t(
                  'animator.company.employees.selected.documents.unavailableDoc',
                )
              : state === 'VERIFIED'
              ? t('animator.company.employees.selected.documents.verified')
              : state === 'REFUSED'
              ? t('animator.company.employees.selected.documents.refused')
              : ''
            : description}
        </div>
        {onDownloadFront && (
          <button
            onClick={onDownloadFront}
            css={{ flex: 1, textDecoration: 'underline' }}
          >
            {t('shared.documents.front')}
          </button>
        )}
        {onDownloadBack && (
          <button
            onClick={onDownloadBack}
            css={{ flex: 1, textDecoration: 'underline', marginLeft: '8px' }}
          >
            {t('shared.documents.back')}
          </button>
        )}
        {onDownload && (
          <button onClick={onDownload} css={{ flex: 1 }}>
            <Icon type="download" size="medium" />
          </button>
        )}
        <div css={{ marginLeft: '24px' }}>
          {onVerified &&
            state !== 'VERIFIED' &&
            state !== 'NEED_TO_BE_UPLOADED' && (
              <button
                onClick={onVerified}
                css={{ flex: 1, marginLeft: '12px' }}
              >
                <Icon type="tick" color="transparent" size="medium" />
              </button>
            )}
          {onRejected &&
            state !== 'REFUSED' &&
            state !== 'NEED_TO_BE_UPLOADED' && (
              <button
                onClick={onRejected}
                css={{ flex: 1, marginLeft: '12px' }}
              >
                <Icon
                  type="block"
                  color={theme.colors.redLegend}
                  size="medium"
                />
              </button>
            )}
        </div>
      </div>
    </div>
  )
}

export default DocumentLine
