/** @jsx jsx */
import { jsx } from '@emotion/core'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import {
  EmployeeBikeHabit,
  ParkingType,
  TravelModeType,
} from '@goodwatt/shared/dist/types'

import Button from '../../components/Button'
import Modal from '../../components/Modal'
import Popup from '../../components/Popup'
import InputSelect from '../../components/InputSelect'
import Input from '../../components/Input'

import {
  NotificationTypes,
  useNotifications,
} from '../../contexts/NotificationContext'
import { MUTATION_UPDATE_ONE_EMPLOYEE } from '../../utils/gql/mutations'

import i18n from '../../i18n/config'
import apiErrorCatcher from '../../utils/apiErrorCatcher'
import { employeeNameFormatter } from '../../utils/formatter'
import {
  GetEmployeeQuery,
  UpdateOneEmployeeMutation,
  UpdateOneEmployeeMutationVariables,
} from '../../__generated__/graphql'

export enum AnchorEditHabitType {
  USER_HABIT_WORK_TRAVEL = 'userHabitWorkTravel',
  USER_HABIT_BIKE_USAGE = 'userHabitBikeUsage',
}

const scrollIntoViewSettings: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'start',
}

interface EditHabitsEmployeeForm {
  travelMode: { value: TravelModeType }
  workTravelDistance: string
  workTravelDuration: string
  averageBikeUsage: { value: EmployeeBikeHabit }
  bikeParking: { value: ParkingType }
}

const schema = yup.object().shape({
  travelMode: yup.object().shape({
    value: yup.string(),
  }),
  workTravelDistance: yup.string(),
  workTravelDuration: yup.string(),
  averageBikeUsage: yup.object().shape({
    value: yup.string(),
  }),
  bikeParking: yup.object().shape({
    value: yup.string(),
  }),

  cgu: yup.boolean(),
})

const travelModeOptions = Object.values(TravelModeType)
  .map(label => ({
    value: label,
    label: i18n.t(`shared.travelModeLabel.${label}`),
  }))
  .sort(({ label: a }, { label: b }) => a.localeCompare(b))
const averageBikeHabitOptions = Object.values(EmployeeBikeHabit).map(label => ({
  value: label,
  label: i18n.t(`shared.employeeBikeHabitLabel.${label}`),
}))

const bikeParkingOptions = Object.values(ParkingType).map(value => ({
  value: value,
  label: i18n.t(`shared.bikeParkingLabel.${value}`),
}))

export interface EditEmployeeHabitsModalProps {
  isOpen: boolean
  anchorOnOpen?: AnchorEditHabitType
  employee: GetEmployeeQuery['employee'] | null
  onClose: () => void
  submitCloseModal?: () => void
  ownEmployeeInfo?: boolean
  refetch?: () => void
  onBoardingMode?: boolean
}

export const EditEmployeeHabitsModal: React.FC<
  EditEmployeeHabitsModalProps
> = ({
  isOpen,
  anchorOnOpen,
  employee,
  onClose,
  submitCloseModal,
  ownEmployeeInfo = false,
  refetch,
  onBoardingMode = false,
}) => {
  const userHabitWorkTravel = React.useRef<HTMLDivElement>(null)
  const userHabitBikeUsage = React.useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const theme = useTheme()
  const notifications = useNotifications()
  const { register, handleSubmit, errors, control } =
    useForm<EditHabitsEmployeeForm>({
      resolver: yupResolver(schema),
      mode: 'onBlur',
    })

  const [updateEmployee, { loading }] = useMutation<
    UpdateOneEmployeeMutation,
    UpdateOneEmployeeMutationVariables
  >(MUTATION_UPDATE_ONE_EMPLOYEE, {
    refetchQueries: ['GetDeploymentEmployees', 'GetAllCompanyEmployees'],
  })

  React.useEffect(() => {
    if (isOpen) {
      if (anchorOnOpen === AnchorEditHabitType.USER_HABIT_WORK_TRAVEL) {
        userHabitWorkTravel?.current?.scrollIntoView(scrollIntoViewSettings)
      } else if (anchorOnOpen === AnchorEditHabitType.USER_HABIT_BIKE_USAGE) {
        userHabitBikeUsage?.current?.scrollIntoView(scrollIntoViewSettings)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const onSubmit = (inputData: EditHabitsEmployeeForm) => {
    const travelAverageValues = {
      distance: parseInt(inputData.workTravelDistance, 10),
      duration: parseInt(inputData.workTravelDuration, 10),
    }

    updateEmployee({
      variables: {
        where: { id: employee?.id },
        data: {
          workTravelDistance: { set: isNaN(travelAverageValues.distance) ? null : travelAverageValues.distance }, //prettier-ignore
          workTravelDuration: { set: isNaN(travelAverageValues.duration) ? null : travelAverageValues.duration }, //prettier-ignore

          ...(inputData.travelMode.value
            ? { travelMode: { connect: { name: inputData.travelMode.value } } }
            : {}),

          ...(inputData.averageBikeUsage.value
            ? {
                employeeBikeHabit: {
                  connect: { name: inputData.averageBikeUsage.value },
                },
              }
            : {}),

          ...(inputData.bikeParking.value
            ? {
                bikeParkingType: {
                  connect: { name: inputData.bikeParking.value },
                },
              }
            : {}),
        },
      },
    })
      .then(result => {
        if (result.data?.updateOneEmployee?.id) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: i18n.t('shared.notification.profileSuccessUpdate'),
          })
          refetch?.()
          submitCloseModal?.()
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={isOpen}>
      <Popup
        maxWidth={750}
        title={
          ownEmployeeInfo
            ? t('employee.profile.editHabitsModal.title.myInfo')
            : t('employee.profile.editHabitsModal.title.default', {
                employeeName: employeeNameFormatter(
                  employee?.firstName,
                  employee?.lastName,
                ),
              })
        }
        onClose={onClose}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {onBoardingMode ? (
              <Button
                type="tertiary"
                onClick={onClose}
                icon="back"
                iconColor={theme.colors.blue1}
              />
            ) : (
              <Button type="tertiary" onClick={onClose}>
                {t('forms.button.cancel')}
              </Button>
            )}

            <div css={{ marginRight: 40 }} />
            <Button
              loading={loading}
              type="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {onBoardingMode ? t('forms.button.next') : t('forms.button.save')}
            </Button>
          </div>
        }
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Group>
            <Label>
              {t('employee.profile.editHabitsModal.workTravel.title')}
            </Label>

            <Controller
              name="travelMode"
              control={control}
              defaultValue={{
                label: employee?.travelMode?.name
                  ? t(`shared.travelModeLabel.${employee?.travelMode?.name}`)
                  : undefined,
                value: employee?.travelMode?.name,
              }}
              render={({ onChange, onBlur, value, name }) => (
                <InputSelect
                  label={t('employee.profile.editHabitsModal.workTravel.mode')}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  options={travelModeOptions}
                />
              )}
            />
          </Group>

          <Group
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginTop: -21,
              marginBottom: '21px',
            }}
          >
            <div
              css={{
                display: 'flex',
                width: 'calc(50% - 22px)',
                marginRight: '22px',
                [theme.media.mobile]: {
                  width: '100%',
                  margin: 0,
                },
              }}
            >
              <Input
                onlyNumbers
                label={t(
                  'employee.profile.editHabitsModal.workTravel.distance',
                )}
                name="workTravelDistance"
                type="text"
                defaultValue={employee?.workTravelDistance?.toString() || ''}
                register={register}
                error={!!errors.workTravelDistance}
                helperText={errors.workTravelDistance?.message}
              />
            </div>

            <div
              css={{
                display: 'flex',
                width: 'calc(50% - 22px)',
                [theme.media.mobile]: {
                  width: '100%',
                },
              }}
            >
              <Input
                onlyNumbers
                label={t(
                  'employee.profile.editHabitsModal.workTravel.duration',
                )}
                name="workTravelDuration"
                type="text"
                defaultValue={employee?.workTravelDuration?.toString() || ''}
                register={register}
                error={!!errors.workTravelDuration}
                helperText={errors.workTravelDuration?.message}
              />
            </div>
          </Group>

          <Group>
            <Label>
              {t('employee.profile.editHabitsModal.bikeUsage.title')}
            </Label>
            <Controller
              name="averageBikeUsage"
              control={control}
              defaultValue={{
                label: employee?.employeeBikeHabit?.name
                  ? t(
                      `shared.employeeBikeHabitLabel.${employee?.employeeBikeHabit?.name}`,
                    )
                  : undefined,
                value: employee?.employeeBikeHabit?.name,
              }}
              render={({ onChange, onBlur, value, name }) => (
                <InputSelect
                  name={name}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  options={averageBikeHabitOptions}
                />
              )}
            />
          </Group>

          <Group>
            <Label>
              {t('employee.profile.editHabitsModal.bikeParking.title')}
            </Label>
            <Controller
              name="bikeParking"
              control={control}
              defaultValue={{
                label: employee?.bikeParkingType?.name
                  ? t(
                      `shared.bikeParkingLabel.${employee?.bikeParkingType?.name}`,
                    )
                  : undefined,
                value: employee?.bikeParkingType?.name,
              }}
              render={({ onChange, onBlur, value, name }) => (
                <InputSelect
                  name={name}
                  maxMenuHeight={105}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  options={bikeParkingOptions}
                />
              )}
            />
          </Group>
        </Form>
      </Popup>
    </Modal>
  )
}

export default EditEmployeeHabitsModal

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',

  paddingTop: 10,
  paddingRight: 20,
  paddingBottom: 30, // Account for Select input height overflow at the bottom
  paddingLeft: 20,
})

const Group = styled.div({
  marginBottom: 21,
})

const Label = styled.div({
  fontSize: '16px',
  lineHeight: '20px',
  marginBottom: '10px',
})

// const Warning = styled.div({
//   display: 'flex',
//   flexDirection: 'row',
//   paddingBottom: 20,
// })

// const WarningIcon = styled.div({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',

//   marginRight: '8px',
//   paddingBottom: 10,
// })

// const WarningLabel = styled.div({
//   fontSize: '14px',
//   lineHeight: '20px',
// })
