/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'
import Button from '../../../../components/Button'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import theme from '../../../../styles/theme'
import i18n from '../../../../i18n/config'
import { MUTATION_DELETE_ANIMATOR } from '../../../../utils/gql/mutations'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import { employeeNameFormatter } from '../../../../utils/formatter'
import {
  DeleteAnimatorMutation,
  DeleteAnimatorMutationVariables,
  GetAnimatorsQuery,
  GetAnimatorsQueryVariables,
} from '../../../../__generated__/graphql'

interface AreaEditModalProps {
  animateur: GetAnimatorsQuery['animators'][0] | null
  refetch: (newData?: Partial<GetAnimatorsQueryVariables>) => void

  closeModal: () => void
  modalIsOpen?: boolean
}

const AreaDeleteModal: React.FC<AreaEditModalProps> = ({
  animateur,
  refetch,

  closeModal,
  modalIsOpen = true,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()

  const [deleteAnimator, { loading }] = useMutation<
    DeleteAnimatorMutation,
    DeleteAnimatorMutationVariables
  >(MUTATION_DELETE_ANIMATOR)

  const onSubmit = async () => {
    if (loading) return
    if (!animateur?.id) return

    await deleteAnimator({
      variables: {
        id: animateur.id,
      },
    })
      .then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: i18n.t('admin.animator.notification.successDelete'),
        })
        refetch()
        closeModal()
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={modalIsOpen}>
      <Popup
        separator={false}
        maxWidth={750}
        css={{ color: theme.colors.redLegend }}
        titleColor={theme.colors.redLegend}
        title={t('admin.animator.modal.deleteTitle')}
        onClose={closeModal}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Button type="tertiaryError" onClick={closeModal}>
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginRight: 16 }} />
            <Button type="primaryError" loading={loading} onClick={onSubmit}>
              {t('admin.area.button.delete')}
            </Button>
          </div>
        }
      >
        <p
          css={{
            textAlign: 'center',
            marginBottom: 24,
            marginTop: 12,
            marginLeft: 48,
            marginRight: 48,
          }}
          dangerouslySetInnerHTML={{
            __html: t('admin.animator.modal.deleteTextLine', {
              name: employeeNameFormatter(
                animateur?.firstName,
                animateur?.lastName,
              ),
            }),
          }}
        ></p>
      </Popup>
    </Modal>
  )
}

export default AreaDeleteModal
