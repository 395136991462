/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Row } from 'react-table'
import React from 'react'
import {
  CompanyMonitoringTableModalActions,
  CompanyMonitoringSettings,
  CompanyMonitoringTableRow,
} from '../../../types/CompanyMonitoring'
import Icon from '../../Icon'

interface CompanyMonitoringActionCellProps {
  setModal: (
    obj: CompanyMonitoringSettings<
      CompanyMonitoringTableRow,
      CompanyMonitoringTableModalActions
    >,
  ) => void
  row: Row<CompanyMonitoringTableRow>
}

const CompanyMonitoringActionCell: React.FC<
  CompanyMonitoringActionCellProps
> = ({ setModal, row }) => {
  const handleClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      actionName: CompanyMonitoringTableModalActions,
    ) => {
      e.stopPropagation()
      setModal({ companyMonitoring: row.original, actionName })
    },
    [row, setModal],
  )

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      {[
        CompanyMonitoringTableModalActions.EDIT,
        CompanyMonitoringTableModalActions.TRASH,
      ].map(actionName => (
        <div
          key={actionName}
          css={{ cursor: 'pointer' }}
          onClick={e => handleClick(e, actionName)}
        >
          <Icon type={actionName} />
        </div>
      ))}
    </div>
  )
}

export default CompanyMonitoringActionCell
