import { Flex } from '../../../../components/Flex'
import { DeploymentTypeIcon } from '../../Shared/DeploymentTypeIcon'
import { Deployment } from '../../../../__generated__/graphql'
import React from 'react'

export interface DeploymentStepLabelProps {
  deployment: Deployment
}

export const DeploymentStepLabel: React.FC<DeploymentStepLabelProps> = ({
  deployment,
}) => {
  return (
    <Flex direction="row" justify="center" align="center" gap="8px">
      {deployment.programStep.stepNbr}
      <DeploymentTypeIcon type={deployment.deploymentType} />
    </Flex>
  )
}
