/** @jsx jsx */
import { useMutation } from '@apollo/client'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNotifications } from '../contexts/NotificationContext'

import usePortal from '../hooks/usePortal'
import { StoreState } from '../redux/rootReducer'
import { loginAction, logoutAction, UserState } from '../redux/user'
import apiErrorCatcher from '../utils/apiErrorCatcher'
import { MUTATION_LOGIN } from '../utils/gql/mutations'
import Button from './Button'
import Input from './Input'

import { LoginMutation } from '../__generated__/graphql'

export const ANIMATOR_GUARD_Z_INDEX = 2147483600

type PasswordInputState = { value: string; error: string }
const initialPassowrdInputState: PasswordInputState = { value: '', error: '' }

const AnimatorGuard: React.FC = () => {
  const target = usePortal()
  const { t } = useTranslation()
  const theme = useTheme()
  const notifications = useNotifications()
  const { email, token } = useSelector((state: StoreState) => state.user)
  const dispatch = useDispatch()
  const [login, { loading }] = useMutation<LoginMutation>(MUTATION_LOGIN)

  const [passwordInput, setPasswordInput] = React.useState<PasswordInputState>(
    initialPassowrdInputState,
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordInput({
      value: e.target.value,
      error: !e.target.value ? t('forms.errors.required') : '',
    })
  }

  React.useEffect(() => {
    if (!email || !token) {
      dispatch(logoutAction())
    }
  }, [email, dispatch, token])

  const handleSubmit = () => {
    login({
      variables: {
        email,
        password: passwordInput.value,
      },
    })
      .then(result => {
        if (!result.data?.login?.token) return
        dispatch(
          loginAction({
            id: result.data.login.user?.id || '',
            token: result.data.login.token,
            email: result.data.login.user?.email || '',
            name: result.data.login.user?.name || '',
            companyName: result.data.login.user?.companyName || '',
            role: result.data.login.user?.userRole.name as UserState['role'],
            subscriptionCode: '',
          }),
        )
      })
      .catch(apiErrorCatcher(notifications))
  }

  return createPortal(
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.colors.white,
        zIndex: ANIMATOR_GUARD_Z_INDEX,
      }}
    >
      <div
        css={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '250px 0 0',
          [theme.media.mobile]: {
            padding: 0,
          },
        }}
      >
        <div
          css={{
            flexDirection: 'column',
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            maxWidth: '480px',
            [theme.media.mobile]: {
              maxWidth: '350px',
              marginTop: 100,
            },
          }}
        >
          <span
            css={{
              textAlign: 'center',
              fontFamily: 'Raleway',
              fontSize: '1.8rem',
              fontWeight: 'bold',
              color: theme.colors.primary,
              marginBottom: 35,
            }}
          >
            {t('auth.animatorGuard.title')}
          </span>
          <form onSubmit={handleSubmit} css={{ width: '100%' }}>
            <Input type="text" name="animatorEmail" value={email} disabled />
            <Input
              type="password"
              name="animatorPassword"
              placeholder={t('forms.label.password')}
              value={passwordInput.value}
              error={!!passwordInput.error}
              helperText={passwordInput.error}
              onChange={handleInputChange}
              onBlur={handleInputChange}
            />
            <div
              css={{ display: 'flex', width: '100%', justifyContent: 'center' }}
            >
              <Button
                type="primary"
                submit
                onClick={handleSubmit}
                loading={loading}
              >
                {t('auth.animatorGuard.button')}
              </Button>
            </div>
          </form>
        </div>
        <span
          css={{
            display: 'flex',
            marginBottom: 20,
            color: theme.colors.gray3,
            textDecorationLine: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => dispatch(logoutAction())}
        >
          {t('shared.auth.logout')}
        </span>
      </div>
    </div>,
    target,
  )
}

export default AnimatorGuard
