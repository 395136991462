/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Avatar from '../../../components/Avatar'
import {
  useNotifications,
  NotificationTypes,
} from '../../../contexts/NotificationContext'
import { MUTATION_UPLOAD_AVATAR } from '../../../utils/gql/mutations'

import { avatarSchema } from '../../../utils/schemaValidation'
import { displayAvatar } from '../../../utils/files'
import theme from '../../../styles/theme'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import {
  UploadAvatarMutation,
  UploadAvatarMutationVariables,
} from '../../../__generated__/graphql'

interface FormInput {
  avatar: FileList | null
}

interface Props {
  avatarUrl?: string
  refetch: () => void
}

const EditAvatarAnimator: React.FC<Props> = ({ avatarUrl, refetch }) => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors, reset } = useForm<FormInput>({
    resolver: yupResolver(avatarSchema),
    mode: 'onBlur',
  })
  const [uploadAvatar, { loading }] = useMutation<
    UploadAvatarMutation,
    UploadAvatarMutationVariables
  >(MUTATION_UPLOAD_AVATAR)
  const notifications = useNotifications()

  useEffect(() => {
    if (errors?.avatar?.message)
      notifications.newNotification({
        type: NotificationTypes.ERROR,
        message: errors.avatar.message,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const onSubmit = async (data: FormInput) => {
    await uploadAvatar({
      variables: {
        file: data.avatar && data.avatar[0],
      },
    })
      .then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t('shared.notification.avatarSuccessUpdate'),
        })
        refetch()
        reset()
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <React.Fragment>
      <h2
        css={{
          margin: '18px 0',
          [theme.media.laptop]: { textAlign: 'center' },
        }}
      >
        {t('shared.profile.myAvatar')}
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div css={{ width: '160px', height: '160px' }}>
          <Avatar
            name="avatar"
            register={register}
            onChange={handleSubmit(onSubmit)}
            imgUrl={displayAvatar(avatarUrl)}
            loading={loading}
          />
        </div>
      </form>
    </React.Fragment>
  )
}

export default EditAvatarAnimator
