/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import { format } from 'date-fns'

import CardTitle from '../../../../../components/Card/CardTitle'
import CardFoldable from '../../../../../components/Card/Foldable'
import Input from '../../../../../components/Input'
import InputSelect from '../../../../../components/InputSelect'
import List from './List'

import { TextVariant } from '../../../../../hooks/useVariant/variants/text'
import { SelectVariant } from '../../../../../hooks/useVariant/variants/select'

import {
  EmployeeTableRefetchContext,
  EmployeeTableTypes,
} from '../EmployeeTableRefetchContext'
import { SpecialBikesType } from '@goodwatt/shared/dist/types'
import {
  CompanyLoanPlacesBikeQuery,
  CompanyLoanPlacesBikeQueryVariables,
} from '../../../../../__generated__/graphql'

const QUERY_COMPANY_LOAN_PLACES_BIKE = gql`
  query CompanyLoanPlacesBike($companyId: String) {
    company(where: { id: $companyId }) {
      id
      programStep {
        stepNbr
      }
      loanQuantityBikes
      employeesSelectedCount
    }
  }
`

let debouncedFn: undefined | (() => void)

const SelectedEmployees: React.FC = () => {
  const {
    tableRefetchPlanner: { [EmployeeTableTypes.SELECTED]: needToRefetch },
  } = React.useContext(EmployeeTableRefetchContext)
  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({})
  const { id: companyId } = useParams<{ id: string }>()
  const { data, refetch: refetchBadgeCount } = useQuery<
    CompanyLoanPlacesBikeQuery,
    CompanyLoanPlacesBikeQueryVariables
  >(QUERY_COMPANY_LOAN_PLACES_BIKE, {
    variables: { companyId },
  })

  React.useEffect(() => {
    if (needToRefetch) {
      refetchBadgeCount()
    }
  }, [needToRefetch, refetchBadgeCount])
  React.useEffect(() => {
    return () => {
      debouncedFn = undefined
    }
  }, [])
  const theme = useTheme()

  const onSelectChange = React.useCallback(({ value }, { name }) => {
    setFilters(prevState => ({
      ...prevState,
      [name]: value !== 'all' ? value : null,
    }))
  }, [])

  const onInputNameChange = React.useCallback(e => {
    /* signal to React not to nullify the event object */
    e.persist()

    if (!debouncedFn) {
      debouncedFn = debounce(() => {
        setFilters(prevState => ({
          ...prevState,
          multiFilter: e.target.value || undefined,
        }))
      }, 300)
    }
    debouncedFn()
  }, [])

  const bikeOptionsOptions: Array<{ value: string; label: string }> =
    React.useMemo(
      () => [
        {
          label: t(
            'animator.company.employees.selected.filter.options.options.all',
          ),
          value: 'all',
        },

        ...(Object.values(SpecialBikesType).map(type => ({
          label: t(`shared.specialBikes.short.${type}`),
          value: type as string,
        })) || []),
      ],
      [t],
    )

  const distanceFilterOptions = React.useMemo(
    () => [
      {
        label: t(
          'animator.company.employees.selected.filter.programStep.options.all',
        ),
        value: 'all',
      },
      {
        label: t(
          'animator.company.employees.selected.filter.programStep.options.complete',
        ),
        value: true,
      },
      {
        label: t(
          'animator.company.employees.selected.filter.programStep.options.notComplete',
        ),
        value: false,
      },
    ],
    [t],
  )

  React.useEffect(() => (debouncedFn = undefined), [])

  return (
    <CardFoldable
      title={
        <CardTitle
          statusColor={theme.colors.orangeLegend}
          label={t('animator.company.employees.selected.titleLabel')}
          badge={{
            label: t('animator.company.employees.selected.ratio', {
              current: data?.company?.employeesSelectedCount,
              total: data?.company?.loanQuantityBikes || 0,
            }),
            fontColor: theme.colors.white,
            backgroundColor: theme.colors.orangeLegend,
          }}
        />
      }
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          marginTop: '2.1rem',
          marginBottom: '2.1rem',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <div
          css={{
            display: 'flex',
            flex: 1,
            minWidth: '220px',
            paddingRight: '17px',
            width: '100%',
            [theme.media.mobile]: {
              paddingRight: '0px',
            },
          }}
        >
          <InputSelect<string>
            name="specialBikeType"
            withError={false}
            variant={SelectVariant.white}
            label={t(
              'animator.company.employees.selected.filter.options.label',
            )}
            defaultValue="all"
            onChange={onSelectChange}
            options={bikeOptionsOptions}
          />
        </div>
        <div
          css={{
            display: 'flex',
            flex: 1,
            minWidth: '220px',
            paddingRight: '17px',
            width: '100%',
            [theme.media.mobile]: {
              paddingRight: '0px',
              marginTop: '14px',
            },
          }}
        >
          <InputSelect<string | boolean>
            name="hasProfileComplete"
            withError={false}
            variant={SelectVariant.white}
            label={t(
              'animator.company.employees.selected.filter.programStep.label',
            )}
            defaultValue="all"
            onChange={onSelectChange}
            options={distanceFilterOptions}
          />
        </div>
        <div
          css={{
            display: 'flex',
            flex: 2,
            minWidth: '220px',
            [theme.media.tablet]: {
              marginTop: '14px',
            },
          }}
        >
          <Input
            icon="magnifier"
            variant={TextVariant.white}
            name="companyEmployeesAutoComplete"
            withError={false}
            onChange={onInputNameChange}
          />
        </div>
      </div>
      <List
        filters={filters}
        loanEventsFormatted={{
          loanDeliveryDate: new Date()
            ? format(new Date(), 'dd/MM/yyyy')
            : '...',
          loanReturnDate: new Date() ? format(new Date(), 'dd/MM/yyyy') : '...',
        }}
        currentProgramStep={data?.company?.programStep.stepNbr || 0}
      />
    </CardFoldable>
  )
}

export default SelectedEmployees
