/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'

interface DeploymentFormStepIndicatorProps {
  step: number
  nbrSteps: number
}

const DeploymentFormStepIndicator: React.FC<
  DeploymentFormStepIndicatorProps
> = ({ step, nbrSteps }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '115px',
        backgroundColor: 'rgba(60, 217, 241, 0.1)',
        marginBottom: '20px',
        justifyContent: 'space-around',
      }}
    >
      {[...Array(nbrSteps)].map((_, i) => (
        <div
          css={{
            opacity: step === i ? 1 : 0.5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: `${100 / nbrSteps}%`,
          }}
          key={i}
        >
          <div
            css={{
              backgroundColor: 'white',
              borderRadius: '50%',
              border: `1px solid ${theme.colors.primary}`,
              width: '50px',
              height: '50px',
              lineHeight: '50px',
              color: theme.colors.primary,
            }}
          >
            {i + 1}
          </div>
          <div
            css={{
              marginTop: '10px',
              fontSize: '14px',
              color: theme.colors.primary,
            }}
          >
            {t(`animator.deployments.form.step${i + 1}`)}
          </div>
        </div>
      ))}
    </div>
  )
}

export default DeploymentFormStepIndicator
