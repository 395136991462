import { MutationHookOptions, useMutation } from '@apollo/client'
import { MUTATION_UNARCHIVE_DEPLOYMENT } from '../../../../utils/gql/mutations'

export const useUnArchiveDeployment = (options?: MutationHookOptions) => {
  return useMutation(MUTATION_UNARCHIVE_DEPLOYMENT, {
    refetchQueries: [
      'GetDeployments',
      'GetDeploymentsCount',
      'GetCompanySitesWithDeployments',
    ],
    ...options,
  })
}
