/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { HTMLAttributes } from 'react'

export interface FlexProps extends HTMLAttributes<HTMLDivElement> {
  direction?: 'row' | 'column'
  align?: string
  justify?: string
  gap?: string
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse' | 'initial' | 'inherit'
  flex?: string | number
}

export const Flex: React.FC<FlexProps> = ({
  direction = 'row',
  align = 'stretch',
  justify = 'start',
  flexWrap = 'nowrap',
  flex = 'initial',
  gap,
  children,
  ...rest
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: direction,
        alignItems: align,
        justifyContent: justify,
        flexWrap,
        flex,
        gap,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}
