import React from 'react'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'

import { QUERY_GET_EMPLOYEE_DASHBOARD } from '../../../utils/gql/queries'

import EditEmployeeModal from '../../Shared/EditEmployeeModal'
import EditEmployeeHabitsModal from '../../Shared/EditEmployeeHabitsModal'
import UploadProfileDocuments from '../Documents/UploadProfileDocuments'

import WelcomeModal from './WelcomeModal'
import CongrasModal from './CongrasModal'
import { useHistory } from 'react-router-dom'
import {
  GetEmployeeDashboardQuery,
  GetEmployeeDashboardQueryVariables,
} from '../../../__generated__/graphql'

export enum ValidationStep {
  WELCOME,
  PROFILE,
  HABIT,
  ID_CARD,
  ADDRESS,
  CONGRAS,
}

const OnBoarding: React.FC = () => {
  const history = useHistory()

  const userId = useSelector((state: StoreState) => state.user.id)

  const { data, refetch } = useQuery<
    GetEmployeeDashboardQuery,
    GetEmployeeDashboardQueryVariables
  >(QUERY_GET_EMPLOYEE_DASHBOARD, {
    fetchPolicy: 'network-only',
    variables: {
      where: { userId: userId },
    },
  })

  const [loanProcessStep, setLoanProcessStep] = React.useState<ValidationStep>(
    ValidationStep.WELCOME,
  )

  const nextStep = () => {
    setLoanProcessStep(loanProcessStep + 1)
  }

  const prevStep = () => {
    setLoanProcessStep(loanProcessStep - 1)
  }

  const showDocumentModal =
    loanProcessStep === ValidationStep.ID_CARD
      ? 'ID_CARD'
      : loanProcessStep === ValidationStep.ADDRESS
      ? 'ADDRESS'
      : null

  return (
    <>
      <WelcomeModal
        isOpen={loanProcessStep === ValidationStep.WELCOME}
        onSubmit={nextStep}
      />

      <CongrasModal
        isOpen={loanProcessStep === ValidationStep.CONGRAS}
        onSubmit={() => history.push('/employee/dashboard')}
      />

      <EditEmployeeModal
        employee={data?.employee || null}
        isOpen={loanProcessStep === ValidationStep.PROFILE}
        onClose={prevStep}
        submitCloseModal={nextStep}
        refetch={() => refetch()}
        onBoardingMode
        ownEmployeeInfo
      />

      <EditEmployeeHabitsModal
        employee={data?.employee || null}
        isOpen={loanProcessStep === ValidationStep.HABIT}
        onClose={prevStep}
        submitCloseModal={nextStep}
        refetch={refetch}
        onBoardingMode
        ownEmployeeInfo
      />

      <UploadProfileDocuments
        isOpen={showDocumentModal}
        closeModal={nextStep}
        closeModalPrev={prevStep}
        refetch={refetch}
        onBoardingMode
      />
    </>
  )
}

export default OnBoarding
