/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  DeploymentType,
  GetCompanyDeploymentWithSiteQuery,
  GetCompanyDeploymentWithSiteQueryVariables,
} from '../../../__generated__/graphql'
import { setSubMenuTitle } from '../../../redux/menu'
import { Flex } from '../../../components/Flex'
import Icon from '../../../components/Icon'
import Card from '../../../components/Card'
import { DeploymentTypeIcon } from '../../Animator/Shared/DeploymentTypeIcon'
import { useFilters } from '../../../hooks/useFilters'
import Input from '../../../components/Input'
import InputSelect from '../../../components/InputSelect'
import { TextVariant } from '../../../hooks/useVariant/variants/text'
import { SelectVariant } from '../../../hooks/useVariant/variants/select'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import { List } from '../../Shared/CompanyEmployees/List'

const QUERY_COMPANY_DEPLOYMENT_WITH_SITE = gql`
  query GetCompanyDeploymentWithSite($id: String!) {
    deployment(id: $id) {
      name
      deploymentType
      site {
        id
        name
      }
    }
  }
`

interface Filters {
  status: string
  search: string
  siteId: string
  deploymentId: string
}

const CompanyLoanEmployees: React.FC = () => {
  const userId = useSelector((state: StoreState) => state.user.id)
  const { deploymentId } = useParams<{ deploymentId: string }>()

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { data } = useQuery<
    GetCompanyDeploymentWithSiteQuery,
    GetCompanyDeploymentWithSiteQueryVariables
  >(QUERY_COMPANY_DEPLOYMENT_WITH_SITE, {
    variables: { id: deploymentId },
  })

  const { filters, register, setFilters } = useFilters<Filters>()

  const employeeStatusOptions = React.useMemo(
    () => [
      {
        value: 'all',
        label: t('animator.companies.filter.options.all'),
      },
      {
        value: 'candidate',
        label: t(
          'company.employees.filters.testingMonth.options.profileCompleted',
        ),
      },
      {
        value: 'selected',
        label: t('company.employees.filters.testingMonth.options.selected'),
      },
      {
        value: 'beneficiary',
        label: t('company.employees.filters.testingMonth.options.beneficiary'),
      },
    ],
    [t],
  )

  useEffect(() => {
    if (data?.deployment?.name) dispatch(setSubMenuTitle(data.deployment.name))

    if (data?.deployment?.site?.id && deploymentId) {
      setFilters(filters => ({
        ...filters,
        siteId: data?.deployment?.site.id,
        deploymentId: deploymentId,
      }))
    }
  }, [data, deploymentId, dispatch, setFilters])

  return (
    <Fragment>
      <Flex
        align="center"
        gap="1.2rem"
        css={{
          marginBottom: '1.2rem',
        }}
      >
        <Flex align="center" gap=".5rem">
          <h1
            css={{
              fontFamily: 'Raleway',
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '20px',
              textAlign: 'left',
              color: '#3CD9F1',
            }}
          >
            {t('company.monitoring.title', {
              address: data?.deployment?.site.name,
              name: data?.deployment?.name,
            })}
          </h1>
          <DeploymentTypeIcon
            type={data?.deployment?.deploymentType as DeploymentType}
          />
        </Flex>
        <Link to="/company/dashboard">
          <Icon type="quit" />
        </Link>
      </Flex>

      <Card
        title={
          <span
            css={{
              fontWeight: 'bold',
              fontSize: '1.8rem',
              lineHeight: '20px',
              fontFamily: 'Raleway',
            }}
          >
            {t('company.card.employees')}
          </span>
        }
      >
        <Flex
          direction="row"
          gap="16px"
          align="end"
          css={{ marginTop: '16px', marginBottom: '16px' }}
        >
          <div css={{ flex: 1 }}>
            <InputSelect
              variant={SelectVariant.white}
              label={t(`company.tables.filters.status`)}
              withError={false}
              defaultValue="all"
              options={employeeStatusOptions}
              {...register('status')}
            />
          </div>

          <div css={{ flex: 2 }}>
            <Input
              icon="magnifier"
              variant={TextVariant.white}
              withError={false}
              {...register('search')}
            />
          </div>
        </Flex>

        <List userId={userId} filters={filters} origin="deployment" />
      </Card>
    </Fragment>
  )
}

export default CompanyLoanEmployees
