import { ApolloError } from '@apollo/client'
import { errors } from '@goodwatt/shared'
import {
  NotificationContextType,
  NotificationTypes,
} from '../contexts/NotificationContext'
import i18n from '../i18n/config'

export const newErrorNotification =
  (notifications: NotificationContextType) =>
  (message: string): void => {
    notifications.newNotification({
      type: NotificationTypes.ERROR,
      message,
      ms: 7000,
    })
  }

export default (notifications: NotificationContextType) =>
  ({ graphQLErrors }: { graphQLErrors: ApolloError[] }): void => {
    const notif = newErrorNotification(notifications)

    if (!graphQLErrors?.length) return notifications.anErrorOccured()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apiErrors = graphQLErrors.map((e: any) => e.message)
    /**
     * SIGN IN / RESET PASSWORD
     */
    if (apiErrors.includes(errors.EmailIsNotVerified().message))
      return notif(i18n.t('forms.errors.emailNotVerified'))
    if (apiErrors.includes(errors.WrongCredentialsError().message))
      return notif(i18n.t('forms.errors.signinFailed'))
    if (apiErrors.includes(errors.ResourceNotFound('USER').message))
      return notif(i18n.t('forms.errors.userNotFound'))
    /**
     * COMPANY SIGN UP
     */
    // STEP 1
    if (apiErrors.includes(errors.FieldValidationError('SIRET').message))
      return notif(i18n.t('forms.errors.siretRegex'))
    if (apiErrors.includes(errors.AlreadyTaken('SIRET').message))
      return notif(i18n.t('forms.errors.siretTaken'))
    /**
     * SHARED
     */
    if (apiErrors.includes(errors.AlreadyTaken('EMAIL').message))
      return notif(i18n.t('forms.errors.emailTaken'))
    if (apiErrors.includes(errors.AlreadyTaken('DEPLOYMENT_NAME').message))
      return notif(i18n.t('forms.errors.deploymentNameTaken'))
    /**
     * EMPLOYEE SIGN UP
     */
    if (
      apiErrors.includes(errors.ResourceNotFound('SUBSCRIPTION_CODE').message)
    )
      return notif(i18n.t('forms.errors.subscriptionCodeNotFound'))
    /**
     * EDIT BIKE
     */
    if (
      apiErrors.includes(
        errors.FieldValidationError('RESOLVED_DATE_ALREADY_PASSED').message,
      )
    )
      return notif(i18n.t('forms.errors.resolvedDatePassed'))
    if (apiErrors.includes(errors.FieldValidationError('PRICE').message))
      return notif(i18n.t('forms.errors.negativePrice'))
    /**
     * BIKE ASSOCIATION
     */
    if (apiErrors.includes(errors.ResourceNotFound('WORK_TRAVEL_INFO').message))
      return notif(i18n.t('forms.errors.addressPositionNotFound'))
    if (apiErrors.includes(errors.ForbiddenError('WRONG_FLEET').message))
      return notif(i18n.t('forms.errors.wrongBikeFleetAssociation'))
    if (apiErrors.includes(errors.ForbiddenError('BIKE_NOT_AVAILABLE').message))
      return notif(i18n.t('forms.errors.bikeNotAvailable'))
    if (
      apiErrors.includes(
        errors.FieldValidationError('IMAGE_FILE_EXTENTION').message,
      )
    )
      // prettier-ignore
      return notif(i18n.t('forms.errors.imageNotSupported'));
    /**
     * SHARED RESOURCES
     */
    if (apiErrors.includes(errors.ResourceNotFound('BIKE').message))
      return notif(i18n.t('shared.errors.bikeNotFound'))
    if (apiErrors.includes(errors.ResourceNotFound('COMPANY').message))
      return notif(i18n.t('shared.errors.companyNotFound'))
    if (
      apiErrors.includes(
        errors.ResourceNotFound('BIKE_LOAN_ATTACHMENT').message,
      )
    )
      return notif(i18n.t('shared.errors.bikeLoanAttachmentNotFound'))

    /**
     * REFERRER
     */
    if (apiErrors.includes(errors.ResourceAlreadyExists('REFERRER').message))
      return notif(i18n.t('shared.errors.referrerAlreadyExists'))

    if (apiErrors.includes(errors.ForbiddenError('BADCOMPANY').message))
      return notif(i18n.t('shared.errors.BadCompanyForbiddenError'))

    /**
     * DEFAULT
     */
    return notifications.anErrorOccured()
  }
