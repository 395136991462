/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'

import Input from '../../components/Input'
import Button from '../../components/Button'

import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../redux/rootReducer'
import { setCompanySignUpForm } from '../../redux/forms'
import i18n from '../../i18n/config'
import { useTranslation } from 'react-i18next'
import usePromiseLazyQuery from '../../hooks/usePromiseLazyQuery'
import apiErrorCatcher from '../../utils/apiErrorCatcher'

import {
  NotificationTypes,
  useNotifications,
} from '../../contexts/NotificationContext'

import {
  CheckZipCodeQuery,
  CheckZipCodeQueryVariables,
} from '../../__generated__/graphql'

import { QUERY_CHECK_ZIPCODE } from '../../utils/gql/queries'
import { countiesList } from '../../utils/countries'
import InputSelect from '../../components/InputSelect'

const schema = yup.object().shape({
  street: yup.string().required(i18n.t('forms.errors.required')),
  postalCode: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .length(5, i18n.t('forms.errors.wrongFormat')),
  city: yup.string().required(i18n.t('forms.errors.required')),
  country: yup.string().required(i18n.t('forms.errors.required')),
  nEmployees: yup.string().required(i18n.t('forms.errors.required')),
})

interface Step2CompanyInputs {
  siteName: string
  street: string
  postalCode: string
  city: string
  nEmployees: string
  country: string
}

interface Step2Props {
  onNext: () => void
  onPrevious: () => void
}

const Step2: React.FC<Step2Props> = ({ onNext, onPrevious }) => {
  const { t } = useTranslation()
  const notifitions = useNotifications()
  const theme = useTheme()
  const dispatch = useDispatch()
  const form = useSelector((state: StoreState) => state.forms.companySignUpForm)
  const checkZipCode = usePromiseLazyQuery<
    CheckZipCodeQuery,
    CheckZipCodeQueryVariables
  >(QUERY_CHECK_ZIPCODE)

  const countries = countiesList.map(x => ({
    label: x,
    value: x,
  }))

  const { register, handleSubmit, errors, getValues, setValue } =
    useForm<Step2CompanyInputs>({
      resolver: yupResolver(schema),
      mode: 'onBlur',
      defaultValues: {
        country: 'France',
      },
    })

  const onSubmit = (data: Step2CompanyInputs) => {
    // To persist form if the user reload the page
    dispatch(
      setCompanySignUpForm({
        siteName: data.siteName,
        street: data.street,
        postalCode: data.postalCode,
        city: data.city,
        nEmployees: data.nEmployees,
        country: data.country,
      }),
    )
    checkZipCode({
      where: {
        code: data.postalCode.slice(0, 2),
      },
    })
      .then(r => {
        if (r.data?.territoryDepartment) onNext()
        else
          notifitions.newNotification({
            type: NotificationTypes.ERROR,
            message: t('forms.errors.zipCodeNotFound'),
            ms: 12000,
          })
      })
      .catch(apiErrorCatcher(notifitions))
  }

  const formValue = getValues()

  const onChangeCountry = async (data: any) => {
    setValue('country', data.value)
  }

  return (
    <div css={{ width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <Input
              type="text"
              name="siteName"
              defaultValue={form.siteName}
              label={t('forms.label.companySiteName')}
              error={!!errors.siteName}
              helperText={errors.siteName?.message}
              register={register}
            />
            <Input
              type="text"
              name="street"
              label={t('forms.label.companyAddress')}
              defaultValue={form.street}
              error={!!errors.street}
              helperText={errors.street?.message}
              register={register}
            />
          </div>

          <div css={{ display: 'flex', flexDirection: 'row' }}>
            <div css={{ flex: 2, marginRight: 15 }}>
              <Input
                type="text"
                name="postalCode"
                label={t('forms.label.postalCode')}
                defaultValue={form.postalCode}
                error={!!errors.postalCode}
                helperText={errors.postalCode?.message}
                maxLength={5}
                register={register}
              />
            </div>
            <div css={{ flex: 3 }}>
              <Input
                type="text"
                name="city"
                label={t('forms.label.city')}
                defaultValue={form.city}
                error={!!errors.city}
                helperText={errors.city?.message}
                register={register}
              />
            </div>
          </div>

          <div>
            <InputSelect
              value={
                formValue.country !== ''
                  ? countries.find(c => c.label === formValue.country)
                  : 'France'
              }
              label={t('forms.label.country')}
              defaultValue="France"
              options={countries}
              name="country"
              error={!!errors.country}
              helperText={errors.country?.message}
              onChange={onChangeCountry}
              register={register('country')}
            />
          </div>
        </div>

        <Input
          type="number"
          name="nEmployees"
          label={t('forms.label.nEmployees')}
          placeholder={t('forms.label.nEmployeesPlaceholder')}
          defaultValue={form.nEmployees}
          error={!!errors.nEmployees}
          helperText={errors.nEmployees?.message}
          register={register}
        />
        <div
          css={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <div css={{ marginRight: 40, marginLeft: '-84px' /* -40 + -44 */ }}>
            <Button
              type="tertiary"
              onClick={onPrevious}
              icon="back"
              iconColor={theme.colors.primary}
            />
          </div>
          <Button type="primary" submit>
            {t('forms.button.next')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Step2
