/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { KeyboardEvent, useCallback } from 'react'
import {
  TextVariant,
  TextVariantsConfig,
} from '../hooks/useVariant/variants/text'
import Icon, { IconTypes } from './Icon'
import useVariant from '../hooks/useVariant'

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined

export interface CurrencyInputProps {
  name?: string
  value: number
  max?: number
  variant?: TextVariant
  icon?: IconTypes
  onChange(value: number): void
  placeholder?: string
  currency?: 'EUR' | 'USD'
  error?: boolean
  disabled?: boolean
  block?: boolean
  label?: string
  helperText?: string
  // RefReturn type is needed for react-hook-form package
  register?: () => RefReturn
  withError?: boolean
}

const VALID_FIRST = /^[1-9]{1}$/
const VALID_NEXT = /^[0-9]{1}$/
const DELETE_KEY = 'Backspace'

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  name,
  label,
  value,
  icon,
  variant = TextVariant.gray,
  onChange: onChangeProp,
  error,
  currency = 'EUR',
  disabled,
  placeholder,
  block = true,
  helperText,
  withError = true,
  register,
  max = Number.MAX_SAFE_INTEGER,
}) => {
  const theme = useTheme()
  const textVariantConfig = useVariant('text')[
    variant
  ] as TextVariantsConfig[typeof variant]

  const valueAbsTrunc = Math.trunc(Math.abs(value))
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    // eslint-disable-next-line no-console
    console.error(`invalid value property`)
  }

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>): void => {
      const { key } = e
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && key !== DELETE_KEY)
      ) {
        return
      }
      const valueString = value.toString()
      let nextValue: number
      if (key !== DELETE_KEY) {
        const nextValueString: string =
          value === 0 ? key : `${valueString}${key}`
        nextValue = Number.parseInt(nextValueString, 10)
      } else {
        const nextValueString = valueString.slice(0, -1)
        nextValue =
          nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10)
      }
      if (nextValue > max) {
        return
      }
      // nextValue is an integer, eg: 10000 will be displayed as 100,00.
      onChangeProp(nextValue)
    },
    [max, onChangeProp, value],
  )
  const valueDisplay = (value / 100).toLocaleString('fr-FR', {
    style: 'currency',
    currency,
  })

  return (
    <div
      css={{
        width: block ? '100%' : 'fit-content',
      }}
    >
      {label && (
        <div
          css={{
            marginLeft: 21,
            marginBottom: 4,
          }}
        >
          <label
            css={{
              fontSize: '1.6rem',
              color: theme.colors.gray3,
            }}
          >
            {label}
          </label>
        </div>
      )}
      <div
        css={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 44,
          backgroundColor: textVariantConfig.bckgColor,
          borderRadius: 22,
          boxSizing: 'border-box',
          border: error
            ? `2px solid ${theme.colors.error}`
            : textVariantConfig.border,
          transition: 'border 0.2s ease-in',
          paddingLeft: icon ? '50px' : '21px',
        }}
      >
        {icon ? (
          <div
            css={{
              position: 'absolute',
              top: '10px',
              left: '15px',
            }}
          >
            <Icon type={icon} color={theme.colors.gray4} />
          </div>
        ) : null}
        <input
          size={1}
          css={{
            flex: 1,
            fontFamily: 'Roboto',
            fontSize: '1.6rem',
            boxSizing: 'border-box',
            padding: '10px 0px 11px',
            border: 'none',
            outline: 'none',
            color: disabled ? theme.colors.gray3 : theme.colors.gray1,
            backgroundColor: 'transparent',
            transition: 'color 0.2s ease-in',
            overflow: 'hidden',
            boxShadow: 'none',
            '::placeholder': {
              fontStyle: 'italic',
              fontWeight: 300,
              color: theme.colors.gray3,
            },
          }}
          id={name}
          placeholder={placeholder}
          name={name}
          value={valueDisplay}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          ref={register}
        />
      </div>
      {withError && (
        <div
          css={{
            marginLeft: 24,
            height: 20,
          }}
        >
          {helperText && (
            <span
              css={{
                fontFamily: 'Roboto',
                fontSize: '1.2rem',
                color: error ? theme.colors.error : theme.colors.gray3,
              }}
            >
              {helperText}
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default CurrencyInput
